import { useState } from "react";
import classNames from "classnames";
import { motion } from "framer-motion";
import { sortBy } from "lodash-es";
import { Success } from "assets/icons";
import { AnimatedCircle } from "components/AnimatedCircle";
import { CallToAction } from "components/CallToAction";
import { Image } from "components/Image";
import { ModuleWrapper } from "components/Wrappers/ModuleWrapper";
import { VARIANT } from "helpers/constants";
import { formatDate } from "helpers/dateHelper";
import { getContentWithLineBreaks } from "helpers/htmlHelper";
import { ContentFeatureCardList } from "page-modules/ContentFeatureCardStatic";

const baseConfig = {
  circle: {
    r: 22,
  },
  viewbox: "0 0 60 60",
};

const cardStyles = {
  achieved: "bg-blue-100 text-black-primary",
  "current priority": "bg-yellow-100 text-black-primary",
  planned: "bg-green-100 text-extra-success-text",
};

export const Slide = ({ card }) => {
  const {
    timelineState_TextField: cardState,
    cta,
    description,
    image,
    endDate,
    listItem,
    listTitle,
    percentageCompletion,
    title,
  } = card.fields;

  const [isInView, setIsInView] = useState(false);

  const formattedEndDate = formatDate(endDate);

  const showSuccessIcon =
    Number(percentageCompletion) === 100 ||
    cardState?.toLowerCase() === "achieved";
  const showAnimatedCircle =
    !isNaN(Number(percentageCompletion)) &&
    Number(percentageCompletion) !== 100;

  return (
    <motion.div
      className="overflow-hidden bg-white rounded-7 shadow-100 md:rounded-10 hover:cursor-grab"
      whileInView={
        /* istanbul ignore next */
        () => setIsInView(true)
      }
      viewport={{ once: true, amount: 0.4 }}
    >
      <div className="px-4 py-5 md:p-7 lg:py-10">
        <div className="absolute w-15 h-15 right-2 top-2">
          {showSuccessIcon && (
            <Success className="ml-4 mt-2.5 w-8 h-8 text-green-500 fill-current" />
          )}
          {showAnimatedCircle && (
            <AnimatedCircle
              className="font-medium text-center text-14/20"
              config={baseConfig}
              isInView={isInView}
              percentageCompletion={percentageCompletion}
            />
          )}
        </div>
        {(cardState || formattedEndDate) && (
          <div
            className={classNames(
              "mb-3 space-y-3 md:space-y-0 md:space-x-2 md:flex",
              { "mb-11 md:mb-3": !formattedEndDate },
            )}
          >
            {cardState && (
              <div
                className={classNames(
                  "inline-block px-2 font-medium rounded-2 text-12/20",
                  cardStyles?.[cardState.toLowerCase()],
                )}
              >
                {cardState}
              </div>
            )}
            {formattedEndDate && (
              <p className="text-black-primary text-12/20">
                {formattedEndDate}
              </p>
            )}
          </div>
        )}
        <div className="space-y-3">
          {title && (
            <h3 className="font-bold text-black-primary text-20/24 md:text-24/28">
              {title}
            </h3>
          )}
          {description && (
            <p className="text-black-primary text-14/20 md:text-16/24">
              {getContentWithLineBreaks(description)}
            </p>
          )}
          {cta && (
            <CallToAction
              className="!p-0"
              data={cta}
              variant={VARIANT.darkTransparent}
            />
          )}
          {listTitle && (
            <h4 className="font-medium text-black-primary text-14/20 md:text-16/24">
              {listTitle}
            </h4>
          )}
          <ContentFeatureCardList data={listItem} />
        </div>
      </div>
      {image && (
        <div className="relative w-full h-28 sm:h-30 md:h-55">
          <Image
            src={image.url}
            alt={image.label}
            layout="fill"
            objectFit="cover"
          />
          <div className="absolute inset-0 bg-curve-milestone"></div>
        </div>
      )}
    </motion.div>
  );
};

export const sortCards = ({ cards, cardStates }) => {
  cards = sortBy(cards, ["properties.itemOrder"]);
  return cardStates
    .map((cardState) =>
      cards.filter((card) => card.fields.timelineState_TextField === cardState),
    )
    .flat()
    .map((card, index) => ({ ...card, index }));
};
export const milestoneNavItems = ({
  config,
  cards,
  cardStates,
  setActiveCardState,
  swiperRef,
}) => {
  config.onSlideChange = () => {
    const activeCard =
      cards.find((card) => card.index === swiperRef?.activeIndex) ??
      cards[config.initialSlide];
    setActiveCardState(activeCard.fields.timelineState_TextField);
  };

  return cardStates.map((cardState) => {
    const navItem = cards.find(
      (card) => card.fields.timelineState_TextField === cardState,
    );
    return {
      index: navItem?.index,
      label: navItem?.fields.timelineState_TextField,
    };
  });
};

export const MilestoneNav = ({ activeCardState, navItems, swiperRef }) => {
  return (
    <ModuleWrapper psnid="psn_milestone_helper" className="!mb-10 !pt-0">
      <div className="flex cursor-pointer text-black-400">
        {navItems.map((navItem) => (
          <div
            className={classNames("relative p-3 text-14/20 md:text-16/24", {
              "text-dark-primary after:h-1 after:rounded after:bottom-0 after:left-0 after:bg-black-primary after:w-full after:content('') after:absolute":
                navItem.label === activeCardState,
            })}
            data-testid={`card-test-${navItem.label}`}
            key={`${navItem.label}-${navItem.index}`}
            onClick={() => swiperRef.slideTo(navItem.index)}
          >
            {navItem.label}
          </div>
        ))}
      </div>
      <div className="w-full h-1 -mt-1 rounded bg-black-50"></div>
    </ModuleWrapper>
  );
};
