import classNames from "classnames";
import { Blank } from "components/Blank";
import { getBlocks } from "helpers/blockEditorHelper";
import { ALL_BLOCKS } from "./Blocks";

function InlineBlockEditor({
  className,
  data = [],
  jsonString = "",
  testid = "inlineBlockEditor",
  blocks = ALL_BLOCKS,
  enableProse = true,
}) {
  const blocksToRender = jsonString ? getBlocks(jsonString) : data;

  if (!blocksToRender.length) {
    return null;
  }

  const proseClasses = enableProse
    ? "prose md:prose-md sm:max-w-full break-words"
    : "";

  return (
    <div className={classNames(proseClasses, className)} data-testid={testid}>
      {blocksToRender.map((block) => {
        const BlockComponent = blocks[block.type] || Blank;
        return (
          <BlockComponent key={block.id} data={block.data} id={block.id} />
        );
      })}
    </div>
  );
}

export { InlineBlockEditor };
