import React from "react";
import classNames from "classnames";
import { BgCurveAnimation } from "./BgCurveAnimation";
import { calculateBoundsForCurve, convertModuleFields } from "./BgCurveHelper";
import { BgCurveMask } from "./BgCurveMask";

const variants = {
  extended: "-inset-y-55 md:-inset-y-65",
  indented: "inset-y-30",
};

function BgCurve({ page, module }) {
  const { backgroundColor, curveType } = module.fields;
  const [bounds, setBounds] = React.useState({ top: 0, height: 0 });

  const curveElementRef = React.useRef(null);

  React.useEffect(() => {
    const fields = convertModuleFields(module.fields);

    function onResize() {
      const result = calculateBoundsForCurve({ page, fields });
      setBounds(result);
    }

    onResize();

    window.addEventListener("resize", onResize);

    return () => window.removeEventListener("resize", onResize);
  }, [page, module]);

  if (bounds.top === 0) {
    return null;
  }

  const bgColor =
    backgroundColor === "orange" ? "bg-orange-100" : "bg-black-50";

  const insetClasses =
    bounds.height === null ? "inset-y-0" : variants[curveType];

  return (
    <div
      data-testid="bgCurveContainer"
      ref={curveElementRef}
      className={classNames("absolute inset-x-0 pointer-events-none -z-1", {
        "bottom-0": bounds.height === null,
      })}
      style={{ top: bounds.top, height: bounds.height }}
    >
      <div
        data-testid="bgCurve"
        className={classNames("absolute inset-x-0", insetClasses)}
      >
        <BgCurveAnimation>
          <div className="flex flex-col h-full">
            <BgCurveMask testid="bgCurveTop" bgColor={bgColor} />
            <div
              data-testid="bgCurveMiddle"
              className={classNames("flex-1", bgColor)}
            ></div>
            <BgCurveMask testid="bgCurveBottom" bgColor={bgColor} />
          </div>
        </BgCurveAnimation>
      </div>
    </div>
  );
}

export { BgCurve };
