import { find } from "lodash-es";
import { compareRegistryAddresses } from "helpers/addressHelper";

export function mergeElecAndGasIcpData(icpData, elecRegistryItem = null) {
  const {
    result: { gasList },
  } = icpData;

  const defaultGasRegistryItem = {
    ICPIdentifier: "",
    registryAddress: {},
  };

  const elecIcp = elecRegistryItem
    ? elecRegistryItem.ICPIdentifier
    : icpData.elecIcp;

  const elecRegistryAddress = elecRegistryItem
    ? elecRegistryItem.registryAddress
    : icpData.elecRegistryAddress;

  const gasRegistryItem =
    find(gasList, ({ registryAddress }) =>
      compareRegistryAddresses(elecRegistryAddress, registryAddress),
    ) || defaultGasRegistryItem;

  const gasRegistryAddress = {
    ...icpData.gasRegistryAddress,
    ...gasRegistryItem.registryAddress,
  };

  return {
    ...icpData,
    elecIcp,
    elecRegistryAddress,
    gasIcp: gasRegistryItem.ICPIdentifier,
    gasRegistryAddress,
  };
}
