import { runAsync } from "helpers";

function getReferenceNameByTopic(topic) {
  const lowerCasedTopicName = topic?.fields?.title?.toLowerCase();
  return lowerCasedTopicName === "market announcement"
    ? "geannouncements"
    : "gereports";
}

async function getCustomInitialProps({ agility, languageCode, item }) {
  const { topic } = item.fields;

  const referenceName = getReferenceNameByTopic(topic);

  const [data, error] = await runAsync(() =>
    agility.getContentList({
      referenceName,
      languageCode,
      take: 4,
      sort: "fields.publishDate",
      direction: agility.types.SortDirections.DESC,
      filters: [
        {
          property: "fields.tag_ValueField",
          operator: agility.types.FilterOperators.EQUAL_TO,
          value: topic.contentID,
        },
      ],
    }),
  );

  if (error) {
    return [];
  }

  const { items, totalCount } = data;

  if (totalCount === 0) {
    return [];
  }

  return items;
}

export { getCustomInitialProps };
