import { Image as ImageComponent } from "components/Image";

function Image({ data }) {
  const { caption, file } = data;

  if (!file.url) {
    return null;
  }

  return (
    <figure className="md:grid md:gap-x-7 md:grid-cols-24 lg:gap-x-8">
      <div className="md:col-span-16 md:col-start-1">
        <div className="mb-3 overflow-hidden rounded-4 sm:max-w-full md:mb-5 md:rounded-7">
          <ImageComponent
            alt={caption}
            src={file.url}
            width={file.size?.width ?? 0}
            height={file.size?.height ?? 0}
            layout="responsive"
          />
        </div>
      </div>
      <div className="md:col-end-14 md:col-start-2">
        <figcaption className="flex">
          <div className="shrink-0 w-0.5 bg-orange-primary rounded"></div>
          <span className="pl-5 text-black-primary text-16/24">{caption}</span>
        </figcaption>
      </div>
    </figure>
  );
}

export { Image };
