import classNames from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import { ArrowLeft } from "assets/icons";
import { Link } from "components/Link";
import {
  selectPreviousNav,
  selectTopLevelNav,
  useNavigation,
} from "providers/Navigation";

function TopLevelNav() {
  const {
    state: { topLevelNav, previousNav },
    dispatch,
  } = useNavigation();

  function onTopLevelNavClick(navData) {
    selectTopLevelNav({ dispatch, payload: navData });
  }

  function onBackButtonClick(e) {
    e.preventDefault();
    selectPreviousNav({ dispatch });
  }

  return (
    <AnimatePresence exitBeforeEnter>
      {previousNav.length === 0 ? (
        <motion.nav
          initial={{
            x: 50,
            opacity: 0,
          }}
          animate={{
            x: 0,
            opacity: 1,
          }}
          exit={{
            x: -50,
            opacity: 0,
          }}
          transition={{ type: "tween", duration: 0.15 }}
          className="pb-5 pt-7"
          key="topLevelNav"
        >
          <ul className="flex flex-wrap">
            {topLevelNav.map((navigationItem) => {
              return (
                <li key={navigationItem.name}>
                  <Link href={navigationItem.path}>
                    <a
                      onClick={(e) => {
                        e.preventDefault();
                        onTopLevelNavClick(navigationItem);
                      }}
                      className={classNames(
                        "block px-1 py-1 text-black-primary text-2xs rounded",
                        {
                          "bg-black-50 font-semibold is-selected":
                            navigationItem.isSelected,
                        },
                      )}
                    >
                      {navigationItem.menuText}
                    </a>
                  </Link>
                </li>
              );
            })}
          </ul>
        </motion.nav>
      ) : (
        <motion.button
          initial={{
            x: 50,
            opacity: 0,
          }}
          animate={{
            x: 0,
            opacity: 1,
          }}
          exit={{
            x: -50,
            opacity: 0,
          }}
          transition={{ type: "tween", duration: 0.15 }}
          className="flex items-center w-full text-xs text-left py-7"
          key="backButton"
          onClick={onBackButtonClick}
        >
          <ArrowLeft className="w-4 mr-2" />
          <span className="flex-1">Back</span>
        </motion.button>
      )}
    </AnimatePresence>
  );
}

export { TopLevelNav };
