import React from "react";
import classNames from "classnames";
import { CallToAction } from "components/CallToAction";
import { Image } from "components/Image";
import { ModuleWrapper } from "components/Wrappers";
import { THEME, VARIANT } from "helpers/constants";
import { getContentWithLineBreaks } from "helpers/htmlHelper";
import {
  FadeInBottomOnScroll,
  FadeInOnScroll,
  RotateOnScroll,
} from "./animations";

const variantStyles = (theme, imagestyle) => ({
  background: {
    "shadow-white-glow bg-day-gradient": theme === THEME.orange,
    "shadow-white-glow backdrop-blur-4xl bg-glass-grey": theme === THEME.glass,
  },
  text: {
    "text-white": theme === THEME.orange,
    "text-dark-primary": theme === THEME.glass,
  },
  image:
    imagestyle === "icon"
      ? "self-center sm:pb-10 md:py-0 md:my-5 lg:my-10"
      : "self-end",
});

function BannerFloatingImage({ module }) {
  const { anchor, cta1, cta2, description, image, imagestyle, theme, title } =
    module.fields;
  image.height = image?.height ?? 840;
  image.width = image?.width ?? 840;

  return (
    <ModuleWrapper psnid="psn_banner_floating_image">
      <FadeInOnScroll>
        <div
          className={classNames(
            "rounded-7 md:rounded-10 md:flex md:min-h-83 lg:min-h-105",
            variantStyles(theme, imagestyle).background,
          )}
          id={anchor}
        >
          <div className="p-5 pt-10 sm:px-10 md:p-15 md:flex-1 md:self-center">
            <FadeInBottomOnScroll>
              <h2
                className={classNames(
                  "mb-2 sm:mb-3 font-bold text-27/32 md:text-32/36 md:mb-5",
                  variantStyles(theme, imagestyle).text,
                )}
              >
                {title}
              </h2>
              {description && (
                <p
                  className={classNames(
                    "text-18/28 md:text-20/32 md:mb-10",
                    variantStyles(theme, imagestyle).text,
                  )}
                >
                  {getContentWithLineBreaks(description)}
                </p>
              )}
            </FadeInBottomOnScroll>
            {(cta1 || cta2) && (
              <div className="flex flex-wrap gap-5 mt-8 md:mt-10">
                {cta1 && (
                  <FadeInBottomOnScroll>
                    <CallToAction
                      data={cta1}
                      dataGa={{
                        content_tertiary: title,
                        event: "cta_click",
                        event_category: "cta",
                        event_label: [cta1?.text],
                      }}
                      variant={
                        theme === THEME.orange ? VARIANT.light : VARIANT.orange
                      }
                    />
                  </FadeInBottomOnScroll>
                )}
                {cta2 && (
                  <FadeInBottomOnScroll isSecondary>
                    <CallToAction
                      data={cta2}
                      dataGa={{
                        content_tertiary: title,
                        event: "cta_click",
                        event_category: "cta",
                        event_label: [cta2?.text],
                      }}
                      variant={
                        theme === THEME.orange ? VARIANT.light : VARIANT.dark
                      }
                      outline
                    />
                  </FadeInBottomOnScroll>
                )}
              </div>
            )}
          </div>
          <div
            className={classNames(
              "max-w-full mr-5 ml-auto w-60 sm:mr-10 md:mr-10 md:ml-0 md:w-75 lg:w-85 lg:mr-25",
              variantStyles(theme, imagestyle).image,
            )}
          >
            <RotateOnScroll imageStyle={imagestyle}>
              <Image
                src={image.url}
                alt={image.label}
                height={image.height}
                width={image.width}
                layout="responsive"
              />
            </RotateOnScroll>
          </div>
        </div>
      </FadeInOnScroll>
    </ModuleWrapper>
  );
}

export { BannerFloatingImage };
