import { runAsync } from "helpers";
import { compact } from "lodash-es";
import { getContentListAll } from "helpers/agilityHelper";

const formatResponse = (data, topics, allButton) => {
  const accordionItems = topics
    .map((topicName) => {
      const accordionPanel = data.gefaqs
        .filter((item) => {
          const multipleTopics = item.fields.topicMultiple ?? [];
          const multipleTopicTitles = multipleTopics.map(
            (topic) => topic?.fields?.title,
          );
          const singleTopic = item.fields.topic?.fields.title;
          const allTopics = compact([...multipleTopicTitles, singleTopic]);
          return allTopics.includes(topicName);
        })
        .map((item) => ({
          contentId: item.contentID,
          title: item.fields.title,
          slug: item.fields.slug,
          sections: item.fields.section_ValueField
            ? item.fields.section_ValueField.split(",").map(Number)
            : [],
        }));

      return {
        accordionHeader: topicName,
        accordionPanel,
      };
    })
    .filter((item) => item.accordionPanel.length); // some topics might not have faqs

  const sections = data.unisection.map((item) => ({
    sectionId: item.contentID,
    title: item.fields.title,
  }));

  const accordionIndices = accordionItems.map((_item, index) => index);

  if (allButton) {
    sections.unshift({ sectionId: 0, title: allButton });
  }

  return {
    accordionItems,
    sections,
    accordionIndices,
  };
};

async function getFaqListingProps({ item: module, languageCode, agility }) {
  // baseline all data against topics

  if (!module.fields.topics) return {};

  const allButton = module.fields?.allbutton;
  const topics = module.fields.topics.map((item) => item.fields.title);

  try {
    const [unisection] = await runAsync(() =>
      agility.getContentList({ referenceName: "unisection", languageCode }),
    );

    const gefaqs = await getContentListAll(agility, {
      referenceName: "gefaqs",
      languageCode,
    });

    const data = {
      unisection: unisection?.items,
      gefaqs: gefaqs?.items,
    };

    return formatResponse(data, topics, allButton);
  } catch (ex) {
    return {};
  }
}

export { getFaqListingProps, formatResponse };
