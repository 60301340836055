export const TitleDescriptionSection = ({ title, description }) => {
  if (!title && !description) return null;

  return (
    <div
      data-testid="title-description-section"
      className="absolute bottom-0 left-0 right-0 p-4 space-y-1 text-white pt-7 rounded-b-7 bg-textoverlay-gradient md:p5 md:pt-10"
    >
      {title && (
        <span className="block font-bold text-20/24 md:text-24/28">
          {title}
        </span>
      )}
      {description && (
        <span className="block text-14/20 md:text-16/24">{description}</span>
      )}
    </div>
  );
};
