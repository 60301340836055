import { isAfter, parse } from "date-fns";
import { cloneDeep, get, set } from "lodash-es";
import {
  applicableDiscountsForBusinessOffers,
  PRODUCT_ELECTRICITY,
  PRODUCT_GAS,
  PRODUCT_LPG,
} from "helpers/constants";

export function filterBusinessOffers(offers, termPeriod) {
  const now = new Date(Date.now());
  return offers.filter(
    (o) =>
      !isAfter(now, parse(o.effectiveToDate, "yyyy-MM-ddXXX", now)) &&
      o.feesAndTerms.termPeriod === termPeriod &&
      o.priceBook.tariffList.find((t) => t.rateExcludingGST > 0),
  );
}

export function createOfferForSignup(offer) {
  const clonedOffer = cloneDeep(offer);

  const termPeriod = get(clonedOffer, "feesAndTerms.termPeriod", null);

  if (termPeriod === null) {
    // this will create feesAndTerms object if it doesn't exist
    set(clonedOffer, "feesAndTerms.termPeriod", "0");
  }

  return clonedOffer;
}

export function getOfferResultNameByProduct(productName) {
  switch (productName) {
    case PRODUCT_ELECTRICITY:
      return "electricityOffersResult";
    case PRODUCT_GAS:
      return "naturalGasOffersResult";
    case PRODUCT_LPG:
      return "bottledGasOffersResult";
    default:
      throw new Error(
        `can not map "${productName}" to an equivalent offer result key name`,
      );
  }
}

export function getOfferNameByProduct(productName) {
  switch (productName) {
    case PRODUCT_ELECTRICITY:
      return "electricityOffers";
    case PRODUCT_GAS:
      return "naturalGasOffers";
    case PRODUCT_LPG:
      return "bottledGasOffers";
    default:
      throw new Error(
        `can not map "${productName}" to an equivalent offer key name`,
      );
  }
}

export function filterApplicableDiscounts(
  offer,
  isMultiConnect,
  isDirectDebit,
  termPeriod,
) {
  const allDiscounts = get(offer, "discountsAndIncentives.discountList", []);

  if (!allDiscounts.length) {
    return offer;
  }

  const clonedOffer = cloneDeep(offer);
  const applicableDiscountTypes = [];

  if (isMultiConnect) {
    applicableDiscountTypes.push(
      applicableDiscountsForBusinessOffers.multiConnect,
    );
  }

  if (isDirectDebit) {
    applicableDiscountTypes.push(
      applicableDiscountsForBusinessOffers.directDebit,
    );
  }

  if (termPeriod === "24") {
    applicableDiscountTypes.push(
      applicableDiscountsForBusinessOffers.fixedTermDiscount24,
    );
  } else if (termPeriod === "12") {
    applicableDiscountTypes.push(
      applicableDiscountsForBusinessOffers.fixedTermDiscount12,
    );
  }

  const validDiscounts = applicableDiscountTypes.map((type) =>
    allDiscounts.find((discount) => discount.type === type),
  );

  set(
    clonedOffer,
    "discountsAndIncentives.discountList",
    validDiscounts.filter(Boolean),
  );

  return clonedOffer;
}

export const getMeanFixedChargeForSignup = (supplyPoint) => {
  let totalFixedCharges = 0;
  supplyPoint.forEach((supply) => {
    const tariffList = get(supply, "selectedOffer.priceBook.tariffList", []);

    const dailyChargeTariffItem = tariffList.find(
      (tariffItem) =>
        tariffItem.description.toLowerCase().includes("daily fixed") ||
        tariffItem.description
          .toLowerCase()
          .includes("monthly gas bottle rental"),
    );
    if (dailyChargeTariffItem) {
      totalFixedCharges += dailyChargeTariffItem.rateIncludingGST;
    }
  });

  return (totalFixedCharges / supplyPoint.length).toFixed(2);
};
