import classNames from "classnames";
import { Button } from "components/Button";
import { VARIANT } from "helpers/constants";
import { ArticleItem } from "page-modules/ArticleItem";

function ArticleFilterResult({
  paginationText,
  filteredArticles,
  showLoadMoreButton,
  loadMoreArticles,
}) {
  return (
    <>
      <p
        className={classNames(
          "mb-5 font-medium text-18/28 text-dark-primary",
          "lg:mt-10",
        )}
        data-testid="paginationText"
      >
        {paginationText}
      </p>
      <div className="space-y-5 md:space-y-6" data-testid="articleList">
        {filteredArticles.map((articleItem) => (
          <ArticleItem
            key={articleItem.contentID}
            data={articleItem}
            horizontal
          />
        ))}
      </div>
      {showLoadMoreButton && (
        <div
          className={classNames(
            "flex flex-col items-center",
            "pb-5 my-10 space-y-5",
            "md:pb-0 md:my-15",
          )}
        >
          <Button variant={VARIANT.dark} onClick={loadMoreArticles}>
            Load more results
          </Button>
          <p className="text-black-300 text-13/16 md:text-14/20">
            {paginationText}
          </p>
        </div>
      )}
    </>
  );
}

export { ArticleFilterResult };
