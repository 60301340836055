import algoliasearch from "algoliasearch/lite";

function getSearchClient() {
  const searchClient = algoliasearch(
    process.env.NEXT_PUBLIC_ALGOLIA_APP_ID,
    process.env.NEXT_PUBLIC_ALGOLIA_SEARCH,
  );
  return searchClient;
}

export { getSearchClient };
