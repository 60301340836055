import { useEffect, useRef } from "react";
import classNames from "classnames";
import { Image } from "components/Image";
import { CtaSection } from "./CtaSection";
import { TitleDescriptionSection } from "./TitleDescriptionSection";

const calculateScrollSync = (elementRef) => {
  const el = elementRef.current;
  const maxElementScroll = el.scrollWidth - el.clientWidth;
  const clientHeight = el.clientHeight / 2;
  const yPos =
    (el.getBoundingClientRect().top - window.innerHeight + clientHeight) * -1;
  /* istanbul ignore next */
  if (yPos > -100 && yPos < 0 && el.scrollLeft > 0) {
    // 100px buffer before to counteract flick scroll issue
    el.scrollLeft = 0;
  } else if (yPos >= 0 && yPos <= window.innerHeight) {
    // sync vertical scrolling to horizontal
    const percentToScroll = yPos / window.innerHeight;
    el.scrollLeft = maxElementScroll * percentToScroll;
  } /* istanbul ignore next */ else if (
    yPos > window.innerHeight &&
    yPos < window.innerHeight + 100 &&
    el.scrollLeft < maxElementScroll
  ) {
    // 100px buffer after to counteract flick scroll issue
    el.scrollLeft = maxElementScroll;
  }
};

export const MobileTabletView = ({ ctaData, images }) => {
  const elementRef = useRef();
  useEffect(() => {
    const handleScroll = () => calculateScrollSync(elementRef);

    document.addEventListener("scroll", handleScroll);

    return () => document.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <>
      <div
        className="flex mb-10 overflow-x-auto overflow-y-hidden pointer-events-none no-scrollbar gap-x-3 h-68"
        data-testid="multiple-image-mobile"
        ref={elementRef}
      >
        {images.map((imageSnippet) => {
          const key = imageSnippet.contentID;
          const { description, image, title } = imageSnippet.fields;
          return (
            <div
              className="relative first:ml-3 first:sm:ml-15 last:mr-3 last:sm:mr-15 h-68"
              key={`mobile-tablet-image-${key}`}
            >
              <div className="flex flex-col overflow-hidden rounded-7">
                <Image
                  alt={image.label}
                  height={image.h}
                  layout="fixed"
                  src={image.url}
                  width={image.w}
                />
                <TitleDescriptionSection
                  description={description}
                  title={title}
                />
              </div>
            </div>
          );
        })}
      </div>
      <div
        className={classNames(
          "px-4 sm:px-15 md:px-17 lg:px-23 xl:px-46",
          "max-w-full w-120 sm:w-210 md:w-auto mx-auto",
        )}
      >
        <CtaSection ctaData={ctaData} />
      </div>
    </>
  );
};
