import {
  CUSTOMER_TYPE_BUSINESS,
  CUSTOMER_TYPE_RESIDENTIAL,
  PLAN_USAGE_HIGH,
} from "helpers/constants";
import {
  SET_CUSTOMER_TYPE,
  SET_GAS_AVAILABILITY,
  SET_GAS_SELECTION,
  SET_PLAN_USAGE_TYPE,
  SET_PRODUCT_SELECTION,
  SET_RECENTLY_FAILED_API,
  SUBMIT_OCCUPANCY_FORM,
  SUBMIT_PLAN_SELECT_FORM,
  UPDATE_PRODUCT_SELECTION,
} from "./appStateActionTypes";

export function setResidentialCustomerType() {
  return {
    type: SET_CUSTOMER_TYPE,
    payload: CUSTOMER_TYPE_RESIDENTIAL,
  };
}

export function setBusinessCustomerType() {
  return {
    type: SET_CUSTOMER_TYPE,
    payload: CUSTOMER_TYPE_BUSINESS,
  };
}

export function setNaturalGasAvailability(payload) {
  return {
    type: SET_GAS_AVAILABILITY,
    payload,
  };
}

export function setProductSelection(payload) {
  return {
    type: SET_PRODUCT_SELECTION,
    payload,
  };
}

export function updateProductSelection(productsToBeSelected) {
  return {
    type: UPDATE_PRODUCT_SELECTION,
    payload: productsToBeSelected,
  };
}

export function setGasSelection(payload) {
  return {
    type: SET_GAS_SELECTION,
    payload,
  };
}

export function setPlanUsageType(payload) {
  return {
    type: SET_PLAN_USAGE_TYPE,
    payload,
  };
}

export function submitOccupancyForm({ isPrimaryResidence }) {
  return function submitOccupancyFormInner(dispatch) {
    const payload = {
      isPrimaryResidence,
      planUsageType: PLAN_USAGE_HIGH, // always defaults to standard user
    };
    dispatch({ type: SUBMIT_OCCUPANCY_FORM, payload });
  };
}

export function submitPlanSelectForm({ planType, planUsageType }) {
  return {
    type: SUBMIT_PLAN_SELECT_FORM,
    payload: { planType, planUsageType },
  };
}

export function setRecentlyFailedApi(payload) {
  return {
    type: SET_RECENTLY_FAILED_API,
    payload,
  };
}
