import { runAsync } from "helpers";
import { orderBy, uniqBy } from "lodash-es";
import { getContentListAll } from "helpers/agilityHelper";
import { formatDate, tryParseISODate } from "helpers/dateHelper";

function getTagData(tag) {
  return {
    title: tag.fields.title,
    contentID: tag.contentID,
  };
}

function getTopicData(topic) {
  return {
    title: topic.fields.title,
    contentID: topic.contentID,
  };
}

function getArticleItemData(articleItem) {
  const {
    title,
    date,
    slug,
    image = null,
    briefdescription,
    tag,
    tag_TextField = null,
    topic: topics,
    topic_TextField = null,
    topic_ValueField = null,
  } = articleItem.fields;

  const tagSafe = tag ? getTagData(tag) : null;
  const topicsSafe = topics?.map(getTopicData) ?? [];
  const year = formatDate(date, "YYY");
  const month = formatDate(date, "MMMM");

  return {
    contentID: articleItem.contentID,
    fields: {
      title,
      date,
      year,
      month,
      slug,
      image: image ?? null,
      briefdescription,
      tag: tagSafe,
      tag_TextField,
      topics: topicsSafe,
      topic_TextField,
      topic_ValueField,
    },
    properties: {
      referenceName: articleItem.properties.referenceName,
    },
  };
}

async function getCustomInitialProps({ agility, languageCode, item: module }) {
  // getting module data but with reference name for documentstoList instead of the array
  const [moduleData, error] = await runAsync(() =>
    agility.getContentItem({
      contentID: module.contentID,
      languageCode,
      contentLinkDepth: 0,
      expandAllContentLinks: false,
    }),
  );

  if (error) {
    return { articleItems: [] };
  }

  const { referencename } = moduleData.fields.documentstoList;

  // passing the reference name to get all the documentstoList items
  const result = await getContentListAll(agility, {
    referenceName: referencename,
    languageCode,
  });

  if (result.items?.length === 0) {
    return { articleItems: [] };
  }

  const articleItems = result.items.map(getArticleItemData);
  const articleItemsSortedByDate = articleItems.sort(
    (firstArticleItem, secondArticleItem) => {
      const firstArticleDate = tryParseISODate(firstArticleItem.fields.date);
      const secondArticleDate = tryParseISODate(secondArticleItem.fields.date);
      return secondArticleDate - firstArticleDate;
    },
  );

  const allDates = articleItems.map((item) => {
    const parsedDate = tryParseISODate(item.fields.date);
    return {
      date: item.fields.date,
      year: item.fields.year,
      month: item.fields.month,
      yearValue: parsedDate.getFullYear(),
      monthValue: parsedDate.getMonth(),
    };
  });

  const sortedDates = orderBy(
    allDates,
    ["yearValue", "monthValue"],
    ["desc", "asc"],
  );

  const uniqueDates = uniqBy(
    sortedDates,
    (date) => `${date.year}-${date.month}`,
  );

  const uniqueYears = uniqBy(uniqueDates, "year").map((date) => date.year);

  const allTags = articleItems
    .filter((item) => item.fields.tag !== null)
    .map((item) => item.fields.tag);
  const uniqueTags = uniqBy(allTags, (tag) => tag.contentID);

  const allTopics = articleItems.map((item) => item.fields.topics).flat();
  const uniqueTopics = uniqBy(allTopics, (topic) => topic.contentID);

  return {
    articleItems: articleItemsSortedByDate,
    allDates: uniqueDates,
    allYears: uniqueYears,
    tags: uniqueTags,
    topics: uniqueTopics,
  };
}

export { getCustomInitialProps };
