import { AccordionButton, useAccordionItemContext } from "@reach/accordion";
import classNames from "classnames";
import { ChevronDown } from "assets/icons";

function AccordionHeader({ children }) {
  const { isExpanded } = useAccordionItemContext();

  return (
    <h3
      className={classNames("flex text-18/28 transition", "md:text-20/32", {
        "text-black-primary": !isExpanded,
        "text-warm-orange-primary": isExpanded,
      })}
    >
      <AccordionButton
        className={classNames(
          "relative group outline-none",
          "flex items-center justify-between flex-1",
          "py-4 text-left",
          "md:py-5",
        )}
      >
        <span className="font-medium">{children}</span>
        <div className="flex items-center justify-end ml-10">
          <ChevronDown
            className={classNames("w-4.5 h-4.5 transition-transform", {
              "rotate-180": isExpanded,
            })}
          />
        </div>
        <div
          className={classNames(
            "absolute inset-x-0 h-px pointer-events-none -bottom-px transition",
            {
              "bg-black-primary opacity-15 group-hover:opacity-25 group-focus:opacity-25":
                !isExpanded,
              "bg-transparent group-hover:bg-transparent group-focus:bg-transparent":
                isExpanded,
            },
          )}
        />
      </AccordionButton>
    </h3>
  );
}

export { AccordionHeader };
