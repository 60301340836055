import { useState } from "react";
import { setBusinessCustomerType } from "actions/appState/appStateActions";
import { submitBusinessDetailsForm } from "actions/businessStateActions";
import { Formik } from "formik";
import { AnimatePresence, motion } from "framer-motion";
import { useRouter } from "next/router";
import { useDispatch, useSelector } from "react-redux";
import { selectBusinessState } from "reducers/selector";
import * as Yup from "yup";
import { ConditionalForm } from "components/ConditionalForm";
import { Feedback } from "components/Feedback";
import { Modal } from "components/Modal";
import { StepProgressionButton } from "components/StepProgressionButton";
import {
  appBusinessRoutes,
  appRoutes,
  BUSINESS_TYPE_COMPANY,
  BUSINESS_TYPE_OTHER,
  BUSINESS_TYPE_SOLE_TRADER,
} from "helpers/constants";
import { trackBusinessContinueButton } from "helpers/gtmHelper";
import {
  heightFadeTransition,
  heightFadeVariants,
  itemContainer,
  itemFade,
} from "./animations";
import styles from "./business-type.module.scss";
import CompanyForm, { companyFormValidation } from "./CompanyForm";
import OtherForm, { otherFormValidationSchema } from "./OtherForm";
import SoleTraderForm, {
  soleTraderFormValidationSchema,
} from "./SoleTraderForm";

const businessTypeMapForGtm = (businessType) => {
  switch (businessType) {
    case BUSINESS_TYPE_SOLE_TRADER:
      return "sole trader";
    case BUSINESS_TYPE_COMPANY:
      return "company";
    case BUSINESS_TYPE_OTHER:
      return "other";
    default:
      return "";
  }
};

const getBusinessTypeComponent = ({ businessType, formikProps, subtitle }) => {
  switch (businessType) {
    case BUSINESS_TYPE_SOLE_TRADER:
      return <SoleTraderForm content={subtitle} props={formikProps} />;
    case BUSINESS_TYPE_COMPANY:
      return <CompanyForm content={subtitle} props={formikProps} />;
    case BUSINESS_TYPE_OTHER:
      return <OtherForm content={subtitle} props={formikProps} />;
    default:
      return null;
  }
};

function BusinessTypeModule({ module, props }) {
  const { businessTypes } = module.fields;
  const {
    values,
    touched,
    errors,
    handleChange,
    handleSubmit,
    isValid,
    setFieldValue,
  } = props;

  const [businessTypeModalIsVisible, setBusinessTypeModalVisibility] =
    useState(null);
  const [modalInfo, setModalInfo] = useState(null);

  function setBusinessType(type) {
    setFieldValue("businessType", type);
  }

  function handleInfoIconClick({
    businessType,
    overlayTitle,
    overlayDescription,
    overlayButtonLabel,
  }) {
    setBusinessTypeModalVisibility(businessType);
    setModalInfo({
      title: overlayTitle,
      content: overlayDescription,
      buttonLabel: overlayButtonLabel,
    });
  }

  const formikProps = {
    values,
    touched,
    errors,
    handleChange,
  };

  return (
    <motion.div
      className={styles.container}
      exit="undefined"
      data-testid="businessTypePage"
    >
      <motion.div
        initial="initial"
        animate="animate"
        exit="exit"
        variants={itemContainer}
        className={styles.body}
      >
        {businessTypes.map((businessOption) => {
          const {
            title,
            subtitle,
            id,
            overlayTitle,
            overlayDescription,
            overlayButtonLabel,
            enableOverlay,
          } = businessOption.fields;
          const isBusinessTypeSelected = values.businessType === id;

          const infoIcoProps = {
            onInfoIconClick: () =>
              handleInfoIconClick({
                businessType: id,
                overlayTitle,
                overlayDescription,
                overlayButtonLabel,
              }),
          };

          return (
            <motion.div variants={itemFade} className={styles.item} key={id}>
              <ConditionalForm
                id={id}
                title={title}
                isSelected={isBusinessTypeSelected}
                initialHeight={isBusinessTypeSelected ? "auto" : 0}
                onClick={() => setBusinessType(id)}
                showInfoIcon={enableOverlay === "true"}
                size="large"
                {...(enableOverlay === "true" && infoIcoProps)}
              >
                {isBusinessTypeSelected &&
                  getBusinessTypeComponent({
                    businessType: id,
                    formikProps,
                    subtitle,
                  })}
              </ConditionalForm>
            </motion.div>
          );
        })}
      </motion.div>
      <div className="mt-10 md:mt-15 lg:mt-20 overflow-hidden">
        <AnimatePresence>
          {errors.businessType && touched.businessType && (
            <motion.div
              initial="initial"
              animate="animate"
              exit="exit"
              variants={heightFadeVariants}
              transition={heightFadeTransition}
              className="mb-8 md:mb-10"
            >
              <Feedback type="error" title="Error" data-testid="errorMessage">
                Please select an option.
              </Feedback>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
      <StepProgressionButton
        onContinueClick={handleSubmit}
        applyDisabledStyle={!isValid}
        backLink={appRoutes.customerType}
      />

      {modalInfo && (
        <Modal
          onRequestClose={() => setBusinessTypeModalVisibility(null)}
          isOpen={!!businessTypeModalIsVisible}
          title={modalInfo.title}
          buttonText={modalInfo.buttonLabel}
        >
          {modalInfo.content}
        </Modal>
      )}
    </motion.div>
  );
}

const mapPropsToValues = ({ businessState }) => ({
  businessType: businessState.businessType,
  soleTraderTradingAs: businessState.soleTraderTradingAs,
  companyTradingAs: businessState.companyTradingAs,
  otherTradingAs: businessState.otherTradingAs,
  companyNZBN: businessState.companyNZBN,
});

const validationSchema = Yup.object()
  .shape({
    businessType: Yup.string().required("Please select a business type"),
  })
  .concat(companyFormValidation)
  .concat(otherFormValidationSchema)
  .concat(soleTraderFormValidationSchema);

export function BusinessType({ module }) {
  const props = useSelector(selectBusinessState);
  const dispatch = useDispatch();

  const router = useRouter();

  const onSubmit = (values) => {
    dispatch(setBusinessCustomerType());
    dispatch(submitBusinessDetailsForm(values));

    trackBusinessContinueButton({
      event: "select_business_type",
      content_tertiary: "select business type",
      business_type: businessTypeMapForGtm(values.businessType),
    });

    router.push(appBusinessRoutes.address);
  };

  return (
    <Formik
      initialValues={props}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      validateOnMount
      enableReinitialize
    >
      {(formikProps) => (
        <BusinessTypeModule module={module} props={formikProps} />
      )}
    </Formik>
  );
}
