import { useEffect, useRef } from "react";
import classNames from "classnames";
import { AnimatePresence } from "framer-motion";
import { usePageViews } from "helpers/gtmHelper";
import { Sidebar } from "join-form/partials/sidebar";
import { useRouter } from "next/router";
import { hideHeader } from "helpers/layoutHelper";
import { PageLayout } from "./PageLayout";
import { appResidentialRoutes, appBusinessRoutes, appRoutes } from "helpers/constants";
import { useSelector } from "react-redux";
import { selectAppState } from "reducers/selector";
import { fetchAccessToken, getTokenExpiration } from "helpers/apiHelper";
import { isLocalStorageAvailable } from "helpers/localStorage";

export function App({ children }) {
  const { asPath: currentPath, pathname, replace } = useRouter();
  const timeoutRef = useRef(null);
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  usePageViews();

  const { customerType } = useSelector(selectAppState);

  useEffect(() => {
    const proceededMoreThanFirstStep = currentPath !== appRoutes.customerType && currentPath.startsWith(appRoutes.customerType);
    const completedJoinJourney =
      currentPath === appResidentialRoutes.success
      || currentPath === appResidentialRoutes.callbackSuccess
      || currentPath === appBusinessRoutes.success
      || currentPath === appBusinessRoutes.callbackSuccess;

    if (!customerType && proceededMoreThanFirstStep && !completedJoinJourney) {
      replace(appRoutes.customerType);
    }
  }, [customerType, replace, currentPath]);

  useEffect(() => {
    if (!isLocalStorageAvailable()) return;
    const expiryDateInMs = getTokenExpiration().getTime();
    const nowInMs = Date.now() + 1000 * 65; // 65 seconds buffer 
    
    if (expiryDateInMs > nowInMs) {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      const timeDifferenceInMs = expiryDateInMs - nowInMs;
      timeoutRef.current = setTimeout(async () => {
        await fetchAccessToken();
      }, timeDifferenceInMs);
    }

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  return (
    <div
      id="content"
      className={classNames(
        "npa-content-wrapper",
        hideHeader(location) && "npa-content-wrapper-no-header",
      )}
    >
      <PageLayout>
        <Sidebar />
        <AnimatePresence exitBeforeEnter>{children}</AnimatePresence>
      </PageLayout>
    </div>
  );
}

export default App;
