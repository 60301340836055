import { motion } from "framer-motion";

const transition = {
  delay: 0.3,
  type: "spring",
  bounce: 0.3,
  duration: 0.3,
};

function FadeInBottomOnScroll({ children }) {
  return (
    <motion.div
      initial={{ y: 20, opacity: 0 }}
      whileInView={{ y: 0, opacity: 1 }}
      viewport={{ once: true, amount: 0.2 }}
      transition={transition}
    >
      {children}
    </motion.div>
  );
}

export { FadeInBottomOnScroll };
