import { runAsync } from "helpers";

async function getCustomInitialProps({ agility, languageCode, item }) {
  const { articleList, topic: topics } = item.fields;
  const [data, error] = await runAsync(() =>
    agility.getContentList({
      referenceName: articleList || "genewsarticles",
      languageCode,
      take: 4,
      sort: "fields.date",
      direction: agility.types.SortDirections.DESC,
      filtersLogicOperator: agility.types.FilterLogicOperators.OR,
      filters: topics.map((topic) => ({
        property: "fields.tag_ValueField",
        operator: agility.types.FilterOperators.EQUAL_TO,
        value: topic.contentID,
      })),
    }),
  );

  if (error) {
    return [];
  }

  const { items, totalCount } = data;

  if (totalCount === 0) {
    return [];
  }

  return items;
}

export { getCustomInitialProps };
