import { ArticleCarouselSection } from "components/ArticleCarouselSection";
import { ArticleItem } from "page-modules/ArticleItem";
import { getCustomInitialProps } from "./getLatestArticlesProps";

function LatestArticles({ module, customData }) {
  const { title, cta } = module.fields;

  return customData.length ? (
    <ArticleCarouselSection title={title} cta={cta} testid="latestArticles">
      {customData.map((item) => (
        <ArticleItem key={item.contentID} data={item} />
      ))}
    </ArticleCarouselSection>
  ) : null;
}

LatestArticles.getCustomInitialProps = getCustomInitialProps;

export { LatestArticles };
