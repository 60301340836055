import classNames from "classnames";
import { BLOCK_SIZE_CENTERED } from "helpers/blockEditorHelper";
import { DEFAULT_BLOCKS } from "./Blocks";
import { InlineBlockEditor } from "./InlineBlockEditor";
import { JumpToSection } from "./JumpToSection";

function DefaultBlocks({ size, data, jumptoSectionTitle, showJumptoSection }) {
  const isCentered = size === BLOCK_SIZE_CENTERED;

  const editor = (
    <InlineBlockEditor
      data={data}
      testid="blockEditorDefaultGroup"
      blocks={DEFAULT_BLOCKS}
    />
  );

  return (
    <div className="mt-10 mb-10 last:mb-0 first:mt-0">
      <div
        className={classNames(
          "px-4 mx-auto sm:px-15 md:px-17 lg:px-23 xl:px-46",
          "max-w-full w-120 sm:w-210 md:w-auto",
        )}
      >
        {isCentered ? (
          <div className="flex flex-col-reverse md:grid md:gap-x-7 md:grid-cols-24 lg:gap-x-8">
            <div className="md:col-span-14 md:col-start-2">{editor}</div>
            <div className="mb-10 md:col-span-7 md:col-start-18">
              {showJumptoSection === "true" && (
                <JumpToSection
                  {...{ data, jumptoSectionTitle }}
                  testid="jumpToSection"
                />
              )}
            </div>
          </div>
        ) : (
          editor
        )}
      </div>
    </div>
  );
}

export { DefaultBlocks };
