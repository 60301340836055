import axios from "axios";
import Script from "next/script";
import Skeleton from "react-loading-skeleton";
import { useQuery } from "react-query";
import { ModuleWrapper } from "components/Wrappers";
import { BOOK_CALL_STATUS_URL } from "helpers/constants";
import { getContentWithLineBreaks } from "helpers/htmlHelper";

async function getBookCallData() {
  return axios.get(BOOK_CALL_STATUS_URL).then((response) => response.data);
}

function useBookCallQuery() {
  return useQuery("book_call", getBookCallData);
}

function BookCall({ module }) {
  const { description, isBusiness } = module.fields;
  const { isLoading } = useBookCallQuery();

  if (isLoading) {
    return (
      <ModuleWrapper testid="bookCallLoading">
        <Skeleton count={3} />
      </ModuleWrapper>
    );
  }

  return (
    <ModuleWrapper testid="bookCallContent" psnid="psn_book_call">
      <div className="flex flex-col gap-y-5">
        <div className="prose md:prose-md" data-testid="bookCallDescription">
          <p>{getContentWithLineBreaks(description)}</p>
        </div>
        <div className="vht-widget-container">
          {isBusiness === "true" ? (
            <div
              id="vht-widget"
              className="vht-widget"
              data-vht-organization-id="03924e610d6f4bdc"
              data-vht-widget-id="999d1f81a1c254f7fb5e0085aa9d8217"
              data-testid="businessBookCall"
            ></div>
          ) : (
            <div
              id="vht-widget"
              className="vht-widget-intent"
              data-vht-organization-id="03924e610d6f4bdc"
              data-vht-widget-intent-id="7e0f5b4e14c93b81f3b80660c221a82f"
              data-testid="residentialBookCall"
            ></div>
          )}
        </div>
      </div>
      <Script src="https://03924e610d6f4bdc.cbridgert.vhtcloud.com/vht-conversation-bridge-runtime.js"></Script>
    </ModuleWrapper>
  );
}

export { BookCall };
