import classNames from "classnames";
import styles from "./form-controls.module.scss";

export default function Input(props) {
  const {
    size,
    type = "text",
    name,
    placeholder,
    value,
    onChange,
    onKeyDown,
    errorMessage,
    showError,
    className,
    inputClassName,
    maxLength,
    disabled,
    readOnly,
    onBlur,
    isOrangeBackground = false,
  } = props;

  const filledClassName = value ? styles.filled : "";
  const invalidClassName = showError ? styles.error : "";
  const sizeClassName = size ? styles.size28 : "";

  const containerClassName = [
    isOrangeBackground ? styles.controlFormOrange : styles.control,
    filledClassName,
    invalidClassName,
    sizeClassName,
    className,
  ]
    .join(" ")
    .trim();

  const labelClassName = classNames(styles.label, {
    [styles.white_input_label]: isOrangeBackground,
  });

  const inputClasses = classNames(
    styles.input,
    { [styles.input_orange]: isOrangeBackground },
    inputClassName,
  );

  return (
    <div className={containerClassName}>
      <label className={labelClassName} htmlFor={name}>
        {placeholder}
      </label>
      <input
        className={inputClasses}
        name={name}
        id={name}
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onKeyDown={onKeyDown}
        maxLength={maxLength}
        disabled={disabled}
        readOnly={readOnly}
        onBlur={onBlur}
      />
      {showError && (
        <span data-testid={`${name}ErrorMessage`} className={styles.errorText}>
          {errorMessage}
        </span>
      )}
    </div>
  );
}
