import classNames from "classnames";

export default function RatesDisclaimer() {
  return (
    <div className="flex justify-center mt-10">
      <div
        className={classNames(
          "text-12/20 md:text-14/20 text-black-primary",
          "md:px-16",
        )}
      >
        <p>
          All prices exclude any discounts. The prices above are for standard
          residential connections. Any applicable bonus credits include GST, is
          non-transferable and non-refundable. The rates displayed are based on
          Registry information for the address you have provided. Your rates
          will be confirmed in your Welcome Pack.
        </p>
      </div>
    </div>
  );
}
