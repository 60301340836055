import { Download, ExternalLink, VideoPlayBlack } from "assets/icons";
import { Button } from "components/Button";
import { DOCUMENT_ACTION_TYPES, VARIANT } from "helpers/constants";

const buttonProps = {
  [DOCUMENT_ACTION_TYPES.video]: {
    IconComponent: VideoPlayBlack,
  },
  [DOCUMENT_ACTION_TYPES.file]: {
    IconComponent: Download,
  },
  [DOCUMENT_ACTION_TYPES.external]: {
    IconComponent: ExternalLink,
  },
};

function DocumentActionButton({ type, url, buttonLabel }) {
  const props = buttonProps[type] ?? buttonProps.file;
  const { IconComponent } = props;

  if (!url.href) return null;

  return (
    <div>
      <Button
        size="medium"
        variant={VARIANT.darkTransparent}
        href={url.href}
        prefetch={false}
        target={url.target}
        data-testid="documentIconButton"
      >
        <span className="hidden md:block">{buttonLabel}</span>
        <IconComponent className="w-5 h-5 fill-current" />
      </Button>
    </div>
  );
}

export { DocumentActionButton };
