import { useState } from "react";
import classNames from "classnames";
import Accordion from "join-form/components/accordion";

const fuelRatesContent = {
  Electricity: "Electricity rates",
  "Natural Gas": "Natural Gas rates",
  "Bottled Gas (LPG)": "Bottled Gas prices",
};

export default function EnergyRatePlaceholderContainer(props) {
  const { icon, title, children, visible, onToggle, ...rest } = props;

  const [ratesVisibleState, toggleRatesState] = useState(true);
  const isControlledComponent = !!onToggle;

  return (
    <Accordion
      title={title}
      icon={icon}
      isOpen={isControlledComponent ? visible : ratesVisibleState}
      onClick={() =>
        isControlledComponent
          ? onToggle(!visible)
          : toggleRatesState(!ratesVisibleState)
      }
      className={classNames(
        "px-4 py-5",
        "border-2 border-blue-primary",
        "bg-blue-100",
        "rounded-5",
      )}
      isBlackVariant
      {...rest}
    >
      We couldn't find your {fuelRatesContent[title]} for this plan. We'll
      confirm these later.
    </Accordion>
  );
}
