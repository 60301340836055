export function isResiJourneyDisabled() {
  const { NEXT_PUBLIC_RESI_JOURNEY_DISABLED } = process.env;
  const result = NEXT_PUBLIC_RESI_JOURNEY_DISABLED ? true : false;
  return result;
}

export function getBusinessRoutePrefix() {
  const businessRoutePrefix = isResiJourneyDisabled() ? "" : "/business";
  return businessRoutePrefix;
}
