import { useQuery } from "@tanstack/react-query";
import Axios from "axios";
import { API_ENDPOINTS } from "helpers/constants";
import { getRequestHeaders } from "./npaAuth";

function createUrlWithParams(url, params) {
  const result = Object.entries(params).reduce((acc, [key, value]) => {
    acc = acc.replaceAll(`:${key}`, value);
    return acc;
  }, url);

  return result;
}

async function getRequestConfig() {
  const headers = await getRequestHeaders();
  const requestConfig = {
    headers,
  };
  return requestConfig;
}

async function getCarRegistration({ queryKey }) {
  const [, plate] = queryKey;
  const params = { plate };

  const url = createUrlWithParams(API_ENDPOINTS.carRegistration, params);
  const requestConfig = await getRequestConfig();

  const result = await Axios.get(url, requestConfig).then(({ data }) => data);

  if (result.isEligibleForEv) {
    return result;
  }

  return Promise.reject(result);
}

function useGetCarRegistration(plate, { initialData, ...options }) {
  const queryOptions = {
    enabled: false,
    retry: false,
    initialData: initialData?.isEligibleForEv ? initialData : null,
    ...options,
  };

  const { data, error, ...queryResult } = useQuery(
    ["carRegistration", plate],
    getCarRegistration,
    queryOptions,
  );

  const isSuccess = data?.isEligibleForEv ?? false;

  return {
    ...queryResult,
    data,
    isSuccess,
    error,
  };
}

export { useGetCarRegistration };
