import { get } from "lodash-es";
import { PRODUCT_ELECTRICITY, PRODUCT_GAS } from "helpers/constants";
import { getFullRegistryAddress } from "./addressHelper";

function getMeterId(registryData) {
  return get(registryData, "meter[0].id", "");
}

function getRegistryAddress(registryData) {
  if (registryData) {
    const { registryAddress } = registryData;
    const fullAddress = getFullRegistryAddress(registryAddress);
    return {
      ...registryAddress,
      fullAddress,
    };
  }

  return null;
}

function getElecIcpDetails(formValues) {
  const { elecIcp, elecRegistryData, selectedFuelTypes } = formValues;

  const includesElectricity = selectedFuelTypes.includes(PRODUCT_ELECTRICITY);

  if (!includesElectricity) {
    return {
      elecIcp: "",
      elecMeterId: "",
      elecRegistryAddress: null,
    };
  }

  const elecRegistryAddress = getRegistryAddress(elecRegistryData);

  return {
    elecIcp,
    elecMeterId: getMeterId(elecRegistryData),
    elecRegistryAddress,
  };
}

function getGasIcpDetails(formValues) {
  const { gasIcp, gasRegistryData, selectedFuelTypes } = formValues;

  const includesGas = selectedFuelTypes.includes(PRODUCT_GAS);

  if (!includesGas) {
    return {
      gasIcp: "",
      gasMeterId: "",
      gasRegistryAddress: null,
    };
  }

  const gasRegistryAddress = getRegistryAddress(gasRegistryData);

  return {
    gasIcp,
    gasMeterId: getMeterId(gasRegistryData),
    gasRegistryAddress,
  };
}

function getIcpDetails(formValues) {
  const elecDetails = getElecIcpDetails(formValues);
  const gasDetails = getGasIcpDetails(formValues);

  return {
    ...elecDetails,
    ...gasDetails,
  };
}

export { getIcpDetails };
