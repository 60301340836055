import classNames from "classnames";
import Icon from "join-form/components/icon";
import styles from "./form-field-term.module.scss";

export default function FormFieldTerm(props) {
  const {
    name,
    value,
    checked,
    className = "",
    shadowless = false,
    testId,
    onChange,
    children,
    errorMessage,
    showError,
    size,
    textSize = "",
  } = props;

  const iconName = checked ? "CheckboxOn" : "CheckboxOff";

  const checkboxClassNames = classNames(
    styles.checkbox,
    styles[size],
    {
      [styles.checked]: checked,
      [styles.shadowless]: shadowless,
    },
    className,
  );

  return (
    <div className={styles.container}>
      <label className={checkboxClassNames}>
        <input
          name={name}
          id={name}
          type="checkbox"
          className={styles.input}
          checked={checked}
          onChange={onChange}
          value={value}
          data-testid={testId}
        />
        <span className={styles.icon_container}>
          <Icon size="24" name={iconName} className={styles.icon_unselected} />
        </span>
        <span
          className={classNames(styles.text, {
            [styles.smallText]: textSize === "small",
          })}
        >
          {children}
        </span>
      </label>
      {showError && (
        <span data-testid={`${name}ErrorMessage`} className={styles.errorText}>
          {errorMessage}
        </span>
      )}
    </div>
  );
}
