import { compact, take } from "lodash-es";
import { ArticleCarouselSection } from "components/ArticleCarouselSection";
import { ArticleItem } from "page-modules/ArticleItem";

function RelatedArticles({ module }) {
  const { title, cta, relatedArticles: relatedArticlesGroup } = module.fields;

  if (!relatedArticlesGroup) {
    return null;
  }

  const relatedArticles = relatedArticlesGroup
    .map(({ fields }) => fields.relatedArticles)
    .flat();

  const topFourRelatedArticles = take(compact(relatedArticles), 4);

  return topFourRelatedArticles.length ? (
    <ArticleCarouselSection
      title={title}
      cta={cta}
      testid="relatedArticles"
      psnid="psn_related_articles"
    >
      {topFourRelatedArticles.map((item) => (
        <ArticleItem
          testid="relatedArticleItem"
          key={item.contentID}
          data={item}
        />
      ))}
    </ArticleCarouselSection>
  ) : null;
}

export { RelatedArticles };
