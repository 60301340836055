import React from "react";
import { createSelector } from "@reduxjs/toolkit";
import { clearState } from "actions/clearStateActions";
import classNames from "classnames";
import { motion } from "framer-motion";
import { useScreenSize } from "hooks";
import { Alert } from "join-form/components/alert";
import Intro from "join-form/components/intro";
import { NextSteps } from "join-form/components/nextSteps";
import { Rates } from "join-form/partials/rates/residential";
import { SidebarHeader } from "join-form/partials/sidebar/residential";
import { sortBy } from "lodash-es";
import { useDispatch, useSelector } from "react-redux";
import { useEffectOnce } from "react-use";
import {
  selectAppState,
  selectAppUserState,
  selectGtmState,
  selectResidentialPlanConfig,
} from "reducers/selector";
import { InlineBlockEditor } from "components/BlockEditor";
import { shouldRedirectToResidentialCallback } from "helpers/callbackHelper";
import {
  links,
  PAYMENT_METHOD_CREDIT_CARD,
  PLAN_TYPE_BASIC,
  PLAN_TYPE_EV_PLUS,
  PLAN_TYPE_PLUS,
  RESIDENTIAL_PLAN_TERM_FIXED12,
} from "helpers/constants";
import { getPageData } from "helpers/getPageData";
import {
  trackResidentialCallbackSuccessPageLoad,
  trackResidentialSuccessPageLoad,
} from "helpers/gtmHelper";
import { getPlanTypeByTerm } from "helpers/plansHelper";
import { CardItem } from "page-modules/CtaCardStatic/CardItem";
import {
  footerFade,
  footerFadeTransition,
  headerFade,
  itemContainerFade,
  itemFade,
} from "./animations";
import styles from "./success-page.module.scss";
import { SuccessPageFeatures } from "./SuccessPageFeatures";

function getDescription({
  planType,
  isExistingEVCustomer,
  contentText,
  evExistingCustomerContentText,
  evNewCustomerContentText,
}) {
  if (isExistingEVCustomer) {
    return evExistingCustomerContentText;
  }

  if (planType === PLAN_TYPE_EV_PLUS) {
    return evNewCustomerContentText;
  }

  return contentText;
}

const getFinePrintTexts = (planType, termItem, paymentMethod) => {
  const finePrintText = [];

  if (termItem.codeName === RESIDENTIAL_PLAN_TERM_FIXED12) {
    if (planType === PLAN_TYPE_PLUS) {
      finePrintText.push(
        "*A $150 exit fee (per fuel type) applies to fixed-term plans. This offer is only available for online signup.",
      );
    } else {
      finePrintText.push(
        "*A $150 exit fee (per fuel type) applies to fixed-term plans.",
      );
    }
  }

  if (planType === PLAN_TYPE_BASIC) {
    if (paymentMethod === PAYMENT_METHOD_CREDIT_CARD) {
      finePrintText.push(
        "**1.0% transaction fee applies on credit card payments.",
      );
    }
  } else {
    finePrintText.push(
      <>
        **Learn more about{" "}
        <a
          className={styles.small_print_link}
          href={links.powerShout}
          target="_blank"
          rel="noreferrer"
        >
          Power Shout
        </a>
      </>,
    );
  }

  return finePrintText;
};

const getCards = ({ planType, basicPlanCards, evPlanCards, plusPlanCards }) => {
  switch (planType) {
    case PLAN_TYPE_BASIC:
      return basicPlanCards;
    case PLAN_TYPE_PLUS:
      return plusPlanCards;
    case PLAN_TYPE_EV_PLUS:
      return evPlanCards;
    default:
      return basicPlanCards;
  }
};

const selectResidentialSuccessState = createSelector(
  [
    selectAppState,
    selectAppUserState,
    selectGtmState,
    selectResidentialPlanConfig,
  ],
  (appState, appUserState, gtmState, residentialPlanConfig) => {
    const { planType, planUsageType, recentlyFailedApi } = appState;
    const { paymentMethod, isExistingCustomer } = appUserState;
    const termItem =
      residentialPlanConfig.termData.find(({ isSelected }) => isSelected) || {};

    const selectedTermCodeName = termItem.codeName ?? "";

    const ratesHeadingPlanType = getPlanTypeByTerm({
      selectedTermCodeName,
      planType,
    });

    const isCallbackSuccess =
      shouldRedirectToResidentialCallback(recentlyFailedApi);

    return {
      termItem,
      paymentMethod,
      planType,
      ratesHeadingPlanType,
      planUsageType,
      isCallbackSuccess,
      isExistingEVCustomer:
        planType === PLAN_TYPE_EV_PLUS && isExistingCustomer === "true",
      residentialEcommerce: gtmState.residentialEcommerce,
    };
  },
);

const modules = [
  "GE_JOIN_Success_Header",
  "GE_JOIN_EV_Customer_Success_Header",
  "GE_JOIN_Residential_Success_Module",
  "GE_JOIN_Help_Live_Chat",
  "GE_JOIN_Help_Phone",
  "GE_CTA_Card_Static",
  "GE_JOIN_Next_Step",
  "GE_JOIN_Residential_Next_Step_Cards",
  "GE_CTA_Card_Static",
];

const getBackgroundImage = (image, mobileImage, { isSm }) =>
  isSm ? `url(${image?.url})` : `url(${mobileImage?.url})`;

function SuccessPage(props) {
  const pageData = getPageData({ modules, page: props.page });

  const {
    image,
    mobileImage,
    logo,
    text,
    evHeaderImage,
    evHeaderMobileImage,
    evHeaderText,
    contentTitle,
    contentText,
    evExistingCustomerContentText,
    evNewCustomerContentText,
    helpSectionText,
    helpSectionTitle,
    liveChatHelpText,
    phoneHelpText,
    nextStepTitle,
    basicPlanCards,
    evPlanCards,
    plusPlanCards,
    cards,
    note,
  } = pageData.fields;

  const screen = useScreenSize();

  const {
    termItem,
    paymentMethod,
    planType,
    ratesHeadingPlanType,
    planUsageType,
    isCallbackSuccess,
    isExistingEVCustomer,
    residentialEcommerce,
  } = useSelector(selectResidentialSuccessState);

  const {
    selectedProducts,
    selectedTermName,
    meanFixedCharges,
    postalCode,
    transactionId,
    selectedPlanType,
    energyUsageType,
    householdSize,
    isExistingCustomerSignup,
  } = residentialEcommerce;

  const dispatch = useDispatch();

  const finePrintTexts = getFinePrintTexts(planType, termItem, paymentMethod);

  const description = getDescription({
    planType,
    isExistingEVCustomer,
    contentText,
    evExistingCustomerContentText,
    evNewCustomerContentText,
  });

  const isEVPlan = planType === PLAN_TYPE_EV_PLUS;

  const headerBackgroundImage = isEVPlan
    ? getBackgroundImage(evHeaderImage, evHeaderMobileImage, screen)
    : getBackgroundImage(image, mobileImage, screen);

  React.useEffect(() => {
    if (window.dataLayer) {
      if (isCallbackSuccess) {
        trackResidentialCallbackSuccessPageLoad({
          selectedProducts,
        });
      } else {
        trackResidentialSuccessPageLoad({
          selectedProducts,
          selectedTermName,
          meanFixedCharges,
          postalCode,
          transactionId,
          selectedPlanType,
          energyUsageType,
          householdSize,
          isExistingCustomerSignup,
        });
      }
    }
  }, [
    selectedProducts,
    selectedTermName,
    meanFixedCharges,
    postalCode,
    transactionId,
    selectedPlanType,
    energyUsageType,
    householdSize,
    isCallbackSuccess,
    isExistingCustomerSignup,
  ]);

  useEffectOnce(() => {
    // clear signup data
    dispatch(clearState());
  });

  const nextStepCards = isCallbackSuccess
    ? cards
    : getCards({ planType, basicPlanCards, evPlanCards, plusPlanCards });

  const sortedCards = sortBy(nextStepCards, ["properties.itemOrder"]);

  return (
    <div
      className={classNames("flex flex-col flex-1", "space-y-10 sm:space-y-15")}
    >
      <motion.div
        className={styles["header-wrapper"]}
        initial="initial"
        animate="animate"
        exit="exit"
        variants={headerFade}
      >
        <div
          className={classNames(styles["header-background"])}
          style={{ backgroundImage: headerBackgroundImage }}
        >
          <a href="/" data-testid="homePage">
            <img
              className={styles["header-logo"]}
              src={logo.url}
              alt={logo.label}
            />
          </a>
          <h1 className={styles["header-text"]} data-testid="heading">
            <InlineBlockEditor
              jsonString={isEVPlan ? evHeaderText : text}
              className="prose-p:text-50/60 prose-p:md:text-80/96 prose-p:text-white"
            />
          </h1>
        </div>
      </motion.div>
      <div className="px-4 sm:px-12 md:px-27">
        <motion.div
          className={classNames("w-137 max-w-full mx-auto", "md:w-306")}
          data-testid="successPage"
        >
          <motion.div
            initial="initial"
            animate="animate"
            exit="exit"
            variants={itemContainerFade}
            className={classNames(
              "flex flex-col-reverse mx-auto max-w-full",
              "md:grid md:grid-cols-12 gap-x-6",
            )}
          >
            <motion.div variants={itemFade} className="md:col-span-7">
              {isExistingEVCustomer && (
                <div className="mt-10 mb-0 md:mb-10 md:mt-0">
                  <Alert
                    fields={{
                      copy: note,
                    }}
                  />
                </div>
              )}
              <Intro className="mt-15 md:mt-0" noStyle>
                {(introStyles) => (
                  <>
                    <h1 className={introStyles.title}>{contentTitle}</h1>

                    <p className={introStyles.text}>{description}</p>
                    <h2 className={introStyles.section_title}>
                      {helpSectionTitle}
                    </h2>
                    <p className={introStyles.text}>{helpSectionText}</p>
                    <ul className={introStyles.link_list}>
                      <li className={introStyles.link_list_item}>
                        <InlineBlockEditor
                          jsonString={liveChatHelpText}
                          className="prose-p:text-14/20 prose-p:md:text-16/24 prose-a:no-underline"
                        />
                      </li>
                      <li className={introStyles.link_list_item}>
                        <InlineBlockEditor
                          jsonString={phoneHelpText}
                          className="prose-p:text-14/20 prose-p:md:text-16/24 prose-a:no-underline"
                        />
                      </li>
                    </ul>
                  </>
                )}
              </Intro>
            </motion.div>
            {!isCallbackSuccess && (
              <motion.div
                variants={itemFade}
                className="md:col-start-9 md:col-span-4"
              >
                <div
                  className={classNames(
                    "flex flex-col space-y-5",
                    "mb-10 md:mb-15",
                  )}
                >
                  <SidebarHeader />
                  <SuccessPageFeatures />
                </div>
                <Rates
                  planType={planType}
                  ratesHeadingPlanType={ratesHeadingPlanType}
                  usageType={planUsageType}
                  data-testid="ratesAccordion"
                  className="mb-5"
                />
                <div className="space-y-5">
                  <p
                    className={classNames(
                      "text-13/20 md:text-14/20 text-black-400",
                    )}
                  >
                    Discounts are not included in the rates above, they will be
                    applied to your total bill.
                  </p>
                  {finePrintTexts?.map((text, index) => (
                    <p
                      key={index}
                      className="text-14/20 text-black-400"
                      data-testid={`smallPrint${index}`}
                    >
                      {text}
                    </p>
                  ))}
                </div>
              </motion.div>
            )}
          </motion.div>
          <motion.hr
            initial="initial"
            animate="animate"
            exit="exit"
            variants={footerFade}
            transition={footerFadeTransition}
            className={styles.separator}
          />
          <NextSteps title={nextStepTitle}>
            {sortedCards.map((item, index) => {
              const isEven = (index + 1) % 2 === 0;
              return (
                <CardItem
                  key={item.contentID}
                  item={item}
                  isEven={isEven}
                  className="md:col-span-8"
                />
              );
            })}
          </NextSteps>
        </motion.div>
      </div>
    </div>
  );
}

export default SuccessPage;
