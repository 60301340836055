import classNames from "classnames";
import { getShortAddressByType } from "helpers/addressHelper";
import { connect } from "react-redux";

function TermHeader({ shortAddress, className }) {
  return (
    <h4 className={classNames("text-15/24 md:text-18/28", className)}>
      Your Business Energy Plan for {shortAddress}
    </h4>
  );
}

const mapStateToProps = (state, ownProps) => {
  const {
    addressState: {
      addressType,
      addressDetails,
      registryAddressDetails,
      manualAddressDetails,
    },
  } = state;
  const shortAddress = getShortAddressByType({
    addressType,
    addressDetails,
    registryAddressDetails,
    manualAddressDetails,
  });
  return { shortAddress };
};

export default connect(mapStateToProps)(TermHeader);
