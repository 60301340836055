import classNames from "classnames";
import { InlineBlockEditor } from "components/BlockEditor";
import { getBlocks } from "helpers/blockEditorHelper";

function FinePrint({ module }) {
  const blocks = getBlocks(module.fields.blockEditor);
  return blocks.length ? (
    <div
      className={classNames(
        "px-4 mx-auto mb-5 sm:px-15 md:px-20 lg:px-23 xl:px-46",
        "max-w-full w-120 sm:w-210 md:w-auto",
      )}
      data-psnid="psn_fine_print"
    >
      <div
        data-testid="blockEditor"
        className="bg-white border p-7 text-18/28 rounded-5 border-black-100 md:text-20/32 md:p-10"
      >
        <InlineBlockEditor data={blocks} />
      </div>
    </div>
  ) : null;
}

export { FinePrint };
