import { get } from "lodash-es";
import {
  PAYMENT_METHOD_CREDIT_CARD,
  PAYMENT_METHOD_DIRECT_DEBIT,
  PAYMENT_METHOD_MANUAL,
  PLAN_TYPE_EV_PLUS,
  PLAN_USAGE_LOW,
  RESIDENTIAL_PLAN_TERM_FIXED12,
} from "helpers/constants";
import {
  trackBusinessContinueButton,
  trackResidentialContinueButton,
} from "helpers/gtmHelper";
import { getMeanFixedChargeForSignup } from "helpers/offersHelper";
import { getPlanTerms } from "join-form/pages/residential/payment/PaymentPage";
import {
  SET_ECOMMERCE_OBJECT_BUSINESS,
  SET_ECOMMERCE_OBJECT_RESIDENTIAL,
} from "./gtmActionTypes";

export function setBusinessEcommerceObject(payload) {
  return { type: SET_ECOMMERCE_OBJECT_BUSINESS, payload };
}

export function setResidentialEcommerceObject(payload) {
  return { type: SET_ECOMMERCE_OBJECT_RESIDENTIAL, payload };
}

// Gtm action to be dispatched after business signup/callback API
export function gtmCreateEcommerceObjectForBusiness({
  requestObject,
  transactionId,
  isCallbackFlow = false,
  hasFuelSelection = false,
}) {
  return (dispatch, getState) => {
    const state = getState();
    const {
      appState: { products },
      appUserState: { paymentMethod },
      businessPlanConfig: { termData },
    } = state;

    const selectedProducts = products
      .filter(({ isSelected }) => isSelected)
      .map(({ displayName }) => displayName)
      .join(" + ");

    if (isCallbackFlow) {
      return dispatch(
        setBusinessEcommerceObject(
          hasFuelSelection ? { selectedProducts } : {},
        ),
      );
    }
    const termItem = termData.find(({ isSelected }) => isSelected);
    const selectedTermName = termItem.displayName || "";
    const siteInfo = get(requestObject, "site", []);
    const postalCode = get(siteInfo[0], "address.postCode");
    const meanFixedCharges = getMeanFixedChargeForSignup(
      siteInfo[0].supplyPoint,
    );
    const paymentType =
      paymentMethod === PAYMENT_METHOD_DIRECT_DEBIT
        ? "auto-pay: direct debit"
        : "manual payments";

    const ecommerceData = {
      selectedProducts,
      selectedTermName,
      meanFixedCharges,
      paymentType,
      postalCode,
      transactionId,
    };

    dispatch(setBusinessEcommerceObject(ecommerceData));
    trackBusinessContinueButton(
      {
        event: "add_payment_info",
        content_tertiary: "add payment info",
        ecommerce: {
          currency: "NZD",
          value: meanFixedCharges,
          payment_type: paymentType,
          items: [
            {
              item_name: "business join",
              item_id: "business join",
              price: meanFixedCharges, // fixed charge/day
              item_brand: "genesis",
              item_category: selectedProducts, // fuel type
              item_category2: "business energy", // plan type
              item_category3: undefined, //indicate energy user type if applicable
              item_variant: selectedTermName, // term type
              quantity: 1,
            },
          ],
        },
      },
      "sign up now",
    );
  };
}

function getPaymentName(paymentMethod) {
  switch (paymentMethod) {
    case PAYMENT_METHOD_CREDIT_CARD:
      return "auto-pay: credit card";
    case PAYMENT_METHOD_DIRECT_DEBIT:
      return "auto-pay: direct debit";
    case PAYMENT_METHOD_MANUAL:
      return "manual payments";
    default:
      return "";
  }
}

// Gtm action to be dispatched after residential signup/callback API
export function gtmCreateEcommerceObjectForResidential({
  requestObject,
  transactionId,
  isCallbackFlow = false,
}) {
  return (dispatch, getState) => {
    const state = getState();
    const {
      appState: { products, planUsageType, planType },
      appUserState: { paymentMethod, isExistingCustomer },
      residentialPlanConfig: { termData },
    } = state;

    const selectedProducts = products
      .filter(({ isSelected }) => isSelected)
      .map(({ displayName }) => displayName)
      .join(" + ");

    if (isCallbackFlow) {
      return dispatch(setResidentialEcommerceObject({ selectedProducts }));
    }

    const { codeName } = termData.find(({ isSelected }) => isSelected) || {};
    const selectedTermName =
      codeName === RESIDENTIAL_PLAN_TERM_FIXED12
        ? "12 month fixed"
        : "Flexible";

    const siteInfo = get(requestObject, "site", []);
    const householdSize = get(siteInfo[0], "housePopulation", "");
    const postalCode = get(siteInfo[0], "address.postCode");
    const meanFixedCharges = getMeanFixedChargeForSignup(
      siteInfo[0].supplyPoint,
    );
    const selectedPlanType = getPlanTerms(planType).title.toLowerCase();
    const isExistingCustomerSignup =
      planType === PLAN_TYPE_EV_PLUS && isExistingCustomer === "true";
    const energyUsageType =
      planUsageType === PLAN_USAGE_LOW ? "low user" : "standard user";

    const ecommerceData = {
      selectedProducts,
      selectedTermName,
      meanFixedCharges,
      postalCode,
      transactionId,
      selectedPlanType,
      energyUsageType,
      householdSize,
      isExistingCustomerSignup,
    };

    dispatch(setResidentialEcommerceObject(ecommerceData));
    if (!isExistingCustomerSignup) {
      trackResidentialContinueButton(
        {
          event: "add_payment_info",
          content_tertiary: "add payment info",
          ecommerce: {
            currency: "NZD",
            value: meanFixedCharges,
            payment_type: getPaymentName(paymentMethod),
            items: [
              {
                item_name: "residential join", // Name or ID is required
                item_id: "residential join", // if applicable
                price: meanFixedCharges, // fixed charge/day
                item_brand: "genesis",
                item_category: selectedProducts, // fuel type
                item_category2: selectedPlanType, // plan type
                item_category3: energyUsageType, // energy user type
                item_variant: selectedTermName,
                // term type
                quantity: 1,
              },
            ],
          },
        },
        "sign up now",
      );
    }
  };
}
