import React from "react";

const NavigationContext = React.createContext(null);

const ACTION_TYPES = {
  selectTopLevelNav: "select_top_level_nav",
  selectPrimaryNavItem: "select_primary_nav_item",
  deselectPrimaryNavItem: "deselect_primary_nav_item",
  deselectActivePrimaryNav: "deselect_active_primary_nav",
  setCurrentMobileNav: "set_current_mobile_nav",
  selectPreviousNav: "select_previous_nav",
  toggleNav: "toggle_nav",
  selectHomeNav: "select_home_nav",
};

function selectNavItemFromList(navItemList, navItemToSelect) {
  return navItemList.map((item) => {
    const isSelected = item.name === navItemToSelect.name;
    return {
      ...item,
      isSelected,
    };
  });
}

function deselectPrimaryNav(navData) {
  const result = {
    ...navData,
    children: navData.children.map((item) => {
      return {
        ...item,
        isSelected: false,
      };
    }),
  };
  return result;
}

function reducer(state, action) {
  switch (action.type) {
    case ACTION_TYPES.selectTopLevelNav: {
      return {
        ...state,
        topLevelNav: selectNavItemFromList(state.topLevelNav, action.payload),
        primaryNav: deselectPrimaryNav(action.payload),
        activePrimaryNav: null,
        previousNav: [],
      };
    }
    case ACTION_TYPES.selectPrimaryNavItem: {
      return {
        ...state,
        primaryNav: {
          ...state.primaryNav,
          children: selectNavItemFromList(
            state.primaryNav.children,
            action.payload,
          ),
        },
        activePrimaryNav: action.payload.path,
      };
    }
    case ACTION_TYPES.deselectPrimaryNavItem: {
      return {
        ...state,
        primaryNav: deselectPrimaryNav(state.primaryNav),
        activePrimaryNav: null,
      };
    }
    case ACTION_TYPES.deselectActivePrimaryNav: {
      return {
        ...state,
        activePrimaryNav: null,
      };
    }
    case ACTION_TYPES.setCurrentMobileNav: {
      return {
        ...state,
        previousNav: [...state.previousNav, state.currentNav],
        currentNav: action.payload,
      };
    }
    case ACTION_TYPES.selectPreviousNav: {
      const previousNav = [...state.previousNav];
      const currentNav = previousNav.pop();
      return {
        ...state,
        previousNav,
        currentNav: currentNav,
      };
    }
    case ACTION_TYPES.toggleNav: {
      return {
        ...state,
        isMobileNavOpen: !state.isMobileNavOpen,
      };
    }
    case ACTION_TYPES.selectHomeNav: {
      const forHomeNav = state.topLevelNav.find(
        (item) => item.name === "for-home",
      );
      return {
        ...state,
        topLevelNav: selectNavItemFromList(state.topLevelNav, forHomeNav),
        primaryNav: deselectPrimaryNav(forHomeNav),
        activePrimaryNav: null,
        previousNav: [],
      };
    }
    default:
      throw new Error(`Navigation reducer was called with ${action.type}`);
  }
}

function NavigationProvider({ globalData, children }) {
  const {
    header: { navigation },
  } = globalData;

  const initialState = {
    isMobileNavOpen: false,
    topLevelNav: navigation,
    primaryNav: navigation.find(({ isSelected }) => isSelected),
    activePrimaryNav: null,
    currentNav: null,
    previousNav: [],
  };

  const [state, dispatch] = React.useReducer(reducer, initialState);

  return (
    <NavigationContext.Provider value={{ state, dispatch }}>
      {children}
    </NavigationContext.Provider>
  );
}

function useNavigation() {
  const context = React.useContext(NavigationContext);
  if (!context) {
    throw new Error(
      "useNavigation must be used within a <NavigationProvider /> component",
    );
  }
  return context;
}

function selectTopLevelNav({ dispatch, payload }) {
  dispatch({ type: ACTION_TYPES.selectTopLevelNav, payload });
}

function selectPrimaryNavItem({ dispatch, payload }) {
  dispatch({ type: ACTION_TYPES.selectPrimaryNavItem, payload });
}

function deselectPrimaryNavItem({ dispatch }) {
  dispatch({ type: ACTION_TYPES.deselectPrimaryNavItem });
}

function deselectActivePrimaryNavItem({ dispatch }) {
  dispatch({ type: ACTION_TYPES.deselectActivePrimaryNav });
}

function selectPreviousNav({ dispatch }) {
  dispatch({ type: ACTION_TYPES.selectPreviousNav });
}

function setCurrentMobileNav({ dispatch, payload }) {
  dispatch({ type: ACTION_TYPES.setCurrentMobileNav, payload });
}

function toggleNav({ dispatch }) {
  dispatch({ type: ACTION_TYPES.toggleNav });
}

function selectHomeNav({ dispatch }) {
  dispatch({ type: ACTION_TYPES.selectHomeNav });
}

export {
  NavigationProvider,
  useNavigation,
  selectTopLevelNav,
  selectPrimaryNavItem,
  deselectPrimaryNavItem,
  deselectActivePrimaryNavItem,
  selectPreviousNav,
  setCurrentMobileNav,
  toggleNav,
  selectHomeNav,
};
