import { ContentZone } from "@agility/nextjs";
import { getModule } from "page-modules";
import styles from "./default-template.module.scss";

function DefaultTemplate(props) {
  return (
    <div className={styles.container}>
      <ContentZone name="Content" {...props} getModule={getModule} />
    </div>
  );
}

export { DefaultTemplate };
