import classNames from "classnames";
import { ExternalLink } from "join-form/components/icon";

function AlertCta({ url }) {
  const validUrl = url?.href && url?.text ? true : false;

  if (!validUrl) {
    return null;
  }

  return (
    <div className="flex ml-auto" data-testid="alertCtaSection">
      <a
        href={url.href}
        target={url.target}
        data-testid="alertButton"
        className={classNames(
          "flex items-center px-5 py-3 bg-white rounded-full",
          "text-black-primary text-15/24 font-medium",
          "outline outline-2 outline-transparent",
          "focus-visible:outline-blue-500 outline-offset-1",
          "hover:shadow-12/60/40 active:shadow-12/80/60 shadow-6/30/20 transition duration-500",
          "md:px-6 md:py-2 md:text-18/28 md:rounded-full",
        )}
      >
        <span>{url.text}</span>
        {url.target === "_blank" && (
          <ExternalLink
            data-testid="alertButtonIcon"
            className="w-5 h-5 ml-3 fill-current"
          />
        )}
      </a>
    </div>
  );
}

export { AlertCta };
