import { GraphQLApi } from "helpers/graphQLApi";

export const getCustomInitialProps = async ({ agility }) => {
  const referenceName = "gehydrographlist";

  try {
    const query = JSON.stringify({
      query: `{
        ${referenceName} {
          fields {
            title
            slug
            blockEditor
          }
        }
      }`,
    });
    const graphQL = new GraphQLApi(agility.config);
    const req = graphQL.buildRequest(query);

    const { data } = await agility.makeRequest(req);
    return data;
  } catch (ex) {
    return {};
  }
};
