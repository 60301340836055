import classNames from "classnames";
import { CHART } from "./constants";

// tooltip content
export const TooltipContent = ({ active, chart, payload }) => {
  if (!active || !payload || !chart || !payload.length) return null;

  const {
    payload: { tooltip },
  } = payload[0];

  const isPriceChart = chart === CHART.price;

  return (
    <div
      className={classNames("px-3 py-2 shadow-400 bg-black-primary rounded-2", {
        "pb-4": isPriceChart,
      })}
    >
      {isPriceChart && (
        <p className="pb-1 font-medium text-white font-14/20 md:font-16/24">
          {tooltip.price}
        </p>
      )}
      {isPriceChart && (
        <p className="text-13/20 md:text-14/20 text-dark-50">{tooltip.date}</p>
      )}
      <p className="text-13/20 md:text-14/20 text-dark-50">
        Volume: <span className="font-medium">{tooltip.volume}</span>
      </p>
    </div>
  );
};
