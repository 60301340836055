import ReCAPTCHA from "react-google-recaptcha";

export default function Recaptcha(props) {
  const {
    onChange,
    // these props required for recaptcha mock
    // values,
    // setFieldValue
  } = props;

  return (
    <ReCAPTCHA
      sitekey="6LcaDdcZAAAAAIwczdOo-Ip2vp9a6VzSWFooAlWp"
      onChange={onChange}
    />
  );
}
