import classNames from "classnames";
import { motion } from "framer-motion";
import Button from "join-form/components/button";
import Icon from "join-form/components/icon";

const footerFade = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: {
    opacity: 0,
    transition: {
      delay: 0,
    },
  },
};

const footerFadeTransition = {
  delay: 0.2,
};

export default function StepProgressionButton(props) {
  const {
    text = "Continue",
    disabled = false,
    onContinueClick,
    backLink,
    isLoading = false,
    testId,
    className,
    applyDisabledAttribute = false,
    buttonContainerClassName = "",
  } = props;

  return (
    <motion.div
      initial="initial"
      animate="animate"
      exit="exit"
      variants={footerFade}
      transition={footerFadeTransition}
      className={classNames("pb-30 md:pb-45", className)}
    >
      <div
        className={classNames(
          "bg-white rounded-15 py-4 px-5 flex items-center border border-black-50",
          {
            "justify-end": !backLink,
            "justify-between": backLink,
          },
          buttonContainerClassName,
        )}
      >
        {backLink && (
          <Button
            buttonType="secondaryOutline"
            to={backLink}
            size="round"
            className="flex items-center justify-center"
            data-testid="previousPage"
          >
            <Icon name="ArrowLeft" size="18" className="" />
          </Button>
        )}
        <Button
          disabled={disabled && !isLoading}
          applyDisabledAttribute={applyDisabledAttribute}
          onClick={onContinueClick}
          className="flex items-center justify-center gap-3"
          data-testid={testId}
          type="submit"
        >
          {text}
          {isLoading ? (
            <Icon
              name="Loading"
              id="loading"
              size="18"
              className="animate-spin"
            />
          ) : (
            <Icon name="ArrowRight" id="arrowRight" size="18" />
          )}
        </Button>
      </div>
    </motion.div>
  );
}
