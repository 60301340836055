import FeatureItem from "join-form/components/feature-item";
import { BUSINESS_PLAN_TERM_FLEXI } from "helpers/constants";

function getTermText({ sidebarDisplayName, discountPercentage }) {
  const termContainsDiscount = discountPercentage > 0;

  const displayText = termContainsDiscount
    ? `${discountPercentage}% ${sidebarDisplayName} discount`
    : sidebarDisplayName;

  return displayText;
}

export default function TermFeature({ termItem, ...rest }) {
  const { codeName, isSelected, sidebarDisplayName, discountPercentage } =
    termItem;

  if (isSelected === false) {
    return <FeatureItem {...rest}>Fixed term or stay flexible</FeatureItem>;
  }

  if (codeName !== BUSINESS_PLAN_TERM_FLEXI) {
    const displayText = getTermText({
      sidebarDisplayName,
      discountPercentage,
    });

    return (
      <FeatureItem type="complete" {...rest}>
        {displayText}
      </FeatureItem>
    );
  }

  return (
    <FeatureItem type="complete" {...rest}>
      Flexible, no fixed-term discount
    </FeatureItem>
  );
}
