import React from "react";
import { useScreenSize } from "hooks";
import { useRouter } from "next/router";
import { SiteHeaderDesktop } from "./Desktop";
import { FadeIn } from "./FadeIn";
import { SiteHeaderMobile } from "./Mobile";

const slugToTextMapping = {
  "for-home": "For Home",
  "for-business": "For Business",
  investor: "For Investors",
};

function getLogoText({ query }) {
  const [section] = query.slug ?? ["for-home"];

  const text = slugToTextMapping[section];

  return text;
}

function SiteHeader(props) {
  const [mounted, setMounted] = React.useState(false);
  const { isMd } = useScreenSize();

  const router = useRouter();

  React.useEffect(() => {
    setMounted(true);
  }, []);

  const logoText = getLogoText(router);

  return mounted && isMd ? (
    <FadeIn isVisible={mounted}>
      <SiteHeaderDesktop {...props} logoText={logoText} />
    </FadeIn>
  ) : (
    <FadeIn isVisible={mounted}>
      <SiteHeaderMobile {...props} logoText={logoText} />
    </FadeIn>
  );
}

export { SiteHeader };
