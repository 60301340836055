import {
  PRODUCT_ELECTRICITY,
  PRODUCT_GAS,
  PRODUCT_LPG,
} from "helpers/constants";
import {
  extractValuesFromDate,
  formatDateToDoubleDigit,
} from "helpers/dateHelper";
import { formatToTitleCase } from "helpers/formatTextHelper";
import { getSelectedAvailableProducts } from "helpers/productHelper";
import { findKey, get, sortBy } from "lodash-es";

const rateDescriptionMap = {
  // elec
  "Basic Low User Fixed Charge": "Fixed Charge",
  "Basic Low User Anytime": "Anytime",
  "Basic Low Anytime": "Anytime",
  "Basic Low User Composite": "Composite",
  "Basic Low User Controlled": "Controlled",
  "Basic Low Controlled": "Controlled",
  "Basic Low Weekend": "Weekend",
  "Basic Low Day": "Day",
  "Basic Low User Day": "Day",
  "Basic Low Night": "Night",
  "Basic Low User Night": "Night",
  "Basic Low User Night Only": "Night Only",
  "Basic Low Daily Fixed": "Daily Fixed",
  "Basic Low Composite": "Composite",
  "Basic Standard Fixed Charge": "Fixed Charge",
  "Basic Standard Daily Fixed": "Daily Fixed",
  "Basic Standard Anytime": "Anytime",
  "Basic Standard Composite": "Composite",
  "Basic Standard Controlled": "Controlled",
  "Basic Standard Day": "Day",
  "Basic Standard Weekend": "Weekend",
  "Basic Standard Night": "Night",
  "Basic Standard Night Only": "Night Only",

  "Plus Low User Fixed Charge": "Fixed Charge",
  "Plus Low Daily Fixed": "Daily Fixed",
  "Plus Low User Anytime": "Anytime",
  "Plus Low Composite": "Composite",
  "Plus Low Anytime": "Anytime",
  "Plus Low Weekend": "Weekend",
  "Plus Low Day": "Day",
  "Plus Low Night": "Night",
  "Plus Low User Composite": "Composite",
  "Plus Low User Controlled": "Controlled",
  "Plus Low Controlled": "Controlled",
  "Plus Low User Day": "Day",
  "Plus Low User Night": "Night",
  "Plus Low User Night Only": "Night Only",
  "Plus Standard Fixed Charge": "Fixed Charge",
  "Plus Standard Daily Fixed": "Daily Fixed",
  "Plus Standard Anytime": "Anytime",
  "Plus Standard Composite": "Composite",
  "Plus Standard Controlled": "Controlled",
  "Plus Standard Day": "Day",
  "Plus Standard Weekend": "Weekend",
  "Plus Standard Night": "Night",
  "Plus Standard Night Only": "Night Only",

  "Energy EV Low Daily Fixed": "Daily Fixed",
  "Energy EV Std Daily Fixed": "Daily Fixed",
  "Energy EV Low Day": "Day (7am-9pm)",
  "Energy EV Std Day": "Day (7am-9pm)",
  "Energy EV Low Night": "Night (9pm-7am)",
  "Energy EV Std Night": "Night (9pm-7am)",
  "Energy EV Low Anytime": "Interim Anytime",
  "Energy EV Std Anytime": "Interim Anytime",
  "Energy EV Low Composite": "Interim Composite",
  "Energy EV Std Composite": "Interim Composite",
  "Energy EV Low Controlled": "Interim Controlled",
  "Energy EV Std Controlled": "Interim Controlled",

  // gas
  "Basic Plan Low User Variable": "Variable Charge",
  "Basic Low Variable": "Variable Charge",
  "Basic Plan Standard Variable": "Variable Charge",
  "Basic Standard Variable": "Variable Charge",
  "Plus Plan Low User Variable": "Variable Charge",
  "Plus Low Variable": "Variable Charge",
  "Plus Plan Standard Variable": "Variable Charge",
  "Plus Standard Variable": "Variable Charge",

  // lpg
  "45kg LPG Cylinder": "45kg cylinder",
  "Monthly Gas Bottle Rental": "Monthly Rental",
  "Monthly Gas Bottle Rental Basic": "Monthly Rental",
  "45kg LPG Cylinder Basic": "45kg cylinder",
  "Monthly Gas Bottle Rental Plus": "Monthly Rental",
  "45kg LPG Cylinder Plus": "45kg cylinder",
};

const fixtureCharges = [
  "Capacity Charge",
  "Congestion Charge",
  "Demand Charge",
  "Distance Charge",
  "Power Factor Charge",
  "Power Factor Rebate",
  "Interruptibility Rebate",
  "Transformer Charge",
].join("|");

const fixtureChargeRegex = new RegExp(fixtureCharges, "i");

function checkDescriptionForFixtureCharge(description) {
  const result = fixtureChargeRegex.test(description);
  return result;
}

function getRateDescription(description) {
  const foundKeyName = findKey(
    rateDescriptionMap,
    (propertyValue, propertyName) =>
      propertyName.toLowerCase() === description.toLowerCase(),
  );
  const result = rateDescriptionMap[foundKeyName] || description;
  return result;
}

function getRateUnitOfMeasurement(meteringUnitOfMeasurement) {
  switch (meteringUnitOfMeasurement.toLowerCase()) {
    case "per day":
      return "day";
    case "per kwh":
      return "kWh";
    case "per bottle":
      return "bottle";
    default:
      return meteringUnitOfMeasurement;
  }
}

function checkResidentialRatesAvailability(products, offers) {
  const applicableOffers = findOffersForSelectedProducts(products, offers);
  const ratesExist = applicableOffers.every((offer) =>
    ratesExistForOffer(offer),
  );
  return ratesExist;
}

function getOfferNameByProduct(product) {
  switch (product.name) {
    case PRODUCT_ELECTRICITY:
      return "electricityOffer";
    case PRODUCT_GAS:
      return "naturalGasOffer";
    case PRODUCT_LPG:
      return "bottledGasOffer";
    default:
      return "";
  }
}

function findOffersForSelectedProducts(products, offers) {
  const selectedProducts = getSelectedAvailableProducts(products);
  const offerNameList = selectedProducts.map((product) =>
    getOfferNameByProduct(product),
  );
  const offerList = offerNameList.map((offerName) => offers[offerName]);
  return offerList;
}

function ratesExistForOffer(offer) {
  const basicPlanLowUsageTariffList = get(
    offer,
    "basicPlan.lowUsage.tariffList",
    [],
  );
  const basicPlanHighUsageTariffList = get(
    offer,
    "basicPlan.highUsage.tariffList",
    [],
  );
  const plusPlanLowUsageTariffList = get(
    offer,
    "plusPlan.lowUsage.tariffList",
    [],
  );
  const plusPlanHighUsageTariffList = get(
    offer,
    "plusPlan.highUsage.tariffList",
    [],
  );

  const allTariffTypes = [
    basicPlanLowUsageTariffList,
    basicPlanHighUsageTariffList,
    plusPlanLowUsageTariffList,
    plusPlanHighUsageTariffList,
  ];

  const doesRatesExist = allTariffTypes.every(
    (tariffList) => tariffList.length !== 0,
  );
  return doesRatesExist;
}

function roundRate(rate, decimalPlaces = 4) {
  try {
    const result = rate.toFixed(decimalPlaces);
    return [result, null];
  } catch (ex) {
    return [null, ex];
  }
}

function formatPriceWithUnitOfMeasurement({
  pricingUnitOfMeasurement,
  rate,
  unitOfMeasurement,
}) {
  const [roundedRate, error] = roundRate(Math.abs(rate));
  if (error) {
    return null;
  }
  const sign = rate < 0 ? "-" : "";
  return `${sign}${pricingUnitOfMeasurement}${roundedRate} / ${unitOfMeasurement}`;
}

function formatFixtureCharge({
  pricingUnitOfMeasurement,
  rate,
  multiplier,
  multiplierUnitOfMeasurement,
}) {
  const [roundedRate, error] = roundRate(Math.abs(rate));
  if (error) {
    return null;
  }
  const sign = rate < 0 ? "-" : "";
  return `${sign}${pricingUnitOfMeasurement}${roundedRate} x ${multiplier}${multiplierUnitOfMeasurement}`;
}

function createSeasonalTariffItem(tariffItem) {
  const {
    description,
    multiplier,
    multiplierUnitOfMeasurement,
    meteringUnitOfMeasurement,
    pricingUnitOfMeasurement,
    type = "",
    season,
  } = tariffItem;

  const unitOfMeasurement = getRateUnitOfMeasurement(meteringUnitOfMeasurement);

  const isFixtureCharge = checkDescriptionForFixtureCharge(description);

  const formatSeasonalRates = season.map((rates) => {
    const fixtureCalculationExcludingGST = isFixtureCharge
      ? formatFixtureCharge({
          pricingUnitOfMeasurement,
          rate: rates.rateExcludingGST,
          multiplier,
          multiplierUnitOfMeasurement,
        })
      : null;

    const fixtureCalculationIncludingGST = isFixtureCharge
      ? formatFixtureCharge({
          pricingUnitOfMeasurement,
          rate: rates.rateIncludingGST,
          multiplier,
          multiplierUnitOfMeasurement,
        })
      : null;

    const valueExcludingGST = formatPriceWithUnitOfMeasurement({
      rate: isFixtureCharge
        ? rates.finalRateExcludingGST
        : rates.rateExcludingGST,
      pricingUnitOfMeasurement,
      unitOfMeasurement,
    });

    const valueIncludingGST = formatPriceWithUnitOfMeasurement({
      rate: isFixtureCharge
        ? rates.finalRateIncludingGST
        : rates.rateIncludingGST,
      pricingUnitOfMeasurement,
      unitOfMeasurement,
    });

    const seasonStartDateValues = extractValuesFromDate(
      rates.startDate.split("+")[0],
    );
    const seasonStartDate = `${formatDateToDoubleDigit(
      seasonStartDateValues.date,
    )} ${seasonStartDateValues.month}`;

    const seasonEndDateValues = extractValuesFromDate(
      rates.endDate.split("+")[0],
    );
    const seasonEndDate = `${formatDateToDoubleDigit(
      seasonEndDateValues.date,
    )} ${seasonEndDateValues.month}`;

    return {
      fixtureCalculationIncludingGST,
      fixtureCalculationExcludingGST,
      valueExcludingGST,
      valueIncludingGST,
      seasonName: `${formatToTitleCase(
        rates.type,
      )} ${seasonStartDate}-${seasonEndDate}`,
    };
  });

  return {
    title: description,
    seasonalRates: formatSeasonalRates,
    type,
  };
}

function createElectricityTariffItem(tariffItem) {
  const {
    description,
    finalRateExcludingGST,
    finalRateIncludingGST,
    multiplier,
    multiplierUnitOfMeasurement,
    meteringUnitOfMeasurement,
    pricingUnitOfMeasurement,
    rateExcludingGST,
    rateIncludingGST,
    type = "",
  } = tariffItem;

  const isFixtureCharge = checkDescriptionForFixtureCharge(description);

  const unitOfMeasurement = getRateUnitOfMeasurement(meteringUnitOfMeasurement);

  const fixtureCalculationExcludingGST = isFixtureCharge
    ? formatFixtureCharge({
        pricingUnitOfMeasurement,
        rate: rateExcludingGST,
        multiplier,
        multiplierUnitOfMeasurement,
      })
    : null;

  const fixtureCalculationIncludingGST = isFixtureCharge
    ? formatFixtureCharge({
        pricingUnitOfMeasurement,
        rate: rateIncludingGST,
        multiplier,
        multiplierUnitOfMeasurement,
      })
    : null;

  const valueExcludingGST = formatPriceWithUnitOfMeasurement({
    rate: isFixtureCharge ? finalRateExcludingGST : rateExcludingGST,
    pricingUnitOfMeasurement,
    unitOfMeasurement,
  });

  const valueIncludingGST = formatPriceWithUnitOfMeasurement({
    rate: isFixtureCharge ? finalRateIncludingGST : rateIncludingGST,
    pricingUnitOfMeasurement,
    unitOfMeasurement,
  });

  return {
    title: description,
    fixtureCalculationIncludingGST,
    fixtureCalculationExcludingGST,
    valueExcludingGST,
    valueIncludingGST,
    type,
  };
}

function sortDailyFixedChargeToTop(tariffItem) {
  const title = get(tariffItem, "title", "");
  const sortOrder = title.toLowerCase() === "daily fixed charge" ? 0 : 1;
  return sortOrder;
}

function createElectricityTariffListForDisplay(elecOfferList) {
  if (elecOfferList.length !== 1) {
    return null;
  }

  const tariffList = get(elecOfferList[0], "priceBook.tariffList", null);

  if (tariffList === null) {
    return null;
  }

  const filteredTarifflist = tariffList.filter(
    (tariffItem) => !tariffItem.description.toLowerCase().includes("unmetered"),
  );

  const transformedTariffList = filteredTarifflist.map((tariffItem, index) => {
    if (tariffItem.season) {
      return createSeasonalTariffItem(tariffItem);
    }
    return createElectricityTariffItem(tariffItem);
  });

  const sortedTariffList = sortBy(transformedTariffList, [
    sortDailyFixedChargeToTop,
    "type",
    "title",
  ]);

  return sortedTariffList;
}

const nonInterimEVRatesKeywords = ["daily fixed", "day", "night"];

function sortEVTariffListForDisplay(tariffList) {
  const interimRates = [];
  const nonInterimRates = [];
  tariffList.forEach((item) => {
    const isNonInterimRate = nonInterimEVRatesKeywords.some((keyword) =>
      item.description.toLowerCase().includes(keyword),
    );
    if (isNonInterimRate) {
      nonInterimRates.push(item);
    } else {
      interimRates.push(item);
    }
  });
  const sortedRates = nonInterimRates.concat(interimRates);
  return sortedRates;
}

export {
  getRateDescription,
  getRateUnitOfMeasurement,
  checkResidentialRatesAvailability,
  createElectricityTariffListForDisplay,
  sortEVTariffListForDisplay,
};
