import classNames from "classnames";
import { CallToAction } from "components/CallToAction";
import { VARIANT } from "helpers/constants";
import { getContentWithLineBreaks } from "helpers/htmlHelper";
import { FadeInBottomOnScroll } from "./animations";

function SectionHeader({ module }) {
  const { title, description, cta } = module.fields;
  return (
    title && (
      <FadeInBottomOnScroll>
        <div
          data-testid="headerSection"
          data-psnid="psn_header_section"
          className={classNames(
            "px-4 mb-7 md:mb-15 sm:px-15 md:px-17 lg:px-23 xl:px-46",
            "max-w-full w-120 sm:w-210 md:w-auto mx-auto",
          )}
        >
          <div className="flex flex-col gap-5 md:flex-row md:items-center md:justify-between">
            <div className="space-y-5 md:basis-3/4">
              <h2 className="font-bold text-dark-primary text-32/36 md:text-44/52">
                {title}
              </h2>
              {description && (
                <p className="text-black-primary text-20/32 md:text-24/36">
                  {getContentWithLineBreaks(description)}
                </p>
              )}
            </div>
            {cta && (
              <div
                data-testid="headerSectionCta"
                className="-mx-5 text-15/24 md:text-18/28"
              >
                <CallToAction
                  data={cta}
                  dataGa={{
                    content_tertiary: title,
                    event: "cta_click",
                    event_category: "cta",
                    event_label: [cta?.text],
                  }}
                  testid="headerCta"
                  variant={VARIANT.darkTransparent}
                />
              </div>
            )}
          </div>
        </div>
      </FadeInBottomOnScroll>
    )
  );
}

export { SectionHeader };
