import { useState } from "react";
import { createSelector } from "@reduxjs/toolkit";
import { Formik } from "formik";
import { AnimatePresence, motion } from "framer-motion";
import {
  setFixedPaymentDay,
  setPaymentMethod as setPaymentMethodAction,
} from "actions/appUserStateActions";
import { createSignUp } from "actions/businessSignupActions";
import { setPaymentForm } from "actions/paymentStateActions";
import { updateZendeskTicket } from "actions/zendeskActions";
import ButtonRadioSelection from "join-form/components/button-radio-selection";
import ConditionalForm from "join-form/components/conditional-form";
import Feedback from "join-form/components/feedback";
import {
  Dropdown,
  FormFieldTerm,
  RadioList,
} from "join-form/components/form-controls";
import Intro from "join-form/components/intro";
import StepProgressionButton from "join-form/components/step-progression-button";
import {
  appBusinessRoutes,
  PAYMENT_DAY_FIXED,
  PAYMENT_DAY_NOT_FIXED,
  PAYMENT_METHOD_DIRECT_DEBIT,
  PAYMENT_METHOD_MANUAL,
} from "helpers/constants";
import { getBusinessName } from "helpers/businessDetailsHelper";
import { ordinalNumber } from "helpers/ordinalNumberHelper";
import {
  selectAppUserState,
  selectBusinessState,
  selectPaymentState,
  selectAddressState,
  selectAppState,
} from "reducers/selector";
import { useRouter } from "next/router";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { InlineBlockEditor } from "components/BlockEditor";
import { getPageData } from "helpers/getPageData";
import {
  headerFade,
  heightFadeTransition,
  heightFadeVariants,
  itemContainer,
  itemFade,
} from "./animations";
import styles from "./payment-page.module.scss";
import { appRoutes } from "helpers/constants";
import { getDebitTermConditionId, setTermsAndConditionsValidation } from "helpers/debitTermsConditionsHelper";
import DirectDebitForm, { directDebitFormValidation } from "join-form/components/direct-debit-form";
import { useEffectOnce } from "react-use";

function PaymentPage({ props, pageData }) {
  const {
    values,
    touched,
    errors,
    handleChange,
    handleSubmit,
    isValid,
    isSubmitting,
    status,
    tradingName,
  } = props;

  const {
    title,
    blockEditor,
    fixedDueDateLabel,
    paymentConfirmation,
    paymentOptions,
    debitTermsConditions,
  } = pageData.fields;

  const dispatch = useDispatch();

  const [initialDirectDebitFormHeight, resetInitialDirectDebitFormHeight] =
    useState(values.paymentMethod === PAYMENT_METHOD_DIRECT_DEBIT ? "auto" : 0);

  function setPaymentMethod(paymentMethod) {
    dispatch(setPaymentMethodAction(paymentMethod));
    if (values.fixPaymentDay === PAYMENT_DAY_FIXED) {
      dispatch(setFixedPaymentDay(values.fixedPaymentDay));
    }
    dispatch(setPaymentForm({ ...values }));
    resetInitialDirectDebitFormHeight(0);
  }

  const formikProps = {
    values,
    touched,
    errors,
    handleChange,
  };

  return (
    <motion.div
      className={styles.container}
      exit="undefined"
      data-testid="paymentPage"
    >
      <Intro className={styles.intro}>
        {(introStyles) => (
          <motion.div
            initial="initial"
            animate="animate"
            exit="exit"
            variants={headerFade}
          >
            <h1 className={introStyles.title}>{title}</h1>
            <InlineBlockEditor jsonString={blockEditor} />
          </motion.div>
        )}
      </Intro>
      <motion.div
        initial="initial"
        animate="animate"
        exit="exit"
        variants={itemContainer}
        className={styles.body}
      >
        {paymentOptions.map((option) => {
          const { id, title, subtitle } = option.fields;

          if (id === PAYMENT_METHOD_DIRECT_DEBIT) {
            return (
              <motion.div variants={itemFade} className={styles.item} key={id}>
                <ConditionalForm
                  id="directDebit"
                  title={title}
                  subTitle={subtitle}
                  isSelected={
                    values.paymentMethod === PAYMENT_METHOD_DIRECT_DEBIT
                  }
                  initialHeight={initialDirectDebitFormHeight}
                  onClick={() => setPaymentMethod(PAYMENT_METHOD_DIRECT_DEBIT)}
                  size="large"
                >
                  <DirectDebitForm {...formikProps} debitTermsConditions={debitTermsConditions} />
                </ConditionalForm>
              </motion.div>
            );
          } else {
            return (
              <motion.div variants={itemFade} className={styles.item} key={id}>
                <ButtonRadioSelection
                  subTitle={subtitle}
                  isSelected={values.paymentMethod === PAYMENT_METHOD_MANUAL}
                  onClick={() => setPaymentMethod(PAYMENT_METHOD_MANUAL)}
                  className={styles.manual_payments}
                  data-testid="manualPayments"
                  size="large"
                >
                  {title}
                </ButtonRadioSelection>
              </motion.div>
            );
          }
        })}

        <motion.div variants={itemFade} className={styles.dateItem}>
          <p className={styles.label}>{fixedDueDateLabel}</p>
          <RadioList
            name="fixPaymentDay"
            onChange={handleChange}
            errorMessage={errors.fixPaymentDay}
            showError={errors.fixPaymentDay && touched.fixPaymentDay}
            className={styles.fixed_day_radio}
            size="medium"
            items={[
              {
                text: "Yes",
                value: PAYMENT_DAY_FIXED,
                checked: values.fixPaymentDay === PAYMENT_DAY_FIXED,
              },
              {
                text: "No",
                value: PAYMENT_DAY_NOT_FIXED,
                checked: values.fixPaymentDay === PAYMENT_DAY_NOT_FIXED,
              },
            ]}
          />

          <AnimatePresence>
            {values.fixPaymentDay === PAYMENT_DAY_FIXED && (
              <motion.div
                initial="initial"
                animate="animate"
                exit="exit"
                variants={heightFadeVariants}
                transition={heightFadeTransition}
                className={styles.date_select_wrapper}
              >
                <Dropdown
                  name="fixedPaymentDay"
                  placeholder="What date in the month?"
                  value={values.fixedPaymentDay}
                  options={[...Array(28).keys()].map((i) => ({
                    value: `${i + 1}`,
                    name: ordinalNumber(i + 1),
                  }))}
                  onChange={handleChange}
                  errorMessage={errors.fixedPaymentDay}
                  showError={errors.fixedPaymentDay && touched.fixedPaymentDay}
                />
              </motion.div>
            )}
          </AnimatePresence>
        </motion.div>

        <motion.div variants={itemFade} className={styles.terms_container}>
          <FormFieldTerm
            name="generalTerms"
            value={values.generalTerms}
            checked={values.generalTerms === true}
            onChange={handleChange}
            errorMessage={errors.generalTerms}
            showError={errors.generalTerms && touched.generalTerms}
            testId="generalTerms"
            size="small"
            textSize="small"
          >
            <InlineBlockEditor
              jsonString={paymentConfirmation.replace(
                "{businessType}",
                tradingName,
              )}
              className="prose-p:text-14/20 prose-p:md:text-16/24"
            />
          </FormFieldTerm>
        </motion.div>
      </motion.div>

      <div className="mt-10 overflow-hidden md:mt-0 lg:mt-5">
        <AnimatePresence>
          {errors.paymentMethod && touched.paymentMethod && (
            <motion.div
              initial="initial"
              animate="animate"
              exit="exit"
              variants={heightFadeVariants}
              transition={heightFadeTransition}
              className="mb-8 md:mb-10"
            >
              <Feedback type="error" title="Error" data-testid="errorMessage">
                Please select an option.
              </Feedback>
            </motion.div>
          )}
          {status && (
            <motion.div
              initial="initial"
              animate="animate"
              exit="exit"
              variants={heightFadeVariants}
              transition={heightFadeTransition}
              className="mb-8 md:mb-10"
            >
              <Feedback
                type="error"
                title="Error"
                data-testid="apiErrorMessage"
              >
                {status}
              </Feedback>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
      <StepProgressionButton
        onContinueClick={handleSubmit}
        testId="submitButton"
        applyDisabledAttribute={isSubmitting}
        disabled={isValid ? pendingRequest : true}
        backLink={appBusinessRoutes.property}
        isLoading={pendingRequest}
        text="Sign up now"
      />
    </motion.div>
  );
}

const mapPropsToValues = ({
  paymentState,
  paymentMethod,
  fixedPaymentDay,
}) => ({
  ...paymentState,
  paymentMethod,
  fixedPaymentDay,
});

const validationSchema = Yup.object()
  .shape({
    fixPaymentDay: Yup.string()
      .required("This field is required")
      .oneOf(
        [PAYMENT_DAY_NOT_FIXED, PAYMENT_DAY_FIXED],
        "This field is required",
      ),
    fixedPaymentDay: Yup.string().when("fixPaymentDay", {
      is: (value) => value === PAYMENT_DAY_FIXED,
      then: Yup.string().required("This field is required"),
    }),
    paymentMethod: Yup.string().required("Please select a payment method"),
    generalTerms: Yup.boolean()
      .required()
      .oneOf([true], "Please accept terms and conditions"),
  })
  .concat(directDebitFormValidation);

let pendingRequest = false;

const selectBusinessPropertyState = createSelector(
  [selectAppUserState, selectPaymentState, selectBusinessState],
  (appUserState, paymentState, businessState) => {
    const tradingName = getBusinessName(businessState);
    const { paymentMethod, fixedPaymentDay } = appUserState;

    return {
      paymentState,
      paymentMethod,
      fixedPaymentDay,
      tradingName,
    };
  },
);

const modules = ["GE_JOIN_Intro_Header", "GE_JOIN_Business_Payment_Options"];

function PaymentPageForm(props) {

  const router = useRouter();
  const dispatch = useDispatch();
  const pageData = getPageData({ modules, page: props.page });

  const { customerType } = useSelector(selectAppState);
  const { addressDetails } = useSelector(selectAddressState);
  const componentProps = useSelector(selectBusinessPropertyState);
  const initialValues = mapPropsToValues(componentProps);
  const debitTermsConditions = pageData.fields?.debitTermsConditions;

  const [formValidationSchema, setFormValidationSchema] = useState(validationSchema);

  const onSubmit = async (values, options) => {
    const invalidRequest = !customerType || !addressDetails;
    if (!props?.isTesting && invalidRequest) {
      router.push(appRoutes.customerType);
      return;
    }
    const { fixPaymentDay, fixedPaymentDay } = values;

    const isPaymentDayFixed = fixPaymentDay === PAYMENT_DAY_FIXED;

    const paymentDay = isPaymentDayFixed ? fixedPaymentDay : "";

    dispatch(setFixedPaymentDay(paymentDay));

    dispatch(setPaymentForm(values));
    
    if (values.paymentMethod === PAYMENT_METHOD_DIRECT_DEBIT) {
      const debitTermsConditionsKeys = debitTermsConditions?.map(({ fields }, index) => getDebitTermConditionId(fields, index));
      dispatch(setPaymentForm({ debitTermsConditionsKeys }));
    }

    if (pendingRequest) {
      return;
    }
    pendingRequest = true;

    const request = dispatch(createSignUp())
      .then(() => {
        pendingRequest = false;
        dispatch(updateZendeskTicket());
        router.push(appBusinessRoutes.success);
      })
      .catch((ex) => {
        pendingRequest = false;
        options.setStatus("Something went wrong, please try again.");
        options.setSubmitting(false);
      });

    return request;
  };

  useEffectOnce(() => {
    setTermsAndConditionsValidation({ debitTermsConditions, setFormValidationSchema, validationSchema });
  });

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={formValidationSchema}
      validateOnMount
      enableReinitialize
    >
      {(formikProps) => (
        <PaymentPage
          pageData={pageData}
          props={{ ...componentProps, ...formikProps }}
        />
      )}
    </Formik>
  );
}

export default PaymentPageForm;
