import { compact } from "lodash-es";
import { v4 as uuidv4 } from "uuid";
import { Link } from "components/Link";
import { isAbsoluteUrl, normalizeUrl } from "./urlHelper";

function transform(node) {
  if (node.type === "tag" && node.name === "a") {
    if (isAbsoluteUrl(node.attribs.href)) {
      node.attribs.href = normalizeUrl(node.attribs.href);
      node.attribs.target = "_blank";
      node.attribs.rel = "noreferrer";
    } else if (
      node.attribs.href?.startsWith("/") &&
      node.children[0].type === "text"
    ) {
      // use router for relative links starting with '/' and no nested elements
      const [link] = node.children;
      return (
        <Link href={node.attribs.href} key={uuidv4()}>
          {/* TODO: investigate possibility to create a generic table component shared by BlockEditor and HydroTable */}
          <a>{link.data}</a>
        </Link>
      );
    }
  }
}

function transformPlainText(node) {
  if (node.type === "tag") {
    const textItems = node.children
      ?.filter((childNode) => childNode.type === "text")
      .map((textNode) => textNode.data?.trim());

    const text = compact(textItems).join(" ");

    return text.length ? text : null;
  }
}

export { transform, transformPlainText };
