import { Blank } from "components/Blank";
import { RichTextArea } from "page-modules/RichTextArea";
import { Search } from "page-modules/Search";
import { AccordionModule, AccordionModuleSearch } from "./AccordionModule";
import { ApiForm } from "./ApiForm";
import { ArticleDetails, ArticleDetailSearch } from "./ArticleDetails";
import { ArticleFilter } from "./ArticleFilter";
import { BannerBubble, BannerBubbleSearch } from "./BannerBubble";
import {
  BannerFloatingImage,
  BannerFloatingImageSearch,
} from "./BannerFloatingImage";
import { BgCurve } from "./BgCurve";
import {
  BlockEditorModule,
  BlockEditorModuleSearch,
} from "./BlockEditorModule";
import { BookCall } from "./BookCall";
import { Breadcrumb } from "./Breadcrumb";
import {
  ContentFeatureCardStatic,
  ContentFeatureCardStaticSearch,
} from "./ContentFeatureCardStatic";
import { CtaCardStatic, CtaCardStaticSearch } from "./CtaCardStatic";
import { DescriptionList } from "./DescriptionList";
import { DocumentFilter } from "./DocumentFilter";
import { FactGoRound } from "./FactGoRound";
import { FaqDetails, FaqDetailSearch } from "./FaqDetails";
import { FaqInPage } from "./FaqInPage";
import { FaqListing } from "./FaqListing";
import { FinePrint } from "./FinePrint";
import { FooterForm, FooterFormSearch } from "./FooterForm";
import { Goal } from "./Goal";
import {
  HeaderLevelOneHome,
  HeaderLevelOneHomeSearch,
} from "./HeaderLevelOneHome";
import {
  HeaderLevelThreeContent,
  HeaderLevelThreeContentSearch,
} from "./HeaderLevelThreeContent";
import {
  HeaderLevelTwoMarketing,
  HeaderLevelTwoMarketingSearch,
} from "./HeaderLevelTwoMarketing";
import {
  HeaderLevelTwoProduct,
  HeaderLevelTwoProductSearch,
} from "./HeaderLevelTwoProduct";
import { HydroGraph, HydroTable } from "./Hydro";
import { InArticleRelatedArticles } from "./InArticleRelatedArticles";
import { InPageForm, InPageFormSearch } from "./InPageForm";
import { BusinessFuelType } from "./Join/BusinessFuelType";
import { BusinessType } from "./Join/BusinessType";
import { CustomerType } from "./Join/CustomerType";
import { FuelTypeIntroHeader } from "./Join/FuelTypeIntroHeader";
import { IntroHeader } from "./Join/IntroHeader";
import { ResidentialFuelType } from "./Join/ResidentialFuelType";
import { TrustedBrandsSection } from "./Join/TrustedBrandsSection";
import { LatestArticles } from "./LatestArticles";
import { LatestDocuments } from "./LatestDocuments";
import { Milestone } from "./Milestone";
import { MultipleImage } from "./MultipleImage";
import { Note } from "./Note";
import { QuickLinks } from "./QuickLinks";
import { Quote } from "./Quote";
import { RelatedArticles } from "./RelatedArticles";
import { RelatedDocuments } from "./RelatedDocuments";
import { SectionHeader, SectionHeaderSearch } from "./SectionHeader";
import { SharePriceListing } from "./SharePriceListing";
import {
  SingleImageCardStatic,
  SingleImageCardStaticSearch,
} from "./SingleImageCardStatic";
import { SingleVideoCard, SingleVideoCardSearch } from "./SingleVideoCard";
import { Stat } from "./Stat";
import { TabbedContent, TabbedContentSearch } from "./TabbedContent";

const allModules = {
  RichTextArea,
  GE_API_Form: ApiForm,
  GE_Article_Details: ArticleDetails,
  GE_Article_Filter: ArticleFilter,
  GE_Banner_Bubble: BannerBubble,
  GE_Banner_Floating_Image: BannerFloatingImage,
  GE_BG_Curve: BgCurve,
  GE_Book_a_Call: BookCall,
  GE_Content_Feature_Card_Static: ContentFeatureCardStatic,
  GE_CTA_Card_Static: CtaCardStatic,
  GE_Document_Filter: DocumentFilter,
  GE_FAQ_Details: FaqDetails,
  GE_FAQ_In_Page: FaqInPage,
  GE_Fine_Print: FinePrint,
  GE_Footer_Form: FooterForm,
  GE_Header_Level_1_Home: HeaderLevelOneHome,
  GE_Header_Level_2_Marketing: HeaderLevelTwoMarketing,
  GE_Header_Level_2_Product: HeaderLevelTwoProduct,
  GE_Header_Level_3_Content: HeaderLevelThreeContent,
  GE_In_Article_Related_Articles: InArticleRelatedArticles,
  GE_In_Page_Form: InPageForm,
  GE_Latest_Articles: LatestArticles,
  GE_Latest_Documents: LatestDocuments,
  GE_Related_Articles: RelatedArticles,
  GE_Related_Documents: RelatedDocuments,
  GE_Single_Image_Card_Static: SingleImageCardStatic,
  GE_Single_Video_Card: SingleVideoCard,
  UNI_Accordion: AccordionModule,
  UNI_Block_Editor: BlockEditorModule,
  UNI_Breadcrumb: Breadcrumb,
  UNI_Description_List: DescriptionList,
  UNI_FAQ_Listing: FaqListing,
  UNI_Notes: Note,
  UNI_Section_Header: SectionHeader,
  UNI_Share_Price_Listing: SharePriceListing,
  UNI_Tabbed_Content: TabbedContent,
  GE_Hydro_Table: HydroTable,
  GE_Hydro_Graph_Details: HydroGraph,
  GE_Multiple_Image: MultipleImage,
  UNI_Fact_Go_Round: FactGoRound,
  UNI_Milestone: Milestone,
  UNI_Stat: Stat,
  UNI_Goals: Goal,
  UNI_Quote: Quote,
  GE_Search_Results: Search,
  GE_Quicklinks: QuickLinks,
  GE_JOIN_Customer_Type: CustomerType,
  GE_JOIN_Intro_Header: IntroHeader,
  GE_JOIN_Trusted_Brands_Section: TrustedBrandsSection,
  GE_JOIN_Residential_Fuel_Type: ResidentialFuelType,
  GE_JOIN_Fuel_Type_Intro_Header: FuelTypeIntroHeader,
  GE_JOIN_Business_Type: BusinessType,
  GE_JOIN_Business_Fuel_Type: BusinessFuelType,
};

const searchModules = {
  GE_Banner_Bubble: BannerBubbleSearch,
  GE_Banner_Floating_Image: BannerFloatingImageSearch,
  GE_Content_Feature_Card_Static: ContentFeatureCardStaticSearch,
  GE_CTA_Card_Static: CtaCardStaticSearch,
  GE_Footer_Form: FooterFormSearch,
  GE_Header_Level_1_Home: HeaderLevelOneHomeSearch,
  GE_Header_Level_2_Marketing: HeaderLevelTwoMarketingSearch,
  GE_Header_Level_2_Product: HeaderLevelTwoProductSearch,
  GE_Header_Level_3_Content: HeaderLevelThreeContentSearch,
  GE_In_Page_Form: InPageFormSearch,
  GE_Single_Image_Card_Static: SingleImageCardStaticSearch,
  GE_Single_Video_Card: SingleVideoCardSearch,
  UNI_article: ArticleDetailSearch,
  UNI_Accordion: AccordionModuleSearch,
  UNI_Block_Editor: BlockEditorModuleSearch,
  UNI_FAQ: FaqDetailSearch,
  UNI_Section_Header: SectionHeaderSearch,
  UNI_Tabbed_Content: TabbedContentSearch,
};

function getModule(moduleName = "") {
  const foundModuleName = Object.keys(allModules).find(
    (name) => name.toLowerCase() === moduleName.toLowerCase(),
  );
  const Module = allModules[foundModuleName] || Blank;

  return Module;
}

function getModuleForSearchIndex(moduleName = "") {
  const foundModuleName = Object.keys(searchModules).find(
    (name) => name.toLowerCase() === moduleName.toLowerCase(),
  );
  const Module = searchModules[foundModuleName];

  return Module;
}

export { getModule, getModuleForSearchIndex };
