import { Link } from "components/Link";

function ArticleCardHeader({ url, children }) {
  return (
    <h4 className="font-bold transition text-20/24 hover:text-warm-orange-primary">
      <Link href={url}>
        <a>{children}</a>
      </Link>
    </h4>
  );
}

export { ArticleCardHeader };
