import classNames from "classnames";

function ArticleCard({
  className,
  children,
  horizontal = false,
  testid = "articleCard",
}) {
  const orientationClassName = horizontal ? "flex-row space-x-5" : "flex-col";
  return (
    <div
      data-testid={testid}
      className={classNames("flex", orientationClassName, className)}
    >
      {children}
    </div>
  );
}

export { ArticleCard };
