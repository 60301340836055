import FeatureItem, {
  FeatureItemContainer,
} from "join-form/components/feature-item";
import {
  CREDIT_CARD_DISCOUNT,
  DIRECT_DEBIT_DISCOUNT,
  EBILLING_DISCOUNT,
  PAYMENT_METHOD_CREDIT_CARD,
  PAYMENT_METHOD_DIRECT_DEBIT,
  PAYMENT_METHOD_MANUAL,
  PLAN_TYPE_BASIC,
  PLAN_TYPE_EV_PLUS,
  RESIDENTIAL_PLAN_TERM_FIXED12,
  RESIDENTIAL_PLAN_TERM_FLEXI,
  TWELVE_MONTH_FIXED_TERM_DISCOUNT,
} from "helpers/constants";
import { getTotalCredit } from "helpers/joiningCreditHelper";
import { getDualFuelDiscount } from "helpers/joiningDiscountsHelper";
import { useSelector } from "react-redux";

const termDiscountCopy = {
  [RESIDENTIAL_PLAN_TERM_FIXED12]: `${TWELVE_MONTH_FIXED_TERM_DISCOUNT}% fixed-term discount*`,
  [RESIDENTIAL_PLAN_TERM_FLEXI]: "Flexible, no fixed-term discount",
};

const autoPayDiscountCopy = {
  [PAYMENT_METHOD_DIRECT_DEBIT]: `${DIRECT_DEBIT_DISCOUNT}% Direct Debit discount`,
  [PAYMENT_METHOD_CREDIT_CARD]: `${CREDIT_CARD_DISCOUNT}% Credit Card discount`,
  [PAYMENT_METHOD_MANUAL]: "Manual, no Auto-pay discount",
};

function getPaymentDiscountItem({
  planType,
  isEVExistingCustomer,
  paymentMethod,
}) {
  if (isEVExistingCustomer) {
    return null;
  }
  return planType === PLAN_TYPE_BASIC ? (
    <FeatureItem data-testid="ebillingDiscount" type="complete">
      Easy eBill and Auto-pay**
    </FeatureItem>
  ) : (
    <FeatureItem data-testid="autoPayDiscount" type="complete">
      {autoPayDiscountCopy[paymentMethod]}
    </FeatureItem>
  );
}

function stateSelector(state) {
  const {
    appState: { products, planType },
    appUserState: { paymentMethod, isExistingCustomer },
    residentialPlanConfig: { termData },
  } = state;

  const termItem = termData.find(({ isSelected }) => isSelected) || {};

  const dualFuelDiscount = getDualFuelDiscount(products);
  const joiningCredit = getTotalCredit({
    products,
    plan: planType,
    term: termItem.codeName,
  });

  return {
    termItem,
    paymentMethod,
    dualFuelDiscount,
    joiningCredit,
    planType,
    isEVExistingCustomer:
      planType === PLAN_TYPE_EV_PLUS && isExistingCustomer === "true",
  };
}

function SuccessPageFeatures() {
  const {
    termItem,
    paymentMethod,
    dualFuelDiscount,
    joiningCredit,
    planType,
    isEVExistingCustomer,
  } = useSelector(stateSelector);

  const paymentDiscountItem = getPaymentDiscountItem({
    planType,
    isEVExistingCustomer,
    paymentMethod,
  });

  return (
    <FeatureItemContainer>
      {planType === PLAN_TYPE_BASIC && (
        <>
          <FeatureItem type="complete">Our lowest energy rates</FeatureItem>
          <FeatureItem type="complete">Price certainty</FeatureItem>
        </>
      )}
      {planType === PLAN_TYPE_EV_PLUS && (
        <FeatureItem data-testid="evHalfRates" type="complete">
          50% electricity rates, 9pm - 7am
        </FeatureItem>
      )}
      {joiningCredit && (
        <FeatureItem type="complete" data-testid="joiningCredit">
          {joiningCredit} fixed-term credit*
        </FeatureItem>
      )}
      {planType !== PLAN_TYPE_BASIC && (
        <FeatureItem data-testid="powershoutFeatureItem" type="complete">
          Free Power Shout Hours**
        </FeatureItem>
      )}
      {dualFuelDiscount > 0 && (
        <FeatureItem data-testid="dualFuelDiscount" type="complete">
          {dualFuelDiscount}% Dual Fuel discount
        </FeatureItem>
      )}
      {planType !== PLAN_TYPE_BASIC && (
        <FeatureItem data-testid="ebillingDiscount" type="complete">
          {EBILLING_DISCOUNT}% eBilling discount
        </FeatureItem>
      )}
      {planType === PLAN_TYPE_BASIC ? (
        <FeatureItem data-testid="basicFixedTerm" type="complete">
          Low rates fixed for 12 months*
        </FeatureItem>
      ) : (
        <FeatureItem data-testid="termDiscount" type="complete">
          {termDiscountCopy[termItem.codeName]}
        </FeatureItem>
      )}
      {paymentDiscountItem}
    </FeatureItemContainer>
  );
}

export { SuccessPageFeatures };
