import addressFinder from "services/AddressFinder";
import npaService from "services/NpaService";

export const fetchSuggestions = async ({ value }) => {
  const response = await addressFinder.getAddressSuggestions({
    q: value,
  });

  const {
    data: { completions },
  } = response;

  return completions;
};

export const fetchAddressDetails = async ({ pxid }) => {
  const { data } = await addressFinder.getAddressDetails({ pxid });
  return data;
};

export const fetchIcpNumber = async (address) => {
  const icpData = await npaService.findIcpByAddress(address);
  return icpData;
};
