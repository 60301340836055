import { motion } from "framer-motion";

const transition = {
  delay: 0.3,
  type: "spring",
  bounce: 0.3,
  duration: 0.3,
};

const cardContainerAnimation = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.2,
    },
  },
};

const cardFadeInBottomAnimation = {
  hidden: { opacity: 0, y: 20 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.3,
    },
  },
};

function FadeInBottomContainer({ children }) {
  return (
    <motion.div
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true, amount: 0.2 }}
      variants={cardContainerAnimation}
      transition={transition}
    >
      {children}
    </motion.div>
  );
}

function FadeInBottomAnimation({ children }) {
  return (
    <motion.div variants={cardFadeInBottomAnimation}>{children}</motion.div>
  );
}

export { FadeInBottomContainer, FadeInBottomAnimation };
