import Axios from "axios";
import { PRODUCT_ELECTRICITY, PRODUCT_GAS } from "helpers/constants";
import { getRequestHeaders } from "./NpaAuth";

export async function findElecRegistryDetailsByIcp({ icp }) {
  try {
    const { data } = await findRegistryDetailsByIcp({
      icp,
      type: PRODUCT_ELECTRICITY,
    });

    const {
      icpList: { electricityList },
    } = data;

    const [firstElecRegistryDetails] = electricityList;

    return firstElecRegistryDetails;
  } catch (ex) {
    return Promise.reject(ex);
  }
}

export async function findGasRegistryDetailsByIcp({ icp }) {
  try {
    const { data } = await findRegistryDetailsByIcp({
      icp,
      type: PRODUCT_GAS,
    });

    const {
      icpList: { gasList },
    } = data;

    const [firstGasRegistryDetails] = gasList;

    return firstGasRegistryDetails;
  } catch (ex) {
    return Promise.reject(ex);
  }
}

export async function findRegistryDetailsByIcp({ icp, type }) {
  const includeElectricity = type === PRODUCT_ELECTRICITY;
  const includeGas = type === PRODUCT_GAS;

  const requestData = {
    ICPDetail: {
      ICPIdentifier: icp.toUpperCase(),
      includeMeter: true,
    },
    includeElectricity,
    includeGas,
  };

  const requestConfig = await getRequestConfig();
  const url =
    process.env.NEXT_PUBLIC_MULE_API_BASE_URL + "/supplyPoint/getICPList";
  const result = await Axios.post(url, requestData, requestConfig);
  return result;
}

async function getRequestConfig() {
  const headers = await getRequestHeaders();
  const requestConfig = {
    headers,
  };
  return requestConfig;
}
