import { useCallback, useEffect, useState } from "react";

function useScrollDirection() {
  const initY = typeof window !== "undefined" && window.scrollY;
  const [scrollY, setScrollY] = useState(initY);
  const [scrollDir, setScrollDir] = useState("scrollUp");

  const handleScrollDirection = useCallback(
    (e) => {
      const window = e.currentTarget;
      if (scrollY > window.scrollY) {
        setScrollDir("scrollUp");
      } else if (scrollY < window.scrollY) {
        setScrollDir("scrollDown");
      }
      setScrollY(window.scrollY);
    },
    [scrollY],
  );

  useEffect(() => {
    setScrollY(window.scrollY);
    window.addEventListener("scroll", handleScrollDirection);

    return () => {
      window.removeEventListener("scroll", handleScrollDirection);
    };
  }, [handleScrollDirection]);

  return {
    scrollDir,
  };
}

export { useScrollDirection };
