import Axios from "axios";
import { isAfter, parse } from "date-fns";
import {
  find,
  findIndex,
  get,
  isNull,
  isUndefined,
  map,
  omitBy,
  sortBy,
} from "lodash-es";
import { getRequestHeaders } from "services/NpaAuth";
import {
  SUPPLY_POINT_TYPE_ELECTRICITY,
  SUPPLY_POINT_TYPE_GAS,
  SUPPLY_POINT_TYPE_LPG,
} from "helpers/constants";

export const getOffersEndpoint =
  process.env.NEXT_PUBLIC_MULE_API_BASE_URL + "/online/pricing/getOffers";

export class SmeService {
  constructor() {
    this.httpServiceOptions = {
      baseURL: "/api/npa",
      auth: {
        username: process.env.NEXT_PUBLIC_AUTH_USER_NAME,
        password: process.env.NEXT_PUBLIC_AUTH_PASSWORD,
      },
    };

    this.httpService = Axios.create(this.httpServiceOptions);

    this.tariffListSortOrder = ["FIXED", "VARIABLE"];

    this.constants = {
      SUPPLY_POINT_TYPE_ELECTRICITY: "ELECTRICITY",
      SUPPLY_POINT_TYPE_GAS: "GAS",
      SUPPLY_POINT_TYPE_LPG: "LPG",
    };

    this.defaultRegistryAddress = {
      fullAddress: "",
      streetNumber: "",
      streetName: "",
      suburb: "",
      town: "",
      postCode: "",
      state: "",
      geoCoordinates: {
        x: "",
        y: "",
      },
    };
  }

  async findOffersByIcp(icpData) {
    const {
      elecIcp,
      elecRegistryAddress,
      gasIcp,
      gasRegistryAddress,
      lpgIdentifier = "",
      addressCoordinates,
    } = icpData;

    const products = [];

    const registryAddressWithNonEmptyFields =
      this.prepareRegistryAddressForRequest(elecRegistryAddress);

    if (elecIcp) {
      const elecProduct = {
        icpIdentifier: elecIcp,
        registryAddress: registryAddressWithNonEmptyFields,
        type: this.constants.SUPPLY_POINT_TYPE_ELECTRICITY,
      };

      products.push(elecProduct);
    }

    if (gasIcp) {
      const gasRegistryAddressWithNonEmptyFields =
        this.prepareRegistryAddressForRequest(gasRegistryAddress);

      const gasProduct = {
        icpIdentifier: gasIcp,
        registryAddress: gasRegistryAddressWithNonEmptyFields,
        type: this.constants.SUPPLY_POINT_TYPE_GAS,
      };

      products.push(gasProduct);
    }

    if (products.length === 0) {
      return Promise.reject("No ICPs supplied to request offers");
    }

    const lpgProduct = {
      icpIdentifier: lpgIdentifier,
      registryAddress: addressCoordinates,
      type: this.constants.SUPPLY_POINT_TYPE_LPG,
    };

    products.push(lpgProduct);

    const productOffersRequestDataList = map(
      products,
      this.transformDataForOffersRequest,
    );

    const requests = map(
      productOffersRequestDataList,
      this.getOffersForProduct,
    );

    return Promise.all(requests).then((result) => {
      const elecOffersResult = this.findOfferByType(
        result,
        SUPPLY_POINT_TYPE_ELECTRICITY,
      );

      const gasOffersResult = this.findOfferByType(
        result,
        SUPPLY_POINT_TYPE_GAS,
      );

      const lpgOffersResult = this.findOfferByType(
        result,
        SUPPLY_POINT_TYPE_LPG,
      );

      const elecOffers = elecOffersResult.errors
        ? []
        : this.transformOfferResult(elecOffersResult);

      const gasOffers = gasOffersResult.errors
        ? []
        : this.transformOfferResult(gasOffersResult);

      const lpgOffers = lpgOffersResult.errors
        ? []
        : this.transformOfferResult(lpgOffersResult);

      return {
        elecOffers,
        gasOffers,
        lpgOffers,
        elecOffersResult,
        gasOffersResult,
        lpgOffersResult,
      };
    });
  }

  findOfferByType = (allOffers, type) => {
    return find(allOffers, (offer) => offer.type === type) || {};
  };

  prepareRegistryAddressForRequest = (registryAddress) => {
    return omitBy(registryAddress, isNull);
  };

  transformDataForOffersRequest = ({
    icpIdentifier,
    registryAddress,
    type,
  }) => {
    if (type === SUPPLY_POINT_TYPE_LPG) {
      return {
        channel: "ONLINE",
        planTypes: ["SME-STANDARD"],
        supplyPoint: {
          type,
          chargeClass: "BT45",
          installType: "SME",
        },
        registryAddress,
      };
    }
    const request = {
      channel: "ONLINE",
      planTypes: ["SME-STANDARD"],
      supplyPoint: {
        type,
        ICPIdentifier: icpIdentifier,
        chargeClass: "BUSINESS",
        installType: "SME",
        registryAddress,
      },
    };
    return request;
  };

  getOffersForProduct = async (requestData) => {
    const requestConfig = await this.getRequestConfig();
    try {
      const { data } = await Axios.post(
        getOffersEndpoint,
        requestData,
        requestConfig,
      );
      return data;
    } catch (errors) {
      let errorResponse = get(errors, "response.data", {});
      errorResponse.type = get(requestData, "supplyPoint.type", "");
      return errorResponse;
    }
  };

  getRequestConfig = async () => {
    const headers = await getRequestHeaders();
    const requestConfig = {
      headers,
    };
    return requestConfig;
  };

  transformOfferResult = (data) => {
    if (isUndefined(data) || isUndefined(data.offerList)) {
      return [];
    }

    const now = new Date(Date.now());

    const offerList = data.offerList
      .filter(
        (o) => !isAfter(now, parse(o.effectiveToDate, "yyyy-MM-ddXXX", now)),
      )
      .map(this.transformPlanData);

    return offerList;
  };

  transformPlanData = (offer) => {
    return {
      ...offer,
      priceBook: {
        ...offer.priceBook,
        tariffList: this.sortTariffList(offer.priceBook.tariffList),
      },
    };
  };

  sortTariffList = (tariffList) => {
    const sortedTariffList = sortBy(tariffList, ({ type }) =>
      findIndex(this.tariffListSortOrder, (tariffListItemType) => {
        const result = tariffListItemType === type;
        return result;
      }),
    );
    return sortedTariffList;
  };
}

const smeService = new SmeService();
export default smeService;
