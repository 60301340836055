import { Select } from "components/Select";
import { ErrorMessage } from "./ErrorMessage";

const DropDown = ({ label, name, register, value, errorMessage, children }) => (
  <div>
    <Select
      id={name}
      name={name}
      label={label}
      value={value}
      {...register(name)}
    >
      {children}
    </Select>
    {errorMessage && (
      <ErrorMessage message={errorMessage} testId={`${name}ErrorMessage`} />
    )}
  </div>
);

export { DropDown };
