import { motion } from "framer-motion";

const transition = {
  delay: 0.3,
  type: "spring",
  bounce: 0.3,
};

function ScaleAnimation({ children, style, className }) {
  return (
    <motion.div
      initial={{ scale: 1.05, opacity: 0 }}
      animate={{ scale: 1, opacity: 1 }}
      transition={{ delay: 0.3, type: "tween" }}
      style={style}
      className={className}
    >
      {children}
    </motion.div>
  );
}

function FadeInAnimation({ children }) {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={transition}
    >
      {children}
    </motion.div>
  );
}

function FadeInTopAnimation({ children }) {
  return (
    <motion.div
      initial={{ y: -20, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={transition}
    >
      {children}
    </motion.div>
  );
}

function FadeInBottomAnimation({ children, isPrimary }) {
  return (
    <motion.div
      initial={{ y: isPrimary ? 20 : 60, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={transition}
    >
      {children}
    </motion.div>
  );
}

export {
  ScaleAnimation,
  FadeInAnimation,
  FadeInTopAnimation,
  FadeInBottomAnimation,
};
