import FeatureItem from "join-form/components/feature-item";
import {
  BUSINESS_DIRECT_DEBIT_DISCOUNT,
  PAYMENT_METHOD_DIRECT_DEBIT,
  PAYMENT_METHOD_MANUAL,
} from "helpers/constants";

export default function AutoPayFeature({ paymentMethod, ...rest }) {
  if (paymentMethod === PAYMENT_METHOD_MANUAL) {
    return (
      <FeatureItem type="complete" {...rest}>
        Manual, no Auto-pay discount
      </FeatureItem>
    );
  }

  const isSelected = paymentMethod === PAYMENT_METHOD_DIRECT_DEBIT;

  const type = isSelected ? "complete" : "";

  const text = isSelected
    ? `${BUSINESS_DIRECT_DEBIT_DISCOUNT}% Direct Debit discount`
    : "Auto-pay discount";

  return (
    <FeatureItem type={type} {...rest}>
      {text}
    </FeatureItem>
  );
}
