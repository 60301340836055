import React from "react";
import { camelCase, snakeCase } from "lodash-es";
import ReactDOMServer from "react-dom/server";
import { ArrowRight, CheckboxOff, CheckboxOn, ChevronDown } from "assets/icons";

const checkboxSvg = ReactDOMServer.renderToString(
  <>
    <CheckboxOff className="fsCheckboxIcon fsCheckboxOff" />
    <CheckboxOn className="fsCheckboxIcon fsCheckboxOn" />
  </>,
);
const selectIconSvg = ReactDOMServer.renderToString(
  <ChevronDown className="fsSelectIcon" />,
);
const arrowRightSvg = ReactDOMServer.renderToString(
  <ArrowRight className="fsIcon" />,
);

function isStyleSheet(token) {
  return token?.tagName === "link" && token?.attrs?.rel === "stylesheet";
}

function addFieldTypeClassName(node) {
  const inputList = node.querySelectorAll("[data-fs-field-name]");
  inputList.forEach((input) => {
    const fieldType = input.getAttribute("data-fs-field-name");
    if (fieldType) {
      input.classList.add(camelCase(`fs-${fieldType}`));
    }
  });
}

function updateCheckboxIcon(node) {
  const checkboxInputList = node.querySelectorAll(".fsCheckbox ");
  checkboxInputList.forEach((checkboxInputNode) => {
    const hasNewCheckbox = checkboxInputNode.parentElement.querySelector(".fsCheckboxIconContainer");
    if (!hasNewCheckbox) {
      const checkboxIconNode = document.createElement("div"); 
      checkboxIconNode.className = "fsCheckboxIconContainer";
      checkboxIconNode.innerHTML = checkboxSvg;
      checkboxInputNode.after(checkboxIconNode);
    }
    });
}

function updatePaginationButtonIcon(node) {
  const paginationButtonList = node.querySelectorAll(
    ".fsNextButton, .fsPreviousButton",
  );

  paginationButtonList.forEach((paginationButton) => {
    let iconContainer = paginationButton.querySelector(".fsSlim");
    if (!iconContainer) {
      paginationButton.insertAdjacentHTML("beforeend", "<span class=\"fsSlim\"></span>")
      iconContainer = paginationButton.querySelector(".fsSlim");
    }
    if (iconContainer) {      
      iconContainer.innerHTML = arrowRightSvg;
    }
  });
}

function updateSelectDropdown(node) {
  const selectElementList = node.querySelectorAll("select.fsField");

  selectElementList.forEach((selectElement) => {
    const wrapperDiv = document.createElement("div");
    wrapperDiv.className = "fsSelectContainer";
    wrapperDiv.innerHTML = selectIconSvg;

    // insert wrapperDiv before select in the DOM tree
    selectElement.parentNode.insertBefore(wrapperDiv, selectElement);

    wrapperDiv.prepend(selectElement);
  });
}

function onFileChange(event) {
  const fieldCell = event.target.closest(".fsFieldCell");
  const hasFiles = event.target?.files?.length > 0;
  fieldCell?.classList.toggle("fsHasFiles", hasFiles);
}

function onRemoveFileClick(event) {
  const fieldCell = event.target.closest(".fsFieldCell");
  fieldCell?.classList.remove("fsHasFiles");
}

function addEventsToFileUploadField(node) {
  const fileUploadList = node.querySelectorAll("input.fsUpload");
  fileUploadList.forEach((fileInput) => {
    fileInput.addEventListener("change", onFileChange);
  });

  const removeFileButtons = node.querySelectorAll(
    `input[type="button"][value="Remove File"]`,
  );
  removeFileButtons.forEach((removeFileButton) => {
    removeFileButton.addEventListener("click", onRemoveFileClick);
  });
}

function updateEmailField(node) {
  const emailField = node.querySelectorAll(".fsFieldEmail");
  emailField.forEach((field) => {
    field.parentElement.classList.add("fsSubField");
  });
}

function addRadioButtonHorizontalClass(node) {
  const radioButtonContentFields = node.querySelectorAll(
    ".fsRadio .fieldset-content",
  );
  radioButtonContentFields.forEach((field) => {
    const horizontalRadioButton = field.querySelector(
      ".fsOptionLabel .horizontal",
    );
    if (horizontalRadioButton) {
      field.classList.add("fieldset-content-horizontal");
      field.classList.remove("fieldset-content");
    }
  });
}

function transformLabels(node) {
  const labelFields = node.querySelectorAll(".fsSupporting");
  labelFields.forEach((labelField) => {
    // An exception to lowercase subsequent texts in formstack internal fields
    if (labelField.textContent !== "ZIP / Postal") {
      const textToTransform = labelField.textContent.split(" ");
      const transformedText = textToTransform.slice(1).join(" ").toLowerCase();
      labelField.textContent =
        `${textToTransform[0]} ${transformedText}`.trim();
    }
  });
}

function labelTelInputs(node) {
  const telInputs = node.querySelectorAll(".fsFieldPhone");
  telInputs.forEach((telInput) => {
    telInput.setAttribute("label", "Phone");
  });
}
function addPlaceholderToInputFields(node) {
  const allTextInputs = node.querySelectorAll("input");
  allTextInputs.forEach((textInput) => {  
    if (!textInput.classList.contains('MuiInputBase-input')) {      
      textInput.placeholder = "_";
    }
  });
}


function addLabelToInputFields(node) {
  const allTextInputs = node.querySelectorAll(".fsFieldShortAnswer, .fsFieldEmail, .fsFieldPhone, .fsNumber"); 
  allTextInputs.forEach((textInput) => {
    const siblingElements = Array.from(textInput.parentNode.children);
    if (siblingElements[0].tagName === 'LABEL') {
      siblingElements[0].remove();
      siblingElements.shift();
    }
    const hasLabelSibling = siblingElements.some(sibling => sibling !== textInput && sibling.tagName === 'LABEL');

    if (!hasLabelSibling) {
      const labelElement = document.createElement("label");
      if (textInput.getAttribute('type') === 'tel') {
        const labelFormstack = textInput.closest('.fsFieldCell').querySelector('.fsLabel');
        let labelText = "";
        if (labelFormstack) {
          for (const child of labelFormstack.children) {
            if (child.textContent) {  
              labelText += child.textContent;
            }
          }
        }
        labelElement.textContent = labelText;
      } else {
        labelElement.textContent = textInput.getAttribute('label');
        if (textInput.getAttribute('required') === '') {
          labelElement.textContent += "*";
        }
      }
      labelElement.setAttribute('for', textInput.getAttribute('id'));
      textInput.parentElement.append(labelElement); 
    }
  });
}
function addLabeltoFileField(node) {
  const fileInputs = node.querySelectorAll(".fsFieldFile");
  fileInputs.forEach((fileInput) => {
    const label = fileInput.parentElement.querySelector(".fsLabel span");
    if (label) {
      label.classList.add("show-label", "file-label");
    }
  });
}
function addRequiredTagToInputFields(node) {
  const inputs = node.querySelectorAll("input"); 
  inputs.forEach((input) => {
    if (input.getAttribute("required") === "") {
      const label = input.parentElement.querySelector("label");
      if (label && (!label.textContent.includes("*"))) {        
        label.textContent += "*";
      }
    }
  });
}
function addRequiredTagToSelectTextareas(node) {
  const selects = node.querySelectorAll("select, textarea, .fsDateField");
  selects.forEach((select) => {
    const label = select.parentElement.querySelector(".fsLabel");
    if (label && label.classList.contains('fsRequiredLabel')) {
      label.classList.add('show-label'); 
    }
  });
}
function addClassnamesToNoLabelInputs(node) {
  const inputs = node.querySelectorAll("select, textarea, fieldset, .fsDateField, .fsDatetime");
  inputs.forEach((input) => { 
    const label = input.parentElement.querySelector(".fsLabel span");
    if (label) {
      label.classList.add("show-label");
      if (input.tagName === "FIELDSET") {
        label.classList.add("fieldset-label");
      }
    } 
  });
}

function cleanupDateInput(node) {
  const dateInputs = node.querySelectorAll(".MuiInputAdornment-root, .MuiOutlinedInput-notchedOutline");
  dateInputs.forEach((input) => { input.remove(); }); 
}

function updateFormLayout(formStackContainerElement) {
  addFieldTypeClassName(formStackContainerElement);
  updateCheckboxIcon(formStackContainerElement);
  updatePaginationButtonIcon(formStackContainerElement);
  updateSelectDropdown(formStackContainerElement);
  addEventsToFileUploadField(formStackContainerElement);
  updateEmailField(formStackContainerElement);
  addRadioButtonHorizontalClass(formStackContainerElement);
  labelTelInputs(formStackContainerElement);
  addPlaceholderToInputFields(formStackContainerElement);
  addLabelToInputFields(formStackContainerElement);
  addLabeltoFileField(formStackContainerElement);
  cleanupDateInput(formStackContainerElement); 
  addRequiredTagToInputFields(formStackContainerElement);
  addRequiredTagToSelectTextareas(formStackContainerElement);
  addClassnamesToNoLabelInputs(formStackContainerElement);
}

function getFormName(formStackField) {
  try {
    const parsedFieldData = JSON.parse(formStackField);
    const formName = parsedFieldData?.name?.toLowerCase();
    return snakeCase(formName); 
  } catch {
    return null;
  }
}

export { isStyleSheet, updateFormLayout, getFormName };
