import AddressItem from "./AddressItem";
import CarRegistrationItem from "./CarRegistrationItem";
import ContactDetailsItem from "./ContactDetailsItem";
import CreditCheckItem from "./CreditCheckItem";
import CustomerTypeItem from "./CustomerTypeItem";
import FuelItem from "./FuelItem";
import PlanSelectItem from "./PlanSelectItem";
import PropertyItem from "./PropertyItem";

export default function ResidentialStepNavigationItems() {
  return (
    <>
      <CustomerTypeItem />
      <AddressItem />
      <FuelItem />
      <PlanSelectItem />
      <CarRegistrationItem />
      <ContactDetailsItem />
      <CreditCheckItem />
      <PropertyItem />
    </>
  );
}
