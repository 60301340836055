const setNumberFormat = (value) => {
  if (!global.IntlNumberFormat) {
    global.IntlNumberFormat = new Intl.NumberFormat("en-NZ", {
      style: "currency",
      currency: "NZD",
      minimumFractionDigits: 2,
    });
  }
  return global.IntlNumberFormat.format(value);
};

export const formatCurrency = (value, withSymbol) =>
  `${setNumberFormat(value)}${withSymbol ? " NZD" : ""}`;

export const formatVolume = (value) => `${Number(value).toLocaleString()}k`;

// 0 => 0.0, .1 => 0.1
export const paddLeadingZero = (str) =>
  str.replace(/^0/, "0.").replace(/^\./, "0.");

// $1.23 => <><sup>$</sup>1.23</>
export const superscriptDollarSymbol = (str, className = "") => {
  if (str.startsWith("$")) {
    const strSplit = str.split("$");
    return (
      <>
        <sup className={className}>$</sup>
        {strSplit[1]}
      </>
    );
  }
  return <>{str}</>;
};

export const getSafeNumber = (value, fallbackValue = 0) => {
  const parsedValue = Number(value);
  return isNaN(parsedValue) ? fallbackValue : parsedValue;
};

export const formatStat = (stat) => {
  const str = stat.toLowerCase();
  switch (true) {
    // $123k
    case str.startsWith("$") && str.endsWith("k") && !isNaN(str.slice(1, -1)):
      return {
        prepend: "$",
        value: Number(str.slice(1, -1)),
        append: "k",
      };
    // 123k
    case str.endsWith("k") && !isNaN(str.slice(0, -1)):
      return {
        value: Number(str.slice(0, -1)),
        append: "k",
      };
    // $123
    case str.startsWith("$") && !isNaN(str.slice(1)):
      return {
        prepend: "$",
        value: Number(str.slice(1).trim()),
      };
    // 12%
    case str.endsWith("%") && !isNaN(str.slice(0, -1)):
      return {
        value: Number(str.slice(0, -1).trim()),
        append: "%",
      };
    case str.endsWith("m") && !isNaN(str.slice(0, -1)):
      return {
        value: Number(str.slice(0, -1).trim()),
        append: "m",
      };
    case str.endsWith("mil") && !isNaN(str.slice(0, -3)):
      return {
        value: Number(str.slice(0, -3).trim()),
        append: "mil",
      };
    case str.endsWith(" goal") && !isNaN(str.slice(0, -4)):
      return {
        value: Number(str.slice(0, -4).trim()),
        append: " goal",
      };
    case str.endsWith(" goals") && !isNaN(str.slice(0, -5)):
      return {
        value: Number(str.slice(0, -5).trim()),
        append: " goals",
      };
    case !isNaN(str):
      return {
        value: Number(str),
      };
    default:
      return { value: str };
  }
};
