import React from "react";
import classNames from "classnames";
import { ChevronDown } from "assets/icons";

const defaultButtonStyles = {
  background:
    "bg-gradient-to-r from-transparent-bright-gray-80 to-transparent-bright-gray-20",
  border: "border-black-50",
  chevron: "",
};

const activeButtonStyles = {
  background: "bg-transparent",
  border: "border-transparent",
  chevron: "rotate-180",
};

function ArticleFilterButton({ children, onClick, isActive }, ref) {
  const { background, border, chevron } = isActive
    ? activeButtonStyles
    : defaultButtonStyles;

  return (
    <button
      className={classNames(
        "flex items-center justify-between w-full p-4 rounded-4",
        "text-18/28 text-black-primary font-medium border",
        "scroll-mt-22",
        background,
        border,
      )}
      onClick={onClick}
      ref={ref}
    >
      <span>{children}</span>
      <span
        className={classNames(
          "transition",
          "flex items-center justify-center w-8 h-8 rounded-full bg-black-50",
          chevron,
        )}
      >
        <ChevronDown className="w-5 h-5" />
      </span>
    </button>
  );
}

ArticleFilterButton = React.forwardRef(ArticleFilterButton);

export { ArticleFilterButton };
