import { take } from "lodash-es";
import { ArticleCarouselSection } from "components/ArticleCarouselSection";
import { ArticleItem } from "page-modules/ArticleItem";

function InArticleRelatedArticles({ module, dynamicPageItem }) {
  const { title, cta } = module.fields;
  const { relatedArticles } = dynamicPageItem.fields;

  const topFourRelatedArticles = take(relatedArticles, 4);

  return topFourRelatedArticles.length ? (
    <ArticleCarouselSection
      title={title}
      cta={cta}
      testid="inArticleRelatedArticlesSection"
    >
      {topFourRelatedArticles.map((item) => (
        <ArticleItem key={item.contentID} data={item} />
      ))}
    </ArticleCarouselSection>
  ) : null;
}

export { InArticleRelatedArticles };
