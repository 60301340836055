import Axios from "axios";
import {
  compact,
  filter,
  find,
  findIndex,
  get,
  head,
  includes,
  isUndefined,
  map,
  some,
  sortBy,
} from "lodash-es";
import { v4 as uuidv4 } from "uuid";
import {
  compareRegistryAddresses,
  getFullRegistryAddress,
} from "helpers/addressHelper";
import { getToken } from "helpers/apiHelper";
import {
  DEFAULT_CAMPAIGN_CODES,
  PLAN_HIGH_USAGE_CODES,
  PLAN_LOW_USAGE_CODES,
  PLAN_TYPE_BASIC,
  PLAN_TYPE_PLUS,
  PLUS_ENERGY_EV_CAMPAIGN_CODE,
  PLUS_PLAN_FIXED_TERM_CODES,
  PRODUCT_ELECTRICITY,
} from "helpers/constants";
import { ensureNpaAccessToken, getRequestHeaders } from "./NpaAuth";

const LPG_CAMPAIGN_CODES = [
  "FLEXI18",
  "PLUSE",
  "PLUSG",
  "PLUSL",
  "PLUSE12",
  "PLUSG12",
  "PLUSL12",
  "BASCE12",
  "BASCG12",
  "BASCL12",
];

export const STORAGE_KEY = "npaAuth";

export class NpaService {
  constructor() {
    this.config = {
      storageKey: STORAGE_KEY,
      accessTokenUrl: "/api/npa/accesstoken",
    };

    this.httpServiceOptions = {
      baseURL: process.env.NEXT_PUBLIC_MULE_API_BASE_URL,
    };

    this.httpService = Axios.create(this.httpServiceOptions);

    this.constants = {
      SUPPLY_POINT_TYPE_ELECTRICITY: "ELECTRICITY",
      SUPPLY_POINT_TYPE_GAS: "GAS",
      SUPPLY_POINT_TYPE_LPG: "LPG",
    };

    this.tariffListSortOrder = ["FIXED", "VARIABLE"];

    this.defaultRegistryAddress = {
      fullAddress: "",
      streetNumber: "",
      streetName: "",
      suburb: "",
      town: "",
      postCode: "",
      state: "",
      geoCoordinates: {
        x: "",
        y: "",
      },
    };

    this.bindRequestHeaders(this.httpService);
  }

  bindRequestHeaders(httpService) {
    httpService.interceptors.request.use(
      this.onInterceptRequestFulfilled.bind(this),
      this.onInterceptRequestRejected.bind(this),
    );
    return httpService;
  }

  async onInterceptRequestFulfilled(config) {
    const accessToken = await getToken();
    config = this.applyRequestHeaders({ config, accessToken });
    return Promise.resolve(config);
  }

  onInterceptRequestRejected(error) {
    return Promise.reject(error);
  }

  applyRequestHeaders({ config, accessToken }) {
    config.headers.client_id = process.env.NEXT_PUBLIC_MULE_API_CLIENT_ID;
    config.headers.client_secret =
      process.env.NEXT_PUBLIC_MULE_API_CLIENT_SECRET;
    config.headers.Authorization = `Bearer ${accessToken}`;
    config.headers["X-Transaction-ID"] = uuidv4();
    config.headers["X-Brand-ID"] = process.env.NEXT_PUBLIC_MULE_API_BRAND_ID;
    config.headers["X-Application-ID"] =
      process.env.NEXT_PUBLIC_MULE_API_APPLICATION_ID;
    config.headers["X-Channel-ID"] =
      process.env.NEXT_PUBLIC_MULE_API_CHANNEL_ID;

    return config;
  }

  async findIcpByAddress(addressFromFinder) {
    const requestData = this.transformAddressForIcpRequest(addressFromFinder);
    const request = this.httpService
      .post("/supplyPoint/getICPList", requestData)
      .then(({ data }) => {
        return this.filterIcpResult(data);
      })
      .then((filteredIcpData) =>
        this.transformIcpResult(filteredIcpData, requestData.siteAddress),
      );
    return request;
  }

  async getRequestConfig() {
    const headers = await getRequestHeaders();
    const requestConfig = {
      headers,
    };
    return requestConfig;
  }

  async findOffersByIcp(icpData) {
    const campaignCodes = DEFAULT_CAMPAIGN_CODES;
    const {
      elecIcp,
      elecRegistryAddress,
      gasIcp,
      gasRegistryAddress,
      lpgIdentifier = "",
    } = icpData;

    const products = [];

    if (elecIcp) {
      const elecProduct = {
        icpIdentifier: elecIcp,
        registryAddress: elecRegistryAddress,
        type: this.constants.SUPPLY_POINT_TYPE_ELECTRICITY,
        campaignCodes,
      };

      products.push(elecProduct);
    }

    if (gasIcp) {
      const gasProduct = {
        icpIdentifier: gasIcp,
        registryAddress: gasRegistryAddress,
        type: this.constants.SUPPLY_POINT_TYPE_GAS,
        campaignCodes,
      };

      products.push(gasProduct);
    }

    if (products.length === 0) {
      return Promise.reject("No ICPs supplied to request offers");
    }

    const lpgProduct = {
      icpIdentifier: lpgIdentifier,
      registryAddress: elecRegistryAddress,
      type: this.constants.SUPPLY_POINT_TYPE_LPG,
      campaignCodes,
    };

    products.push(lpgProduct);

    const productOffersRequestDataList = map(
      products,
      this.transformDataForOffersRequest,
    );

    await ensureNpaAccessToken();

    const requests = map(
      productOffersRequestDataList,
      this.getOffersForProduct,
    );

    return Promise.all(requests)
      .then((result) => {
        const elecOffersResult =
          find(
            result,
            (offer) =>
              offer.type === this.constants.SUPPLY_POINT_TYPE_ELECTRICITY,
          ) || {};
        const gasOffersResult =
          find(
            result,
            (offer) => offer.type === this.constants.SUPPLY_POINT_TYPE_GAS,
          ) || {};
        const lpgOffersResult =
          find(
            result,
            (offer) => offer.type === this.constants.SUPPLY_POINT_TYPE_LPG,
          ) || {};

        const elecOffer = this.transformOfferResult(elecOffersResult);
        const gasOffer = this.transformOfferResult(gasOffersResult);
        const lpgOffer = this.transformLpgOfferResult(lpgOffersResult);

        return {
          elecOffer,
          gasOffer,
          lpgOffer,
          elecOffersResult,
          gasOffersResult,
          lpgOffersResult,
        };
      })
      .catch(() => {
        return {
          elecOffer: {
            hasEvPlan: true,
          },
          gasOffer: {},
          lpgOffer: {},
          elecOffersResult: {},
          gasOffersResult: {},
          lpgOffersResult: {},
        };
      });
  }

  getOffersForProduct = async (requestData) => {
    return this.httpService
      .post("/online/pricing/getOffers", requestData)
      .then(({ data }) => {
        return data;
      })
      .catch(() => {
        return {
          type: requestData.supplyPoint.type,
          offerList: [],
        };
      });
  };

  transformLpgOfferResult = (lpgData) => {
    if (isUndefined(lpgData)) {
      return {};
    }
    const plansMatchingCampaignCodes = filter(lpgData.offerList, ({ code }) =>
      some(LPG_CAMPAIGN_CODES, (campaignCode) => campaignCode === code),
    );

    const basicPlan = this.transformPlanData(
      plansMatchingCampaignCodes,
      PLAN_TYPE_BASIC,
    );
    const plusPlan = this.transformPlanData(
      plansMatchingCampaignCodes,
      PLAN_TYPE_PLUS,
    );

    const result = {
      basicPlan,
      plusPlan,
    };

    return result;
  };

  transformOfferResult = (elecData) => {
    if (isUndefined(elecData)) {
      return {};
    }

    const offerList = elecData.offerList ?? [];

    const plansMatchingCampaignCodes = filter(offerList, ({ code }) =>
      some(DEFAULT_CAMPAIGN_CODES, (campaignCode) => campaignCode === code),
    );

    const nonEvPlans = plansMatchingCampaignCodes.filter(
      (plan) => plan.code !== PLUS_ENERGY_EV_CAMPAIGN_CODE,
    );

    const evPlans = plansMatchingCampaignCodes.filter(
      (plan) => plan.code === PLUS_ENERGY_EV_CAMPAIGN_CODE,
    );

    const basicPlan = this.transformPlanData(nonEvPlans, PLAN_TYPE_BASIC);

    const plusPlan = this.transformPlanData(nonEvPlans, PLAN_TYPE_PLUS);

    const evPlan = this.transformPlanData(evPlans, PLAN_TYPE_PLUS);

    const maybeTheAddressIsEligibleForEvPlan = offerList.length === 0;

    const result = {
      basicPlan,
      plusPlan,
      evPlan,
      hasEvPlan: evPlans.length > 0 || maybeTheAddressIsEligibleForEvPlan,
      smartMeter: elecData.smartMeter,
    };

    return result;
  };

  transformPlanData = (plans, type) => {
    const plansMatchingType = filter(
      plans,
      ({ priceBook, code }) =>
        priceBook.type === type && !PLUS_PLAN_FIXED_TERM_CODES.includes(code),
    );

    const lowUsagePlan =
      find(plansMatchingType, ({ priceBook }) =>
        includes(PLAN_LOW_USAGE_CODES, priceBook.category.code),
      ) || {};
    const lowUsagePlanDiscountList = get(
      lowUsagePlan,
      "discountsAndIncentives.discountList",
      [],
    );
    const lowUsagePlanTariffList = get(
      lowUsagePlan,
      "priceBook.tariffList",
      [],
    );
    const lowUsagePlanTariffListFiltered = this.filterTariffList(
      lowUsagePlanTariffList,
    );
    const lowUsagePlanTariffListSorted = this.sortTariffList(
      lowUsagePlanTariffListFiltered,
    );

    const highUsagePlan =
      find(plansMatchingType, ({ priceBook }) =>
        includes(PLAN_HIGH_USAGE_CODES, priceBook.category.code),
      ) || {};
    const highUsagePlansDiscountList = get(
      highUsagePlan,
      "discountsAndIncentives.discountList",
      [],
    );
    const highUsagePlansTariffList = get(
      highUsagePlan,
      "priceBook.tariffList",
      [],
    );
    const highUsagePlansTariffListFiltered = this.filterTariffList(
      highUsagePlansTariffList,
    );
    const highUsagePlansTariffListSorted = this.sortTariffList(
      highUsagePlansTariffListFiltered,
    );

    const result = {
      lowUsage: {
        discounts: lowUsagePlanDiscountList,
        tariffList: lowUsagePlanTariffListSorted,
        data: lowUsagePlan,
      },
      highUsage: {
        discounts: highUsagePlansDiscountList,
        tariffList: highUsagePlansTariffListSorted,
        data: highUsagePlan,
      },
    };

    return result;
  };

  filterTariffList = (tariffList) => {
    return filter(
      tariffList,
      ({ description }) => description.toLowerCase().indexOf("homegen") === -1,
    );
  };

  sortTariffList = (tariffList) => {
    const sortedTariffList = sortBy(tariffList, ({ type }) =>
      findIndex(this.tariffListSortOrder, (tariffListItemType) => {
        const result = tariffListItemType === type;
        return result;
      }),
    );
    return sortedTariffList;
  };

  transformDataForOffersRequest = ({ icpIdentifier, type, campaignCodes }) => {
    const request = {
      channel: "WEB",
      campaignCodes,
      planTypes: ["NPA-PLUS", "NPA-BASIC", "NPA-PREMIUM"],
      supplyPoint: {
        type,
        ICPIdentifier: icpIdentifier,
        chargeClass: "PRIMARY",
        installType: "RESIDENTIAL",
      },
    };
    if (type === PRODUCT_ELECTRICITY.toUpperCase()) {
      request.returnEVRates = true;
    }
    return request;
  };

  transformAddressForIcpRequest(addressFromFinder) {
    const building = addressFromFinder.building_name || "";
    const unit = addressFromFinder.unit_identifier || "";
    const streetNumber = compact([
      addressFromFinder.number,
      addressFromFinder.alpha,
    ]).join(" ");

    const suburb = addressFromFinder.suburb || "";
    const town = addressFromFinder.city || addressFromFinder.mailtown || "";
    const state = addressFromFinder.region || "";
    const result = {
      siteAddress: {
        building,
        unit,
        streetNumber,
        streetName: addressFromFinder.street,
        suburb,
        town,
        state,
      },
      includeElectricity: true,
      includeGas: true,
      includeLPG: true,
    };
    return result;
  }

  normalizeRegistryAddress = (icpDataItem) => {
    const { registryAddress = {} } = icpDataItem;
    const fullAddress = getFullRegistryAddress(registryAddress);
    const registryAddressModified = Object.assign(
      {},
      icpDataItem.registryAddress,
      { fullAddress },
    );
    return Object.assign({}, icpDataItem, {
      registryAddress: registryAddressModified,
    });
  };

  findAddressMatchInGasRegistry(gasList, elecRegistryAddress) {
    const result =
      find(gasList, ({ registryAddress }) =>
        compareRegistryAddresses(elecRegistryAddress, registryAddress),
      ) || {};
    return result;
  }

  findAddressMatchInElecRegistry(elecList, transformedAddressFromFinder) {
    if (elecList.length > 1) {
      return {};
    }
    const elecItem = head(elecList);
    const {
      registryAddress: { streetNumber: elecStreetNumber },
    } = elecItem;
    const { streetNumber: addressFinderStreetNumber } =
      transformedAddressFromFinder;

    const doesStreetNumberMatch =
      elecStreetNumber.toLowerCase() ===
      addressFinderStreetNumber.toLowerCase();
    const result = doesStreetNumberMatch ? elecItem : {};
    return result;
  }

  filterIcpResult(data) {
    const { icpList } = data;
    icpList.electricityList = filter(
      icpList.electricityList,
      ({ registryAddress }) => registryAddress.streetNumber !== "",
    );
    return data;
  }

  transformIcpResult(data, transformedAddressFromFinder) {
    const { icpList } = data;

    icpList.electricityList = map(
      icpList.electricityList,
      this.normalizeRegistryAddress,
    );
    icpList.gasList = map(icpList.gasList, this.normalizeRegistryAddress);

    const elecMatch = this.findAddressMatchInElecRegistry(
      icpList.electricityList,
      transformedAddressFromFinder,
    );
    const lpgMatch = icpList.lpgList.length === 1 ? head(icpList.lpgList) : {};

    const elecIcp = elecMatch.ICPIdentifier || "";
    const elecHasMultipleResults = icpList.electricityList.length > 1;
    const elecRegistryAddress =
      elecMatch.registryAddress || this.defaultRegistryAddress;

    const gasMatch =
      elecIcp !== ""
        ? this.findAddressMatchInGasRegistry(
            icpList.gasList,
            elecRegistryAddress,
          )
        : {};

    const gasIcp = gasMatch.ICPIdentifier || "";
    const gasHasMultipleResults = icpList.gasList.length > 1;
    const gasRegistryAddress =
      gasMatch.registryAddress || this.defaultRegistryAddress;

    const lpgIcp = lpgMatch.ICPIdentifier || "";
    const lpgHasMultipleResults = icpList.lpgList.length > 1;
    const lpgRegistryAddress =
      lpgMatch.registryAddress || this.defaultRegistryAddress;

    const result = {
      elecIcp,
      elecHasMultipleResults,
      elecRegistryAddress,
      gasIcp,
      gasHasMultipleResults,
      gasRegistryAddress,
      lpgIcp,
      lpgHasMultipleResults,
      lpgRegistryAddress,
      result: icpList,
    };

    return result;
  }
}

const npaService = new NpaService();
export default npaService;
