import classNames from "classnames";
import { useRouter } from "next/router";
import { Image } from "components/Image";
import { Link } from "components/Link";
import styles from "./page-footer-partial.module.scss";
import ReactHtmlParser from "react-html-parser";
import { transform } from "helpers/transformNodeHelper";

export default function PageFooterPartial(props) {
  const {
    globalData: { joinFooterData },
    isPreview,
  } = props;

  const router = useRouter();
  if (joinFooterData === null) {
    return null;
  }

  const {
    genesisFooterLink,
    genesisFooterLogo,
    primaryLinks,
    customerTypePageHelpLabel,
    genericContactInfo,
    residentialContactInfo,
    businessContactInfo,
  } = joinFooterData;

  const shouldPrefetch = isPreview ? false : undefined;

  const year = new Date(Date.now()).getFullYear();
  const isResidential = router.asPath.includes("/residential");
  const isBusiness = router.asPath.includes("/business");
  const isCustomerTypePage = !isResidential && !isBusiness;

  return (
    <div className="text-center bg-black-50 rounded-t-10 text-black-primary md:text-left">
      <div className="grid-container">
        <div className="row">
          <div className="column-xs">
            <div className={styles.container}>
              <div className={styles.main}>
                {isCustomerTypePage ? (
                  <div data-testid="genericContactInfo" className="mb-10">
                    {customerTypePageHelpLabel && (
                      <p className="mb-4 font-bold text-20/24 md:text-24/28 md:mb-3">
                        {customerTypePageHelpLabel}&nbsp;
                      </p>
                    )}
                    <div className="flex flex-col gap-4 md:flex-row md:gap-6">
                      {genericContactInfo && ReactHtmlParser(genericContactInfo, { transform })}
                    </div>
                  </div>
                ) : null}
                {isResidential ? (
                  <div
                    data-testid="residentialContactInfo"
                    className={classNames(
                      "mb-10 text-20/24 md:text-24/28",
                      "flex justify-center md:justify-start",
                    )}
                  >
                    {residentialContactInfo && ReactHtmlParser(residentialContactInfo, { transform })}
                  </div>
                ) : null}
                {isBusiness ? (
                  <div
                    data-testid="businessContactInfo"
                    className={classNames(
                      "mb-10 text-20/24 md:text-24/28",
                      "flex justify-center md:justify-start",
                    )}
                  >
                    {businessContactInfo && ReactHtmlParser(businessContactInfo, { transform })}
                  </div>
                ) : null}
                <hr
                  className={classNames(
                    "bg-black-100 h-0.5 mx-auto mb-10 rounded-2.5",
                    "w-40 md:w-full",
                  )}
                />
                <div className="md:flex md:justify-between">
                  <ul
                    className={classNames(
                      "flex flex-col gap-4 text-black-primary text-14/16 mb-15",
                      "md:flex-row md:text-16/24 md:font-medium",
                    )}
                  >
                    {primaryLinks.map((item) => {
                      return (
                        <li key={`${item.href} ${item.text}`}>
                          <Link href={item.href} prefetch={shouldPrefetch}>
                            <a target={item.target}>{item.text}</a>
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                  <Link href={genesisFooterLink.href}>
                    <a
                      target={genesisFooterLink.target}
                      className={styles.logo}
                    >
                      <Image
                        src={genesisFooterLogo.url}
                        alt={genesisFooterLogo.label}
                        width={120}
                        height={120}
                        quality={100}
                      />
                    </a>
                  </Link>
                  <p className="text-14/16 md:text-16/24">
                    All content &copy; <span data-testid="year">{year}</span>{" "}
                    Genesis Energy
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
