import { head, isEmpty } from "lodash-es";
import {
  ADDRESS_TYPE_MANUAL,
  CALLBACK_REASON_BOTTLED_GAS,
  CALLBACK_REASON_DECOMMISSIONED,
  CALLBACK_REASON_LPG_REQUOTE_PPD_PLANS,
  CALLBACK_REASON_MANUAL_ADDRESS,
  CALLBACK_REASON_MULTI_CONNECT,
  CALLBACK_REASON_NO_RATES,
  CALLBACK_REASON_NO_RATES_FOR_SELECTED_TERM,
  CALLBACK_REASON_RESIDENTIAL,
  CALLBACK_REASON_TOU,
  CALLBACK_REASON_UNMETERED,
  CALLBACK_REASON_UNSPECIFIED,
  LPG_BOTTLE_SIZE_45,
  LPG_NO_OF_BOTTLES_SETUP_2,
  residentialApisForCallback,
  smeOfferErrorCodes,
  SUPPLY_POINT_TYPE_LPG,
} from "helpers/constants";
import {
  filterBusinessOffers,
  getOfferNameByProduct,
  getOfferResultNameByProduct,
} from "helpers/offersHelper";

export function determineCallbackReason({
  addressType,
  signupForAdditionalPropertiesOrIcps,
  selectedProductNames,
  termPeriod,
  businessOffers,
  gasBottlesCount,
  gasBottleSize,
}) {
  if (addressType === ADDRESS_TYPE_MANUAL) {
    return CALLBACK_REASON_MANUAL_ADDRESS;
  }

  if (signupForAdditionalPropertiesOrIcps) {
    return CALLBACK_REASON_MULTI_CONNECT;
  }

  if (selectedProductNames.includes(SUPPLY_POINT_TYPE_LPG)) {
    if (gasBottlesCount && gasBottlesCount !== LPG_NO_OF_BOTTLES_SETUP_2) {
      return CALLBACK_REASON_LPG_REQUOTE_PPD_PLANS;
    }

    if (gasBottleSize && gasBottleSize !== LPG_BOTTLE_SIZE_45) {
      return CALLBACK_REASON_LPG_REQUOTE_PPD_PLANS;
    }
  }

  const applicableOfferResults = selectedProductNames.map((productName) =>
    getOfferResultNameByProduct(productName),
  );

  const offerNameWithErrors =
    applicableOfferResults.find((offerResultName) => {
      const offerResult = businessOffers[offerResultName];
      return offerResult && offerResult.errors;
    }) || "";

  const { errors } = businessOffers[offerNameWithErrors] || { errors: [] };

  if (errors.length) {
    const { code: errorCode } = head(errors);

    const callbackReasonFromErrorCode =
      getCallbackReasonFromErrorCode(errorCode);

    if (callbackReasonFromErrorCode) {
      return callbackReasonFromErrorCode;
    }
  }

  const applicableOfferNames = selectedProductNames.map((productName) =>
    getOfferNameByProduct(productName),
  );

  const oneOrMoreProductsHaveNoOffers = applicableOfferNames.some(
    (offerName) => {
      const offerList = businessOffers[offerName];
      return isEmpty(offerList);
    },
  );

  if (oneOrMoreProductsHaveNoOffers) {
    return CALLBACK_REASON_NO_RATES;
  }

  if (termPeriod !== "") {
    const oneOrMoreProductsHaveAmbiguousRatesForSelectedTerm =
      applicableOfferNames.some((offerName) => {
        const offerList = businessOffers[offerName];
        const offerFilteredByTerm = filterBusinessOffers(offerList, termPeriod);
        const exactlyOneOfferWasNotFound = offerFilteredByTerm.length !== 1;
        return exactlyOneOfferWasNotFound;
      });

    if (oneOrMoreProductsHaveAmbiguousRatesForSelectedTerm) {
      return CALLBACK_REASON_NO_RATES_FOR_SELECTED_TERM;
    }
  }

  return "";
}

/*
  This returns an array [result, error]
  with first element being the result
  and second element an error
*/
export function getCallbackReasonDescription(reasonCode) {
  switch (reasonCode) {
    case CALLBACK_REASON_UNSPECIFIED:
      return ["Request a callback only", null];

    case CALLBACK_REASON_MANUAL_ADDRESS:
      return [
        "Manual Address or no ICP, obtain address/ICP, quote PPD plans",
        null,
      ];

    case CALLBACK_REASON_MULTI_CONNECT:
      return ["Multi-Connect customer - please quote BEP2 plan", null];

    case CALLBACK_REASON_BOTTLED_GAS:
      return ["LPG Selected - Quote PPD Plan", null];

    case CALLBACK_REASON_TOU:
      return ["TOU meter", null];

    case CALLBACK_REASON_DECOMMISSIONED:
      return ["Decommissioned or inactive meter - needs reconnection", null];

    case CALLBACK_REASON_RESIDENTIAL:
      return ["Residential ICP, quote Residential plan and PPD plans", null];

    case CALLBACK_REASON_UNMETERED:
      return ["Unmetered, Quote a PPD Plan", null];

    case CALLBACK_REASON_NO_RATES:
    case CALLBACK_REASON_NO_RATES_FOR_SELECTED_TERM:
      return ["No Rates available - quote PPD plan", null];

    case CALLBACK_REASON_LPG_REQUOTE_PPD_PLANS:
      return [
        "LPG bottle size or number of bottles - re-quote PPD plans",
        null,
      ];

    default:
      return [
        null,
        `Could not find callback reason description for code: ${reasonCode}`,
      ];
  }
}

function getCallbackReasonFromErrorCode(errorCode) {
  switch (errorCode) {
    case smeOfferErrorCodes.tou:
      return CALLBACK_REASON_TOU;

    case smeOfferErrorCodes.decommissioned:
      return CALLBACK_REASON_DECOMMISSIONED;

    case smeOfferErrorCodes.residential:
      return CALLBACK_REASON_RESIDENTIAL;

    case smeOfferErrorCodes.unmetered:
      return CALLBACK_REASON_UNMETERED;

    default:
      return "";
  }
}

export const shouldRedirectToResidentialCallback = (recentlyFailedApi = "") =>
  residentialApisForCallback.includes(recentlyFailedApi);
