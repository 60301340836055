import React from "react";
import classNames from "classnames";
import { motion } from "framer-motion";
import { useRouter } from "next/router";
import {
  Configure,
  InfiniteHits,
  InstantSearch,
  SearchBox,
} from "react-instantsearch-hooks-web";
import { Close } from "assets/icons";
import { ModuleWrapper } from "components/Wrappers";
import { Hit } from "./Hit";
import { MetaData } from "./MetaData";
import { getSearchClient } from "./searchHelper";
import { SearchResults } from "./SearchResults";

const searchHeaderAnimationConfig = {
  initial: { y: -100, opacity: 0 },
  animate: { y: 0, opacity: 1 },
  transition: { delay: 0.3, type: "spring", bounce: 0.3 },
};

const defaultSearchClient = getSearchClient();

function Search({ searchClient = defaultSearchClient }) {
  const router = useRouter();

  if (!router.isReady) {
    return null;
  }

  const { q: query } = router.query;

  return (
    <InstantSearch
      indexName="website"
      searchClient={searchClient}
      initialUiState={{
        website: {
          query,
        },
      }}
    >
      <ModuleWrapper psnid="psn_search">
        <Configure
          hitsPerPage={12}
          attributesToSnippet={["pageData:30"]}
          snippetEllipsisText="..."
        />
        <motion.div {...searchHeaderAnimationConfig}>
          <h3
            className={classNames(
              "text-32/36 text-black-primary font-bold my-7",
              "md:text-44/52 md:text-dark-primary md:my-10",
            )}
          >
            Search
          </h3>
          <SearchBox
            classNames={{
              form: "relative",
              input: classNames(
                "w-full rounded-2 outline-none md:rounded-3",
                "py-1 px-8 md:py-2 md:px-14",
                "text-15/24 md:text-24/36",
                "no-native-clear-icon",
              ),
              submit: classNames(
                "absolute top-0 left-0 bottom-0",
                "w-8 md:w-14",
                "flex items-center justify-center",
              ),
              submitIcon: classNames("w-4 h-4 md:w-6 md:h-6"),
              reset: classNames(
                "absolute top-0 right-0 bottom-0",
                "text-extra-neutral-mid02",
              ),
            }}
            placeholder="Start typing to search Genesis..."
            resetIconComponent={() => (
              <div
                className={classNames(
                  "w-8 md:w-14 h-full",
                  "flex items-center justify-center",
                )}
              >
                <Close className="w-4 h-4 fill-current md:w-6 md:h-6" />
              </div>
            )}
            aria-label="searchInput"
          />
          <hr
            className={classNames(
              "h-1 mt-6 mb-8 rounded bg-black-primary opacity-10",
              "md:mt-14 md:mb-12",
            )}
          />
        </motion.div>
        <SearchResults>
          <div className="flex flex-col gap-5">
            <InfiniteHits
              hitComponent={Hit}
              showPrevious={false}
              translations={{ showMoreButtonText: "Load more results" }}
              classNames={{
                root: classNames(
                  "flex flex-col",
                  "items-center justify-center",
                  "gap-y-10 md:gap-y-15",
                ),
                list: classNames(
                  "grid grid-cols-1 gap-4",
                  "md:gap-8",
                  "lg:grid-cols-2 lg:gap-x-21",
                ),
              }}
            />
            <MetaData />
          </div>
        </SearchResults>
      </ModuleWrapper>
    </InstantSearch>
  );
}

export { Search };
