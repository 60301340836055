import classNames from "classnames";
import { useScreenSize } from "hooks";
import { CallToAction } from "components/CallToAction";
import { THEME, VARIANT } from "helpers/constants";
import { getContentWithLineBreaks } from "helpers/htmlHelper";
import {
  FadeInBottomAnimation,
  FadeInTopAnimation,
  ScaleAnimation,
} from "./animations";
import { SharePriceBadge } from "./SharePriceBadge";

const getBackgroundImage = (fields, { isMd, isSm }) => {
  if (isMd) {
    return `url(${fields?.smdtimage?.url})`;
  } else if (isSm) {
    return `url(${fields?.tbimage?.url})`;
  } else return `url(${fields?.mbimage?.url})`;
};

const getVideoSource = (screen, mbVideo, tbVideo, dtVideo) => {
  // return matching screen & video sizes together first
  if (screen.isLg && dtVideo?.href) {
    return dtVideo.href;
  } else if (screen.isMd && tbVideo?.href) {
    return tbVideo.href;
  } else if (screen.isSm && mbVideo?.href) {
    return mbVideo.href;
  } else if (dtVideo?.href) {
    // else return largest to smallest videos for any screen size
    return dtVideo.href;
  } else if (tbVideo?.href) {
    return tbVideo.href;
  } else if (mbVideo?.href) {
    return mbVideo.href;
  } else {
    return "";
  }
};

function HeaderLevelOneHome({ module }) {
  const { fields } = module;

  // use video background if applicable
  const screen = useScreenSize();
  const videoSource = getVideoSource(
    screen,
    fields?.mbvideo,
    fields?.tbvideo,
    fields?.dtvideo,
  );
  const hasVideo = videoSource.length > 0;

  const isShowSharePrice = fields.showSharePrice === "true";
  const hasCtas = fields.cta1;
  const variantStyles = {
    backgroundHeight: hasVideo
      ? "mb-4 min-h-97 sm:min-h-125"
      : "mb-15 min-h-125 sm:mb-20 sm:min-h-150",
    title: fields.theme === THEME.dark ? "text-white" : "text-dark-primary",
    description:
      fields.theme === THEME.dark ? "text-white" : "text-black-primary",
  };
  return (
    <div
      data-psnid="psn_header_level_one_home"
      className={classNames(
        "relative mx-auto overflow-hidden",
        "first:pt-21",
        "sm:mb-20",
        "md:first:pt-37",
        "lg:first:pt-45",
        variantStyles.backgroundHeight,
      )}
    >
      <ScaleAnimation
        testid="headerBackground"
        className="absolute inset-x-0 top-0 bottom-0 overflow-hidden bg-center bg-no-repeat bg-cover"
        style={
          hasVideo
            ? undefined
            : { backgroundImage: getBackgroundImage(fields, screen) }
        }
      >
        {hasVideo && (
          <video
            aria-label={`${fields.title} video`}
            autoPlay
            muted
            loop
            playsInline
            className="object-cover w-full h-full"
          >
            <track default kind="captions" srcLang="en" />
            <source
              data-testid="video-source"
              src={videoSource}
              type="video/mp4"
            />
          </video>
        )}
      </ScaleAnimation>
      <div className="absolute inset-0 bottom-0 translate-y-px bg-bottom bg-no-repeat bg-auto bg-curve-sm sm:bg-curve-md md:bg-curve-lg lg:bg-curve-xl" />
      <div className="relative px-4 mx-auto sm:px-15 md:px-17 lg:px-23 xl:px-46">
        <div className={classNames("pt-7 md:pt-10 pb-50 md:pb-40 lg:pb-50")}>
          <div className="sm:w-3/4 md:w-2/3">
            {isShowSharePrice && <SharePriceBadge />}
            <FadeInTopAnimation>
              <h1
                className={classNames(
                  "mb-3 sm:mb-5",
                  "font-bold text-white text-32/36 sm:text-44/52",
                  variantStyles.title,
                )}
              >
                {fields.title}
              </h1>
              <p
                data-testid="headerContentDescription"
                className={classNames(
                  "font-normal text-18/28",
                  "sm:text-20/32",
                  variantStyles.description,
                )}
              >
                {getContentWithLineBreaks(fields.description)}
              </p>
            </FadeInTopAnimation>
            {hasCtas && (
              <div
                data-testid="ctaSection"
                className="flex flex-wrap mt-8 md:mt-10"
              >
                <FadeInBottomAnimation>
                  <CallToAction
                    data={fields.cta1}
                    testid="primaryCta"
                    variant={VARIANT.orange}
                    dataGa={{
                      content_tertiary: fields.title,
                      event: "cta_click",
                      event_category: "cta",
                      event_label: [fields.cta1?.text],
                    }}
                  />
                </FadeInBottomAnimation>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export { HeaderLevelOneHome };
