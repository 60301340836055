import { useState } from "react";
import { DialogContent, DialogOverlay } from "@reach/dialog";
import classNames from "classnames";
import { GenesisVideoLogo, ModalClose, VideoPlay } from "assets/icons";
import { Image } from "components/Image";
import { ScaleAnimation } from "./animations";

function VideoCard({ videoId, textoverlay, image }) {
  const [showOverlay, setShowOverlay] = useState(false);
  const hideOverlay = () => {
    setShowOverlay(false);
  };

  return (
    <div>
      <div
        className="relative text-white cursor-pointer"
        data-testid="videoCardImage"
        onClick={() => setShowOverlay(true)}
      >
        <div className="overflow-hidden peer shadow-white-glow rounded-7 md:rounded-10">
          <ScaleAnimation>
            <Image
              src={image.url}
              alt={image.label}
              width={2096}
              height={1188}
              layout="responsive"
              className="w-full"
            />
          </ScaleAnimation>
        </div>
        <VideoPlay
          className={classNames(
            "absolute top-1/2 left-1/2 -translate-x-2/4 -translate-y-2/4",
            "w-25 h-25",
            "sm:w-37.5 sm:h-37.5",
            "md:w-50 md:h-50",
            "transition ease-in-out hover:scale-110 peer-hover:scale-110",
          )}
        />
        <div
          className={classNames(
            "absolute bottom-0 left-0 right-0 bg-textoverlay-gradient",
            "h-16 md:h-21 rounded-b-7 md:rounded-b-10",
          )}
        ></div>
        <p
          className={classNames(
            "absolute bottom-5 left-5 text-15/24",
            "md:bottom-7 md:left-7 md:text-18/28",
          )}
        >
          {textoverlay}
        </p>
        <GenesisVideoLogo
          className={classNames(
            "absolute top-5 left-5 w-15 h-15",
            "md:h-20 md:w-20 md:left-7",
          )}
        />
      </div>

      <DialogOverlay
        className="z-20 flex items-center bg-black-primary/90 animate-fade-in"
        isOpen={showOverlay}
        onDismiss={hideOverlay}
        aria-label={textoverlay}
      >
        <DialogContent
          className="w-full p-4 bg-transparent sm:w-11/12 md:w-6/12 sm:p-8"
          aria-label={textoverlay}
        >
          <button
            onClick={hideOverlay}
            data-testid="overlayClose"
            className="float-right outline-none"
          >
            <ModalClose className="w-8 fill-current" />
          </button>
          <div
            className="video aspect-w-16 aspect-h-9 mt-13"
            data-testid="videoOverlay"
          >
            <iframe
              src={`https://www.youtube.com/embed/${videoId}?controls=0&enablejsapi=1`}
              frameBorder="0"
              title={textoverlay}
            />
          </div>
        </DialogContent>
      </DialogOverlay>
    </div>
  );
}

export { VideoCard };
