import { CallToAction } from "components/CallToAction";
import { VARIANT } from "helpers/constants";
import { getContentWithLineBreaks } from "helpers/htmlHelper";

export const CtaSection = ({ ctaData }) => {
  return (
    <>
      <h2 className="mb-5 font-bold text-dark-primary text-32/36 md:text-44/52">
        {ctaData.title}
      </h2>
      <p className="mb-10 text-20/32 text-black-primary md:text-24/36">
        {getContentWithLineBreaks(ctaData.description)}
      </p>
      <div className="flex flex-wrap gap-y-5 md:mb-15">
        <CallToAction data={ctaData.cta1} variant={VARIANT.orange} />
        {ctaData.cta2 && (
          <CallToAction data={ctaData.cta2} variant={VARIANT.darkTransparent} />
        )}
      </div>
    </>
  );
};
