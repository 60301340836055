import classNames from "classnames";
import styles from "./badge.module.scss";

export default function Badge(props) {
  const { text, isWhiteTheme, borderRadiusClass = "" } = props;

  return (
    <div
      className={classNames(
        styles.badge,
        { [styles.white]: isWhiteTheme },
        "py-1 pl-4.5 pr-6 w-29 h-6",
        "lg:pl-6 lg:pr-7 lg:w-34.5 lg:h-7",
        "rounded-tl-none rounded-br-none",
        borderRadiusClass || "rounded-tr-10 rounded-bl-7",
      )}
    >
      <span
        className={classNames(
          styles.text,
          { [styles.white]: isWhiteTheme },
          "text-12/16 font-medium lg:text-14/20",
        )}
        data-testid="badgeText"
      >
        {text}
      </span>
    </div>
  );
}
