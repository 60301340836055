import StepNavigationItem from "join-form/components/step-navigation-item";
import {
  appBusinessRoutes,
  BUSINESS_TYPE_SOLE_TRADER,
} from "helpers/constants";
import { useRouter } from "next/router";
import { connect } from "react-redux";

const {
  businessDetails,
  address,
  addressIcp,
  addressManual,
  fuel,
  callback,
  contactDetails,
  termSelect,
  creditCheck,
  success,
  callbackSuccess,
  ...allLicenceDetailsRouteInfo
} = appBusinessRoutes;
const licenceDetailPageRoutes = Object.values(allLicenceDetailsRouteInfo);

function CreditCheckItem({ isSoleTrader, ...rest }) {
  const router = useRouter();

  const isVisible = licenceDetailPageRoutes.includes(router.asPath);

  if (!isVisible) {
    return null;
  }

  return (
    isSoleTrader && (
      <StepNavigationItem
        title="Licence Details"
        path={creditCheck}
        {...rest}
      />
    )
  );
}

const mapStateToProps = (props, ownProps) => {
  const {
    businessState: { businessType },
  } = props;

  const isSoleTrader = businessType === BUSINESS_TYPE_SOLE_TRADER;

  return { isSoleTrader };
};

export default connect(mapStateToProps)(CreditCheckItem);
