import React from "react";
import { take } from "lodash-es";
import { getPaginationState } from "./helpers";
import { ACTION_TYPES, reducer } from "./reducer";

const defaultState = {
  filteredDocuments: [],
  documentItems: [],
  categories: [],
  allYears: [],
  allDates: [],
  pageSize: 10,
  visibleDocumentsCount: 12,
  selectedYear: "",
  selectedTagIds: [],
};

function useDocumentFilter({
  documentItems,
  categories,
  allYears,
  allDates,
  pageSize,
  visibleDocumentsCount,
}) {
  const initialState = {
    ...defaultState,
    filteredDocuments: documentItems,
    documentItems,
    categories,
    allYears,
    allDates,
    pageSize,
    visibleDocumentsCount,
  };

  const [state, dispatch] = React.useReducer(reducer, initialState);

  function filterDocuments() {
    dispatch({ type: ACTION_TYPES.filterDocuments });
  }

  function setYear(year) {
    dispatch({ type: ACTION_TYPES.setYear, payload: year });
    filterDocuments();
  }

  function setCategory(tagId) {
    dispatch({ type: ACTION_TYPES.setCategory, payload: tagId });
    filterDocuments();
  }

  function reset() {
    dispatch({ type: ACTION_TYPES.reset });
  }

  function loadMoreDocuments() {
    dispatch({ type: ACTION_TYPES.loadMoreDocuments });
  }

  const paginatedDocuments = take(
    state.filteredDocuments,
    state.visibleDocumentsCount,
  );

  const { paginationText, showLoadMoreButton } = getPaginationState(
    paginatedDocuments,
    state.filteredDocuments,
  );

  return {
    filteredDocuments: paginatedDocuments,
    visibleDocumentsCount: state.visibleDocumentsCount,
    selectedYear: state.selectedYear,
    selectedTagIds: state.selectedTagIds,
    paginationText,
    showLoadMoreButton,
    setYear,
    setCategory,
    reset,
    loadMoreDocuments,
    dispatch,
  };
}

export { useDocumentFilter };
