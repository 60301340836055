import { runAsync } from "helpers";
import { dropRight } from "lodash-es";

function getSitemapItems(sitemap, parentPageUrl) {
  const result = sitemap.reduce((acc, current) => {
    if (current.path === parentPageUrl) {
      acc.push(current);
      return acc;
    }
    if (parentPageUrl.includes(current.path) && current.children) {
      const childSitemapItems = getSitemapItems(
        current.children,
        parentPageUrl,
      );
      acc = acc.concat(current).concat(childSitemapItems);
    }
    return acc;
  }, []);

  return result;
}

function getParentItems(sitemap, sitemapNode) {
  if (!sitemapNode) {
    return [];
  }
  const sitemapUrlParts = dropRight(sitemapNode.path.split("/"));
  const parentPageUrl = sitemapUrlParts.join("/");
  const sitemapItems = getSitemapItems(sitemap, parentPageUrl);

  const result = sitemapItems.map(({ title, menuText, path, redirect }) => ({
    title,
    menuText,
    path,
    redirect,
  }));

  return result;
}

async function getBreadcrumbProps({
  agility,
  languageCode,
  channelName,
  sitemapNode,
}) {
  const [sitemap, error] = await runAsync(() =>
    agility.getSitemapNested({
      channelName,
      languageCode,
    }),
  );

  if (error) {
    return [];
  }

  return getParentItems(sitemap, sitemapNode);
}

export { getBreadcrumbProps };
