import axios from "axios";
import { addSeconds, isBefore, parseJSON } from "date-fns";
import { ACCESS_TOKEN_STORAGE_KEY } from "helpers/constants";
import { clearState } from "actions/clearStateActions";
import { store } from "store/store";

export function getTokenExpiration() {
  const session = JSON.parse(
    window.localStorage.getItem(ACCESS_TOKEN_STORAGE_KEY),
  );
  const expiresIn = session && session.expiresIn;
  const parsedDate = parseJSON(expiresIn);
  return parsedDate;
}

export function hasAccessTokenInfoInitialized() {
  const now = Date.now() - 1000 * 60; // 60s buffer
  const expirationDate = getTokenExpiration();
  const sessionExists = isBefore(now, expirationDate);
  return sessionExists;
}

export function getToken() {
  const sessionExists = hasAccessTokenInfoInitialized();
  if (sessionExists) {
    const tokenInfo = JSON.parse(
      window.localStorage.getItem(ACCESS_TOKEN_STORAGE_KEY),
    );
    return tokenInfo.accessToken;
  }

  return fetchAccessToken();
}

export async function fetchAccessToken() {
  window.localStorage.removeItem("persist:root");
  store.dispatch(clearState());

  const data = await axios.get("/api/npa/accesstoken").then((res) => res.data);
  const { access_token, expires_in } = data;

  if (access_token) {
    const date = new Date(Date.now());
    const expiresIn = addSeconds(date, expires_in);
    const stringifiedExpiry = JSON.stringify(expiresIn.toISOString());
    const tokenInfo = {
      accessToken: access_token,
      expiresIn: stringifiedExpiry,
    };

    window.localStorage.setItem(
      ACCESS_TOKEN_STORAGE_KEY,
      JSON.stringify(tokenInfo),
    );
    return tokenInfo.accessToken;
  }
}
