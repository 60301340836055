import { useState } from "react";
import classNames from "classnames";
import { FieldArray } from "formik";
import { AnimatePresence, motion } from "framer-motion";
import {
  CheckboxList,
  Input,
  RadioList,
} from "join-form/components/form-controls";
import Icon from "join-form/components/icon";
import Modal from "join-form/components/modal";
import { PREVENT_ACCESS_REASON_OTHER } from "helpers/constants";
import { getPropertyAccessListContent } from "helpers/propertyAccessHelper";
import { heightFadeTransition, heightFadeVariants } from "./animations";
import styles from "./property-page.module.scss";

export function MeterOrBottleAccessQuestions(props) {
  const {
    values,
    touched,
    errors,
    handleChange,
    askBottledGasSpecificQuestions,
    askMeterAccessQuestions,
    assets,
    pageData,
  } = props;
  const [showGasBottlesAccessInfoModal, setGasBottlesAccessInfoModal] =
    useState(false);
  const onInfoModalClose = () => {
    setGasBottlesAccessInfoModal(false);
  };

  return (
    <>
      <p
        className={classNames(styles.label, "inline")}
        data-testid="isAccessQuestion"
      >
        {pageData.fields.meterAccessQuestionLabel} {assets.join(" or ")}?
      </p>
      {askBottledGasSpecificQuestions && (
        <button
          data-testid="gasBottlesAccessOverlay"
          onClick={() => setGasBottlesAccessInfoModal(true)}
          className="ml-2 align-text-bottom text-black-primary md:align-text-top"
        >
          <Icon name="Info" size="18" />
        </button>
      )}
      <RadioList
        name="meterAccessHasAnIssue"
        onChange={handleChange}
        errorMessage={errors.meterAccessHasAnIssue}
        showError={
          errors.meterAccessHasAnIssue && touched.meterAccessHasAnIssue
        }
        size="medium"
        items={[
          {
            text: "Yes",
            value: true,
            checked: values.meterAccessHasAnIssue === "true",
            testId: "meterAccessHasAnIssueYesOption",
          },
          {
            text: "No",
            value: false,
            checked: values.meterAccessHasAnIssue === "false",
            testId: "meterAccessHasAnIssueNoOption",
          },
        ]}
      />

      <AnimatePresence>
        {values.meterAccessHasAnIssue === "true" && (
          <motion.div
            initial="initial"
            animate="animate"
            exit="exit"
            variants={heightFadeVariants}
            transition={heightFadeTransition}
            className={styles.dependant_fields_wrapper}
          >
            <FieldArray
              name="meterAccessIssueReasons"
              data-testid="meterAccessIssueReasons"
              render={(arrayHelpers) => (
                <CheckboxList
                  label={pageData.fields.meterAccessReasonLabel}
                  name="meterAccessIssueReasons"
                  size="medium"
                  onChange={(e) => {
                    const { checked, value } = e.target;
                    if (checked) {
                      arrayHelpers.push(value);
                    } else {
                      const idx = values.meterAccessIssueReasons.indexOf(value);
                      arrayHelpers.remove(idx);
                    }
                  }}
                  errorMessage={errors.meterAccessIssueReasons}
                  showError={
                    errors.meterAccessIssueReasons &&
                    touched.meterAccessIssueReasons
                  }
                  items={getPropertyAccessListContent(
                    "meterAccessIssueReasons",
                    askMeterAccessQuestions,
                    askBottledGasSpecificQuestions,
                  ).map((reason) => ({
                    name: `meterAccessIssueReasons${reason.text}`,
                    testId: `meterAccessIssueReasons${reason.text}`,
                    text: reason.text,
                    value: reason.value,
                    checked: values.meterAccessIssueReasons.includes(
                      reason.value,
                    ),
                  }))}
                />
              )}
            />
          </motion.div>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {values.meterAccessHasAnIssue === "true" &&
          values.meterAccessIssueReasons.includes(
            PREVENT_ACCESS_REASON_OTHER,
          ) && (
            <motion.div
              initial="initial"
              animate="animate"
              exit="exit"
              variants={heightFadeVariants}
              transition={heightFadeTransition}
              className={styles.dependant_fields_wrapper}
            >
              <Input
                name="meterAccessIssueReasonOther"
                placeholder={pageData.fields.meterAccessReasonOtherLabel}
                value={values.meterAccessIssueReasonOther}
                onChange={handleChange}
                errorMessage={errors.meterAccessIssueReasonOther}
                showError={
                  errors.meterAccessIssueReasonOther &&
                  touched.meterAccessIssueReasonOther
                }
              />
            </motion.div>
          )}
      </AnimatePresence>
      {showGasBottlesAccessInfoModal && (
        <Modal
          onRequestClose={onInfoModalClose}
          isOpen
          title={pageData.fields.bottledGasAccessModalTitle}
        >
          {pageData.fields.bottledGasAccessModalContent}
        </Modal>
      )}
    </>
  );
}

export default MeterOrBottleAccessQuestions;
