import agilityContentFetch from "@agility/content-fetch";
import { range } from "lodash-es";
import { runAsync } from "./runAsync";

function getSafeUrl(url) {
  return url?.replace("~", "");
}

function getAgilityApiClient() {
  const client = agilityContentFetch.getApi({
    guid: process.env.AGILITY_GUID,
    apiKey: process.env.AGILITY_API_FETCH_KEY,
  });
  return client;
}

async function getContentList(agility, config) {
  return runAsync(() => agility.getContentList(config));
}

async function getContentListAll(agility, config) {
  const [firstBatchData, firstBatchError] = await getContentList(agility, {
    skip: 0,
    take: 50,
    ...config,
  });

  if (firstBatchError) {
    return { items: [], totalCount: 0 };
  }

  const { items, totalCount } = firstBatchData;

  if (items.length >= totalCount) {
    return firstBatchData;
  }

  const remainingItems = totalCount - items.length;

  const pagesForRemainingItems = Math.ceil(remainingItems / 50);

  const requestIndicesForEachPage = range(pagesForRemainingItems);

  const requestConfigForEachPage = requestIndicesForEachPage.map(
    (requestIndex) => {
      const skip = 50 * (requestIndex + 1);
      return {
        skip,
        take: 50,
        ...config,
      };
    },
  );

  const contentRequestList = requestConfigForEachPage.map((requestConfig) =>
    agility.getContentList(requestConfig),
  );

  const [result, error] = await runAsync(() => Promise.all(contentRequestList));

  if (error) {
    return { items: [], totalCount: 0 };
  }

  const flattenedResult = result.map((list) => list.items).flat();

  return { items: [...firstBatchData.items, ...flattenedResult], totalCount };
}

async function getRedirects(agilityApiClient) {
  const [result, error] = await runAsync(() =>
    agilityApiClient.getUrlRedirections({}),
  );

  if (error) {
    console.error(error);
    return [];
  }

  const redirects = result.items.map((redirect) => ({
    source: getSafeUrl(redirect.originUrl),
    destination: getSafeUrl(redirect.destinationUrl),
    permanent: redirect.statusCode === 301,
  }));

  return redirects;
}

export { getAgilityApiClient, getContentListAll, getRedirects };
