import { gtmCreateEcommerceObjectForBusiness } from "actions/gtmActions";
import { createSmeCallbackLead } from "services/SmeLeadGenerationService";
import { getFullAddressByType } from "helpers/addressHelper";
import {
  getBusinessName,
  getBusinessTypeNameForSignup,
} from "helpers/businessDetailsHelper";
import { determineCallbackReason } from "helpers/callbackHelper";
import { appBusinessRoutes } from "helpers/constants";
import {
  getSelectedAvailableProducts,
  isBottledGasSelected,
  isElectricitySelected,
  isNaturalGasSelected,
} from "helpers/productHelper";
import {
  SET_CALLBACK_REASON,
  SET_CALLBACK_REFERRER_URL,
} from "./callbackPageActionTypes";

export function unsetCallbackReferrerUrl() {
  return { type: SET_CALLBACK_REFERRER_URL, payload: "" };
}

export function setCallbackReferrerUrl(url) {
  return { type: SET_CALLBACK_REFERRER_URL, payload: url };
}

export function setCallbackReason(reason) {
  return { type: SET_CALLBACK_REASON, payload: reason };
}

export function checkStoreStateForBusinessCallbackFlow() {
  return function checkStoreStateForBusinessCallbackFlowInternal(
    dispatch,
    getState,
  ) {
    const {
      addressState: { addressType },
      appUserState: { signupForAdditionalPropertiesOrIcps },
      appState: { products },
      businessPlanConfig: { termData },
      businessOffers,
      appPropertyState: { gasBottlesCount, gasBottleSize },
    } = getState();

    const selectedAndAvailableProducts = getSelectedAvailableProducts(products);
    const selectedProductNames = selectedAndAvailableProducts.map(
      ({ name }) => name,
    );
    const selectedTerm = termData.find(({ isSelected }) => isSelected);

    const termPeriod = selectedTerm ? selectedTerm.termPeriod : "";

    const reasonCode = determineCallbackReason({
      addressType,
      signupForAdditionalPropertiesOrIcps,
      selectedProductNames,
      termPeriod,
      businessOffers,
      gasBottlesCount,
      gasBottleSize,
    });

    dispatch(setCallbackReason(reasonCode));

    const shouldTriggerCallbackFlow = reasonCode !== "";

    return { shouldTriggerCallbackFlow, reasonCode };
  };
}

export function createSimpleLead(callbackReasonDescription = "") {
  return async function createSimpleLeadWithStoreState(dispatch, getState) {
    const {
      addressState: {
        addressType,
        addressDetails,
        registryAddressDetails,
        manualAddressDetails,
      },
      appState: { products, callbackReferrerUrl },
      appUserState: {
        title,
        firstName,
        lastName,
        email: emailAddress,
        phone,
        signupForAdditionalPropertiesOrIcps,
      },
      businessState: {
        businessType,
        soleTraderTradingAs,
        companyTradingAs,
        otherTradingAs,
        companyNZBN,
      },
      icp: { elecIcp, gasIcp },
    } = getState();

    const businessTypeForSignup = getBusinessTypeNameForSignup(businessType);

    const tradingAs = getBusinessName({
      businessType,
      soleTraderTradingAs,
      companyTradingAs,
      otherTradingAs,
      companyNZBN,
    });

    const address = getFullAddressByType({
      addressType,
      addressDetails,
      registryAddressDetails,
      manualAddressDetails,
    });

    const shouldSendIcpInformation =
      callbackReferrerUrl === appBusinessRoutes.addressIcp;

    const electricity = isElectricitySelected(products);

    const electricityIcp =
      shouldSendIcpInformation && electricity ? elecIcp : "";

    const naturalGas = isNaturalGasSelected(products);

    const naturalGasIcp = shouldSendIcpInformation && naturalGas ? gasIcp : "";

    const bottledGas = isBottledGasSelected(products);

    const leadData = {
      businessType: businessTypeForSignup,
      tradingAs,
      NZBN: companyNZBN,
      address,
      isMultipleAddresses: signupForAdditionalPropertiesOrIcps,
      electricity,
      electricityIcp,
      naturalGas,
      naturalGasIcp,
      bottledGas,
      title,
      firstName,
      lastName,
      emailAddress,
      phone,
      reason: callbackReasonDescription,
    };

    await createSmeCallbackLead(leadData);

    const hasFuelSelection = callbackReferrerUrl !== appBusinessRoutes.address;
    dispatch(
      gtmCreateEcommerceObjectForBusiness({
        isCallbackFlow: true,
        hasFuelSelection,
      }),
    );
  };
}
