import { sum } from "lodash-es";
import { DEFAULT_CAMPAIGN_CODE_DEFINITIONS } from "helpers/constants";


export function getJoiningCampaignsForResidential({ products, plan, term }) {
  const selectedAvailableProducts = products.filter(
    ({ isAvailable, isSelected }) => isAvailable && isSelected,
  );

  const campaigns = DEFAULT_CAMPAIGN_CODE_DEFINITIONS.filter(
    ({ planType, planTerm, planProduct }) =>
      planType === plan &&
      planTerm === term &&
      selectedAvailableProducts.find((p) => p.name === planProduct),
  );

  return campaigns;
}

export function applyResidentialTermCredits({ termData, planType, products }) {
  return termData.map((termItem) => {
    return applyCreditToResidentialTerm({ termItem, planType, products });
  });
}

export function applyCreditToResidentialTerm({
  termItem,
  planType,
  products,
} = {}) {
  const credit = getTotalCredit({
    products,
    plan: planType,
    term: termItem.codeName,
  });

  return {
    ...termItem,
    credit,
  };
}

export function getTotalCredit({ products, plan, term }) {
  const campaigns = getJoiningCampaignsForResidential({
    products,
    plan,
    term,
  });
  return formatTotalCredit(campaigns);
}

function formatTotalCredit(campaigns = []) {
  const totalCredit = sum(campaigns.map(({ planCredit }) => planCredit));
  return totalCredit > 0 ? `$${totalCredit}` : "";
}
