import StepNavigationItem from "join-form/components/step-navigation-item";
import {
  appBusinessRoutes,
  BUSINESS_PLAN_TERM_FIXED12,
  BUSINESS_PLAN_TERM_FIXED24,
  BUSINESS_PLAN_TERM_FLEXI,
  CALLBACK_REASON_LPG_REQUOTE_PPD_PLANS,
  CALLBACK_REASON_NO_RATES_FOR_SELECTED_TERM,
} from "helpers/constants";
import { useRouter } from "next/router";
import { connect } from "react-redux";

function getTitle(termCodeName) {
  switch (termCodeName) {
    case BUSINESS_PLAN_TERM_FIXED24:
      return "24-Month Fixed Term";
    case BUSINESS_PLAN_TERM_FIXED12:
      return "12-Month Fixed Term";
    case BUSINESS_PLAN_TERM_FLEXI:
      return "No Fixed Term";
    default:
      return "Term";
  }
}

const callbackReasonsCanDisplayTerm = [
  CALLBACK_REASON_NO_RATES_FOR_SELECTED_TERM,
  CALLBACK_REASON_LPG_REQUOTE_PPD_PLANS,
];

const {
  businessDetails,
  address,
  addressIcp,
  addressManual,
  fuel,
  contactDetails,
  termSelect,
  success,
  callbackSuccess,
  ...allContactDetailsRouteInfo
} = appBusinessRoutes;
const contactDetailPageRoutes = Object.values(allContactDetailsRouteInfo);

function TermSelectItem({ title, callbackReason, ...rest }) {
  const { asPath: pathname } = useRouter();

  const isVisible = contactDetailPageRoutes.includes(pathname);

  if (!isVisible) {
    return null;
  }

  if (pathname === appBusinessRoutes.callback) {
    if (!callbackReasonsCanDisplayTerm.includes(callbackReason)) {
      return null;
    }
  }

  return <StepNavigationItem title={title} path={termSelect} {...rest} />;
}

const mapStateToProps = (props, ownProps) => {
  const {
    appUserState: { callbackReason },
    businessPlanConfig: { termData },
  } = props;

  const { codeName } = termData.find(({ isSelected }) => isSelected) || {};

  const title = getTitle(codeName);

  return { title, callbackReason };
};

export default connect(mapStateToProps)(TermSelectItem);
