import Icon from "join-form/components/icon";
import styles from "./form-controls.module.scss";

export default function Checkbox(props) {
  const {
    name,
    value,
    checked,
    className = "",
    testId,
    onChange,
    text,
    size,
  } = props;

  const checkedClassName = checked ? styles.checked : "";
  const iconName = checked ? "CheckboxOn" : "CheckboxOff";

  return (
    <label
      className={`${styles.checkbox} ${styles[size]} ${checkedClassName} ${className}`}
    >
      <input
        name={name}
        id={name}
        type="checkbox"
        className={styles.checkbox_input}
        checked={checked}
        onChange={onChange}
        value={value}
        data-testid={testId}
      />
      <span className={styles.checkbox_icon_container}>
        <Icon
          size="24"
          name={iconName}
          className={styles.checkbox_icon_unselected}
        />
      </span>
      <span className={styles.checkbox_text}>{text}</span>
    </label>
  );
}
