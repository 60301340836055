import { useEffect } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { ErrorMessage } from "./ErrorMessage";

const recaptchaKey = process.env.NEXT_PUBLIC_RECAPTCHA_KEY;
const isProduction = process.env.NEXT_PUBLIC_WEBSITE_ENV === "production";

export const ReCaptcha = ({ errorMessage, name, onChange }) => {
  // skip for non prod environments
  // will invalidate after 15 mins per plugin defaults
  useEffect(() => {
    if (!isProduction) {
      onChange("skip");
    }
  }, [onChange]);

  return (
    <div>
      <ReCAPTCHA onChange={onChange} sitekey={recaptchaKey} />
      {errorMessage && (
        <ErrorMessage message={errorMessage} testId={`${name}ErrorMessage`} />
      )}
    </div>
  );
};
