import StepNavigationItem from "join-form/components/step-navigation-item";
import { appBusinessRoutes, appRoutes } from "helpers/constants";
import { isResiJourneyDisabled } from "helpers/journeyHelper";
import { useRouter } from "next/router";

const { success, callbackSuccess, ...allowedRoutesInfo } = appBusinessRoutes;
const allowedRoutes = Object.values(allowedRoutesInfo);

export default function CustomerTypeItem(props) {
  const router = useRouter();

  const isVisible = allowedRoutes.includes(router.asPath);

  if (!isVisible) {
    return null;
  }

  if (isResiJourneyDisabled()) {
    return null;
  }

  return (
    <StepNavigationItem
      title="For Business"
      path={appRoutes.customerType}
      {...props}
    />
  );
}
