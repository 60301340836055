import React from "react";
import { useHeadroomInstance } from "providers/Headroom";

function scrollFilterButtonIntoView(button, yOffset = -88) {
  const supportsScrollMargin = CSS.supports("scroll-margin: 0");

  if (supportsScrollMargin) {
    button.scrollIntoView({ behavior: "smooth" });
    return;
  }

  const buttonOffsetTopValue = button.getBoundingClientRect().top;

  const scrollY = buttonOffsetTopValue + window.scrollY + yOffset;

  window.scrollTo({ top: scrollY, behavior: "smooth" });
}

function useFilterOverlay() {
  const [isOverlayVisible, setOverlayVisibility] = React.useState(false);
  const filterButtonRef = React.useRef();

  const headroomInstance = useHeadroomInstance();

  React.useEffect(() => {
    document.body.classList.toggle("filter-overlay-visible", isOverlayVisible);

    if (isOverlayVisible) {
      headroomInstance.pin();
      scrollFilterButtonIntoView(filterButtonRef.current);
    }

    headroomInstance.freeze(isOverlayVisible);

    return () => document.body.classList.remove("filter-overlay-visible");
  }, [isOverlayVisible, headroomInstance]);

  return {
    isOverlayVisible,
    setOverlayVisibility,
    filterButtonRef,
  };
}

export { useFilterOverlay };
