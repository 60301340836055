import { createSelector } from "@reduxjs/toolkit";
import { differenceInDays, parse } from "date-fns";
import { Formik } from "formik";
import { AnimatePresence, motion } from "framer-motion";
import { submitPropertyDetailsForm } from "actions/appPropertyStateActions";
import { DateInput, RadioList } from "join-form/components/form-controls";
import Intro from "join-form/components/intro";
import StepProgressionButton from "join-form/components/step-progression-button";
// import Feedback from "join-form/components/feedback";
import {
  appResidentialRoutes,
  LPG_NO_OF_BOTTLES_SETUP_2,
  PREVENT_ACCESS_REASON_OTHER,
  PROPERTY_SITUATION_MOVE,
  PROPERTY_SITUATION_TRANSFER,
} from "helpers/constants";
import { trackResidentialContinueButton } from "helpers/gtmHelper";
import {
  isBottledGasSelected,
  isElectricitySelected,
  isNaturalGasSelected,
} from "helpers/productHelper";
import {
  selectAppPropertyState,
  selectAppState,
  selectResidentialOffers,
} from "reducers/selector";
import { useRouter } from "next/router";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { InlineBlockEditor } from "components/BlockEditor";
import { getPageData } from "helpers/getPageData";
import {
  headerFade,
  heightFadeTransition,
  heightFadeVariants,
  itemContainer,
} from "./animations";
import { BottledGasQuestions } from "./BottledGasQuestions";
import { ElectricityQuestions } from "./ElectricityQuestions";
import { MeterOrBottleAccessQuestions } from "./MeterOrBottleAccessQuestions";
import styles from "./property-page.module.scss";

const modules = [
  "GE_JOIN_Intro_Header",
  "GE_JOIN_Residential_Property_Page",
  "GE_JOIN_Residential_Medical_Support_Modal",
  "GE_JOIN_Residential_Wellbeing_Modal",
];

export const defaultPageData = {
  fields: {
    title: "About your home",
    blockEditor: null,
    situationLabel: "Are you moving premises or switching suppliers?",
    moveInDateLabel: "What date are you moving into this address?",
    medicalSupportLabel:
      "Is anyone at this address reliant on mains electricity and/or natural gas or bottled gas (LPG) for critical medical support?",
    medicalSupportModalTitle: "Medical support",
    medicalSupportModalContent:
      "Please tell us if someone at this property depends on mains electricity and/or natural gas or bottled gas (LPG) for critical medical support, such that loss of electricity and/or natural gas or bottled gas (LPG) may result in loss of life or serious harm. Please note that we may contact you to complete a ‘Notice of Potential Medically Dependent Consumer status’ form to have it noted on your account.",
    medicalSupportModalCtaText: "Okay, got it",
    disconnectionThreatLabel:
      "Does disconnection of electricity and/or natural gas or bottled gas (LPG) present a threat to the health or well-being of anyone at this address for reasons of age, health, disability or severe financial insecurity?",
    wellbeingModalTitle: "Health or well-being risk",
    wellbeingModalContent:
      "Please tell us if the disconnection of mains electricity and/or natural gas or bottled gas (LPG) presents a clear risk to your (or that of someone in your household) health or well-being for the reasons of age, health, disability or severe financial insecurity. If you are experiencing financial hardship, please contact us on 0800 300 400 so we can discuss how we can help you.",
    disconnectionThreatModalCtaText: "Okay, got it",
    isWellBeingThreatLabelPreText:
      "What presents a threat? If you are experiencing financial hardship, please contact us on ",
    isWellBeingThreatPhoneNumber: "0800 300 400",
    isWellBeingThreatLabelPostText: " so we can discuss how we can help you.",
    gasBottlesCountLabel: "How many gas bottles is your property set up for?",
    gasBottlesSupplierLabel: "Who is your current bottled gas (LPG) supplier?",
    orderBottlesLabel: "Would you like to order bottles?",
    gasBottlesLocationLabel: "Where are your gas bottles located?",
    gasBottleLocationInfoModalTitle: "Gas bottle location",
    gasBottlesLocationInfoModalContent:
      "Our delivery person needs to easily locate and access bottle installation without putting themselves at risk. Please make sure trees are trimmed for the delivery truck, the access way is clear, and the ground is firm and non-slippery as bottles are heavy.",
    meterAccessQuestionLabel:
      "Is there anything that could prevent a Genesis team member from accessing your",
    meterAccessReasonLabel: "What could prevent access?",
    meterAccessReasonOtherLabel: "What else could prevent access?",
    bottledGasAccessModalTitle: "Accessing your property",
    bottledGasAccessModalContent: `Upon first delivery of gas bottles, all sites are subject to a safety compliance check. Please ensure you let us know of anything that could prevent delivery of bottles. This can include things like a steep driveway, stairs, dogs, gates and limited space for delivery trucks.`,
  },
};

export function PropertyPage(props) {
  const {
    values,
    touched,
    errors,
    handleChange,
    handleSubmit,
    isValid,
    askElectricitySpecificQuestions,
    askBottledGasSpecificQuestions,
    askMeterAccessQuestions,
    pageProps,
  } = props;

  const pageData = getPageData({
    modules,
    page: pageProps.page,
    defaultPageData,
  });

  const assets = [];
  if (askMeterAccessQuestions) {
    assets.push("meter");
  }
  if (askBottledGasSpecificQuestions) {
    assets.push("gas bottles");
  }

  return (
    <motion.div
      className={styles.container}
      exit="undefined"
      data-testid="propertyPage"
    >
      <Intro>
        {(introStyles) => (
          <motion.div
            initial="initial"
            animate="animate"
            exit="exit"
            variants={headerFade}
          >
            <h1 className={introStyles.title}>{pageData.fields.title}</h1>
            <div className={introStyles.text}>
              <InlineBlockEditor
                jsonString={pageData.fields.blockEditor}
                enableProse={false}
              />
            </div>
          </motion.div>
        )}
      </Intro>
      <motion.div
        initial="initial"
        animate="animate"
        exit="exit"
        variants={itemContainer}
        className={styles.body}
      >
        <p className={styles.label}>{pageData.fields.situationLabel}</p>
        <RadioList
          name="situation"
          onChange={handleChange}
          errorMessage={errors.situation}
          showError={errors.situation && touched.situation}
          size="medium"
          items={[
            {
              text: "Moving / Just moved",
              value: PROPERTY_SITUATION_MOVE,
              checked: values.situation === PROPERTY_SITUATION_MOVE,
            },
            {
              text: "Switching suppliers",
              value: PROPERTY_SITUATION_TRANSFER,
              checked: values.situation === PROPERTY_SITUATION_TRANSFER,
            },
          ]}
        />

        <AnimatePresence>
          {values.situation === PROPERTY_SITUATION_MOVE && (
            <motion.div
              initial="initial"
              animate="animate"
              exit="exit"
              variants={heightFadeVariants}
              transition={heightFadeTransition}
              className={styles.dependant_fields_wrapper}
            >
              <p className={styles.label}>{pageData.fields.moveInDateLabel}</p>
              <DateInput
                name="moveInDate"
                label="DD/MM/YYYY"
                placeholder="DD/MM/YYYY"
                value={values.moveInDate}
                onChange={handleChange}
                errorMessage={errors.moveInDate}
                showError={errors.moveInDate && touched.moveInDate}
                isHalfWidth
              />
            </motion.div>
          )}
        </AnimatePresence>

        {askElectricitySpecificQuestions && (
          <ElectricityQuestions
            values={values}
            errors={errors}
            touched={touched}
            handleChange={handleChange}
            pageData={pageData}
          />
        )}

        {askBottledGasSpecificQuestions && (
          <BottledGasQuestions
            values={values}
            errors={errors}
            touched={touched}
            handleChange={handleChange}
            pageData={pageData}
          />
        )}

        {!!assets.length && (
          <MeterOrBottleAccessQuestions
            values={values}
            errors={errors}
            touched={touched}
            handleChange={handleChange}
            askBottledGasSpecificQuestions={askBottledGasSpecificQuestions}
            askMeterAccessQuestions={askMeterAccessQuestions}
            assets={assets}
            pageData={pageData}
          />
        )}

        {/* <p className={styles.label}>Is your site connected to solar?</p>
        <RadioList
          name="isConnectedToSolar"
          onChange={handleChange}
          errorMessage={errors.isConnectedToSolar}
          showError={errors.isConnectedToSolar && touched.isConnectedToSolar}
          items={[
            {
              text: "Yes",
              value: true,
              checked: values.isConnectedToSolar === "true",
              testId: "isConnectedToSolarYesOption",
            },
            {
              text: "No",
              value: false,
              checked: values.isConnectedToSolar === "false",
              testId: "isConnectedToSolarNoOption",
            },
          ]}
        /> */}
      </motion.div>
      <div className="mt-5 overflow-hidden md:mt-10 lg:mt-15">
        {/* <AnimatePresence>
          {errorMessage && (
            <motion.div
              initial="initial"
              animate="animate"
              exit="exit"
              variants={heightFadeVariants}
              transition={heightFadeTransition}
              className={styles.feedback_container}
            >
              <Feedback type="error" title="Error" data-testid="errorMessage">
                {errorMessage}
              </Feedback>
            </motion.div>
          )}
        </AnimatePresence> */}
      </div>
      <StepProgressionButton
        disabled={!isValid}
        onContinueClick={handleSubmit}
        backLink={appResidentialRoutes.creditCheck}
      />
    </motion.div>
  );
}

const mapPropsToValues = ({
  appPropertyState,
  askElectricitySpecificQuestions,
  askBottledGasSpecificQuestions,
  askMeterAccessQuestions,
}) => ({
  ...appPropertyState,
  askElectricitySpecificQuestions,
  askBottledGasSpecificQuestions,
  askMeterAccessQuestions,
});

const validationSchema = Yup.object().shape({
  situation: Yup.string().required("This field is required"),
  moveInDate: Yup.string().when("situation", {
    is: (value) => value === PROPERTY_SITUATION_MOVE,
    then: Yup.string()
      .required("Move in date is required")
      .matches(
        /(?:0[1-9]|[12][0-9]|3[01])\/(?:0[1-9]|1[0-2])\/(?:\d{4})$/,
        "Please enter a valid move in date",
      )
      .max(10, "Move in date cannot be more than 10 characters")
      .test(
        "moveInDate",
        "Please enter a date no more than 12 months in the past or future",
        (value) => {
          const now = new Date(Date.now());
          const enteredDate = parse(value, "dd/MM/yyyy", now);
          const difference = differenceInDays(now, enteredDate);
          return difference >= -365 && difference <= 365;
        },
      ),
  }),
  meterAccessHasAnIssue: Yup.string().when(
    ["askMeterAccessQuestions", "askBottledGasSpecificQuestions"],
    {
      is: (meterAccessValue, bottledGasValue) =>
        meterAccessValue === true || bottledGasValue === true,
      then: Yup.string().required("This field is required"),
    },
  ),
  meterAccessIssueReasons: Yup.array().when("meterAccessHasAnIssue", {
    is: (value) => value === "true",
    then: Yup.array()
      .min(1, "Please select an option")
      .required("Please select an option"),
  }),
  meterAccessIssueReasonOther: Yup.string().when("meterAccessIssueReasons", {
    is: (value) => value.includes(PREVENT_ACCESS_REASON_OTHER),
    then: Yup.string()
      .max(255, "This field cannot be more than 255 characters")
      .required("Please enter what could prevent access"),
  }),
  isMedicalSupport: Yup.string().when("askElectricitySpecificQuestions", {
    is: (value) => value === true,
    then: Yup.string().required("This field is required"),
  }),
  isWellBeing: Yup.string().when("askElectricitySpecificQuestions", {
    is: (value) => value === true,
    then: Yup.string().required("This field is required"),
  }),
  wellBeingAffectedReason: Yup.string().when("isWellBeing", {
    is: (value) => value === "true",
    then: Yup.string().required("Please select an option"),
  }),
  gasBottlesCount: Yup.string().when("askBottledGasSpecificQuestions", {
    is: (value) => value === true,
    then: Yup.string().required("This field is required"),
  }),
  gasBottlesSupplier: Yup.string().when("gasBottlesCount", {
    is: (value) => value === LPG_NO_OF_BOTTLES_SETUP_2,
    then: Yup.string().required("Please select your current supplier"),
  }),
  orderBottlesOption: Yup.string().when("gasBottlesCount", {
    is: (value) => value === LPG_NO_OF_BOTTLES_SETUP_2,
    then: Yup.string().required("Please select an option"),
  }),
  gasBottlesLocation: Yup.string().when("gasBottlesCount", {
    is: (value) => value === LPG_NO_OF_BOTTLES_SETUP_2,
    then: Yup.string().required(
      "Please select the location of your gas bottles",
    ),
  }),
  // isConnectedToSolar: Yup.string().required("This field is required"),
});

const stateSelector = createSelector(
  [selectAppState, selectAppPropertyState, selectResidentialOffers],
  (appState, appPropertyState, residentialOffers) => {
    const { products } = appState;
    const {
      electricityOffersResult: { smartMeter: elecSmartMeters },
      naturalGasOffersResult: { smartMeter: gasSmartMeters },
    } = residentialOffers;

    const askElectricitySpecificQuestions = isElectricitySelected(products);
    const askBottledGasSpecificQuestions = isBottledGasSelected(products);

    const askMeterAccessQuestions =
      (isElectricitySelected(products) && !elecSmartMeters) ||
      (isNaturalGasSelected(products) && !gasSmartMeters);

    return {
      appPropertyState,
      askElectricitySpecificQuestions,
      askBottledGasSpecificQuestions,
      askMeterAccessQuestions,
      nextPageUrl: appResidentialRoutes.payment,
    };
  },
);

function PropertyPageForm(pageProps) {
  const props = useSelector(stateSelector);
  const dispatch = useDispatch();

  const initialValues = mapPropsToValues(props);
  const router = useRouter();

  const onSubmit = (values) => {
    dispatch(submitPropertyDetailsForm(values));
    trackResidentialContinueButton({
      event: "input_property_details",
      content_tertiary: "input property details",
      selection:
        values.situation === PROPERTY_SITUATION_MOVE
          ? "moving / just moved"
          : "switching providers",
    });
    router.push({ pathname: props.nextPageUrl });
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      validateOnMount
      enableReinitialize
    >
      {(formikProps) => (
        <PropertyPage {...props} {...formikProps} pageProps={pageProps} />
      )}
    </Formik>
  );
}

export default PropertyPageForm;
