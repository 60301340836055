import { useState } from "react";
import classNames from "classnames";
import { motion } from "framer-motion";
import { sortBy } from "lodash-es";
import { ChevronDown } from "assets/icons";
import { Link } from "components/Link";
import { ModuleWrapper } from "components/Wrappers";
import { getContentWithLineBreaks } from "helpers/htmlHelper";
import { GoalIcon } from "./GoalIcon";

export const Goal = ({ module }) => {
  const { anchor, cards, description, title } = module.fields;
  const sortedGoals = sortBy(cards, ["properties.itemOrder"]);
  const [isInView, setIsInView] = useState(false);

  return (
    <ModuleWrapper id={anchor} psnid="psn_goal">
      {title && (
        <h2
          className="mb-5 font-bold text-32/36 text-dark-primary lg:text-44/52"
          data-testid="goal-title"
        >
          {title}
        </h2>
      )}
      {description && (
        <p
          className="text-20/32 text-black-primary lg:text-24/36"
          data-testid="goal-description"
        >
          {getContentWithLineBreaks(description)}
        </p>
      )}
      {sortedGoals && (
        <div className="grid gap-4 mt-7 lg:mt-10 sm:gap-6 sm:grid-cols-2 md:gap-7 md:grid-cols-3 lg:grid-cols-4">
          {sortedGoals.map((card) => {
            const isComplete =
              card.fields.percentageComplete === "100" ||
              Number(card.fields.percentageComplete) > 100;
            const showAnimatedCircle =
              !isComplete && !isNaN(Number(card.fields.percentageComplete));
            return (
              <motion.div
                className={classNames(
                  "w-full p-5 pb-10 shadow-100 rounded-5 sm:basis-[calc(50%-12px)] md:basis-[calc(33.33%-19px)] lg:basis-[calc(25%-21px)]",
                  { "bg-day-gradient": isComplete },
                )}
                key={`key-${card.contentID}`}
                whileInView={
                  /* istanbul ignore next */
                  () => setIsInView(true)
                }
                viewport={{ once: true, amount: 0.4 }}
              >
                {card.fields.cta.href && card.fields.cta.text && (
                  <div
                    className="flex justify-between mb-5 sm:mb-3"
                    data-testid="goal-cta"
                  >
                    <Link
                      href={card.fields.cta.href}
                      target={card.fields.cta.target}
                    >
                      <a
                        className={classNames(
                          "mb-5 text-13/20 text-black-primary sm:mb-3 md:text-14/20 ",
                          { "!text-white": isComplete },
                        )}
                      >
                        {card.fields.cta.text}
                      </a>
                    </Link>
                    <Link
                      href={card.fields.cta.href}
                      target={card.fields.cta.target}
                    >
                      <a>
                        <ChevronDown
                          className={classNames(
                            "w-5 h-5 text-black-primary -rotate-90 cursor-pointer",
                            { "!text-white": isComplete },
                          )}
                        />
                      </a>
                    </Link>
                  </div>
                )}
                <div className="flex items-center justify-between sm:flex-col sm:space-y-10">
                  {card.fields.title && (
                    <p
                      className={classNames(
                        "mr-5 font-medium sm:mr-0 text-20/24 md:text-24/28",
                        { "text-white": isComplete },
                      )}
                      data-testid="goal-card-title"
                    >
                      {card.fields.title}
                    </p>
                  )}
                  {card.fields.icon && (
                    <GoalIcon
                      card={card}
                      isComplete={isComplete}
                      showAnimatedCircle={showAnimatedCircle}
                      isInView={isInView}
                    />
                  )}
                </div>
              </motion.div>
            );
          })}
        </div>
      )}
    </ModuleWrapper>
  );
};
