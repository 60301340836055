import { createSelector } from "@reduxjs/toolkit";
import { submitPersonalDetailsForm } from "actions/appUserStateActions";
import { createZendeskTicket } from "actions/zendeskActions";
import { Formik } from "formik";
import { motion } from "framer-motion";
import { useRouter } from "next/router";
import { useDispatch, useSelector } from "react-redux";
import { selectAddressState, selectAppState, selectAppUserState } from "reducers/selector";
import * as Yup from "yup";
import { InlineBlockEditor } from "components/BlockEditor";
import {
  Dropdown,
  errorText,
  Input,
  RadioButton,
} from "components/JoinFormControls";
import { StepProgressionButton } from "components/StepProgressionButton";
import { ADDRESS_TYPE_REGISTRY, appBusinessRoutes } from "helpers/constants";
import { getPageData } from "helpers/getPageData";
import { trackBusinessContinueButton } from "helpers/gtmHelper";
import { IntroHeader } from "page-modules/Join/IntroHeader";
import {
  footerFade,
  footerFadeTransition,
  itemContainer,
  itemFade,
} from "./animations";
import styles from "./business-contact-details-page.module.scss";

const modules = [
  "GE_JOIN_Intro_Header",
  "GE_JOIN_Business_Contact_Details",
].map((item) => item.toLowerCase());

function ContactDetailsPage({ pageData, props }) {
  const {
    backButtonUrl,
    values,
    touched,
    errors,
    handleChange,
    handleSubmit,
    isValid,
  } = props;

  const {
    fields: {
      blockEditor,
      title,
      contactDetails,
      eBilling,
      emailUpdates,
      finePrint,
    },
  } = pageData;

  return (
    <motion.div
      className={styles.container}
      exit="undefined"
      data-testid="contactDetailsPage"
    >
      <IntroHeader
        module={{
          fields: {
            title,
            blockEditor,
          },
        }}
      />
      <motion.div
        initial="initial"
        animate="animate"
        exit="exit"
        variants={itemContainer}
        className={styles.body}
      >
        <p className={styles.text_note_container}>{contactDetails}</p>
        <motion.div variants={itemFade} className={styles.item}>
          <div className={styles.row}>
            <div className={`${styles.column} ${styles.title_field_container}`}>
              <Dropdown
                name="title"
                placeholder="Title"
                value={values.title}
                options={[
                  { value: "MRS", name: "Mrs" },
                  { value: "MISS", name: "Miss" },
                  { value: "MR", name: "Mr" },
                  { value: "DR", name: "Dr" },
                  { value: "MS", name: "Ms" },
                  { value: "REV", name: "Rev" },
                ]}
                onChange={handleChange}
                errorMessage={errors.title}
                showError={errors.title && touched.title}
              />
            </div>
            <div className={styles.column}>
              <Input
                name="firstName"
                placeholder="First Name"
                value={values.firstName}
                onChange={handleChange}
                errorMessage={errors.firstName}
                showError={errors.firstName && touched.firstName}
              />
            </div>
            <div className={styles.column}>
              <Input
                name="lastName"
                placeholder="Last Name"
                value={values.lastName}
                onChange={handleChange}
                errorMessage={errors.lastName}
                showError={errors.lastName && touched.lastName}
              />
            </div>
          </div>

          <p className={styles.text_container}>{eBilling}</p>
          <div className={styles.row}>
            <div className={styles.column}>
              <Input
                name="email"
                placeholder="Email"
                value={values.email}
                onChange={handleChange}
                errorMessage={errors.email}
                showError={errors.email && touched.email}
              />
            </div>
            <div className={styles.column}>
              <Input
                name="phone"
                placeholder="Phone"
                value={values.phone}
                onChange={handleChange}
                errorMessage={errors.phone}
                showError={errors.phone && touched.phone}
              />
            </div>
          </div>

          <div className={styles.radio_container}>
            <p className={styles.radio_label}>{emailUpdates}</p>
            <div className={styles.row}>
              <div className={`${styles.column} ${styles.radio_column}`}>
                <RadioButton
                  name="isPromoByEmail"
                  checked={values.isPromoByEmail === "true"}
                  onChange={handleChange}
                  value={true}
                  text="Yes please"
                  size="medium"
                />
              </div>
              <div className={`${styles.column} ${styles.radio_column}`}>
                <RadioButton
                  name="isPromoByEmail"
                  checked={values.isPromoByEmail === "false"}
                  onChange={handleChange}
                  value={false}
                  text="No thanks"
                  size="medium"
                />
              </div>
            </div>
            {errors.isPromoByEmail && touched.isPromoByEmail && (
              <span
                data-testid="isPromoByEmailErrorMessage"
                className={errorText}
              >
                {errors.isPromoByEmail}
              </span>
            )}
          </div>
        </motion.div>
      </motion.div>
      <motion.div
        initial="initial"
        animate="animate"
        exit="exit"
        variants={footerFade}
        transition={footerFadeTransition}
        className={styles.small_print}
      >
        <InlineBlockEditor
          jsonString={finePrint}
          className="prose-p:text-13/20 prose-p:md:text-14/20"
        />
      </motion.div>
      <div className="mt-10 overflow-hidden md:mt-15 lg:mt-20" />

      <StepProgressionButton
        onContinueClick={handleSubmit}
        testId="continueButton"
        applyDisabledStyle={!isValid}
        backLink={backButtonUrl}
      />
    </motion.div>
  );
}

const mapPropsToValues = ({ appUserState }) => ({
  title: appUserState.title,
  firstName: appUserState.firstName,
  lastName: appUserState.lastName,
  email: appUserState.email,
  phone: appUserState.phone,
  isPromoByEmail: appUserState.isPromoByEmail || "true",
});

const validationSchema = Yup.object().shape({
  title: Yup.string().required("Please select your title"),
  firstName: Yup.string()
    .max(40, "First name cannot be more than 40 characters")
    .required("Please enter your first name"),
  lastName: Yup.string()
    .max(80, "Last name cannot be more than 80 characters")
    .required("Please enter your last name"),
  email: Yup.string()
    .email("Please enter a valid email")
    .max(80, "Email address cannot be more than 80 characters")
    .required("Please enter your email address"),
  phone: Yup.string()
    .min(7, "Phone number must be at least 7 digits")
    .max(40, "Phone number cannot be more than 40 digits")
    .matches(/^[0-9+]+[0-9 ]{3,40}$/, "Please enter valid number")
    .required("Please enter your phone number"),
  isPromoByEmail: Yup.string()
    .matches(/(true|false)/i, { message: "This field is required" })
    .required("This field is required"),
});

const selectBusinessContactState = createSelector(
  [selectAddressState, selectAppUserState],
  (addressState, appUserState) => {
    const { addressType } = addressState;
    const backButtonUrl =
      addressType === ADDRESS_TYPE_REGISTRY
        ? appBusinessRoutes.addressIcp
        : appBusinessRoutes.fuel;

    return { appUserState, backButtonUrl };
  },
);

function ContactDetailsPageForm(props) {
  const pageData = getPageData({ modules, page: props.page });
  const componentProps = useSelector(selectBusinessContactState);
  const dispatch = useDispatch();

  const router = useRouter();

  const initialValues = mapPropsToValues(componentProps);
  const { customerType } = useSelector(selectAppState);
  const { addressDetails } = useSelector(selectAddressState);

  const onSubmit = (values) => {
    const invalidRequest = !customerType || !addressDetails;
    if(!props?.isTesting && invalidRequest) {
      router.push(appRoutes.customerType);
      return;
    }
    dispatch(submitPersonalDetailsForm(values));
    dispatch(createZendeskTicket());
    trackBusinessContinueButton({
      event: "input_contact_details",
      content_tertiary: "input contact details",
    });
    router.push(appBusinessRoutes.termSelect);
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      validateOnMount
      enableReinitialize
    >
      {(formikProps) => (
        <ContactDetailsPage
          pageData={pageData}
          props={{ ...componentProps, ...formikProps }}
        />
      )}
    </Formik>
  );
}

export { ContactDetailsPageForm as BusinessContactTemplate };
