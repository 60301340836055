import { motion } from "framer-motion";

export const headerFade = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: {
    y: -60,
    opacity: 0,
    transition: {
      type: "tween",
    },
  },
};

export const itemContainerFade = {
  initial: {
    opacity: 1,
  },
  animate: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1,
      delay: 0.1,
    },
  },
  exit: {
    opacity: 0,
    y: -60,
    transition: {
      type: "tween",
    },
  },
};

export const itemFade = {
  initial: { opacity: 0, y: 60 },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: 0 },
};

export const footerFade = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: {
    opacity: 0,
    transition: {
      delay: 0,
    },
  },
};

export const footerFadeTransition = {
  delay: 0.2,
};

const transition = {
  delay: 0.3,
  type: "spring",
  bounce: 0.3,
  duration: 0.3,
};

const cardContainerAnimation = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.2,
    },
  },
};

export function FadeInBottomContainer({ children }) {
  return (
    <motion.div
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true, amount: 0.2 }}
      variants={cardContainerAnimation}
      transition={transition}
    >
      {children}
    </motion.div>
  );
}
