import { useEffect } from "react";
import { useRouter } from "next/router";
import {
  DUAL_FUEL_ELEC_BONUS_CREDIT_PLAN_CODE,
  PAYMENT_METHOD_CREDIT_CARD,
  PAYMENT_METHOD_DIRECT_DEBIT,
  PAYMENT_METHOD_MANUAL,
  PLAN_TYPE_BASIC,
  PRODUCT_ELECTRICITY,
  PRODUCT_GAS,
  PRODUCT_LPG,
  productPrices,
  PROPERTY_SITUATION_MOVE,
  RESIDENTIAL_PLAN_TERM_FIXED12,
} from "helpers/constants";
import { formatDateToString } from "helpers/dateHelper";

export const getPrimaryAddress = (selectedPrimaryAddress) => {
  if (typeof selectedPrimaryAddress === "boolean") {
    return selectedPrimaryAddress ? "yes" : "no";
  }
  return "undefined";
};

export const getCommunicationPreference = (preference) => {
  switch (preference) {
    case "true":
      return "email";
    case "false":
      return "none";
    default:
      return "undefined";
  }
};

export const getHouseholdSize = (noOfAdults, noOfKids) =>
  parseInt(noOfAdults, 10) + parseInt(noOfKids, 10) || "undefined";

export const getServicesRequired = (selectedProducts) =>
  selectedProducts
    .map((product) => product.name)
    .join("|")
    .toLowerCase();

export const getPlanType = (selectedPlanType) =>
  selectedPlanType || PLAN_TYPE_BASIC;

export const getPlanTermAndName = (termData) => {
  const selectedPlanTermConfig =
    termData.find(({ isSelected }) => isSelected) || {};
  return {
    planTerm: selectedPlanTermConfig.codeName || RESIDENTIAL_PLAN_TERM_FIXED12,
    planName: selectedPlanTermConfig.displayName,
  };
};

export const getCampaignElectricity = (campaignCodes) => {
  const campaignElecSelected = campaignCodes.filter(
    ({ planProduct }) => planProduct === PRODUCT_ELECTRICITY,
  );
  return campaignElecSelected.length > 0
    ? campaignElecSelected[0].planCode
    : "";
};

export const getCampaignGas = (campaignCodes) => {
  const campaignGasSelected = campaignCodes.filter(
    ({ planProduct }) => planProduct === PRODUCT_GAS,
  );
  return campaignGasSelected.length > 0 ? campaignGasSelected[0].planCode : "";
};

export const getCampaignLpg = (campaignCodes) => {
  const campaignLpgSelected = campaignCodes.filter(
    ({ planProduct }) => planProduct === PRODUCT_LPG,
  );
  return campaignLpgSelected.length > 0 ? campaignLpgSelected[0].planCode : "";
};

export const getCampaignDualFuel = (campaignCodes) => {
  const campaignDualFuelSelected = campaignCodes.filter(
    ({ planCode }) => planCode === DUAL_FUEL_ELEC_BONUS_CREDIT_PLAN_CODE,
  );
  return campaignDualFuelSelected.length > 0
    ? campaignDualFuelSelected[0].planCode
    : "";
};

export const getMeanValuePrice = (
  selectedServices,
  selectedContract,
  selectedPlan,
) => {
  const meanValue = productPrices.filter(({ services, contract, plan }) => {
    const servicesMatch = services === selectedServices.toLowerCase();
    const contractMatch = contract === selectedContract.toLowerCase();
    const planMatch = plan === selectedPlan.toLowerCase();
    return servicesMatch && contractMatch && planMatch;
  });

  return meanValue.length > 0 ? meanValue[0].mean.toString() : "0";
};

export const getPaymentMethod = (selectedPaymentMethod) => {
  switch (selectedPaymentMethod) {
    case PAYMENT_METHOD_CREDIT_CARD:
      return "credit card";
    case PAYMENT_METHOD_DIRECT_DEBIT:
      return "direct debit";
    case PAYMENT_METHOD_MANUAL:
      return "manual payments";
    default:
      return "none";
  }
};

export const getSituation = (selectedSituation) =>
  selectedSituation === PROPERTY_SITUATION_MOVE ? "moving" : "switching";

export function usePageViews() {
  let location = useRouter();
  useEffect(() => {
    if (window.dataLayer) {
      window.dataLayer.push({
        event: "pageview",
        page: window.location.pathname,
      });
    }
  }, [location]);
}

export const gaPush = (gaData) => {
  window.dataLayer.push({
    ...gaData,
    event_timestamp: formatDateToString(
      new Date().toISOString(),
      "yyyy-MM-dd hh:mm:ss",
    ),
  });
};

export const trackContinueButton = (data) => {
  gaPush({
    ...data,
    event: "cta_click",
    event_label: "continue",
  });
};

export const trackResidentialContinueButton = (
  data,
  eventLabel = "continue",
) => {
  gaPush({
    ...data,
    event_category: "residential join",
    event_label: eventLabel,
  });
};

export const trackResidentialAddressPageLoad = () => {
  gaPush({
    event: "begin_checkout",
    event_category: "residential join",
    content_tertiary: "input address",
    ecommerce: {
      items: [
        {
          item_name: "residential join",
          item_id: "residential join",
          price: undefined,
          item_brand: "genesis",
          item_category: undefined, // fuel type
          item_category2: undefined, // plan type
          item_category3: undefined, // energy user type
          item_variant: undefined, // term type
          quantity: 1,
        },
      ],
    },
  });
};

export const trackBusinessContinueButton = (data, eventLabel = "continue") => {
  gaPush({
    ...data,
    event_category: "business join",
    event_label: eventLabel,
  });
};

export const trackBusinessAddressPageLoad = () => {
  gaPush({
    event: "begin_checkout",
    event_category: "business join",
    content_tertiary: "input address",
    ecommerce: {
      items: [
        {
          item_name: "business join",
          item_id: "business join",
          price: undefined,
          item_brand: "genesis",
          item_category: undefined, // fuel type
          item_category2: undefined, // plan type
          item_category3: undefined, // energy user type
          item_variant: undefined, // term type
          quantity: 1,
        },
      ],
    },
  });
};

export const trackBusinessSuccessPageLoad = ({
  selectedProducts,
  selectedTermName,
  meanFixedCharges,
  postalCode,
  transactionId,
}) => {
  gaPush({
    event: "purchase",
    event_category: "business join",
    event_label: "success",
    content_tertiary: "success",
    postal_code_sign_up: postalCode,
    ecommerce: {
      currency: "NZD",
      transaction_id: transactionId,
      value: meanFixedCharges, // mean price value for each service
      tax: 0.0,
      items: [
        {
          item_name: "business join",
          item_id: "business join",
          price: meanFixedCharges, // mean price value for each service
          item_brand: "genesis",
          item_category: selectedProducts, // fuel type
          item_category2: "business energy", // plan type
          item_category3: undefined, //indicate energy user type if applicable
          item_variant: selectedTermName, // term type
          quantity: 1,
        },
      ],
    },
  });
};

export const trackBusinessCallbackSuccessPageLoad = ({ selectedProducts }) => {
  gaPush({
    event: "purchase",
    event_category: "business join",
    event_label: "callback success",
    content_tertiary: "callback success",
    ecommerce: {
      items: [
        {
          item_name: "business join",
          item_id: "business join",
          price: undefined,
          item_brand: "genesis",
          item_category: selectedProducts, // fuel type
          item_category2: undefined, // plan type
          item_category3: undefined, // energy user type
          item_variant: undefined, // term type
          quantity: 1,
        },
      ],
    },
  });
};

export const trackResidentialSuccessPageLoad = ({
  selectedProducts,
  selectedTermName,
  meanFixedCharges,
  postalCode,
  transactionId,
  selectedPlanType,
  energyUsageType,
  householdSize,
  isExistingCustomerSignup,
}) => {
  gaPush({
    event: "purchase",
    event_category: "residential join",
    event_label: isExistingCustomerSignup
      ? "success for existing customer"
      : "success",
    content_tertiary: isExistingCustomerSignup
      ? "success for existing customer"
      : "success",
    postal_code_sign_up: postalCode,
    household_size_sign_up: householdSize,
    ecommerce: {
      currency: "NZD",
      transaction_id: transactionId,
      value: meanFixedCharges, // mean price value for each service
      tax: 0.0,
      items: [
        {
          item_name: "residential join",
          item_id: "residential join",
          price: meanFixedCharges, // mean price value for each service
          item_brand: "genesis",
          item_category: selectedProducts, // fuel type
          item_category2: selectedPlanType, // plan type
          item_category3: energyUsageType, //indicate energy user type if applicable
          item_variant: selectedTermName, // term type
          quantity: 1,
        },
      ],
    },
  });
};

export const trackResidentialCallbackSuccessPageLoad = ({
  selectedProducts,
}) => {
  gaPush({
    event: "purchase",
    event_category: "residential join",
    event_label: "callback success",
    content_tertiary: "callback success",
    ecommerce: {
      items: [
        {
          item_name: "residential join",
          item_id: "residential join",
          price: undefined,
          item_brand: "genesis",
          item_category: selectedProducts, // fuel type
          item_category2: undefined, // plan type
          item_category3: undefined, // energy user type
          item_variant: undefined, // term type
          quantity: 1,
        },
      ],
    },
  });
};
