import React from "react";
import classNames from "classnames";
import { motion } from "framer-motion";
import { itemFade } from "../animations";

function PlanCard({ testid, children }) {
  return (
    <motion.div
      variants={itemFade}
      className={classNames(
        "flex flex-col rounded-10",
        "transition-transform-and-opacity",
        "relative",
        "bg-white",
        "min-w-75",
        "grid grid-rows-subgrid row-span-5 grid-flow-row",
        "pb-9",
        "group",
      )}
      data-testid={testid}
    >
      {children}
    </motion.div>
  );
}

export { PlanCard };
