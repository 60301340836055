import { motion } from "framer-motion";
import { heightFadeTransition, heightFadeVariants } from "./animations";

function RegistrationError({ data }) {
  return (
    <motion.div
      initial="initial"
      animate="animate"
      exit="exit"
      variants={heightFadeVariants}
      transition={heightFadeTransition}
      data-testid="regError"
    >
      <p className="pt-5 font-medium text-red-primary text-16/24">
        Sorry, we can't seem to validate this EV's registration number. You must
        have a Battery Electric (BEV) or Plug-in Hybrid Electric Vehicle (PHEV)
        to be eligible for this plan. Please select a different plan or, call us
        on 0800 300 400.
      </p>
    </motion.div>
  );
}

export { RegistrationError };
