import { forwardRef } from "react";
import classNames from "classnames";
import { CheckboxOff, CheckboxOn } from "assets/icons";

const checkboxIconStyles = "absolute inset-0 fill-current";

function Checkbox(
  { label, value, checked, onChange, name, type = "default" },
  ref,
) {
  const isLargeType = type === "large";
  return (
    <label
      className={classNames(
        "flex items-center cursor-pointer transition-all bg-white",
        "px-5 space-x-3",
        "text-black-primary ",
        "border border-black-100 rounded-2",
        "hover:shadow-4/14/15 active:shadow-4/14/15",
        { "text-14/20 md:text-16/24 py-3 md:py-4": !isLargeType },
        { "text-15/24 md:text-18/28 py-4 md:py-5": isLargeType },
      )}
    >
      <span className={classNames("relative w-6 h-6", "text-orange-primary")}>
        <input
          value={value}
          onChange={onChange}
          checked={checked}
          className="hidden peer"
          type="checkbox"
          ref={ref}
          name={name}
        />
        <CheckboxOff className={checkboxIconStyles} />
        <CheckboxOn
          className={classNames(
            checkboxIconStyles,
            "transition-opacity opacity-0 peer-checked:opacity-100",
          )}
        />
      </span>
      <span>{label}</span>
    </label>
  );
}

Checkbox = forwardRef(Checkbox);

export { Checkbox };
