import { Icon } from "components/Icon";
import styles from "./button-checkbox-selection.module.scss";

const sizeMap = {
  small: styles.small,
  medium: styles.medium,
  large: styles.large,
};

export default function ButtonCheckboxSelection(props) {
  const {
    id = "",
    children,
    onClick,
    onInfoIconClick,
    isSelected = false,
    className,
    size,
    textSize,
    ...rest
  } = props;

  const sizeClassName = sizeMap[size];

  const onInfoIconClickInternal = (e) => {
    if (onInfoIconClick) {
      e.preventDefault();
      e.stopPropagation();
      onInfoIconClick();
    }
  };

  const activeClass = isSelected ? styles.active : "";

  return (
    <button
      className={`${styles.button} ${className} ${sizeClassName} ${activeClass}`}
      onClick={onClick}
      {...rest}
    >
      <div className={styles.iconContainer}>
        <Icon size="24" name="CheckboxOff" className={styles.icon_unselected} />
        <Icon size="24" name="CheckboxOn" className={styles.icon_selected} />
      </div>
      <div className={`${styles.text} ${styles[textSize]}`}>{children}</div>
      {onInfoIconClick && (
        <div
          onClick={onInfoIconClickInternal}
          className={styles.info}
          data-testid={`${id}InfoButton`}
        >
          <Icon
            name="Info"
            size={size === "medium" ? "18" : "24"}
            desktopSize={size === "medium" ? "24" : "32"}
          />
        </div>
      )}
    </button>
  );
}
