import { createSelector } from "@reduxjs/toolkit";
import { submitPropertyDetailsForm } from "actions/appPropertyStateActions";
import {
  checkStoreStateForBusinessCallbackFlow,
  setCallbackReferrerUrl,
  unsetCallbackReferrerUrl,
} from "actions/callbackPageActions";
import { differenceInDays, parse } from "date-fns";
import { Formik } from "formik";
import { AnimatePresence, motion } from "framer-motion";
import { useRouter } from "next/router";
import { useDispatch, useSelector } from "react-redux";
import {
  selectAppPropertyState,
  selectAppState,
  selectBusinessOffers,
  selectBusinessState,
} from "reducers/selector";
import * as Yup from "yup";
import { DateInput, RadioList } from "components/JoinFormControls";
import { StepProgressionButton } from "components/StepProgressionButton";
import {
  appBusinessRoutes,
  BUSINESS_TYPE_SOLE_TRADER,
  LPG_BOTTLE_SIZE_45,
  LPG_NO_OF_BOTTLES_SETUP_2,
  PREVENT_ACCESS_REASON_OTHER,
  PROPERTY_SITUATION_MOVE,
  PROPERTY_SITUATION_TRANSFER,
} from "helpers/constants";
import { getPageData } from "helpers/getPageData";
import { trackBusinessContinueButton } from "helpers/gtmHelper";
import {
  isBottledGasSelected,
  isElectricitySelected,
  isNaturalGasSelected,
} from "helpers/productHelper";
import { IntroHeader } from "page-modules/Join/IntroHeader";
import {
  heightFadeTransition,
  heightFadeVariants,
  itemContainer,
} from "../animations";
import { BottledGasQuestions } from "./BottledGasQuestions";
import { MeterOrBottleAccessQuestions } from "./MeterOrBottleAccessQuestions";
import styles from "./property-page.module.scss";

const modules = [
  "GE_JOIN_Intro_Header",
  "GE_JOIN_Business_Property_Details",
].map((item) => item.toLowerCase());

function PropertyPage({ pageData, props }) {
  const {
    values,
    touched,
    errors,
    handleChange,
    handleSubmit,
    isValid,
    previousPageLink,
    askBottledGasSpecificQuestions,
    askMeterAccessQuestions,
  } = props;

  const {
    fields: {
      blockEditor,
      title,
      lpgAccessOverlayButton,
      lpgAccessOverlayDescription,
      lpgAccessOverlayTitle,
      lpgAccessQuestion,
      lpgOrMeterAccessQuestion,
      meterAccessQuestion,
      lpgCapacityQuestion,
      lpgLocationOverlayButton,
      lpgLocationOverlayDescription,
      lpgLocationOverlayTitle,
      lpgLocationQuestion,
      lpgOrderBottlesQuestion,
      lpgSupplierQuestion,
      moveinDateQuestion,
      movingorSwitchingSuppliersQuestion,
      enableLPGAccessOverlay,
      enableLPGLocationOverlay,
    },
  } = pageData;

  let propertyAccessQuestion = "";
  if (askMeterAccessQuestions && askBottledGasSpecificQuestions) {
    propertyAccessQuestion = lpgOrMeterAccessQuestion;
  } else if (askMeterAccessQuestions) {
    propertyAccessQuestion = meterAccessQuestion;
  } else if (askBottledGasSpecificQuestions) {
    propertyAccessQuestion = lpgAccessQuestion;
  }

  return (
    <motion.div
      className={styles.container}
      exit="undefined"
      data-testid="propertyPage"
    >
      <IntroHeader
        module={{
          fields: {
            title,
            blockEditor,
          },
        }}
      />
      <motion.div
        initial="initial"
        animate="animate"
        exit="exit"
        variants={itemContainer}
        className={styles.body}
      >
        <p className={styles.label}>{movingorSwitchingSuppliersQuestion}</p>
        <RadioList
          name="situation"
          onChange={handleChange}
          errorMessage={errors.situation}
          showError={errors.situation && touched.situation}
          size="medium"
          items={[
            {
              text: "Moving / Just moved",
              value: PROPERTY_SITUATION_MOVE,
              checked: values.situation === PROPERTY_SITUATION_MOVE,
            },
            {
              text: "Switching suppliers",
              value: PROPERTY_SITUATION_TRANSFER,
              checked: values.situation === PROPERTY_SITUATION_TRANSFER,
            },
          ]}
        />

        <AnimatePresence>
          {values.situation === PROPERTY_SITUATION_MOVE && (
            <motion.div
              initial="initial"
              animate="animate"
              exit="exit"
              variants={heightFadeVariants}
              transition={heightFadeTransition}
              className={styles.dependant_fields_wrapper}
            >
              <p className={styles.label}>{moveinDateQuestion}</p>
              <DateInput
                name="moveInDate"
                label="DD/MM/YYYY"
                placeholder="DD/MM/YYYY"
                value={values.moveInDate}
                onChange={handleChange}
                errorMessage={errors.moveInDate}
                showError={errors.moveInDate && touched.moveInDate}
                isHalfWidth
              />
            </motion.div>
          )}
        </AnimatePresence>

        {askBottledGasSpecificQuestions && (
          <BottledGasQuestions
            values={values}
            errors={errors}
            touched={touched}
            handleChange={handleChange}
            lpgCapacityQuestion={lpgCapacityQuestion}
            lpgLocationOverlayButton={lpgLocationOverlayButton}
            lpgLocationOverlayDescription={lpgLocationOverlayDescription}
            lpgLocationOverlayTitle={lpgLocationOverlayTitle}
            lpgLocationQuestion={lpgLocationQuestion}
            lpgOrderBottlesQuestion={lpgOrderBottlesQuestion}
            lpgSupplierQuestion={lpgSupplierQuestion}
            enableLPGLocationOverlay={enableLPGLocationOverlay === "true"}
          />
        )}

        {!!propertyAccessQuestion && (
          <MeterOrBottleAccessQuestions
            values={values}
            errors={errors}
            touched={touched}
            handleChange={handleChange}
            askBottledGasSpecificQuestions={askBottledGasSpecificQuestions}
            askMeterAccessQuestions={askMeterAccessQuestions}
            propertyAccessQuestion={propertyAccessQuestion}
            lpgAccessOverlayButton={lpgAccessOverlayButton}
            lpgAccessOverlayDescription={lpgAccessOverlayDescription}
            lpgAccessOverlayTitle={lpgAccessOverlayTitle}
            lpgAccessQuestion={lpgAccessQuestion}
            enableLPGAccessOverlay={enableLPGAccessOverlay === "true"}
          />
        )}
      </motion.div>
      <div className="mt-5 md:mt-10 lg:mt-15 overflow-hidden"></div>
      <StepProgressionButton
        onContinueClick={handleSubmit}
        applyDisabledStyle={!isValid}
        backLink={previousPageLink}
      />
    </motion.div>
  );
}

const mapPropsToValues = ({
  appPropertyState,
  askMeterAccessQuestions,
  askBottledGasSpecificQuestions,
}) => ({
  ...appPropertyState,
  askMeterAccessQuestions,
  askBottledGasSpecificQuestions,
});

const validationSchema = Yup.object().shape({
  situation: Yup.string().required("This field is required"),
  moveInDate: Yup.string().when("situation", {
    is: (value) => value === PROPERTY_SITUATION_MOVE,
    then: Yup.string()
      .required("Move in date is required")
      .matches(
        /(?:0[1-9]|[12][0-9]|3[01])\/(?:0[1-9]|1[0-2])\/(?:\d{4})$/,
        "Please enter a valid move in date",
      )
      .max(10, "Move in date cannot be more than 10 characters")
      .test(
        "moveInDate",
        "Please enter a date no more than 12 months in the past or future",
        (value) => {
          const now = new Date(Date.now());
          const enteredDate = parse(value, "dd/MM/yyyy", now);
          const difference = differenceInDays(now, enteredDate);
          return difference >= -365 && difference <= 365;
        },
      ),
  }),
  meterAccessHasAnIssue: Yup.string().when(
    ["askMeterAccessQuestions", "askBottledGasSpecificQuestions"],
    {
      is: (meterAccessValue, bottledGasValue) =>
        meterAccessValue === true || bottledGasValue === true,
      then: Yup.string().required("This field is required"),
    },
  ),
  meterAccessIssueReasons: Yup.array().when("meterAccessHasAnIssue", {
    is: (value) => value === "true",
    then: Yup.array()
      .min(1, "Please select an option")
      .required("Please select an option"),
  }),
  meterAccessIssueReasonOther: Yup.string().when("meterAccessIssueReasons", {
    is: (value) => value.includes(PREVENT_ACCESS_REASON_OTHER),
    then: Yup.string()
      .max(255, "This field cannot be more than 255 characters")
      .required("Please enter what could prevent access"),
  }),
  gasBottlesCount: Yup.string().when("askBottledGasSpecificQuestions", {
    is: (value) => value === true,
    then: Yup.string().required("This field is required"),
  }),
  gasBottleSize: Yup.string().when("gasBottlesCount", {
    is: (value) => value === LPG_NO_OF_BOTTLES_SETUP_2,
    then: Yup.string().required("Please select your gas bottle size"),
  }),
  gasBottlesSupplier: Yup.string().when("gasBottleSize", {
    is: (value) => value === LPG_BOTTLE_SIZE_45,
    then: Yup.string().required("Please select your current supplier"),
  }),
  orderBottlesOption: Yup.string().when("gasBottleSize", {
    is: (value) => value === LPG_BOTTLE_SIZE_45,
    then: Yup.string().required("Please select an option"),
  }),
  gasBottlesLocation: Yup.string().when("gasBottleSize", {
    is: (value) => value === LPG_BOTTLE_SIZE_45,
    then: Yup.string().required(
      "Please select the location of your gas bottles",
    ),
  }),
});

export const selectBusinessPropertyState = createSelector(
  [
    selectBusinessState,
    selectAppState,
    selectAppPropertyState,
    selectBusinessOffers,
  ],
  (businessState, appState, appPropertyState, businessOffers) => {
    const { businessType } = businessState;
    const { products } = appState;
    const {
      electricityOffersResult: { smartMeter: elecSmartMeters },
      naturalGasOffersResult: { smartMeter: gasSmartMeters },
    } = businessOffers;

    const previousPageLink =
      businessType === BUSINESS_TYPE_SOLE_TRADER
        ? appBusinessRoutes.creditCheck
        : appBusinessRoutes.termSelect;

    const askBottledGasSpecificQuestions = isBottledGasSelected(products);

    const askMeterAccessQuestions =
      (isElectricitySelected(products) && !elecSmartMeters) ||
      (isNaturalGasSelected(products) && !gasSmartMeters);

    return {
      appPropertyState,
      askBottledGasSpecificQuestions,
      askMeterAccessQuestions,
      nextPageUrl: appBusinessRoutes.payment,
      previousPageLink,
      businessType,
    };
  },
);

export function BusinessPropertyTemplate(props) {
  const pageData = getPageData({ modules, page: props.page });
  const componentProps = useSelector(selectBusinessPropertyState);

  const dispatch = useDispatch();

  const initialValues = mapPropsToValues(componentProps);
  const router = useRouter();

  const {
    nextPageUrl,
    askMeterAccessQuestions,
    askBottledGasSpecificQuestions,
    businessType,
  } = componentProps;

  const onSubmit = (values) => {
    const formWithDefaultAccessValues = {
      ...values,
      meterAccessHasAnIssue: "",
      meterAccessIssueReasons: [],
      meterAccessIssueReasonOther: "",
    };

    const shouldAskExtraQuestions =
      askMeterAccessQuestions || askBottledGasSpecificQuestions;

    const formPayload = shouldAskExtraQuestions
      ? values
      : formWithDefaultAccessValues;

    dispatch(submitPropertyDetailsForm(formPayload));

    if (businessType !== BUSINESS_TYPE_SOLE_TRADER) {
      trackBusinessContinueButton(
        {
          event: "input_licence_details",
          content_tertiary: "input property details",
        },
        "automated",
      );
    }
    trackBusinessContinueButton({
      event: "input_property_details",
      content_tertiary: "input property details",
      selection:
        values.situation === PROPERTY_SITUATION_MOVE
          ? "moving / just moved"
          : "switching providers",
    });
    const { shouldTriggerCallbackFlow } = dispatch(
      checkStoreStateForBusinessCallbackFlow(),
    );

    const path = shouldTriggerCallbackFlow
      ? appBusinessRoutes.callback
      : nextPageUrl;

    if (shouldTriggerCallbackFlow) {
      dispatch(setCallbackReferrerUrl(appBusinessRoutes.property));
    } else {
      dispatch(unsetCallbackReferrerUrl());
    }
    router.push(path);
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      validateOnMount
      enableReinitialize
    >
      {(formikProps) => (
        <PropertyPage
          pageData={pageData}
          props={{ ...componentProps, ...formikProps }}
        />
      )}
    </Formik>
  );
}
