import { createSelector } from "@reduxjs/toolkit";
import StepNavigationItem from "join-form/components/step-navigation-item";
import { useSelector } from "react-redux";
import { selectAppState, selectResidentialPlanConfig } from "reducers/selector";
import {
  appResidentialRoutes,
  PLAN_TYPE_EV_PLUS,
  RESIDENTIAL_PLAN_TERM_FIXED12,
} from "helpers/constants";
import { usePathWithoutQuery } from "hooks";

function getPlanTitle({ planType, codeName }) {
  if (planType === PLAN_TYPE_EV_PLUS) {
    return "Energy EV Plan";
  }

  if (codeName === RESIDENTIAL_PLAN_TERM_FIXED12) {
    return "Fixed Plan";
  }

  return "Flexi Plan";
}

const stateSelector = createSelector(
  [selectAppState, selectResidentialPlanConfig],
  (appState, residentialPlanConfig) => {
    const { planType } = appState;
    const { termData } = residentialPlanConfig;

    const { codeName } = termData.find(({ isSelected }) => isSelected) ?? {};

    const title = getPlanTitle({ planType, codeName });

    return {
      title,
    };
  },
);

const {
  address,
  fuel,
  occupancy,
  planSelect,
  success,
  callback,
  callbackSuccess,
  ...allPlanSelectRouteInfo
} = appResidentialRoutes;
const planSelectPageRoutes = Object.values(allPlanSelectRouteInfo);

function PlanSelectItem(props) {
  const { title } = useSelector(stateSelector);
  const pathWithoutQuery = usePathWithoutQuery();
  const isVisible = planSelectPageRoutes.includes(pathWithoutQuery);

  return isVisible ? (
    <StepNavigationItem title={title} path={planSelect} {...props} />
  ) : null;
}

export default PlanSelectItem;
