import { motion } from "framer-motion";

const transition = {
  delay: 0.3,
  type: "spring",
  bounce: 0.3,
};

function ScaleAnimation({ children, style, className, testid }) {
  return (
    <motion.div
      initial={{ scale: 1.05, opacity: 0 }}
      animate={{ scale: 1, opacity: 1 }}
      transition={{ delay: 0.3, type: "tween" }}
      style={style}
      className={className}
      data-testid={testid}
    >
      {children}
    </motion.div>
  );
}

function FadeInTopAnimation({ children }) {
  return (
    <motion.div
      initial={{ y: -20, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={transition}
    >
      {children}
    </motion.div>
  );
}

function FadeInBottomAnimation({ children }) {
  return (
    <motion.div
      initial={{ y: 20, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={transition}
    >
      {children}
    </motion.div>
  );
}

function FadeInHeightAnimation({ children }) {
  return (
    <motion.div
      initial={{ height: 0, opacity: 0 }}
      animate={{ height: "auto", opacity: 1 }}
      transition={{ delay: 2, type: "tween" }}
    >
      {children}
    </motion.div>
  );
}

export {
  ScaleAnimation,
  FadeInTopAnimation,
  FadeInBottomAnimation,
  FadeInHeightAnimation,
};
