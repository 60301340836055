import * as Yup from "yup";
import { InlineBlockEditor } from "components/BlockEditor";
import { Input } from "components/JoinFormControls";
import { getBlocks } from "helpers/blockEditorHelper";
import { BUSINESS_TYPE_OTHER } from "helpers/constants";

export const otherFormValidationSchema = Yup.object().shape({
  otherTradingAs: Yup.string().when("businessType", {
    is: (value) => value === BUSINESS_TYPE_OTHER,
    then: Yup.string()
      .max(255, "Company name cannot be more than 255 characters")
      .required("This field is required"),
  }),
});

export default function OtherForm({ props, content }) {
  const { values, touched, errors, handleChange } = props;
  const blocks = getBlocks(content);

  return (
    <>
      {blocks.length ? (
        <InlineBlockEditor
          data={blocks}
          className="prose-p:text-15/24 prose-p:md:text-18/28 prose-a:text-black-primary mb-5"
        />
      ) : null}
      <Input
        size="28"
        name="otherTradingAs"
        placeholder="Trading as"
        value={values.otherTradingAs}
        onChange={handleChange}
        errorMessage={errors.otherTradingAs}
        showError={errors.otherTradingAs && touched.otherTradingAs}
      />
    </>
  );
}
