function Embed({ data }) {
  const { service, embed, caption } = data;

  return (
    service === "youtube" && (
      <figure data-testid="youtubeEmbed">
        <div className="aspect-w-16 aspect-h-9">
          <iframe
            src={embed}
            title="YouTube video player"
            frameBorder="0"
            allowFullScreen
          ></iframe>
        </div>
        {caption && (
          <figcaption>
            <p data-testid="embedCaption">{caption}</p>
          </figcaption>
        )}
      </figure>
    )
  );
}

export { Embed };
