import {
  LPG_LOCATION_BACK,
  LPG_LOCATION_FRONT,
  LPG_LOCATION_LEFT_SIDE,
  LPG_LOCATION_RIGHT_SIDE,
  LPG_NO_OF_BOTTLES_SETUP_2,
  LPG_NO_OF_BOTTLES_SETUP_2_MORE,
  LPG_NO_OF_BOTTLES_SETUP_NONE,
  LPG_SUPPLIER_DONT_KNOW,
  LPG_SUPPLIER_ELGAS,
  LPG_SUPPLIER_ENERGY_ONLINE,
  LPG_SUPPLIER_GENESIS_ENERGY,
  LPG_SUPPLIER_KING_COUNTRY_ENERGY,
  LPG_SUPPLIER_NOVA,
  LPG_SUPPLIER_ONGAS,
  LPG_SUPPLIER_PULSE,
  LPG_SUPPLIER_ROCK_GAS,
  LPG_SUPPLIER_TRUST_POWER,
  PREVENT_ACCESS_REASON_DOGS,
  PREVENT_ACCESS_REASON_GATE,
  PREVENT_ACCESS_REASON_METER_INSIDE,
  PREVENT_ACCESS_REASON_OTHER,
  PREVENT_ACCESS_REASON_SECURITY_CODE,
  PREVENT_ACCESS_REASON_STEEP_DRIVEWAY,
  PREVENT_ACCESS_REASON_STEPS,
  PREVENT_ACCESS_REASON_TRUCKS,
  WELLBEING_AFFECTED_AGE,
  WELLBEING_AFFECTED_DISABILITY,
  WELLBEING_AFFECTED_HEALTH,
} from "helpers/constants";

export const getPropertyAccessListContent = (
  type = "",
  isStandardMeter = false,
  isBottledGasSelected = false,
) => {
  switch (type) {
    case "wellbeingAffectedReason":
      return [
        { text: "Age", value: WELLBEING_AFFECTED_AGE },
        { text: "Health", value: WELLBEING_AFFECTED_HEALTH },
        { text: "Disability", value: WELLBEING_AFFECTED_DISABILITY },
      ];

    case "gasBottlesCount":
      return [
        { text: "2 bottles", value: LPG_NO_OF_BOTTLES_SETUP_2 },
        { text: "More than 2 bottles", value: LPG_NO_OF_BOTTLES_SETUP_2_MORE },
        { text: "Not set up yet", value: LPG_NO_OF_BOTTLES_SETUP_NONE },
      ];

    case "gasBottlesSupplier":
      return [
        { text: "Elgas", value: LPG_SUPPLIER_ELGAS },
        { text: "Frank Energy", value: LPG_SUPPLIER_ENERGY_ONLINE },
        { text: "Genesis Energy", value: LPG_SUPPLIER_GENESIS_ENERGY },
        {
          text: "King Country Energy",
          value: LPG_SUPPLIER_KING_COUNTRY_ENERGY,
        },
        { text: "Nova Energy", value: LPG_SUPPLIER_NOVA },
        { text: "Ongas", value: LPG_SUPPLIER_ONGAS },
        { text: "Pulse Energy", value: LPG_SUPPLIER_PULSE },
        { text: "Rockgas", value: LPG_SUPPLIER_ROCK_GAS },
        { text: "Trustpower", value: LPG_SUPPLIER_TRUST_POWER },
        { text: "I don’t know", value: LPG_SUPPLIER_DONT_KNOW },
      ];

    case "gasBottlesLocation":
      return [
        { text: "Front of property", value: LPG_LOCATION_FRONT },
        { text: "Back of property", value: LPG_LOCATION_BACK },
        { text: "Right side of property", value: LPG_LOCATION_RIGHT_SIDE },
        { text: "Left side of property", value: LPG_LOCATION_LEFT_SIDE },
      ];

    case "meterAccessIssueReasons":
      return [
        { text: "Dogs", value: PREVENT_ACCESS_REASON_DOGS },
        isBottledGasSelected && {
          text: "Trucks",
          value: PREVENT_ACCESS_REASON_TRUCKS,
        },
        isBottledGasSelected && {
          text: "Security Code",
          value: PREVENT_ACCESS_REASON_SECURITY_CODE,
        },
        isStandardMeter && {
          text: "Meter inside",
          value: PREVENT_ACCESS_REASON_METER_INSIDE,
        },
        isBottledGasSelected && {
          text: "Steps",
          value: PREVENT_ACCESS_REASON_STEPS,
        },
        isBottledGasSelected && {
          text: "Steep Driveway",
          value: PREVENT_ACCESS_REASON_STEEP_DRIVEWAY,
        },
        { text: "Gate", value: PREVENT_ACCESS_REASON_GATE },
        { text: "Other", value: PREVENT_ACCESS_REASON_OTHER },
      ].filter(Boolean);

    default:
      return [];
  }
};
