import { motion } from "framer-motion";

const transition = {
  delay: 0.3,
  type: "spring",
  bounce: 0.3,
  duration: 0.3,
};

const bannerRotateAnimation = {
  hidden: { rotate: 10, opacity: 0 },
  visible: { rotate: 0, opacity: 1 },
};

const bannerFadeInBottomAnimation = {
  hidden: { y: 20, opacity: 0 },
  visible: { y: 0, opacity: 1 },
};

function FadeInOnScroll({ children }) {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      viewport={{ once: true, amount: 0.2 }}
      transition={transition}
    >
      {children}
    </motion.div>
  );
}

function FadeInBottomOnScroll({ children, isSecondary }) {
  return (
    <motion.div
      initial={{ y: isSecondary ? 60 : 20, opacity: 0 }}
      whileInView={{ y: 0, opacity: 1 }}
      viewport={{ once: true, amount: 0.2 }}
      transition={transition}
    >
      {children}
    </motion.div>
  );
}

function RotateOnScroll({ children, imageStyle }) {
  const isIcon = imageStyle === "icon";
  return (
    <motion.div
      initial="hidden"
      whileInView="visible"
      variants={isIcon ? bannerRotateAnimation : bannerFadeInBottomAnimation}
      viewport={{ once: true, amount: 0.2 }}
      transition={{ delay: 0.3, type: "tween", duration: 0.3 }}
    >
      {children}
    </motion.div>
  );
}

export { FadeInOnScroll, FadeInBottomOnScroll, RotateOnScroll };
