import classNames from "classnames";
import {
  getContentWithLineBreaks,
  getTextWithoutSpaces,
} from "helpers/htmlHelper";

function Quote({ data }) {
  const { alignment, caption, text } = data;

  if (!getTextWithoutSpaces(text) || !getTextWithoutSpaces(caption)) {
    return null;
  }

  return (
    <div className="my-10" data-testid="blockEditorQuote">
      <div className="h-1 bg-orange-primary rounded-25 mb-7" />
      <p
        className={classNames(
          "text-20/32 font-medium text-dark-primary mb-3 md:text-24/36",
          { "text-left": alignment === "left" },
          { "text-center": alignment === "center" },
        )}
      >
        {getContentWithLineBreaks(text)}
      </p>
      <p
        className={classNames(
          "text-right text-black-primary font-normal text-14/20 mt-3 mb-7",
          "md:text-16/24 md:italic",
        )}
      >
        <span className="mr-2">—</span>
        {getContentWithLineBreaks(caption)}
      </p>
      <div className="h-0.5 bg-orange-200 rounded-25" />
    </div>
  );
}

export { Quote };
