export const heightFadeVariants = {
  initial: { height: 0, opacity: 0 },
  animate: { height: "auto", opacity: 1 },
  exit: { height: 0, opacity: 0, transition: { type: "tween", delay: 0.1 } },
};

export const heightFadeTransition = { type: "tween" };

export const staticVariants = {
  initial: { height: "auto", opacity: 1 },
  animate: { height: "auto", opacity: 1 },
  exit: { height: "auto", opacity: 1 },
};
