import classNames from "classnames";
import { twMerge } from "tailwind-merge";

/**
 * Combines class names using `classnames` and merges them using `tailwind-merge`.
 *
 * This function takes multiple class name arguments, combines them into a single string
 * using the `classnames` library, and then merges any Tailwind CSS class name conflicts
 * using the `tailwind-merge` library.
 *
 * @param {...classNames.ArgumentArray} inputs - An array of class name arguments. Each argument can be a string, object, array, or other values supported by `classnames`.
 * @returns {string} - A single string of combined and merged class names.
 *
 * @example
 * const buttonClasses = cn('btn', 'btn-primary', { 'btn-disabled': isDisabled });
 * returns 'btn btn-primary btn-disabled' if `isDisabled` is true
 *
 * @example
 * const classes = cn('text-base', 'text-lg');
 * returns 'text-lg' because `tailwind-merge` resolves the conflict by keeping the last class
 */
export function cn(...inputs) {
  return twMerge(classNames(inputs));
}
