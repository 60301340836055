import classNames from "classnames";
import { Image } from "components/Image";
import { Link } from "components/Link";

function ArticleCardImage({ data, url, testid, horizontal = false }) {
  const orientationClassName = horizontal
    ? classNames("w-20 h-20 shrink-0", "md:w-53 md:min-h-42")
    : classNames("mb-3 md:mb-5", "aspect-w-16 aspect-h-9");

  return (
    <Link href={url}>
      <a
        data-testid={testid}
        className={classNames(
          "outline outline-4 outline-transparent focus-visible:outline-blue-500 outline-offset-2",
          "group relative overflow-hidden rounded-4 transition",
          orientationClassName,
        )}
      >
        <Image
          src={data.url}
          alt={data.label}
          layout="fill"
          objectFit="cover"
          className="transition ease-in-out group-hover:scale-105"
        />
      </a>
    </Link>
  );
}

export { ArticleCardImage };
