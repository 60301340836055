import React from "react";
import classNames from "classnames";
import { useFilterOverlay } from "hooks";
import { Filter } from "assets/icons";
import { Button } from "components/Button";
import { Checkbox } from "components/Checkbox";
import { Select } from "components/Select";
import { VARIANT } from "helpers/constants";
import { gaPush } from "helpers/gaHelper";
import { ArticleFilterButton } from "./ArticleFilterButton";
import { ArticleFilterResult } from "./ArticleFilterResult";
import { getCustomInitialProps } from "./getArticleFilterProps";
import { useArticleFilter } from "./state";

function ArticleFilter({
  module,
  customData,
  visibleArticlesCount = 12,
  pageSize = 12,
}) {
  const { filterDocumentsLabel } = module.fields;
  const { articleItems, tags, topics, allYears, allDates } = customData;

  const sidebarTitle = filterDocumentsLabel
    ? filterDocumentsLabel
    : "Filter documents";

  const {
    filteredArticles,
    allMonths,
    selectedYear,
    selectedMonth,
    selectedTagIds,
    selectedTopicIds,
    paginationText,
    showLoadMoreButton,
    setYear,
    setMonth,
    setTag,
    setTopic,
    reset,
    loadMoreArticles,
  } = useArticleFilter({
    articleItems,
    tags,
    topics,
    allYears,
    allDates,
    pageSize,
    visibleArticlesCount,
  });

  const { isOverlayVisible, setOverlayVisibility, filterButtonRef } =
    useFilterOverlay();

  if (articleItems.length === 0) {
    return null;
  }

  return (
    <div
      data-testid="articleFilter"
      className={classNames(
        "first:pt-21 md:first:pt-44 lg:first:pt-56",
        "mb-5 md:mb-10",
      )}
    >
      <div
        className={classNames(
          "px-4 mx-auto sm:px-15 md:px-17 lg:px-23 xl:px-46",
          "max-w-full w-120 sm:w-210 md:w-auto",
        )}
      >
        <div className="md:grid md:gap-x-7 md:grid-cols-24 lg:gap-x-8">
          <div className="relative mx-auto mb-10 z-2 md:hidden">
            <ArticleFilterButton
              onClick={() => setOverlayVisibility(!isOverlayVisible)}
              isActive={isOverlayVisible}
              ref={filterButtonRef}
            >
              {sidebarTitle}
            </ArticleFilterButton>
          </div>
          <div className="md:col-span-9 lg:col-span-8">
            <div
              data-testid="articleFilterOverlay"
              className={classNames("article-filter-sidebar", {
                "article-filter-sidebar-mobile--invisible": !isOverlayVisible,
                "article-filter-sidebar-mobile--visible": isOverlayVisible,
              })}
            >
              <div className="article-filter-sidebar-content">
                <div
                  className={classNames(
                    "hidden text-dark-primary",
                    "items-center justify-between mb-10",
                    "md:flex",
                  )}
                >
                  <h5 className="font-medium text-20/32">{sidebarTitle}</h5>
                  <Filter className="w-5 h-5 fill-current" />
                </div>
                <div
                  className={classNames(
                    "flex flex-col",
                    "text-black-primary text-15/24 md:text-18/28",
                    "space-y-7 md:space-y-10",
                  )}
                >
                  <Select
                    id="year"
                    label="Year released"
                    value={selectedYear}
                    onChange={(e) => {
                      gaPush({
                        content_tertiary: "news article listings",
                        event: "filter_click",
                        event_category: "news article listings - year released",
                        event_label: e.target.value,
                      });
                      setYear(e.target.value);
                    }}
                  >
                    {allYears.map((year) => (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    ))}
                  </Select>

                  <Select
                    id="month"
                    label="Month released"
                    value={selectedMonth}
                    onChange={(e) => {
                      if (e.target.value) {
                        gaPush({
                          content_tertiary: "news article listings",
                          event: "filter_click",
                          event_category: "news article listings - month",
                          event_label: `${selectedYear} - ${e.target.value}`,
                        });
                      }
                      setMonth(e.target.value);
                    }}
                    disabled={allMonths.length === 0}
                  >
                    {allMonths.map((month) => (
                      <option key={month} value={month}>
                        {month}
                      </option>
                    ))}
                  </Select>

                  {tags.length > 1 && (
                    <div>
                      <p className="mb-2">Article type</p>
                      <ul className="space-y-2">
                        {tags.map((tag) => (
                          <li key={tag.contentID}>
                            <Checkbox
                              label={tag.title}
                              value={tag.contentID}
                              checked={selectedTagIds.includes(
                                tag.contentID.toString(),
                              )}
                              onChange={(e) => setTag(e.target.value)}
                            />
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}

                  <div>
                    <p className="mb-2">Article topic</p>
                    <ul className="space-y-2">
                      {topics.map((topic) => (
                        <li key={topic.contentID}>
                          <Checkbox
                            label={topic.title}
                            value={topic.contentID}
                            checked={selectedTopicIds.includes(
                              topic.contentID.toString(),
                            )}
                            onChange={(e) => setTopic(e.target.value)}
                          />
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="article-filter-sidebar-footer">
                <Button
                  size="small"
                  shadowless
                  onClick={() => setOverlayVisibility(false)}
                >
                  Apply filters
                </Button>
                <Button
                  size="small"
                  shadowless
                  variant={VARIANT.darkTransparent}
                  onClick={reset}
                >
                  Clear all
                </Button>
              </div>
            </div>
          </div>
          <div className="md:col-start-11 md:col-span-14">
            {filteredArticles.length ? (
              <ArticleFilterResult
                paginationText={paginationText}
                filteredArticles={filteredArticles}
                showLoadMoreButton={showLoadMoreButton}
                loadMoreArticles={loadMoreArticles}
              />
            ) : (
              <div
                data-testid="noResultsSection"
                className="space-y-1 md:mt-8 lg:mt-10 text-black-primary"
              >
                <h4 className="font-medium text-18/28 md:text-20/32">
                  No matches found
                </h4>
                <p className="text-15/24 md:text-18/28">
                  Please try a different set of filters.
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

ArticleFilter.getCustomInitialProps = getCustomInitialProps;

export { ArticleFilter };
