import ExistingCustomerContactDetailsForm from "./ExistingCustomerContactDetailsForm";
import NewCustomerContactDetailsForm from "./NewCustomerContactDetailsForm";

export function CustomerDetailsForm({
  shouldShowNewCustomerForm,
  shouldShowExistingCustomerForm,
  ...props
}) {
  if (shouldShowNewCustomerForm) {
    return (
      <NewCustomerContactDetailsForm {...props} data-testid="newCustomerForm" />
    );
  }

  if (shouldShowExistingCustomerForm) {
    return <ExistingCustomerContactDetailsForm {...props} />;
  }

  return null;
}
