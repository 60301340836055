import { AnimatePresence, motion } from "framer-motion";
import {
  Dropdown,
  errorText,
  Input,
  RadioButton,
} from "join-form/components/form-controls";
import { EBILLING_DISCOUNT, PLAN_TYPE_BASIC } from "helpers/constants";
import { InlineBlockEditor } from "components/BlockEditor";
import {
  footerFade,
  footerFadeTransition,
  heightFadeTransition,
  heightFadeVariants,
  itemContainer,
  itemFade,
} from "./animations";
import styles from "./contact-details-page.module.scss";

function NewCustomerContactDetailsForm(props) {
  const { planType, values, touched, errors, handleChange, pageData } = props;

  const shouldShowHighlightedTexts = planType !== PLAN_TYPE_BASIC;

  return (
    <>
      <motion.div
        initial="initial"
        animate="animate"
        exit="exit"
        variants={itemContainer}
        className={styles.body}
      >
        <p className={styles.text_container} data-testid="newCustomerFormInfo">
          {pageData.fields.newCustomerInfoText}
        </p>
        <motion.div variants={itemFade}>
          <div className={styles.row}>
            <div className={`${styles.column} ${styles.title_field_container}`}>
              <Dropdown
                name="title"
                placeholder={pageData.fields.titleLabel}
                value={values.title}
                options={[
                  { value: "MRS", name: "Mrs" },
                  { value: "MISS", name: "Miss" },
                  { value: "MR", name: "Mr" },
                  { value: "DR", name: "Dr" },
                  { value: "MS", name: "Ms" },
                  { value: "REV", name: "Rev" },
                ]}
                onChange={handleChange}
                errorMessage={errors.title}
                showError={errors.title && touched.title}
              />
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.column}>
              <Input
                name="firstName"
                placeholder={pageData.fields.firstNameLabel}
                value={values.firstName}
                onChange={handleChange}
                errorMessage={errors.firstName}
                showError={errors.firstName && touched.firstName}
              />
            </div>
            <div className={styles.column}>
              <Input
                name="middleName"
                placeholder={pageData.fields.middleNameLabel}
                value={values.middleName}
                onChange={handleChange}
                errorMessage={errors.middleName}
                showError={errors.middleName && touched.middleName}
              />
            </div>
            <div className={styles.column}>
              <Input
                name="lastName"
                placeholder={pageData.fields.lastNameLabel}
                value={values.lastName}
                onChange={handleChange}
                errorMessage={errors.lastName}
                showError={errors.lastName && touched.lastName}
              />
            </div>
          </div>
          <p className={styles.text_container}>
            We'll use your email for ebilling.{" "}
            {shouldShowHighlightedTexts && (
              <span
                data-testid="eBillingDiscountCopy"
                className={styles.highlight_text}
              >
                Doing this saves you {EBILLING_DISCOUNT}% off your bill.
              </span>
            )}{" "}
            You can change this later by calling our team.
          </p>
          <div className={styles.row}>
            <div className={styles.column}>
              <Input
                name="email"
                placeholder={pageData.fields.emailLabel}
                value={values.email}
                onChange={handleChange}
                errorMessage={errors.email}
                showError={errors.email && touched.email}
              />
            </div>
            <div className={styles.column}>
              <Input
                name="phone"
                placeholder={pageData.fields.phoneLabel}
                value={values.phone}
                onChange={handleChange}
                errorMessage={errors.phone}
                showError={errors.phone && touched.phone}
              />
            </div>
          </div>

          <div className={styles.radio_container}>
            <p className={styles.radio_label}>
              Opt in to receive emails so you don't miss{" "}
              {shouldShowHighlightedTexts && "your Power Shouts and "}
              promotional offers.
            </p>
            <AnimatePresence>
              {shouldShowHighlightedTexts && values.isPromoByEmail === "false" && (
                <motion.div
                  initial="initial"
                  animate="animate"
                  exit="exit"
                  variants={heightFadeVariants}
                  transition={heightFadeTransition}
                  className={styles.promoEmail_notSelected_text}
                  data-testid="promoEmailNotSelectedMessage"
                >
                  <span>
                    {pageData.fields.newCustomerPromoByEmailOptOutMessage}
                  </span>
                </motion.div>
              )}
            </AnimatePresence>
            <div className={styles.row}>
              <div className={`${styles.column} ${styles.radio_column}`}>
                <RadioButton
                  name="isPromoByEmail"
                  checked={values.isPromoByEmail === "true"}
                  onChange={handleChange}
                  value={true}
                  text="Yes please"
                  size="medium"
                />
              </div>
              <div className={`${styles.column} ${styles.radio_column}`}>
                <RadioButton
                  name="isPromoByEmail"
                  checked={values.isPromoByEmail === "false"}
                  onChange={handleChange}
                  value={false}
                  text="No thanks"
                  size="medium"
                />
              </div>
            </div>
            {errors.isPromoByEmail && touched.isPromoByEmail && (
              <span
                data-testid="isPromoByEmailErrorMessage"
                className={errorText}
              >
                {errors.isPromoByEmail}
              </span>
            )}
          </div>
        </motion.div>
      </motion.div>
      <motion.div
        initial="initial"
        animate="animate"
        exit="exit"
        variants={footerFade}
        transition={footerFadeTransition}
        className={styles.small_print}
      >
        <InlineBlockEditor
          jsonString={pageData.fields.newCustomerFinePrint}
          enableProse={false}
        />
      </motion.div>
    </>
  );
}

export default NewCustomerContactDetailsForm;
