import classNames from "classnames";
import { ExternalLink } from "assets/icons";
import { Link } from "components/Link";

function AlertCta({ className, url }) {
  const validUrl = url?.href && url?.text ? true : false;

  if (!validUrl) {
    return null;
  }

  return (
    <div className="flex ml-auto" data-testid="alertCtaSection">
      <Link href={url.href} prefetch={false}>
        <a
          target={url.target}
          data-testid="alertButton"
          className={classNames(
            "flex items-center px-5 py-3 bg-white rounded-3xl",
            "text-black-primary text-15/24 font-medium",
            "outline outline-2 outline-transparent",
            "focus-visible:outline-blue-500 outline-offset-1",
            "hover:shadow-12/60/40 active:shadow-12/80/60 shadow-6/30/20 transition duration-500",
            "md:px-6 md:py-3 md:text-18/28 md:rounded-6.5",
            className,
          )}
          data-ga={JSON.stringify({
            event: "cta_click",
            event_category: "cta",
            event_label: [url.text],
          })}
        >
          <span>{url.text}</span>
          {url.target === "_blank" && (
            <ExternalLink
              data-testid="alertButtonIcon"
              className="w-5 h-5 ml-3 fill-current"
            />
          )}
        </a>
      </Link>
    </div>
  );
}

export { AlertCta };
