import { compact, map, toString } from "lodash-es";
import {
  ADDRESS_TYPE_ADDRESS_FINDER,
  ADDRESS_TYPE_MANUAL,
  ADDRESS_TYPE_REGISTRY,
  appBusinessRoutes,
} from "helpers/constants";

function convertObjectPropertiesToString(objectToConvert) {
  const keyValuePair = Object.entries(objectToConvert);
  const convertedKeyValuePair = map(keyValuePair, ([key, value]) => {
    const stringValue = toString(value);
    return [key, stringValue];
  });
  const result = Object.fromEntries(convertedKeyValuePair);
  return result;
}

export function getBusinessAddressPageUrl(addressType) {
  switch (addressType) {
    case ADDRESS_TYPE_ADDRESS_FINDER:
      return appBusinessRoutes.address;
    case ADDRESS_TYPE_REGISTRY:
      return appBusinessRoutes.addressIcp;
    case ADDRESS_TYPE_MANUAL:
      return appBusinessRoutes.addressManual;
    default:
      return appBusinessRoutes.address;
  }
}

// https://wiki.waze.com/wiki/Abbreviations_and_acronyms_(NZ)
// appendix here: https://www.aucklandcouncil.govt.nz/building-and-consents/types-resource-consents/subdivision-of-property/Documents/road-naming-guidelines.pdf
const ADDRESS_NORMALIZE_REGEX =
  /\b(Alley|Aly|Arcade|Arc|Avenue|Ave|Boulevard|Blvd|Circle|Cir|Close|Cl|Court|Crt|Crescent|Cres|Drive|Dr|Esplanade|Esp|Glade|Gld|Green|Grn|Grove|Grv|Highway|Hwy|Lane|Lane|Loop|Loop|Mall|Mall|Mews|Mews|Parade|Pde|Place|Pl|Promenade|Prom|Quay|Qy|Rise|Rise|Road|Rd|Square|Sq|State Highway|SH|Steps|Stps|Street|St|Terrace|Tce|Track|Trk|Walk|Walk|Way|Way|Wharf|Whrf)\b/gi;

export function createBillingAddress({
  addressFromFinder,
  registryAddress,
  isFromAddressIcpPage = false,
}) {
  const result =
    addressFromFinder === null || isFromAddressIcpPage
      ? createBillingAddressFromRegistry(registryAddress)
      : createBillingAddressFromAddressFinder(addressFromFinder);
  return result;
}

function createBillingAddressFromRegistry(address = {}) {
  const {
    unit,
    streetNumber,
    streetName,
    propertyName,
    suburb,
    town,
    postCode,
    // state,
    fullAddress,
  } = address;

  const addressLine1 = compact([streetNumber, streetName]).join(" ");
  const addressLine2 = compact([propertyName, suburb]).join(", ");
  const addressLine3 = compact([town, postCode]).join(" ");

  const result = {
    addressLine1,
    addressLine2,
    addressLine3,
    addressLine4: "",
    addressLine5: "",
    dpid: "",
    unit,
    unitType: "",
    floor: "",
    streetNumber,
    streetNumberSuffix: "",
    streetName,
    streetType: "",
    ruralDeliveryNumber: "",
    lobby: "",
    suburb,
    town,
    mailTown: "",
    postCode,
    boxNumber: "",
    boxType: "",
    parcelId: "",
    region: "",
    freeformAddress: fullAddress,
  };

  const resultWithStringValues = convertObjectPropertiesToString(result);

  return resultWithStringValues;
}

function createBillingAddressFromAddressFinder(address = {}) {
  const {
    postal_line_1: addressLine1,
    postal_line_2: addressLine2,
    postal_line_3: addressLine3,
    postal_line_4: addressLine4,
    postal_line_5: addressLine5,
    dpid = "",
    unit_identifier: unit = "",
    unit_type: unitType = "",
    floor = "",
    number = "",
    street_name: streetName,
    street_type: streetType,
    rd_number: ruralDeliveryNumber,
    lobby_name: lobby,
    suburb,
    city: town,
    mailtown: mailTown,
    postcode: postCode,
    box_type: boxType,
    primary_parcel_id: parcelId,
    region,
  } = address;

  const freeformAddress = getFullAddressFromFinder(address);
  const streetNumber = streetType ? number : "";
  const boxNumber = boxType ? number : "";

  const result = {
    addressLine1,
    addressLine2,
    addressLine3,
    addressLine4,
    addressLine5,
    dpid,
    unit,
    unitType,
    floor,
    streetNumber,
    streetNumberSuffix: "", // similar key is not available with AddressFinder
    streetName,
    streetType,
    ruralDeliveryNumber,
    lobby,
    suburb,
    town,
    mailTown,
    postCode,
    boxNumber,
    boxType,
    parcelId,
    region,
    freeformAddress,
  };

  const resultWithStringValues = convertObjectPropertiesToString(result);

  return resultWithStringValues;
}

export function createSiteAddress({
  addressFromFinder,
  registryAddress,
  isFromAddressIcpPage = false,
}) {
  const address =
    addressFromFinder === null || isFromAddressIcpPage
      ? createSiteAddressFromRegistry(registryAddress)
      : createSiteAddressFromAddressFinder(addressFromFinder);
  return address;
}

export function createSiteAddressFromAddressFinder(address = {}) {
  const {
    dpid = "",
    unit_identifier: unit,
    unit_type: unitType,
    number: streetNumber,
    alpha: streetNumberSuffix,
    street_name: streetName,
    street_type: streetType,
    suburb,
    city: town,
    postcode: postCode,
    region,
  } = address;

  const freeformAddress = getFullAddressFromFinder(address);

  const result = {
    dpid,
    unit,
    unitType,
    streetNumber,
    streetNumberSuffix,
    streetName,
    streetType,
    suburb,
    town,
    postCode,
    region,
    freeformAddress,
  };

  const resultWithStringValues = convertObjectPropertiesToString(result);

  return resultWithStringValues;
}

export function createSiteAddressFromRegistry(address) {
  const {
    unit,
    unitType,
    streetNumber,
    streetNumberSuffix,
    streetName,
    streetType, // isn't available on registry address but value should be "avenue, corner etc"
    suburb,
    town,
    postCode,
    fullAddress,
  } = address || {};

  const result = {
    dpid: "",
    unit,
    unitType,
    streetNumber,
    streetNumberSuffix,
    streetName,
    streetType,
    suburb,
    town,
    postCode,
    region: "",
    freeformAddress: fullAddress,
  };

  const resultWithStringValues = convertObjectPropertiesToString(result);

  return resultWithStringValues;
}

export function getFullAddressByType({
  addressType,
  addressDetails,
  registryAddressDetails,
  manualAddressDetails,
} = {}) {
  switch (addressType) {
    case ADDRESS_TYPE_ADDRESS_FINDER:
      return getFullAddressFromFinder(addressDetails);
    case ADDRESS_TYPE_REGISTRY:
      return getFullRegistryAddress(registryAddressDetails);
    case ADDRESS_TYPE_MANUAL:
      return getFullManualAddress(manualAddressDetails);
    default:
      return "";
  }
}

export function getFullRegistryAddress(registryAddress) {
  const { streetNumber, unit, streetName, propertyName, suburb, town } =
    registryAddress;

  const streetNumberWithUnit = compact([unit, streetNumber]).join("/");
  const fullAddress = compact([
    streetNumberWithUnit,
    streetName,
    propertyName,
    suburb,
    town,
  ]).join(", ");

  return fullAddress;
}

export function getFullAddressFromFinder(address) {
  const {
    postal_line_1,
    postal_line_2,
    postal_line_3,
    postal_line_4,
    postal_line_5,
    a,
  } = address || {};
  const fullAddress = compact([
    postal_line_1,
    postal_line_2,
    postal_line_3,
    postal_line_4,
    postal_line_5,
  ]).join(", ");

  // returns full postal address or address finder metadata field a
  return fullAddress || a || "";
}

export function getFullManualAddress(manualAddress) {
  const { addressLine1, addressLine2, suburb, town, postcode } =
    manualAddress || {};
  const result = compact([
    addressLine1,
    addressLine2,
    suburb,
    town,
    postcode,
  ]).join(", ");
  return result;
}

export function getShortAddressByType({
  addressType,
  addressDetails,
  registryAddressDetails,
  manualAddressDetails,
} = {}) {
  switch (addressType) {
    case ADDRESS_TYPE_ADDRESS_FINDER:
      return getShortAddressFromFinder(addressDetails);
    case ADDRESS_TYPE_REGISTRY:
      return getShortRegistryAddress(registryAddressDetails);
    case ADDRESS_TYPE_MANUAL:
      return getShortManualAddress(manualAddressDetails);
    default:
      return "";
  }
}

export function getShortAddressFromFinder(address = {}) {
  const { postal_line_1, postal_line_2, address_line_1 } = address || {};
  const result = compact([postal_line_1, postal_line_2]).join(", ");
  return result || address_line_1 || "";
}

export function verifyAddressInNorthIsland(address = {}) {
  const { postcode } = address;
  return isPostcodeForNorthIslandAddress(postcode);
}

export function getShortRegistryAddress(registryAddress) {
  const { streetNumber, streetName, propertyName, suburb } =
    registryAddress || {};
  const addressLine1 = compact([streetNumber, streetName]).join(" ");
  const addressLine2 = compact([propertyName, suburb]).join(", ");
  const result = compact([addressLine1, addressLine2]).join(", ");
  return result;
}

export function getShortManualAddress(manualAddress) {
  const { addressLine1, addressLine2 } = manualAddress || {};
  const result = compact([addressLine1, addressLine2]).join(", ");
  return result;
}

export function isAddressInNorthIsland(address = {}) {
  const { postcode } = address;
  return isPostcodeForNorthIslandAddress(postcode);
}

export function isPostcodeForNorthIslandAddress(postcode = "") {
  const parsedPostcode = parseInt(postcode, 10);
  const isNorthIslandAddress = parsedPostcode < 7000;
  return isNorthIslandAddress;
}

export function compareRegistryAddresses(
  elecRegistryAddress,
  gasRegistryAddress,
) {
  const foundMatchInPassOne = addressComparisonPassOne(
    elecRegistryAddress,
    gasRegistryAddress,
  );
  if (foundMatchInPassOne) {
    return gasRegistryAddress;
  }

  const foundMatchInPassTwo = addressComparisonPassTwo(
    elecRegistryAddress,
    gasRegistryAddress,
  );
  if (foundMatchInPassTwo) {
    return gasRegistryAddress;
  }

  const foundMatchInPassThree = addressComparisonPassThree(
    elecRegistryAddress,
    gasRegistryAddress,
  );
  if (foundMatchInPassThree) {
    return gasRegistryAddress;
  }

  const foundMatchInPassFour = addressComparisonPassFour(
    elecRegistryAddress,
    gasRegistryAddress,
  );
  if (foundMatchInPassFour) {
    return gasRegistryAddress;
  }
}

function comparePostCodes(elecPostCode, gasPostCode) {
  const elecPostCodeWithDefaultValue = elecPostCode || 0;
  const gasPostCodeWithDefaultValue = gasPostCode || 0;

  const parsedElecPostCode = parseInt(elecPostCodeWithDefaultValue);
  const parsedGasPostCode = parseInt(gasPostCodeWithDefaultValue);

  const isMatch = parsedElecPostCode === parsedGasPostCode;
  return isMatch;
}

function addressComparisonPassOne(elecRegistryAddress, gasRegistryAddress) {
  const {
    unit: elecUnit,
    streetNumber: elecStreetNumber,
    streetName: elecStreetName,
    town: elecTown,
    state: elecState,
  } = elecRegistryAddress;

  const {
    unit: gasUnit,
    streetNumber: gasStreetNumber,
    streetName: gasStreetName,
    town: gasTown,
    state: gasState,
  } = gasRegistryAddress;

  const doesUnitNumberMatch = elecUnit === gasUnit;
  const doesStreetNumberMatch = elecStreetNumber === gasStreetNumber;
  const doesStreetNameMatch = elecStreetName === gasStreetName;
  const doesTownMatch = elecTown === gasTown;
  const doesStateMatch = elecState === gasState;

  const isMatch =
    doesUnitNumberMatch &&
    doesStreetNumberMatch &&
    doesStreetNameMatch &&
    doesTownMatch &&
    doesStateMatch;

  return isMatch;
}

function addressComparisonPassTwo(elecRegistryAddress, gasRegistryAddress) {
  const {
    unit: elecUnit,
    streetNumber: elecStreetNumber,
    streetName: elecStreetName,
    town: elecTown,
    postCode: elecPostCode,
  } = elecRegistryAddress;

  const {
    unit: gasUnit,
    streetNumber: gasStreetNumber,
    streetName: gasStreetName,
    town: gasTown,
    postCode: gasPostCode,
  } = gasRegistryAddress;

  const doesUnitNumberMatch = elecUnit === gasUnit;
  const doesStreetNumberMatch = elecStreetNumber === gasStreetNumber;
  const doesStreetNameMatch = elecStreetName === gasStreetName;
  const doesTownMatch = elecTown === gasTown;
  const doesPostCodeMatch = comparePostCodes(elecPostCode, gasPostCode);

  const isMatch =
    doesUnitNumberMatch &&
    doesStreetNumberMatch &&
    doesStreetNameMatch &&
    doesTownMatch &&
    doesPostCodeMatch;

  return isMatch;
}

function addressComparisonPassThree(elecRegistryAddress, gasRegistryAddress) {
  const {
    unit: elecUnit,
    streetNumber: elecStreetNumber,
    streetName: elecStreetName,
    postCode: elecPostCode,
  } = elecRegistryAddress;

  const {
    unit: gasUnit,
    streetNumber: gasStreetNumber,
    streetName: gasStreetName,
    postCode: gasPostCode,
  } = gasRegistryAddress;

  const doesUnitNumberMatch = elecUnit === gasUnit;
  const doesStreetNumberMatch = elecStreetNumber === gasStreetNumber;
  const doesStreetNameMatch = elecStreetName === gasStreetName;
  const doesPostCodeMatch = comparePostCodes(elecPostCode, gasPostCode);

  const isMatch =
    doesUnitNumberMatch &&
    doesStreetNumberMatch &&
    doesStreetNameMatch &&
    doesPostCodeMatch;

  return isMatch;
}

function addressComparisonPassFour(elecRegistryAddress, gasRegistryAddress) {
  const {
    unit: elecUnit,
    streetNumber: elecStreetNumber,
    streetName: elecStreetName,
    postCode: elecPostCode,
  } = elecRegistryAddress;

  const {
    unit: gasUnit,
    streetNumber: gasStreetNumber,
    streetName: gasStreetName,
    postCode: gasPostCode,
  } = gasRegistryAddress;

  const doesUnitNumberMatch = elecUnit === gasUnit;

  const doesStreetNumberMatch = elecStreetNumber === gasStreetNumber;

  const doestStreetNameMatch = doesStreetNumberMatch
    ? compareStreetNamesWithAbbreviation(elecStreetName, gasStreetName)
    : false;

  const doesPostCodeMatch = comparePostCodes(elecPostCode, gasPostCode);

  const isMatch =
    doesUnitNumberMatch && doestStreetNameMatch && doesPostCodeMatch;

  return isMatch;
}

function compareStreetNamesWithAbbreviation(elecStreetName, gasStreetName) {
  const normalizedElecStreetName =
    normalizeAddressAbbreviations(elecStreetName);
  const normalizedGasStreetName = normalizeAddressAbbreviations(gasStreetName);

  const doesNormalizedStreetNamesMatch =
    normalizedElecStreetName === normalizedGasStreetName;
  return doesNormalizedStreetNamesMatch;
}

function normalizeAddressAbbreviations(addressLine) {
  const normalizedLine = addressLine.replace(ADDRESS_NORMALIZE_REGEX, "");
  return normalizedLine;
}
