import { isUndefined, lowerCase, omitBy } from "lodash-es";
import { isEmpty } from "lodash-es";
import TagManager from "react-gtm-module";
import { formatDate } from "helpers/dateHelper";
import { isInternalLink } from "helpers/urlHelper";

const handleCtaLinks = (data, href) => {
  // handle "/join" links
  if (href.toLowerCase().includes("/join")) {
    const joinUrl = new URL(href);
    if (joinUrl.pathname.startsWith("/join")) {
      data.event_category = "cta - join";
    }
  }
  // handle "demo.genesisenergy" links
  if (href.includes("demo.genesisenergy.co.nz")) {
    data.event_category = "cta - demo";
  }
  // handle eco tracker links
  if (
    href.includes("myaccount.genesisenergy.co.nz") &&
    (href.includes("eco-tracker") ||
      href.includes("powershout") ||
      href.includes("usage"))
  ) {
    data.event_category = "cta - web energy iq";
  }
  // handle energy IQ iphone app links
  if (
    (href.includes("itunes.apple.com") &&
      href.includes("genesis-energy-mobile-app")) ||
    (href.includes("play.google.com") &&
      href.includes("id=nz.co.genesisenergy.gema"))
  ) {
    data.event_category = "cta - download energy iq";
  }
};

export const handleLinks = (data, href) => {
  if (typeof href !== "string") return data;

  // handle cta links
  if (data.event_category === "cta") {
    handleCtaLinks(data, href);
  } else {
    // handle internal links
    if (!data.event_category) {
      if (isInternalLink(href)) {
        data.event_category = "linked text";
      } else {
        data.event_category = "outbound link";
      }
    }

    // handle tel: links
    if (href.startsWith("tel:")) {
      data.event = "contact";
      data.event_category = "phone";
    }
    // handle mailto: links
    if (href.startsWith("mailto:")) {
      data.event = "contact";
      data.event_category = "email";
    }
  }

  return data;
};

export const gaPageView = (slug = []) => {
  const pageNames = slug.map(lowerCase);
  const [content_global, content_primary, content_secondary, content_tertiary] =
    pageNames;

  if (!content_global) return;

  if (
    content_secondary === "order bottled gas" &&
    content_tertiary === "thanks"
  ) {
    gaFormSubmissionSuccess({
      event_category: "bottled gas refill",
    });
  }

  const data = omitBy(
    { content_global, content_primary, content_secondary },
    isUndefined,
  );

  window?.dataLayer?.push(data);
};

export const gaPageClick = ({ target }) => {
  let gaEvent;
  switch (true) {
    // a[data-ga] > span > span
    case target.parentElement.parentElement?.nodeName === "A" &&
      !isEmpty(target.parentElement.parentElement.dataset?.ga):
      gaEvent = JSON.parse(target.parentElement.parentElement.dataset?.ga);
      break;
    // a[data-ga] > span, button[data-ga] > span
    case (target.parentElement.nodeName === "A" ||
      target.parentElement.nodeName === "BUTTON") &&
      !isEmpty(target.parentElement.dataset?.ga):
      gaEvent = JSON.parse(target.parentElement.dataset?.ga);
      if (target.parentElement.href) {
        gaEvent = handleLinks(gaEvent, target.parentElement.href);
      }
      break;
    // a[data-ga]
    case target.nodeName === "A" && !isEmpty(target.dataset?.ga):
      gaEvent = JSON.parse(target.dataset?.ga);
      gaEvent = handleLinks(gaEvent, target.href);
      break;
    // a
    case target.nodeName === "A":
      gaEvent = {
        event_label: [target.innerText],
      };
      gaEvent = handleLinks(gaEvent, target.href);

      break;
    // button
    default:
      return;
  }

  // gaEvent.content_tertiary
  if (!gaEvent.content_tertiary) {
    const selector = ":scope > h2, :scope > h3, :scope > h4";
    const heading =
      target.parentElement.querySelector(selector)?.innerText ||
      target.parentElement.parentElement.querySelector(selector)?.innerText;

    gaEvent.content_tertiary = heading || document.title.split(" | ")[0];
  }

  // gaEvent.event
  if (!gaEvent.event) {
    gaEvent.event = "navigation_click";
  }

  // data.event_label
  gaEvent.event_label = gaEvent.event_label.join(" - ");

  gaPush(gaEvent);
};

export const gaPush = (gaEvent) => {
  if (!gaEvent) return;

  gaEvent.event_timestamp = formatDate(
    new Date().toISOString(),
    "yyyy-MM-dd hh:mm:ss",
  );

  window?.dataLayer?.push(gaEvent);
};

export const gaFormSubmission = (gaEvent) => {
  gaPush({
    event: "form_submission",
    ...gaEvent,
  });
};

export const gaFormError = (gaEvent) => {
  gaPush({
    event: "track_error",
    ...gaEvent,
  });
};

const gaFormSubmissionSuccess = (gaEvent) => {
  gaPush({
    event: "form_submission",
    event_label: "success",
    content_tertiary: "great you're all done",
    form_submissions: 1,
    ...gaEvent,
  });
};

export const gaLiveChat = (gaEvent) => {
  gaPush({
    event: "live_chat",
    event_category: "live chat",
    content_tertiary: "live chat widget",
    ...gaEvent,
  });
};

export const gaJoinFormContinueButton = (data) => {
  gaPush({
    ...data,
    event: "cta_click",
    event_label: "continue",
  });
};

export const gaJoinFormResidentialContinueButton = (
  data,
  eventLabel = "continue",
) => {
  gaPush({
    ...data,
    event_category: "residential join",
    event_label: eventLabel,
  });
};

export const gaJoinFormResidentialAddressPageLoad = () => {
  gaPush({
    event: "begin_checkout",
    event_category: "residential join",
    content_tertiary: "input address",
    ecommerce: {
      items: [
        {
          item_name: "residential join",
          item_id: "residential join",
          price: undefined,
          item_brand: "genesis",
          item_category: undefined, // fuel type
          item_category2: undefined, // plan type
          item_category3: undefined, // energy user type
          item_variant: undefined, // term type
          quantity: 1,
        },
      ],
    },
  });
};

export const gaInit = () => {
  TagManager.initialize({
    gtmId: process.env.NEXT_PUBLIC_GTM_ID,
  });
};
