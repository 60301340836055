import { useState } from "react";
import classNames from "classnames";
import { useScrollDirection } from "hooks";
import { useRouter } from "next/router";
import ReactHtmlParser from "react-html-parser";
import { useEffectOnce } from "react-use";

function SectionHeading({ heading, id, testid, onClick, activeSection }) {
  const { level, text } = heading;
  const headingText = ReactHtmlParser(text);
  const hasHeading = level === 2 || level === 3;
  const variantStyles = {
    text:
      level === 2
        ? "md:text-16/24 text-14/20"
        : "md:text-14/20 ml-4 text-13/20",
  };

  return (
    hasHeading && (
      <a
        className={classNames(
          "cursor-pointer rounded-1 hover:bg-black-50 text-black-primary px-2 py-1 truncate",
          {
            "bg-black-50": activeSection,
          },
        )}
        data-testid={testid}
        href={`#${id}`}
        onClick={onClick}
      >
        <span className={variantStyles.text}>{headingText}</span>
      </a>
    )
  );
}

function JumpToSection({ data, jumptoSectionTitle, testid }) {
  const router = useRouter();
  const { scrollDir } = useScrollDirection();
  const [activeSectionId, setActiveSectionId] = useState("");

  useEffectOnce(() => {
    const sectionIdFromUrl = router.asPath.split("#")[1] || "";
    setActiveSectionId(sectionIdFromUrl);
  });

  return (
    <div
      className={classNames(
        "flex flex-col gap-2 font-medium md:sticky md:top-10",
        {
          "md:top-50 transition-all delay-75 duration-300 ease-in-out translate-y":
            scrollDir === "scrollUp",
        },
      )}
      data-testid={testid}
    >
      {jumptoSectionTitle && (
        <div
          className="px-2 py-1 text-warm-orange-primary md:text-16/24 text-14/20"
          data-testid="jumpToSectionTitle"
        >
          {jumptoSectionTitle}
        </div>
      )}
      {data.map(
        (item) =>
          item.type === "header" && (
            <SectionHeading
              key={item.id}
              heading={item.data}
              id={item.id}
              testid="jumpToSectionLink"
              scrollDir={scrollDir}
              onClick={() => setActiveSectionId(item.id)}
              activeSection={item.id === activeSectionId}
            />
          ),
      )}
    </div>
  );
}

export { JumpToSection };
