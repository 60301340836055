import { BusinessAddressManualTemplate } from "./BusinessAddressManualTemplate";
import { BusinessAddressTemplate } from "./BusinessAddressTemplate";
import { BusinessCallbackTemplate } from "./BusinessCallbackTemplate";
import { BusinessContactTemplate } from "./BusinessContactTemplate";
import { BusinessCreditCheckTemplate } from "./BusinessCreditCheckTemplate";
import { BusinessIcpTemplate } from "./BusinessIcpTemplate";
import { BusinessPaymentTemplate } from "./BusinessPaymentTemplate";
import { BusinessPropertyTemplate } from "./BusinessPropertyTemplate";
import { BusinessSuccessTemplate } from "./BusinessSuccessTemplate";
import { BusinessTermSelectTemplate } from "./BusinessTermSelectTemplate";
import { DefaultTemplate } from "./DefaultTemplate";
import { ResidentialAddressTemplate } from "./ResidentialAddressTemplate";
import { ResidentialCallbackTemplate } from "./ResidentialCallbackTemplate";
import { ResidentialCarRegistrationTemplate } from "./ResidentialCarRegistrationTemplate";
import { ResidentialContactDetailsTemplate } from "./ResidentialContactDetailsTemplate";
import { ResidentialCreditCheckTemplate } from "./ResidentialCreditCheckTemplate";
import { ResidentialPaymentTemplate } from "./ResidentialPaymentTemplate";
import { ResidentialPlanSelectTemplate } from "./ResidentialPlanSelectTemplate";
import { ResidentialPropertyTemplate } from "./ResidentialPropertyTemplate";
import { ResidentialSuccessTemplate } from "./ResidentialSuccessTemplate";
import { ResidentialTermSelectTemplate } from "./ResidentialTermSelectTemplate";

const allTemplates = {
  GEJoinPageTemplate: DefaultTemplate,
  GEJoinResidentialAddressTemplate: ResidentialAddressTemplate,
  GEJoinResidentialPlanSelectTemplate: ResidentialPlanSelectTemplate,
  GEJoinResidentialTermSelectTemplate: ResidentialTermSelectTemplate,
  GEJoinResidentialCarRegistrationTemplate: ResidentialCarRegistrationTemplate,
  GEJoinResidentialContactDetailsTemplate: ResidentialContactDetailsTemplate,
  GEJoinResidentialCreditCheckTemplate: ResidentialCreditCheckTemplate,
  GEJoinResidentialPropertyTemplate: ResidentialPropertyTemplate,
  GEJoinResidentialPaymentTemplate: ResidentialPaymentTemplate,
  GEJoinResidentialSuccessTemplate: ResidentialSuccessTemplate,
  GEJoinResidentialCallbackTemplate: ResidentialCallbackTemplate,
  GEJoinBusinessAddressTemplate: BusinessAddressTemplate,
  GEJOINBusinessIcpTemplate: BusinessIcpTemplate,
  GEJoinBusinessContactTemplate: BusinessContactTemplate,
  GEJoinBusinessTermSelectTemplate: BusinessTermSelectTemplate,
  GEJoinBusinessPropertyTemplate: BusinessPropertyTemplate,
  GEJoinBusinessCreditCheckTemplate: BusinessCreditCheckTemplate,
  GEJoinBusinessPaymentTemplate: BusinessPaymentTemplate,
  GEJoinBusinessSuccessTemplate: BusinessSuccessTemplate,
  GEJoinBusinessCallbackTemplate: BusinessCallbackTemplate,
  GEJoinBusinessAddressManualTemplate: BusinessAddressManualTemplate,
};

function getJoinPageTemplate(templateName = "") {
  const searchKey = Object.keys(allTemplates).find(
    (name) => name.toLowerCase() === templateName.toLowerCase(),
  );

  const template = allTemplates[searchKey] || DefaultTemplate;

  return template;
}

export { getJoinPageTemplate };
