import React from "react";
import Headroom from "headroom.js";

function safeDestroyHeadroom(headroomInstance = {}) {
  if (!headroomInstance.scrollTracker) {
    headroomInstance.scrollTracker = {
      destroy: () => {
        // This is intentional
      },
    };
  }
  headroomInstance.destroy?.();
}

const HeadroomContext = React.createContext(null);

function HeadroomProvider({ children }) {
  const headerRef = React.useRef();
  const headroomInstance = React.useRef();

  React.useEffect(() => {
    return () => {
      safeDestroyHeadroom(headroomInstance.current);
    };
  }, []);

  function setupHeadroom({ testRef, ...config }) {
    headerRef.current = headerRef.current ?? testRef?.current;
    if (headerRef.current) {
      headroomInstance.current = new Headroom(headerRef.current, config);
      headroomInstance.current.init();
    }
  }

  const value = { headerRef, headroomInstance, setupHeadroom };

  return (
    <HeadroomContext.Provider value={value}>
      {children}
    </HeadroomContext.Provider>
  );
}

function useHeadroom(config) {
  const context = React.useContext(HeadroomContext);
  if (!context) {
    throw new Error(
      "useHeadroom must be used within a <HeadroomProvider /> component",
    );
  }

  React.useEffect(() => {
    context.setupHeadroom(config);
  }, [context, config]);

  return context;
}

function useHeadroomInstance() {
  const context = React.useContext(HeadroomContext);
  if (!context) {
    throw new Error(
      "useHeadroom must be used within a <HeadroomProvider /> component",
    );
  }

  function getHeadroomInstance() {
    return context.headroomInstance?.current;
  }

  function pin(shouldPin = true) {
    const instance = getHeadroomInstance();
    return shouldPin ? instance?.pin() : instance?.unpin();
  }

  function unpin(shouldUnPin = true) {
    const instance = getHeadroomInstance();
    return shouldUnPin ? instance?.unpin() : instance?.pin();
  }

  function freeze(shouldFreeze = true) {
    const instance = getHeadroomInstance();
    return shouldFreeze ? instance?.freeze() : instance?.unfreeze();
  }

  return {
    pin,
    unpin,
    freeze,
  };
}

export { HeadroomProvider, useHeadroom, useHeadroomInstance };
