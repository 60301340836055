import Head from "next/head";
import ReactHtmlParser from "react-html-parser";

const preConnectUrls = [
  process.env.NEXT_PUBLIC_MULE_API_HOST_NAME,
  "https://media.genesisenergy.co.nz",
  "https://api.genesisenergy.co.nz",
  "https://staticcdn.co.nz",
  "https://cdn.evgnet.com",
];

function PageHead({
  title,
  description,
  keywords,
  ogImage,
  metaHTML,
  sitemapVisible,
}) {
  const additionalHeaderMarkup = metaHTML ? ReactHtmlParser(metaHTML) : null;
  const isDevelopment = process.env.NEXT_PUBLIC_WEBSITE_ENV !== "production";
  const shouldAddNoindexTag = isDevelopment || !sitemapVisible;

  const isLocal = window.location.host === "localhost:3000";
  const isUAT = window.location.host === "uat.genesisenergy.co.nz";
  const pageTitle = [
    isUAT ? "[UAT]" : "",
    isLocal ? "[LOCAL]" : "",
    `${title} | Genesis NZ`,
  ].join(" ").trim();
  
  return (
    <Head>
      <title>{pageTitle}</title>
      <meta name="timestamp" content={new Date().toLocaleString("en-nz")} />
      <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      {shouldAddNoindexTag && <meta name="robots" content="noindex" />}
      {ogImage && <meta property="og:image" content={ogImage} />}
      <link rel="manifest" href="/manifest.json" crossOrigin="use-credentials" />
      <link type="text/plain" rel="author" href="/humans.txt" />

      {preConnectUrls.map((url) => (
        <link key={url} rel="preconnect" href={url} />  
      ))}
      {additionalHeaderMarkup}
    </Head>
  );
}

export { PageHead };
