import Axios from "axios";
import { format, parse, sub } from "date-fns";
import {
  EV_EXISTING_CUSTOMER,
  EV_NEW_CUSTOMER,
  NON_EV_CUSTOMER,
  PAYMENT_METHOD_CREDIT_CARD,
  PAYMENT_METHOD_DIRECT_DEBIT,
  PLAN_HIGH_USAGE_CODES,
  PLAN_LOW_USAGE_CODES,
  PLAN_TYPE_BASIC,
  PLAN_TYPE_EV_PLUS,
  PLAN_TYPE_PLUS,
  PLAN_USAGE_HIGH,
  PLAN_USAGE_LOW,
  PLUS_PLAN_FIXED_TERM_CODES,
  PREVENT_ACCESS_REASON_DOGS,
  PREVENT_ACCESS_REASON_GATE,
  PREVENT_ACCESS_REASON_METER_INSIDE,
  PREVENT_ACCESS_REASON_OTHER,
  PREVENT_ACCESS_REASON_SECURITY_CODE,
  PREVENT_ACCESS_REASON_STEEP_DRIVEWAY,
  PREVENT_ACCESS_REASON_STEPS,
  PREVENT_ACCESS_REASON_TRUCKS,
  PROPERTY_SITUATION_MOVE,
  RESIDENTIAL_PLAN_TERM_FIXED12,
  SUPPLY_POINT_TYPE_ELECTRICITY,
  SUPPLY_POINT_TYPE_GAS,
  SUPPLY_POINT_TYPE_LPG,
} from "helpers/constants";
import {
  createBillingAddress,
  createSiteAddress,
} from "helpers/addressHelper";
import {
  isBottledGasSelected,
  isElectricitySelected,
  isNaturalGasSelected,
} from "helpers/productHelper";
import { getSignupRequestHeaders } from "join-form/services/npaAuth";
import { compact, filter, get } from "lodash";
import { find, includes, isUndefined } from "lodash-es";

const signupEndpoint =
  process.env.NEXT_PUBLIC_MULE_API_BASE_URL + "/servicerequest/onlinesignup";

const failedSignupQueueEndpoint = "/api/onlinesignup/createonlinesignup";

const axiosOptions = {
  auth: {
    username: process.env.NEXT_PUBLIC_MULE_API_USER_NAME,
    password: process.env.NEXT_PUBLIC_MULE_API_PASSWORD,
  },
};

function getOpportunityType(planType, isExistingCustomer) {
  if (planType !== PLAN_TYPE_EV_PLUS) {
    return NON_EV_CUSTOMER;
  }
  return isExistingCustomer === "true" ? EV_EXISTING_CUSTOMER : EV_NEW_CUSTOMER;
}

async function createSignup(state) {
  try {
    const {
      appUserState: {
        title,
        lastName,
        middleName,
        firstName,
        dob,
        phone,
        email,
        isDriverLicence,
        licenceNo,
        licenceVersionNo,
        isExistingCustomer,
        customerNumber,
      },
      appPropertyState: { situation, moveInDate },
      appState: { planType },
    } = state;
    const isNewCustomerFlow = isExistingCustomer === "false";

    const driverLicence =
      isNewCustomerFlow && isDriverLicence
        ? {
            number: licenceNo,
            version: licenceVersionNo,
          }
        : {};

    const requestedDate = getRequestedDate({ situation, moveInDate });
    const opportunityType = getOpportunityType(planType, isExistingCustomer);

    const requestObject = {
      contact: {
        firstName,
        middleName,
        lastName,
        dob: formatDateOfBirth(dob),
        phone,
        title,
        email,
        driverLicence,
      },
      waterHeater: "",
      requestedDate,
      uniqueCode: "npa-signup",
      referrer: "",
      site: createSite(state),
      opportunityType,
      existingCustomer: opportunityType === EV_EXISTING_CUSTOMER,
      ...(isNewCustomerFlow && {
        moveOrTransfer: situation,
      }),
      billingAccount: isNewCustomerFlow
        ? createBillingAccount(state)
        : {
            number: customerNumber,
          },
    };

    const requestConfig = await getRequestConfig();

    await Axios.post(signupEndpoint, requestObject, requestConfig)
      .then(validateSignupResponse)
      .catch((ex) => sendRequestToFailedSignupQueue(requestObject));

    return {
      requestObject,
      transactionId: requestConfig.headers["X-Transaction-ID"],
    };
  } catch (ex) {
    return Promise.reject(ex);
  }
}

function formatDateOfBirth(dob) {
  const [formattedDate, error] = formatDate(dob);
  if (error) {
    const now = new Date(Date.now());
    const validBirthDate = sub(now, { years: 18 });
    return format(validBirthDate, "yyyy-MM-dd");
  }
  return formattedDate;
}

function getRequestedDate({ situation, moveInDate }) {
  const now = new Date(Date.now());
  const formattedNow = format(now, "yyyy-MM-dd");

  if (situation !== PROPERTY_SITUATION_MOVE) {
    return formattedNow;
  }

  const [formattedDate, error] = formatDate(moveInDate);

  if (error) {
    return formattedNow;
  }

  return formattedDate;
}

async function getRequestConfig() {
  const headers = await getSignupRequestHeaders();
  const requestConfig = {
    headers,
  };
  return requestConfig;
}

async function sendRequestToFailedSignupQueue(requestObject) {
  return Axios.post(
    failedSignupQueueEndpoint,
    requestObject,
    axiosOptions,
  ).then(validateSignupResponse);
}

function validateSignupResponse(axiosResponse) {
  if (axiosResponse.status > 202) {
    return Promise.reject(axiosResponse);
  }

  return axiosResponse;
}

function createBillingAccount(storeState) {
  const {
    appUserState: {
      email,
      isEBillingPreference,
      isPromoByEmail,
      paymentMethod,
    },
    addressState: { addressDetails },
    icp: { elecRegistryAddress },
    paymentState,
  } = storeState;

  return {
    paymentMethod: createPaymentMethod({ paymentMethod, ...paymentState }),
    promotion: {
      preference: isPromoByEmail === "true" ? "EMAIL" : "NONE",
      value: isPromoByEmail === "true" ? email : "",
    },
    billing: {
      address: createBillingAddress({
        addressFromFinder: addressDetails,
        registryAddress: elecRegistryAddress,
      }),
      preference: isEBillingPreference ? "EMAIL" : "POST",
      value: isEBillingPreference ? email : "",
    },
  };
}

function createPaymentMethod({
  paymentMethod,
  bankName,
  bankAccountNo,
  bankAccountName,
  expiryDate,
  generalTerms,
  tokenId,
  debitTermsConditionsKeys,
  ...restPaymentState
}) {
  switch (paymentMethod) {
    case PAYMENT_METHOD_DIRECT_DEBIT: {
      
      const debitTerms = Object.entries(restPaymentState)
        .filter(([key]) => debitTermsConditionsKeys.includes(key))
        .map(([key, value]) => ({ key, value }));
        
      const termsAccepted = debitTerms.every(term => term.value === true);
      
      return {
        paymentAuthority: "DIRECT_DEBIT",
        bankName: bankName || "Other",
        bankAcctName: bankAccountName,
        bankAcctNumber: bankAccountNo,
        authorised: termsAccepted,
        termsAccepted: termsAccepted,
        paymentRef: "Genesis",
        CCToken: "",
        CCCardExpiryDate: "",
        Confirmation: generalTerms,
      };
    }
    case PAYMENT_METHOD_CREDIT_CARD:
      return {
        paymentAuthority: "CREDIT_CARD",
        bankName: "",
        bankAcctName: "",
        bankAcctNumber: "",
        authorised: false,
        termsAccepted: false,
        paymentRef: "",
        CCToken: tokenId,
        CCCardExpiryDate: expiryDate,
        Confirmation: generalTerms,
      };
    default:
      return {
        paymentAuthority: "NONE",
        bankName: "",
        bankAcctName: "",
        bankAcctNumber: "",
        authorised: false,
        termsAccepted: false,
        paymentRef: "",
        CCToken: "",
        CCCardExpiryDate: "",
        Confirmation: generalTerms,
      };
  }
}

function createSite(storeState) {
  const {
    addressState: { addressDetails },
    appPropertyState,
    icp: { elecRegistryAddress },
    appState: {
      planType,
      planUsageType,
      products,
      numberOfChildren,
      numberOfAdults,
      isPrimaryResidence,
    },
    residentialOffers,
    appUserState: { isExistingCustomer },
    residentialPlanConfig: { termData },
  } = storeState;

  const address = createSiteAddress({
    addressFromFinder: addressDetails,
    registryAddress: elecRegistryAddress,
  });
  address.streetName = address.streetName + " " + address.streetType;

  const totalOccupancy = numberOfAdults + numberOfChildren;

  return [
    {
      address,
      primaryResidence: isPrimaryResidence,
      housePopulation:
        totalOccupancy >= 4 ? "4_or_more" : totalOccupancy.toString(),
      supplyPoint: createSupplyPoints({
        products,
        residentialOffers,
        planType,
        planUsageType,
        appPropertyState,
        isExistingCustomer,
        termData,
      }),
      ...(isExistingCustomer === "false" && {
        access: createSiteAccess(appPropertyState),
      }),
    },
  ];
}

function createSiteAccess({
  meterAccessHasAnIssue,
  meterAccessIssueReasons,
  meterAccessIssueReasonOther,
}) {
  const limited = meterAccessHasAnIssue === "true";

  if (!limited) {
    return {
      limited,
      dog: "NO_DOGS",
      trucks: false,
      securityCode: false,
      steps: false,
      meterInside: false,
      steepDriveway: false,
      gate: false,
      meterReaderNotes: "",
    };
  }

  const dog = meterAccessIssueReasons.includes(PREVENT_ACCESS_REASON_DOGS)
    ? "DOGS"
    : "NO_DOGS";
  const trucks = meterAccessIssueReasons.includes(PREVENT_ACCESS_REASON_TRUCKS);
  const securityCode = meterAccessIssueReasons.includes(
    PREVENT_ACCESS_REASON_SECURITY_CODE,
  );
  const steps = meterAccessIssueReasons.includes(PREVENT_ACCESS_REASON_STEPS);
  const meterInside = meterAccessIssueReasons.includes(
    PREVENT_ACCESS_REASON_METER_INSIDE,
  );

  const steepDriveway = meterAccessIssueReasons.includes(
    PREVENT_ACCESS_REASON_STEEP_DRIVEWAY,
  );

  const gate = meterAccessIssueReasons.includes(PREVENT_ACCESS_REASON_GATE);

  const otherAccessIssues = meterAccessIssueReasons.includes(
    PREVENT_ACCESS_REASON_OTHER,
  );

  const otherAccessIssuesMessage = otherAccessIssues
    ? meterAccessIssueReasonOther
    : "";

  return {
    limited,
    dog,
    trucks,
    securityCode,
    steps,
    meterInside,
    steepDriveway,
    gate,
    meterReaderNotes: otherAccessIssuesMessage,
  };
}

function createSupplyPoints({
  products,
  residentialOffers: {
    electricityOffer,
    naturalGasOffer,
    bottledGasOffer,
    electricityOffersResult,
    naturalGasOffersResult,
    bottledGasOffersResult,
  },
  planType,
  planUsageType,
  appPropertyState,
  isExistingCustomer,
  termData,
}) {
  const planTypeForNonElec =
    planType === PLAN_TYPE_EV_PLUS ? PLAN_TYPE_PLUS : planType;

  const isNewCustomerFlow = isExistingCustomer === "false";

  const selectedPlanTermConfig =
    find(termData, ({ isSelected }) => isSelected) || {};
  const selectedPlanTerm = selectedPlanTermConfig.codeName || "";

  const supplyPointElectricity = createSupplyPointElectricity({
    products,
    electricityOffer,
    planType,
    planUsageType,
    appPropertyState,
    isNewCustomerFlow,
    selectedPlanTerm,
    electricityOffersResult,
  });

  const supplyPointGas = createSupplyPointGas({
    products,
    naturalGasOffer,
    planType: planTypeForNonElec,
    planUsageType,
    selectedPlanTerm,
    naturalGasOffersResult,
  });

  const supplyPointBottledGas = createSupplyPointBottledGas({
    products,
    bottledGasOffer,
    planType: planTypeForNonElec,
    planUsageType,
    appPropertyState,
    isNewCustomerFlow,
    selectedPlanTerm,
    bottledGasOffersResult,
  });

  return compact([
    supplyPointElectricity,
    supplyPointGas,
    supplyPointBottledGas,
  ]);
}

function createSupplyPointElectricity({
  products,
  electricityOffer,
  planType,
  planUsageType,
  appPropertyState,
  isNewCustomerFlow,
  selectedPlanTerm,
  electricityOffersResult,
}) {
  const isSelected = isElectricitySelected(products);

  if (isSelected === false) {
    return null;
  }

  const selectedOffer = getSelectedOffer({
    planType,
    planUsageType,
    offer: electricityOffer,
    selectedPlanTerm,
    offersAPIResponse: electricityOffersResult,
  });

  const critical = appPropertyState.isWellBeing === "true";

  return {
    type: SUPPLY_POINT_TYPE_ELECTRICITY,
    supplyAgreement: isNewCustomerFlow
      ? {
          medicalDependency: appPropertyState.isMedicalSupport === "true",
          critical,
          criticalCode: critical
            ? appPropertyState.wellBeingAffectedReason
            : null,
        }
      : {},
    selectedOffer,
  };
}

function createSupplyPointGas({
  products,
  naturalGasOffer,
  planType,
  planUsageType,
  selectedPlanTerm,
  naturalGasOffersResult,
}) {
  const isSelected = isNaturalGasSelected(products);

  if (isSelected === false) {
    return null;
  }

  const selectedOffer = getSelectedOffer({
    planType,
    planUsageType,
    offer: naturalGasOffer,
    selectedPlanTerm,
    offersAPIResponse: naturalGasOffersResult,
  });

  return {
    type: SUPPLY_POINT_TYPE_GAS,
    selectedOffer,
  };
}

function createSupplyPointBottledGas({
  products,
  bottledGasOffer,
  planType,
  planUsageType,
  appPropertyState,
  isNewCustomerFlow,
  selectedPlanTerm,
  bottledGasOffersResult,
}) {
  const isSelected = isBottledGasSelected(products);

  if (isSelected === false) {
    return null;
  }

  const {
    gasBottlesCount,
    gasBottlesSupplier,
    orderBottlesOption,
    gasBottlesLocation,
  } = appPropertyState;

  const selectedOffer = getSelectedOffer({
    planType,
    planUsageType,
    offer: bottledGasOffer,
    selectedPlanTerm,
    offersAPIResponse: bottledGasOffersResult,
  });

  const lpgSupplyPoint = {
    type: SUPPLY_POINT_TYPE_LPG,
    ...(isNewCustomerFlow && {
      numberOfLPGBottles: gasBottlesCount,
      retailer: gasBottlesSupplier,
      meterLocationCode: gasBottlesLocation,
    }),
    selectedOffer,
  };

  if (isNewCustomerFlow && orderBottlesOption === "true") {
    lpgSupplyPoint.lpgOrder = {
      numberOfBottles: "2",
    };
  }

  return lpgSupplyPoint;
}

function getSelectedPlan(planType) {
  switch (planType) {
    case PLAN_TYPE_BASIC:
      return "basicPlan";
    case PLAN_TYPE_PLUS:
      return "plusPlan";
    case PLAN_TYPE_EV_PLUS:
      return "evPlan";
    default:
      return "";
  }
}

function getSelectedOfferForFixedPlusPlan({ offersList, planUsageType }) {
  if (isUndefined(offersList)) {
    return {};
  }

  const fixedTermPlusPlans = filter(offersList, ({ priceBook, code }) => {
    return (
      PLUS_PLAN_FIXED_TERM_CODES.includes(code) &&
      priceBook.type === PLAN_TYPE_PLUS
    );
  });

  if (planUsageType === PLAN_USAGE_LOW) {
    return (
      find(fixedTermPlusPlans, ({ priceBook }) =>
        includes(PLAN_LOW_USAGE_CODES, priceBook.category.code),
      ) || {}
    );
  } else {
    return (
      find(fixedTermPlusPlans, ({ priceBook }) =>
        includes(PLAN_HIGH_USAGE_CODES, priceBook.category.code),
      ) || {}
    );
  }
}

function getSelectedOffer({
  planType,
  planUsageType,
  offer,
  selectedPlanTerm,
  offersAPIResponse,
}) {
  if (
    planType === PLAN_TYPE_PLUS &&
    selectedPlanTerm === RESIDENTIAL_PLAN_TERM_FIXED12
  ) {
    return getSelectedOfferForFixedPlusPlan({
      offersList: offersAPIResponse.offerList,
      planUsageType,
    });
  }

  const type = getSelectedPlan(planType);
  const usageType =
    planUsageType === PLAN_USAGE_HIGH ? "highUsage" : "lowUsage";

  const selectedOffer = get(offer, `${type}.${usageType}.data`, {});
  return selectedOffer;
}

function formatDate(dateString) {
  const now = new Date(Date.now());
  try {
    const parsedDate = parse(dateString, "dd/MM/yyyy", now);
    const formattedDate = format(parsedDate, "yyyy-MM-dd");
    return [formattedDate, null];
  } catch (ex) {
    return [null, ex];
  }
}

export { signupEndpoint, failedSignupQueueEndpoint, createSignup };
