import React from "react";
import classNames from "classnames";
import { AccordionSectionCta } from "./AccordionSectionCta";

function AccordionSectionTransparent({ title, anchor, cta = null, children }) {
  return (
    <div
      id={anchor}
      data-testid="accordionSection"
      className={classNames(
        "mx-auto mb-10 sm:mb-20",
        "scroll-mt-28 md:scroll-mt-48 lg:scroll-mt-56",
        "first:pt-28 md:first:pt-48 lg:first:pt-56",
      )}
    >
      <div
        className={classNames(
          "px-4 mx-auto sm:px-15 md:px-17 lg:px-23 xl:px-46",
          "max-w-full w-120 sm:w-210 md:w-auto",
        )}
      >
        <div className={classNames("flex flex-col", "gap-y-5 sm:gap-y-10")}>
          <div
            className={classNames(
              "flex flex-col",
              "sm:flex-row sm:justify-between sm:items-center",
            )}
          >
            <h2 className={classNames("font-bold text-27/32", "md:text-32/36")}>
              {title}
            </h2>
            {cta ? <AccordionSectionCta data={cta} /> : null}
          </div>
          {children}
        </div>
      </div>
    </div>
  );
}

export { AccordionSectionTransparent };
