import { motion, useReducedMotion } from "framer-motion";

function BgCurveAnimation({ children }) {
  const prefersReducedMotion = useReducedMotion();

  if (prefersReducedMotion) {
    return (
      <div className="h-full" data-testid="bgCurveAnimationReducedMotion">
        {children}
      </div>
    );
  }

  return (
    <motion.div
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true, amount: 0.1 }}
      transition={{ duration: 0.5 }}
      variants={{
        visible: { opacity: 1, y: 0 },
        hidden: { opacity: 0, y: 150 },
      }}
      className="h-full"
    >
      {children}
    </motion.div>
  );
}

export { BgCurveAnimation };
