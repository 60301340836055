export const headerFade = {
  initial: { x: -40, opacity: 0 },
  animate: { x: 0, opacity: 1 },
  exit: {
    y: -60,
    opacity: 0,
    transition: {
      type: "tween",
    },
  },
};

export const itemContainer = {
  initial: {
    opacity: 1,
  },
  animate: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1,
    },
  },
  exit: {
    opacity: 0,
    y: -60,
    transition: {
      type: "tween",
    },
  },
};

export const itemFade = {
  initial: { opacity: 0, y: 60 },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: 0 },
};

export const heightFadeVariants = {
  initial: { height: 0, opacity: 0 },
  animate: { height: "auto", opacity: 1 },
  exit: { height: 0, opacity: 0 },
};

export const planFooterVariants = {
  initial: { height: 0, opacity: 0, transition: { duration: 0.2 } },
  animate: { height: "auto", opacity: 1, transition: { duration: 0.2 } },
  exit: { height: 0, opacity: 0, transition: { duration: 0.2 } },
};

export const staticVariants = {
  initial: { height: "auto", opacity: 1 },
  animate: { height: "auto", opacity: 1 },
  exit: { height: "auto", opacity: 1 },
};

export const heightFadeTransition = { type: "tween" };

export const fadeInBottomAnimation = {
  hidden: { opacity: 0, y: 20 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.3,
    },
  },
};
