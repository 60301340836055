import { PAYMENT_METHOD_DIRECT_DEBIT } from "helpers/constants";
import { camelCase } from "lodash";
import * as Yup from "yup";

export const getDebitTermConditionId = (fields, index) => fields?.id ? camelCase(fields?.id) : `debitTermsConditionsItem${index}`;

// set formik validation rules dynamically based on debitTermsConditions
export const setTermsAndConditionsValidation = ({ debitTermsConditions, setFormValidationSchema, validationSchema }) => {
  if (debitTermsConditions?.length <= 0 || !validationSchema) return;
  let validationRules = {};
  debitTermsConditions?.forEach(({ fields }, index) => {
    if(!fields.term) return;
    const itemId = getDebitTermConditionId(fields, index);
    validationRules = {
      ...validationRules,
      [itemId]: Yup.boolean().when("paymentMethod", {
        is: (value) => value === PAYMENT_METHOD_DIRECT_DEBIT,
        then: Yup.boolean().required().oneOf([true], "This field is required"),
      }),
    };

  });
  const termsValidation = Yup.object().shape(validationRules);
  setFormValidationSchema(validationSchema?.concat(termsValidation));
};

export const directDebitFormValidation = Yup.object().shape({
  bankAccountNo: Yup.string().when("paymentMethod", {
    is: (value) => value === PAYMENT_METHOD_DIRECT_DEBIT,
    then: Yup.string()
      .required("Account number is required")
      .matches(/^[0-9 -]+$/, "Please enter a valid bank account number")
      .min(18, "Please enter a valid bank account number"),
  }),
  bankAccountName: Yup.string().when("paymentMethod", {
    is: (value) => value === PAYMENT_METHOD_DIRECT_DEBIT,
    then: Yup.string()
      .required("Account holder name is required")
      .max(255, "Account holder name cannot be more than 255 characters"),
  }),
});
