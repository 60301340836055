import React from "react";
import classNames from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import { Close, GenesisLogo, Menu, SearchLeft } from "assets/icons";
import { Link } from "components/Link";
import { APP_ROUTES } from "helpers/constants";
import { useHeadroom } from "providers/Headroom";
import { toggleNav, useNavigation } from "providers/Navigation";
import { MainNav } from "./MainNav";
import { TopLevelNav } from "./TopLevelNav";

const headroomConfig = {
  offset: 150,
  tolerance: { up: 40, down: 10 },
  classes: { pinned: "translate-y-0", unpinned: "-translate-y-24" },
};

function SiteHeaderMobile(props) {
  const {
    globalData: {
      header: { content },
    },
    logoText,
  } = props;

  const { primaryCta, secondaryCta } = content;

  const {
    state: { isMobileNavOpen, currentNav },
    dispatch,
  } = useNavigation();

  const { headerRef } = useHeadroom(headroomConfig);

  const mainNavRef = React.useRef();

  React.useEffect(() => {
    document.body.classList.toggle("overflow-hidden", isMobileNavOpen);

    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, [isMobileNavOpen]);

  React.useEffect(() => {
    mainNavRef.current.scrollTo({ top: 0, behaviour: "smooth" });
  }, [currentNav]);

  function onToggleNavClick() {
    toggleNav({ dispatch });
  }

  return (
    <>
      <AnimatePresence>
        {isMobileNavOpen && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            className="fixed inset-0 z-10 bg-white"
            data-testid="menuBackgroundOverlay"
          />
        )}
      </AnimatePresence>
      <div
        ref={headerRef}
        className={classNames(
          "translate-y fixed z-10 inset-x-4 mx-auto py-4 transition-all duration-300 ease-in-out",
          { "h-25": !isMobileNavOpen, "h-full": isMobileNavOpen },
        )}
        data-testid="siteHeaderMobile"
      >
        <div className="relative h-full mobile-header">
          <Link href="/">
            <a className="absolute flex items-center left-3 top-2 shrink-0 gap-x-2">
              <span className="w-18 h-18">
                <GenesisLogo
                  role="img"
                  aria-label={content.logoAltText}
                  className="w-full h-full"
                />
              </span>
              {logoText ? (
                <span
                  data-testid="siteHeaderLogoText"
                  className="font-bold text-black-primary -tracking-[0.3px]"
                >
                  {logoText}
                </span>
              ) : null}
            </a>
          </Link>
          <div
            className={classNames(
              "flex flex-col h-full overflow-hidden bg-white min-h-17 rounded-2xl",
              {
                "shadow-100": !isMobileNavOpen,
              },
            )}
          >
            <div className="flex min-h-17">
              <div className="flex ml-auto">
                <Link href={APP_ROUTES.search} prefetch={false}>
                  <a className="flex items-end py-3 text-black-primary" aria-label="Search">
                    <span className="flex items-center bg-dark-50 p-1.5 rounded-full">
                      <SearchLeft className="w-4 fill-current" />
                    </span>
                  </a>
                </Link>
                <button
                  className="flex items-end px-5 py-3"
                  onClick={onToggleNavClick}
                >
                  <div className="w-7 h-7 text-black-primary">
                    {isMobileNavOpen ? (
                      <Close
                        aria-label="Close menu"
                        className="fill-current w-7"
                      />
                    ) : (
                      <Menu
                        aria-label="Open menu"
                        className="fill-current w-7"
                      />
                    )}
                  </div>
                </button>
              </div>
            </div>
            <div className="flex flex-col flex-1 px-4 pt-2">
              <TopLevelNav />
              <div className="w-full h-px rounded bg-black-50" />
              <div
                ref={mainNavRef}
                className="flex flex-1 flex-col -mx-3 px-3 max-h-(screen-185px) overflow-scroll overscroll-contain"
              >
                <div className="flex-col py-3">
                  <MainNav />
                </div>
                <div
                  className={classNames(
                    "flex flex-col opacity-0 transition-opacity duration-300",
                    {
                      "opacity-100 delay-300": isMobileNavOpen,
                    },
                  )}
                >
                  <div className="flex items-center pt-3 pb-12 space-x-4">
                    {primaryCta.href && primaryCta.text ? (
                      <Link href={primaryCta.href} prefetch={false}>
                        <a
                          className="flex px-3 py-2 text-center text-white rounded-full text-2xs bg-orange-primary"
                          target={primaryCta.target}
                          data-testid="primaryCta"
                          data-ga={JSON.stringify({
                            content_tertiary: "global menu",
                            event: "cta_click",
                            event_category: "cta",
                            event_label: [primaryCta.text],
                            href: primaryCta.href,
                          })}
                        >
                          {primaryCta.text}
                        </a>
                      </Link>
                    ) : null}
                    {secondaryCta.href && secondaryCta.text ? (
                      <Link href={secondaryCta.href} prefetch={false}>
                        <a
                          className="flex px-3 py-2 text-center text-2xs text-orange-primary"
                          target={secondaryCta.target}
                          data-testid="secondaryCta"
                          data-ga={JSON.stringify({
                            content_tertiary: "global menu",
                            event: "cta_click",
                            event_category: "cta",
                            event_label: [secondaryCta.text],
                            href: secondaryCta.href,
                          })}
                        >
                          {secondaryCta.text}
                        </a>
                      </Link>
                    ) : null}
                  </div>
                </div>
              </div>
              <div
                className={classNames(
                  "absolute bottom-0 left-0 right-0 flex opacity-0 pointer-events-none transition-opacity duration-300",
                  {
                    "opacity-100 pointer-events-auto delay-300":
                      isMobileNavOpen,
                  },
                )}
              >
                <button
                  onClick={onToggleNavClick}
                  className="flex items-end justify-center flex-1 h-12 pb-2 bg-gradient-to-t rounded-b-2xl from-white"
                  aria-label="Footer close menu"
                >
                  <span className="h-1 rounded w-7 bg-black-700"></span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export { SiteHeaderMobile };
