import classNames from "classnames";
import { ErrorMessage } from "./ErrorMessage";

export const baseStyles = {
  input: [
    "appearance-none bg-transparent w-full transition-all outline-none",
    "cursor-pointer disabled:cursor-auto",
    "pl-1 pr-9 pb-2",
    "border-b border-b-black-300",
    "hover:border-b-orange-primary",
    "focus:border-b-orange-primary focus:border-b-2 focus:-mb-px",
    "disabled:hover:border-b-black-300",
    "disabled:focus:border-b-black-300",
  ].join(" "),
  label: [
    "absolute transition-all left-1",
    "cursor-pointer disabled:cursor-auto",
  ].join(" "),
};

export const defaultStyles = {
  containerBorder: "bg-transparent",
  label: "opacity-0 pointer-events-none text-15/24 md:text-18/28",
  input: "text-black-300 border-b-black-300",
};

export const filledStyles = {
  containerBorder: "bg-black-primary",
  label:
    "opacity-100 text-black-300 top-0 pointer-events-auto text-12/16 md:text-13/16",
  input: "text-black-primary border-b-black-primary border-b-2 -mb-px",
};

export const InputField = ({
  label,
  type = "text",
  name,
  value,
  register,
  errorMessage,
}) => {
  const styles = value ? filledStyles : defaultStyles;
  return (
    <div className="relative">
      <div className={classNames("pt-5 md:pt-4", "text-15/24 md:text-18/28")}>
        <label
          htmlFor={name}
          className={classNames(baseStyles.label, styles.label)}
          data-testid={`inputField${name}`}
        >
          {label}
        </label>
        <input
          placeholder={label}
          type={type}
          className={classNames(baseStyles.input, styles.input, {
            "border-b-red-primary": !!errorMessage,
          })}
          {...register(name)}
        />
      </div>
      {errorMessage && (
        <ErrorMessage message={errorMessage} testId={`${name}ErrorMessage`} />
      )}
    </div>
  );
};
