import { createSelector } from "@reduxjs/toolkit";
import {
  EnergyRateContainer,
  EnergyRateItem,
  EnergyRatePlaceholderContainer,
} from "join-form/components/energy-rate";
import {
  PLAN_TYPE_EV_PLUS,
  PLAN_TYPE_TO_PLAN_NAME,
  PLAN_USAGE_LOW,
} from "helpers/constants";
import { isElectricitySelected } from "helpers/productHelper";
import {
  getRateDescription,
  getRateUnitOfMeasurement,
  sortEVTariffListForDisplay,
} from "helpers/rateHelper";
import { selectAppState, selectResidentialOffers } from "reducers/selector";
import { useSelector } from "react-redux";

const stateSelector = createSelector(
  [selectAppState, selectResidentialOffers, (state, planType) => planType],
  (appState, residentialOffers, planType) => {

    const plan = PLAN_TYPE_TO_PLAN_NAME[planType];
    const { products } = appState;
    const electricityOffer = residentialOffers?.electricityOffer;

    const lowUsageTariffList = electricityOffer[plan]?.lowUsage.tariffList || [];
    const highUsageTariffList = electricityOffer[plan]?.highUsage.tariffList || [];

    const shouldRender = isElectricitySelected(products);
    const isEVplan = planType === PLAN_TYPE_EV_PLUS;

    const lowUsageTariffListToDisplay = isEVplan
      ? sortEVTariffListForDisplay(lowUsageTariffList)
      : lowUsageTariffList;

    const highUsageTariffListToDisplay = isEVplan
      ? sortEVTariffListForDisplay(highUsageTariffList)
      : highUsageTariffList;

    return {
      lowUsageTariffListToDisplay,
      highUsageTariffListToDisplay,
      shouldRender,
    };
  },
);

function ElectricityRates(props) {
  const { planType, usageType, inclGst, visible, onToggle, highlightOnMobile } =
    props;

  const {
    lowUsageTariffListToDisplay,
    highUsageTariffListToDisplay,
    shouldRender,
  } = useSelector((state) => stateSelector(state, planType));

  const tariffList =
    usageType === PLAN_USAGE_LOW
      ? lowUsageTariffListToDisplay
      : highUsageTariffListToDisplay;

  if (!shouldRender) {
    return null;
  }

  return tariffList.length ? (
    <EnergyRateContainer
      icon="ElectricityBadge"
      title="Electricity"
      data-testid={`${planType}_${usageType}_ElectricityRates`}
      visible={visible}
      onToggle={onToggle}
      highlightOnMobile={highlightOnMobile}
    >
      {tariffList.map((tariffItem, index) => {
        const {
          description,
          meteringUnitOfMeasurement,
          pricingUnitOfMeasurement,
          rateExcludingGST,
          rateIncludingGST,
        } = tariffItem;

        const title = getRateDescription(description);
        const rateValue = inclGst ? rateIncludingGST : rateExcludingGST;
        const unitOfMeasurement = getRateUnitOfMeasurement(
          meteringUnitOfMeasurement,
        );
        const value = `${pricingUnitOfMeasurement}${rateValue.toFixed(
          4,
        )} / ${unitOfMeasurement}`;

        return <EnergyRateItem key={index} title={title} value={value} />;
      })}
    </EnergyRateContainer>
  ) : (
    <EnergyRatePlaceholderContainer
      icon="ElectricityBadgeBlack"
      title="Electricity"
      visible={visible}
      onToggle={onToggle}
      data-testid={`${planType}_ElectricityRatesPlaceholder`}
    />
  );
}

export default ElectricityRates;
