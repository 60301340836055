import React from "react";
import { ContentZone } from "@agility/nextjs";
import { getModule, SiteFooter, SiteHeader } from "page-modules";


function PageTemplate(props) {
  const {
    page: { zones },
  } = props;

  const hasBackgroundCurves = Boolean(zones.BackgroundCurves);

  return (
    <>
      <SiteHeader {...props} />
      <div data-testid="page" className="min-h-screen isolate">
        <div id="page-content" className="relative z-1">
          <ContentZone
            name="Content"
            {...props}
            getModule={getModule}
          />
          {hasBackgroundCurves && (
            <div data-testid="backgroundCurvesZone">
              <ContentZone
                name="BackgroundCurves"
                {...props}
                getModule={getModule}
              />
            </div>
          )}
        </div>
      </div>
      <SiteFooter {...props} />
    </>
  );
}

export { PageTemplate };
