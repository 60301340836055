export class GraphQLApi {
  constructor(config) {
    this.baseUrl = "https://api.aglty.io/v1";
    this.locale = config.locale || "en-nz";
    this.urlSuffix = "/graphql";
    this.method = "post";
    this.req = {};

    this.initRequest(config);
  }

  initRequestUrlPath({ guid, isPreview }) {
    let apiFetchOrPreview = "fetch";

    if (isPreview === true || isPreview === "true") {
      apiFetchOrPreview = "preview";
    }

    return `${this.baseUrl}/${guid}/${apiFetchOrPreview}/${this.locale}`;
  }

  initHeaders({ apiKey, guid, headers, requiresGuidInHeaders }) {
    const defaultHeaders = {
      apiKey,
      ...headers,
    };
    if (requiresGuidInHeaders) {
      defaultHeaders.guid = guid;
    }

    return defaultHeaders;
  }

  initRequest(config) {
    this.req = {
      url: this.urlSuffix,
      method: this.method,
      baseURL: this.initRequestUrlPath(config),
      headers: this.initHeaders(config),
    };
  }

  buildRequest(data) {
    return {
      ...this.req,
      data,
    };
  }
}
