import { SearchRight, Spinner } from "assets/icons";
import styles from "../address-auto-suggest.module.scss";

export default function Input({ inputProps, isBusy, disabled }) {
  const busyClassName = isBusy ? styles.busy : "";

  return (
    <div className={styles.inputContainer}>
      <input {...inputProps} disabled={disabled} />
      <div
        data-testid="addressInputIconContainer"
        className={`${styles.inputIconContainer} ${busyClassName}`}
      >
        <div className={styles.inputIconSearch}>
          <SearchRight className="w-6 md:w-9 fill-current" />
        </div>
        <div className={styles.inputIconLoading}>
          <Spinner
            className="w-6 md:w-9 motion-safe:animate-spin"
            data-testid="loadingIcon"
          />
        </div>
      </div>
    </div>
  );
}
