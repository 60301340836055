import classNames from "classnames";
import { Image } from "components/Image";
import styles from "./button-on-off-toggle.module.scss";

export function ButtonOnOffToggle(props) {
  const {
    title,
    onClick,
    isSelected = false,
    className,
    icon,
    disableShadowStylesOnActive = false,
    subTitle = "",
    ...rest
  } = props;

  const classes = classNames(styles.button, className, {
    [styles.active]: isSelected,
    [styles.disableShadow]: disableShadowStylesOnActive,
  });

  return (
    <button className={classes} onClick={onClick} {...rest}>
      <div className={classNames(styles.icon, "w-15 h-15 md:w-18 md:h-18")}>
        <Image src={icon.url} alt={icon.label} layout="fill" />
      </div>
      {subTitle ? (
        <div data-testid="subtitle" className={styles.text_container}>
          <div className={styles.text}>{title}</div>
          <div
            className={classNames(styles.subtitle_text, {
              [styles.active]: isSelected,
            })}
          >
            {subTitle}
          </div>
        </div>
      ) : (
        <div className={styles.text}>{title}</div>
      )}
    </button>
  );
}
