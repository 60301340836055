import React, { useEffect } from "react";
import { AlertBanner, LiveChat } from "page-modules";
import { getPageTemplate } from "page-templates";
import { PagePreview } from "page-layouts/PagePreview";
import { gaPageClick } from "helpers/gaHelper";
import { QueryClientProvider, QueryClient } from "react-query";
import { GlobalDataProvider, HeadroomProvider, NavigationProvider } from "providers";
import { setUtmValues } from "actions/zendeskActions";
import { useRouter } from "next/router";
import { useDispatch } from "react-redux";
import { getUtmValues } from "helpers/utmHelper";

const queryClient = new QueryClient();

export const Providers = ({ children, ...props }) => (
  <QueryClientProvider client={queryClient}>
    <GlobalDataProvider globalData={props.globalData}>
      <HeadroomProvider>
        <div className="fixed z-20 mx-auto pointer-events-none max-w-400 left-4 right-4 bottom-4">
          <LiveChat />
          <AlertBanner items={props.globalData.alertBanner} />
        </div>
        <PagePreview {...props} />
        <NavigationProvider globalData={props.globalData}>
          {children}
        </NavigationProvider>
      </HeadroomProvider>
    </GlobalDataProvider>
  </QueryClientProvider>
);

const useSearchParams = () => {
  const router = useRouter();
  const dispatch = useDispatch();
  const searchParams = router.asPath.split("?")[1];

  useEffect(() => {
    if (searchParams) {
      const utmValues = getUtmValues(searchParams);
      dispatch(setUtmValues(utmValues));
    }
  }, [searchParams, dispatch]);
}

function PageContent(props) {

  useSearchParams();
  const { pageTemplateName } = props;

  const PageTemplateComponent = getPageTemplate(pageTemplateName);
  return (
    <div
      className="max-w-full mx-auto antialiased bg-dark-50 page-content w-420"
      onClick={gaPageClick}
    >
      <Providers {...props}>
        <PageTemplateComponent {...props} />
      </Providers>
    </div>
  );
}

export { PageContent };
