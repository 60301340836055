import classNames from "classnames";
import Icon from "join-form/components/icon";
import styles from "../page-sidebar-partial.module.scss";

function createButtonText({
  termDiscount,
  dualFuelDiscount,
  paymentMethodDiscount,
  eBillingDiscount,
  credit = "",
} = {}) {
  const discountPercentage =
    termDiscount + dualFuelDiscount + paymentMethodDiscount + eBillingDiscount;

  const discountExists = discountPercentage > 0;
  const creditExists = credit !== "";
  if (discountExists && creditExists) {
    return `${discountPercentage}% discount and ${credit} credit`;
  }
  if (discountExists) {
    return `${discountPercentage}% discount`;
  }
  if (creditExists) {
    return `${credit} credit`;
  }
  return "";
}

export default function EnergyPlanButton(props) {
  const {
    onClick,
    termItem: { discountPercentage: termDiscount, credit },
    dualFuelDiscount,
    eBillingDiscount,
    paymentMethodDiscount,
  } = props;

  const text = createButtonText({
    termDiscount,
    dualFuelDiscount,
    eBillingDiscount,
    paymentMethodDiscount,
    credit,
  });

  return (
    <button className={styles.header_button} onClick={onClick}>
      <span className="flex items-center">
        <span
          className={classNames(
            "flex items-center justify-center rounded-full mr-3",
            "bg-orange-primary text-white",
          )}
        >
          <Icon name="ElectricityWhite" size="24" />
        </span>
        <span className={styles.header_button_text_container}>
          <span
            className={styles.header_button_text_line1}
            data-testid="mobilePlanTitle"
          >
            Your energy plan & rates
          </span>
          <span
            className={styles.header_button_text_line2}
            data-testid="mobileHeaderText"
          >
            {text}
          </span>
        </span>
      </span>
      <span className={styles.header_button_icon_container}>
        <Icon name="ChevronDown" size="18" />
      </span>
    </button>
  );
}
