import classNames from "classnames";
import { useSelector } from "react-redux";
import { getShortAddressFromFinder } from "helpers/addressHelper";
import { getPlanDisplayName, getPlanTypeByTerm } from "helpers/plansHelper";

function stateSelector(state) {
  const {
    appState: { planType: planTypeFromStore },
    addressState,
    residentialPlanConfig,
  } = state;
  const { termData } = residentialPlanConfig;
  const { addressDetails } = addressState;

  const { codeName: selectedTermCodeName } =
    termData.find(({ isSelected }) => isSelected) ?? {};

  const planType = getPlanTypeByTerm({
    selectedTermCodeName,
    planType: planTypeFromStore,
  });

  const shortAddress = getShortAddressFromFinder(addressDetails);
  const planName = getPlanDisplayName(planType);
  return { shortAddress, planName };
}

function SidebarHeader({ className }) {
  const { planName, shortAddress } = useSelector(stateSelector);
  return (
    <h4
      className={classNames("text-15/24 md:text-18/28", className)}
      data-testid="planTitle"
    >
      Your Energy {planName} for {shortAddress}
    </h4>
  );
}

export { SidebarHeader };
