import { compact } from "lodash-es";
import { getContentListAll } from "helpers/agilityHelper";
import { getSafeNumber } from "helpers/numberStringHelper";

async function getCustomInitialProps({ agility, languageCode, item }) {
  const { topics_ValueField } = item.fields;
  const parsedTopicValue = getSafeNumber(topics_ValueField);

  const { items, totalCount } = await getContentListAll(agility, {
    referenceName: "gefaqs",
    languageCode,
  });

  if (totalCount === 0) {
    return [];
  }

  const filteredItems = items.filter((faqItem) => {
    const multipleTopics = faqItem.fields.topicMultiple ?? [];
    const multipleTopicContentIds = multipleTopics.map(
      (topic) => topic?.contentID,
    );
    const singleTopicContentID = faqItem.fields.topic?.contentID;
    const allTopicsContentIDs = compact([
      ...multipleTopicContentIds,
      singleTopicContentID,
    ]);
    return allTopicsContentIDs.includes(parsedTopicValue);
  });

  return filteredItems;
}

export { getCustomInitialProps };
