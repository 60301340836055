import { useEffect, useState } from "react";
import { createSelector } from "@reduxjs/toolkit";
import { setProductSelection, setGasSelection } from "actions/appState/appStateActions";
import {
  checkStoreStateForBusinessCallbackFlow,
  setCallbackReferrerUrl,
  unsetCallbackReferrerUrl,
} from "actions/callbackPageActions";
import { motion } from "framer-motion";
import { filter, head } from "lodash-es";
import { useRouter } from "next/router";
import { useDispatch, useSelector } from "react-redux";
import { selectAddressState, selectAppState, selectIcpState } from "reducers/selector";
import { ButtonOnOffToggle } from "components/ButtonOnOffToggle";
import { StepProgressionButton } from "components/StepProgressionButton";
import { getBusinessAddressPageUrl } from "helpers/addressHelper";
import {
  appBusinessRoutes,
  CALLBACK_REASON_LPG_REQUOTE_PPD_PLANS,
  CALLBACK_REASON_NO_RATES_FOR_SELECTED_TERM,
} from "helpers/constants";
import { trackBusinessContinueButton } from "helpers/gtmHelper";
import { itemContainer, itemFade } from "./animations";

// selectors
const selectBusinessFuelType = createSelector(
  [selectAppState, selectAddressState, selectIcpState],
  (appState, addressState, icpState) => {
    const { products } = appState;
    const { addressType } = addressState;
    const { gasIcp } = icpState;

    const availableProducts = filter(
      products,
      ({ isAvailable }) => isAvailable,
    );
    const backButtonUrl = getBusinessAddressPageUrl(addressType);
    const hasGasIcp = gasIcp !== "";
    return {
      products,
      availableProducts,
      backButtonUrl,
      hasGasIcp,
    };
  },
);

export function BusinessFuelType({ module }) {
  const { fuelOptions } = module.fields;
  const {
    products: initialProductsData,
    availableProducts,
    backButtonUrl,
    hasGasIcp
  } = useSelector(selectBusinessFuelType);

  const dispatch = useDispatch();
  const router = useRouter();

  const [products, updateProducts] = useState(initialProductsData);

  useEffect(() => {
    const isGasTurnedOn = fuelOptions.filter((option) => option?.fields?.id === 'Natural Gas')?.length > 0;
    if (hasGasIcp && isGasTurnedOn) {
      dispatch(setGasSelection(true));
      updateProducts((prevProducts) => {
        return prevProducts.map((product) => {
          if (product.name === 'Natural Gas') {
            return {
              ...product,
              isSelected: true,
            };
          }
          return product;
        });
      });
    }
  }, [dispatch, hasGasIcp, fuelOptions]);

  const onContinueClick = (e) => {
    e.preventDefault();
    dispatch(setProductSelection(products));

    const { shouldTriggerCallbackFlow, reasonCode } = dispatch(
      checkStoreStateForBusinessCallbackFlow(),
    );

    const canNavigateToCallbackPage =
      shouldTriggerCallbackFlow &&
      reasonCode !== CALLBACK_REASON_NO_RATES_FOR_SELECTED_TERM &&
      reasonCode !== CALLBACK_REASON_LPG_REQUOTE_PPD_PLANS;

    const path = canNavigateToCallbackPage
      ? appBusinessRoutes.callback
      : appBusinessRoutes.contactDetails;

    if (canNavigateToCallbackPage) {
      dispatch(setCallbackReferrerUrl(appBusinessRoutes.fuel));
    } else {
      dispatch(unsetCallbackReferrerUrl());
    }

    const selectedProductNames = products
      .filter(({ isSelected }) => isSelected)
      .map(({ displayName }) => displayName);

    trackBusinessContinueButton({
      event: "select_fuel",
      content_tertiary: "select fuel",
      selection: selectedProductNames.join(" + "),
    });

    router.push(path);
  };

  const toggleProduct = (productName) => {
    const updatedProducts = products.map((product) => {
      if (product.name === productName) {
        return {
          ...product,
          isSelected: product.isSelected ? false : true,
        };
      }
      return product;
    });
    updateProducts([...updatedProducts]);
  };

  const onProductClick = (productName) => {
    const selectedProducts = filter(products, ({ isSelected }) => isSelected);
    const selectedAvailableProducts = selectedProducts.filter(({ name }) =>
      availableProducts.some(
        (availableProduct) => name === availableProduct.name,
      ),
    );

    const isThereOnlyOneSelectedProduct =
      selectedAvailableProducts.length === 1;
    const selectedProduct = head(selectedAvailableProducts) || {};
    if (isThereOnlyOneSelectedProduct && selectedProduct.name === productName) {
      return;
    }
    toggleProduct(productName);
  };

  const isProductSelected = (productName) => {
    const productDetails = products.find(({ name }) => name === productName);
    return productDetails?.isSelected;
  };

  return (
    <motion.div
      initial="initial"
      animate="animate"
      exit="exit"
      variants={itemContainer}
      className="max-w-full px-4 mx-auto w-158 md:w-210"
    >
      {fuelOptions.map((product, index) => {
        const { title, subtitle, selectedIcon, defaultIcon, id } =
          product.fields;

        const fuelOption = availableProducts.find((item) => item.name === id);

        if (!fuelOption) {
          return null;
        }

        const isSelected = isProductSelected(id);
        return (
          <motion.div
            variants={itemFade}
            key={index}
            className="flex mb-5 last:mb-0"
          >
            <ButtonOnOffToggle
              isSelected={isSelected}
              onClick={() => onProductClick(id)}
              icon={isSelected ? selectedIcon : defaultIcon}
              subTitle={subtitle}
              title={title}
            />
          </motion.div>
        );
      })}
      <div className="mt-10 md:mt-15 lg:mt-20 overflow-hidden" />
      <StepProgressionButton
        onContinueClick={onContinueClick}
        backLink={backButtonUrl}
      />
    </motion.div>
  );
}
