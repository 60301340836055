import npaService from "services/NpaService";
import {
  SET_RESIDENTIAL_ELECTRICITY_OFFER,
  SET_RESIDENTIAL_GAS_OFFER,
  SET_RESIDENTIAL_LPG_OFFER,
  SET_RESIDENTIAL_OFFERS,
} from "./residentialOfferActionTypes";

function setResidentialElectricityOffer(payload) {
  return { type: SET_RESIDENTIAL_ELECTRICITY_OFFER, payload };
}

function setResidentialGasOffer(payload) {
  return { type: SET_RESIDENTIAL_GAS_OFFER, payload };
}


function setResidentialLpgOffer(payload) {
  return { type: SET_RESIDENTIAL_LPG_OFFER, payload };
}

function setResidentialOffersResult(payload) {
  return { type: SET_RESIDENTIAL_OFFERS, payload };
}

export function setResidentialOffers() {
  return async function setResidentialOffersWithDispatch(dispatch, getState) {
    const {
      icp: { elecIcp, elecRegistryAddress, gasIcp, gasRegistryAddress, lpgIcp },
    } = getState();

    const result = await npaService.findOffersByIcp({
      elecIcp,
      elecRegistryAddress,
      gasIcp,
      gasRegistryAddress,
      lpgIcp,
    });

    const {
      elecOffer,
      elecOffersResult: electricityOffersResult,
      gasOffer,
      gasOffersResult: naturalGasOffersResult,
      lpgOffer,
      lpgOffersResult: bottledGasOffersResult,
    } = result;

    const elecOfferAction = setResidentialElectricityOffer(elecOffer);
    dispatch(elecOfferAction);

    const gasOfferAction = setResidentialGasOffer(gasOffer);
    dispatch(gasOfferAction);

    const lpgOfferAction = setResidentialLpgOffer(lpgOffer);
    dispatch(lpgOfferAction);

    const setOffersResultAction = setResidentialOffersResult({
      electricityOffersResult,
      naturalGasOffersResult,
      bottledGasOffersResult,
    });
    dispatch(setOffersResultAction);

    return result;
  };
}
