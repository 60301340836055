import React, { useRef, useState } from "react";
import {
  arrow,
  flip,
  FloatingArrow,
  FloatingPortal,
  offset,
  safePolygon,
  shift,
  useClick,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
  useTransitionStyles,
} from "@floating-ui/react";
import classNames from "classnames";
import { tailWindConfig } from "getConfig";
import { InfoUnfilled } from "assets/icons";

function PlanCardTooltip({ testIdPrefix = "tooltip", children }) {
  const [isOpen, setIsOpen] = useState(false);
  const arrowRef = useRef(null);

  const { refs, floatingStyles, context } = useFloating({
    placement: "top",
    middleware: [
      offset(12),
      shift(),
      flip(),
      arrow({
        element: arrowRef,
        padding: 30,
      }),
    ],
    open: isOpen,
    onOpenChange: setIsOpen,
  });

  const hover = useHover(context, {
    handleClose: safePolygon(),
  });
  const focus = useFocus(context);
  const click = useClick(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([
    hover,
    focus,
    click,
  ]);

  const { isMounted, styles } = useTransitionStyles(context);

  const hideTooltip = () => setIsOpen(false);

  return (
    <>
      <button
        data-testid={`${testIdPrefix}_tooltipButton`}
        ref={refs.setReference}
        {...getReferenceProps()}
      >
        <InfoUnfilled className="size-3.5 text-blue-primary" />
      </button>
      {isMounted && (
        <FloatingPortal>
          <div
            ref={refs.setFloating}
            {...getFloatingProps()}
            style={{ ...floatingStyles, ...styles }}
            data-testid={`${testIdPrefix}_tooltipContent`}
          >
            <div className="px-4">
              <div
                className={classNames(
                  "shadow-400",
                  "w-66 rounded-3 p-3",
                  "flex flex-col gap-y-1",
                  "bg-black-primary",
                  "text-white",
                  "font-normal-plus",
                )}
              >
                {children}
                <div>
                  <button
                    className={classNames(
                      "text-orange-primary",
                      "text-13/20",
                      "focus:outline-none focus-visible:outline-none",
                      "md:hidden",
                    )}
                    onClick={hideTooltip}
                    data-testid={`${testIdPrefix}_tooltipCloseButton`}
                  >
                    Okay, got it
                  </button>
                </div>
              </div>
              <FloatingArrow
                ref={arrowRef}
                context={context}
                width={12}
                height={8}
                tipRadius={4}
                fill={tailWindConfig.theme.colors.black.primary}
              />
            </div>
          </div>
        </FloatingPortal>
      )}
    </>
  );
}

export { PlanCardTooltip };
