import { merge } from "lodash-es";

export function getPageData({ modules, page, defaultPageData = {} }) {
  if(!modules || !page) return defaultPageData;
  const lowerCasedModules = modules.map((item) => item.toLowerCase());
  const content = page.zones.Content;
  const pageModules = content
    .filter(({ moduleName }) =>
      lowerCasedModules.includes(moduleName?.toLowerCase()),
    )
    .map((moduleInfo) => moduleInfo?.item)
    .filter(Boolean);

  const pageModulesCombined = merge({}, defaultPageData, ...pageModules);

  return pageModulesCombined;
}
