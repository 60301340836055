import classNames from "classnames";
import { Button } from "components/Button";
import { VARIANT } from "helpers/constants";
import { DocumentItem } from "page-modules/DocumentItem";

function DocumentFilterResult({
  paginationText,
  filteredDocuments,
  showLoadMoreButton,
  loadMoreDocuments,
}) {
  return (
    <>
      <p
        className={classNames(
          "mb-5 font-medium text-18/28 text-black-primary",
          "md:mt-8 lg:mt-10",
        )}
        data-testid="paginationText"
      >
        {paginationText}
      </p>
      <div className="space-y-2" data-testid="documentList">
        {filteredDocuments.map((documentItem) => (
          <DocumentItem key={documentItem.contentID} data={documentItem} />
        ))}
      </div>
      {showLoadMoreButton && (
        <div
          className={classNames(
            "flex flex-col items-center",
            "pb-5 my-10 space-y-5",
            "md:pb-0 md:my-15",
          )}
        >
          <Button variant={VARIANT.dark} onClick={loadMoreDocuments}>
            Load more results
          </Button>
          <p className="text-black-300 text-13/16 md:text-14/20">
            {paginationText}
          </p>
        </div>
      )}
    </>
  );
}

export { DocumentFilterResult };
