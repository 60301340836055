import StepNavigationItem from "join-form/components/step-navigation-item";
import {
  ADDRESS_TYPE_REGISTRY,
  appBusinessRoutes,
  CALLBACK_REASON_MULTI_CONNECT,
} from "helpers/constants";
import { getProductSelectionTitle } from "helpers/productHelper";
import { useRouter } from "next/router";
import { connect } from "react-redux";

const {
  businessDetails,
  address,
  addressIcp,
  addressManual,
  fuel,
  success,
  callbackSuccess,
  ...allContactDetailsRouteInfo
} = appBusinessRoutes;
const contactDetailPageRoutes = Object.values(allContactDetailsRouteInfo);

function FuelItem({ shouldRender, title, changeLinkUrl, ...rest }) {
  const router = useRouter();

  const isVisible = contactDetailPageRoutes.includes(router.asPath);

  if (!isVisible) {
    return null;
  }

  return (
    shouldRender && (
      <StepNavigationItem
        title={title}
        path={changeLinkUrl}
        fluidWidth={true}
        {...rest}
      />
    )
  );
}

const mapStateToProps = (props, ownProps) => {
  const {
    addressState: { addressType },
    appState: { products },
    appUserState: { callbackReason },
  } = props;

  const selectedProductNames = products
    .filter(({ isSelected }) => isSelected)
    .map(({ name }) => name);

  const title = getProductSelectionTitle(selectedProductNames);

  const changeLinkUrl =
    addressType === ADDRESS_TYPE_REGISTRY
      ? appBusinessRoutes.addressIcp
      : appBusinessRoutes.fuel;

  const shouldRender = callbackReason !== CALLBACK_REASON_MULTI_CONNECT;

  return { title, changeLinkUrl, shouldRender };
};

export default connect(mapStateToProps)(FuelItem);
