import classNames from "classnames";
import { ArrowLeft } from "assets/icons";

// % change label in red/green
export const PercentChangeLabel = ({ movePercent }) => {
  const variantStyles = {
    text:
      movePercent < 0
        ? "text-extra-red bg-red-100"
        : "text-extra-success-text bg-green-100",
    icon: movePercent < 0 ? "-rotate-90" : "rotate-90",
  };

  return (
    <span
      className={classNames(
        "relative inline-block px-1 pr-5 rounded-1 text-12/20",
        variantStyles.text,
      )}
    >
      {`${movePercent}%`}
      <ArrowLeft
        className={classNames(
          "absolute w-3 h-3 fill-current right-1 top-1",
          variantStyles.icon,
        )}
      />
    </span>
  );
};
