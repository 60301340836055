import { useState } from "react";
import classNames from "classnames";
import Accordion from "join-form/components/accordion";

export default function EnergyRateContainer(props) {
  const {
    icon,
    title,
    children,
    visible,
    onToggle,
    highlightOnMobile,
    ...rest
  } = props;

  const [ratesVisibleState, toggleRatesState] = useState(true);
  const isControlledComponent = !!onToggle;

  return (
    <Accordion
      title={title}
      icon={icon}
      isOpen={isControlledComponent ? visible : ratesVisibleState}
      onClick={() =>
        isControlledComponent
          ? onToggle(!visible)
          : toggleRatesState(!ratesVisibleState)
      }
      className={classNames("px-4 py-5", "bg-black-50", "rounded-5", {
        "bg-orange-50 md:bg-black-50": highlightOnMobile,
      })}
      {...rest}
    >
      {children}
    </Accordion>
  );
}
