import FeatureItem from "join-form/components/feature-item";
import {
  PLAN_TYPE_BASIC,
  RESIDENTIAL_PLAN_TERM_FLEXI,
} from "helpers/constants";

function getCreditText({ credit }) {
  const creditExists = credit !== "";

  const displayText = creditExists ? `${credit} fixed-term credit` : "";

  return displayText;
}

export default function PlusPlanCredit(props) {
  const { termItem, products, selectedPlanType, ...rest } = props;

  const { codeName, credit, isSelected } = termItem;

  if (isSelected === false) {
    return null;
  }

  if (selectedPlanType === PLAN_TYPE_BASIC) {
    return null;
  }

  if (codeName !== RESIDENTIAL_PLAN_TERM_FLEXI) {
    if (!credit) {
      return null;
    }

    const displayText = getCreditText({ credit });

    return (
      <FeatureItem type="complete" {...rest}>
        {displayText}
      </FeatureItem>
    );
  }

  return (
    <FeatureItem type="complete" {...rest}>
      Flexible, no credit offer
    </FeatureItem>
  );
}
