import { motion } from "framer-motion";
import { Alert } from "join-form/components/alert";
import {
  DateInput,
  Dropdown,
  FormFieldTerm,
  Input,
} from "join-form/components/form-controls";
import { InlineBlockEditor } from "components/BlockEditor";
import { itemContainer, itemFade } from "./animations";
import styles from "./contact-details-page.module.scss";

function ExistingCustomerContactDetailsForm(props) {
  const { values, touched, errors, handleChange, pageData } = props;

  return (
    <>
      <motion.div
        initial="initial"
        animate="animate"
        exit="exit"
        variants={itemContainer}
      >
        <Alert
          fields={{
            copy: pageData.fields.existingCustomerInfo,
          }}
        />
        <motion.div variants={itemFade} className="mt-10 md:mt-15">
          <div className="w-full">
            <Input
              name="customerNumber"
              placeholder={pageData.fields.existingCustomerNumberLabel}
              value={values.customerNumber}
              onChange={handleChange}
              errorMessage={errors.customerNumber}
              showError={errors.customerNumber && touched.customerNumber}
            />
          </div>

          <p className="mb-5 text-15/24 md:text-18/28">
            {pageData.fields.existingCustomerFieldsInfo}
          </p>
          <div className={styles.row}>
            <div className={`${styles.column} ${styles.title_field_container}`}>
              <Dropdown
                name="title"
                placeholder={pageData.fields.titleLabel}
                value={values.title}
                options={[
                  { value: "MRS", name: "Mrs" },
                  { value: "MISS", name: "Miss" },
                  { value: "MR", name: "Mr" },
                  { value: "DR", name: "Dr" },
                  { value: "MS", name: "Ms" },
                  { value: "REV", name: "Rev" },
                ]}
                onChange={handleChange}
                errorMessage={errors.title}
                showError={errors.title && touched.title}
              />
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.column}>
              <Input
                name="firstName"
                placeholder={pageData.fields.firstNameLabel}
                value={values.firstName}
                onChange={handleChange}
                errorMessage={errors.firstName}
                showError={errors.firstName && touched.firstName}
              />
            </div>
            <div className={styles.column}>
              <Input
                name="middleName"
                placeholder={pageData.fields.middleNameLabel}
                value={values.middleName}
                onChange={handleChange}
                errorMessage={errors.middleName}
                showError={errors.middleName && touched.middleName}
              />
            </div>
            <div className={styles.column}>
              <Input
                name="lastName"
                placeholder={pageData.fields.lastNameLabel}
                value={values.lastName}
                onChange={handleChange}
                errorMessage={errors.lastName}
                showError={errors.lastName && touched.lastName}
              />
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.column}>
              <DateInput
                name="dob"
                label={pageData.fields.existingCustomerDateOfBirthLabel}
                placeholder={pageData.fields.existingCustomerDateOfBirthLabel}
                value={values.dob}
                onChange={handleChange}
                errorMessage={errors.dob}
                showError={errors.dob && touched.dob}
              />
            </div>
            <div className={styles.column}></div>
          </div>

          <p className="mb-5 text-15/24 md:text-18/28">
            {pageData.fields.existingCustomerContactDetailsInfo}
          </p>
          <div className={styles.row}>
            <div className={styles.column}>
              <Input
                name="email"
                placeholder={pageData.fields.emailLabel}
                value={values.email}
                onChange={handleChange}
                errorMessage={errors.email}
                showError={errors.email && touched.email}
              />
            </div>
            <div className={styles.column}>
              <Input
                name="phone"
                placeholder={pageData.fields.phoneLabel}
                value={values.phone}
                onChange={handleChange}
                errorMessage={errors.phone}
                showError={errors.phone && touched.phone}
              />
            </div>
          </div>
        </motion.div>
      </motion.div>
      <motion.div className={styles.terms_container}>
        <FormFieldTerm
          name="generalTermsForExistingCustomers"
          testId="generalTermsForExistingCustomers"
          value={values.generalTermsForExistingCustomers}
          checked={values.generalTermsForExistingCustomers}
          onChange={handleChange}
          size="small"
          errorMessage={errors.generalTermsForExistingCustomers}
          showError={
            errors.generalTermsForExistingCustomers &&
            touched.generalTermsForExistingCustomers
          }
          textSize="small"
        >
          <InlineBlockEditor
            jsonString={pageData.fields.existingCustomerTerms}
            enableProse={false}
          />
        </FormFieldTerm>
      </motion.div>
    </>
  );
}

export default ExistingCustomerContactDetailsForm;
