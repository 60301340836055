import classNames from "classnames";
import { useRouter } from "next/router";
import { appBusinessRoutes, appResidentialRoutes } from "helpers/constants";

const fullWidthPages = [
  appResidentialRoutes.success,
  appBusinessRoutes.success,
  appResidentialRoutes.callbackSuccess,
  appBusinessRoutes.callbackSuccess,
];

 function PageLayout({ children }) {
  const { asPath } = useRouter();

  const isFullWidth = fullWidthPages.includes(asPath);

  const pageLayoutClass = classNames({ "npa-app-wrapper": !isFullWidth });

   return (
    <div id="npa-app" className={pageLayoutClass}>
      {children}
    </div>
  );
}
export { PageLayout };
