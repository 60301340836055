import classNames from "classnames";
import { Image } from "components/Image";
import { getContentWithLineBreaks } from "helpers/htmlHelper";
import { FadeInBottomAnimation } from "./animations";
import { CardItemCta } from "./CardItemCta";

function CardItem({ isEven, item, className: classNameProp }) {
  const { title, image, description, cta } = item.fields;
  return (
    <FadeInBottomAnimation className="flex flex-col md:flex-col md:flex-1 sm:flex-row sm:min-h-60">
      <div
        data-testid="cardItem"
        className={classNames(
          "rounded-5 overflow-hidden bg-white",
          "transition duration-200 ease-in-out",
          "hover:delay-200 active:delay-200 focus-within:delay-200",
          "shadow-100 hover:shadow-600 focus-within:shadow-600",
          "md:flex-1",
          "md:hover:-translate-y-3 md:focus-within:-translate-y-3",
          classNameProp,
        )}
      >
        {image ? (
          <div
            data-testid="cardItemImage"
            className={classNames(
              "card-image-mask-to-bottom",
              "sm:flex-1",
              {
                "sm:card-image-mask-to-right-even": isEven,
                "sm:card-image-mask-to-right-odd": !isEven,
              },
              "md:flex-grow-0",
              {
                "md:card-image-mask-to-bottom-even": isEven,
                "md:card-image-mask-to-bottom-odd": !isEven,
              },
            )}
          >
            <div
              className={classNames(
                "hidden w-full h-full bg-center bg-no-repeat bg-cover",
                "sm:block",
                "md:hidden",
              )}
              style={{
                backgroundImage: `url("${image.url}")`,
              }}
            />
            <div className="max-h-75 md:h-full sm:hidden md:block">
              <Image
                src={image.url}
                alt={image.label}
                layout="responsive"
                width={image.width}
                height={image.height}
                className="!mb-1 md:!mb-0"
              />
            </div>
          </div>
        ) : null}
        <div
          className={classNames(
            "pt-10 px-[8%] pb-10 flex flex-col flex-1",
            "sm:p-5 sm:justify-center",
            "md:pt-10 md:px-7.5 md:pb-17 md:justify-start md:items-center",
          )}
        >
          <div
            className={classNames(
              "text-center text-black-primary",
              "sm:text-left",
              "md:flex md:flex-col md:flex-1 md:items-center md:text-center",
            )}
          >
            <h4
              className={classNames(
                "text-20/24 font-bold mb-3",
                "md:mb-5 md:text-24/28",
              )}
            >
              {title}
            </h4>
            <p
              data-testid="cardItemDescription"
              className={classNames(
                "text-15/24 mb-5 last:mb-0",
                "md:text-18/28",
              )}
            >
              {getContentWithLineBreaks(description)}
            </p>
            <CardItemCta data={cta} />
          </div>
        </div>
      </div>
    </FadeInBottomAnimation>
  );
}

export { CardItem };
