import classNames from "classnames";
import { Image } from "components/Image";
import { FadeInBottomImageAnimation } from "./animations";

function HeaderImage({ image, isCentered }) {
  return (
    <FadeInBottomImageAnimation>
      <div
        data-testid="imageContainer"
        className={classNames(
          "max-w-153 shadow-white-glow rounded-7 md:rounded-10 overflow-hidden md:max-w-200",
          {
            "mx-auto": isCentered,
          },
        )}
      >
        <Image
          alt={image.label}
          src={image.url}
          width={image.width}
          height={image.height}
          layout="responsive"
        />
      </div>
    </FadeInBottomImageAnimation>
  );
}

export { HeaderImage };
