/* istanbul ignore file */
import classNames from "classnames";
import { motion } from "framer-motion";
import { tailWindConfig } from "getConfig";
import { Autoplay } from "swiper";
import "swiper/css/bundle";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pause } from "assets/icons";

let {
  screens: { xl },
} = tailWindConfig.theme;
xl = parseInt(xl, 10); // '1680px' => 1680

// carousel config
const config = {
  allowTouchMove: false,
  autoplay: {
    delay: 8000,
    disableOnInteraction: false,
    pauseOnMouseEnter: true,
  },
  breakpoints: {
    [xl]: {
      slidesOffsetAfter: 184,
      slidesOffsetBefore: 184,
    },
  },
  className: "fact-go-round",
  modules: [Autoplay],
  slidesOffsetBefore: 80,
  slidesPerView: "auto",
  slideToClickedSlide: true,
};

export const Slide = ({ data, index }) => (
  <div className="cursor-nwse-resize" data-index={index}>
    <motion.div
      animate={{ opacity: 1, y: 0 }}
      initial={{ opacity: 0, y: 20 }}
      transition={{ delay: index * 0.2 }}
      className="px-4 py-5 mb-32 bg-white md:mt-10 mt-7 text-20/24 shadow-200 rounded-5"
    >
      <p className="font-medium text-black-primary">
        {index + 1}. {data.greytext}{" "}
        <span className="text-warm-orange-primary" data-ellipsis-text>
          {data.orangetext.split(" ", 3).join(" ")}...{" "}
          <a className="underline">more</a>
        </span>
        <span className="hidden text-warm-orange-primary" data-full-text>
          {data.orangetext}
        </span>
      </p>

      <div className="flex items-center mt-7">
        <div
          className="hidden mr-3 text-warm-orange-primary text-12/20"
          data-pause
        >
          <Pause className="inline-block w-5 h-5 mr-1" />
          Paused
        </div>
        <div
          className="flex w-10 h-2 overflow-hidden rounded bg-black-50"
          data-fact-loader
        >
          <div className="rounded bg-orange-primary"></div>
        </div>
      </div>
    </motion.div>
  </div>
);

export const Carousel = ({ factList }) => {
  return (
    <div className="mb-7 md:mb-10 min-h-125" data-testid="fact-go-round">
      <Swiper {...config}>
        {factList.map((item, index) => (
          <SwiperSlide
            key={`slide-key-${index}`}
            className={classNames(
              "!w-[calc(100%-160px)] last:mr-[80px]",
              "sm:!w-[calc(calc(100%-160px)/2)] last:sm:mr-[calc(calc(100%-160px)/2)]",
              "md:!w-[calc(calc(100%-160px)/3)] last:md:mr-[calc(calc(calc(100%-160px)/3)*2)]",
              "lg:!w-[calc(calc(100%-160px)/4)] last:lg:mr-[calc(calc(calc(100%-160px)/4)*3)]",
              "xl:!w-[calc(calc(100%-368px)/4)] last:xl:mr-[calc(calc(calc(100%-368px)/4)*3)]",
            )}
          >
            <Slide data={item.fields} index={index} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};
