function PagePreview({ isPreview, isDevelopmentMode }) {
  if (isDevelopmentMode || !isPreview) {
    return null;
  }

  function onClick(e) {
    e.preventDefault();
    const confirmed = confirm("Would you like to exit Preview Mode?");
    if (confirmed) {
      window.location.assign(
        `/api/exitPreview?slug=${window.location.pathname}`,
      );
    }
  }

  return (
    <div data-testid="pagePreviewButton" className="fixed bottom-4 right-4">
      <button
        onClick={onClick}
        className="px-4 py-2 text-white bg-orange-primary rounded-full"
      >
        Exit Preview
      </button>
    </div>
  );
}

export { PagePreview };
