import StepNavigationItem from "join-form/components/step-navigation-item";
import { appResidentialRoutes, PLAN_TYPE_EV_PLUS } from "helpers/constants";
import { useSelector } from "react-redux";
import { usePathWithoutQuery } from "hooks";

const {
  success,
  address,
  addressIcp,
  addressManual,
  fuel,
  occupancy,
  planSelect,
  termSelect,
  callback,
  callbackSuccess,
  carRegistration: carRegistrationRoute,
  ...allResidentialRoutes
} = appResidentialRoutes;
const validRoutes = Object.values(allResidentialRoutes);

export default function CarRegistrationItem(props) {
  const { planType, plate } = useSelector(({ appState, carRegistration }) => ({
    planType: appState.planType,
    plate: carRegistration.plate,
  }));
  const pathWithoutQuery = usePathWithoutQuery();
  const isVisible = validRoutes.includes(pathWithoutQuery);

  if (planType !== PLAN_TYPE_EV_PLUS) {
    return null;
  }

  if (!plate) {
    return null;
  }

  return isVisible ? (
    <StepNavigationItem title={plate} path={carRegistrationRoute} {...props} />
  ) : null;
}
