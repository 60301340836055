import { motion } from "framer-motion";
import { Link } from "components/Link";
import styles from "./step-navigation-item.module.scss";

const variants = {
  visible: {
    opacity: 1,
    transition: {
      delay: 0.2,
    },
  },
  hidden: {
    opacity: 0,
  },
};

const transition = {
  duration: 0.2,
};

export default function StepNavigationItem(props) {
  const { title, path, fluidWidth = false, ...rest } = props;

  const fluidWidthClassName = fluidWidth ? `${styles.item_fluid}` : "";

  return (
    <motion.div
      initial="hidden"
      animate="visible"
      exit="hidden"
      variants={variants}
      transition={transition}
      className={`${styles.item} ${fluidWidthClassName}`}
      {...rest}
    >
      <h4 className={styles.title}>{title}</h4>
      {path && (
        <Link href={path} data-testid="changeLink">
          <a className={styles.link}>Change</a>
        </Link>
      )}
    </motion.div>
  );
}
