import classNames from "classnames";

const RadioButton = ({ label, value, checked, register, name }) => {
  const styles = checked ? "bg-orange-primary" : "bg-transparent";

  return (
    <label
      className={classNames(
        "flex items-center cursor-pointer transition-all bg-white",
        "px-5 py-4 md:py-5",
        "text-black-primary text-14/20 md:text-16/24",
        "border border-black-100 rounded-2",
        "gap-3",
        "hover:shadow-4/14/15 active:shadow-4/14/15",
      )}
    >
      <input
        name={label}
        id={label}
        placeholder={label}
        type="radio"
        value={value}
        className="hidden"
        {...register(name)}
      />
      <span
        className={classNames(
          "flex justify-center items-center w-6 h-6",
          "rounded-full border-2 border-orange-primary",
        )}
      >
        <span
          className={classNames("h-4 w-4 rounded-full", styles)}
          data-testid="radioButton"
        />
      </span>
      <span className="text-15/24 text-black-primary md:text-18/28">
        {label}
      </span>
    </label>
  );
};

export { RadioButton };
