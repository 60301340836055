import Axios from "axios";

const axiosOptions = {
  baseURL: "/api/zendesk",
  auth: {
    username: process.env.NEXT_PUBLIC_AUTH_USER_NAME,
    password: process.env.NEXT_PUBLIC_AUTH_PASSWORD,
  },
};

export async function createTicket(ticket) {
  try {
    const { data } = await Axios.post("/createticket", ticket, axiosOptions);
    return data.ticket.id;
  } catch (ex) {
    return "";
  }
}

export async function updateTicket(ticket) {
  try {
    return await Axios.put("/updateticket", ticket, axiosOptions);
  } catch (ex) {
    return null;
  }
}
