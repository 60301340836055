import { AnimatePresence, motion } from "framer-motion";
import { ButtonOnOffToggle } from "../ButtonOnOffToggle";
import styles from "./conditional-form-with-button-toggle.module.scss";

export function ConditionalFormWithButtonToggle(props) {
  const {
    title,
    onClick,
    isSelected = false,
    initialHeight = 0,
    children,
    icon = "",
  } = props;
  const activeClass = isSelected ? styles.active : "";

  return (
    <div
      className={`${styles.item} ${activeClass}`}
      data-testid="conditionalFormWithButtonToggle"
    >
      <ButtonOnOffToggle
        isSelected={isSelected}
        onClick={onClick}
        icon={icon}
        disableShadowStylesOnActive
        data-testid="buttonOnOffToggle"
        title={title}
      />
      <AnimatePresence>
        {isSelected && (
          <motion.div
            initial={{ height: initialHeight, opacity: 0 }}
            animate={{ height: "auto", opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            transition={{ type: "tween" }}
            className={styles.item_body}
            data-testid="conditionalFormBody"
          >
            <div className={styles.item_form}>{children}</div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}
