import classNames from "classnames";
import styles from "./intro.module.scss";

export default function Intro({ children, className, noStyle }) {
  const defaultStyles = noStyle ? null : styles.intro;
  const classes = classNames(defaultStyles, className);
  return (
    <div className={classes} data-testid="intro">
      {children(styles)}
    </div>
  );
}
