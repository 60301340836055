import styles from "./button-radio-selection.module.scss";

// Prop size expects values: small, medium, large
export function ButtonRadioSelection(props) {
  const {
    children,
    onClick,
    isSelected = false,
    subTitle = "",
    className,
    size,
    ...rest
  } = props;

  const activeClass = isSelected ? styles.active : "";

  return (
    <button
      className={`${styles.button} ${styles[size]} ${className} ${activeClass}`}
      onClick={onClick}
      {...rest}
    >
      <div className={styles.iconContainer}>
        <div className={styles.icon}></div>
      </div>
      {subTitle ? (
        <div className="text-left">
          <div className="text-18/28 md:text-24/36 font-medium text-black-primary">
            {children}
          </div>
          <div className="text-15/24 md:text-18/28 text-orange-primary mt-1">
            {subTitle}
          </div>
        </div>
      ) : (
        <div className="text-18/28 md:text-24/36 font-medium text-black-primary">
          {children}
        </div>
      )}
    </button>
  );
}
