import classNames from "classnames";
import { InlineBlockEditor } from "components/BlockEditor";
import { FormStack, getFormName } from "components/FormStack";

function InPageForm({ module }) {
  const { anchor, title, subtitle, description, form } = module.fields;
  const formName = getFormName(form);
  return (
    formName && (
      <div id={anchor} data-testid="inPageForm" data-psnid="psn_in_page_form">
        <div className="my-15 md:mb-25 md:px-17 lg:px-23 xl:px-46">
          <div className="lg:grid lg:grid-cols-24 lg:gap-8">
            <div className="lg:col-start-2 lg:col-span-22">
              <div
                className={classNames(
                  "flex flex-col",
                  "bg-white",
                  "py-15",
                  "rounded-7 md:rounded-10",
                )}
              >
                <div
                  className={classNames(
                    "flex flex-col",
                    "px-4 sm:px-15 md:px-10 lg:px-13",
                    "gap-y-10 md:gap-y-15",
                    "mx-auto md:mx-0",
                    "max-w-full w-120 sm:w-210 md:w-auto",
                  )}
                >
                  <div className="space-y-3 text-black-primary max-w-200">
                    <h2 className="font-bold text-32/36 md:text-44/52 text-dark-primary">
                      {title}
                    </h2>
                    {subtitle && (
                      <h3
                        data-testid="inPageFormSubTitle"
                        className="text-20/32 md:text-24/36"
                      >
                        {subtitle}
                      </h3>
                    )}
                    {description && (
                      <InlineBlockEditor
                        testid="inPageFormDescription"
                        jsonString={description}
                      />
                    )}
                  </div>
                  <FormStack
                    className="fsInPageForm"
                    formName={formName}
                    module={module}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
}

export { InPageForm };
