import { Close, InfoUnfilled, Success, Warning } from "assets/icons";
import { cn } from "helpers/classNames";
import { AlertCta } from "./AlertCta";

const iconClassNames = "w-5 h-5 fill-current md:w-6 md:h-6";
const themes = {
  info: {
    testId: "info",
    backgroundColor: "bg-blue-100",
    borderColor: "border-blue-primary",
    iconColor: "text-blue-primary",
    icon: <InfoUnfilled className={iconClassNames} />,
  },
  error: {
    testId: "error",
    backgroundColor: "bg-red-100",
    borderColor: "border-red-primary",
    iconColor: "text-red-primary",
    icon: <Close className={iconClassNames} />,
  },
  success: {
    testId: "success",
    backgroundColor: "bg-green-100",
    borderColor: "border-green-500",
    iconColor: "text-green-500",
    icon: <Success className={iconClassNames} />,
  },
  warning: {
    testId: "warning",
    backgroundColor: "bg-warm-orange-100",
    borderColor: "border-warm-orange-primary",
    iconColor: "text-warm-orange-primary",
    icon: <Warning className={iconClassNames} />,
  },
};

function Alert({
  fields,
  className,
  contentContainerClassName,
  ctaClassName,
  type = "info",
  CtaJsx = null,
}) {
  const { title, copy, url } = fields;
  const theme = themes[type] ?? themes.info;
  const { testId, backgroundColor, borderColor, iconColor } = theme;

  return (
    <div
      role="alert"
      data-testid={testId}
      className={cn(
        "flex flex-wrap justify-between items-center",
        "p-4 border-2 rounded-4 text-black-primary",
        "gap-y-3 gap-x-2",
        "md:p-7 md:rounded-5",
        "md:gap-y-0 md:gap-x-9",
        backgroundColor,
        borderColor,
        className,
      )}
    >
      <div
        className={cn("flex items-center md:flex-1", contentContainerClassName)}
      >
        <div className={cn("mr-4", iconColor)}>{theme.icon}</div>
        <div className="text-15/24 md:text-18/28">
          {title ? <h4 className="mb-1 font-medium">{title}</h4> : null}
          <p className="mb-1">{copy}</p>
        </div>
      </div>
      {CtaJsx ?? <AlertCta className={ctaClassName} url={url} />}
    </div>
  );
}

export { Alert };
