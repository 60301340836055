import React from "react";
import { RatesHeading } from "join-form/components/energy-rate";
import {
  PLAN_TYPE_BASIC,
  PLAN_TYPE_EV_PLUS,
  PLAN_TYPE_PLUS,
} from "helpers/constants";
import BottledGasRates from "join-form/partials/rates/residential/BottledGasRates";
import ElectricityRates from "join-form/partials/rates/residential/ElectricityRates";
import NaturalGasRates from "join-form/partials/rates/residential/NaturalGasRates";

const planTypeList = [PLAN_TYPE_BASIC, PLAN_TYPE_PLUS, PLAN_TYPE_EV_PLUS];

function RatesGrid({ planUsageType }) {
  const [inclGst, toggleInclGst] = React.useState(true);
  const [showElec, toggleElec] = React.useState(true);
  const [showGas, toggleGas] = React.useState(true);
  const [showLpg, toggleLpg] = React.useState(true);

  return (
    <div className="px-4 md:px-16 min-w-220 md:min-w-236">
      <div className="grid grid-cols-3 gap-x-2 gap-y-1">
        {planTypeList.map((planType) => (
          <RatesHeading
            key={planType}
            planType={planType}
            title="Your energy rates"
            inclGst={inclGst}
            setInclGst={toggleInclGst}
          />
        ))}
        {planTypeList.map((planType) => (
          <ElectricityRates
            key={planType}
            planType={planType}
            usageType={planUsageType}
            inclGst={inclGst}
            visible={showElec}
            onToggle={toggleElec}
          />
        ))}
        {planTypeList.map((planType) => (
          <NaturalGasRates
            key={planType}
            planType={planType}
            usageType={planUsageType}
            inclGst={inclGst}
            visible={showGas}
            onToggle={toggleGas}
            highlightOnMobile={false}
          />
        ))}
        {planTypeList.map((planType) => (
          <BottledGasRates
            key={planType}
            planType={planType}
            usageType={planUsageType}
            inclGst={inclGst}
            visible={showLpg}
            onToggle={toggleLpg}
            highlightOnMobile={false}
          />
        ))}
      </div>
    </div>
  );
}

export { RatesGrid };
