import classNames from "classnames";
import { sortBy } from "lodash-es";
import { ModuleWrapper } from "components/Wrappers";
import {
  SnippetContainerAnimation,
  VideoCardContainerAnimation,
} from "./animations";
import { Snippet } from "./Snippet";
import { VideoCard } from "./VideoCard";

function SingleVideoCard({ module }) {
  const {
    fields: { anchor, videoURL, videoalignment, textoverlay, snippet, image },
  } = module;

  const sortedSnippetContent = sortBy(snippet, ["properties.itemOrder"]);
  const isRightAlignment = videoalignment === "right";
  const videoId = videoURL.split("v=")[1];

  return (
    <ModuleWrapper psnid="psn_single_video_card">
      <div
        id={anchor}
        className={classNames(
          "space-y-10",
          "md:space-y-0 md:grid md:grid-cols-24 md:gap-7 lg:gap-8",
        )}
      >
        <div
          className={classNames("md:col-span-14", {
            "md:order-2 md:col-start-11": isRightAlignment,
          })}
        >
          <VideoCardContainerAnimation>
            <VideoCard
              image={image}
              videoId={videoId}
              textoverlay={textoverlay}
            />
          </VideoCardContainerAnimation>
        </div>
        <div
          className={classNames(
            "md:flex md:flex-col md:col-span-9",
            { "md:col-start-16": !isRightAlignment },
            { "md:col-start-1": isRightAlignment },
          )}
        >
          <SnippetContainerAnimation>
            {sortedSnippetContent.map((item, index) => (
              <Snippet
                content={item}
                key={index}
                isFirstItem={index === 0}
                isSnippetLeftAligned={isRightAlignment}
              />
            ))}
          </SnippetContainerAnimation>
        </div>
      </div>
    </ModuleWrapper>
  );
}

export { SingleVideoCard };
