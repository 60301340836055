import InputMask from "react-input-mask";
import styles from "./form-controls.module.scss";

export default function MaskedInput(props) {
  const {
    size,
    type = "text",
    name,
    placeholder,
    value,
    onChange,
    errorMessage,
    mask,
    showError,
  } = props;

  const filledClassName = value ? styles.filled : "";
  const invalidClassName = showError ? styles.error : "";
  const sizeClassName = size ? styles.size28 : "";

  const className = [
    styles.control,
    filledClassName,
    invalidClassName,
    sizeClassName,
  ]
    .join(" ")
    .trim();

  return (
    <div className={className}>
      <label className={styles.label} htmlFor={name}>
        {placeholder}
      </label>
      <InputMask
        className={styles.input}
        name={name}
        id={name}
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        mask={mask}
        maskChar={null}
      />
      {showError && (
        <span data-testid={`${name}ErrorMessage`} className={styles.errorText}>
          {errorMessage}
        </span>
      )}
    </div>
  );
}
