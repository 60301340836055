import { useState } from "react";
import { createSelector } from "@reduxjs/toolkit";
import { setPlanTerm } from "actions/businessPlanConfigActions";
import {
  checkStoreStateForBusinessCallbackFlow,
  setCallbackReferrerUrl,
  unsetCallbackReferrerUrl,
} from "actions/callbackPageActions";
import { AnimatePresence, motion } from "framer-motion";
import { useRouter } from "next/router";
import { useDispatch, useSelector } from "react-redux";
import {
  selectBusinessPlanConfig,
  selectBusinessState,
} from "reducers/selector";
import { InlineBlockEditor } from "components/BlockEditor";
import { ButtonRadioSelection } from "components/ButtonRadioSelection";
import { Feedback } from "components/Feedback";
import { StepProgressionButton } from "components/StepProgressionButton";
import {
  appBusinessRoutes,
  BUSINESS_TYPE_SOLE_TRADER,
  CALLBACK_REASON_LPG_REQUOTE_PPD_PLANS,
} from "helpers/constants";
import { getPageData } from "helpers/getPageData";
import { trackBusinessContinueButton } from "helpers/gtmHelper";
import { IntroHeader } from "page-modules/Join/IntroHeader";
import {
  footerFade,
  footerFadeTransition,
  heightFadeTransition,
  heightFadeVariants,
  itemContainer,
  itemFade,
} from "./animations";
import styles from "./business-term-select-page.module.scss";

const modules = ["ge_join_intro_header", "ge_join_business_term_select"];

const selectBusinessTermSelectState = createSelector(
  [selectBusinessState, selectBusinessPlanConfig],
  (businessState, businessPlanConfig) => {
    const { businessType } = businessState;
    const { termData } = businessPlanConfig;
    const termItem = termData.find(({ isSelected }) => isSelected);
    const isValid = !!termItem;

    return {
      termData,
      termItem,
      isValid,
      businessType,
    };
  },
);

export function BusinessTermSelectTemplate(props) {
  const pageData = getPageData({ modules, page: props.page });
  const {
    fields: { blockEditor, title, termOptions, finePrint },
  } = pageData;
  const { isValid, termData, businessType, termItem } = useSelector(
    selectBusinessTermSelectState,
  );

  const dispatch = useDispatch();
  const router = useRouter();

  const [errorMessage, setErrorMessage] = useState(null);

  const onTermClick = (termCodeName) => {
    dispatch(setPlanTerm(termCodeName));
    if (errorMessage) {
      setErrorMessage(null);
    }
  };

  const isTermSelected = (termCodeName) => {
    const termDetails = termData.find(
      ({ codeName }) => codeName === termCodeName,
    );
    return termDetails?.isSelected;
  };

  const onContinueClick = (e) => {
    e.preventDefault();

    if (isValid === false) {
      setErrorMessage("Please select a term");
      return;
    }

    trackBusinessContinueButton(
      {
        event: "select_plan",
        content_tertiary: "select plan",
        selection: "business energy",
      },
      "automated",
    );

    trackBusinessContinueButton({
      event: "select_term",
      content_tertiary: "select plan",
      selection: termItem.displayName,
    });

    const { shouldTriggerCallbackFlow, reasonCode } = dispatch(
      checkStoreStateForBusinessCallbackFlow(),
    );

    const canNavigateToCallbackPage =
      shouldTriggerCallbackFlow &&
      reasonCode !== CALLBACK_REASON_LPG_REQUOTE_PPD_PLANS;

    if (canNavigateToCallbackPage) {
      dispatch(setCallbackReferrerUrl(appBusinessRoutes.termSelect));
      router.push(appBusinessRoutes.callback);
      return;
    }

    const nextPageRoute =
      businessType === BUSINESS_TYPE_SOLE_TRADER
        ? appBusinessRoutes.creditCheck
        : appBusinessRoutes.property;

    dispatch(unsetCallbackReferrerUrl());
    router.push(nextPageRoute);
  };

  return (
    <motion.div
      className={styles.container}
      exit="undefined"
      data-testid="termSelectPage"
    >
      <IntroHeader
        module={{
          fields: {
            title,
            blockEditor,
          },
        }}
      />
      <motion.div
        initial="initial"
        animate="animate"
        exit="exit"
        variants={itemContainer}
        className={styles.body}
      >
        {termOptions.map((option) => {
          const { id, title, subtitle } = option.fields;

          return (
            <motion.div
              variants={itemFade}
              className={styles.selection_item}
              key={id}
            >
              <ButtonRadioSelection
                onClick={() => onTermClick(id)}
                isSelected={isTermSelected(id)}
                subTitle={subtitle}
                size="large"
              >
                {title}
              </ButtonRadioSelection>
            </motion.div>
          );
        })}
      </motion.div>
      <motion.div
        initial="initial"
        animate="animate"
        exit="exit"
        variants={footerFade}
        transition={footerFadeTransition}
        className={styles.small_print}
      >
        <InlineBlockEditor
          jsonString={finePrint}
          className="prose-p:text-12/20 prose-p:md:text-12/20"
        />
      </motion.div>
      <div className="mt-10 overflow-hidden md:mt-15 lg:mt-20">
        <AnimatePresence>
          {errorMessage && (
            <motion.div
              initial="initial"
              animate="animate"
              exit="exit"
              variants={heightFadeVariants}
              transition={heightFadeTransition}
              className="mb-8 md:mb-10"
            >
              <Feedback type="error" title="Error" data-testid="errorMessage">
                {errorMessage}
              </Feedback>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
      <StepProgressionButton
        onContinueClick={onContinueClick}
        applyDisabledStyle={!isValid}
        backLink={appBusinessRoutes.contactDetails}
      />
    </motion.div>
  );
}
