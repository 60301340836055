import { compact, groupBy, maxBy, sumBy } from "lodash-es";

function transformPlanData({
  joinPlanSelectPageData,
  isDualFuel = false,
  credits = [],
}) {
  if (!joinPlanSelectPageData || joinPlanSelectPageData.length === 0) {
    console.warn("No plan data available");
    return [];
  }

  return joinPlanSelectPageData.map((plan) =>
    transformSinglePlan({ plan, isDualFuel, credits }),
  );
}

function transformSinglePlan({ plan, isDualFuel, credits }) {
  const termsArray = [];
  let asteriskCount = 0;
  const planType = plan.fields.planType;

  const discounts = getDiscounts(plan);
  const discountTerms = getDiscountTerms(discounts);
  const hasDiscountTerms = discountTerms.length > 0;
  const applicableDiscounts = getApplicableDiscounts({ discounts, isDualFuel });
  const highestDiscounts = getHighestDiscounts(applicableDiscounts);
  const totalDiscount = sumBy(highestDiscounts, "percentage");

  const features = plan.fields.features.map((feature) => {
    const isValidFeature = validateFeature({ feature, credits, planType });

    if (!isValidFeature) {
      return null;
    }

    const transformedFeature = transformFeature({
      feature,
      totalDiscount,
      applicableDiscounts,
      hasDiscountTerms,
      discountTerms,
      termsArray,
      credits,
      planType,
      asteriskCount,
    });
    asteriskCount = transformedFeature.asteriskCount;
    return transformedFeature.feature;
  });

  const validFeatures = compact(features);

  return {
    planType: plan.fields.planType,
    ratesType: plan.fields.ratesType,
    planName: plan.fields.planName,
    badgeText: plan.fields.badgeText,
    planTerms: plan.fields.planTerms,
    description: plan.fields.description,
    iconUrl: plan.fields.icon?.url,
    termsLink: plan.fields.termsLink,
    discounts,
    features: validFeatures,
    terms: termsArray,
  };
}

function validateFeature({ feature, credits, planType }) {
  const { featureType } = feature.fields;

  if (featureType === "credit") {
    const creditsTitle = credits[planType];
    const hasValidCreditTitle = creditsTitle ? true : false;
    return hasValidCreditTitle;
  }

  return true;
}

function getDiscounts(plan) {
  return plan.fields.discounts.map((discount) => ({
    name: discount.fields.name,
    description: discount.fields.description,
    percentage: parseInt(discount.fields.discount, 10),
    discountType: discount.fields.discountType,
    terms: discount.fields.terms,
  }));
}

function getDiscountTerms(discounts) {
  return discounts
    .filter((discount) => discount.terms)
    .map((discount) => discount.terms)
    .join(" ");
}

function getApplicableDiscounts({ discounts, isDualFuel }) {
  if (isDualFuel) {
    return discounts;
  } else {
    return discounts.filter((discount) => discount.discountType !== "fuel");
  }
}

function getHighestDiscounts(applicableDiscounts) {
  const groupedDiscounts = groupBy(applicableDiscounts, "discountType");
  return Object.values(groupedDiscounts).map((group) =>
    maxBy(group, "percentage"),
  );
}

function transformFeature({
  feature,
  totalDiscount,
  applicableDiscounts,
  hasDiscountTerms,
  discountTerms,
  termsArray,
  credits,
  planType,
  asteriskCount,
}) {
  const featureType = feature.fields.featureType;

  if (featureType === "discount") {
    return transformDiscountFeature({
      totalDiscount,
      applicableDiscounts,
      hasDiscountTerms,
      discountTerms,
      termsArray,
      asteriskCount,
    });
  } else if (featureType === "credit") {
    return transformCreditFeature({
      feature,
      credits,
      planType,
      termsArray,
      asteriskCount,
    });
  } else {
    return transformRegularFeature({
      feature,
      termsArray,
      asteriskCount,
    });
  }
}

function transformDiscountFeature({
  totalDiscount,
  applicableDiscounts,
  hasDiscountTerms,
  discountTerms,
  termsArray,
  asteriskCount,
}) {
  let discountFeatureTitle = `Up to ${totalDiscount}% in discounts`;
  if (hasDiscountTerms) {
    asteriskCount += 1;
    discountFeatureTitle += "*".repeat(asteriskCount);
    termsArray.push(`${"*".repeat(asteriskCount)} ${discountTerms}`);
  }

  const discountList = applicableDiscounts.map(
    (discount) => discount.description,
  );

  const discountFeature = {
    title: discountFeatureTitle,
    tooltip: {
      title: discountFeatureTitle,
      description: "Eligible discounts on this plan:",
      list: compact(discountList),
    },
  };

  return { feature: discountFeature, asteriskCount };
}

function transformCreditFeature({
  feature,
  credits,
  planType,
  termsArray,
  asteriskCount,
}) {
  let creditFeatureTitle = credits[planType];

  const creditTerms = feature.fields.terms;
  if (creditTerms) {
    asteriskCount += 1;
    creditFeatureTitle += "*".repeat(asteriskCount);
    termsArray.push(`${"*".repeat(asteriskCount)} ${creditTerms}`);
  }

  const tooltip = feature.fields.tooltipTitle
    ? {
        title: feature.fields.tooltipTitle,
        description: feature.fields.tooltipDescription,
      }
    : null;

  const creditFeature = {
    title: creditFeatureTitle,
    tooltip,
  };

  return { feature: creditFeature, asteriskCount };
}

function transformRegularFeature({ feature, termsArray, asteriskCount }) {
  const featureObj = {
    title: feature.fields.name,
    tooltip: getTooltip(feature),
  };

  const featureTerms = feature.fields.terms;
  if (featureTerms) {
    asteriskCount += 1;
    termsArray.push(`${"*".repeat(asteriskCount)} ${featureTerms}`);
    featureObj.title += "*".repeat(asteriskCount);
  }

  return { feature: featureObj, asteriskCount };
}

function getTooltip(feature) {
  if (feature.fields.tooltipTitle) {
    return {
      title: feature.fields.tooltipTitle,
      description: feature.fields.tooltipDescription,
    };
  } else {
    return null;
  }
}

export default transformPlanData;
