/**
 * Function formatToTitleCase
 * @param {*} text
 * @returns the text in title case
 */
export function formatToTitleCase(text) {
  try {
    return text
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  } catch (error) {
    return "";
  }
}
