import Button from "join-form/components/button";
import Icon from "join-form/components/icon";

function PlanSelectButton(props) {
  const {
    buttonText,
    name,
    isSelected,
    onClick,
    isUnavailable = false,
  } = props;

  if (isUnavailable) {
    return (
      <Button
        id={name}
        buttonType="tertiaryFillWithBorder"
        shadowless={true}
        disabled
        applyDisabledAttribute
        className="flex items-center justify-center w-full"
      >
        Unavailable
      </Button>
    );
  }

  if (isSelected) {
    return (
      <Button
        id={name}
        buttonType="primaryFillWarmOrange"
        shadowless={true}
        onClick={onClick}
        className="flex items-center justify-center w-full"
      >
        Plan Selected <Icon name="Success" size="18" className="ml-3" />
      </Button>
    );
  }

  return (
    <Button
      id={name}
      buttonType="secondaryFill"
      shadowless={true}
      onClick={onClick}
      className="flex items-center justify-center w-full"
    >
      {buttonText}
    </Button>
  );
}

export { PlanSelectButton };
