import FeatureItem from "join-form/components/feature-item";
import {
  PLAN_TYPE_BASIC,
  RESIDENTIAL_PLAN_TERM_FLEXI,
} from "helpers/constants";

function getPlusPlanTermText({ sidebarDisplayName, discountPercentage }) {
  const termContainsDiscount = discountPercentage > 0;

  const displayText = termContainsDiscount
    ? `${discountPercentage}% fixed-term discount`
    : sidebarDisplayName;

  return displayText;
}

export default function TermFeature({ termItem, planType, ...rest }) {
  const {
    codeName,
    isSelected,
    sidebarDisplayName,
    displayName,
    discountPercentage,
  } = termItem;

  if (isSelected === false) {
    return <FeatureItem {...rest}>Go flexible or, fixed</FeatureItem>;
  }

  if (planType === PLAN_TYPE_BASIC) {
    return (
      <FeatureItem type="complete" {...rest}>
        {displayName}
      </FeatureItem>
    );
  }

  if (codeName !== RESIDENTIAL_PLAN_TERM_FLEXI) {
    const displayText = getPlusPlanTermText({
      sidebarDisplayName,
      discountPercentage,
    });

    return (
      <FeatureItem type="complete" {...rest}>
        {displayText}
      </FeatureItem>
    );
  }

  return (
    <FeatureItem type="complete" {...rest}>
      Flexible, no fixed-term discount
    </FeatureItem>
  );
}
