import {
  PRODUCT_ELECTRICITY,
  PRODUCT_ELECTRICITY_DISPLAY_NAME,
  PRODUCT_GAS,
  PRODUCT_GAS_DISPLAY_NAME,
  PRODUCT_LPG,
  PRODUCT_LPG_DISPLAY_NAME,
} from "helpers/constants";

const fuelOptions = [
  {
    id: "singleFuelElec",
    productIds: [PRODUCT_ELECTRICITY],
    title: `${PRODUCT_ELECTRICITY_DISPLAY_NAME} only`,
  },
  {
    id: "dualFuelElecAndGas",
    productIds: [PRODUCT_ELECTRICITY, PRODUCT_GAS],
    title: `${PRODUCT_ELECTRICITY_DISPLAY_NAME} + ${PRODUCT_GAS_DISPLAY_NAME}`,
  },
  {
    id: "dualFuelElecAndLpg",
    productIds: [PRODUCT_ELECTRICITY, PRODUCT_LPG],
    title: `${PRODUCT_ELECTRICITY_DISPLAY_NAME} + ${PRODUCT_LPG_DISPLAY_NAME}`,
  },
  {
    id: "singleFuelLpg",
    title: `${PRODUCT_LPG_DISPLAY_NAME} only`,
    productIds: [PRODUCT_LPG],
  },
];

function getAvailableFuelOptionList(products) {
  const result = fuelOptions.filter((fuelDetails) => {
    const { productIds } = fuelDetails;
    const allFuels = products.filter(({ name }) => productIds.includes(name));
    const allFuelsAvailable = allFuels.every(({ isAvailable }) => isAvailable);
    return allFuelsAvailable;
  });
  return result;
}

function preSelectAvailableFuelOptionList({
  selectedProducts,
  availableFuelOptionList,
}) {
  const result = availableFuelOptionList.map((fuelDetails, index) => {
    const { productIds } = fuelDetails;
    const countMatch = selectedProducts.length === productIds.length;
    const productIdsMatch = selectedProducts.every(({ name }) =>
      productIds.includes(name),
    );
    const isSelected = countMatch && productIdsMatch;

    return {
      ...fuelDetails,
      isSelected,
    };
  });

  return result;
}

function validateProductSelection({ products }) {
  const productIds = products
    .filter(({ isSelected }) => isSelected)
    .map(({ name }) => name);

  switch (productIds.length) {
    // single fuel
    case 1: {
      const [productId] = productIds;
      return productId !== PRODUCT_GAS;
    }

    // dual fuel
    case 2: {
      const selectionContainsElec = productIds.includes(PRODUCT_ELECTRICITY);
      return selectionContainsElec;
    }

    // zero or tri-fuel
    case 0:
    case 3:
    default:
      return false;
  }
}

function selectProductsByIcp({ products, gasIcp }) {
  const productsToSelect = gasIcp
    ? [PRODUCT_ELECTRICITY, PRODUCT_GAS]
    : [PRODUCT_ELECTRICITY];

  const result = products.map((product) => {
    const isSelected = productsToSelect.includes(product.name);
    return {
      ...product,
      isSelected,
    };
  });

  return result;
}

function buildFuelOptionList({ products }) {
  const availableFuelOptionList = getAvailableFuelOptionList(products);

  const selectedProducts = products.filter(({ isSelected }) => isSelected);

  const fuelOptionList = preSelectAvailableFuelOptionList({
    selectedProducts,
    availableFuelOptionList,
  });

  return fuelOptionList;
}

export { buildFuelOptionList, selectProductsByIcp, validateProductSelection };
