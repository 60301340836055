import { BlockEditor } from "components/BlockEditor";

function BlockEditorModule({ module }) {
  const { size, blockEditor, jumptoSectionTitle, showJumptoSection } =
    module.fields;

  return (
    <BlockEditor
      className="md:first:pt-37 lg:first:pt-45 mb-25 first:pt-21"
      data={blockEditor}
      testid="blockEditor"
      {...{ size, jumptoSectionTitle, showJumptoSection }}
    />
  );
}

export { BlockEditorModule };
