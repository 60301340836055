import { clamp, isFunction } from "lodash-es";
import {
  filterByTagId,
  filterByYear,
  findMonthsForSelectedYear,
  toggleItemFromList,
} from "./helpers";

function setYear(state, selectedYear) {
  const allMonths = findMonthsForSelectedYear(state.allDates, selectedYear);

  return {
    ...state,
    allMonths,
    selectedYear,
  };
}

function setCategory(state, tagId) {
  const selectedTagIds = toggleItemFromList(state.selectedTagIds, tagId);

  return {
    ...state,
    selectedTagIds,
  };
}

function reset(state) {
  return {
    ...state,
    filteredDocuments: [...state.documentItems],
    selectedYear: "",
    selectedTagIds: [],
  };
}

function loadMoreDocuments(state) {
  const { filteredDocuments, visibleDocumentsCount, pageSize } = state;
  const totalDocuments = filteredDocuments.length;
  const updatedVisibleDocumentsCount = visibleDocumentsCount + pageSize;
  const clampedDocumentsCount = clamp(
    updatedVisibleDocumentsCount,
    pageSize,
    totalDocuments,
  );
  return {
    ...state,
    visibleDocumentsCount: clampedDocumentsCount,
  };
}

function filterDocuments(state) {
  const { documentItems, selectedYear, selectedTagIds } = state;

  const filteredDocuments = documentItems
    .filter((documentItem) => filterByYear(documentItem, selectedYear))
    .filter((documentItem) => filterByTagId(documentItem, selectedTagIds));

  return {
    ...state,
    filteredDocuments,
    visibleDocumentsCount: state.pageSize,
  };
}

const ACTION_TYPES = Object.freeze({
  setYear: "setYear",
  setCategory: "setCategory",
  reset: "reset",
  loadMoreDocuments: "loadMoreDocuments",
  filterDocuments: "filterDocuments",
});

const handlers = {
  [ACTION_TYPES.setYear]: setYear,
  [ACTION_TYPES.setCategory]: setCategory,
  [ACTION_TYPES.reset]: reset,
  [ACTION_TYPES.loadMoreDocuments]: loadMoreDocuments,
  [ACTION_TYPES.filterDocuments]: filterDocuments,
};

function reducer(state, action) {
  const { type, payload } = action;
  const handler = handlers[type];
  const isValidHandler = isFunction(handler);

  if (!isValidHandler) {
    throw new Error(
      `DocumentFilter reducer called with unsupported action: ${action.type}`,
    );
  }

  return handler(state, payload);
}

export { ACTION_TYPES, reducer };
