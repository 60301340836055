import classNames from "classnames";
import { ChevronDown } from "assets/icons";
import { Link } from "components/Link";
import { getPageLinkProps } from "../getPageLinkProps";

function PrimaryNavLink({
  item,
  isActive,
  hasChildren,
  onClick,
  prefetch,
  parentLabel,
}) {
  const { url, target } = getPageLinkProps(item);

  const { menuText, isSelected } = item;

  return (
    <Link href={url} prefetch={prefetch}>
      <a
        className="group pb-4.5 flex flex-col items-center pt-4 px-2 text-black-primary text-sm lg:pb-6 lg:px-3 lg:text-base"
        target={target}
        onClick={onClick}
        data-ga={JSON.stringify({
          content_tertiary: "primary menu",
          event_label: [parentLabel, menuText],
          event_category: "primary menu",
        })}
      >
        <span className="flex items-center mb-1">
          <span className="flex-1 font-medium">{menuText}</span>

          {hasChildren ? (
            <ChevronDown
              className={classNames(
                "ml-1 w-5 h-5 fill-current transition-transform duration-200",
                { "rotate-180": isActive },
              )}
            />
          ) : null}
        </span>

        <span className="flex justify-center w-full">
          <span
            data-testid="underline"
            className={classNames(
              "group-hover:w-7 h-1 bg-orange-primary rounded opacity-0 group-hover:opacity-100 transition-width-and-opacity duration-200",
              {
                "w-3": !isSelected,
              },
              {
                "w-7 opacity-100 is-selected": isSelected,
              },
            )}
          />
        </span>
      </a>
    </Link>
  );
}

export { PrimaryNavLink };
