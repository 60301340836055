import React from "react";
import classNames from "classnames";
import { Link } from "components/Link";
import { normalizeUrl } from "helpers/urlHelper";
import { FadeInBottomOnScroll } from "./animations";

function getHref(sitemapNode) {
  const { redirect } = sitemapNode;

  if (redirect?.url) {
    return normalizeUrl(redirect.url);
  }

  return sitemapNode.path;
}

function Breadcrumb({ parentItems, currentItem }) {
  if (parentItems.length === 0) {
    return null;
  }
  const eventLabels = parentItems.map((item) => item.menuText);
  return (
    <div
      data-testid="breadcrumb"
      data-psnid="psn_breadcrumb"
      className="mb-5 md:mb-10 first:pt-21 md:first:pt-44 lg:first:pt-56"
    >
      <FadeInBottomOnScroll>
        <div
          className={classNames(
            "px-4 sm:px-15 md:px-17 lg:px-23 xl:px-46",
            "max-w-full w-120 sm:w-210 md:w-auto mx-auto",
          )}
        >
          <div className="flex overflow-x-auto overscroll-contain rtl no-scrollbar">
            <ul
              className={classNames(
                "flex flex-1 flex-nowrap",
                "ltr space-x-1",
                "text-15/24 text-dark-primary",
                "md:space-x-2 md:text-18/28",
              )}
            >
              {parentItems.map((item) => {
                const href = getHref(item);

                return (
                  <React.Fragment key={href}>
                    <li className="whitespace-nowrap">
                      <Link href={href} prefetch={false}>
                        <a
                          className="transition hover:text-warm-orange-primary"
                          data-ga={JSON.stringify({
                            content_tertiary: "bread crumb menu",
                            event_label: eventLabels,
                            event_category: "bread crumb menu",
                          })}
                        >
                          {item.menuText || item.title}
                        </a>
                      </Link>
                    </li>
                    <li className="whitespace-nowrap">/</li>
                  </React.Fragment>
                );
              })}
              <li className="text-warm-orange-primary whitespace-nowrap">
                {currentItem.menuText || currentItem.title}
              </li>
            </ul>
          </div>
        </div>
      </FadeInBottomOnScroll>
    </div>
  );
}

export { Breadcrumb };
