import classNames from "classnames";
import { ModuleWrapper } from "components/Wrappers";
import { getContentWithLineBreaks } from "helpers/htmlHelper";
import {
  FadeInBottomAnimation,
  FadeInLeftTextAnimation,
  ScaleAnimation,
} from "./animations";

function Quote({ module }) {
  const { quote, person } = module.fields;
  return (
    <div className="relative py-15 md:py-20">
      <ScaleAnimation
        className={classNames(
          "absolute rounded-full bg-black-primary/10 -z-1",
          "top-0 -left-20 sm:-left-5 md:left-2 lg:left-11",
          "w-63 h-63 sm:w-66 sm:h-66 md:w-92 md:h-92",
        )}
      />
      <ModuleWrapper testid="quoteSection" psnid="psn_quote">
        <div className="z-20 md:grid md:gap-x-7 md:grid-cols-24 lg:gap-x-8">
          <FadeInBottomAnimation className="md:col-span-14 lg:col-start-2">
            <p className="mb-5 font-medium text-dark-primary text-32/36 md:text-44/52">
              {getContentWithLineBreaks(quote)}
            </p>
          </FadeInBottomAnimation>
          <FadeInLeftTextAnimation
            className={classNames(
              "flex justify-end space-x-2 md:col-span-7 md:col-start-11 lg:col-span-8 lg:col-start-9",
              "text-black-primary text-15/24 md:text-18/28",
            )}
          >
            <span>&mdash;</span>
            <span>{person}</span>
          </FadeInLeftTextAnimation>
        </div>
      </ModuleWrapper>
    </div>
  );
}

export { Quote };
