const tildeUrlPrefixes = ["https://~", "http://~", "//~", "~"];

function startsWithTilde(url) {
  return tildeUrlPrefixes.some((prefix) => url?.startsWith(prefix));
}

function isAbsoluteUrl(url) {
  const externalUrlRegex = new RegExp("^(?:[a-z]+:)?//", "i");
  const isExternalUrl = externalUrlRegex.test(url);
  const result = isExternalUrl || startsWithTilde(url);
  return result;
}

function normalizeUrl(url) {
  const prefixToReplace = tildeUrlPrefixes.find((prefix) =>
    url?.startsWith(prefix),
  );
  return url.replace(prefixToReplace, "");
}

function getAbsoluteUrl(url) {
  if (isAbsoluteUrl(url)) {
    return url;
  }

  const normalizedUrl = normalizeUrl(url);

  const absoluteUrl = new URL(normalizedUrl, process.env.NEXT_PUBLIC_WEBSITE_URL);

  return absoluteUrl.toString();
}

function isInternalLink(url) {
  const base = new URL(
    `${window?.location.protocol}//${window?.location.host}`,
  );
  return new URL(url, base).hostname === base.hostname;
}

export {
  startsWithTilde,
  isAbsoluteUrl,
  normalizeUrl,
  getAbsoluteUrl,
  isInternalLink,
};
