import React from "react";
import { useGlobalData } from "providers/GlobalData";
import { Breadcrumb as BreadcrumbComponent } from "components/Breadcrumb";

function Breadcrumb({ sitemapNode }) {
  const { parentItems } = useGlobalData();

  return (
    <BreadcrumbComponent parentItems={parentItems} currentItem={sitemapNode} />
  );
}

export { Breadcrumb };
