import { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import styles from "../address-auto-suggest.module.scss";
import { heightFadeTransition, heightFadeVariants } from "../animations";
import { SuggestionsHeaderMultipleResults } from "./SuggestionsHeaderMultipleResults";

export function SuggestionsContainer(props) {
  const {
    containerProps,
    children,
    hasMultipleRegistryResults,
    renderHeader = SuggestionsHeaderMultipleResults,
    renderFooter,
    addressFromFinder,
  } = props;

  const [suggestionsScrolled, setSuggestionsScrolled] = useState(false);

  // suggestions scroll effect
  const suggestionsWrapperRef = useRef(null);

  const onSuggestionsContainerScroll = (e) => {
    const hasScrolled = e.target.scrollTop > 0;
    setSuggestionsScrolled(hasScrolled);
  };

  useEffect(() => {
    if (suggestionsWrapperRef && suggestionsWrapperRef.current) {
      const currentRef = suggestionsWrapperRef.current;
      currentRef.addEventListener("scroll", onSuggestionsContainerScroll);
      return () => {
        currentRef.removeEventListener("scroll", onSuggestionsContainerScroll);
      };
    }
  }, []);

  const scrolledClassName = suggestionsScrolled
    ? styles.suggestions_wrapper_scrolled
    : "";
  const wrapperClassName = [styles.suggestions_wrapper, scrolledClassName]
    .join(" ")
    .trim();

  return (
    <motion.div
      initial="initial"
      animate="animate"
      exit="exit"
      variants={heightFadeVariants}
      transition={heightFadeTransition}
      data-testid="suggestionsContainer"
    >
      <div className={wrapperClassName} ref={suggestionsWrapperRef}>
        {renderHeader({ hasMultipleRegistryResults })}
        <div {...containerProps}>{children}</div>
      </div>
      {renderFooter({ addressFromFinder, hasMultipleRegistryResults })}
    </motion.div>
  );
}
