import classNames from "classnames";
import ReactHtmlParser from "react-html-parser";
import { transform } from "helpers/transformNodeHelper";

function UnorderedList({ items }) {
  return (
    <ul className="list-disc marker:text-black-primary text-18/28 md:text-20/32">
      {items.map((item, index) => {
        const listItem = ReactHtmlParser(item.content, { transform });
        return (
          <li key={`iteration${index}`} className="my-5">
            {listItem}
            {item.items.length ? <UnorderedList items={item.items} /> : null}
          </li>
        );
      })}
    </ul>
  );
}

function OrderedList({ items, level = 1 }) {
  return (
    <ol
      className={classNames(
        "marker:text-black-primary text-18/28 md:text-20/32",
        { "list-decimal": level % 3 === 1 }, // level = 1, 4
        { "list-alpha": level % 3 === 2 }, // level = 2, 5
        { "list-roman": level % 3 === 0 }, // level = 3, 6
      )}
    >
      {items.map((item, index) => {
        const listItem = ReactHtmlParser(item.content, { transform });
        return (
          <li key={index} className="my-5">
            {listItem}
            {item.items.length ? (
              <OrderedList items={item.items} level={level + 1} />
            ) : null}
          </li>
        );
      })}
    </ol>
  );
}

function List({ data }) {
  const { style, items } = data;

  if (!items.length) {
    return null;
  }

  if (style === "unordered") {
    return <UnorderedList items={items} />;
  }

  return <OrderedList items={items} />;
}

export { List };
