import {
  EnergyRateContainer,
  EnergyRateItem,
} from "join-form/components/energy-rate";
import { filterBusinessOffers } from "helpers/offersHelper";
import { isBottledGasSelected } from "helpers/productHelper";
import { getRateUnitOfMeasurement } from "helpers/rateHelper";
import { connect } from "react-redux";

function BottledGasRates(props) {
  const { tariffList, inclGst, highlightOnMobile } = props;

  if (!tariffList) {
    return null;
  }

  return (
    <EnergyRateContainer
      icon="BottledGasBadge"
      title="Bottled Gas (LPG)"
      data-testid="lpgRates"
      highlightOnMobile={highlightOnMobile}
    >
      {tariffList.map((tariffItem, index) => {
        const {
          description: title,
          meteringUnitOfMeasurement,
          pricingUnitOfMeasurement,
          rateExcludingGST,
          rateIncludingGST,
        } = tariffItem;

        const roundedValue = (
          inclGst ? rateIncludingGST : rateExcludingGST
        ).toFixed(2);
        const unitOfMeasurement = getRateUnitOfMeasurement(
          meteringUnitOfMeasurement,
        );
        const value = `${pricingUnitOfMeasurement}${roundedValue} / ${unitOfMeasurement}`;

        return <EnergyRateItem key={index} title={title} value={value} />;
      })}
    </EnergyRateContainer>
  );
}
const mapStateToProps = (state) => {
  const {
    appState: { products },
    businessPlanConfig: { termData },
    businessOffers: { bottledGasOffers },
  } = state;

  const termItem = termData.find(({ isSelected }) => isSelected) || {};

  const lpgOffers =
    termItem.isSelected && isBottledGasSelected(products)
      ? filterBusinessOffers(bottledGasOffers, termItem.termPeriod)
      : [];

  return {
    tariffList:
      lpgOffers.length === 1 ? lpgOffers[0].priceBook.tariffList : null,
  };
};

export default connect(mapStateToProps)(BottledGasRates);
