import { useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import classNames from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import { useRouter } from "next/router";
import { Controller, useForm } from "react-hook-form";
import { useMutation } from "react-query";
import * as yup from "yup";
import { ArrowLeft, ArrowRight, Cross } from "assets/icons";
import { AddressFinder } from "components/AddressFinder";
import { Button } from "components/Button";
import { Checkbox } from "components/Checkbox";
import {
  DropDown,
  InputField,
  InputList,
  RadioButton,
  ReCaptcha,
} from "components/Form";
import { VARIANT } from "helpers/constants";
import { gaFormError, gaFormSubmission } from "helpers/gaHelper";
import { heightFadeTransition, heightFadeVariants } from "./animations";
import { placeLpgOrder } from "./ApiHelper";
import { PROPERTY_ACCESS_REASONS } from "./constants";
import {
  cylinderQuantityOptions,
  cylinderTypeOptions,
  propertyAccessOptions,
} from "./OptionsList";

const schema = yup.object({
  name: yup
    .string()
    .required("Please enter your first and last name")
    .min(2, "Name needs to be at least 2 characters")
    .max(120, "Name needs to be no more than 120 characters"),
  customerNumber: yup
    .string()
    .required("Please enter your customer number/ID")
    .min(8, "Customer number/ID needs to be at least 8 digits")
    .max(12, "Customer number/ID needs to be no more than 12 digits"),
  phone: yup
    .string()
    .required("Please enter your phone number")
    .min(6, "Phone number needs to be at least 6 digits")
    .max(40, "Phone number needs to be no more than 40 digits")
    .matches(/^[0-9+]+[0-9 ]{3,40}$/, "Please enter a valid phone number"),
  email: yup
    .string()
    .required("Please enter your email address")
    .min(3, "Email needs to be at least 3 characters")
    .max(80, "Email needs to be no more than 80 characters")
    .email("Please enter a valid email address"),
  address: yup.string().required("Please enter your address"),
  propertyAccessReasons: yup.array().when("propertyAccessIssue", {
    is: (value) => value === "true",
    then: yup.array().min(1, "Please select access issue reason"),
  }),
  propertyAccessReasonSecurityCode: yup.string().when("propertyAccessReasons", {
    is: (value) => value.includes(PROPERTY_ACCESS_REASONS.SECURITY_CODE),
    then: yup
      .string()
      .required("Please enter the security code to allow access"),
  }),
  propertyAccessReasonOther: yup.string().when("propertyAccessReasons", {
    is: (value) => value.includes(PROPERTY_ACCESS_REASONS.OTHER),
    then: yup
      .string()
      .required("Please enter details about what could prevent access"),
  }),
  cylinderType: yup
    .string()
    .required("Please select the cylinder type you need"),
  cylinderQuantity: yup.string().when("cylinderType", {
    is: (value) => value,
    then: yup.string().required("Please select how many bottles you need"),
  }),
  recaptcha: yup.string().typeError("").required("Please tick the checkbox"),
});

function SmeLpgRefillForm({ submitText, thanksPage }) {
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    watch,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      propertyAccessIssue: "false",
      propertyAccessReasons: [],
      address: "",
    },
  });

  const { mutate, isError, isSuccess, reset, isLoading } = useMutation(
    placeLpgOrder,
    {
      retry: 3,
    },
  );

  const formValues = getValues();

  useEffect(() => {
    const firstFormError = errors ? errors[Object.keys(errors)[0]] : "";

    if (firstFormError) {
      gaFormError({
        event_category: "form - bottled gas refill",
        event_label: firstFormError.message,
        content_tertiary: "business lpg bottled gas refill request form",
      });
    }
  }, [errors]);

  watch();
  const router = useRouter();

  useEffect(() => {
    if (isSuccess) {
      gaFormSubmission({
        event_category: "bottled gas refill",
        event_label: "submit",
        content_tertiary: "business lpg bottled gas refill request form",
      });
      router.push({
        pathname: thanksPage.href,
      });
    }
  }, [isSuccess, thanksPage.href, router]);

  const onSubmit = () => {
    mutate(formValues);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} data-testid="smeRefillApiForm">
      <div
        className={classNames(
          "flex flex-col gap-y-10 my-10",
          "md:grid md:grid-cols-2 md:gap-x-7 md:gap-y-15 md:my-15 lg:gap-x-8",
        )}
      >
        <InputField
          label="Name"
          name="name"
          value={formValues.name}
          register={register}
          errorMessage={errors.name?.message}
        />
        <InputField
          label="Customer number/ID"
          type="number"
          name="customerNumber"
          value={formValues.customerNumber}
          register={register}
          errorMessage={errors.customerNumber?.message}
        />
        <InputField
          label="Daytime phone"
          type="tel"
          name="phone"
          value={formValues.phone}
          register={register}
          errorMessage={errors.phone?.message}
        />
        <InputField
          label="Email address"
          type="email"
          name="email"
          value={formValues.email}
          register={register}
          errorMessage={errors.email?.message}
        />

        <Controller
          control={control}
          name="address"
          render={({ field: { ref, ...rest } }) => (
            <AddressFinder {...rest} errorMessage={errors.address?.message} />
          )}
        />

        <InputList
          label="Is there anything that could prevent access to your gas bottles, such as dogs on the property?"
          className="md:col-span-2"
          name="propertyAccessIssue"
        >
          <RadioButton
            label="Yes"
            value="true"
            register={register}
            name="propertyAccessIssue"
            checked={formValues.propertyAccessIssue === "true"}
          />
          <RadioButton
            label="No"
            value="false"
            register={register}
            name="propertyAccessIssue"
            checked={formValues.propertyAccessIssue === "false"}
          />
        </InputList>
        <AnimatePresence>
          {formValues.propertyAccessIssue === "true" && (
            <motion.div
              initial="initial"
              animate="animate"
              exit="exit"
              variants={heightFadeVariants}
              transition={heightFadeTransition}
              className="md:col-span-2"
            >
              <InputList
                label="What could prevent access?"
                name="propertyAccessReasons"
                errorMessage={errors.propertyAccessReasons?.message}
              >
                {propertyAccessOptions.map((item) => (
                  <Checkbox
                    key={item.value}
                    label={item.text}
                    value={item.value}
                    type="large"
                    {...register("propertyAccessReasons")}
                  />
                ))}
              </InputList>
              {formValues.propertyAccessReasons && (
                <AnimatePresence>
                  {formValues.propertyAccessReasons.includes(
                    PROPERTY_ACCESS_REASONS.SECURITY_CODE,
                  ) && (
                    <motion.div
                      initial="initial"
                      animate="animate"
                      exit="exit"
                      variants={heightFadeVariants}
                      transition={heightFadeTransition}
                      className="mt-10 md:w-1/2 md:pr-3.5 md:mt-15"
                      key={PROPERTY_ACCESS_REASONS.SECURITY_CODE}
                    >
                      <InputField
                        label="Please enter security code:"
                        name="propertyAccessReasonSecurityCode"
                        register={register}
                        value={formValues.propertyAccessReasonSecurityCode}
                        errorMessage={
                          errors.propertyAccessReasonSecurityCode?.message
                        }
                      />
                    </motion.div>
                  )}
                  {formValues.propertyAccessReasons.includes(
                    PROPERTY_ACCESS_REASONS.OTHER,
                  ) && (
                    <motion.div
                      initial="initial"
                      animate="animate"
                      exit="exit"
                      variants={heightFadeVariants}
                      transition={heightFadeTransition}
                      className="mt-10 md:w-1/2 md:pr-3.5 md:mt-15"
                      key={PROPERTY_ACCESS_REASONS.OTHER}
                    >
                      <InputField
                        label="Please enter other issues limiting property access:"
                        name="propertyAccessReasonOther"
                        register={register}
                        value={formValues.propertyAccessReasonOther}
                        errorMessage={errors.propertyAccessReasonOther?.message}
                      />
                    </motion.div>
                  )}
                </AnimatePresence>
              )}
            </motion.div>
          )}
        </AnimatePresence>
        <div className="flex flex-col gap-y-10 md:gap-y-15">
          <DropDown
            name="cylinderType"
            label="Cylinder type"
            register={register}
            value={formValues.cylinderType}
            errorMessage={errors.cylinderType?.message}
          >
            {cylinderTypeOptions.map((type) => (
              <option key={type.value} value={type.value}>
                {type.text}
              </option>
            ))}
          </DropDown>
          <AnimatePresence>
            {formValues.cylinderType && (
              <motion.div
                initial="initial"
                animate="animate"
                exit="exit"
                variants={heightFadeVariants}
                transition={heightFadeTransition}
              >
                <DropDown
                  name="cylinderQuantity"
                  label="Quantity"
                  register={register}
                  value={formValues.cylinderQuantity}
                  errorMessage={errors.cylinderQuantity?.message}
                >
                  {cylinderQuantityOptions.map((type) => (
                    <option key={type} value={type}>
                      {type}
                    </option>
                  ))}
                </DropDown>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>
      <div className="pt-5 mb-10 md:pt-4">
        <Controller
          control={control}
          name="recaptcha"
          render={({ field: { name, onChange } }) => (
            <ReCaptcha
              errorMessage={errors[name]?.message}
              name={name}
              onChange={onChange}
            />
          )}
        />
      </div>
      {isError && (
        <div className="flex justify-between p-4 mb-10 bg-red-100 border-2 rounded-1 md:p-5 border-red-primary md:mb-15">
          <p className="text-black-primary text-14/20 md:text-16/24">
            Sorry, we couldn&apos;t process your request!
          </p>
          <div className="self-center h-4">
            <button
              onClick={() => reset()}
              data-testid="smeRefillApiError"
              aria-label="Close error message"
            >
              <Cross className="w-4 h-4 fill-current text-red-primary" />
            </button>
          </div>
        </div>
      )}
      <div className="flex justify-between">
        <Button
          variant={VARIANT.darkTransparent}
          className="!px-0"
          onClick={(e) => {
            e.preventDefault();
            router.back();
          }}
          data-testid="smeRefillApiFormBackButton"
        >
          <ArrowLeft />
          <span>Back</span>
        </Button>
        <Button
          variant={VARIANT.orange}
          className="px-2"
          data-testid="smeRefillApiFormSubmitButton"
          type="submit"
          disabled={isLoading || isSuccess}
        >
          <span>{submitText}</span>
          <ArrowRight />
        </Button>
      </div>
    </form>
  );
}

export { SmeLpgRefillForm };
