import { Image } from "components/Image";
import styles from "./feedback.module.scss";

const typeClassNameMapping = {
  info: styles.feedback_info,
  success: styles.feedback_success,
  warning: styles.feedback_warning,
  error: styles.feedback_error,
};

export default function Feedback(props) {
  const { type = "info", children, title, ...rest } = props;

  const typeClassName = typeClassNameMapping[type] || "";

  return (
    <div className={typeClassName} {...rest}>
      <div className={styles.feedback_icon_container}>
        <Image
          src={"/images/Error.svg"}
          className={styles.feedback_icon}
          alt=""
          width={20}
          height={20}
        />
      </div>
      <div className={styles.feedback_content}>
        {title && <h4 className={styles.feedback_title}>{title}</h4>}
        {children && <p className={styles.feedback_text}>{children}</p>}
      </div>
    </div>
  );
}
