import { sortBy } from "lodash-es";
import { runAsync } from "helpers";

export const getAlertBannerProps = async ({ agility, languageCode }) => {
  const [data, error] = await runAsync(() =>
    agility.getContentList({
      referenceName: "gealertbanner",
      languageCode,
    }),
  );

  if (error) {
    return [];
  }

  const { items, totalCount } = data;

  if (totalCount === 0) {
    return [];
  }

  return sortBy(items, ["properties.itemOrder"]);
};
