import { getJoinPlanSelectPageProps } from "join-form/pages/residential/plan-select/getJoinPlanSelectPageProps";
import { getAlertBannerProps } from "./AlertBanner/getAlertBannerProps";
import { getBreadcrumbProps } from "./Breadcrumb/getBreadcrumbProps";
import { getJoinFooterProps } from "./Join/footer";
import { getJoinHeaderProps } from "./Join/Header";
import { footer } from "./SiteFooter/getSiteFooterProps";
import { header } from "./SiteHeader/getSiteHeaderProps";

const globalComponents = Object.freeze({
  header,
  footer,
  parentItems: { getCustomInitialProps: getBreadcrumbProps },
  alertBanner: { getCustomInitialProps: getAlertBannerProps },
  joinFooterData: { getCustomInitialProps: getJoinFooterProps },
  joinHeaderData: { getCustomInitialProps: getJoinHeaderProps },
  joinPlanSelectPageData: { getCustomInitialProps: getJoinPlanSelectPageProps },
});

export { globalComponents };
