import { motion } from "framer-motion";

const documentFadeInBottomAnimation = {
  hidden: { opacity: 0, y: 20 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.3,
    },
  },
};

function FadeInBottomAnimation({ children }) {
  return (
    <motion.div variants={documentFadeInBottomAnimation}>{children}</motion.div>
  );
}

export { FadeInBottomAnimation };
