import classNames from "classnames";
import styles from "./radio-list.module.scss";
import RadioButton from "../RadioButton";

export default function RadioList(props) {
  const {
    items,
    label,
    name,
    onChange,
    errorMessage,
    showError,
    className,
    size,
    isHalfWidth = false,
  } = props;

  const columnClassName = classNames(styles.column, {
    [styles.halfWidth]: isHalfWidth,
  });

  return (
    <div
      className={classNames(styles.container, className)}
      data-testid={`${name}FieldContainer`}
    >
      <p className={styles.label}>{label}</p>
      <div className={styles.row}>
        {items.map((radioButtonProps, index) => (
          <div className={columnClassName} key={index}>
            <RadioButton
              key={index}
              name={name}
              onChange={onChange}
              size={size}
              {...radioButtonProps}
            />
          </div>
        ))}
      </div>
      {showError && (
        <span data-testid={`${name}ErrorMessage`} className={styles.errorText}>
          {errorMessage}
        </span>
      )}
    </div>
  );
}
