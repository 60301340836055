import { useState } from "react";
import classNames from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import { RadioList } from "join-form/components/form-controls";
import Icon from "join-form/components/icon";
import Modal from "join-form/components/modal";
import { getPropertyAccessListContent } from "helpers/propertyAccessHelper";
import { InlineBlockEditor } from "components/BlockEditor";
import { heightFadeTransition, heightFadeVariants } from "./animations";
import styles from "./property-page.module.scss";

export function ElectricityQuestions(props) {
  const { values, touched, errors, handleChange, pageData } = props;

  const infoModalContent = {
    medical: {
      title: pageData.fields.medicalSupportModalTitle,
      content: (
        <InlineBlockEditor
          jsonString={pageData.fields.medicalSupportModalContent}
        />
      ),
    },
    wellbeing: {
      title: pageData.fields.wellbeingModalTitle,
      content: (
        <InlineBlockEditor jsonString={pageData.fields.wellbeingModalContent} />
      ),
    },
  };

  const [infoModalType, setInfoModalType] = useState("");
  const onInfoModalClose = () => {
    setInfoModalType("");
  };

  return (
    <>
      <p
        className={classNames(styles.label, "inline")}
        data-testid="medicalSupport"
      >
        {pageData.fields.medicalSupportLabel}
      </p>
      <button
        data-testid="medicalSupportOverlay"
        onClick={() => setInfoModalType("medical")}
        className="ml-2 align-text-bottom text-black-primary md:align-text-top"
      >
        <Icon name="Info" size="18" />
      </button>

      <RadioList
        name="isMedicalSupport"
        onChange={handleChange}
        errorMessage={errors.isMedicalSupport}
        showError={errors.isMedicalSupport && touched.isMedicalSupport}
        size="medium"
        items={[
          {
            text: "Yes",
            value: true,
            checked: values.isMedicalSupport === "true",
            testId: "isMedicalSupportYesOption",
          },
          {
            text: "No",
            value: false,
            checked: values.isMedicalSupport === "false",
            testId: "isMedicalSupportNoOption",
          },
        ]}
      />

      <p
        className={classNames(styles.label, "inline")}
        data-testid="disconnectionThreat"
      >
        {pageData.fields.disconnectionThreatLabel}
      </p>
      <button
        data-testid="disconnectionOverlay"
        onClick={() => setInfoModalType("wellbeing")}
        className="ml-2 align-text-bottom text-black-primary md:align-text-top"
      >
        <Icon name="Info" size="18" />
      </button>

      <RadioList
        name="isWellBeing"
        onChange={handleChange}
        errorMessage={errors.isWellBeing}
        showError={errors.isWellBeing && touched.isWellBeing}
        size="medium"
        items={[
          {
            text: "Yes",
            value: true,
            checked: values.isWellBeing === "true",
            testId: "isWellBeingYesOption",
          },
          {
            text: "No",
            value: false,
            checked: values.isWellBeing === "false",
            testId: "isWellBeingNoOption",
          },
        ]}
      />
      <AnimatePresence>
        {values.isWellBeing === "true" && (
          <motion.div
            initial="initial"
            animate="animate"
            exit="exit"
            variants={heightFadeVariants}
            transition={heightFadeTransition}
            className={styles.dependant_fields_wrapper}
          >
            <p className={styles.label} data-testid="isWellBeingThreatQuestion">
              {pageData.fields.isWellBeingThreatLabelPreText}{" "}
              <a
                href={`tel:${pageData.fields.isWellBeingThreatPhoneNumber}`}
                className={styles.link}
              >
                {pageData.fields.isWellBeingThreatPhoneNumber}
              </a>{" "}
              {pageData.fields.isWellBeingThreatLabelPostText}
            </p>
            <RadioList
              name="wellBeingAffectedReason"
              errorMessage={errors.wellBeingAffectedReason}
              showError={
                errors.wellBeingAffectedReason &&
                touched.wellBeingAffectedReason
              }
              onChange={handleChange}
              isHalfWidth
              size="medium"
              items={getPropertyAccessListContent(
                "wellbeingAffectedReason",
              ).map((reason) => ({
                text: reason.text,
                value: reason.value,
                checked: values.wellBeingAffectedReason === reason.value,
                testId: `wellBeingAffectedReason${reason.text}`,
              }))}
            />
          </motion.div>
        )}
      </AnimatePresence>
      {!!infoModalType && (
        <Modal
          onRequestClose={onInfoModalClose}
          isOpen
          title={infoModalContent[infoModalType]["title"]}
        >
          {infoModalContent[infoModalType]["content"]}
        </Modal>
      )}
    </>
  );
}

export default ElectricityQuestions;
