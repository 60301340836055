import { ArticleCarouselSection } from "components/ArticleCarouselSection";
import { getCustomInitialProps } from "./getLatestDocumentsProps";
import { LatestDocumentItem } from "./LatestDocumentItem";

function LatestDocuments({ module, customData }) {
  const { title, cta } = module.fields;

  return customData?.length ? (
    <ArticleCarouselSection title={title} cta={cta} testid="latestDocuments">
      {customData.map((data) => (
        <LatestDocumentItem key={data.contentID} data={data} />
      ))}
    </ArticleCarouselSection>
  ) : null;
}

LatestDocuments.getCustomInitialProps = getCustomInitialProps;

export { LatestDocuments };
