import { sortBy } from "lodash-es";
import { GraphQLApi } from "helpers/graphQLApi";

export const getCustomInitialProps = async ({ agility }) => {
  const referenceName = "unitimlinelist";
  try {
    const query = JSON.stringify({
      query: `{
        ${referenceName} {
          fields {            
            title
          }
          properties {
            itemOrder
          }
        }
      }`,
    });
    const graphQL = new GraphQLApi(agility.config);
    const req = graphQL.buildRequest(query);

    const { data } = await agility.makeRequest(req);
    const sortedData = sortBy(data.unitimlinelist, ["properties.itemOrder"]);

    return sortedData.map((item) => item.fields.title);
  } catch (ex) {
    return {};
  }
};
