import React from "react";
import classNames from "classnames";
import { useScreenSize } from "hooks";
import { CallToAction } from "components/CallToAction";
import { THEME, VARIANT } from "helpers/constants";
import { getContentWithLineBreaks } from "helpers/htmlHelper";
import {
  FadeInAnimation,
  FadeInBottomAnimation,
  FadeInTopAnimation,
  ScaleAnimation,
} from "./animations";
import { HeaderImage } from "./HeaderImage";

const getTitle = (fields, { isMd }) => {
  if (fields.theme === THEME.dark || (fields.theme === THEME.light && !isMd))
    return "text-white";
  else return "text-dark-primary";
};

const getDescription = (fields, { isMd }) => {
  if (fields.theme === THEME.dark || (fields.theme === THEME.light && !isMd))
    return "text-white";
  else return "text-black-primary";
};

const getBackgroundImage = (fields, { isMd, isLg }) => {
  if (isLg) {
    return `url(${fields.lgdtimage.url})`;
  } else if (isMd) {
    return `url(${fields.smdtimage.url})`;
  } else return null;
};

const getCTAButtonStyle = (fields, { isMd }) => {
  if (fields.theme === THEME.light && isMd) {
    return VARIANT.dark;
  } else return VARIANT.light;
};

function HeaderLevelTwoMarketing({ module }) {
  const [mounted, setMounted] = React.useState(false);
  const screen = useScreenSize();

  React.useEffect(() => {
    setMounted(true);
  }, []);

  const { fields } = module;
  const hasImage = fields.tbmbimage;
  const hasCtas = fields.cta1 || fields.cta2;

  const variantStyles = {
    title: getTitle(fields, screen),
    description: getDescription(fields, screen),
    cta1: screen.isMd ? VARIANT.orange : VARIANT.light,
    cta2: getCTAButtonStyle(fields, screen),
    outline: screen.isMd ? false : true,
  };

  return (
    <div
      data-psnid="psn_header_level_two_marketing"
      className={classNames(
        "relative mx-auto mb-10",
        "first:pt-21",
        "sm:mb-20",
        "md:first:pt-37",
        "lg:first:pt-45",
      )}
    >
      <div
        className={classNames(
          "absolute inset-x-0 top-0 bottom-25",
          "bg-day-gradient",
          "sm:bottom-10",
          "md:bottom-20",
        )}
      >
        <div
          className={classNames(
            "absolute inset-0 translate-y-px",
            "bg-bottom bg-no-repeat bg-auto",
            "bg-curve-sm sm:bg-curve-md md:bg-curve-lg lg:bg-curve-xl",
          )}
        />
      </div>
      <div
        className={classNames(
          "relative px-4 mx-auto sm:px-15 md:px-17 lg:px-23 xl:px-46",
          "max-w-full w-120 sm:w-210 md:w-auto",
        )}
      >
        <div className="flex justify-center md:pt-6 lg:pt-5">
          <FadeInAnimation>
            <div
              className={classNames(
                "relative flex items-center md:rounded-10 overflow-hidden",
                "md:shadow-white-glow md:w-213 md:min-h-97",
                "lg:w-252 lg:min-h-115",
              )}
              data-testid="headerContent"
            >
              <ScaleAnimation
                className={classNames(
                  "absolute inset-x-0 top-0 bottom-0 bg-center bg-no-repeat bg-cover",
                )}
                style={{ backgroundImage: getBackgroundImage(fields, screen) }}
              ></ScaleAnimation>
              <div
                className={classNames(
                  "relative text-center md:text-left",
                  "md:pl-10 md:max-w-153 md:py-12",
                  "lg:pl-12 lg:max-w-175",
                )}
              >
                <FadeInTopAnimation>
                  <h1
                    className={classNames(
                      "mt-7 mb-3 font-bold text-32/36",
                      "md:mt-0 md:mb-5 md:text-44/52",
                      variantStyles.title,
                    )}
                  >
                    {fields.title}
                  </h1>
                  <p
                    data-testid="headerContentDescription"
                    className={classNames(
                      "font-normal text-18/28",
                      "md:text-20/32",
                      variantStyles.description,
                    )}
                  >
                    {getContentWithLineBreaks(fields.description)}
                  </p>
                </FadeInTopAnimation>
                {hasCtas && mounted ? (
                  <div
                    data-testid="ctaSection"
                    className={classNames(
                      "flex flex-wrap justify-center gap-5 mt-8",
                      "mb-10 md:mb-0 md:mt-10 md:justify-start",
                    )}
                  >
                    <FadeInBottomAnimation isPrimary>
                      <CallToAction
                        data={fields.cta1}
                        dataGa={{
                          content_tertiary: "hero banner",
                          event: "cta_click",
                          event_category: "cta",
                          event_label: [fields.cta1?.text],
                        }}
                        testid="primaryCta"
                        variant={variantStyles.cta1}
                      />
                    </FadeInBottomAnimation>
                    <FadeInBottomAnimation>
                      <CallToAction
                        data={fields.cta2}
                        dataGa={{
                          content_tertiary: "hero banner",
                          event: "cta_click",
                          event_category: "cta",
                          event_label: [fields.cta2?.text],
                        }}
                        testid="secondaryCta"
                        variant={variantStyles.cta2}
                        outline={variantStyles.outline}
                      />
                    </FadeInBottomAnimation>
                  </div>
                ) : null}
              </div>
            </div>
          </FadeInAnimation>
        </div>
        <div className={classNames("block md:hidden")}>
          {hasImage ? <HeaderImage image={fields.tbmbimage} /> : null}
        </div>
      </div>
    </div>
  );
}

export { HeaderLevelTwoMarketing };
