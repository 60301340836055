import classNames from "classnames";
import { Button } from "components/Button";

function CardItemCta({ data }) {
  const canRender = data?.text && data?.href ? true : false;

  return (
    canRender && (
      <div
        data-testid="cardItemCta"
        className={classNames(
          "flex justify-center mt-auto",
          "sm:justify-start",
        )}
      >
        <Button
          href={data.href}
          target={data.target}
          data-ga={JSON.stringify({
            event: "cta_click",
            event_category: "cta",
            event_label: [data.text],
          })}
        >
          {data.text}
        </Button>
      </div>
    )
  );
}

export { CardItemCta };
