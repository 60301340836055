export const LegendContent = ({ payload }) => (
  <div className="flex justify-center mt-16 text-14/20 text-black-primary">
    {payload.map((item) => (
      <div className="mx-3" key={`item-${item.value}`}>
        <div
          className="inline-block w-3 h-3 mr-1 rounded-xl"
          style={{ backgroundColor: item.payload.stroke }}
        ></div>
        {item.payload.yAxisId}
      </div>
    ))}
  </div>
);
