import { useEffect, useState } from "react";
import classNames from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import { appRoutes } from "helpers/constants";
import { hideHeader } from "helpers/layoutHelper";
import { useHeadroom } from "providers/Headroom";
import { BusinessStepNavigationItems } from "join-form/routes/business";
import { ResidentialStepNavigationItems } from "join-form/routes/residential";
import { useRouter } from "next/router";
import { ChevronDown } from "assets/icons";
import { Image } from "components/Image";
import { Link } from "components/Link";
import styles from "./page-header-partial.module.scss";

const variants = {
  visible: {
    opacity: 1,
    transition: {
      delay: 0.2,
    },
  },
  hidden: { opacity: 0 },
};

const transition = {
  duration: 0.2,
};

const headroomConfig = {
  offset: 150,
  tolerance: { up: 40, down: 10 },
  classes: {
    pinned: "header-pinned translate-y-0",
    unpinned: "header-unpinned -translate-y-53 pointer-events-none",
  },
};

function PageHeader({ pathname, location, ...props }) {
  const {
    globalData: { joinHeaderData },
  } = props;
  const { backToHomeLink, genesisHeaderLogo } = joinHeaderData;

  const { headerRef } = useHeadroom(headroomConfig);

  const [hasScrollableContent, setHasScrollableContent] = useState(false);

  useEffect(() => {
    const appInfoBar = document.getElementById("app-info-bar");
    if (appInfoBar && appInfoBar.scrollWidth > appInfoBar.clientWidth) {
      setHasScrollableContent(true);
    } else {
      setHasScrollableContent(false);
    }
  }, [location]);

  useEffect(() => {
    const appInfoBar = document.getElementById("app-info-bar");
    appInfoBar &&
      appInfoBar.addEventListener("scroll", () => {
        if (
          appInfoBar.scrollWidth -
            appInfoBar.clientWidth +
            appInfoBar.scrollLeft >
          5
        ) {
          setHasScrollableContent(true);
        } else {
          setHasScrollableContent(false);
        }
      });
  }, []);

  if (joinHeaderData === null) {
    return null;
  }

  const shouldShowBackToHomeLink =
    pathname.split("?")[0] === appRoutes.customerType;

  return (
    <div
      ref={headerRef}
      className={classNames(
        "fixed z-10 py-5 mx-auto max-w-400",
        "transition-transform duration-300 translate-y",
        "inset-x-5 lg:inset-x-10 ",
      )}
    >
      <div
        className={classNames("rounded-7 md:rounded-10 shadow-200 bg-white", {
          [styles["scrollable"]]: hasScrollableContent,
        })}
        data-testid="page-header-partial"
      >
        <div
          className={`${styles.header} grid-container grid-container--full-width pl-4`}
        >
          <Link href={backToHomeLink.href}>
            <a
              className={styles["header__logo-wrapper"]}
              target={backToHomeLink.target}
            >
              <span className={styles.header__logo}>
                <Image
                  src={genesisHeaderLogo.url}
                  alt={genesisHeaderLogo.label}
                  width={90}
                  height={90}
                />
              </span>
              <span className={styles.header__logo_shadow}></span>
            </a>
          </Link>
          <div
            id="app-info-bar"
            data-testid="app-info-bar"
            className={styles.infoBar}
          >
            <AnimatePresence>
              <div key="appInfoBarSpacer" className="app-info-bar-spacer"></div>
              <div className="app-info-bar-content">
                <BusinessStepNavigationItems />
                <ResidentialStepNavigationItems />
              </div>
            </AnimatePresence>
          </div>
          <AnimatePresence exitBeforeEnter>
            {shouldShowBackToHomeLink && (
              <motion.div
                initial="hidden"
                animate="visible"
                exit="hidden"
                variants={variants}
                transition={transition}
                className={styles.back_container}
              >
                <Link href={backToHomeLink.href}>
                  <a className={styles.back} data-testid="backToHome">
                    <ChevronDown
                      width={24}
                      height={24}
                      className={styles.back_icon}
                    />
                    <span className={styles.back_text}>
                      {backToHomeLink.text}
                    </span>
                  </a>
                </Link>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
}

export default function PageHeaderPartial(props) {
  const location = useRouter();

  if (hideHeader(location)) {
    return null;
  }

  return (
    <PageHeader {...props} pathname={location.asPath} location={location} />
  );
}
