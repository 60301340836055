import Link from "next/link";
import styles from "../address-auto-suggest.module.scss";

export function SuggestionsFooterRequestCallback({
  manualAddressRoute,
  onRequestCallbackClick,
}) {
  return (
    <div className={styles.suggestions_footer}>
      <p className={styles.suggestions_footer_text}>
        Can&#39;t see the correct address?{" "}
        <Link href={manualAddressRoute}>
          <span className={styles.suggestions_footer_link}>
            Enter it manually
          </span>
        </Link>{" "}
        or{" "}
        <button
          onClick={onRequestCallbackClick}
          className={styles.suggestions_footer_link}
        >
          request a call back
        </button>
      </p>
    </div>
  );
}
