import { motion } from "framer-motion";
import { heightFadeTransition, heightFadeVariants } from "./animations";

export default function RegistrationDetails({ data }) {
  const { plate, make, model, year, colour } = data;

  return (
    <motion.div
      initial="initial"
      animate="animate"
      exit="exit"
      variants={heightFadeVariants}
      transition={heightFadeTransition}
      data-testid="regDetails"
      className="text-dark-primary"
    >
      <p className="font-medium text-15/24 md:text-18/28 ">
        Number plate '{plate}' validated
      </p>
      <p className="font-normal text-14/20 md:text-16/24">
        {year}
        <span className="mx-3 text-black-100">&bull;</span>
        {make} {model}
        <span className="mx-3 text-black-100">&bull;</span>
        {colour}
      </p>
    </motion.div>
  );
}
