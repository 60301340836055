import StepNavigationItem from "join-form/components/step-navigation-item";
import { appResidentialRoutes } from "helpers/constants";
import { getProductSelectionTitle } from "helpers/productHelper";
import { connect } from "react-redux";
import { usePathWithoutQuery } from "hooks";

function FuelItem({ title, ...rest }) {
  const {
    businessDetails,
    address,
    addressIcp,
    addressManual,
    fuel,
    success,
    callbackSuccess,
    ...remainingRoutesInfo
  } = appResidentialRoutes;
  const validRoutes = Object.values(remainingRoutesInfo);
  const pathWithoutQuery = usePathWithoutQuery();
  const isVisible = validRoutes.includes(pathWithoutQuery);

  return isVisible ? (
    <StepNavigationItem title={title} path={fuel} fluidWidth={true} {...rest} />
  ) : null;
}

const mapStateToProps = (props, ownProps) => {
  const {
    appState: { products },
  } = props;

  const selectedProductNames = products
    .filter(({ isSelected }) => isSelected)
    .map(({ name }) => name);

  const title = getProductSelectionTitle(selectedProductNames);

  return { title };
};

export default connect(mapStateToProps)(FuelItem);
