import {
  FORM_CAR_REGISTRATION_SUBMIT,
  FORM_CREDIT_CHECK_SUBMIT,
  FORM_PERSONAL_DETAILS_SUBMIT,
  SET_FIXED_PAYMENT_DAY,
  SET_IS_EXISTING_CUSTOMER,
  SET_PAYMENT_METHOD,
  SET_SIGNUP_FOR_ADDITIONAL_PROPERTIES_OR_ICPS,
} from "./appUserStateActionTypes";

export function submitPersonalDetailsForm(payload) {
  return { type: FORM_PERSONAL_DETAILS_SUBMIT, payload };
}

export function submitCreditCheckForm(payload) {
  return { type: FORM_CREDIT_CHECK_SUBMIT, payload };
}

export function setSignupForAdditionalPropertiesOrIcps(payload) {
  return { type: SET_SIGNUP_FOR_ADDITIONAL_PROPERTIES_OR_ICPS, payload };
}

export function unsetSignupForAdditionalPropertiesOrIcps() {
  return { type: SET_SIGNUP_FOR_ADDITIONAL_PROPERTIES_OR_ICPS, payload: false };
}

export function setPaymentMethod(payload) {
  return { type: SET_PAYMENT_METHOD, payload };
}

export function setFixedPaymentDay(payload) {
  return { type: SET_FIXED_PAYMENT_DAY, payload };
}

export function submitCarRegistrationForm(payload) {
  return { type: FORM_CAR_REGISTRATION_SUBMIT, payload };
}

export function setIsExistingCustomer(payload) {
  return { type: SET_IS_EXISTING_CUSTOMER, payload };
}
