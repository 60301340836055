import classNames from "classnames";
import Badge from "join-form/components/badge";
import styles from "./plancard-sidebar.module.scss";

export default function PlanCardSidebar(props) {
  const {
    title,
    subtitle,
    className,
    children,
    shouldWrapWhiteSpace = false,
    showPopularBadge = false,
    ...rest
  } = props;

  const planCardClassName = classNames(styles.card, styles.selected, className);

  return (
    <div className={planCardClassName} {...rest}>
      {showPopularBadge && <Badge text="Most popular" isWhiteTheme />}
      <h3
        className={classNames(styles.title, {
          [styles.whitespace_wrap]: shouldWrapWhiteSpace,
        })}
        data-testid="planCardTitle"
      >
        {title}
      </h3>
      {subtitle && (
        <h4 className={styles.subtitle} data-testid="planCardSubtitle">
          {subtitle}
        </h4>
      )}
      <div className={styles.body}>{children}</div>
      <div className={styles.gradient} />
    </div>
  );
}
