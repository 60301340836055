import {
  EnergyRateContainer,
  EnergyRateItem,
  SeasonalEnergyRateItem,
} from "join-form/components/energy-rate";
import { filterBusinessOffers } from "helpers/offersHelper";
import { isElectricitySelected } from "helpers/productHelper";
import { createElectricityTariffListForDisplay } from "helpers/rateHelper";
import { connect } from "react-redux";

function ElectricityRates(props) {
  const { tariffList, inclGst, highlightOnMobile } = props;

  if (!tariffList) {
    return null;
  }

  return (
    <EnergyRateContainer
      icon="ElectricityBadge"
      title="Electricity"
      data-testid="elecrates"
      highlightOnMobile={highlightOnMobile}
    >
      {
        //todo: the rates are to be ordered and grouped:
        // Fixed charges - any charges marked as FIXED totaled up?
        // Variable charges - any charges marked as VARIABLE and not recognised as a meter
        //    variable charges are grouped together with total calculated
        //    individual variable charges to be displayed as size="small"
        // Meter charges - each charge displayed separately?
        tariffList.map((tariffItem, index) => {
          const {
            title,
            fixtureCalculationExcludingGST,
            fixtureCalculationIncludingGST,
            valueExcludingGST,
            valueIncludingGST,
            seasonalRates,
          } = tariffItem;

          if (seasonalRates) {
            return (
              <SeasonalEnergyRateItem
                title={title}
                seasonalRates={seasonalRates}
                inclGst={inclGst}
                key={`${title}${index}`}
              />
            );
          }

          const subtitle = inclGst
            ? fixtureCalculationIncludingGST
            : fixtureCalculationExcludingGST;

          const value = inclGst ? valueIncludingGST : valueExcludingGST;

          return (
            <EnergyRateItem
              key={index}
              title={title}
              subtitle={subtitle}
              value={value}
            />
          );
        })
      }
    </EnergyRateContainer>
  );
}

const mapStateToProps = (state, ownProps) => {
  const {
    appState: { products },
    businessPlanConfig: { termData },
    businessOffers: { electricityOffers },
  } = state;

  const termItem = termData.find(({ isSelected }) => isSelected) || {};

  const elecOffers =
    termItem.isSelected && isElectricitySelected(products)
      ? filterBusinessOffers(electricityOffers, termItem.termPeriod)
      : [];

  // todo: Call this from SME service so that we have formatted tariffs as soon as we receive offers
  const tariffList = createElectricityTariffListForDisplay(elecOffers);

  return {
    tariffList,
  };
};

export default connect(mapStateToProps)(ElectricityRates);
