import StepNavigationItem from "join-form/components/step-navigation-item";
import { appBusinessRoutes } from "helpers/constants";
import {
  getBusinessAddressPageUrl,
  getShortAddressByType,
} from "helpers/addressHelper";
import { useRouter } from "next/router";
import { connect } from "react-redux";

const {
  businessDetails,
  address,
  addressIcp,
  addressManual,
  success,
  callbackSuccess,
  ...allFuelRouteInfo
} = appBusinessRoutes;
const fuelPageRoutes = Object.values(allFuelRouteInfo);

function AddressItem({ title, changeLinkUrl, ...rest }) {
  const router = useRouter();

  const isVisible = fuelPageRoutes.includes(router.asPath);

  if (!isVisible) {
    return null;
  }

  return <StepNavigationItem title={title} path={changeLinkUrl} {...rest} />;
}

const mapStateToProps = (props, ownProps) => {
  const {
    addressState: {
      addressType,
      addressDetails,
      registryAddressDetails,
      manualAddressDetails,
    },
  } = props;
  const changeLinkUrl = getBusinessAddressPageUrl(addressType);
  const shortAddress = getShortAddressByType({
    addressType,
    addressDetails,
    registryAddressDetails,
    manualAddressDetails,
  });
  const title = shortAddress || "Address details";
  return { title, changeLinkUrl };
};

export default connect(mapStateToProps)(AddressItem);
