export const sidebarFade = {
  initial: {
    x: 40,
    opacity: 0,
  },
  animate: {
    x: 0,
    opacity: 1,
    transition: {
      delay: 0.2,
    },
  },
  exit: {
    y: -60,
    opacity: 0,
    transition: {
      type: "tween",
    },
  },
};

export const heightFadeVariants = {
  initial: { height: 0, opacity: 0 },
  animate: { height: "auto", opacity: 1 },
  exit: { height: 0, opacity: 0 },
};

export const heightFadeTransition = { type: "tween" };
