import Axios from "axios";

const axiosOptions = {
  auth: {
    username: process.env.NEXT_PUBLIC_AUTH_USER_NAME,
    password: process.env.NEXT_PUBLIC_AUTH_PASSWORD,
  },
};

export async function createSmeCallbackLead(requestObject) {
  try {
    return await Axios.post(
      "/api/onlinesignup/smecreatelead",
      requestObject,
      axiosOptions,
    );
  } catch (ex) {
    return Promise.reject(ex);
  }
}
