import { motion } from "framer-motion";
import Link from "next/link";
import styles from "../address-auto-suggest.module.scss";
import { heightFadeTransition, heightFadeVariants } from "../animations";

export default function NoResults(props = {}) {
  const { manualAddressRoute, onRequestCallbackClick } = props;

  const showCallToActions =
    manualAddressRoute && onRequestCallbackClick ? true : false;

  return (
    <motion.div
      initial="initial"
      animate="animate"
      exit="exit"
      variants={heightFadeVariants}
      transition={heightFadeTransition}
      data-testid="suggestionsNoResultsContainer"
    >
      <div className={styles.no_results_container}>
        <h4 className={styles.no_results_title}>
          We couldn&#39;t find your address.
        </h4>
        {showCallToActions ? (
          <p className={styles.no_results_text}>
            <Link href={manualAddressRoute}>
              <span className={styles.no_results_link}>Enter it manually</span>
            </Link>{" "}
            or{" "}
            <button
              onClick={onRequestCallbackClick}
              className={styles.no_results_link}
              data-testid="noResultsCallbackButton"
            >
              request a call back
            </button>
          </p>
        ) : null}
      </div>
    </motion.div>
  );
}
