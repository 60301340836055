import { useState } from "react";
import classNames from "classnames";
import { RatesHeading } from "join-form/components/energy-rate";
import BottledGasRates from "./BottledGasRates";
import ElectricityRates from "./ElectricityRates";
import NaturalGasRates from "./NaturalGasRates";

export default function Rates({
  ratesHeadingPlanType,
  planType,
  usageType,
  children,
  className,
  ...rest
}) {
  const [inclGst, setInclGst] = useState(true);

  return (
    <div className={classNames("space-y-2", className)} {...rest}>
      <RatesHeading
        planType={ratesHeadingPlanType ?? planType}
        inclGst={inclGst}
        setInclGst={setInclGst}
      />
      <ElectricityRates
        planType={planType}
        usageType={usageType}
        inclGst={inclGst}
      />
      <NaturalGasRates
        planType={planType}
        usageType={usageType}
        inclGst={inclGst}
      />
      <BottledGasRates
        planType={planType}
        usageType={usageType}
        inclGst={inclGst}
      />
      {children}
    </div>
  );
}
