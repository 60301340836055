import classNames from "classnames";
import { Image } from "components/Image";
import { ImageBackgroundGradientAnimation, ScaleAnimation } from "./animations";

function ImageCard({ image, isRightAlignment, bgPosition }) {
  const isTopLeft = bgPosition === "topBottom" && !isRightAlignment;
  const isBottomRight = bgPosition === "topBottom" && isRightAlignment;
  return (
    <div
      className={classNames(
        "relative grid grid-cols-7 md:gap-7 lg:gap-8 max-w-xs sm:max-w-md md:max-w-full",
        "grid-cols-12 lg:grid-cols-11",
        { "mb-20": isBottomRight },
      )}
      data-testid="imageCard"
    >
      <div
        className={classNames(
          "absolute inset-x-0 inset-y-10 grid grid-cols-7 md:gap-7 lg:gap-8",
          "grid-cols-12 lg:grid-cols-11",
          { "-mt-20": isTopLeft },
          { "-mb-20": isBottomRight },
        )}
      >
        <ImageBackgroundGradientAnimation
          isImageRightAligned={isRightAlignment}
          className={classNames(
            "bg-energy-gradient rounded-7 md:rounded-10 shadow-300 md:shadow-700",
            {
              "col-span-11 sm:col-span-7 md:col-span-10 lg:col-span-9":
                !isRightAlignment,
            },
            {
              "col-span-11 col-start-2 sm:col-span-7 sm:col-start-2 md:col-span-8 md:col-start-5 lg:col-span-9 lg:col-start-3":
                isRightAlignment,
            },
          )}
        />
      </div>
      <div
        className={classNames(
          "col-span-11 sm:col-span-7 md:col-span-10 md:col-start-2 lg:col-span-9 lg:col-start-2",
          "rounded-7 md:rounded-10 overflow-hidden",
          {
            "col-start-2 sm:col-start-2": !isRightAlignment,
          },
        )}
      >
        <ScaleAnimation>
          <Image
            alt={image.label}
            src={image.url}
            width={image.width}
            height={image.height}
            layout="responsive"
          />
        </ScaleAnimation>
      </div>
    </div>
  );
}

export { ImageCard };
