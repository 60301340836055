import { FormFieldTerm, Input, MaskedInput } from "join-form/components/form-controls";
import { PAYMENT_METHOD_DIRECT_DEBIT } from "helpers/constants";
import * as Yup from "yup";
import { InlineBlockEditor } from "components/BlockEditor";
import { getDebitTermConditionId } from "helpers/debitTermsConditionsHelper";
import styles from "./direct-debit-form.module.scss";

export const directDebitFormValidation = Yup.object().shape({
  bankAccountNo: Yup.string().when("paymentMethod", {
    is: (value) => value === PAYMENT_METHOD_DIRECT_DEBIT,
    then: Yup.string()
      .required("Account number is required")
      .matches(/^[0-9 -]+$/, "Please enter a valid bank account number")
      .min(18, "Please enter a valid bank account number"),
  }),
  bankAccountName: Yup.string().when("paymentMethod", {
    is: (value) => value === PAYMENT_METHOD_DIRECT_DEBIT,
    then: Yup.string()
      .required("Account holder name is required")
      .max(255, "Account holder name cannot be more than 255 characters"),
  }),
});

export default function DirectDebitForm(props) {
  const { values, touched, errors, handleChange, debitTermsConditions } = props;
  return (
    <div className={styles.direct_debit_form}>
      <MaskedInput
        size="28"
        name="bankAccountNo"
        placeholder="Account number"
        value={values.bankAccountNo}
        mask="99-9999-9999999-999"
        onChange={handleChange}
        errorMessage={errors.bankAccountNo}
        showError={errors.bankAccountNo && touched.bankAccountNo}
      />
      <Input
        size="28"
        name="bankAccountName"
        placeholder="Account holder name"
        value={values.bankAccountName}
        onChange={handleChange}
        errorMessage={errors.bankAccountName}
        showError={errors.bankAccountName && touched.bankAccountName}
      />
      {debitTermsConditions?.map(({ fields }, index) => {
        const itemId = getDebitTermConditionId(fields, index);
        return fields.term && (
          <FormFieldTerm
            shadowless
            key={itemId}
            name={itemId}
            testId="debitTermsConditionsItem"
            value={values[itemId]}
            checked={values[itemId] === true}
            onChange={handleChange}
            size="medium"
            errorMessage={errors[itemId]}
            showError={errors[itemId] && touched[itemId]}
          >
            <InlineBlockEditor
              jsonString={fields.term}
              className="prose-p:tex-15/24 prose-p:md:text-18/28"
            />
          </FormFieldTerm>
        );
      })}
    </div>
  );
}
