import { AccordionPanel as ReachAccordionPanel } from "@reach/accordion";
import classNames from "classnames";

function AccordionPanel({ className: classNameProp, children }) {
  const mergedClassNames = classNames("pb-5", classNameProp);
  return (
    <ReachAccordionPanel
      data-testid="accordionPanel"
      className={mergedClassNames}
    >
      {children}
    </ReachAccordionPanel>
  );
}

export { AccordionPanel };
