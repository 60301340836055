import Skeleton from "react-loading-skeleton";
import { useQuery } from "react-query";
import { Table } from "components/BlockEditor/Blocks/Table";
import { ModuleWrapper } from "components/Wrappers/ModuleWrapper";
import { HYDRO } from "helpers/constants";
import { fetchReq, useQueryConfig } from "./HydroHelper";

const tableHeaders = {
  [HYDRO.type.rainfall]: [
    "Monitoring site",
    "Rainfall in last hour<br />(mm)",
    "Measurement<br />(date and time)",
    "7 day total rainfall<br />(mm)",
  ],
  [HYDRO.type.river]: [
    "Monitoring site",
    "Flow<br />(m<sup>3</sup>/sec)",
    "Measurement<br />(date and time)",
    "7 day max flow<br />(m<sup>3</sup>/sec)",
    "Measurement<br />(date and time)",
  ],
  [HYDRO.type.lake]: [
    "Monitoring site",
    "Lake level in last hour<br />(m)",
    "Measurement<br />(date and time)",
    "7 day max lake level<br />(m)",
    "Measurement<br />(date and time)",
  ],
  [HYDRO.type.spillFlow]: [
    "Monitoring site",
    "Spill flow in last hour<br />(m<sup>3</sup>/sec)",
    "Measurement<br />(date and time)",
    "7 day max spill flow<br />(m<sup>3</sup>/sec)",
    "Measurement<br />(date and time)",
  ],
};

const TableSummary = ({ data, groupId }) => {
  const tableData = {
    content: [tableHeaders[groupId], ...data],
    withHeadings: true,
  };
  return <Table data={tableData} />;
};

const select = (res, region, groupId) => {
  const tableData = res
    .filter((item) => {
      return item.region === region && item.groupId === groupId;
    })
    .map((item) => {
      const tableRow = [
        `<a href="${item.hydroGraphUrl}">${item.site}</a>`,
        item.lastHour,
        item.at,
        item.day7Max,
      ];
      if (groupId !== HYDRO.type.rainfall) {
        tableRow.push(item.day7DateOfMax);
      }
      return tableRow;
    });

  return tableData;
};
export const HydroTable = ({ module }) => {
  const { powerScheme: region, tableType: groupId } = module?.fields ?? {};
  const { data, isError, isLoading } = useQuery(HYDRO.queryId, fetchReq, {
    ...useQueryConfig,
    select: (res) => select(res, region, groupId),
  });

  if (isError)
    return <p data-testid="hydro-table-error">Error fetching data</p>;

  return (
    <ModuleWrapper psnid="psn_hydro_table">
      {isLoading ? (
        <Skeleton containerTestId="hydro-table-loader" count={2} />
      ) : (
        <div className="prose md:prose-md max-w-none">
          <TableSummary data={data} groupId={groupId} />
        </div>
      )}
    </ModuleWrapper>
  );
};
