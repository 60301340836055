import React from "react";
import classNames from "classnames";
import { useScreenSize } from "hooks";
import { compact } from "lodash-es";
import { getContentWithLineBreaks } from "helpers/htmlHelper";
import {
  FadeInLeftPrimaryAnimation,
  FadeInLeftSecondaryAnimation,
  FadeInLeftTextAnimation,
  FadeInWidthAnimation,
} from "./animations";

function ArticleHeader({
  title,
  description,
  ctaPrimary = null,
  ctaSecondary = null,
  metaData = [],
}) {
  const { isMd } = useScreenSize();
  const filteredMetaData = compact(metaData);
  const hasCtas =
    React.isValidElement(ctaPrimary) || React.isValidElement(ctaSecondary);

  return (
    <div
      data-testid="sansImage"
      className="mb-10 md:first:pt-37 lg:first:pt-45 first:pt-21 sm:mb-20"
    >
      <div
        className={classNames(
          "px-4 sm:px-15 md:px-17 lg:px-23 xl:px-46",
          "mx-auto",
          "max-w-full w-120 sm:w-210 md:w-auto",
        )}
      >
        <div className="pt-7 md:pt-10">
          <div
            data-testid="headerContent"
            className="mb-15 sm:max-w-162 md:max-w-175"
          >
            {filteredMetaData.length ? (
              <ul
                data-testid="metaData"
                className="flex mb-5 space-x-2 text-black-primary"
              >
                {filteredMetaData.map((metaDataItem, index) => {
                  const isFirst = index === 0;
                  return (
                    <React.Fragment key={metaDataItem}>
                      {!isFirst && (
                        <li className="flex items-center">
                          <span className="w-1 h-1 rounded bg-orange-primary" />
                        </li>
                      )}
                      <li>{metaDataItem}</li>
                    </React.Fragment>
                  );
                })}
              </ul>
            ) : null}
            <FadeInLeftTextAnimation screen={isMd}>
              <h1
                className={classNames(
                  "mb-3 md:mb-5",
                  "font-bold text-dark-primary text-32/36 md:text-44/52",
                )}
              >
                {title}
              </h1>
              <p
                data-testid="headerContentDescription"
                className="text-black-primary text-18/28 md:text-20/32"
              >
                {getContentWithLineBreaks(description)}
              </p>
            </FadeInLeftTextAnimation>
            {hasCtas && (
              <div
                data-testid="ctaSection"
                className="flex flex-wrap mt-8 md:mt-10"
              >
                <FadeInLeftPrimaryAnimation screen={isMd}>
                  {ctaPrimary}
                </FadeInLeftPrimaryAnimation>
                <FadeInLeftSecondaryAnimation screen={isMd}>
                  {ctaSecondary}
                </FadeInLeftSecondaryAnimation>
              </div>
            )}
          </div>
        </div>
        <FadeInWidthAnimation className="w-full h-1 rounded-full bg-orange-primary md:col-end-24 md:col-start-2" />
      </div>
    </div>
  );
}

export { ArticleHeader };
