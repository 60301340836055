import classNames from "classnames";
import { useRouter } from "next/router";
import { ArrowRight } from "assets/icons";
import { Link } from "components/Link";
import { getPageLinkProps } from "../getPageLinkProps";

function NavLink({ item, onClick, parentLabel }) {
  const { url, target } = getPageLinkProps(item);
  const router = useRouter();
  const isActive = router.asPath === url;
  return (
    <Link href={url}>
      <a
        onClick={onClick}
        className={classNames(
          "flex items-center py-2 text-sm group px-7 text-black-primary",
          {
            "font-bold text-dark-primary": isActive,
          },
        )}
        target={target}
        data-ga={JSON.stringify({
          content_tertiary: "secondary menu",
          event_label: [...parentLabel, item.menuText],
          event_category: "secondary menu",
        })}
      >
        <span className="mr-2">{item.menuText}</span>
        <ArrowRight className="w-3 h-3 transition duration-150 -translate-x-2 opacity-0 fill-current group-hover:opacity-100 group-hover:translate-x-0 group-active:translate-x-2" />
      </a>
    </Link>
  );
}

export { NavLink };
