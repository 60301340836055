import classNames from "classnames";
import { sortBy } from "lodash-es";
import { ModuleWrapper } from "components/Wrappers";
import { THEME } from "helpers/constants";
import { FadeInBottomContainer } from "./animations";
import { ContentFeatureCardItem } from "./ContentFeatureCardItem";

function ContentFeatureCardStatic({ module }) {
  const { snippet, theme, anchor } = module.fields;
  const variantStyles = {
    "shadow-white-glow px-5 py-7 sm:p-10 md:pb-15 md:pt-13 lg:px-15 bg-day-gradient":
      theme === THEME.orange,
    "shadow-white-glow px-5 py-7 sm:p-10 md:pb-15 md:pt-13 lg:px-15 backdrop-blur-4xl bg-glass-grey":
      theme === THEME.glass,
    "pt-0": theme === THEME.white,
  };

  const sortedSnippetContent = sortBy(snippet, ["properties.itemOrder"]);

  return (
    <ModuleWrapper
      id={anchor}
      testid="contentFeatureCardStatic"
      psnid="psn_content_feature_card_static"
    >
      <FadeInBottomContainer>
        <div
          className={classNames(
            "rounded-7 space-y-10",
            "md:rounded-10 md:space-y-0 md:flex md:space-x-7",
            "lg:space-x-8",
            variantStyles,
          )}
        >
          {sortedSnippetContent.map((item) => (
            <ContentFeatureCardItem
              key={item.contentID}
              item={item}
              theme={theme}
              className="md:flex-1"
            />
          ))}
        </div>
      </FadeInBottomContainer>
    </ModuleWrapper>
  );
}
export { ContentFeatureCardStatic };
