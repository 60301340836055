import { useState } from "react";
import classNames from "classnames";
import { motion } from "framer-motion";
import FeatureItem, {
  FeatureItemContainer,
} from "join-form/components/feature-item";
import PlanCardSidebar from "join-form/components/plancard-sidebar";
import { getDualFuelDiscount, getPaymentMethodDiscount } from "helpers/joiningDiscountsHelper";
import { filterBusinessOffers } from "helpers/offersHelper";
import {
  isBottledGasSelected,
  isElectricitySelected,
  isNaturalGasSelected,
} from "helpers/productHelper";
import { Rates, RatesDisclaimer } from "join-form/partials/rates/business";
import { connect } from "react-redux";
import { sidebarFade } from "./animations";
import AutoPayFeature from "./AutoPayFeature";
import EnergyPlanButton from "./EnergyPlanButton";
import styles from "./page-sidebar-partial.module.scss";
import TermFeature from "./TermFeature";
import TermHeader from "./TermHeader";

function PageSidebarPartial(props) {
  const {
    termItem,
    paymentMethod,
    multiConnectDiscount,
    selectedPaymentMethodDiscount,
    hasMatchedTariffs,
  } = props;

  const [plansVisible, togglePlans] = useState(false);

  const onTogglePlansClick = (e) => {
    const newValue = plansVisible ? false : true;
    togglePlans(newValue);
  };

  const plansVisibleCssClass = plansVisible ? styles.plans_visible : "";

  return (
    <motion.div
      initial="initial"
      animate="animate"
      exit="exit"
      variants={sidebarFade}
      className={`${styles.container} ${plansVisibleCssClass}`}
      data-testid="businessSidebar"
    >
      <EnergyPlanButton
        onClick={onTogglePlansClick}
        termItem={termItem}
        multiConnectDiscount={multiConnectDiscount}
        paymentMethodDiscount={selectedPaymentMethodDiscount}
      />
      <div className={styles.body}>
        <TermHeader
          className={classNames(
            "mt-3 md:mt-0 mb-5",
            plansVisible ? "block" : "hidden md:block",
          )}
        />
        <FeatureItemContainer className={styles.feature_list_container}>
          {multiConnectDiscount > 0 && (
            <FeatureItem
              data-testid="multiConnectDiscountFeature"
              type="complete"
            >
              {multiConnectDiscount}% Multi-Connect discount
            </FeatureItem>
          )}
          <TermFeature termItem={termItem} data-testid="planTerm" />
          <AutoPayFeature paymentMethod={paymentMethod} />
          {/* <li className={styles.feature}>
              <Icon name="Success" size="18" className={styles.feature_icon} />
              <span className={styles.feature_text}>Auto-pay discount</span>
            </li> */}
        </FeatureItemContainer>
        <PlanCardSidebar
          title="Business Energy Plan"
          subtitle="More choice + more rewards"
          data-testid="businessEnergyPlanCard"
          className={styles.plan_card_container}
          shouldWrapWhiteSpace
        >
          <FeatureItemContainer theme="tertiary">
            <FeatureItem
              type="highlight"
              fontSize={14}
              bottomSpacing={20}
              showSeparator={false}
            >
              Flexible or fixed-term options
            </FeatureItem>
            <FeatureItem
              type="highlight"
              fontSize={14}
              bottomSpacing={20}
              showSeparator={false}
            >
              Choose how you pay
            </FeatureItem>
            <FeatureItem
              type="highlight"
              fontSize={14}
              bottomSpacing={20}
              showSeparator={false}
            >
              Be in control with Energy IQ
            </FeatureItem>
            {multiConnectDiscount ? (
              <FeatureItem
                type="highlight"
                fontSize={14}
                data-testid="multiConnectDiscount"
                bottomSpacing={20}
                showSeparator={false}
              >
                {multiConnectDiscount}% Multi-Connect discount
              </FeatureItem>
            ) : null}
          </FeatureItemContainer>
        </PlanCardSidebar>
        {termItem.isSelected && hasMatchedTariffs && (
          <Rates highlightOnMobile>
            <RatesDisclaimer />
          </Rates>
        )}
      </div>
    </motion.div>
  );
}

const defaultTermItem = {
  codeName: "",
  discountPercentage: 0,
  credit: "",
  isSelected: false,
};

const mapStateToProps = (state, ownProps) => {
  const {
    appState: { products },
    businessPlanConfig: { termData },
    appUserState: { paymentMethod },
    businessOffers: { electricityOffers, naturalGasOffers, bottledGasOffers },
  } = state;

  const termItem =
    termData.find(({ isSelected }) => isSelected) || defaultTermItem;

  const multiConnectDiscount = getDualFuelDiscount(products, true);

  const selectedPaymentMethodDiscount = getPaymentMethodDiscount(
    {
      paymentMethod,
    },
    true,
  );

  let hasMatchedTariffs = termItem.isSelected;
  if (isElectricitySelected(products)) {
    hasMatchedTariffs &&=
      filterBusinessOffers(electricityOffers, termItem.termPeriod).length === 1;
  }
  if (isNaturalGasSelected(products)) {
    hasMatchedTariffs &&=
      filterBusinessOffers(naturalGasOffers, termItem.termPeriod).length === 1;
  }
  if (isBottledGasSelected(products)) {
    hasMatchedTariffs &&=
      filterBusinessOffers(bottledGasOffers, termItem.termPeriod).length === 1;
  }

  return {
    termItem,
    paymentMethod,
    multiConnectDiscount,
    selectedPaymentMethodDiscount,
    hasMatchedTariffs,
  };
};

export default connect(mapStateToProps)(PageSidebarPartial);
