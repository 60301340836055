import { PROPERTY_ACCESS_REASONS } from "./constants";

const propertyAccessOptions = [
  {
    text: "Dog",
    value: PROPERTY_ACCESS_REASONS.DOG,
  },
  {
    text: "Security code required",
    value: PROPERTY_ACCESS_REASONS.SECURITY_CODE,
  },
  {
    text: "Other",
    value: PROPERTY_ACCESS_REASONS.OTHER,
  },
];

const cylinderTypeOptions = [
  {
    text: "45kg standard",
    value: "45",
  },
  {
    text: "9kg",
    value: "09",
  },
  {
    text: "12kg",
    value: "12",
  },
  {
    text: "18kg",
    value: "18",
  },
  {
    text: "27kg",
    value: "27",
  },
  {
    text: "15kg for forklift bottle",
    value: "15",
  },
  {
    text: "20kg for forklift bottle",
    value: "20",
  },
];

const cylinderQuantityOptions = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
];

export { propertyAccessOptions, cylinderTypeOptions, cylinderQuantityOptions };
