import { useState } from "react";
import { createSelector } from "@reduxjs/toolkit";
import { AnimatePresence, motion } from "framer-motion";
import { setPlanTerm } from "actions/residentialPlanConfigActions";
import Feedback from "join-form/components/feedback";
import Intro from "join-form/components/intro";
import StepProgressionButton from "join-form/components/step-progression-button";
import {
  appResidentialRoutes,
  RESIDENTIAL_PLAN_TERM_FIXED12,
} from "helpers/constants";
import { trackResidentialContinueButton } from "helpers/gtmHelper";
import { applyResidentialTermCredits } from "helpers/joiningCreditHelper";
import { applyResidentialTermDiscounts } from "helpers/joiningDiscountsHelper";
import {
  selectAppState,
  selectResidentialPlanConfig,
} from "reducers/selector";
import { useRouter } from "next/router";
import { useDispatch, useSelector } from "react-redux";
import { InlineBlockEditor } from "components/BlockEditor";
import { getPageData } from "helpers/getPageData";
import {
  footerFade,
  footerFadeTransition,
  headerFade,
  heightFadeTransition,
  heightFadeVariants,
  itemContainer,
} from "./animations";
import styles from "./term-select-page.module.scss";
import TermItem from "./TermItem";

const stateSelector = createSelector(
  [selectAppState, selectResidentialPlanConfig],
  (appState, residentialPlanConfig) => {
    const { planType, products } = appState;
    const { termData } = residentialPlanConfig;

    const isValid = termData.some(({ isSelected }) => isSelected);
    const termDataWithDiscountsApplied = applyResidentialTermDiscounts({
      termData,
      planType,
    });

    const termDataWithDiscountAndCreditsApplied = applyResidentialTermCredits({
      termData: termDataWithDiscountsApplied,
      planType,
      products,
    });

    return {
      termData: termDataWithDiscountAndCreditsApplied,
      isValid,
    };
  },
);

const modules = ["GE_JOIN_Intro_Header", "GE_JOIN_Fine_Print"];

export function TermSelectPage(props) {
  const pageData = getPageData({ modules, page: props.page });
  const [errorMessage, setErrorMessage] = useState(null);

  const { isValid, termData } = useSelector(stateSelector);
  const dispatch = useDispatch();

  const router = useRouter();

  const onTermClick = (termCodeName) => {
    dispatch(setPlanTerm(termCodeName));
    if (errorMessage) {
      setErrorMessage(null);
    }
  };

  const onContinueClick = (e) => {
    e.preventDefault();

    if (!isValid) {
      setErrorMessage("Please select a term");
    } else {
      const { codeName } = termData.find(({ isSelected }) => isSelected);

      trackResidentialContinueButton({
        event: "select_term",
        content_tertiary: "select term",
        selection:
          codeName === RESIDENTIAL_PLAN_TERM_FIXED12
            ? "12 month fixed"
            : "flexible, no fixed term",
      });
      router.push({ pathname: appResidentialRoutes.contactDetails });
    }
  };

  return (
    <motion.div
      className={styles.container}
      exit="undefined"
      data-testid="termSelectPage"
    >
      <Intro>
        {(introStyles) => (
          <motion.div
            initial="initial"
            animate="animate"
            exit="exit"
            variants={headerFade}
          >
            <h1 className={introStyles.title}>{pageData.fields.title}</h1>
          </motion.div>
        )}
      </Intro>
      <motion.div
        initial="initial"
        animate="animate"
        exit="exit"
        variants={itemContainer}
        className={styles.body}
      >
        {termData.map((props) => (
          <TermItem
            key={props.codeName}
            {...props}
            onClick={onTermClick}
            size="large"
          />
        ))}
      </motion.div>
      <motion.div
        initial="initial"
        animate="animate"
        exit="exit"
        variants={footerFade}
        transition={footerFadeTransition}
        className={styles.small_print}
      >
        <InlineBlockEditor
          jsonString={pageData.fields.finePrint}
          enableProse={false}
        />
      </motion.div>
      <div className="mt-10 overflow-hidden md:mt-15 lg:mt-20">
        <AnimatePresence>
          {errorMessage && (
            <motion.div
              initial="initial"
              animate="animate"
              exit="exit"
              variants={heightFadeVariants}
              transition={heightFadeTransition}
              className="mb-8 md:mb-10"
            >
              <Feedback type="error" title="Error" data-testid="errorMessage">
                {errorMessage}
              </Feedback>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
      <StepProgressionButton
        disabled={!isValid}
        onContinueClick={onContinueClick}
        backLink={appResidentialRoutes.planSelect}
      />
    </motion.div>
  );
}

export default TermSelectPage;
