import { AnimatePresence } from "framer-motion";
import { appBusinessRoutes, appResidentialRoutes } from "helpers/constants";
import { useRouter } from "next/router";
import BusinessSidebar from "./business/BusinessSidebar";
import ResidentialSidebar from "./residential/ResidentialSidebar";

const businessSidebarRoutes = [
  appBusinessRoutes.termSelect,
  appBusinessRoutes.creditCheck,
  appBusinessRoutes.property,
  appBusinessRoutes.gas,
  appBusinessRoutes.payment,
];

const residentialSidebarRoutes = [
  appResidentialRoutes.contactDetails,
  appResidentialRoutes.termSelect,
  appResidentialRoutes.creditCheck,
  appResidentialRoutes.carRegistration,
  appResidentialRoutes.property,
  appResidentialRoutes.gas,
  appResidentialRoutes.payment,
];

const generateKey = (lowerCasedPathname) => {
  if (businessSidebarRoutes.includes(lowerCasedPathname)) {
    return "businessSidebar";
  }
  if (residentialSidebarRoutes.includes(lowerCasedPathname)) {
    return "residentialSidebar";
  }
  return "nonSidebarPage";
};

export function Sidebar(props) {
  const location = useRouter();
  const { asPath: pathname } = location;

  const key = generateKey(pathname.toLowerCase());
  const isBusinessSidebar = businessSidebarRoutes.includes(pathname);
  const isResidentialSidebar = residentialSidebarRoutes.includes(pathname);

  return (
    <AnimatePresence exitBeforeEnter>
      {isBusinessSidebar ? <BusinessSidebar /> : null}
      {isResidentialSidebar ? <ResidentialSidebar /> : null}
    </AnimatePresence>
  );
}
