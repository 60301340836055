import { motion } from "framer-motion";
import Badge from "join-form/components/badge";
import ButtonRadioSelection from "join-form/components/button-radio-selection";
import { RESIDENTIAL_PLAN_TERM_FIXED12 } from "helpers/constants";
import { itemFade } from "./animations";
import styles from "./term-select-page.module.scss";

function generateSubtitle({ discountPercentage, credit }) {
  const discountExists = discountPercentage > 0;
  const creditExists = credit !== "";
  if (discountExists && creditExists) {
    return ` ${credit} credit + ${discountPercentage}% discount`;
  }
  if (discountExists) {
    return `+${discountPercentage}% discount`;
  }
  if (creditExists) {
    return `${credit} credit`;
  }
  return "";
}

export default function TermItem({
  codeName,
  displayName,
  discountPercentage,
  credit,
  isSelected,
  onClick,
  size,
}) {
  const onButtonClick = () => {
    onClick(codeName);
  };

  const subtitle = generateSubtitle({ discountPercentage, credit });

  return (
    <motion.div variants={itemFade} className={styles.selection_item}>
      {subtitle !== "" ? (
        <ButtonRadioSelection
          onClick={onButtonClick}
          isSelected={isSelected}
          subTitle={subtitle}
          data-testid={codeName}
          size={size}
        >
          {displayName} *
          {codeName === RESIDENTIAL_PLAN_TERM_FIXED12 && (
            <Badge
              text="Most popular"
              borderRadiusClass="rounded-tr-4 rounded-bl-4 md:rounded-tr-5 md:rounded-bl-5"
            />
          )}
        </ButtonRadioSelection>
      ) : (
        <ButtonRadioSelection
          onClick={onButtonClick}
          isSelected={isSelected}
          data-testid={codeName}
          size={size}
        >
          {displayName}
        </ButtonRadioSelection>
      )}
    </motion.div>
  );
}
