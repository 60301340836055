import { gtmCreateEcommerceObjectForResidential } from "actions/gtmActions";
import { getFullAddressByType } from "helpers/addressHelper";
import {
  isBottledGasSelected,
  isElectricitySelected,
  isNaturalGasSelected,
} from "helpers/productHelper";
import { createResiCallbackLead } from "join-form/services/resiLeadGenerationService";

export function createResiSimpleLead() {
  return async function createSimpleLeadWithStoreState(dispatch, getState) {
    const {
      addressState: {
        addressType,
        addressDetails,
        registryAddressDetails,
        manualAddressDetails,
      },
      appState: { products },
      appUserState: {
        title,
        firstName,
        lastName,
        email: emailAddress,
        phone,
        signupForAdditionalPropertiesOrIcps,
      },
      icp: { elecIcp, gasIcp },
    } = getState();

    const address = getFullAddressByType({
      addressType,
      addressDetails,
      registryAddressDetails,
      manualAddressDetails,
    });

    const electricity = isElectricitySelected(products);

    const electricityIcp = electricity ? elecIcp : "";

    const naturalGas = isNaturalGasSelected(products);

    const naturalGasIcp = naturalGas ? gasIcp : "";

    const bottledGas = isBottledGasSelected(products);

    const leadData = {
      address,
      isMultipleAddresses: signupForAdditionalPropertiesOrIcps,
      electricity,
      electricityIcp,
      naturalGas,
      naturalGasIcp,
      bottledGas,
      title,
      firstName,
      lastName,
      emailAddress,
      phone,
    };

    await createResiCallbackLead(leadData);
    dispatch(gtmCreateEcommerceObjectForResidential({ isCallbackFlow: true }));
  };
}
