import styles from "../address-auto-suggest.module.scss";

export function SuggestionsFooterConfirmLater({
  visible,
  addressFromFinder,
  onConfirmLaterClick,
}) {
  function onConfirmLaterClickInternal(e) {
    e.preventDefault();
    onConfirmLaterClick({ addressFromFinder });
  }

  return visible ? (
    <div className={styles.suggestions_footer}>
      <p className={styles.suggestions_footer_text}>
        Can&#39;t see the correct address?{" "}
        <button
          onClick={onConfirmLaterClickInternal}
          className={styles.suggestions_footer_link}
        >
          Keep going and confirm later
        </button>
      </p>
    </div>
  ) : null;
}
