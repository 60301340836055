import axios from "axios";
import { useQuery } from "react-query";
import { PercentChangeLabel } from "components/Chart/PercentChangeLabel";
import { getToken } from "helpers/apiHelper";
import { SHARE_PRICE_API } from "helpers/constants";
import {
  formatCurrency,
  superscriptDollarSymbol,
} from "helpers/numberStringHelper";
import { FadeInHeightAnimation } from "./animations";

// format data req
const formatPriceData = (priceQuoteData) => {
  const { last, move_percent: movePercent } = priceQuoteData;

  const priceQuote = {
    last,
    movePercent,
  };
  return {
    priceQuote,
  };
};

const fetchReq = async () => {
  try {
    // fetch jwt token once if necessary
    const accessToken = await getToken();

    // request
    const url = SHARE_PRICE_API.urls.find((apiUrl) =>
      apiUrl.includes("priceQuote"),
    );
    const { data } = await axios.get(url, {
      baseURL: process.env.NEXT_PUBLIC_MULE_API_HOST_NAME,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return formatPriceData(data.document.feed.issuer);
  } catch (ex) {
    throw new Error(ex);
  }
};

function SharePriceBadge() {
  const { data, isLoading, isError } = useQuery("headerSharePrice", fetchReq, {
    refetchOnWindowFocus: false,
    refetchOnmount: false,
    refetchOnReconnect: false,
    staleTime: SHARE_PRICE_API.refetchInterval,
    refetchInterval: SHARE_PRICE_API.refetchInterval,
  });

  if (isError || isLoading) {
    return null;
  }

  return (
    <FadeInHeightAnimation>
      <div className="flex mb-3 md:mb-5">
        <div className="flex items-center px-2 gap-x-2 md:gap-x-3 bg-glass-dark-grey rounded-2">
          <h3 className="my-1 font-bold text-white text-20/24 md:text-32/36">
            {superscriptDollarSymbol(
              formatCurrency(data.priceQuote.last),
              "font-bold text-14/22 md:text-18/30 -top-1 md:-top-2",
            )}
          </h3>
          <PercentChangeLabel movePercent={data.priceQuote.movePercent} />
        </div>
      </div>
    </FadeInHeightAnimation>
  );
}

export { SharePriceBadge };
