import StepNavigationItem from "join-form/components/step-navigation-item";
import { appResidentialRoutes } from "helpers/constants";
import { usePathWithoutQuery } from "hooks";

export default function ContactDetailsItem({ ...props }) {
  const {
    address,
    addressIcp,
    addressManual,
    fuel,
    occupancy,
    planSelect,
    termSelect,
    contactDetails,
    success,
    callback,
    callbackSuccess,
    carRegistration,
    ...allContactDetailsRouteInfo
  } = appResidentialRoutes;
  const contactDetailPageRoutes = Object.values(allContactDetailsRouteInfo);
  const pathWithoutQuery = usePathWithoutQuery();
  const isVisible = contactDetailPageRoutes.includes(pathWithoutQuery);

  return isVisible ? (
    <StepNavigationItem
      title="Personal Details"
      path={contactDetails}
      {...props}
    />
  ) : null;
}
