import { runAsync } from "helpers";
import { get } from "lodash-es";

function getLinkProps(items) {
  return items
    .map((item) => {
      const text = get(item, "fields.link.text", "");
      const link = get(item, "fields.link.href", "");
      const target = get(item, "fields.link.target", "");
      const itemOrder = get(item, "properties.itemOrder", 0);
      return {
        text,
        link,
        target,
        itemOrder,
      };
    })
    .sort((first, second) => first.itemOrder - second.itemOrder);
}

function getIconsProps(items) {
  return items
    .map((item) => {
      const image = get(item, "fields.iconsimage.url", "");
      const altText = get(item, "fields.iconsimage.label", "");
      const link = get(item, "fields.iconslink.href", "");
      const target = get(item, "fields.iconslink.target", "");
      const itemOrder = get(item, "properties.itemOrder", 0);
      return {
        image,
        altText,
        link,
        target,
        itemOrder,
      };
    })
    .sort((first, second) => first.itemOrder - second.itemOrder);
}

function createContentProps(contentItem) {
  const logoUrl = get(
    contentItem,
    "fields.genesisfooterlogo.url",
    "/genesis-logo.png",
  );
  const logoAltText = get(
    contentItem,
    "fields.genesisfooterlogo.label",
    "Genesis Energy",
  );
  const logoLink = get(
    contentItem,
    "fields.genesisfooterlink.href",
    "Genesis Energy",
  );
  const logoLinkTarget = get(
    contentItem,
    "fields.genesisfooterlink.target",
    "Genesis Energy",
  );
  const utilitiesDisputeLogoUrl = get(
    contentItem,
    "fields.utilitiesdisputeslogo.url",
    "",
  );
  const utilitiesDisputeLogoAltText = get(
    contentItem,
    "fields.utilitiesdisputeslogo.label",
    "",
  );
  const utilitiesDisputeLogoLink = get(
    contentItem,
    "fields.utilitiesdisputeslogolink.href",
    "",
  );
  const utilitiesDisputeLogoLinkTarget = get(
    contentItem,
    "fields.utilitiesdisputeslogolink.target",
    "",
  );
  const powerSwitchLogoUrl = get(contentItem, "fields.powerswitchlogo.url", "");
  const powerSwitchLogoAltText = get(
    contentItem,
    "fields.powerswitchlogo.label",
    "",
  );
  const powerSwitchLogoLink = get(
    contentItem,
    "fields.powerswitchlogolink.href",
    "",
  );
  const powerSwitchLogoLinkTarget = get(
    contentItem,
    "fields.powerswitchlogolink.target",
    "",
  );
  const copyrightMessage = get(contentItem, "fields.copyrightMessage", "");
  const shieldedSiteIcon = get(contentItem, "fields.theshieldedsite", "");
  const primaryLinksData = get(contentItem, "fields.primaryLinks", []);
  const secondaryLinksData = get(contentItem, "fields.secondaryLinks", []);
  const socialIconsData = get(contentItem, "fields.socialIcons", []);
  const copyrightMessageSplit = copyrightMessage.split("&copy;");
  const copyrightMessageWithDate = [
    copyrightMessageSplit[0],
    copyrightMessageSplit[1].replace(/([\d]*)/, new Date().getFullYear()),
  ].join("©");

  return {
    logoUrl,
    logoAltText,
    logoLink,
    logoLinkTarget,
    utilitiesDisputeLogoUrl,
    utilitiesDisputeLogoAltText,
    utilitiesDisputeLogoLink,
    utilitiesDisputeLogoLinkTarget,
    powerSwitchLogoUrl,
    powerSwitchLogoAltText,
    powerSwitchLogoLink,
    powerSwitchLogoLinkTarget,
    trustedBrandsLogoUrl: get(contentItem, "fields.trustedBrandslogo.url", ""),
    trustedBrandsLogoAltText: get(
      contentItem,
      "fields.trustedBrandslogo.label",
      "Trusted brands",
    ),
    copyrightMessage: copyrightMessageWithDate,
    shouldDisplayShieldedSiteIcon: shieldedSiteIcon === "true",
    primaryLinks: getLinkProps(primaryLinksData),
    secondaryLinks: getLinkProps(secondaryLinksData),
    socialIcons: getIconsProps(socialIconsData),
  };
}

async function getCustomInitialProps(context) {
  const { agility, languageCode } = context;

  const [contentItem, getContentItemError] = await runAsync(() =>
    agility.getContentItem({
      contentID: 178,
      languageCode,
      expandAllContentLinks: true,
      contentLinkDepth: 2,
    }),
  );

  if (getContentItemError) {
    return null;
  }

  const content = createContentProps(contentItem);

  return {
    content,
  };
}

const footer = { getCustomInitialProps };

export { footer };
