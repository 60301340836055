import React from "react";
import classNames from "classnames";
import { PlanCurveLarge, PlanCurveSmall } from "assets/icons";
import { Image } from "components/Image";

function TextWithLineBreakAdjustments({ text }) {
  if (!text) return null;

  const containsHyphen = text.includes("-");
  if (!containsHyphen) {
    return text;
  }

  const words = text.split(" ");
  const wordCount = words.length;

  return words.map((word, index) => {
    if (word.includes("-")) {
      return (
        <span key={index} className="whitespace-nowrap">
          {word}
        </span>
      );
    }

    const isLastWord = index === wordCount - 1;
    const trailingSpace = isLastWord ? "" : " ";
    const wordWithSpace = word + trailingSpace;

    return <React.Fragment key={index}>{wordWithSpace}</React.Fragment>;
  });
}

function PlanCardHeader({ data } = {}) {
  const { badgeText, title, description, iconPath } = data ?? {};

  return (
    <div className="relative pb-4">
      <div className="relative overflow-hidden rounded-t-10">
        {badgeText ? (
          <div
            className={classNames(
              "absolute top-0 right-0",
              "py-1 pl-4.5 pr-6",
              "lg:pl-6 lg:pr-7",
              "bg-white",
              "rounded-bl-7",
              "text-12/16 font-medium lg:text-14/20",
              "text-black-primary",
            )}
          >
            {badgeText}
          </div>
        ) : null}
        <div className="bg-day-gradient">
          <div className={classNames("px-7 pt-6 pb-21", "md:pt-7")}>
            <div
              className={classNames(
                "flex flex-col gap-y-2",
                "w-55",
                "md:gap-y-1",
              )}
            >
              <h3
                className={classNames(
                  "font-bold text-white text-32/36",
                  "md:text-44/52",
                )}
                data-testid="planCardTitle"
              >
                {title}
              </h3>
              <h4
                className="font-medium text-white text-20/24"
                data-testid="planCardSubtitle"
              >
                <TextWithLineBreakAdjustments text={description} />
              </h4>
            </div>
          </div>
        </div>
        <div className="absolute inset-x-0 text-white -bottom-px">
          <PlanCurveLarge className="hidden w-full md:block" />
          <PlanCurveSmall className="block w-full md:hidden" />
        </div>
      </div>
      <div className="absolute right-0 pointer-events-none -md:right-0 -bottom-2 md:-bottom-5 size-35 md:size-40">
        <Image
          src={iconPath}
          layout="responsive"
          width={200}
          height={200}
          alt="Elec icon"
        />
      </div>
    </div>
  );
}

export { PlanCardHeader };
