import classNames from "classnames";
import Icon from "join-form/components/icon";
import Link from "next/link";
import styles from "./button.module.scss";

const linkRegex = /link/i;

const typeClassNameMapping = {
  primaryLink: styles.primaryLink,
  primaryFill: styles.primaryFill,
  primaryFillIcon: styles.primaryFillIcon,
  primaryRoundIcon: styles.primaryRoundIcon,
  primaryFillWarmOrange: styles.primaryFillWarmOrange,

  secondaryFill: styles.secondaryFill,
  secondaryFillIcon: styles.secondaryFillIcon,
  secondaryOutline: styles.secondaryOutline,
  secondaryRoundIcon: styles.secondaryRoundIcon,

  tertiaryLink: styles.tertiaryLink,
  tertiaryFill: styles.tertiaryFill,
  tertiaryFillWithBorder: styles.tertiaryFillWithBorder,
  tertiaryRoundIcon: styles.tertiaryRoundIcon,
};

const sizeClassNameMapping = {
  regular: styles.regular,
  medium: styles.medium,
  small: styles.small,
  extraSmall: styles.extraSmall,
  round: styles.round,
};

function renderChildren({
  suffixIconName,
  suffixIconSize,
  suffixIconSizeTablet,
  children,
}) {
  if (suffixIconName) {
    return (
      <>
        <span className={styles.text}>{children}</span>
        <Icon
          name={suffixIconName}
          size={suffixIconSize}
          tabletSize={suffixIconSizeTablet}
          className={styles.icon}
        />
      </>
    );
  }

  return children;
}

export default function Button(props) {
  const {
    href,
    to,
    buttonType = "primaryFill",
    size = "regular",
    disabled = false,
    applyDisabledAttribute = false,
    isLoading = false,
    shadowless = false,
    onClick,
    children,
    className,
    suffixIconName,
    suffixIconSize,
    suffixIconSizeTablet,
    ...rest
  } = props;

  const typeClassName = typeClassNameMapping[buttonType];
  const sizeClassName = sizeClassNameMapping[size];
  const applySizeClassName = linkRegex.test(buttonType) === false;

  const buttonClassNames = classNames(
    typeClassName,
    { [styles.shadowless]: shadowless },
    { [sizeClassName]: applySizeClassName },
    { [styles.loading]: isLoading },
    { [styles.disabled]: disabled },
    className,
  );

  if (to && suffixIconName) {
    return (
      <Link href={to}>
        <a className={buttonClassNames} onClick={onClick} {...rest}>
          {renderChildren(props)}
        </a>
      </Link>
    );
  }

  if (to) {
    return (
      <Link href={to}>
        <a className={buttonClassNames} onClick={onClick} {...rest}>
          {children}
        </a>
      </Link>
    );
  }

  if (href) {
    return (
      <a href={href} className={buttonClassNames} onClick={onClick} {...rest}>
        {children}
      </a>
    );
  }
  return (
    <button
      className={buttonClassNames}
      onClick={onClick}
      disabled={applyDisabledAttribute}
      {...rest}
    >
      {renderChildren(props)}
    </button>
  );
}
