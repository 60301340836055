import { format, isValid, parseISO } from "date-fns";
import { formatInTimeZone, zonedTimeToUtc } from "date-fns-tz";

/**
 * Function extractValuesFromDate
 * @param {*} dateString a valid date which can be parsed by Date()
 * @returns an object containing date, month(MMM) and year
 */
function extractValuesFromDate(dateString) {
  const date = new Date(dateString);
  if (isValid(date)) {
    return {
      date: date.getDate(),
      month: format(date, "MMM"),
      year: date.getFullYear(),
    };
  }
  return {
    date: "",
    month: "",
    year: "",
  };
}

/**
 * Function formatDayOrMonth
 * @param {*} value day or month value to be formatted
 * @returns double-digit date or month value: 01, 11
 */
function formatDateToDoubleDigit(value) {
  if (value) {
    return Number(value) < 10 ? `0${value}` : value;
  }
  return "";
}

/**
 * Function formatDateToString
 * @param {*} isoDateString iso date to format
 * @param {*} formatString string to format date
 * @returns formatted string
 */
function formatDateToString(isoDateString, formatString = "d LLL yyyy") {
  try {
    const parsedDate = parseISO(isoDateString);
    // https://date-fns.org/v2.28.0/docs/format
    return format(parsedDate, formatString);
  } catch (ex) {
    return null;
  }
}

function formatDate(isoDateString, formatString = "d LLL yyyy") {
  try {
    const parsedDate = parseISO(isoDateString);
    // https://date-fns.org/v2.28.0/docs/format
    return format(parsedDate, formatString);
  } catch (ex) {
    return null;
  }
}

function tryParseISODate(isoDateString, fallbackDate = new Date()) {
  const parsedDate = parseISO(isoDateString);
  const result = isValid(parsedDate) ? parsedDate : fallbackDate;
  return result;
}

function subtractDays(date, days) {
  const result = new Date(date);
  result.setDate(result.getDate() - days);
  return result;
}

function getZonedTime(
  isoDateString,
  fromTimezone = "US/Eastern",
  toTimeZone = "Pacific/Auckland",
) {
  if (!Boolean(isoDateString)) {
    return "";
  }

  const utcTime = zonedTimeToUtc(isoDateString, fromTimezone);

  const zonedIsoDate = formatInTimeZone(
    utcTime,
    toTimeZone,
    "yyyy-MM-dd'T'HH:mm:ss",
  );

  return zonedIsoDate;
}

export {
  formatDate,
  tryParseISODate,
  subtractDays,
  getZonedTime,
  extractValuesFromDate,
  formatDateToDoubleDigit,
  formatDateToString,
};
