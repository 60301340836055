import { useState } from "react";
import { useRouter } from "next/router";
import Skeleton from "react-loading-skeleton";
import { useQuery } from "react-query";
import { ChevronDown } from "assets/icons";
import { ArticleHeader } from "components/ArticleHeader";
import { InlineBlockEditor } from "components/BlockEditor";
import { Image } from "components/Image";
import { ModuleWrapper } from "components/Wrappers/ModuleWrapper";
import { HYDRO } from "helpers/constants";
import { getCustomInitialProps } from "./getCustomInitialProps";
import { fetchReq, useQueryConfig } from "./HydroHelper";

const config = {
  textFriendlyType: {
    [HYDRO.type.rainfall]: "Rainfall",
    [HYDRO.type.river]: "River flow",
    [HYDRO.type.lake]: "Lake level",
    [HYDRO.type.spillFlow]: "",
  },
  timeframes: [
    {
      label: "2 days",
      imageSuffix: "_2D",
    },
    {
      label: "7 days",
      imageSuffix: "_7D",
    },
    {
      label: "1 month",
      imageSuffix: "_1M",
    },
    {
      label: "3 months",
      imageSuffix: "_3M",
    },
  ],
};

const select = (res, routerAsPath) => {
  const summary = res.find((item) => item.hydroGraphUrl === routerAsPath);
  const src = encodeURI(
    [
      process.env.NEXT_PUBLIC_BLOB_STORAGE_HOST_NAME,
      HYDRO.gifUrlPath,
      summary.gifName,
    ].join(""),
  );
  const images = {};
  config.timeframes.forEach((timeframe) => {
    images[timeframe.label] = `${src}${timeframe.imageSuffix}.gif`;
  });

  return {
    images,
    friendlyType: config.textFriendlyType[summary.groupId],
    siteName: summary.site,
  };
};

const HydroGraph = ({ customData }) => {
  const router = useRouter();
  // set '2 days' as default
  const [activeImage, setActiveImage] = useState(
    () => config.timeframes[0].label,
  );

  // fetch graph images
  const { data, isLoading } = useQuery(HYDRO.queryId, fetchReq, {
    ...useQueryConfig,
    select: (res) => select(res, router.asPath),
  });

  // find cms content
  const cmsContent = customData?.gehydrographlist?.find((item) =>
    router.asPath.endsWith(item.fields.slug),
  );
  const headerTitle = cmsContent?.fields?.title;
  const blocks = cmsContent?.fields?.blockEditor;

  return [
    <ArticleHeader
      key="hydro-graph-header"
      title={isLoading ? "" : headerTitle}
    />,
    <ModuleWrapper key="hydro-graph-content" psnid="psn_hydro">
      <div
        className="p-4 bg-white border border-black-100 rounded-4 md:p-10 md:rounded-5 md:max-w-200"
        data-testid="hydro-graph"
      >
        {isLoading ? (
          <Skeleton containerTestId="hydro-graph-loader" />
        ) : (
          <>
            <div className="flex justify-between mb-16">
              <div>
                <p className="mb-1 font-medium text-orange-primary text-16/24">
                  {data.friendlyType}
                </p>
                <h2 className="font-bold text-black-primary text-24/28">
                  {data.siteName}
                </h2>
              </div>
              <div className="justify-self-end text-14/20 md:text-16/24">
                <div className="fsSelectContainer">
                  <select
                    className="fsField"
                    onChange={({ target }) => setActiveImage(target.value)}
                  >
                    {config.timeframes.map((item) => (
                      <option
                        key={`key-timeframe-${item.label}`}
                        value={item.label}
                      >
                        {item.label}
                      </option>
                    ))}
                  </select>
                  <ChevronDown className="fsSelectIcon" />
                </div>
              </div>
            </div>
            <div className="relative max-w-150">
              <Image
                alt={activeImage}
                height={250}
                layout="responsive"
                src={data.images[activeImage]}
                width={600}
              />
            </div>
          </>
        )}
      </div>
      {blocks && (
        <div className="mt-10 max-w-200">
          <InlineBlockEditor jsonString={blocks} />
        </div>
      )}
    </ModuleWrapper>,
  ];
};

HydroGraph.getCustomInitialProps = getCustomInitialProps;

export { HydroGraph };
