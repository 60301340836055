import { sortBy } from "lodash-es";
import {
  AccordionSection,
  AccordionSectionList,
} from "components/AccordionSection";
import { getCustomInitialProps } from "./getFaqInPageProps";

function FaqInPage({ module, customData: accordionList }) {
  const { title, cta } = module.fields;

  if (!accordionList.length) {
    return null;
  }

  const sortedAccordionList = sortBy(accordionList, ["properties.itemOrder"]);

  return (
    <AccordionSection
      title={title}
      anchor="faq"
      psnid="psn_faq_in_page"
      cta={cta}
    >
      <AccordionSectionList
        anchorPrefix="faq"
        accordionList={sortedAccordionList}
        accordionItemContentField="blockeditor"
      />
    </AccordionSection>
  );
}

FaqInPage.getCustomInitialProps = getCustomInitialProps;

export { FaqInPage };
