import classNames from "classnames";
import Link from "next/link";
import { Highlight, Snippet } from "react-instantsearch-hooks-web";

function Hit({ hit }) {
  return (
    <div className={classNames("flex flex-col gap-1 group", "md:gap-2")}>
      <h4
        className={classNames(
          "font-medium text-15/24 text-dark-primary group-hover:text-warm-orange-primary",
          "md:text-24/36",
        )}
      >
        <Link href={hit.path} prefetch={false}>
          <a target="_blank">
            <Highlight hit={hit} attribute="title" />
          </a>
        </Link>
      </h4>
      <Snippet
        hit={hit}
        attribute="pageData"
        className={classNames("text-12/20 text-black-primary", "md:text-18/28")}
      />
    </div>
  );
}

export { Hit };
