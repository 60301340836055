import { compact, every, find, map } from "lodash-es";
import {
  BOTTLED_GAS_ABBREVIATION,
  PRODUCT_ELECTRICITY,
  PRODUCT_GAS,
  PRODUCT_LPG,
  PRODUCT_LPG_FULL_NAME,
} from "helpers/constants";

export const doesSelectedProductsHaveIcp = (selectedProducts, icpData) => {
  const sparseProductIcpKeyNames = map(selectedProducts, ({ name }) => {
    switch (name) {
      case PRODUCT_ELECTRICITY:
        return "elecIcp";
      case PRODUCT_GAS:
        return "gasIcp";
      default:
        return null;
    }
  });
  const productIcpNames = compact(sparseProductIcpKeyNames);
  const productIcpNumbers = map(
    productIcpNames,
    (productIcpName) => icpData[productIcpName],
  );
  const doAllProductsHaveIcpNumbers = every(
    productIcpNumbers,
    (productIcpNumber) => productIcpNumber !== "",
  );
  return doAllProductsHaveIcpNumbers;
};

export const findAvailableProducts = (products) => {
  const availableProducts = products
    .filter(({ isAvailable }) => isAvailable)
    .map((product) => ({ ...product }));
  return availableProducts;
};

export const getProductSelectionTitle = (products) => {
  const normalizedProductNames = map(products, (productName) =>
    productName === PRODUCT_LPG ? PRODUCT_LPG_FULL_NAME : productName,
  );
  const allProductsAreSelected = normalizedProductNames.length === 3;
  if (allProductsAreSelected) {
    return `${PRODUCT_ELECTRICITY} + ${PRODUCT_GAS} + ${BOTTLED_GAS_ABBREVIATION}`;
  }

  const productListContainsElectricity =
    normalizedProductNames.includes(PRODUCT_ELECTRICITY);

  const productListContainsNaturalGas =
    normalizedProductNames.includes(PRODUCT_GAS);
  const productListContainsBottledGas = normalizedProductNames.includes(
    PRODUCT_LPG_FULL_NAME,
  );

  const naturalAndBottledGasAreSelected =
    productListContainsNaturalGas && productListContainsBottledGas;
  if (naturalAndBottledGasAreSelected) {
    return `${PRODUCT_GAS} + ${PRODUCT_LPG}`;
  }

  const electricityAndBottledGasAreSelected =
    productListContainsElectricity && productListContainsBottledGas;
  if (electricityAndBottledGasAreSelected) {
    return `${PRODUCT_ELECTRICITY} + ${PRODUCT_LPG}`;
  }

  return normalizedProductNames.join(" + ");
};

const defaultProduct = { isSelected: false };

export const isProductSelectionDualFuel = ({ gasIcp, availableProducts }) => {
  const isGasIcpAvailable = gasIcp !== "";

  const { isSelected: isElecSelected } =
    find(availableProducts, ({ name }) => name === PRODUCT_ELECTRICITY) ||
    defaultProduct;

  const { isSelected: isGasSelected } =
    find(availableProducts, ({ name }) => name === PRODUCT_GAS) ||
    defaultProduct;

  const result = isGasIcpAvailable && isElecSelected && isGasSelected;

  return result;
};

export const getSelectedAvailableProducts = (products) => {
  return products.filter(
    ({ isAvailable, isSelected }) => isAvailable && isSelected,
  );
};

export const isDualFuelDiscountApplicable = (products) => {
  const selectedAvailableProducts = getSelectedAvailableProducts(products);

  const isMoreThanOneFuelTypeIsSelected = selectedAvailableProducts.length > 1;

  if (!isMoreThanOneFuelTypeIsSelected) {
    return false;
  }

  return isElectricitySelected(products);
};

export const isBottledGasSelected = (products = []) => {
  const bottledGas = products.find(({ name }) => name === PRODUCT_LPG) || {
    isAvailable: false,
    isSelected: false,
  };
  const isBottledGasAvailableAndSelected =
    bottledGas.isAvailable && bottledGas.isSelected;
  return isBottledGasAvailableAndSelected;
};

export const isElectricitySelected = (products = []) => {
  const electricity = products.find(
    ({ name }) => name === PRODUCT_ELECTRICITY,
  ) || {
    isAvailable: false,
    isSelected: false,
  };
  const isElectricityAvailableAndSelected =
    electricity.isAvailable && electricity.isSelected;
  return isElectricityAvailableAndSelected;
};

export const isNaturalGasSelected = (products = []) => {
  const naturalGas = products.find(({ name }) => name === PRODUCT_GAS) || {
    isAvailable: false,
    isSelected: false,
  };
  const isNaturalGasAvailableAndSelected =
    naturalGas.isAvailable && naturalGas.isSelected;
  return isNaturalGasAvailableAndSelected;
};

/**
 * Function isOnlyBottledGasFuelSelected
 * @param {} products array of products
 */
export const isOnlyBottledGasFuelSelected = (products = []) =>
  isBottledGasSelected(products) &&
  !isElectricitySelected(products) &&
  !isNaturalGasSelected(products);
