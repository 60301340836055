import { v4 as uuidv4 } from "uuid";
import { getToken, hasAccessTokenInfoInitialized } from "helpers/apiHelper";

export const STORAGE_KEY = "npaAuth";

const safeWindow =
  typeof window !== "undefined" ? window : { localStorage: {} };

const storage = safeWindow?.localStorage;

export async function initializeNpaAccessToken() {
  const sessionExists = hasAccessTokenInfoInitialized();
  if (sessionExists === false) {
    getAccessToken();
  }
}

export async function ensureNpaAccessToken() {
  const sessionExists = hasAccessTokenInfoInitialized();
  if (sessionExists) {
    return Promise.resolve();
  }
  return getToken();
}

export async function getRequestHeaders() {
  const { accessToken } = await getTokenInfo();
  const headers = {
    Authorization: `Bearer ${accessToken}`,
    client_id: process.env.NEXT_PUBLIC_MULE_API_CLIENT_ID,
    client_secret: process.env.NEXT_PUBLIC_MULE_API_CLIENT_SECRET,
    "X-Transaction-ID": uuidv4(),
    "X-Brand-ID": process.env.NEXT_PUBLIC_MULE_API_BRAND_ID,
    "X-Application-ID": process.env.NEXT_PUBLIC_MULE_API_APPLICATION_ID,
    "X-Channel-ID": process.env.NEXT_PUBLIC_MULE_API_CHANNEL_ID,
  };
  return headers;
}

export async function getLiveChatRequestHeaders() {
  const { accessToken } = await getTokenInfo();
  const headers = {
    Authorization: `Bearer ${accessToken}`,
    client_id: process.env.NEXT_PUBLIC_MULE_API_CLIENT_ID,
    client_secret: process.env.NEXT_PUBLIC_MULE_API_CLIENT_SECRET,
    "X-Transaction-ID": uuidv4(),
    "X-Brand-ID": process.env.NEXT_PUBLIC_MULE_API_BRAND_ID,
    "X-Application-ID": "Genesys",
    "X-Channel-ID": "Chat",
  };
  return headers;
}

export async function getSignupRequestHeaders() {
  const { accessToken } = await getTokenInfo();
  const headers = {
    Authorization: `Bearer ${accessToken}`,
    client_id: process.env.NEXT_PUBLIC_MULE_API_CLIENT_ID,
    client_secret: process.env.NEXT_PUBLIC_MULE_API_CLIENT_SECRET,
    "X-Transaction-ID": uuidv4(),
    "X-Brand-ID": process.env.NEXT_PUBLIC_MULE_API_BRAND_ID,
    "X-Application-ID": "TWENTY",
    "X-Channel-ID": "ONLINE",
    "X-Customer-Sector-ID": "RESIDENTIAL",
  };
  return headers;
}

export async function getSmeSignupRequestHeaders() {
  const { accessToken } = await getTokenInfo();
  const headers = {
    Authorization: `Bearer ${accessToken}`,
    client_id: process.env.NEXT_PUBLIC_MULE_API_CLIENT_ID,
    client_secret: process.env.NEXT_PUBLIC_MULE_API_CLIENT_SECRET,
    "X-Transaction-ID": uuidv4(),
    "X-Brand-ID": "GENE",
    "X-Application-ID": "CMS",
    "X-Channel-ID": "ONLINE",
    "X-Customer-Sector-ID": "SME",
  };
  return headers;
}

export async function getTokenInfo() {
  const sessionExists = hasAccessTokenInfoInitialized();
  if (sessionExists) {
    const defaultTokenInfo = {
      accessToken: "",
      refreshToken: "",
      expiresIn: "",
    };
    const tokenInfo =
      JSON.parse(storage.getItem(STORAGE_KEY)) || defaultTokenInfo;
    return Promise.resolve(tokenInfo);
  }
  return getAccessToken();
}

async function getAccessToken() {
  try {
    const { data } = await Axios.get("/api/npa/accesstoken");
    const tokenInfo = storeAccessToken({ data });
    return tokenInfo;
  } catch (e) {
    const tokenInfo = {
      accessToken: "",
      refreshToken: "",
      expiresIn: "",
    };
    storage.setItem(STORAGE_KEY, JSON.stringify(tokenInfo));
    return tokenInfo;
  }
}

function storeAccessToken({ data }) {
  const { access_token, expires_in } = data;
  const date = new Date(Date.now());
  const expiresIn = addSeconds(date, expires_in);
  const stringifiedExpiry = JSON.stringify(expiresIn.toISOString());

  const tokenInfo = {
    accessToken: access_token,
    expiresIn: stringifiedExpiry,
  };
  storage.setItem(STORAGE_KEY, JSON.stringify(tokenInfo));

  return tokenInfo;
}
