import { useState } from "react";
import { createSelector } from "@reduxjs/toolkit";
import {
  setBusinessCustomerType,
  setResidentialCustomerType,
} from "actions/appState/appStateActions";
import { AnimatePresence, motion } from "framer-motion";
import { useRouter } from "next/router";
import { useDispatch, useSelector } from "react-redux";
import { selectAppState } from "reducers/selector";
import { Alert } from "components/Alert";
import { ButtonRadioSelection } from "components/ButtonRadioSelection";
import { StepProgressionButton } from "components/StepProgressionButton";
import {
  CUSTOMER_TYPE_BUSINESS,
  CUSTOMER_TYPE_RESIDENTIAL,
} from "helpers/constants";
import {
  appBusinessRoutes,
  appResidentialRoutes,
} from "helpers/constants";
import { gaJoinFormContinueButton } from "helpers/gaHelper";
import {
  heightFadeTransition,
  heightFadeVariants,
  item,
  itemContainerVariants,
  list,
} from "./animations";

// selectors
const selectCustomerType = createSelector(
  selectAppState,
  ({ customerType }) => {
    const isResidentialCustomerType =
      customerType === CUSTOMER_TYPE_RESIDENTIAL;
    const isBusinessCustomerType = customerType === CUSTOMER_TYPE_BUSINESS;
    const isPageInvalid = customerType === null;

    return {
      isResidentialCustomerType,
      isBusinessCustomerType,
      isPageInvalid,
    };
  },
);

export function CustomerType({ module }) {
  const { businessCustomerLabel, residentialCustomerLabel } = module.fields;
  const { isResidentialCustomerType, isBusinessCustomerType, isPageInvalid } =
    useSelector(selectCustomerType);

  const dispatch = useDispatch();
  const [errorVisible, setErrorVisibility] = useState(false);

  const router = useRouter();

  function onResidentialCustomerTypeClick(e) {
    e.preventDefault();
    dispatch(setResidentialCustomerType());
    setErrorVisibility(false);
  }

  function onBusinessCustomerTypeClick(e) {
    e.preventDefault();
    dispatch(setBusinessCustomerType());
    setErrorVisibility(false);
  }

  function onContinueClick(e) {
    e.preventDefault();
    setErrorVisibility(isPageInvalid);
    if (isResidentialCustomerType) {
      gaJoinFormContinueButton({
        event_category: "residential join",
        content_tertiary: "select join type",
        checkout_type: "residential",
      });
      router.push({
        pathname: appResidentialRoutes.address,
      });
    }
    if (isBusinessCustomerType) {
      gaJoinFormContinueButton({
        event_category: "business join",
        content_tertiary: "select join type",
        checkout_type: "business",
      });
      router.push({
        pathname: appBusinessRoutes.businessDetails,
      });
    }
  }

  return (
    <motion.div
      className="max-w-full px-4 mx-auto w-158 md:w-210"
      initial="hidden"
      animate="visible"
      exit="undefined"
      variants={list}
    >
      <motion.div variants={itemContainerVariants} className="mb-10 md:mb-15">
        <motion.div variants={item} className="flex mb-5 last:mb-0">
          <ButtonRadioSelection
            isSelected={isResidentialCustomerType}
            onClick={onResidentialCustomerTypeClick}
            size="large"
            data-testid="residentialCustomerLabel"
          >
            {residentialCustomerLabel}
          </ButtonRadioSelection>
        </motion.div>
        <motion.div variants={item} className="flex mb-5 last:mb-0">
          <ButtonRadioSelection
            isSelected={isBusinessCustomerType}
            onClick={onBusinessCustomerTypeClick}
            size="large"
            data-testid="businessCustomerLabel"
          >
            {businessCustomerLabel}
          </ButtonRadioSelection>
        </motion.div>
      </motion.div>
      <div className="mt-10 overflow-hidden md:mt-15 lg:mt-20">
        <AnimatePresence>
          {errorVisible && (
            <motion.div
              initial="hidden"
              animate="visible"
              exit="exit"
              variants={heightFadeVariants}
              transition={heightFadeTransition}
              className="mb-8 md:mb-10"
            >
              <Alert
                fields={{
                  title: "Error",
                  copy: "Please select an option to continue",
                }}
                type="error"
              />
            </motion.div>
          )}
        </AnimatePresence>
      </div>
      <StepProgressionButton
        onContinueClick={onContinueClick}
        className="!pb-10"
        applyDisabledStyle={isPageInvalid}
      />
    </motion.div>
  );
}

export default CustomerType;
