import { useEffect, useState } from "react";
import classNames from "classnames";
import { Close, Cross, InfoFilled, Warning } from "assets/icons";
import { Link } from "components/Link";
import {
  getAllStorage,
  getWithExpiry,
  removeStorageItem,
  setWithExpiry,
} from "helpers/localStorage";

const config = {
  localStoragePrefix: "alertBanner",
  now: new Date().getTime(),
  day: 1000 * 60 * 60 * 24, // 24 hours
  week: 1000 * 60 * 60 * 24 * 7, // 7 days
  indefinitely: 1000 * 60 * 60 * 24 * 365, // 365 days
};

const icons = (type) => {
  const iconStyle = "w-6 h-6 fill-current";
  const iconset = {
    error: <Close className={classNames("text-red-400", iconStyle)} />,
    info: <InfoFilled className={classNames("text-blue-primary", iconStyle)} />,
    warning: (
      <Warning className={classNames("text-warm-orange-primary", iconStyle)} />
    ),
  };
  return iconset[type];
};

const purgeAlerts = (items) => {
  // delete localStorage items whose contentId no longer exists
  const contentIds = items.map(({ contentID }) => String(contentID));
  const allKeys = getAllStorage();
  for (const i in allKeys) {
    if (
      i.startsWith(config.localStoragePrefix) &&
      !contentIds.some((contentId) => i.endsWith(contentId))
    ) {
      removeStorageItem(i);
    }
  }
};

function AlertBanner({ items }) {
  // firstly, clean up any alerts deleted from cms
  useEffect(() => {
    purgeAlerts(items);
  }, []);

  // then, determine to render or not
  const [isValid, updateIsValid] = useState(false);
  const [item] = items;
  const contentId = item?.contentID;
  const localStorageKey = config.localStoragePrefix + contentId;
  const { closeDuration, cta1, cta2, text, iconLink } = item?.fields ?? {};

  const handleClose = () => {
    // don't render again
    setWithExpiry(
      localStorageKey,
      { closeDuration, contentId },
      config.now + config[closeDuration],
    );
    updateIsValid(false);
  };

  useEffect(() => {
    if (!contentId) {
      return;
    }

    if (!isValid) {
      // don't re-render if matched alert has rendered within close duration
      const localData = getWithExpiry(localStorageKey);
      if (localData?.contentId === contentId) {
        // passively reset local storage if changed in cms
        if (localData.closeDuration !== closeDuration) {
          setWithExpiry(
            localStorageKey,
            { closeDuration, contentId },
            config.now + config[closeDuration],
          );
        }
        return;
      }

      // else, render and reset expiry
      updateIsValid(true);
    }
  }, [closeDuration, contentId, localStorageKey, isValid]);

  if (!isValid) return null;

  // ok, proceed with rendering
  const hasCta = cta1 || cta2;

  return (
    <div
      className={classNames(
        "p-4 text-white text-14/20 rounded-xl bg-black-primary shadow-200",
        { "pb-12": hasCta },
        "pointer-events-auto",
        "md:shadow-400 md:pb-4 md:text-16/24",
      )}
    >
      <div className="flex space-x-4">
        <div className="self-center h-6">{icons(iconLink)}</div>
        <div className="self-center grow">{text}</div>
        {hasCta && (
          <div
            className={classNames(
              "self-center text-right absolute bottom-4 right-4",
              "md:static shrink-0",
            )}
          >
            {[cta1, cta2]
              .filter((ctaItem) => ctaItem?.href)
              .map((ctaItem) => (
                <Link key={ctaItem.href} href={ctaItem.href} prefetch={false}>
                  <a className="px-3 border-r-2 pointer-events-auto border-white/20 last:border-r-0 last:pr-0 last:md:border-r-2 last:md:pr-4">
                    {ctaItem.text}
                  </a>
                </Link>
              ))}
          </div>
        )}
        <div className="self-center h-4">
          <button onClick={handleClose} aria-label="close alert banner">
            <Cross className="w-4 h-4 text-white pointer-events-auto fill-current" />
          </button>
        </div>
      </div>
    </div>
  );
}

export { AlertBanner };
