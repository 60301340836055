import { Delimiter } from "./Delimiter";
import { Embed } from "./Embed";
import { Header } from "./Header";
import { Image } from "./Image";
import { List } from "./List";
import { Paragraph } from "./Paragraph";
import { Quote } from "./Quote";
import { RawHtml } from "./RawHtml";
import { Table } from "./Table";

const DEFAULT_BLOCKS = {
  embed: Embed,
  delimiter: Delimiter,
  header: Header,
  list: List,
  paragraph: Paragraph,
  quote: Quote,
  raw: RawHtml,
};

const SPECIAL_BLOCKS = {
  image: Image,
  table: Table,
};

const ALL_BLOCKS = {
  ...DEFAULT_BLOCKS,
  ...SPECIAL_BLOCKS,
};

export { ALL_BLOCKS, DEFAULT_BLOCKS, SPECIAL_BLOCKS };
