import React from "react";
import classNames from "classnames";
import Icon from "join-form/components/icon";
import Modal from "join-form/components/modal";
import { Info } from "assets/icons";
import {
  PLAN_TYPE_BASIC,
  PLAN_TYPE_EV_PLUS,
  PLAN_TYPE_PLUS,
  PLAN_TYPE_PLUS_FIXED,
  PLAN_TYPE_PLUS_FLEXI,
} from "helpers/constants";

const titleMap = {
  [PLAN_TYPE_BASIC]: "Energy Basic rates",
  [PLAN_TYPE_PLUS]: "Energy Plus rates",
  [PLAN_TYPE_EV_PLUS]: "Energy EV rates",
  [PLAN_TYPE_PLUS_FIXED]: "Fixed rates",
  [PLAN_TYPE_PLUS_FLEXI]: "Flexi rates",
};

export default function RatesHeading({ planType, inclGst, setInclGst }) {
  const [isEvPlusRatesInfoModalVisible, setEvPlusRatesInfoModalVisibility] =
    React.useState(false);

  const inclGstIconName = inclGst ? "CheckboxOn" : "CheckboxOff";

  const title = titleMap[planType] ?? "Your energy rates";

  const isEvPlus = planType === PLAN_TYPE_EV_PLUS;

  function toggleEvPlusRatesInfoModal() {
    const toggledValue = isEvPlusRatesInfoModalVisible ? false : true;
    setEvPlusRatesInfoModalVisibility(toggledValue);
  }

  return (
    <>
      <span
        className={classNames(
          "flex items-center",
          "mb-4 px-4",
          "font-medium text-15/24 md:text-18/28",
        )}
      >
        <span
          className="flex items-center gap-2"
          data-testid="ratesHeadingTitle"
        >
          <span>{title}</span>
          {isEvPlus && (
            <button
              onClick={toggleEvPlusRatesInfoModal}
              className="outline-none focus:outline-none"
              aria-label="EV Plus rates info"
              type="button"
            >
              <Icon name="Info" size="18" />
            </button>
          )}
        </span>
        <label
          className={classNames(
            "flex items-center",
            "ml-auto",
            "cursor-pointer text-12/20 text-black-primary",
          )}
        >
          Incl GST
          <input
            type="checkbox"
            checked={inclGst}
            onChange={() => setInclGst(!inclGst)}
            data-testid="ratesGstCheckbox"
            className="hidden"
          />
          <Icon
            size="18"
            name={inclGstIconName}
            className={classNames("text-orange-primary ml-2")}
          />
        </label>
      </span>
      {isEvPlus && (
        <Modal
          onRequestClose={toggleEvPlusRatesInfoModal}
          isOpen={isEvPlusRatesInfoModalVisible}
          title="Energy EV rates"
          data-testid="evPlusRatesInfoModal"
        >
          <div className="space-y-4">
            <p>
              Your meter needs to be configured to allow Day and Night pricing,
              most homes will need an update to their meter, on average
              depending on your meter type it can take a couple weeks, so in the
              meantime;
            </p>
            <p>
              If you're new to Genesis, you'll go onto our Energy EV “Interim”
              rates (as shown in the table) while we get your metering sorted.
              Once that's all done, you'll be able to receive Day and Night
              pricing.
            </p>
            <p>
              If you're an existing customer, you'll stay on your current plan
              while we get the metering sorted, and then move you to the Day and
              Night pricing.
            </p>
            <p>
              For clarity, existing customers can ignore the “Interim” Rates in
              the table. Don't worry, we'll keep in touch with you along the
              way.
            </p>
          </div>
        </Modal>
      )}
    </>
  );
}
