import classNames from "classnames";
import styles from "./feature-item.module.scss";

const themeMap = {
  tertiary: styles.feature_tertiary,
  complete: styles.feature_complete,
};

export default function FeatureItemContainer(props) {
  const { className, theme, children, showHeader = false } = props;

  const themeClassName = themeMap[theme];

  const containerClassName = classNames(
    styles.feature_list,
    { [themeClassName]: theme },
    className,
  );

  return (
    <>
      {showHeader && (
        <>
          <p className="text-16/24 text-black-primary">What's available</p>
          <hr className="mt-2 mb-3 md:mt-3 h-px bg-black-50 rounded-4 border-none"></hr>
        </>
      )}
      <ul className={containerClassName}>{children}</ul>
    </>
  );
}
