import { runAsync } from "helpers";
import { appResidentialRoutes } from "helpers/constants";

async function getJoinPlanSelectPageProps(context) {
  if (context.sitemapNode?.path !== appResidentialRoutes.planSelect) {
    return [];
  }

  const { agility, languageCode } = context;

  const [data, error] = await runAsync(() =>
    agility.getContentList({
      referenceName: "gejoinplanlist",
      languageCode,
      expandAllContentLinks: true,
    }),
  );

  if (error) {
    return [];
  }

  const { items, totalCount } = data;

  if (totalCount === 0) {
    return [];
  }

  return items;
}

export { getJoinPlanSelectPageProps };
