import classNames from "classnames";
import { CallToAction } from "components/CallToAction";
import { Image } from "components/Image";
import { ImageBubble } from "components/ImageBubble/ImageBubble";
import { ModuleWrapper } from "components/Wrappers";
import { THEME, VARIANT } from "helpers/constants";
import { getContentWithLineBreaks } from "helpers/htmlHelper";
import {
  FadeInBottomOnScroll,
  FadeInOnScroll,
  ScaleOnScroll,
} from "./animations";

function BannerBubble({ module }) {
  const {
    anchor,
    cta1,
    cta2,
    description,
    dTImage: image,
    theme,
    title,
  } = module.fields;
  const variantStyles = {
    background: theme === THEME.orange ? "bg-orange-primary" : "bg-black-50",
    text: theme === THEME.orange ? "text-white" : "text-dark-primary",
    cta1: theme === THEME.orange ? VARIANT.light : VARIANT.dark,
    cta2:
      theme === THEME.orange
        ? VARIANT.lightTransparent
        : VARIANT.darkTransparent,
  };

  return (
    <ModuleWrapper psnid="psn_banner_bubble">
      <FadeInOnScroll>
        <div
          className={classNames(
            "rounded-7 shadow-white-glow overflow-hidden",
            "md:rounded-10 md:flex md:min-h-83",
            "lg:min-h-105",
            variantStyles.background,
          )}
          id={anchor}
        >
          <div className="relative w-full h-64 overflow-hidden sm:h-88 md:h-auto md:basis-5/12 md:order-last">
            <ScaleOnScroll>
              <Image
                src={image.url}
                alt={image.label}
                layout="fill"
                objectFit="cover"
                objectPosition="left center"
                className="!mb-1 md:!mb-0" // https://bugs.webkit.org/show_bug.cgi?id=14956
              />
            </ScaleOnScroll>
            <ImageBubble className={variantStyles.background}></ImageBubble>
          </div>
          <div className="px-5 pb-5 text-center sm:px-10 sm:pb-10 md:text-left md:p-15 md:pr-0 md:order-first md:self-center md:basis-7/12">
            <FadeInBottomOnScroll>
              <h2
                className={classNames(
                  "mb-3 font-bold text-27/32 md:text-32/36 md:mb-5",
                  variantStyles.text,
                )}
              >
                {title}
              </h2>
              <p
                className={classNames(
                  "mb-8 text-18/28 md:text-20/32 md:mb-10",
                  variantStyles.text,
                )}
              >
                {getContentWithLineBreaks(description)}
              </p>
            </FadeInBottomOnScroll>
            {(cta1 || cta2) && (
              <div className="flex flex-wrap justify-center gap-5 mt-10 mb-5 md:mb-0 md:justify-start">
                {cta1 && (
                  <FadeInBottomOnScroll>
                    <CallToAction
                      data={cta1}
                      variant={variantStyles.cta1}
                      dataGa={{
                        content_tertiary: title,
                        event: "cta_click",
                        event_category: "cta",
                        event_label: [cta1?.text],
                      }}
                    />
                  </FadeInBottomOnScroll>
                )}
                {cta2 && (
                  <FadeInBottomOnScroll isSecondary>
                    <CallToAction
                      data={cta2}
                      variant={variantStyles.cta2}
                      dataGa={{
                        content_tertiary: title,
                        event: "cta_click",
                        event_category: "cta",
                        event_label: [cta2?.text],
                      }}
                    />
                  </FadeInBottomOnScroll>
                )}
              </div>
            )}
          </div>
        </div>
      </FadeInOnScroll>
    </ModuleWrapper>
  );
}
export { BannerBubble };
