import { CartesianGrid } from "recharts";

export const renderGrid = ({ stroke }) => (
  <CartesianGrid vertical={false} strokeDasharray="3" stroke={stroke} />
);

// this masks the unecessary top grid line, due to yAxis padding
export const GridMask = ({ width }) => {
  return (
    <div
      className="absolute top-0 right-0 h-2 bg-white"
      style={{ left: `${width}px` }}
    ></div>
  );
};
