import {
  EnergyRateContainer,
  EnergyRateItem,
  EnergyRatePlaceholderContainer,
} from "join-form/components/energy-rate";
import { PLAN_TYPE_BASIC, PLAN_USAGE_LOW } from "helpers/constants";
import { isBottledGasSelected } from "helpers/productHelper";
import {
  getRateDescription,
  getRateUnitOfMeasurement,
} from "helpers/rateHelper";
import { connect } from "react-redux";

function BottledGasRates(props) {
  const {
    shouldRender,
    planType,
    usageType,
    lowUsageTariffList,
    highUsageTariffList,
    inclGst,
    visible,
    onToggle,
    highlightOnMobile,
  } = props;

  if (!shouldRender) {
    return null;
  }

  const tariffList =
    usageType === PLAN_USAGE_LOW ? lowUsageTariffList : highUsageTariffList;

  return tariffList.length ? (
    <EnergyRateContainer
      icon="BottledGasBadge"
      title="Bottled Gas (LPG)"
      data-testid={`${planType}_${usageType}_BottledGasRates`}
      visible={visible}
      onToggle={onToggle}
      highlightOnMobile={highlightOnMobile}
    >
      {tariffList.map((tariffItem, index) => {
        const {
          description,
          meteringUnitOfMeasurement,
          pricingUnitOfMeasurement,
          rateExcludingGST,
          rateIncludingGST,
        } = tariffItem;

        const title = getRateDescription(description);
        const roundedValue = (
          inclGst ? rateIncludingGST : rateExcludingGST
        ).toFixed(2);
        const unitOfMeasurement = getRateUnitOfMeasurement(
          meteringUnitOfMeasurement,
        );
        const value = `${pricingUnitOfMeasurement}${roundedValue} / ${unitOfMeasurement}`;

        return <EnergyRateItem key={index} title={title} value={value} />;
      })}
    </EnergyRateContainer>
  ) : (
    <EnergyRatePlaceholderContainer
      icon="BottledGasBadgeBlack"
      title="Bottled Gas (LPG)"
      visible={visible}
      onToggle={onToggle}
      data-testid={`${planType}_BottledGasRates`}
    />
  );
}

const mapStateToProps = (state, { planType, ...ownProps }) => {
  const plan = planType === PLAN_TYPE_BASIC ? "basicPlan" : "plusPlan";
  const {
    appState: { products },
    residentialOffers: {
      bottledGasOffer: {
        [plan]: {
          lowUsage: { tariffList: lowUsageTariffList },
          highUsage: { tariffList: highUsageTariffList },
        },
      },
    },
  } = state;

  const shouldRender = isBottledGasSelected(products);

  return { lowUsageTariffList, highUsageTariffList, shouldRender, ...ownProps };
};

export default connect(mapStateToProps)(BottledGasRates);
