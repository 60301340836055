import { motion } from "framer-motion";

const transition = {
  delay: 0.3,
  type: "spring",
  bounce: 0.3,
};

function RotateAnimation({ children }) {
  return (
    <motion.div
      initial={{ rotate: 5, opacity: 0 }}
      animate={{ rotate: 0, opacity: 1 }}
      transition={{ delay: 0.3, type: "tween" }}
    >
      {children}
    </motion.div>
  );
}

function FadeInTopAnimation({ children }) {
  return (
    <motion.div
      initial={{ y: -20, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={transition}
    >
      {children}
    </motion.div>
  );
}

function FadeInBottomAnimation({ children, isPrimary }) {
  return (
    <motion.div
      initial={{ y: isPrimary ? 20 : 60, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={transition}
    >
      {children}
    </motion.div>
  );
}

export { RotateAnimation, FadeInTopAnimation, FadeInBottomAnimation };
