import { useState } from "react";
import classNames from "classnames";
import { useScreenSize } from "hooks";
import { groupBy, map, sortBy, uniqBy } from "lodash-es";
import {
  Accordion,
  AccordionHeader,
  AccordionItem,
  AccordionPanel,
} from "components/Accordion";
import { InlineBlockEditor } from "components/BlockEditor";
import { getContentWithLineBreaks } from "helpers/htmlHelper";
import { FadeInBottomOnScroll } from "./animations";

function Tabs({ topics, activeTopic, handleClick }) {
  return (
    <div className="flex border-b-4 border-black-50">
      {topics.map(({ topic }, index) => (
        <button
          className={classNames(
            "first:pl-0 p-3 pb-4 relative",
            topic === activeTopic
              ? "text-dark-primary after:border-black-primary after:-my-1"
              : "text-black-400 after:border-black-50 after:-my-1",
            "after:absolute after:right-0 after:bottom-0 after:left-0",
            "after:border-b-4 after:rounded-lg",
            "hover:text-dark-primary",
          )}
          key={"key-topic-" + index}
          onClick={() => handleClick(topic, "tabs")}
        >
          {topic}
        </button>
      ))}
    </div>
  );
}

function Sections({ sections, activeSection, handleClick }) {
  return (
    <div className="flex flex-col items-start">
      {sections.map(({ section }, index) => (
        <div
          key={index}
          className={classNames(
            index === activeSection
              ? "text-dark-primary after:border-black-primary"
              : "text-black-400 after:border-none before:mr-0",
            "relative hover:text-dark-primary",
            "after:border-r-4 after:rounded-lg before:mr-3",
            "after:absolute after:bottom-0 after:left-0 after:h-full",
          )}
        >
          <button
            className="py-1 text-left"
            key={"key-section-" + index}
            onClick={() => handleClick(index)}
          >
            {section}
          </button>
        </div>
      ))}
    </div>
  );
}

function SectionContent({ sections, activeSection }) {
  return sections
    .filter((s, index) => index === activeSection)
    .map(({ sectionContent }, index) => (
      <InlineBlockEditor
        jsonString={sectionContent}
        className="mt-10 mb-10 last:mb-0 first:mt-0"
        testid="sectionContent"
        key={index}
      />
    ));
}

function AccordionItems({ sections }) {
  return sections.map(({ section, sectionContent }, index) => (
    <AccordionItem
      key={index}
      id={`tab-accordion-${section}-${index}`}
      data-testid={`tab-accordion-${section}-${index}`}
      className="scroll-mt-28 md:scroll-mt-48 lg:scroll-mt-56"
    >
      <AccordionHeader>
        <h2>{section}</h2>
      </AccordionHeader>
      <AccordionPanel key={index}>
        <InlineBlockEditor
          jsonString={sectionContent}
          className="mt-10 mb-10 last:mb-0 first:mt-0"
          testid="sectionContent"
        />
      </AccordionPanel>
    </AccordionItem>
  ));
}

const fakeSpacerCard = (
  <div className="md:hidden shrink-0 screen-edge-spacer"></div>
);

function TabbedContent({ module }) {
  const { title, description, content } = module.fields;
  const [activeTopic, setActiveTopic] = useState(content?.[0].fields.topic);
  const [activeSection, setActiveSection] = useState(0);
  const [indices, setIndices] = useState([]);
  const { isMd } = useScreenSize();

  const sortedContent = sortBy(content, ["properties.itemOrder"]);
  const groupedByTopics = groupBy(sortedContent, "fields.topic");
  const groupedBySections = map(groupedByTopics, (arr, topic) => {
    const sectionItems = arr.map((item) => item.fields);
    return {
      topic: topic,
      sections: map(sectionItems, (sectionItem) => ({
        section: sectionItem.section,
        sectionContent: sectionItem.blockEditor,
      })),
    };
  });

  const topics = uniqBy(groupedBySections, "topic");
  const hasTopics = topics.length > 1;

  const sections = groupedBySections
    .filter((sectionItem) => sectionItem.topic === activeTopic)
    .map((item) => item.sections)
    .flat();
  const hasSections = sections.length > 1;

  const handleClick = (topic, category) => {
    if (category) {
      setActiveTopic(topic);
      setActiveSection(0);
    } else {
      setActiveSection(topic);
    }
  };

  const toggleItem = (toggledIndex) => {
    if (Array.isArray(toggledIndex)) {
      // used for showing/hiding all
      setIndices(toggledIndex);
    } else if (indices.includes(toggledIndex)) {
      setIndices(
        indices.filter((currentIndex) => currentIndex !== toggledIndex),
      );
    } else {
      const sortedIndices = sortBy([...indices, toggledIndex]);
      setIndices(sortedIndices);
    }
  };

  return (
    <div
      data-psnid="psn_tabbed_content"
      className="md:first:pt-37 lg:first:pt-45 first:pt-21 mb-15 md:mb-25"
    >
      <FadeInBottomOnScroll>
        <div
          className={classNames(
            "px-4 mx-auto sm:px-15 md:px-17 lg:px-23 xl:px-46",
            "max-w-full w-120 sm:w-210 md:w-auto",
          )}
        >
          {title && (
            <h2 className="mb-4 font-bold text-32/36 md:text-44/52 text-dark-primary">
              {title}
            </h2>
          )}
          {description && (
            <p className="mb-10 font-normal text-20/32 md:text-24/36 text-black-primary">
              {getContentWithLineBreaks(description)}
            </p>
          )}
        </div>
        <div
          className={classNames(
            "overflow-x-auto no-scrollbar whitespace-nowrap",
            "flex md:grid md:col-span-8 mb-5 md:mb-10",
            "font-normal text-center text-14/20 md:text-16/24",
            "px-4 sm:px-15 md:px-17 lg:px-23 xl:px-46",
          )}
        >
          {(hasTopics || !isMd) && (
            <>
              {fakeSpacerCard}
              <Tabs {...{ topics, activeTopic, handleClick }} />
              {fakeSpacerCard}
            </>
          )}
        </div>
        <div
          className={classNames(
            "px-4 mx-auto sm:px-15 md:px-17 lg:px-23 xl:px-46",
            "max-w-full w-120 sm:w-210 md:w-auto",
          )}
        >
          {isMd ? (
            <div className="grid md:grid-cols-24 gap-x-7">
              <div className="col-span-9 font-medium text-14/20 md:text-16/24">
                {hasSections && (
                  <Sections {...{ sections, activeSection, handleClick }} />
                )}
              </div>
              <div className="md:col-span-15 md:col-start-10 lg:col-span-14">
                <SectionContent {...{ sections, activeSection }} />
              </div>
            </div>
          ) : (
            <div className="grid">
              <Accordion
                index={indices}
                onChange={toggleItem}
                className="col-span-12"
              >
                <AccordionItems {...{ sections }} />
              </Accordion>
            </div>
          )}
        </div>
      </FadeInBottomOnScroll>
    </div>
  );
}

export { TabbedContent };
