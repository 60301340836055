import { get } from "lodash-es";

function getPageLinkProps(page) {
  if (page.redirect) {
    const originalUrl = get(page, "redirect.url", "");
    const url = originalUrl.startsWith("~")
      ? originalUrl.replace("~", "")
      : originalUrl;
    return {
      ...page.redirect,
      url,
    };
  }
  return {
    url: page.path,
  };
}

export { getPageLinkProps };
