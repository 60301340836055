import { ArticleHeader } from "components/ArticleHeader";
import { BlockEditor } from "components/BlockEditor";
import { Breadcrumb } from "components/Breadcrumb";
import { DescriptionList } from "components/DescriptionList";
import {
  BLOCK_SIZE_CENTERED,
  estimateReadTime,
} from "helpers/blockEditorHelper";
import { formatDate } from "helpers/dateHelper";
import { useGlobalData } from "providers/GlobalData";
import { getCustomInitialProps } from "./getArticleDetailsProps";

function ArticleDetails({ dynamicPageItem, customData }) {
  const {
    fields: {
      title,
      headerDescription,
      tag,
      date,
      excerpt,
      breadcrumb,
      displayReadTime,
    },
  } = dynamicPageItem;

  const { descriptionList } = customData;
  const { parentItems } = useGlobalData();

  const shouldShowBreadcrumbs = breadcrumb?.toLowerCase() === "true";
  const shouldDisplayReadTime = displayReadTime?.toLowerCase() === "true";

  const formattedDate = formatDate(date);
  const readTime = shouldDisplayReadTime ? estimateReadTime(excerpt) : null;
  const metaData = [tag?.fields?.title, formattedDate, readTime];

  return (
    <div className="pb-15 md:pb-25">
      <ArticleHeader
        title={title}
        description={headerDescription}
        metaData={metaData}
      />
      {shouldShowBreadcrumbs && (
        <Breadcrumb
          parentItems={parentItems}
          currentItem={{
            menuText: title,
          }}
        />
      )}
      {excerpt ? (
        <BlockEditor
          data={excerpt}
          size={BLOCK_SIZE_CENTERED}
          className="mt-10 mb-10 last:mb-0 first:mt-0"
          testid="articleContent"
        />
      ) : null}
      {descriptionList?.map((descriptionListItem, index) => (
        <DescriptionList key={index} data={descriptionListItem.fields} />
      ))}
    </div>
  );
}

ArticleDetails.getCustomInitialProps = getCustomInitialProps;

export { ArticleDetails };
