import classNames from "classnames";

function ModuleWrapper({ children, id, testid, psnid, className }) {
  // in case this is the first module, force padding top to accommodate header
  return (
    <div
      id={id}
      className={classNames(
        "first:pt-21 md:first:pt-37 lg:first:pt-45",
        "scroll-mt-28 md:scroll-mt-48 lg:scroll-mt-56",
        "mb-15 md:mb-25",
        "px-4 sm:px-15 md:px-17 lg:px-23 xl:px-46",
        "max-w-full w-120 sm:w-210 md:w-auto mx-auto",
        className,
      )}
      data-testid={testid}
      data-psnid={psnid}
    >
      {children}
    </div>
  );
}

export { ModuleWrapper };
