import { useState } from "react";
import classNames from "classnames";
import { sortBy } from "lodash-es";
import {
  Accordion,
  AccordionHeader,
  AccordionItem,
  AccordionPanel,
} from "components/Accordion";
import { Link } from "components/Link";
import { APP_ROUTES } from "helpers/constants";
import { getFaqListingProps } from "./getFaqListingProps";

function FaqListing({ customData }) {
  const init = customData?.sections?.length;
  const [indices, setIndices] = useState([]); // all accordions closed on init
  const [activeSection, setSection] = useState(0); // all sections shown on init

  if (!init) return null;

  const toggleItem = (toggledIndex) => {
    if (Array.isArray(toggledIndex)) {
      // used for showing/hiding all
      setIndices(toggledIndex);
    } else if (indices.includes(toggledIndex)) {
      setIndices(
        indices.filter((currentIndex) => currentIndex !== toggledIndex),
      );
    } else {
      const sortedIndices = sortBy([...indices, toggledIndex]);
      setIndices(sortedIndices);
    }
  };

  const handleSectionClick = (sectionId) => {
    toggleItem([]); // toggling section always collapses accordions
    setSection(sectionId);
  };

  const filterSections = (accordionPanel) => {
    return activeSection === customData.sections[0].sectionId
      ? true
      : accordionPanel.sections.includes(activeSection);
  };

  const renderAccordionItems = (allAccordionItems) => {
    return allAccordionItems
      .filter(
        (accordionItem) =>
          accordionItem.accordionPanel.filter(filterSections).length,
      )
      .map((item) => {
        return (
          <AccordionItem
            key={"key-faq-accordion-" + item.accordionHeader}
            id={"faq-accordion-" + item.accordionHeader}
            data-testid={"faq-accordion-" + item.accordionHeader}
            className="scroll-mt-28 md:scroll-mt-48 lg:scroll-mt-56"
          >
            <AccordionHeader>
              <h2>{item.accordionHeader}</h2>
            </AccordionHeader>
            {item.accordionPanel
              .filter(filterSections)
              .map((accordionPanel) => (
                <AccordionPanel
                  key={accordionPanel.contentId}
                  className="last:pb-10"
                >
                  <h3 className="text-18/28 md:text-20/32 text-black-primary hover:underline">
                    <Link href={`${APP_ROUTES.faqs}/${accordionPanel.slug}`}>
                      <a
                        data-ga={JSON.stringify({
                          content_tertiary: item.accordionHeader,
                          event_label: [accordionPanel.title],
                          event_category: "faq menu",
                        })}
                      >
                        {accordionPanel.title}
                      </a>
                    </Link>
                  </h3>
                </AccordionPanel>
              ))}
          </AccordionItem>
        );
      });
  };

  return (
    <div className="mb-10 md:first:pt-37 lg:first:pt-45 first:pt-21 sm:mb-20">
      <div
        className={classNames(
          "flex overflow-x-auto no-scrollbar",
          "mb-5 md:mb-10",
          "px-4 sm:px-15 md:px-17 lg:px-23 xl:px-46",
          "mx-auto",
          "max-w-full w-120 sm:w-210 md:w-auto",
        )}
      >
        {customData.sections.map((item, index) => {
          const gaData =
            index === 0
              ? undefined
              : JSON.stringify({
                  content_tertiary: "faqs listing",
                  event: "filter_click",
                  event_category: "faqs listing - faqs topics",
                  event_label: [`For ${item.title}`],
                });
          return (
            <button
              key={"key-section-" + item.sectionId}
              className={classNames(
                "first:pl-1.5 first:rounded-l-25",
                "whitespace-nowrap bg-black-50 py-1.5 font-medium text-14/20",
                "md:text-16/24 md:p-2",
                "last:pr-1.5 last:rounded-r-25 last:mr-5",
              )}
              onClick={() => handleSectionClick(item.sectionId)}
              data-ga={gaData}
            >
              <span
                className={classNames(
                  "inline-block px-5 py-3 first:rounded-l-25 last:rounded-r-25",
                  item.sectionId === activeSection
                    ? "text-white bg-orange-primary"
                    : "text-black-primary",
                )}
              >
                {index > 0 && "For"} {item.title}
              </span>
            </button>
          );
        })}
      </div>
      <div
        className={classNames(
          "px-4 mx-auto sm:px-15 md:px-17 lg:px-23 xl:px-46",
          "max-w-full w-120 sm:w-210 md:w-auto",
        )}
      >
        <div className="grid md:grid-cols-3 md:gap-x-7 lg:gap-x-8">
          <Accordion
            index={indices}
            onChange={toggleItem}
            className="col-span-12 md:col-span-2"
          >
            {renderAccordionItems(customData.accordionItems)}
          </Accordion>
        </div>
      </div>
    </div>
  );
}

FaqListing.getCustomInitialProps = getFaqListingProps;

export { FaqListing };
