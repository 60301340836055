import { isString } from "lodash-es";
import NextImage from "next/image";

function isSvg(src) {
  return src.toLowerCase().includes(".svg");
}

function isFileValidForBlur(src, width) {
  if (width < 40) {
    return false;
  }

  const isSvgOrPng = isSvg(src) || src.toLowerCase().includes(".png");

  return isSvgOrPng ? false : true;
}

function getBlurUrl(src, config = {}) {
  const params = new URLSearchParams({
    w: 200,
    q: 50,
    format: "auto",
    ...config,
  });
  const queryString = params.toString();
  const blurUrl = `${src}?${queryString}`;
  return blurUrl;
}

function getBlurProps(src, width) {
  if (isFileValidForBlur(src, width)) {
    return {
      blurDataURL: getBlurUrl(src),
      placeholder: "blur",
    };
  }
  return {};
}


function loader({ src, width, quality = 75 }) {
  if (isSvg(src) || quality === 100) {
    return src;
  }
  const params = new URLSearchParams({ w: width, q: quality, format: "auto" });
  const queryString = params.toString();
  const fullUrl = `${src}?${queryString}`;
  return fullUrl;
}

function getCustomProps(props) {
  if (!isString(props.src) || props?.quality === 100) {
    return props;
  }

  const { placeholder, blurDataURL } = getBlurProps(props.src, props.width);

  const unoptimized = isSvg(props.src);

  return {
    unoptimized,
    placeholder,
    blurDataURL,
    ...props,
  };
}

function Image(props) {
  const customProps = getCustomProps(props);

  return <NextImage loader={loader} {...customProps} />;
}

export { Image, getBlurUrl };
