import { clamp, isFunction } from "lodash-es";
import {
  filterByYear,
  filterByMonth,
  filterByTagId,
  filterByTopicId,
  findMonthsForSelectedYear,
  toggleItemFromList,
} from "./helpers";

function setYear(state, selectedYear) {
  const allMonths = findMonthsForSelectedYear(state.allDates, selectedYear);

  return {
    ...state,
    allMonths,
    selectedYear,
    selectedMonth: "",
  };
}

function setMonth(state, selectedMonth) {
  return {
    ...state,
    selectedMonth,
  };
}

function setTag(state, tagId) {
  const selectedTagIds = toggleItemFromList(state.selectedTagIds, tagId);

  return {
    ...state,
    selectedTagIds,
  };
}

function setTopic(state, topicId) {
  const selectedTopicIds = toggleItemFromList(state.selectedTopicIds, topicId);

  return {
    ...state,
    selectedTopicIds,
  };
}

function reset(state) {
  return {
    ...state,
    filteredArticles: [...state.articleItems],
    selectedYear: "",
    selectedMonth: "",
    selectedTagIds: [],
    selectedTopicIds: [],
  };
}

function loadMoreArticles(state) {
  const { filteredArticles, visibleArticlesCount, pageSize } = state;
  const totalArticles = filteredArticles.length;
  const updatedVisibleArticlesCount = visibleArticlesCount + pageSize;
  const clampedArticlesCount = clamp(
    updatedVisibleArticlesCount,
    pageSize,
    totalArticles,
  );
  return {
    ...state,
    visibleArticlesCount: clampedArticlesCount,
  };
}

function filterArticles(state) {
  const {
    articleItems,
    selectedYear,
    selectedMonth,
    selectedTagIds,
    selectedTopicIds,
  } = state;

  const filteredArticles = articleItems
    .filter((articleItem) => filterByYear(articleItem, selectedYear))
    .filter((articleItem) => filterByMonth(articleItem, selectedMonth))
    .filter((articleItem) => filterByTagId(articleItem, selectedTagIds))
    .filter((articleItem) => filterByTopicId(articleItem, selectedTopicIds));

  return {
    ...state,
    filteredArticles,
    visibleArticlesCount: state.pageSize,
  };
}

const ACTION_TYPES = Object.freeze({
  setYear: "setYear",
  setMonth: "setMonth",
  setTag: "setTag",
  setTopic: "setTopic",
  reset: "reset",
  loadMoreArticles: "loadMoreArticles",
  filterArticles: "filterArticles",
});

const handlers = {
  [ACTION_TYPES.setYear]: setYear,
  [ACTION_TYPES.setMonth]: setMonth,
  [ACTION_TYPES.setTag]: setTag,
  [ACTION_TYPES.setTopic]: setTopic,
  [ACTION_TYPES.reset]: reset,
  [ACTION_TYPES.loadMoreArticles]: loadMoreArticles,
  [ACTION_TYPES.filterArticles]: filterArticles,
};

function reducer(state, action) {
  const { type, payload } = action;
  const handler = handlers[type];
  const isValidHandler = isFunction(handler);

  if (!isValidHandler) {
    throw new Error(
      `ArticleFilter reducer called with unsupported action: ${action.type}`,
    );
  }

  return handler(state, payload);
}

export { ACTION_TYPES, reducer };
