import classNames from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import { get } from "lodash-es";
import { useRouter } from "next/router";
import { ArrowRight } from "assets/icons";
import { Link } from "components/Link";
import {
  setCurrentMobileNav,
  toggleNav,
  useNavigation,
} from "providers/Navigation";
import { getPageLinkProps } from "../getPageLinkProps";

function NavLink({ item }) {
  const { dispatch } = useNavigation();
  const hasChildren = get(item, "children", []).length !== 0;
  const { url, target } = getPageLinkProps(item);
  const router = useRouter();
  const isActivePrimary = router.asPath.includes(item.path);
  const isActiveSecondary = router.asPath === url;

  function onClick(e) {
    if (hasChildren) {
      e.preventDefault();
      setCurrentMobileNav({ dispatch, payload: item });
    } else {
      toggleNav({ dispatch });
    }
  }

  return (
    <Link href={url}>
      <a
        className={classNames("flex items-center py-3 text-sm", {
          "font-medium text-dark-primary": isActivePrimary || isActiveSecondary,
        })}
        target={target}
        onClick={onClick}
      >
        <span className="flex-1">{item.menuText}</span>
        {hasChildren && (
          <ArrowRight className="w-4 h-4 fill-current text-black-200" />
        )}
      </a>
    </Link>
  );
}

function MainNav() {
  const {
    state: { currentNav, primaryNav },
    dispatch,
  } = useNavigation();

  function onOverviewLinkClick() {
    toggleNav({ dispatch });
  }

  const nav = currentNav || primaryNav;

  return nav ? (
    <AnimatePresence exitBeforeEnter>
      <motion.div
        initial={{
          x: 50,
          opacity: 0,
        }}
        animate={{
          x: 0,
          opacity: 1,
        }}
        exit={{
          x: -50,
          opacity: 0,
        }}
        transition={{ type: "tween", duration: 0.15 }}
        key={nav.name}
        data-testid="mainNav"
        className="text-black-primary"
      >
        <Link href={nav.path}>
          <a
            className="flex items-center py-3 text-sm"
            onClick={onOverviewLinkClick}
          >
            <span className="flex-1">{nav.menuText} overview</span>
            <ArrowRight className="w-4 h-4 fill-current text-black-200" />
          </a>
        </Link>
        <ul className="flex flex-col">
          {nav.children?.map((navItem) => (
            <li key={navItem.path}>
              <NavLink item={navItem} parentLabel={[nav.menuText]} />
            </li>
          ))}
        </ul>
      </motion.div>
    </AnimatePresence>
  ) : null;
}

export { MainNav };
