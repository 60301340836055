import classNames from "classnames";
import { Icon } from "components/Icon";

export function IconListItem(props) {
  const { icon, title, children, className, theme, testId, ...rest } = props;

  return (
    <div
      className={classNames("flex items-start mb-3 last:mb-0", className)}
      data-testid={testId}
      {...rest}
    >
      <div
        className={classNames(
          "flex items-center justify-center rounded-full mr-3",
          {
            "bg-orange-primary text-white": theme === "primary",
            "bg-white text-orange-primary": theme === "secondary",
            "bg-black-primary text-white": theme === "tertiary",
          },
        )}
      >
        <Icon name={icon} size="24" />
      </div>
      <div className="flex flex-wrap items-center">
        <h4 className="mr-1 text-14/20 md:text-16/24">{title}:</h4>
        <p className="text-14/20 md:text-16/24">{children}</p>
      </div>
    </div>
  );
}
