// these functions must be wrapped within a useEffect or componentDidMount
export const setWithExpiry = (key, data, ttl) => {
  const item = {
    ...data,
    expiry: new Date().getTime() + ttl,
  };
  localStorage.setItem(key, JSON.stringify(item));
};

export const getWithExpiry = (key) => {
  const itemString = window.localStorage.getItem(key);
  if (!itemString) return null;

  const item = JSON.parse(itemString);
  const isExpired = new Date().getTime() > item.expiry;
  if (isExpired) {
    window.localStorage.removeItem(key);
    return null;
  }

  return item;
};

export const getAllStorage = () => ({ ...window.localStorage });

export const removeStorageItem = (key) => window.localStorage.removeItem(key);

export const isLocalStorageAvailable = () => {
  try {
    const key = "storageAvailability";
    const value = "true";
    window.localStorage.setItem(key, value);
    window.localStorage.removeItem(key);
    return true;
  } catch (e) {
    return false;
  }
}

export const appPersistStateKey = "persist:root";
