import { Alert } from "components/Alert";
import { ModuleWrapper } from "components/Wrappers";
import { useRouter } from "next/router";

function Note({ module }) {
  const { fields } = module;
  const { asPath: currentPath } = useRouter();
  const isJoinPages = currentPath.startsWith("/join");
  return (
    <ModuleWrapper className={isJoinPages ? "!px-4 !py-0 !my-10 md:!my-15" : ""} psnid="psn_note">
      <Alert fields={fields} type={fields.type} />
    </ModuleWrapper>
  );
}

export { Note };
