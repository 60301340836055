import styles from "./form-controls.module.scss";

export default function RadioButton(props) {
  const {
    name,
    value,
    checked,
    className = "",
    testId,
    onChange,
    text,
    size,
  } = props;

  const checkedClassName = checked ? styles.checked : "";

  return (
    <label
      className={`${styles.radio} ${styles[size]} ${checkedClassName} ${className}`}
    >
      <input
        name={name}
        id={`${name}_${value}`}
        type="radio"
        className={styles.radio_input}
        checked={checked}
        onChange={onChange}
        value={value}
        data-testid={testId}
      />
      <span className={styles.radio_icon_container}>
        <span className={styles.radio_icon} />
      </span>
      <span className={styles.radio_text}>{text}</span>
    </label>
  );
}
