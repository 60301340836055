import Axios from "axios";

export async function createResiCallbackLead(requestObject) {
  try {
    return await Axios.post("/api/onlinesignup/rescreatelead", requestObject, {
      auth: {
        username: process.env.NEXT_PUBLIC_AUTH_USER_NAME,
        password: process.env.NEXT_PUBLIC_AUTH_PASSWORD,
      },
    });
  } catch (ex) {
    return Promise.reject(ex);
  }
}
