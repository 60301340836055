import { DefaultBlocks, SpecialBlocks } from "components/BlockEditor";
import {
  BLOCK_GROUP_TYPES,
  getBlocks,
  groupBlocksByType,
} from "helpers/blockEditorHelper";

function BlockEditor({
  data,
  size,
  className,
  testid = "blockEditor",
  psnid = "psn_block_editor",
  jumptoSectionTitle,
  showJumptoSection,
}) {
  const blocksData = getBlocks(data);
  const groupedBlocks = groupBlocksByType(blocksData);

  if (groupedBlocks.length === 0) {
    return null;
  }

  return (
    <div className={className} data-testid={testid} data-psnid={psnid}>
      {groupedBlocks.map((blockGroup) => {
        const isDefault = blockGroup.type === BLOCK_GROUP_TYPES.default;
        const Blocks = isDefault ? DefaultBlocks : SpecialBlocks;
        return (
          <Blocks
            key={blockGroup.id}
            size={size}
            data={blockGroup.data}
            {...{ jumptoSectionTitle, showJumptoSection }}
          />
        );
      })}
    </div>
  );
}

export { BlockEditor };
