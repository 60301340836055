import classNames from "classnames";

function BgCurveMask({ bgColor, testid }) {
  return (
    <div
      data-testid={testid}
      className={classNames("h-30 sm:h-40 md:h-50 lg:h-60 curve-mask", bgColor)}
    />
  );
}

export { BgCurveMask };
