import StepNavigationItem from "join-form/components/step-navigation-item";
import {
  appBusinessRoutes,
  CALLBACK_REASON_LPG_REQUOTE_PPD_PLANS,
  CALLBACK_REASON_NO_RATES_FOR_SELECTED_TERM,
} from "helpers/constants";
import { useRouter } from "next/router";
import { connect } from "react-redux";

const {
  businessDetails,
  address,
  addressIcp,
  addressManual,
  fuel,
  contactDetails,
  success,
  callbackSuccess,
  ...allContactDetailsRouteInfo
} = appBusinessRoutes;
const contactDetailPageRoutes = Object.values(allContactDetailsRouteInfo);

const callbackReasonsCanDisplayContactDetails = [
  CALLBACK_REASON_NO_RATES_FOR_SELECTED_TERM,
  CALLBACK_REASON_LPG_REQUOTE_PPD_PLANS,
];

function ContactDetailsItem({ callbackReason, ...props }) {
  const { asPath: pathname } = useRouter();

  const isVisible = contactDetailPageRoutes.includes(pathname);

  if (!isVisible) {
    return null;
  }

  if (pathname === appBusinessRoutes.callback) {
    if (!callbackReasonsCanDisplayContactDetails.includes(callbackReason)) {
      return null;
    }
  }

  return (
    <StepNavigationItem
      title="Personal Details"
      path={contactDetails}
      {...props}
    />
  );
}

const mapStateToProps = (props, ownProps) => {
  const {
    appUserState: { callbackReason },
  } = props;

  return {
    callbackReason,
  };
};

export default connect(mapStateToProps)(ContactDetailsItem);
