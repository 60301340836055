function ErrorMessage({ message, testId }) {
  return (
    <p
      className="mt-2 text-red-primary text-12/16 md:text-13/16 font-normal"
      data-testid={testId}
    >
      {message}
    </p>
  );
}

export { ErrorMessage };
