import classNames from "classnames";

export const CircleDivider = ({ className }) => (
  <span
    className={classNames(
      "inline-block w-1.5 h-1.5 mx-1 rounded-2 mb-px",
      className,
    )}
  ></span>
);
