import { useState } from "react";
import classNames from "classnames";
import { RatesHeading } from "join-form/components/energy-rate";
import BottledGasRates from "./BottledGasRates";
import ElectricityRates from "./ElectricityRates";
import NaturalGasRates from "./NaturalGasRates";

export default function Rates({ highlightOnMobile, className, children }) {
  const [inclGst, setInclGst] = useState(false);

  return (
    <div className={classNames("space-y-2 mb-4", className)}>
      <RatesHeading inclGst={inclGst} setInclGst={setInclGst} />
      <ElectricityRates
        inclGst={inclGst}
        highlightOnMobile={highlightOnMobile}
      />
      <NaturalGasRates
        inclGst={inclGst}
        highlightOnMobile={highlightOnMobile}
      />
      <BottledGasRates
        inclGst={inclGst}
        highlightOnMobile={highlightOnMobile}
      />
      {children}
    </div>
  );
}
