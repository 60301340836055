import InputMask from "react-input-mask";
import classNames from "classnames";
import styles from "./form-controls.module.scss";

export default function DateInput(props) {
  const {
    size,
    type = "text",
    name,
    placeholder,
    label = "Date of Birth",
    value,
    onChange,
    errorMessage,
    showError,
    onBlur,
    isHalfWidth = false,
  } = props;

  const filledClassName = value ? styles.filled : "";
  const invalidClassName = showError ? styles.error : "";
  const sizeClassName = size ? styles.size28 : "";

  const className = [
    styles.control,
    filledClassName,
    invalidClassName,
    sizeClassName,
  ]
    .join(" ")
    .trim();

  return (
    <div className={className}>
      <label className={styles.label} htmlFor={name}>
        {label}
      </label>
      <InputMask
        className={classNames(styles.input, {
          [styles.halfWidth]: isHalfWidth,
        })}
        name={name}
        id={name}
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        mask="99/99/9999"
        maskChar={null}
        onBlur={onBlur}
      />
      {showError && (
        <span data-testid={`${name}ErrorMessage`} className={styles.errorText}>
          {errorMessage}
        </span>
      )}
    </div>
  );
}
