import classNames from "classnames";
import { CallToAction } from "components/CallToAction";
import { VARIANT } from "helpers/constants";
import { getContentWithLineBreaks } from "helpers/htmlHelper";
import { FadeInBottomAnimation, FadeInTopAnimation } from "./animations";
import { HeaderImage } from "./HeaderImage";

function OrangeWithImage({ module }) {
  const { fields } = module;
  const hasImage = Boolean(fields.image);
  const hasCtas = fields.cta1 || fields.cta2;
  const isCentered = fields.textalignment === "centre";
  return (
    <div
      data-testid="orangeImage"
      data-psnid="psn_header_level_three_content"
      className="relative mx-auto mb-10 md:first:pt-37 lg:first:pt-45 first:pt-21 sm:mb-20"
    >
      <div
        data-testid="background"
        className={classNames("absolute inset-x-0 top-0 bg-day-gradient", {
          "bottom-22 sm:bottom-49 md:bottom-53 lg:bottom-80": hasImage,
          "bottom-0": !hasImage,
        })}
      >
        <div
          className={classNames(
            "absolute inset-0 translate-y-px",
            "bg-bottom bg-no-repeat bg-auto",
            "bg-curve-sm sm:bg-curve-md md:bg-curve-lg lg:bg-curve-xl",
          )}
        />
      </div>
      <div
        className={classNames(
          "relative mx-auto",
          "px-4 sm:px-15 md:px-17 lg:px-23 xl:px-46",
          "max-w-full w-120 sm:w-210 md:w-auto",
        )}
      >
        <div className="md:grid md:gap-x-5 md:grid-cols-24">
          <div className="pt-7 md:col-span-22 md:col-start-2 md:pt-10">
            <div
              data-testid="headerContent"
              className={classNames("md:mb-15 sm:max-w-162 md:max-w-175", {
                "mx-auto text-center": isCentered,
                "mb-15": hasImage,
                "pb-25 md:pb-20": !hasImage,
              })}
            >
              <FadeInTopAnimation>
                <h1
                  className={classNames(
                    "mb-3 md:mb-5",
                    "font-bold text-white text-32/36 md:text-44/52",
                  )}
                >
                  {fields.title}
                </h1>
                <p
                  data-testid="headerContentDescription"
                  className="text-white text-18/28 md:text-20/32"
                >
                  {getContentWithLineBreaks(fields.description)}
                </p>
              </FadeInTopAnimation>
              {hasCtas ? (
                <div
                  data-testid="ctaSection"
                  className={classNames("flex flex-wrap mt-8 md:mt-10", {
                    "justify-center": isCentered,
                  })}
                >
                  <FadeInBottomAnimation isPrimary>
                    <CallToAction
                      data={fields.cta1}
                      testid="primaryCta"
                      variant={VARIANT.light}
                      dataGa={{
                        content_tertiary: fields.title,
                        event: "cta_click",
                        event_category: "cta",
                        event_label: [fields.cta1?.text],
                      }}
                    />
                  </FadeInBottomAnimation>
                  <FadeInBottomAnimation>
                    <CallToAction
                      data={fields.cta2}
                      variant={VARIANT.lightTransparent}
                      testid="linkCta"
                      dataGa={{
                        content_tertiary: fields.title,
                        event: "cta_click",
                        event_category: "cta",
                        event_label: [fields.cta2?.text],
                      }}
                    />
                  </FadeInBottomAnimation>
                </div>
              ) : null}
            </div>
          </div>
        </div>
        {fields.image ? (
          <HeaderImage image={fields.image} isCentered={isCentered} />
        ) : null}
      </div>
    </div>
  );
}

export { OrangeWithImage };
