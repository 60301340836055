import { compact } from "lodash-es";
import { Success } from "assets/icons";
import { PlanCardTooltip } from "./PlanCardTooltip";

function Tooltip({ data } = {}) {
  const { title, description, list } = data ?? {};
  const shouldRender = compact([title, description, list]).length > 0;

  if (!shouldRender) {
    return null;
  }

  const listWithValidEntries = compact(list);

  return (
    <PlanCardTooltip>
      {title ? <h4 className="font-medium text-16/24">{title}</h4> : null}
      {description ? <p className="text-14/20">{description}</p> : null}
      {listWithValidEntries.length ? (
        <ul className="ml-4 list-disc text-14/20">
          {listWithValidEntries.map((listItem, index) => (
            <li key={index}>{listItem}</li>
          ))}
        </ul>
      ) : null}
    </PlanCardTooltip>
  );
}

function PlanCardFeatures({ id, data } = {}) {
  const { features } = data ?? {};
  const hasData = compact(features).length > 0;

  if (!hasData) {
    return null;
  }

  return (
    <div id={id} data-testid={`${id}_features`} className="py-1 px-7">
      <ul className="flex flex-col gap-y-3">
        {features.map((item, index) => {
          return (
            <li
              key={index}
              className="flex gap-x-0.5 items-center text-black-primary"
            >
              <span className="py-1 pr-1">
                <Success className="size-4" />
              </span>
              <span className="text-14/20 md:text-16/24">{item.title}</span>
              <span className="flex self-start">
                <Tooltip data={item.tooltip} />
              </span>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

export { PlanCardFeatures };
