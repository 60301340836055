import classNames from "classnames";
import { sortBy } from "lodash-es";
import { ModuleWrapper } from "components/Wrappers";
import { SnippetContainerAnimation } from "./animations";
import { ImageCard } from "./ImageCard";
import { Snippet } from "./Snippet";

function SingleImageCardStatic({ module }) {
  const {
    fields: {
      image,
      snippet,
      anchor,
      imagealignment,
      energyBackgroundPosition,
    },
  } = module;

  const isImageRightAligned = imagealignment === "right";

  const sortedSnippetContent = sortBy(snippet, ["properties.itemOrder"]);

  return (
    <ModuleWrapper psnid="psn_single_image_card_static">
      <div
        className={classNames(
          "space-y-10",
          "md:space-y-0 md:grid md:grid-cols-24 md:gap-7 lg:gap-8",
        )}
        id={anchor}
      >
        <div
          className={classNames("md:col-span-12 lg:col-span-11", {
            "lg:col-start-2": !isImageRightAligned,
            "lg:col-end-24 md:order-2": isImageRightAligned,
          })}
        >
          <ImageCard
            bgPosition={energyBackgroundPosition}
            image={image}
            isRightAlignment={isImageRightAligned}
          />
        </div>

        <div
          className={classNames(
            "md:flex md:flex-col md:place-content-center md:col-span-12 lg:col-span-11",
            "max-w-162 md:max-w-200",
            {
              "lg:col-end-24": !isImageRightAligned,
              "lg:col-start-2": isImageRightAligned,
            },
          )}
        >
          <SnippetContainerAnimation>
            {sortedSnippetContent.map((item, index) => (
              <Snippet
                content={item}
                key={index}
                isFirstItem={index === 0}
                isSnippetLeftAligned={isImageRightAligned}
              />
            ))}
          </SnippetContainerAnimation>
        </div>
      </div>
    </ModuleWrapper>
  );
}

export { SingleImageCardStatic };
