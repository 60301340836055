import classNames from "classnames";
import styles from "./icon.module.scss";

const icons = "/icons/icon-sprite.svg";

const imageIconMap = {
  BottledGasBadge: "/icons/individual-icons/BottledGasBadge.svg",
  BottledGasBadgeBlack: "/icons/individual-icons/BottledGasBadgeBlack.svg",
  CarBlack: "/icons/individual-icons/cars/Black.png",
  CarBlue: "/icons/individual-icons/cars/Blue.png",
  CarGreen: "/icons/individual-icons/cars/Green.png",
  CarRed: "/icons/individual-icons/cars/Red.png",
  CarSilver: "/icons/individual-icons/cars/Silver.png",
  CarWhite: "/icons/individual-icons/cars/White.png",
  ChevronDown: "/icons/individual-icons/ChevronDown.png",
  ElectricityBadge: "/icons/individual-icons/ElectricityBadge.svg",
  ElectricityBadgeBlack: "/icons/individual-icons/ElectricityBadgeBlack.svg",
  ExternalLink: "/icons/individual-icons/ExternalLink.svg",
  DualFuelElecGasBadgeTier3Orange:
    "/icons/individual-icons/fuel-icons-tier3/icon-df_elec_gas-tier3-genesis_orange@2x.png",
  DualFuelElecGasBadgeTier3White:
    "/icons/individual-icons/fuel-icons-tier3/icon-df_elec_gas-tier3-white@2x.png",
  DualFuelElecLpgBadgeTier3Orange:
    "/icons/individual-icons/fuel-icons-tier3/icon-df_elec_lpg-tier3-genesis_orange@2x.png",
  DualFuelElecLpgBadgeTier3White:
    "/icons/individual-icons/fuel-icons-tier3/icon-df_elec_lpg-tier3-white@2x.png",
  ElectricityBadgeTier3Orange:
    "/icons/individual-icons/fuel-icons-tier3/icon-elec-tier3-genesis_orange@2x.png",
  ElectricityBadgeTier3White:
    "/icons/individual-icons/fuel-icons-tier3/icon-elec-tier3-white@2x.png",
  GasBadgeTier3Orange:
    "/icons/individual-icons/fuel-icons-tier3/icon-gas-tier3-genesis_orange@2x.png",
  GasBadgeTier3White:
    "/icons/individual-icons/fuel-icons-tier3/icon-gas-tier3-white@2x.png",
  BottledGasBadgeTier3Orange:
    "/icons/individual-icons/fuel-icons-tier3/icon-lpg-tier3-genesis_orange@2x.png",
  BottledGasBadgeTier3White:
    "/icons/individual-icons/fuel-icons-tier3/icon-lpg-tier3-white@2x.png",
  GasBadge: "/icons/individual-icons/GasBadge.svg",
  GasBadgeBlack: "/icons/individual-icons/GasBadgeBlack.svg",
  InfoFilled: "/icons/individual-icons/InfoFilled.svg",
  InfoUnfilled: "/icons/individual-icons/InfoUnfilled.svg",
};

const iconSizeMap = {
  72: styles.size72,
  60: styles.size60,
  50: styles.size50,
  38: styles.size38,
  36: styles.size36,
  32: styles.size32,
  24: styles.size24,
  20: styles.size20,
  18: styles.size18,
  12: styles.size12,
};

const tabletSizeMap = {
  38: styles.tabletSize38,
  36: styles.tabletSize36,
  32: styles.tabletSize32,
  24: styles.tabletSize24,
  20: styles.tabletSize20,
  18: styles.tabletSize18,
  12: styles.tabletSize12,
};

const desktopSizeMap = {
  96: styles.desktopSize96,
  72: styles.desktopSize72,
  50: styles.desktopSize50,
  38: styles.desktopSize38,
  36: styles.desktopSize36,
  32: styles.desktopSize32,
  24: styles.desktopSize24,
  20: styles.desktopSize20,
  18: styles.desktopSize18,
  12: styles.desktopSize12,
};

function getSizeClasses({ size, tabletSize, desktopSize }) {
  const sizeClassName = iconSizeMap[size] || "";
  const tabletSizeClassName = tabletSizeMap[tabletSize] || "";
  const desktopSizeClassName = desktopSizeMap[desktopSize] || "";

  const allSizesClassName = classNames(
    sizeClassName,
    tabletSizeClassName,
    desktopSizeClassName,
  );

  return allSizesClassName;
}

export default function Icon(props) {
  const {
    id = "",
    name,
    title,
    className = "",
    size = 36,
    tabletSize = 0,
    desktopSize = 0,
    useSvg = true,
    useSizeClasses = true,
  } = props;

  const sizeClassNames = useSizeClasses
    ? getSizeClasses({ size, tabletSize, desktopSize })
    : null;

  return (
    <i
      className={classNames(styles.icon, sizeClassNames, className)}
      data-testid={`${id}Icon`}
    >
      {useSvg ? (
        <svg className={styles.svg}>
          <use href={`${icons}#${name}`} />
        </svg>
      ) : (
        // eslint-disable-next-line @next/next/no-img-element
        <img alt={title || name} src={imageIconMap[name]} />
      )}
    </i>
  );
}
