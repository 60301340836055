import { forwardRef } from "react";
import classNames from "classnames";
import { ChevronDown } from "assets/icons";

const defaultStyles = {
  containerBorder: "bg-transparent",
  label: "opacity-0 pointer-events-none text-15/24 md:text-18/28",
  select: "text-black-300 border-b-black-300",
};

const filledStyles = {
  containerBorder: "bg-black-primary",
  label: "opacity-100 top-0 pointer-events-auto text-12/16 md:text-13/16",
  select: "text-black-primary border-b-black-primary",
};

function Select({ id, name, label, value, onChange, disabled, children }, ref) {
  const styles = value ? filledStyles : defaultStyles;

  return (
    <div className="relative">
      <div
        className={classNames(
          "pt-5 md:pt-4",
          "text-15/24 md:text-18/28",
          styles.container,
        )}
      >
        <label
          htmlFor={id}
          className={classNames(
            "absolute transition-all left-1",
            "cursor-pointer disabled:cursor-auto",
            styles.label,
          )}
        >
          {label}
        </label>
        <select
          id={id}
          name={name}
          ref={ref}
          className={classNames(
            "peer",
            "appearance-none bg-transparent w-full transition-all outline-none",
            "cursor-pointer disabled:cursor-auto",
            "pl-1 pr-9 pb-2",
            "border-b border-b-black-300",
            "hover:border-b-orange-primary",
            "focus:border-b-orange-primary",
            "disabled:hover:border-b-black-300",
            "disabled:focus:border-b-black-300",
            styles.select,
          )}
          value={value}
          onChange={onChange}
          disabled={disabled}
        >
          <option value="">{label}</option>
          {children}
        </select>
        <ChevronDown className="w-4.5 h-4.5 absolute right-0 bottom-3 text-black-primary pointer-events-none" />
        <div
          className={classNames(
            "transition h-px",
            disabled
              ? null
              : "peer-hover:bg-orange-primary peer-focus-within:bg-orange-primary",
            styles.containerBorder,
          )}
        />
      </div>
    </div>
  );
}

Select = forwardRef(Select);

export { Select };
