import { createSelector } from "@reduxjs/toolkit";
import { Formik } from "formik";
import { AnimatePresence, motion } from "framer-motion";
import { submitPersonalDetailsForm } from "actions/appUserStateActions";
import { createResiSimpleLead } from "actions/residentialCallbackActions";
import Feedback from "join-form/components/feedback";
import {
  Dropdown,
  errorText,
  Input,
  Recaptcha,
} from "join-form/components/form-controls";
import Intro from "join-form/components/intro";
import StepProgressionButton from "join-form/components/step-progression-button";
import { appResidentialRoutes } from "helpers/constants";
import { trackResidentialContinueButton } from "helpers/gtmHelper";
import {
  selectAppState,
  selectAppUserState,
} from "reducers/selector";
import { useRouter } from "next/router";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { InlineBlockEditor } from "components/BlockEditor";
import { getPageData } from "helpers/getPageData";
import {
  footerFade,
  footerFadeTransition,
  headerFade,
  heightFadeTransition,
  heightFadeVariants,
  itemContainer,
  itemFade,
} from "./animations";
import styles from "./callback-page.module.scss";

const modules = [
  "GE_JOIN_Intro_Header",
  "GE_JOIN_Residential_Callback_Page",
  "GE_JOIN_Fine_Print",
];

export const defaultPageData = {
  fields: {
    title: "About your home",
    blockEditor: null,
    titleLabel: "Title",
    firstNameLabel: "First Name",
    lastNameLabel: "Last Name",
    emailLabel: "Email",
    phoneLabel: "Phone",
    finePrint: null,
  },
};

function CallbackPage(props) {
  const {
    backButtonUrl,
    values,
    touched,
    errors,
    handleChange,
    handleSubmit,
    setFieldValue,
    isSubmitting,
    isValid,
    status,
    pageProps,
  } = props;

  const pageData = getPageData({
    modules,
    page: pageProps.page,
    defaultPageData,
  });

  function onRecaptchaChange(value) {
    setFieldValue("recaptcha", value);
  }

  return (
    <motion.div
      className={styles.container}
      exit="undefined"
      data-testid="callbackPage"
    >
      <Intro>
        {(introStyles) => (
          <motion.div
            initial="initial"
            animate="animate"
            exit="exit"
            variants={headerFade}
          >
            <h1 className={introStyles.title}>{pageData.fields.title}</h1>
            <div className={introStyles.text}>
              <InlineBlockEditor
                jsonString={pageData.fields.blockEditor}
                enableProse={false}
              />
            </div>
          </motion.div>
        )}
      </Intro>
      <motion.div
        initial="initial"
        animate="animate"
        exit="exit"
        variants={itemContainer}
        className={styles.body}
      >
        <motion.div variants={itemFade} className={styles.item}>
          <div className={styles.row}>
            <div className={`${styles.column} ${styles.title_field_container}`}>
              <Dropdown
                name="title"
                placeholder={pageData.fields.titleLabel}
                value={values.title}
                options={[
                  { value: "MRS", name: "Mrs" },
                  { value: "MISS", name: "Miss" },
                  { value: "MR", name: "Mr" },
                  { value: "DR", name: "Dr" },
                  { value: "MS", name: "Ms" },
                  { value: "REV", name: "Rev" },
                ]}
                onChange={handleChange}
                errorMessage={errors.title}
                showError={errors.title && touched.title}
              />
            </div>
            <div className={styles.column}>
              <Input
                name="firstName"
                placeholder={pageData.fields.firstNameLabel}
                value={values.firstName}
                onChange={handleChange}
                errorMessage={errors.firstName}
                showError={errors.firstName && touched.firstName}
              />
            </div>
            <div className={styles.column}>
              <Input
                name="lastName"
                placeholder={pageData.fields.lastNameLabel}
                value={values.lastName}
                onChange={handleChange}
                errorMessage={errors.lastName}
                showError={errors.lastName && touched.lastName}
              />
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.column}>
              <Input
                name="email"
                placeholder={pageData.fields.emailLabel}
                value={values.email}
                onChange={handleChange}
                errorMessage={errors.email}
                showError={errors.email && touched.email}
              />
            </div>
            <div className={styles.column}>
              <Input
                name="phone"
                placeholder={pageData.fields.phoneLabel}
                value={values.phone}
                onChange={handleChange}
                errorMessage={errors.phone}
                showError={errors.phone && touched.phone}
              />
            </div>
          </div>

          <div className={styles.radio_container}>
            <div className={styles.row}>
              <div className={`${styles.column} ${styles.radio_column}`}>
                <Recaptcha
                  onChange={onRecaptchaChange}
                  // these are required for react-google-recaptcha mock to work
                  values={values}
                  setFieldValue={setFieldValue}
                />
              </div>
            </div>
            {errors.recaptcha && touched.recaptcha && (
              <span data-testid="recaptchaErrorMessage" className={errorText}>
                {errors.recaptcha}
              </span>
            )}
          </div>
        </motion.div>
      </motion.div>
      <motion.div
        initial="initial"
        animate="animate"
        exit="exit"
        variants={footerFade}
        transition={footerFadeTransition}
        className={styles.small_print}
      >
        <InlineBlockEditor
          jsonString={pageData.fields.finePrint}
          enableProse={false}
        />
      </motion.div>
      <div className={styles.feedback_wrapper}>
        <AnimatePresence>
          {status && (
            <motion.div
              initial="initial"
              animate="animate"
              exit="exit"
              variants={heightFadeVariants}
              transition={heightFadeTransition}
              className={styles.feedback_container}
            >
              <Feedback type="error" title="Error" data-testid="errorMessage">
                {status}
              </Feedback>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
      <StepProgressionButton
        onContinueClick={handleSubmit}
        testId="continueButton"
        applyDisabledAttribute={isSubmitting}
        disabled={!isValid || isSubmitting}
        backLink={backButtonUrl}
        isLoading={isSubmitting}
        text="Submit"
      />
    </motion.div>
  );
}

const mapPropsToValues = ({ appUserState }) => ({
  title: appUserState.title,
  firstName: appUserState.firstName,
  lastName: appUserState.lastName,
  email: appUserState.email,
  phone: appUserState.phone,
  recaptcha: "",
});

const validationSchema = Yup.object().shape({
  title: Yup.string().required("Please select your title"),
  firstName: Yup.string()
    .max(40, "First name cannot be more than 40 characters")
    .required("Please enter your first name"),
  lastName: Yup.string()
    .max(80, "Last name cannot be more than 80 characters")
    .required("Please enter your last name"),
  email: Yup.string()
    .email("Please enter a valid email")
    .max(80, "Email address cannot be more than 80 characters")
    .required("Please enter your email address"),
  phone: Yup.string()
    .min(7, "Phone number must be at least 7 digits")
    .max(40, "Phone number cannot be more than 40 digits")
    .matches(/^[0-9+]+[0-9 ]{3,40}$/, "Please enter valid number")
    .required("Please enter your phone number"),
  recaptcha: Yup.string().nullable(true).required("This field is required"),
});

let pendingRequest = false;

const stateSelector = createSelector(
  [selectAppState, selectAppUserState],
  (appState, appUserState) => {
    const { callbackReferrerUrl } = appUserState;

    const backButtonUrl = callbackReferrerUrl || appResidentialRoutes.fuel;

    return { appUserState, backButtonUrl };
  },
);

function CallbackPageForm(pageProps) {
  const props = useSelector(stateSelector);
  const dispatch = useDispatch();

  const initialValues = mapPropsToValues(props);
  const router = useRouter();

  const onSubmit = async (values, options) => {
    if (pendingRequest) {
      return;
    }
    pendingRequest = true;

    dispatch(submitPersonalDetailsForm(values));

    const request = dispatch(createResiSimpleLead())
      .then(() => {
        pendingRequest = false;
        trackResidentialContinueButton({
          event: "input_contact_details",
          content_tertiary: "input contact details for callback",
        });
        router.push({ pathname: appResidentialRoutes.callbackSuccess });
      })
      .catch((ex) => {
        pendingRequest = false;
        options.setStatus("Something went wrong, please try again.");
        options.setSubmitting(false);
      });

    return request;
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      validateOnMount
      enableReinitialize
    >
      {(formikProps) => (
        <CallbackPage {...props} {...formikProps} pageProps={pageProps} />
      )}
    </Formik>
  );
}

export default CallbackPageForm;
