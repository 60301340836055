import { useEffect } from "react";
import { handlePreview } from "@agility/nextjs";
import Error from "next/error";
import { useRouter } from "next/router";
import Script from "next/script";
import { gaInit, gaPageView } from "helpers/gaHelper";
import { PageContent } from "./PageContent";
import { PageHead } from "./PageHead";
import { PageLoading } from "./PageLoading";

// set up handle preview
let isPreview = handlePreview();

function initializePreview() {
  isPreview = handlePreview();
}

function getSeoDetails(pageItem) {
  return {
    metaDescription: pageItem.seo?.metaDescription,
    metaKeywords: pageItem.seo?.metaKeywords,
    metaHTML: pageItem.seo?.metaHTML,
    sitemapVisible: pageItem.seo?.sitemapVisible,
  };
}

function AppendScriptTags() {
  return (
    <>
      <Script strategy="defer" src={process.env.NEXT_PUBLIC_SALESFORCE_HOST} />
      <Script strategy="defer" src="https://ettsdvmw.genesisenergy.co.nz/genesis.js" />
    </>
  );
}

function PageLayout(props) {
  const { page, sitemapNode, notFound, dynamicPageItem } = props;

  // dynamic pages have props dynamicPageItem and page.dynamic
  const seoDetails = getSeoDetails(dynamicPageItem ? dynamicPageItem : page);

  let sitemapVisible = seoDetails.sitemapVisible;

  if (typeof sitemapVisible !== "boolean") {
    sitemapVisible = page.dynamic
      ? page.dynamic.visibleOnSitemap
      : page.visible.sitemap;
  }

  const {
    isFallback,
    isReady,
    query: { slug },
  } = useRouter();

  useEffect(() => {
    gaInit();
    window.buildId = process.env.NEXT_PUBLIC_BUILD_ID;
  }, []);

  useEffect(() => {
    if (isReady) {
      gaPageView(slug);
    }
  }, [isReady, slug]);

  if (isFallback) {
    return <PageLoading />;
  }

  if (notFound) {
    return (
      <div className="antialiased" data-testid="pageNotFound">
        <Error statusCode={404} />
      </div>
    );
  }

  return (
    <div id="site-wrapper" className="bg-black-primary">
      <PageHead
        title={sitemapNode?.title ?? sitemapNode?.menuText}
        description={seoDetails.metaDescription}
        keywords={seoDetails.metaKeywords}
        metaHTML={seoDetails.metaHTML}
        sitemapVisible={sitemapVisible}
      />
      {isPreview ? <PageLoading /> : <PageContent {...props} />}
      <AppendScriptTags />
    </div>
  );
}

export { PageLayout, initializePreview };
