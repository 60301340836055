import React, { useState } from "react";
import { createSelector } from "@reduxjs/toolkit";
import { submitPlanSelectForm } from "actions/appState/appStateActions";
import { setIsExistingCustomer } from "actions/appUserStateActions";
import { setPlanTerm } from "actions/residentialPlanConfigActions";
import classNames from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import Feedback from "join-form/components/feedback";
import Intro from "join-form/components/intro";
import StepProgressionButton from "join-form/components/step-progression-button";
import RatesDisclaimer from "join-form/partials/rates/residential/RatesDisclaimer";
import { useRouter } from "next/router";
import { useDispatch, useSelector } from "react-redux";
import {
  selectAppState,
  selectResidentialOffers,
  selectResidentialPlanConfig,
} from "reducers/selector";
import { InlineBlockEditor } from "components/BlockEditor";
import {
  appResidentialRoutes,
  PLAN_TYPE_BASIC,
  PLAN_TYPE_EV_PLUS,
  PLAN_TYPE_PLUS,
  PLAN_TYPE_PLUS_FIXED,
  PLAN_TYPE_PLUS_FLEXI,
  RESIDENTIAL_PLAN_TERM_FIXED12,
  RESIDENTIAL_PLAN_TERM_FLEXI,
} from "helpers/constants";
import { getPageData } from "helpers/getPageData";
import { trackResidentialContinueButton } from "helpers/gtmHelper";
import { getTotalCredit } from "helpers/joiningCreditHelper";
import {
  getDualFuelDiscount,
  getTotalDiscountPercentage,
} from "helpers/joiningDiscountsHelper";
import { getPlanTypeByTerm } from "helpers/plansHelper";
import { isDualFuelDiscountApplicable } from "helpers/productHelper";
import {
  fadeInBottomAnimation,
  headerFade,
  heightFadeTransition,
  heightFadeVariants,
  itemContainer,
} from "./animations";
import styles from "./plan-select-page.module.scss";
import {
  EvPlanAlert,
  PlanCard,
  PlanCardFeatures,
  PlanCardHeader,
  PlanCardRates,
  PlanSelectButton,
  PlanSelectTab,
} from "./sub-components";
import transformPlanData from "./transformPlanData";

const validPlanTypes = [
  PLAN_TYPE_PLUS,
  PLAN_TYPE_PLUS_FIXED,
  PLAN_TYPE_PLUS_FLEXI,
  PLAN_TYPE_BASIC,
  PLAN_TYPE_EV_PLUS,
];

const getPlanNamesMapForGA = (planType) => {
  switch (planType) {
    case PLAN_TYPE_BASIC:
      return "energy basic";
    case PLAN_TYPE_PLUS_FIXED:
      return "energy plus fixed";
    case PLAN_TYPE_PLUS_FLEXI:
      return "energy plus flexible";
    case PLAN_TYPE_PLUS:
      return "energy plus";
    case PLAN_TYPE_EV_PLUS:
      return "energy ev";
    default:
      return "";
  }
};

function getNextPageUrlByPlanType(planType) {
  if (planType === PLAN_TYPE_EV_PLUS) {
    return appResidentialRoutes.carRegistration;
  }

  return appResidentialRoutes.contactDetails;
}

const plusPlanCodes = [PLAN_TYPE_PLUS_FIXED, PLAN_TYPE_PLUS_FLEXI];

function getPlanTypeToSubmit(planType) {
  if (plusPlanCodes.includes(planType)) {
    return PLAN_TYPE_PLUS;
  }

  return planType;
}

function getPlanTermToSubmit(planType) {
  if (planType === PLAN_TYPE_PLUS_FLEXI) {
    return { term: RESIDENTIAL_PLAN_TERM_FLEXI, eventName: "flexible" };
  }

  return { term: RESIDENTIAL_PLAN_TERM_FIXED12, eventName: "12 month fixed" };
}

const stateSelector = createSelector(
  [selectAppState, selectResidentialOffers, selectResidentialPlanConfig],
  (appState, residentialOffers, residentialPlanConfig) => {
    const { termData } = residentialPlanConfig;
    const { planType: planTypeFromStore, planUsageType, products } = appState;
    const { hasEvPlan } = residentialOffers.electricityOffer;

    const { codeName: selectedTermCodeName } =
      termData.find(({ isSelected }) => isSelected) ?? {};

    const planType = getPlanTypeByTerm({
      selectedTermCodeName,
      planType: planTypeFromStore,
    });

    const flexiPlanJoiningDiscount = getTotalDiscountPercentage({
      products,
      planType: PLAN_TYPE_PLUS_FLEXI,
    });

    const fixedPlanJoiningDiscount = getTotalDiscountPercentage({
      products,
      planType: PLAN_TYPE_PLUS_FIXED,
    });

    const evPlanJoiningDiscount = getTotalDiscountPercentage({
      products,
      planType: PLAN_TYPE_EV_PLUS,
    });

    const plusPlanJoiningCredit = getTotalCredit({
      products,
      plan: PLAN_TYPE_PLUS,
      term: RESIDENTIAL_PLAN_TERM_FIXED12,
    });

    const evPlusPlanJoiningCredit = getTotalCredit({
      products,
      plan: PLAN_TYPE_EV_PLUS,
      term: RESIDENTIAL_PLAN_TERM_FIXED12,
    });

    const dualFuelDiscount = getDualFuelDiscount(products);

    const isEvPlanUnavailable = !hasEvPlan;

    const isDualFuel = isDualFuelDiscountApplicable(products);

    const credits = {
      [PLAN_TYPE_PLUS_FIXED]: plusPlanJoiningCredit
        ? `${plusPlanJoiningCredit} on 12-month signup`
        : null,
      [PLAN_TYPE_EV_PLUS]: evPlusPlanJoiningCredit
        ? `${evPlusPlanJoiningCredit} on 12-month signup`
        : null,
    };

    return {
      planType,
      planUsageType,
      flexiPlanJoiningDiscount,
      fixedPlanJoiningDiscount,
      evPlanJoiningDiscount,
      plusPlanJoiningCredit,
      evPlusPlanJoiningCredit,
      dualFuelDiscount,
      isDualFuel,
      isEvPlanUnavailable,
      credits,
    };
  },
);

function PlanSelectPage(props) {
  const {
    planType: planTypeFromStore,
    planUsageType: planUsageTypeFromStore,
    isEvPlanUnavailable,
    credits,
    isDualFuel,
  } = useSelector(stateSelector);

  const transformedData = transformPlanData({
    joinPlanSelectPageData: props.globalData.joinPlanSelectPageData,
    isDualFuel,
    credits,
  });

  const introHeaderData = getPageData({
    modules: props?.modules,
    page: props?.page,
  });

  const dispatch = useDispatch();

  const router = useRouter();

  const [inclGst, toggleInclGst] = useState(true);
  const [planUsageType, setPlanUsageType] = useState(planUsageTypeFromStore);
  const [planType, setPlanType] = useState(planTypeFromStore);
  const [errorVisible, setErrorVisibility] = useState(false);
  const [selectedPlanTab, setSelectedPlanTab] = useState(PLAN_TYPE_PLUS_FLEXI);

  const onUsageChange = (e) => {
    setPlanUsageType(e.target.value);
  };

  const togglePlanSelection = (planTypeToToggle) => {
    const updatedPlanType =
      planType !== planTypeToToggle ? planTypeToToggle : null;

    setPlanType(updatedPlanType);
    setErrorVisibility(false);
  };

  const onContinueClick = (e) => {
    if (!validPlanTypes.includes(planType)) {
      setErrorVisibility(true);
      return;
    }

    const nextPageUrl = getNextPageUrlByPlanType(planType);

    const planTypeToSubmit = getPlanTypeToSubmit(planType);

    dispatch(
      submitPlanSelectForm({
        planType: planTypeToSubmit,
        planUsageType,
      }),
    );

    if (planType !== PLAN_TYPE_EV_PLUS) {
      dispatch(setIsExistingCustomer("false"));
    }

    trackResidentialContinueButton({
      event: "select_plan",
      content_tertiary: "select plan",
      selection: getPlanNamesMapForGA(planType),
    });

    const { term, eventName } = getPlanTermToSubmit(planType);

    dispatch(setPlanTerm(term));
    trackResidentialContinueButton(
      {
        event: "select_term",
        content_tertiary: "select plan",
        selection: eventName,
      },
      "automated",
    );

    router.push({ pathname: nextPageUrl });
  };

  const handleTabClick = (name) => {
    setSelectedPlanTab(name);
    const selectedPlanContent = document.getElementById(name);
    selectedPlanContent.scrollIntoView({
      behavior: "smooth",
      inline: "center",
      block: "nearest",
    });
  };

  return (
    <motion.div data-testid="planSelectPage" className={styles.container}>
      <Intro>
        {() => (
          <motion.div
            initial="initial"
            animate="animate"
            exit="exit"
            variants={headerFade}
            className="flex flex-col max-w-full px-4 gap-y-3 md:px-16"
          >
            {introHeaderData?.fields?.title && (
              <h1
                className={classNames(
                  "text-40/48 font-black text-dark-500",
                  "md:text-dark-primary md:text-40/48 lg:text-60/68",
                )}
              >
                {introHeaderData.fields.title}
              </h1>
            )}
            {introHeaderData?.fields?.blockEditor && (
              <InlineBlockEditor
                jsonString={introHeaderData.fields.blockEditor}
                className="prose-p:text-16/24 prose-p:md:text-20/32 prose-a:text-black-primary"
              />
            )}
          </motion.div>
        )}
      </Intro>

      <EvPlanAlert />

      <div
        className={classNames(
          "relative flex pb-px mb-10 border-b border-b-black-100",
          "md:hidden",
        )}
      >
        <div className="-mb-px">
          {transformedData.map((planData, index) => {
            return (
              <PlanSelectTab
                key={index}
                isSelected={selectedPlanTab === planData.planType}
                onClick={() => handleTabClick(planData.planType)}
              >
                {planData.planName} plan
              </PlanSelectTab>
            );
          })}
        </div>
      </div>

      <motion.div
        initial="initial"
        animate="animate"
        exit="exit"
        variants={itemContainer}
        className={classNames(
          "-mx-4 md:mx-auto",
          "overflow-x-auto smooth-scroll no-scrollbar",
          "md:mb-10",
        )}
      >
        <div
          className={classNames(
            "inline-grid grid-cols-plan-cards auto-rows-min gap-x-2 gap-y-5",
            "px-4 md:px-16 min-w-220 md:min-w-236",
            "mx-auto",
          )}
        >
          {transformedData.map((planData, index) => {
            const isUnavailable =
              planData.planType === PLAN_TYPE_EV_PLUS
                ? isEvPlanUnavailable
                : false;
            return (
              <PlanCard key={index} testid={planData.planType}>
                <PlanCardHeader
                  data={{
                    title: planData.planName,
                    description: planData.description,
                    iconPath: planData.iconUrl,
                    badgeText: planData.badgeText,
                  }}
                />

                <PlanCardFeatures
                  id={planData.planType}
                  data={{
                    features: planData.features,
                  }}
                />

                <PlanCardRates
                  testIdPrefix={planData.planType}
                  planType={planData.ratesType}
                  radioButtonName={`${planData.planType.toLowerCase()}-radio`}
                  inclGst={inclGst}
                  planUsageType={planUsageType}
                  onUsageChange={onUsageChange}
                  onGstChange={() => toggleInclGst(!inclGst)}
                  showDayNightRatesInfo={
                    planData.ratesType === PLAN_TYPE_EV_PLUS
                  }
                />

                <div className="pt-1 px-7">
                  <PlanSelectButton
                    name={planData.planType}
                    onClick={() => togglePlanSelection(planData.planType)}
                    isSelected={planType === planData.planType}
                    buttonText={`Select ${planData.planName} plan`}
                    isUnavailable={isUnavailable}
                  />
                </div>

                <div className="pb-1 prose md:prose-terms-md px-7 text-13/20 text-black-primary">
                  {planData.planTerms ? (
                    <p>
                      To join Energy EV you must have an eligible PHEV or BEV.
                    </p>
                  ) : null}
                  {planData.terms?.length
                    ? planData.terms.map((term, termIndex) => (
                        <p key={termIndex}>{term}</p>
                      ))
                    : null}
                  {planData.termsLink ? (
                    <p>
                      <a
                        href={planData.termsLink?.href}
                        target={planData.termsLink?.target ?? "_blank"}
                        rel="noreferrer"
                      >
                        {planData.termsLink?.text}
                      </a>
                    </p>
                  ) : null}
                </div>

                <div
                  className={classNames(
                    "absolute inset-0",
                    "border-2 border-transparent",
                    "pointer-events-none rounded-10",
                    "transition",
                    "group-hover:border-warm-orange-primary",
                    {
                      "border-warm-orange-primary":
                        planType === planData.planType,
                    },
                  )}
                />
              </PlanCard>
            );
          })}
        </div>
      </motion.div>

      {/* <motion.div
        initial="initial"
        animate="animate"
        exit="exit"
        variants={itemContainer}
        className={classNames(
          "-mx-4 md:mx-auto",
          "overflow-x-auto smooth-scroll no-scrollbar",
          "md:mb-10",
        )}
      >
        <div
          className={classNames(
            "inline-grid grid-cols-plan-cards auto-rows-min gap-x-2 gap-y-5",
            "px-4 md:px-16 min-w-220 md:min-w-236",
            "mx-auto",
          )}
        >
          <PlanCard testid="flexiPlanCard">
            <PlanCardHeader
              data={{
                title: "Flexi",
                description: "Leave anytime, no exit-fee",
                iconPath: "/icons/individual-icons/plan-card-elec-icon.png",
              }}
            />

            <PlanCardFeatures
              id={PLAN_TYPE_PLUS_FLEXI}
              data={{
                features: [
                  {
                    title: "Leave anytime, no exit-fee",
                  },
                  {
                    title: `Up to ${flexiPlanJoiningDiscount}% in discounts`,
                    tooltip: {
                      title: `Up to ${flexiPlanJoiningDiscount}% in discounts`,
                      description: "Eligible discounts on this plan:",
                      list: [
                        dualFuelDiscount > 0
                          ? `${dualFuelDiscount}% for having 2 fuels with us`
                          : null,
                        "1% for receiving your bills by email",
                        "2% for Auto-pay, Direct Debit",
                        "1% for Auto-pay, Credit Card",
                      ],
                    },
                  },
                  {
                    title: "Free Power Shout hours",
                    tooltip: {
                      title: "Free Power Shout hours",
                      description:
                        "Free power from time to time to schedule and use when you like.",
                    },
                  },
                ],
              }}
            />

            <PlanCardRates
              testIdPrefix="flexiPlan"
              planType={PLAN_TYPE_PLUS}
              radioButtonName="flexiPlanRadio"
              inclGst={inclGst}
              planUsageType={planUsageType}
              onUsageChange={onUsageChange}
              onGstChange={() => toggleInclGst(!inclGst)}
            />

            <div className="pt-1 px-7">
              <PlanSelectButton
                name="flexiPlan"
                onClick={() => togglePlanSelection(PLAN_TYPE_PLUS_FLEXI)}
                isSelected={planType === PLAN_TYPE_PLUS_FLEXI}
                buttonText="Select Flexi plan"
              />
            </div>

            <div className="pb-1 prose md:prose-terms-md px-7 text-13/20 text-black-primary">
              <p>
                <a
                  href={links.plusTerms}
                  className="visited:text-orange-primary"
                  target="_blank"
                  rel="noreferrer"
                >
                  Energy Plus plan terms apply
                </a>
              </p>
            </div>

            <div
              className={classNames(
                "absolute inset-0",
                "border-2 border-transparent",
                "pointer-events-none rounded-10",
                "transition",
                "group-hover:border-warm-orange-primary",
                {
                  "border-warm-orange-primary":
                    planType === PLAN_TYPE_PLUS_FLEXI,
                },
              )}
            />
          </PlanCard>

          <PlanCard testid="fixedPlanCard">
            <PlanCardHeader
              data={{
                title: "Fixed",
                description: "Go 12 months fixed for a 3% discount*",
                badgeText: "Most popular",
                iconPath: "/icons/individual-icons/plan-card-elec-icon.png",
              }}
            />

            <PlanCardFeatures
              id={PLAN_TYPE_PLUS_FIXED}
              data={{
                features: compact([
                  {
                    title: `Up to ${fixedPlanJoiningDiscount}% in discounts*`,
                    tooltip: {
                      title: `Up to ${fixedPlanJoiningDiscount}% in discounts`,
                      description: "Eligible discounts on this plan:",
                      list: [
                        dualFuelDiscount > 0
                          ? `${dualFuelDiscount}% for having 2 fuels with us`
                          : null,
                        "3% for going fixed term",
                        "1% for receiving your bills by email",
                        "2% for Auto-pay, Direct Debit",
                        "1% for Auto-pay, Credit Card",
                      ],
                    },
                  },
                  plusPlanJoiningCredit
                    ? {
                        title: `${plusPlanJoiningCredit} on 12-month signup*`,
                      }
                    : null,
                  {
                    title: "Free Power Shout hours",
                    tooltip: {
                      title: "Free Power Shout hours",
                      description:
                        "Free power from time to time to schedule and use when you like.",
                    },
                  },
                ]),
              }}
            />

            <PlanCardRates
              testIdPrefix="fixedPlan"
              planType={PLAN_TYPE_PLUS}
              radioButtonName="fixedPlanRadio"
              inclGst={inclGst}
              planUsageType={planUsageType}
              onUsageChange={onUsageChange}
              onGstChange={() => toggleInclGst(!inclGst)}
            />

            <div className="pt-1 px-7">
              <PlanSelectButton
                name="fixedPlan"
                onClick={() => togglePlanSelection(PLAN_TYPE_PLUS_FIXED)}
                isSelected={planType === PLAN_TYPE_PLUS_FIXED}
                buttonText="Select Fixed plan"
              />
            </div>

            <div className="pb-1 prose prose-terms md:prose-terms-md px-7 text-black-primary">
              <p>
                *Includes a 3% discount for fixing your term for 12 months. A
                $150 exit fee (per fuel type) applies to fixed-term plans.
              </p>
              <p>
                <a
                  href={links.plusTerms}
                  className="visited:text-orange-primary"
                  target="_blank"
                  rel="noreferrer"
                >
                  Energy Plus plan terms apply
                </a>
              </p>
            </div>

            <div
              className={classNames(
                "absolute inset-0",
                "border-2 border-transparent",
                "pointer-events-none rounded-10",
                "transition",
                "group-hover:border-warm-orange-primary",
                {
                  "border-warm-orange-primary":
                    planType === PLAN_TYPE_PLUS_FIXED,
                },
              )}
            />
          </PlanCard>

          <PlanCard testid="evPlusPlanCard">
            <PlanCardHeader
              data={{
                title: "Energy EV",
                description: "50% cheaper electricity between 9pm-7am*",
                iconPath: "/icons/individual-icons/plan-card-ev-icon.png",
              }}
            />
            <PlanCardFeatures
              id={PLAN_TYPE_EV_PLUS}
              data={{
                features: compact([
                  { title: "50% night rates*" },
                  {
                    title: "EVerywhere",
                    tooltip: {
                      title: "EVerywhere",
                      description:
                        "With this Energy EV add-on you can take your day and night energy rates on the road to any eligible ChargeNet fast charging station across Aotearoa. T&Cs apply.",
                    },
                  },
                  {
                    title: `Up to ${evPlanJoiningDiscount}% in discounts**`,
                    tooltip: {
                      title: `Up to ${evPlanJoiningDiscount}% in discounts`,
                      description: "Eligible discounts on this plan:",
                      list: [
                        dualFuelDiscount > 0
                          ? `${dualFuelDiscount}% for having 2 fuels with us`
                          : null,
                        "3% for going fixed term",
                        "1% for receiving your bills by email",
                        "2% for Auto-pay, Direct Debit",
                        "1% for Auto-pay, Credit Card",
                      ],
                    },
                  },
                  evPlusPlanJoiningCredit
                    ? {
                        title: `${evPlusPlanJoiningCredit} on 12-month signup**`,
                      }
                    : null,
                  {
                    title: "Free Power Shout hours",
                    tooltip: {
                      title: "Free Power Shout hours",
                      description:
                        "Free power from time to time to schedule and use when you like.",
                    },
                  },
                ]),
              }}
            />

            <PlanCardRates
              testIdPrefix="energyEvPlan"
              planType={PLAN_TYPE_EV_PLUS}
              radioButtonName="energyEvPlanRadio"
              inclGst={inclGst}
              planUsageType={planUsageType}
              onUsageChange={onUsageChange}
              onGstChange={() => toggleInclGst(!inclGst)}
              showDayNightRatesInfo
            />

            <div className="pt-1 px-7">
              <PlanSelectButton
                name="energyEvPlan"
                onClick={() => togglePlanSelection(PLAN_TYPE_EV_PLUS)}
                isSelected={planType === PLAN_TYPE_EV_PLUS}
                buttonText="Select EV plan"
                isUnavailable={isEvPlanUnavailable}
              />
            </div>

            <div className="pb-1 prose prose-terms md:prose-terms-md px-7 text-black-primary">
              <p>To join Energy EV you must have an eligible PHEV or BEV.</p>
              <p>
                *50% lower night rate applies to variable portion of your bill
                only.
              </p>
              <p>
                **Includes a 3% discount for fixing your term for 12 months. A
                $150 exit fee (per fuel type) applies to fixed-term plans.
              </p>
              <p>
                <a
                  href={links.evPlusTerms}
                  className="visited:text-orange-primary"
                  target="_blank"
                  rel="noreferrer"
                >
                  Energy EV plan terms apply
                </a>
              </p>
            </div>

            <div
              className={classNames(
                "absolute inset-0",
                "border-2 border-transparent",
                "pointer-events-none rounded-10",
                "transition",
                "group-hover:border-warm-orange-primary",
                {
                  "border-warm-orange-primary": planType === PLAN_TYPE_EV_PLUS,
                },
              )}
            />
          </PlanCard>
        </div>
      </motion.div> */}

      <AnimatePresence exitBeforeEnter={false}>
        <div key="disclaimer">
          <RatesDisclaimer />
        </div>
      </AnimatePresence>

      <AnimatePresence>
        <motion.div
          initial="hidden"
          whileInView="visible"
          variants={fadeInBottomAnimation}
          className={planType !== null ? "sticky bottom-5" : ""}
        >
          <div className="px-5 md:px-21">
            <div className="mt-10 overflow-hidden">
              <AnimatePresence>
                {errorVisible && (
                  <motion.div
                    initial="initial"
                    animate="animate"
                    exit="exit"
                    variants={heightFadeVariants}
                    transition={heightFadeTransition}
                    className="mb-8 md:px-16 md:mb-10"
                  >
                    <Feedback
                      data-testid="errorMessage"
                      type="error"
                      title="Error"
                    >
                      Please select a plan
                    </Feedback>
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
            <StepProgressionButton
              onContinueClick={onContinueClick}
              backLink={appResidentialRoutes.fuel}
              disabled={planType === null}
              testId="continueButton"
              className="!pb-0"
              buttonContainerClassName={planType !== null ? "shadow-200" : ""}
            />
          </div>
        </motion.div>
      </AnimatePresence>
      <div className="pb-8 md:pb-18" />
    </motion.div>
  );
}

export default PlanSelectPage;
