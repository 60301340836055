import * as Yup from "yup";
import { InlineBlockEditor } from "components/BlockEditor";
import { Input } from "components/JoinFormControls";
import { getBlocks } from "helpers/blockEditorHelper";
import { BUSINESS_TYPE_COMPANY } from "helpers/constants";

const emptyStringToNull = (value, originalValue) => {
  if (typeof originalValue === "string" && originalValue === "") {
    return null;
  }
  return value;
};

function NZBNInputLengthCheck(value) {
  if (value === null) {
    return true;
  }
  const { originalValue } = this.options;
  if (typeof originalValue === "undefined") {
    return true;
  }
  const lengthMatch = originalValue.toString().length === 13;
  return lengthMatch;
}

function NZBNInputMinValueCheck(value) {
  if (value === null) {
    return true;
  }

  const { originalValue } = this.options;
  if (typeof originalValue === "undefined") {
    return true;
  }

  const result = originalValue.toString() !== "0000000000000";

  return result;
}

function NZBNInputMaxValueCheck(value) {
  if (value === null) {
    return true;
  }

  const { originalValue } = this.options;
  if (typeof originalValue === "undefined") {
    return true;
  }

  const result = originalValue.toString() !== "9999999999999";

  return result;
}

export const companyFormValidation = Yup.object().shape({
  companyTradingAs: Yup.string().when("businessType", {
    is: (value) => value === BUSINESS_TYPE_COMPANY,
    then: Yup.string()
      .max(255, "Company name cannot be more than 255 characters")
      .required("This field is required"),
  }),
  companyNZBN: Yup.number()
    .nullable()
    .optional()
    .when("businessType", {
      is: (value) => value === BUSINESS_TYPE_COMPANY,
      then: Yup.number()
        .min(0, "Please enter a valid NZBN")
        .transform(emptyStringToNull)
        .nullable()
        .optional()
        .typeError("Please enter a valid NZBN")
        .integer("Please enter a valid NZBN")
        .test("minValue", "Please enter a valid NZBN", NZBNInputMinValueCheck)
        .test("len", "Please enter a valid NZBN", NZBNInputLengthCheck)
        .test("maxValue", "Please enter a valid NZBN", NZBNInputMaxValueCheck),
    }),
});

export default function CompanyForm({ props, content }) {
  const { values, touched, errors, handleChange } = props;
  const blocks = getBlocks(content);

  const isNZBNValueEmpty =
    values.companyNZBN === null || values.companyNZBN === "";
  const NZBNValue = isNZBNValueEmpty ? "" : values.companyNZBN;

  return (
    <>
      {blocks.length ? (
        <InlineBlockEditor
          data={blocks}
          className="prose-p:text-15/24 prose-p:md:text-18/28 prose-a:text-black-primary mb-5"
        />
      ) : null}
      <Input
        size="28"
        name="companyTradingAs"
        placeholder="Trading as"
        value={values.companyTradingAs}
        onChange={handleChange}
        errorMessage={errors.companyTradingAs}
        showError={errors.companyTradingAs && touched.companyTradingAs}
      />
      <Input
        size="28"
        name="companyNZBN"
        placeholder="NZBN (optional)"
        value={NZBNValue}
        onChange={handleChange}
        errorMessage={errors.companyNZBN}
        showError={errors.companyNZBN && touched.companyNZBN}
      />
    </>
  );
}
