import Axios from "axios";
import { format, parse } from "date-fns";
import {
  ADDRESS_TYPE_REGISTRY,
  BUSINESS_TYPE_COMPANY,
  PAYMENT_METHOD_DIRECT_DEBIT,
  PREVENT_ACCESS_REASON_DOGS,
  PREVENT_ACCESS_REASON_GATE,
  PREVENT_ACCESS_REASON_METER_INSIDE,
  PREVENT_ACCESS_REASON_OTHER,
  PREVENT_ACCESS_REASON_SECURITY_CODE,
  PREVENT_ACCESS_REASON_STEEP_DRIVEWAY,
  PREVENT_ACCESS_REASON_STEPS,
  PREVENT_ACCESS_REASON_TRUCKS,
  SUPPLY_POINT_TYPE_ELECTRICITY,
  SUPPLY_POINT_TYPE_GAS,
  SUPPLY_POINT_TYPE_LPG,
} from "helpers/constants";
import {
  createBillingAddress,
  createSiteAddress,
} from "helpers/addressHelper";
import {
  getBusinessName,
  getBusinessTypeNameForSignup,
} from "helpers/businessDetailsHelper";
import { getDualFuelDiscount } from "helpers/joiningDiscountsHelper";
import {
  createOfferForSignup,
  filterApplicableDiscounts,
  filterBusinessOffers,
} from "helpers/offersHelper";
import {
  isBottledGasSelected,
  isElectricitySelected,
  isNaturalGasSelected,
} from "helpers/productHelper";
import { getSmeSignupRequestHeaders } from "join-form/services/npaAuth";
import { compact, toString } from "lodash-es";

export async function createSmeSignup(storeState) {
  const requestObject = createSmeRequestObject(storeState);

  try {
    const requestConfig = await getRequestConfig();
    const url =
      process.env.NEXT_PUBLIC_MULE_API_BASE_URL +
      "/servicerequests/onlinesignupsme";
    const { data } = await Axios.post(url, requestObject, requestConfig);

    return {
      data,
      requestObject,
      transactionId: requestConfig.headers["X-Transaction-ID"],
    };
  } catch (ex) {
    return Promise.reject(ex);
  }
}

async function getRequestConfig() {
  const headers = await getSmeSignupRequestHeaders();
  const requestConfig = {
    headers,
  };
  return requestConfig;
}

export function createSmeRequestObject(storeState) {
  const {
    appPropertyState: { moveInDate, situation },
    appUserState: { fixedPaymentDay },
  } = storeState;
  const contact = createContact(storeState);
  const business = createBusiness(storeState);
  const site = createSite(storeState);
  const billingAccount = createBillingAccount(storeState);
  const requestedDate = createRequestDate({ moveInDate });

  return {
    contact,
    business,
    site: [site],
    billingAccount,
    moveOrTransfer: situation,
    requestedDate,
    preferredBillDate: parseInt(fixedPaymentDay) || null,
  };
}

function createContact(storeState) {
  const {
    appUserState: {
      title,
      firstName,
      lastName,
      dob = "",
      phone,
      email,
      licenceNo,
      licenceVersionNo,
    },
  } = storeState;

  const dobAsSafeString = toString(dob);
  const dobFormatted = formatDateOfBirth(dobAsSafeString);

  return {
    title,
    firstName,
    lastName,
    dob: dobFormatted,
    phone,
    email,
    driverLicence: {
      number: licenceNo,
      version: licenceVersionNo,
    },
  };
}

function createBusiness(storeState) {
  const {
    businessState: {
      businessType,
      soleTraderTradingAs,
      companyTradingAs,
      otherTradingAs,
      companyNZBN,
    },
  } = storeState;

  const type = getBusinessTypeNameForSignup(businessType);
  const name = getBusinessName({
    businessType,
    soleTraderTradingAs,
    companyTradingAs,
    otherTradingAs,
  });

  const safeNZBN = companyNZBN === null ? "" : companyNZBN;

  const registrationNumber =
    businessType === BUSINESS_TYPE_COMPANY ? safeNZBN : "";

  return {
    type,
    name,
    registrationNumber,
  };
}

function createSite(storeState) {
  const {
    addressState: { addressDetails, addressType },
    icp: { elecRegistryAddress, gasRegistryAddress },
  } = storeState;

  const address = createSiteAddress({
    addressFromFinder: addressDetails,
    registryAddress: elecRegistryAddress || gasRegistryAddress,
    isFromAddressIcpPage: addressType === ADDRESS_TYPE_REGISTRY,
  });

  const access = createSiteAccess(storeState);

  const supplyPointElectricity = createSupplyPointElectricity(storeState);

  const supplyPointGas = createSupplyPointGas(storeState);

  const supplyPointBottledGas = createSupplyPointBottledGas(storeState);

  const supplyPoint = compact([
    supplyPointElectricity,
    supplyPointGas,
    supplyPointBottledGas,
  ]);

  return {
    address,
    access,
    supplyPoint,
  };
}

function createSiteAccess(storeState) {
  const {
    appPropertyState: {
      meterAccessHasAnIssue,
      meterAccessIssueReasons,
      meterAccessIssueReasonOther,
    },
  } = storeState;

  const limited = meterAccessHasAnIssue === "true";

  if (limited === false) {
    return {
      limited,
      dog: "NO_DOGS",
      trucks: false,
      securityCode: false,
      steps: false,
      meterInside: false,
      meterReaderNotes: "",
    };
  }

  const dog = meterAccessIssueReasons.includes(PREVENT_ACCESS_REASON_DOGS)
    ? "DOGS"
    : "NO_DOGS";
  const trucks = meterAccessIssueReasons.includes(PREVENT_ACCESS_REASON_TRUCKS);
  const securityCode = meterAccessIssueReasons.includes(
    PREVENT_ACCESS_REASON_SECURITY_CODE,
  );
  const steps = meterAccessIssueReasons.includes(PREVENT_ACCESS_REASON_STEPS);
  const meterInside = meterAccessIssueReasons.includes(
    PREVENT_ACCESS_REASON_METER_INSIDE,
  );
  const steepDriveway = meterAccessIssueReasons.includes(
    PREVENT_ACCESS_REASON_STEEP_DRIVEWAY,
  );
  const gate = meterAccessIssueReasons.includes(PREVENT_ACCESS_REASON_GATE);
  const otherAccessIssues = meterAccessIssueReasons.includes(
    PREVENT_ACCESS_REASON_OTHER,
  );

  const otherAccessIssuesMessage = otherAccessIssues
    ? meterAccessIssueReasonOther
    : "";
  const steepDrivewayMessage = steepDriveway ? "Steep Driveway" : "";
  const gateMessage = gate ? "Gate" : "";

  const meterReaderNotes = compact([
    otherAccessIssuesMessage,
    steepDrivewayMessage,
    gateMessage,
  ]).join(", ");

  return {
    limited,
    dog,
    trucks,
    securityCode,
    steps,
    meterInside,
    meterReaderNotes,
  };
}

function createSupplyPointElectricity(storeState) {
  const {
    appState: { products },
    appUserState: { paymentMethod },
    businessPlanConfig: { termData },
    businessOffers: { electricityOffers },
    icp: { elecIcp },
  } = storeState;

  const isFuelTypeSelected = isElectricitySelected(products);
  const isMultiConnect = getDualFuelDiscount(products) > 0;
  const isDirectDebit = paymentMethod === PAYMENT_METHOD_DIRECT_DEBIT;

  if (isFuelTypeSelected === false) {
    return null;
  }

  const termItem = termData.find(({ isSelected }) => isSelected) || {
    termPeriod: "",
  };

  const offers = filterBusinessOffers(electricityOffers, termItem.termPeriod);

  if (offers.length === 1) {
    const signupOffer = createOfferForSignup(offers[0]);
    const signupOfferWithFilteredTariff = {
      ...signupOffer,
      priceBook: {
        ...signupOffer.priceBook,
        tariffList: signupOffer.priceBook.tariffList.filter(
          (tariffItem) =>
            !tariffItem.description.toLowerCase().includes("unmetered"),
        ),
      },
    };

    const offerWithApplicableDiscounts = filterApplicableDiscounts(
      signupOfferWithFilteredTariff,
      isMultiConnect,
      isDirectDebit,
      termItem.termPeriod,
    );

    return {
      type: SUPPLY_POINT_TYPE_ELECTRICITY,
      icp: elecIcp,
      selectedOffer: offerWithApplicableDiscounts,
    };
  }

  return null;
}

function createSupplyPointGas(storeState) {
  const {
    appState: { products },
    appUserState: { paymentMethod },
    businessPlanConfig: { termData },
    businessOffers: { naturalGasOffers },
    icp: { gasIcp },
  } = storeState;
  const isFuelTypeSelected = isNaturalGasSelected(products);
  const isMultiConnect = getDualFuelDiscount(products) > 0;
  const isDirectDebit = paymentMethod === PAYMENT_METHOD_DIRECT_DEBIT;

  if (isFuelTypeSelected === false) {
    return null;
  }

  const termItem = termData.find(({ isSelected }) => isSelected) || {
    termPeriod: "",
  };

  const offers = filterBusinessOffers(naturalGasOffers, termItem.termPeriod);

  if (offers.length === 1) {
    const signupOffer = createOfferForSignup(offers[0]);
    const offerWithApplicableDiscounts = filterApplicableDiscounts(
      signupOffer,
      isMultiConnect,
      isDirectDebit,
      termItem.termPeriod,
    );

    return {
      type: SUPPLY_POINT_TYPE_GAS,
      icp: gasIcp,
      selectedOffer: offerWithApplicableDiscounts,
    };
  }

  return null;
}

function createSupplyPointBottledGas(storeState) {
  const {
    appState: { products },
    appUserState: { paymentMethod },
    appPropertyState: {
      gasBottlesCount,
      gasBottlesSupplier,
      orderBottlesOption,
      gasBottlesLocation,
    },
    businessPlanConfig: { termData },
    businessOffers: { bottledGasOffers },
  } = storeState;
  const isBottledGasProductSelected = isBottledGasSelected(products);
  const isMultiConnect = getDualFuelDiscount(products) > 0;
  const isDirectDebit = paymentMethod === PAYMENT_METHOD_DIRECT_DEBIT;

  if (isBottledGasProductSelected === false) {
    return null;
  }

  const termItem = termData.find(({ isSelected }) => isSelected) || {
    termPeriod: "",
  };

  const offers = filterBusinessOffers(bottledGasOffers, termItem.termPeriod);

  if (offers.length === 1) {
    const signupOffer = createOfferForSignup(offers[0]);

    const offerWithApplicableDiscounts = filterApplicableDiscounts(
      signupOffer,
      isMultiConnect,
      isDirectDebit,
      termItem.termPeriod,
    );

    const lpgSupplyPoint = {
      type: SUPPLY_POINT_TYPE_LPG,
      numberOfLPGBottles: Number(gasBottlesCount),
      retailer: gasBottlesSupplier,
      meterLocationCode: gasBottlesLocation,
      selectedOffer: offerWithApplicableDiscounts,
    };

    if (orderBottlesOption === "true") {
      lpgSupplyPoint.lpgOrder = {
        numberOfBottles: 2,
      };
    }
    return lpgSupplyPoint;
  }
  return null;
}

function createBillingAccount(storeState) {
  const {
    appUserState: { email, isEBillingPreference, isPromoByEmail },
    addressState: { addressDetails, addressType },
    icp: { elecRegistryAddress, gasRegistryAddress },
  } = storeState;

  const paymentMethod = createPaymentMethod(storeState);

  const billingAddress = createBillingAddress({
    addressFromFinder: addressDetails,
    registryAddress: elecRegistryAddress || gasRegistryAddress,
    isFromAddressIcpPage: addressType === ADDRESS_TYPE_REGISTRY,
  });

  const billingPreference = isEBillingPreference ? "EMAIL" : "POST";
  const billingPreferenceValue = isEBillingPreference ? email : null;

  const promotionPreference = isPromoByEmail === "true" ? "EMAIL" : "NONE";
  const promotionPreferenceValue = isPromoByEmail === "true" ? email : "";

  const billingAccount = {
    paymentMethod,
    billing: {
      address: billingAddress,
      preference: billingPreference,
      value: billingPreferenceValue,
    },
    promotion: {
      preference: promotionPreference,
      value: promotionPreferenceValue,
    },
  };

  return billingAccount;
}

function createPaymentMethod(storeState) {
  const {
    appUserState: { paymentMethod },
    paymentState: {
      bankAccountNo,
      bankAccountName,
      generalTerms,
      debitTermsConditionsKeys,
      ...restPaymentState
    },
  } = storeState;

  if (paymentMethod === PAYMENT_METHOD_DIRECT_DEBIT) {

    const debitTerms = Object.entries(restPaymentState)
      .filter(([key]) => debitTermsConditionsKeys.includes(key))
      .map(([key, value]) => ({ key, value }));
    const termsAccepted = debitTerms.every(term => term.value === true);

    return {
      paymentAuthority: "DIRECT_DEBIT",
      bankAcctNumber: bankAccountNo,
      bankAcctName: bankAccountName,
      authorised: termsAccepted,
      termsAccepted: termsAccepted,
      Confirmation: generalTerms,
    };
  }
  return {
    paymentAuthority: "NONE",
    bankAcctNumber: "",
    bankAcctName: "",
    authorised: false,
    termsAccepted: false,
    Confirmation: generalTerms,
  };
}

function createRequestDate({ moveInDate }) {
  const [formattedDate, error] = formatDate(moveInDate);

  if (error) {
    const now = new Date(Date.now());
    return format(now, "yyyy-MM-dd");
  }

  return formattedDate;
}

function formatDateOfBirth(dateString) {
  const [formattedDate] = formatDate(dateString);
  const result = formattedDate || "";
  return result;
}

function formatDate(dateString) {
  const now = new Date(Date.now());
  try {
    const parsedDate = parse(dateString, "dd/MM/yyyy", now);
    const formattedDate = format(parsedDate, "yyyy-MM-dd");
    return [formattedDate, null];
  } catch (ex) {
    return [null, ex];
  }
}
