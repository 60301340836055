import { runAsync } from "helpers";

async function getJoinFooterProps(context) {
  const { agility, languageCode } = context;

  const [contentItem, getContentItemError] = await runAsync(() =>
    agility.getContentItem({
      contentID: 8935,
      languageCode,
      expandAllContentLinks: true,
      contentLinkDepth: 2,
    }),
  );

  if (getContentItemError) {
    return null;
  }

  const primaryLinks = contentItem.fields.primaryLinks.map(
    (item) => item.fields.link,
  );

  return { ...contentItem.fields, primaryLinks };
}

export { getJoinFooterProps };
