import { motion } from "framer-motion";

const transition = {
  delay: 0.3,
  type: "spring",
  bounce: 0.3,
  duration: 0.3,
};

function FadeInBottomAnimation({ children, className }) {
  return (
    <motion.div
      initial={{ y: 20, opacity: 0 }}
      whileInView={{ y: 0, opacity: 1 }}
      viewport={{ once: true, amount: 0.2 }}
      transition={transition}
      className={className}
    >
      {children}
    </motion.div>
  );
}

function ScaleAnimation({ children, className }) {
  return (
    <motion.div
      initial={{ scale: 0.5, opacity: 0 }}
      animate={{ scale: 1, opacity: 1 }}
      viewport={{ once: true, amount: 0.2 }}
      transition={transition}
      className={className}
    >
      {children}
    </motion.div>
  );
}

function FadeInLeftTextAnimation({ children, className }) {
  return (
    <motion.div
      initial={{ x: 40, opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      viewport={{ once: true, amount: 0.2 }}
      transition={transition}
      className={className}
    >
      {children}
    </motion.div>
  );
}

export { FadeInBottomAnimation, ScaleAnimation, FadeInLeftTextAnimation };
