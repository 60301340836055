import Icon from "join-form/components/icon";
import styles from "./rates-disclaimer.module.scss";

export default function RatesDisclaimer(props) {
  return (
    <div className={styles.footer}>
      <div className={styles.disclaimer}>
        <p>
          All prices exclude any discounts. The rates displayed are based on
          Registry information for the address you have provided. Your rates
          will be confirmed in your Welcome Email.
        </p>
        <p>
          Rates displayed consist of Genesis and network charges. See how
          pricing works below.
        </p>
      </div>
      <a
        href="/business/understanding-your-bill"
        target="_blank"
        className={styles.cta}
      >
        <span className={styles.cta_text}>Understand your rates in-depth</span>
        <Icon name="Info" size="24" className={styles.cta_icon} />
      </a>
    </div>
  );
}
