import { useState } from "react";
import { createSelector } from "@reduxjs/toolkit";
import {
  setIsExistingCustomer,
  submitPersonalDetailsForm,
} from "actions/appUserStateActions";
import { createOnlineSignup } from "actions/residentialSignupActions";
import { createZendeskTicket } from "actions/zendeskActions";
import { differenceInYears, parse } from "date-fns";
import { Formik } from "formik";
import { AnimatePresence, motion } from "framer-motion";
import Feedback from "join-form/components/feedback";
import { RadioList } from "join-form/components/form-controls";
import Intro from "join-form/components/intro";
import StepProgressionButton from "join-form/components/step-progression-button";
import { useRouter } from "next/router";
import { useDispatch, useSelector } from "react-redux";
import {
  selectAddressState,
  selectAppState,
  selectAppUserState,
} from "reducers/selector";
import * as Yup from "yup";
import { InlineBlockEditor } from "components/BlockEditor";
import {
  appResidentialRoutes,
  appRoutes,
  PLAN_TYPE_EV_PLUS,
} from "helpers/constants";
import { getPageData } from "helpers/getPageData";
import { trackResidentialContinueButton } from "helpers/gtmHelper";
import {
  headerFade,
  heightFadeTransition,
  heightFadeVariants,
} from "./animations";
import styles from "./contact-details-page.module.scss";
import { CustomerDetailsForm } from "./CustomerDetailsForm";

function getBackButtonUrl(planType) {
  if (planType === PLAN_TYPE_EV_PLUS) {
    return appResidentialRoutes.carRegistration;
  }
  return appResidentialRoutes.planSelect;
}

const modules = [
  "GE_JOIN_Intro_Header",
  "GE_JOIN_Residential_Contact_Details",
  "GE_JOIN_Residential_New_Customer_Fine_Print",
  "GE_JOIN_Residential_Existing_Customer_Terms",
];

const defaultPageData = {
  fields: {
    title: "Get connected",
    blockEditor: null,
    existingCustomerFieldLabel: "Are you an existing Genesis customer?",
    newCustomerInfoText: `Please provide your full legal name. You'll need your passport or
    driver's licence handy so we can verify your identity. If you don't
    complete the sign-up process, we may still contact you with the
    details you've supplied, to see how we can help you along.`,
    titleLabel: "Title",
    firstNameLabel: "First Name",
    middleNameLabel: "Middle Name",
    lastNameLabel: "Last Name",
    emailLabel: "Email",
    phoneLabel: "Phone",
    newCustomerPromoByEmailOptOutMessage: `Just to note, you won't be notified about your free Power
    Shouts via email but can still redeem them in Energy IQ.`,
    newCustomerFinePrint: null,
    existingCustomerInfo: `You won't lose auto-pay discounts you're already receiving.`,
    existingCustomerNumberLabel: "Genesis customer number (optional)",
    existingCustomerFieldsInfo:
      "Please provide your full name and date of birth",
    existingCustomerDateOfBirthLabel: "Date of Birth (DD/MM/YYYY)",
    existingCustomerContactDetailsInfo: "Please provide your contact details",
    existingCustomerTerms: null,
  },
};

function ContactDetailsPage(props) {
  const {
    planType,
    values,
    touched,
    errors,
    handleChange,
    handleSubmit,
    isValid,
    showExistingCustomerSelectionFields,
    handleCustomerTypeSelection,
    status,
    isSubmitting,
    pageProps,
  } = props;

  const pageData = getPageData({
    modules,
    page: pageProps.page,
    defaultPageData,
  });

  const backButtonUrl = getBackButtonUrl(planType);

  const onCustomerTypeSelection = (e) => {
    handleChange(e);
    handleCustomerTypeSelection(e.target.value, values);
  };

  const shouldShowNewCustomerForm =
    planType !== PLAN_TYPE_EV_PLUS ||
    (showExistingCustomerSelectionFields &&
      values.isExistingCustomer === "false");

  const shouldShowExistingCustomerForm =
    showExistingCustomerSelectionFields && values.isExistingCustomer === "true";

  return (
    <motion.div
      className={styles.container}
      exit="undefined"
      data-testid="contactDetailsPage"
    >
      <Intro>
        {(introStyles) => (
          <motion.div
            initial="initial"
            animate="animate"
            exit="exit"
            variants={headerFade}
          >
            <h1 className={introStyles.title}>{pageData.fields.title}</h1>
            <div className={introStyles.text}>
              <InlineBlockEditor
                jsonString={pageData.fields.blockEditor}
                enableProse={false}
              />
            </div>
          </motion.div>
        )}
      </Intro>
      {showExistingCustomerSelectionFields && (
        <div className="mb-5 md:mb-10">
          <p className={styles.radio_label}>
            {pageData.fields.existingCustomerFieldLabel}
          </p>
          <RadioList
            name="isExistingCustomer"
            onChange={onCustomerTypeSelection}
            errorMessage={errors.isExistingCustomer}
            showError={errors.isExistingCustomer && touched.isExistingCustomer}
            size="medium"
            items={[
              {
                text: "Yes",
                value: true,
                checked: values.isExistingCustomer === "true",
                testId: "isExistingCustomerYesOption",
              },
              {
                text: "No",
                value: false,
                checked: values.isExistingCustomer === "false",
                testId: "isExistingCustomerYesOption",
              },
            ]}
          />
        </div>
      )}
      <CustomerDetailsForm
        shouldShowNewCustomerForm={shouldShowNewCustomerForm}
        shouldShowExistingCustomerForm={shouldShowExistingCustomerForm}
        planType={planType}
        values={values}
        touched={touched}
        errors={errors}
        handleChange={handleChange}
        pageData={pageData}
      />
      <div className="mt-10 overflow-hidden md:mt-15 lg:mt-20">
        <AnimatePresence>
          {status && (
            <motion.div
              initial="initial"
              animate="animate"
              exit="exit"
              variants={heightFadeVariants}
              transition={heightFadeTransition}
              className="overflow-hidden"
            >
              <Feedback
                type="error"
                title="Error"
                data-testid="apiErrorMessage"
              >
                {status}
              </Feedback>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
      <StepProgressionButton
        disabled={!isValid || isSubmitting}
        onContinueClick={handleSubmit}
        backLink={backButtonUrl}
        testId="continueButton"
        applyDisabledAttribute={isSubmitting}
        isLoading={isSubmitting}
        text={shouldShowExistingCustomerForm ? "Sign up now" : "Continue"}
      />
    </motion.div>
  );
}

const mapPropsToValues = ({
  appUserState,
  showExistingCustomerSelectionFields,
}) => ({
  title: appUserState.title,
  firstName: appUserState.firstName,
  middleName: appUserState.middleName,
  lastName: appUserState.lastName,
  email: appUserState.email,
  phone: appUserState.phone,
  dob: appUserState.dob,
  isPromoByEmail: appUserState.isPromoByEmail || "true",
  isExistingCustomer: appUserState.isExistingCustomer,
  customerNumber: appUserState.customerNumber,
  showExistingCustomerSelectionFields,
  generalTermsForExistingCustomers:
    appUserState.generalTermsForExistingCustomers,
});

const validationSchema = Yup.object().shape({
  title: Yup.string().required("Please select your title"),
  firstName: Yup.string()
    .max(40, "First name cannot be more than 40 characters")
    .required("Please enter your first name"),
  middleName: Yup.string()
    .max(40, "Middle name cannot be more than 40 characters")
    .optional(),
  lastName: Yup.string()
    .max(80, "Last name cannot be more than 80 characters")
    .required("Please enter your last name"),
  email: Yup.string()
    .email("Please enter a valid email")
    .max(80, "Email address cannot be more than 80 characters")
    .required("Please enter your email address"),
  phone: Yup.string()
    .min(7, "Phone number must be at least 7 digits")
    .max(40, "Phone number cannot be more than 40 digits")
    .matches(/^[0-9+]+[0-9 ]{3,40}$/, "Please enter valid number")
    .required("Please enter your phone number"),
  isPromoByEmail: Yup.string()
    .matches(/(true|false)/i, { message: "This field is required" })
    .required("This field is required"),
  isExistingCustomer: Yup.string().when("showExistingCustomerSelectionFields", {
    is: true,
    then: Yup.string()
      .matches(/(true|false)/i, { message: "This field is required" })
      .required("This field is required"),
  }),
  generalTermsForExistingCustomers: Yup.string().when("isExistingCustomer", {
    is: (value) => value === "true",
    then: Yup.string()
      .required("Please accept terms and conditions")
      .oneOf(["true"], "Please accept terms and conditions"),
  }),
  dob: Yup.string().when("isExistingCustomer", {
    is: (value) => value === "true",
    then: Yup.string()
      .required("Date of birth is required")
      .matches(
        /(?:0[1-9]|[12][0-9]|3[01])\/(?:0[1-9]|1[0-2])\/(?:\d{4})$/,
        "Please enter a valid date of birth",
      )
      .max(10, "Date of birth cannot be more than 10 characters")
      .test("dob", "Sorry, you must be over 18", (value) => {
        const now = new Date(Date.now());
        const enteredDate = parse(value, "dd/MM/yyyy", now);
        const age = differenceInYears(now, enteredDate);
        return age >= 18;
      }),
  }),
  customerNumber: Yup.string().when("isExistingCustomer", {
    is: (value) => value === "true",
    then: Yup.string()
      .nullable()
      .notRequired()
      .when("customerNumber", {
        is: (value) => value?.length,
        then: Yup.string()
          .min(8, "Customer number needs to be at least 8 digits")
          .max(12, "Customer number needs to be no more than 12 digits"),
      }),
  }),
});

const stateSelector = createSelector(
  [selectAppState, selectAppUserState],
  (appState, appUserState) => {
    const { planType } = appState;
    return {
      appUserState,
      planType,
      showExistingCustomerSelectionFields: planType === PLAN_TYPE_EV_PLUS,
    };
  },
);

function ContactDetailsPageForm(pageProps) {
  const [pendingRequest, setPendingRequest] = useState(false);
  const props = useSelector(stateSelector);
  const dispatch = useDispatch();

  const initialValues = mapPropsToValues(props);
  const router = useRouter();

  const { customerType } = useSelector(selectAppState);
  const { addressDetails } = useSelector(selectAddressState);

  const onSubmit = async (values, options) => {
    const invalidRequest = !customerType || !addressDetails;
    if (!pageProps?.isTesting && invalidRequest) {
      router.push(appRoutes.customerType);
      return;
    }
    dispatch(submitPersonalDetailsForm(values));
    if (pendingRequest) return;

    if (values.isExistingCustomer === "true") {
      const request = dispatch(createOnlineSignup())
        .then(() => {
          setPendingRequest(false);
          options.setSubmitting(false);
          trackResidentialContinueButton(
            {
              event: "input_contact_details",
              content_tertiary: "input contact details for existing customer",
            },
            "sign up now",
          );
          router.push({ pathname: appResidentialRoutes.success });
        })
        .catch((err) => {
          setPendingRequest(false);
          options.setStatus("Something went wrong, please try again.");
          options.setSubmitting(false);
        });
      return request;
    }
    trackResidentialContinueButton({
      event: "input_contact_details",
      content_tertiary: "input contact details",
    });
    dispatch(createZendeskTicket());
    router.push({ pathname: appResidentialRoutes.creditCheck });
  };

  const handleCustomerTypeSelection = (isExistingCustomer, values) => {
    dispatch(submitPersonalDetailsForm(values));
    dispatch(setIsExistingCustomer(isExistingCustomer));
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      validateOnMount
      enableReinitialize
    >
      {(formikProps) => (
        <ContactDetailsPage
          {...props}
          {...formikProps}
          pageProps={pageProps}
          handleCustomerTypeSelection={handleCustomerTypeSelection}
        />
      )}
    </Formik>
  );
}

export default ContactDetailsPageForm;
