import classNames from "classnames";
import { sortBy } from "lodash-es";
import { Success } from "assets/icons";
import { THEME } from "helpers/constants";

function ContentFeatureCardList({ data, theme }) {
  if (!data?.length) {
    return null;
  }
  const sortedData = sortBy(data, ["properties.itemOrder"]);
  const variantStyle =
    theme === THEME.orange ? "text-white" : "text-warm-orange-primary";
  return (
    <>
      <hr className="mt-2 mb-5 md:mt-3 h-0.5 bg-black-50 rounded-4" />
      <ul className={variantStyle} data-testid="contentFeatureCardList">
        {sortedData.map((item, index, allItems) => {
          const isLast = index + 1 === allItems.length;
          return (
            <li
              key={item.contentID}
              data-testid="contentFeatureCardListItem"
              className="flex items-stretch space-x-3"
            >
              <div className="flex flex-col items-center">
                <div className="flex items-center h-5 md:h-6">
                  <Success className="fill-current w-4.5 h-4.5" />
                </div>
                {!isLast && (
                  <div
                    data-testid="contentFeatureCardListItemSeparator"
                    className={classNames(
                      "w-0.5 rounded bg-orange-300 min-h-2 flex-1 mt-px",
                      "md:min-h-3",
                    )}
                  />
                )}
              </div>
              <div
                className={classNames(
                  "text-14/20 flex flex-col",
                  "md:text-16/24",
                )}
              >
                {item.fields.checklistitem}
              </div>
            </li>
          );
        })}
      </ul>
    </>
  );
}

export { ContentFeatureCardList };
