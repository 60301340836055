import React from "react";
import classNames from "classnames";
import { FadeInBottomAnimation, FadeInBottomContainer } from "./animations";
import { ArticleCarouselSectionCta } from "./ArticleCarouselSectionCta";

const fakeSpacerCard = (
  <div
    className={classNames(
      "sm:ml-9",
      "md:hidden",
      "shrink-0",
      "screen-edge-spacer",
    )}
  ></div>
);

function ArticleCarouselSection({
  title,
  cta,
  testid,
  psnid,
  children: childrenProp,
}) {
  const children = React.Children.map(childrenProp, (child) => {
    if (!React.isValidElement(child)) {
      return child;
    }
    return React.cloneElement(child, {
      className: classNames(
        "w-57 shrink-0 snap-center",
        "md:w-auto",
        child.props.className,
      ),
    });
  });

  return (
    <div
      data-testid={testid}
      data-psnid={psnid}
      className="mb-10 md:first:pt-44 lg:first:pt-56 md:mb-20 first:pt-21"
    >
      <div
        className={classNames(
          "px-4 mx-auto sm:px-15 md:px-17 lg:px-23 xl:px-46",
          "max-w-full w-120 sm:w-210 md:w-auto",
        )}
      >
        <FadeInBottomAnimation>
          <div
            className={classNames(
              "flex flex-col mb-4",
              "sm:flex-row sm:justify-between sm:items-center sm:mb-8",
            )}
          >
            <h2 className={classNames("font-bold text-27/32", "md:text-32/36")}>
              {title}
            </h2>
            {cta && <ArticleCarouselSectionCta data={cta} />}
          </div>
        </FadeInBottomAnimation>
      </div>
      <FadeInBottomContainer>
        <div
          className={classNames(
            "flex gap-4 pb-5 pt-2 overflow-x-auto snap-x snap-mandatory custom-scrollbar",
            "sm:gap-6",
            "md:space-y-0 md:grid md:grid-cols-4 md:gap-7 md:px-17 lg:px-23 xl:px-46",
          )}
        >
          {fakeSpacerCard}
          {children}
          {fakeSpacerCard}
        </div>
      </FadeInBottomContainer>
    </div>
  );
}

export { ArticleCarouselSection };
