import { compact, flattenDeep, get, last } from "lodash-es";
import ReactHtmlParser from "react-html-parser";
import { transformPlainText } from "./transformNodeHelper";

const BLOCK_SIZE_CENTERED = "centered";

const BLOCK_GROUP_TYPES = Object.freeze({
  default: "default",
  special: "special",
});

const SPECIAL_BLOCKS = ["image", "table"];

function getGroupTypeForBlock(blockType) {
  const isSpecial = SPECIAL_BLOCKS.includes(blockType);
  const type = isSpecial
    ? BLOCK_GROUP_TYPES.special
    : BLOCK_GROUP_TYPES.default;
  return type;
}

function groupBlocksByType(data) {
  const [firstBlock, ...rest] = data;

  if (!firstBlock) {
    return [];
  }

  const blockGroup = {
    id: firstBlock.id,
    type: getGroupTypeForBlock(firstBlock.type),
    data: [firstBlock],
  };

  const groupedData = rest.reduce(
    (acc, currentBlock) => {
      const currentGroup = last(acc);
      const blockType = getGroupTypeForBlock(currentBlock.type);
      if (currentGroup.type === blockType) {
        currentGroup.data.push(currentBlock);
      } else {
        acc.push({
          id: currentBlock.id,
          type: blockType,
          data: [currentBlock],
        });
      }
      return acc;
    },
    [blockGroup],
  );

  return groupedData;
}

function getBlocks(data) {
  try {
    const parsedData = JSON.parse(data);
    const result = get(parsedData, "blocks", []);
    return result;
  } catch (ex) {
    return [];
  }
}

function getPlainText(data) {
  const result = ReactHtmlParser(data, { transform: transformPlainText });
  return result;
}

function getListData(data) {
  const result = data.items.reduce((acc, current) => {
    acc.push(current.content);
    if (current.items) {
      const content = getListData(current);
      acc = acc.concat(content);
    }
    return acc;
  }, []);
  return result;
}

function getQuoteData(data) {
  return compact([data.text, data.caption]).join(" ");
}

function getTextFromBlocks(blocks) {
  const textItems = blocks.map((block) => {
    switch (block.type) {
      case "list": {
        const listData = getListData(block.data);
        const plainTextData = listData?.map(getPlainText);
        return plainTextData;
      }
      case "image": {
        const imageData = get(block.data, "caption", "");
        return getPlainText(imageData);
      }
      case "quote": {
        const quoteData = getQuoteData(block.data);
        return getPlainText(quoteData);
      }
      default:
        const defaultData = get(block.data, "text", "");
        return getPlainText(defaultData);
    }
  });

  const flatTextItems = flattenDeep(textItems);
  const validTextItems = compact(flatTextItems);

  const trimmedTextItems = validTextItems.map((textItem) => textItem?.trim());

  return trimmedTextItems.join(" ");
}

function estimateReadTime(blockEditorJsonString, wordsPerMinute = 200) {
  const blocks = getBlocks(blockEditorJsonString);
  if (!blocks.length) {
    return "";
  }

  const text = getTextFromBlocks(blocks);
  const totalWords = compact(text.split(" ")).length;
  const readTime = Math.ceil(totalWords / wordsPerMinute);

  return `${readTime} min read`;
}

function getBlockEditorDataForSearchIndex(blockEditorJsonString) {
  const blocks = getBlocks(blockEditorJsonString);
  if (!blocks.length) {
    return "";
  }

  const text = getTextFromBlocks(blocks);

  return text;
}

export {
  BLOCK_GROUP_TYPES,
  BLOCK_SIZE_CENTERED,
  getBlocks,
  estimateReadTime,
  groupBlocksByType,
  getBlockEditorDataForSearchIndex,
};
