import React from "react";
import classNames from "classnames";
import { GenesisLogo, SearchLeft } from "assets/icons";
import { Link } from "components/Link";
import { APP_ROUTES } from "helpers/constants";
import { useHeadroom } from "providers/Headroom";
import { selectHomeNav, useNavigation } from "providers/Navigation";
import { PrimaryNav } from "./PrimaryNav";
import { TopLevelNav } from "./TopLevelNav";

const headroomConfig = {
  offset: 150,
  tolerance: { up: 40, down: 10 },
  classes: {
    pinned: "header-pinned translate-y-0",
    unpinned: "header-unpinned -translate-y-53 pointer-events-none",
  },
};

function SiteHeaderDesktop(props) {
  const {
    globalData: {
      header: { content },
    },
    isPreview,
    logoText,
  } = props;

  const { dispatch } = useNavigation();

  const shouldPrefetch = isPreview ? false : undefined;

  const { primaryCta, secondaryCta } = content;

  const { headerRef } = useHeadroom(headroomConfig);

  function onHomeNavClick() {
    selectHomeNav({ dispatch });
  }

  return (
    <div
      ref={headerRef}
      className="fixed z-10 py-5 mx-auto transition-transform duration-300 translate-y inset-x-5 max-w-400 lg:inset-x-10 lg:py-10"
      data-testid="siteHeaderDesktop"
    >
      <div className="flex items-end justify-between bg-white rounded-10 shadow-100">
        <Link href="/">
          <a
            className={classNames(
              "flex items-center shrink-0",
              "gap-x-2.5 lg:gap-x-3",
              "ml-5 -mb-2",
              "lg:-mb-4 lg:ml-10",
            )}
            onClick={onHomeNavClick}
            data-testid="siteHeaderLogoLink"
          >
            <span className={classNames("w-25 h-25", "lg:w-32 lg:h-32")}>
              <GenesisLogo
                role="img"
                aria-label={content.logoAltText}
                className="w-full h-full"
              />
            </span>
            {logoText ? (
              <span
                data-testid="siteHeaderLogoText"
                className="font-bold text-black-primary text-20/24 lg:text-24/28 -tracking-[0.3px]"
              >
                {logoText}
              </span>
            ) : null}
          </a>
        </Link>
        <div className="flex flex-col justify-between flex-1 h-32 lg:h-35">
          <div className="flex items-center justify-end pt-4 pr-4 lg:pr-5 lg:pt-5">
            <TopLevelNav prefetch={shouldPrefetch} />
            <div className="mx-2 w-0.5 h-6 bg-black-50 rounded-sm" />
            <div className="flex items-center justify-center space-x-3 font-medium">
              {primaryCta.href && primaryCta.text ? (
                <Link href={primaryCta.href} prefetch={false}>
                  <a
                    className="flex items-center px-2 py-0.5 text-warm-orange-primary text-xs"
                    target={primaryCta.target}
                    data-testid="primaryCta"
                    data-ga={JSON.stringify({
                      content_tertiary: "global menu",
                      event: "cta_click",
                      event_category: "cta",
                      event_label: [primaryCta.text],
                      href: primaryCta.href,
                    })}
                  >
                    {primaryCta.text}
                  </a>
                </Link>
              ) : null}
              {secondaryCta.href && secondaryCta.text ? (
                <Link href={secondaryCta.href} prefetch={false}>
                  <a
                    className="flex px-5 py-2.5 text-white text-xs bg-orange-primary rounded-full"
                    target={secondaryCta.target}
                    data-testid="secondaryCta"
                    data-ga={JSON.stringify({
                      content_tertiary: "global menu",
                      event: "cta_click",
                      event_category: "cta",
                      event_label: [secondaryCta.text],
                      href: secondaryCta.href,
                    })}
                  >
                    {secondaryCta.text}
                  </a>
                </Link>
              ) : null}
            </div>
            <div className="ml-5 mr-3 w-0.5 h-6 bg-black-50 rounded-sm" />
            <Link href={APP_ROUTES.search} prefetch={false}>
              <a className="flex items-end text-black-primary">
                <span className="flex items-center p-2 rounded-full bg-dark-50">
                  <SearchLeft className="w-6 fill-current" />
                </span>
              </a>
            </Link>
          </div>
          <PrimaryNav prefetch={shouldPrefetch} />
        </div>
      </div>
    </div>
  );
}

export { SiteHeaderDesktop };
