import { motion } from "framer-motion";

const transition = {
  delay: 0.3,
  type: "spring",
  bounce: 0.3,
};

function FadeInLeftTextAnimation({ children, isMd }) {
  return (
    <motion.div
      initial={{ x: isMd ? 60 : 40, opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      transition={transition}
    >
      {children}
    </motion.div>
  );
}

function FadeInLeftPrimaryAnimation({ children, isMd }) {
  return (
    <motion.div
      initial={{ x: isMd ? 100 : 80, opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      transition={transition}
    >
      {children}
    </motion.div>
  );
}

function FadeInLeftSecondaryAnimation({ children, isMd }) {
  return (
    <motion.div
      initial={{ x: isMd ? 140 : 100, opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      transition={transition}
    >
      {children}
    </motion.div>
  );
}

function FadeInWidthAnimation({ children, className }) {
  return (
    <motion.div
      initial={{ width: 0, opacity: 0 }}
      animate={{ width: "auto", opacity: 1 }}
      transition={transition}
      className={className}
    >
      {children}
    </motion.div>
  );
}

export {
  FadeInLeftTextAnimation,
  FadeInLeftPrimaryAnimation,
  FadeInLeftSecondaryAnimation,
  FadeInWidthAnimation,
};
