import { UTM_CODES } from "helpers/constants";

export const getUtmValues = (searchParams) => {
  const allParams = new URLSearchParams(searchParams);
  for (const [key, value] of allParams.entries()) {
    if (key.toLowerCase() !== key) {
      allParams.append(key.toLowerCase(), value);
    }
  }

  const utmSource = allParams.get(UTM_CODES.source);
  const utmMedium = allParams.get(UTM_CODES.medium);
  const utmCampaign = allParams.get(UTM_CODES.campaign);
  const gclid = allParams.get(UTM_CODES.gclid);
  const dclid = allParams.get(UTM_CODES.dclid);

  return {
    utmSource,
    utmMedium,
    utmCampaign,
    gclid,
    dclid,
  };
};
