import classNames from "classnames";

export const ImageBubble = ({ className }) => (
  <div
    className={classNames(
      "absolute rounded-[50%] top-full h-80 w-[140%] -left-[20%] -translate-y-14",
      "sm:h-96 -translate-y-16",
      "md:h-[120%] md:w-80 md:-top-[10%] md:-left-80 md:translate-x-20 md:translate-y-0",
      className,
    )}
  ></div>
);
