/* istanbul ignore file */
import { useState } from "react";
import classNames from "classnames";
import { Mousewheel } from "swiper";
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  MilestoneNav,
  milestoneNavItems,
  Slide,
  sortCards,
} from "./MilestoneHelper";

// carousel config
const config = {
  centeredSlides: true,
  centeredSlidesBounds: true,
  className: "milestone",
  modules: [Mousewheel],
  direction: "horizontal",
  initialSlide: 0,
  mousewheel: {
    forceToAxis: true,
  },
  slidesPerView: "auto",
  touchEventsTarget: "wrapper",
};

export const Carousel = ({ cards, cardStates, showTabs }) => {
  const [swiperRef, setSwiperRef] = useState(null);
  const [activeCardState, setActiveCardState] = useState(
    () => cardStates[config.initialSlide],
  );

  // sort cards by both card and cardState order
  cards = sortCards({ cards, cardStates });

  // handle active slide
  let navItems;
  if (showTabs) {
    navItems = milestoneNavItems({
      config,
      cards,
      cardStates,
      setActiveCardState,
      swiperRef,
    });
  }

  return (
    <>
      {showTabs && (
        <MilestoneNav
          activeCardState={activeCardState}
          navItems={navItems}
          swiperRef={swiperRef}
        />
      )}
      <Swiper {...config} onSwiper={setSwiperRef}>
        {cards.map((card) => (
          <SwiperSlide
            key={`milestone-slide-${card.contentID}`}
            className={classNames(
              "!w-53 sm:!w-57 md:!w-93",
              "my-3",
              "mx-2.5 sm:mx-3 md:mx-3.5 lg:mx-4",
              "first:ml-4 first:sm:ml-15 first:md:ml-17 first:lg:ml-23 first:xl:ml-46",
              "last:mr-4 last:sm:mr-15 last:md:mr-17 last:lg:mr-23 last:xl:mr-46",
              "after:content-[''] after:-right-4 after:sm:-right-5 after:md:-right-6 after:lg:-right-7 after:top-14 after:absolute after:w-3 after:sm:w-4 after:md:w-5 after:lg:w-6 after:h-1 after:rounded after:bg-dark-primary last:after:hidden",
            )}
          >
            <Slide card={card} />
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
};
