import React from "react";
import dynamic from "next/dynamic";

const FormStackEmbed = dynamic(() => import("./FormStackEmbed"), {
  ssr: false,
});

function FormStack(props) {
  return <FormStackEmbed {...props} />;
}

export { FormStack };
