import { ArticleHeader } from "components/ArticleHeader";
import { CallToAction } from "components/CallToAction";
import { VARIANT } from "helpers/constants";

function DefaultHeader({ module }) {
  const { title, description, cta1, cta2 } = module.fields;

  const ctaPrimary = cta1 ? (
    <CallToAction data={cta1} variant={VARIANT.orange} testid="primaryCta" />
  ) : null;
  const ctaSecondary = cta2 ? (
    <CallToAction
      data={cta2}
      variant={VARIANT.darkTransparent}
      testid="linkCta"
    />
  ) : null;

  return (
    <ArticleHeader
      title={title}
      description={description}
      ctaPrimary={ctaPrimary}
      ctaSecondary={ctaSecondary}
    />
  );
}

export { DefaultHeader };
