import { motion } from "framer-motion";

const springTransition = {
  delay: 0.3,
  type: "spring",
  bounce: 0.3,
  duration: 0.3,
};

const tweenTransition = {
  delay: 0.1,
  type: "tween",
  duration: 0.3,
};

const leftFadeInAnimation = {
  hidden: { opacity: 0, x: -20 },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 0.3,
    },
  },
};

const rightFadeInAnimation = {
  hidden: { opacity: 0, x: 20 },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 0.3,
    },
  },
};

const snippetContainerAnimation = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.2,
    },
  },
};

function VideoCardContainerAnimation({ children }) {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      viewport={{ once: true, amount: 0.2 }}
      transition={tweenTransition}
    >
      {children}
    </motion.div>
  );
}

function ScaleAnimation({ children }) {
  return (
    <motion.div
      initial={{ scale: 1.05, opacity: 0 }}
      animate={{
        scale: 1,
        opacity: 1,
      }}
      transition={tweenTransition}
    >
      {children}
    </motion.div>
  );
}

function SnippetContainerAnimation({ children }) {
  return (
    <motion.div
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true, amount: 0.2 }}
      variants={snippetContainerAnimation}
      transition={springTransition}
    >
      {children}
    </motion.div>
  );
}

function SnippetItemFadeInAnimation({ children, isSnippetLeftAligned }) {
  return (
    <motion.div
      variants={
        isSnippetLeftAligned ? leftFadeInAnimation : rightFadeInAnimation
      }
    >
      {children}
    </motion.div>
  );
}

export {
  ScaleAnimation,
  SnippetContainerAnimation,
  SnippetItemFadeInAnimation,
  VideoCardContainerAnimation,
};
