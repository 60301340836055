import { useEffect, useState } from "react";
import classNames from "classnames";
import { useRouter } from "next/router";
import { useQuery } from "react-query";
import { Chat } from "assets/icons";
import { gaLiveChat } from "helpers/gaHelper";
import { getLiveChatButtonActiveStatus } from "./apiHelper";

function getLiveChatScript() {
  (function (g, e, n, es, ys) {
    g["_genesysJs"] = e;
    g[e] =
      g[e] ||
      function () {
        (g[e].q = g[e].q || []).push(arguments);
      };
    g[e].t = 1 * new Date();
    g[e].c = es;
    ys = document.createElement("script");
    ys.async = 1;
    ys.src = n;
    ys.charset = "utf-8";
    document.head.appendChild(ys);
  })(
    window,
    "Genesys",
    "https://apps.mypurecloud.com.au/genesys-bootstrap/genesys.min.js",
    {
      environment: process.env.NEXT_PUBLIC_LIVE_CHAT_ENVIRONMENT,
      deploymentId: process.env.NEXT_PUBLIC_LIVE_CHAT_DEPLOYMENT_ID,
    },
  );
}

function LiveChat() {
  const router = useRouter();
  const pathname = router.asPath;
  const [showLiveChatButton, setLiveChatButtonVisibility] = useState(false);
  const [isLiveChatLaunched, setLiveChatLaunchStatus] = useState(false);

  useEffect(() => {
    if (!window.Genesys) {
      getLiveChatScript();
      window.Genesys("subscribe", "Launcher.ready", function () {
        setLiveChatLaunchStatus(true);
      });
    }
  }, []);

  const { data } = useQuery(
    ["liveChatStatus", pathname],
    getLiveChatButtonActiveStatus,
    {
      retry: false,
      refetchOnWindowFocus: false,
    },
  );
  
  const openchat = router?.query?.openchat;
  useEffect(() => {
    // open live chat if openchat url query is provided
    if(openchat === 'true' && window?.Genesys) {
      toggleMessenger();
    }
  }, [openchat]);

  useEffect(() => {
    setLiveChatButtonVisibility(data);
  }, [data]);

  // Ref: https://developer.genesys.cloud/commdigital/digital/webmessaging/messengersdk/pluginExamples#build-your-own-messenger-launcher
  const toggleMessenger = () => {
    window.Genesys(
      "command",
      "Messenger.open",
      {},
      function (o) {
        gaLiveChat({
          event_label: "initiate chat",
        });
      }, // if resolved
      function (o) {
        // if rejected
        window.Genesys("command", "Messenger.close");
      },
    );
  };

  return showLiveChatButton && isLiveChatLaunched ? (
    <div className="pointer-events-none max-w-400 left-4 right-4 bottom-4">
      <button
        aria-label="Live Chat Button"
        type="button"
        data-testid="liveChatButton"
        id="custom-launcher"
        onClick={toggleMessenger}
        className={classNames("flex items-center ml-auto pointer-events-auto")}
      >
        <Chat />
      </button>
    </div>
  ) : null;
}

export { LiveChat };
