import { useScreenSize } from "hooks";
import { DesktopView } from "./DesktopView";
import { getCustomInitialProps } from "./getCustomInitialProps";
import { MobileTabletView } from "./MobileTabletView";

const MultipleImage = ({ customData, module }) => {
  const { isMd } = useScreenSize();
  if (!module?.fields || !customData.length) return null;
  const { alignment, cta1, cta2, description, title } = module.fields;
  const ctaData = {
    description,
    title,
    cta1,
    cta2,
  };

  return (
    <div className="mb-15 md:mb-25" data-psnid="psn_multiple_image">
      {!isMd && <MobileTabletView ctaData={ctaData} images={customData} />}
      {isMd && (
        <DesktopView
          ctaData={ctaData}
          images={customData}
          alignment={alignment}
        />
      )}
    </div>
  );
};

MultipleImage.getCustomInitialProps = getCustomInitialProps;

export { MultipleImage };
