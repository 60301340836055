import { JoinPageTemplate } from "./JoinPageTemplate";
import { PageTemplate } from "./PageTemplate";

const allTemplates = {
  GEPageTemplate: PageTemplate,
};

function getPageTemplate(templateName = "") {
  const searchKey = Object.keys(allTemplates).find(
    (name) => name.toLowerCase() === templateName.toLowerCase(),
  );

  const template = allTemplates[searchKey] || JoinPageTemplate;

  return template;
}

export { getPageTemplate };
