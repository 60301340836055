import classNames from "classnames";
import { sortBy } from "lodash-es";
import { InlineBlockEditor } from "components/BlockEditor";
import { THEME } from "helpers/constants";
import { DescriptionListContent } from "./DescriptionListContent";
import { DescriptionListHeader } from "./DescriptionListHeader";
import { DescriptionListItem } from "./DescriptionListItem";

function DescriptionList({ data }) {
  const { title, titleStyle, list } = data;
  const sortedList = sortBy(list, ["properties.itemOrder"]);
  const titleStyles =
    titleStyle === THEME.orange
      ? "text-orange-primary text-20/24"
      : "text-black-primary text-27/32 md:text-32/36";

  if (!list?.length) {
    return null;
  }

  return (
    <div
      className={classNames(
        "mx-auto mb-5",
        "px-4 sm:px-15 md:px-20 lg:px-23 xl:px-46",
        "max-w-full w-120 sm:w-210 md:w-auto",
        "md:grid md:gap-x-7 md:grid-cols-24 lg:gap-x-8",
      )}
    >
      <div className="md:col-span-13 md:col-start-2">
        <h2 className={classNames("font-bold mb-5", titleStyles)}>{title}</h2>
        <div data-testid="descriptionList">
          {sortedList.map((item) => {
            const { fields } = item;
            return (
              <DescriptionListItem key={item.contentID}>
                <DescriptionListHeader>{fields.term}</DescriptionListHeader>
                <DescriptionListContent>
                  <InlineBlockEditor
                    jsonString={fields.description}
                    className="!prose-sm break-words"
                  />
                </DescriptionListContent>
              </DescriptionListItem>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export { DescriptionList };
