import classNames from "classnames";
import { useInstantSearch } from "react-instantsearch-hooks-web";

function SearchResults({ children }) {
  const { indexUiState, results } = useInstantSearch();

  if (!indexUiState.query) {
    return null;
  }

  const hasResults = results.nbHits > 0;

  return (
    <div className="flex flex-col gap-5 md:gap-10" data-testid="searchResults">
      <h3
        data-testid="searchResultsHeading"
        className={classNames(
          "font-medium text-20/24 text-warm-orange-primary",
          "md:text-32/36 md:font-bold",
        )}
      >
        {hasResults ? "Top results" : "No results found"}
      </h3>
      <div className="sm:pl-4.5 md:pl-10 lg:pl-13">
        {hasResults ? (
          children
        ) : (
          <p>Check your spelling or try different keywords.</p>
        )}
      </div>
    </div>
  );
}

export { SearchResults };
