import { AnimatePresence, motion } from "framer-motion";
import { Icon } from "components/Icon";
import styles from "./conditional-form.module.scss";

// Prop size expects values: small, medium, large
export function ConditionalForm(props) {
  const {
    id = "",
    title,
    onClick,
    isSelected = false,
    initialHeight = 0,
    subTitle = "",
    onInfoIconClick,
    children,
    size,
  } = props;
  const activeClass = isSelected ? styles.active : "";

  const onInfoIconClickInternal = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onInfoIconClick();
  };

  return (
    <div
      className={`${styles.item} ${styles[size]} ${activeClass}`}
      data-testid={`${id}ConditionalForm`}
    >
      <button
        className={styles.item_button}
        onClick={onClick}
        data-testid={`${id}ConditionalFormButton`}
      >
        <div className={styles.item_radio_iconContainer}>
          <div className={styles.item_radio_icon}></div>
        </div>
        {subTitle ? (
          <div className="text-left">
            <div className="text-18/28 md:text-24/36 font-medium text-black-primary">
              {title}
            </div>
            <div
              className="text-15/24 md:text-18/28 text-orange-primary mt-1"
              data-testid={`${id}ConditionalFormSubTitle`}
            >
              {subTitle}
            </div>
          </div>
        ) : (
          <div className="text-18/28 md:text-24/36 font-medium text-black-primary">
            {title}
          </div>
        )}
        {onInfoIconClick && (
          <div
            onClick={onInfoIconClickInternal}
            className={styles.info}
            data-testid={`${id}InfoButton`}
          >
            <Icon name="Info" size="18" desktopSize="24" />
          </div>
        )}
      </button>
      <AnimatePresence>
        {isSelected && (
          <motion.div
            initial={{ height: initialHeight, opacity: 0 }}
            animate={{ height: "auto", opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            transition={{ type: "tween" }}
            className={styles.item_body}
            data-testid="conditionalFormBody"
          >
            <div className={styles.item_form}>{children}</div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}
