import React from "react";
import { useEvent } from "react-use";
import { ArrowRight } from "assets/icons";
import { Link } from "components/Link";
import {
  deselectActivePrimaryNavItem,
  useNavigation,
} from "providers/Navigation";
import { NavLink } from "./NavLink";

function SecondaryNav({ item }) {
  const {
    dispatch,
    state: { primaryNav },
  } = useNavigation();

  const secondaryNavRef = React.useRef();

  function onNavItemClick() {
    deselectActivePrimaryNavItem({ dispatch });
  }

  function onClickOutside(e) {
    const clickedOutside = !secondaryNavRef.current?.contains(e.target);
    const clickedOnPrimaryNav = e.target.closest(".primary-nav");
    if (clickedOutside && !clickedOnPrimaryNav) {
      deselectActivePrimaryNavItem({ dispatch });
    }
  }

  function onKeyUp({ key }) {
    if (key.toLowerCase() === "escape") {
      deselectActivePrimaryNavItem({ dispatch });
    }
  }

  useEvent("keyup", onKeyUp);

  useEvent("click", onClickOutside);

  return (
    <div
      ref={secondaryNavRef}
      data-testid="secondaryNav"
      className="absolute transition-opacity duration-150 delay-300 -translate-x-1/2 moz-bg-white secondary-nav opacity-1 left-1/2 top-14 bg-gradient-to-t rounded-5 from-transparent-white-60 to-transparent-white-80"
    >
      <div className="duration-1000 py-7 w-96 rounded-5 backdrop-blur-4xl">
        <div className="absolute inset-0 border border-transparent pointer-events-none border-mask bg-gradient-to-t rounded-5 from-transparent-white-60 to-transparent-white-40 bg-origin-border"></div>
        <Link href={item.path}>
          <a
            onClick={onNavItemClick}
            className="flex items-center justify-between mb-3 text-base group px-7 text-black-primary"
            data-ga={JSON.stringify({
              content_tertiary: "secondary menu",
              event_label: [
                primaryNav.menuText,
                item.menuText,
                `${item.menuText} overview`,
              ],
              event_category: "secondary menu",
            })}
          >
            {item.menuText} overview
            <span className="flex items-center justify-center transition duration-150 bg-white rounded-full w-7 h-7 group-hover:translate-x-1 group-active:translate-x-2">
              <ArrowRight className="w-4 h-4" />
            </span>
          </a>
        </Link>
        <ul className="flex flex-col space-y-0.5">
          {item.children?.map((navItem) => (
            <li key={navItem.path}>
              <NavLink
                item={navItem}
                onClick={onNavItemClick}
                parentLabel={[primaryNav.menuText, item.menuText]}
              />
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export { SecondaryNav };
