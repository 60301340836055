import { get } from "lodash-es";
import { runAsync } from "helpers";

function isCurrentPageChildOfSitemapItem(currentPage, sitemapItem) {
  return currentPage.path.includes(sitemapItem.path);
}

function getSitemapItemsVisibleOnMenu(sitemap, currentPage) {
  const transformedSitemap = sitemap.reduce((result, sitemapItem) => {
    if (sitemapItem.visible.menu) {
      if (sitemapItem.children) {
        sitemapItem.children = getSitemapItemsVisibleOnMenu(
          sitemapItem.children,
          currentPage,
        );
      }
      sitemapItem.isSelected = isCurrentPageChildOfSitemapItem(
        currentPage,
        sitemapItem,
      );
      result.push(sitemapItem);
    }
    return result;
  }, []);
  return transformedSitemap;
}

function ensureTopLevelNavIsSelected(sitemap) {
  const isTopLevelNavAlreadySelected = sitemap.some(
    ({ isSelected }) => isSelected,
  );
  if (isTopLevelNavAlreadySelected) {
    return sitemap;
  }
  const sitemapWithTopLevelNavSelected = sitemap.map((item) => {
    return {
      ...item,
      isSelected: item.name.toLowerCase() === "for-home",
    };
  });
  return sitemapWithTopLevelNavSelected;
}

function createContentProps(contentItem) {
  const logoUrl = get(contentItem, "fields.logo.url", "/genesis-logo.png");
  const logoAltText = get(contentItem, "fields.logo.label", "Genesis Energy");
  const primaryCta = get(contentItem, "fields.primaryCta", {});
  const secondaryCta = get(contentItem, "fields.secondaryCta", {});
  return {
    logoUrl,
    logoAltText,
    primaryCta,
    secondaryCta,
  };
}

async function getCustomInitialProps(context) {
  const { agility, languageCode, channelName, sitemapNode } = context;

  if (!sitemapNode) {
    return null;
  }

  const [contentItem, getContentItemError] = await runAsync(() =>
    agility.getContentItem({ contentID: 151, languageCode }),
  );

  if (getContentItemError) {
    return null;
  }

  const [sitemap, getSitemapNestedError] = await runAsync(() =>
    agility.getSitemapNested({ channelName, languageCode }),
  );

  if (getSitemapNestedError) {
    return null;
  }

  const sitemapItemsVisibleOnMenu = getSitemapItemsVisibleOnMenu(
    sitemap,
    sitemapNode,
  );

  const navigation = ensureTopLevelNavIsSelected(sitemapItemsVisibleOnMenu);

  const content = createContentProps(contentItem);

  return {
    content,
    navigation,
  };
}

const header = { getCustomInitialProps };

export { header };
