import { setPaymentForm } from "actions/paymentStateActions";
import Axios from "axios";
import { windcaveCallbackUrls } from "helpers/constants";
import { getRequestHeaders } from "join-form/services/npaAuth";
import { get } from "lodash-es";
import Router from "next/router";
import { store } from "store";

const axiosInstance = Axios.create({
  baseURL: process.env.NEXT_PUBLIC_MULE_API_BASE_URL,
});

async function getRequestConfig() {
  const headers = await getRequestHeaders();
  return { headers };
}

async function makeSessionRequest(requestData) {
  const requestConfig = await getRequestConfig();
  return axiosInstance.post(
    "/customer/payment/session",
    requestData,
    requestConfig,
  );
}

export async function setCcPayment(sessionId) {
  const dispatch = store.dispatch;
  const [sessionData, sessionDataError] = await getSessionData(sessionId);

  if (sessionDataError) {
    dispatch(setPaymentForm({ isApiError: true }));
    return;
  }

  if (sessionData.state === "cancelled") {
    dispatch(setPaymentForm({ isApiError: false, isCcInvalid: true }));
    return;
  }

  const {
    transactionResult,
    tokenId,
    expiryDate,
    cardNumber,
    cardType,
  } = getPaymentDataFromSession(sessionData);


  if (transactionResult !== "APPROVED") {
    dispatch(setPaymentForm({
      isApiError: false, isCcInvalid: true, responseText: transactionResult
    }));
    return;
  }

  const formattedDate = formatExpiryDate(expiryDate);
  dispatch(setPaymentForm({
    tokenId,
    expiryDate,
    isApiError: false,
    isCcInvalid: false,
    responseText: transactionResult,
    creditCard: cardNumber,
    creditCardType: cardType,
    expiryMmYy: formattedDate,
  }));
} 

async function getSessionData(sessionId) {
  try {
    const requestConfig = await getRequestConfig();
    const { data } = await axiosInstance.get(
      `/customer/payment/session/${sessionId}`,
      requestConfig,
    );
    return [data, null];
  } catch (ex) {
    return [null, ex];
  }
}

function getRedirectUrl(data) {
  const { links } = data;
  const redirectInfo = links.find(({ method }) => method === "REDIRECT") || {};
  const url = redirectInfo.href || "";
  return url;
}

export function formatExpiryDate(expiryDate) {
  if (typeof expiryDate === 'string' && expiryDate.length === 4) {
    const month = expiryDate.slice(0, 2); // Get the first two characters (mm)
    const year = expiryDate.slice(2);      // Get the last two characters (yy)
    return `${month}/${year}`;        // Format as mm/yy
  }
}

export function getPaymentDataFromSession(sessionData) {
  const transactionDetails = get(sessionData, "transactions[0]", {});
  const transactionResult = get(transactionDetails, "responseText", "");
  const cardDetails = get(transactionDetails, "card", {});
  const cardNumber = get(cardDetails, "cardNumber", "").replace(/\./g, '•').replace(/(.{4})/g, '$1 ');
  const cardType = get(cardDetails, "type", "");
  const tokenId = get(cardDetails, "id", "");
  const expiryMonth = get(cardDetails, "dateExpiryMonth", "");
  const expiryYear = get(cardDetails, "dateExpiryYear", "");
  const expiryDate = expiryMonth + expiryYear;

  return { tokenId, expiryDate, transactionResult, cardNumber, cardType };
}

async function createSession() {
  const requestData = {
    type: "validate",
    amount: "0.00",
    currency: "NZD",
    storeCard: true,
    storeCardIndicator: "recurring",
    merchantReference: "Genesis Auto-pay",
    callbackUrls: {
      approved: windcaveCallbackUrls.approved,
      declined: windcaveCallbackUrls.declined,
      cancelled: windcaveCallbackUrls.cancelled,
    }
  };

  try {
    const { data } = await makeSessionRequest(requestData);
    const sessionId = data.id;
    const formUrl = getRedirectUrl(data);
    const result = { sessionId, formUrl };
    return [result, null];
  } catch (ex) {
    return [null, ex];
  }
}

export function deleteCreditCardInfo() {
  const dispatch = store.dispatch;
  dispatch(setPaymentForm({
    sessionId: "",
    tokenId: "",
    expiryDate: "",
    isApiError: false,
    isCcInvalid: false,
    responseText: "",
    creditCard: "",
    creditCardType: "",
    expiryMmYy: "",
  }));
}

export async function createCreditCardSession() {
  const dispatch = store.dispatch;
  dispatch(setPaymentForm({ sessionId: "" }));
  const [sessionInfo, createSessionError] = await createSession();

  if (createSessionError) {
    dispatch(setPaymentForm({ isApiError: true }));
    return;
  }

  const { formUrl } = sessionInfo;
  if (!formUrl) {
    dispatch(setPaymentForm({ isApiError: true }));
    return;
  }

  dispatch(setPaymentForm({ sessionId: sessionInfo.sessionId, isApiError: false }));
  Router.push(formUrl);
}
