import {
  BUSINESS_TYPE_COMPANY,
  BUSINESS_TYPE_OTHER,
  BUSINESS_TYPE_SOLE_TRADER,
  DISPLAY_NAME_COMPANY,
  DISPLAY_NAME_OTHER,
  DISPLAY_NAME_SOLE_TRADER,
} from "helpers/constants";

const businessTypeToDisplayNameMapping = {
  [BUSINESS_TYPE_COMPANY]: DISPLAY_NAME_COMPANY,
  [BUSINESS_TYPE_SOLE_TRADER]: DISPLAY_NAME_SOLE_TRADER,
  [BUSINESS_TYPE_OTHER]: DISPLAY_NAME_OTHER,
};

export const getBusinessTypeDisplayName = ({
  businessType,
  otherTradingAs,
}) => {
  if (businessType === BUSINESS_TYPE_OTHER && otherTradingAs) {
    return otherTradingAs;
  }
  const displayName = businessTypeToDisplayNameMapping[businessType] || "";
  return displayName;
};

export const getBusinessTypeNameForSignup = (type) => {
  return businessTypeToDisplayNameMapping[type];
};

export const getBusinessName = ({
  businessType,
  soleTraderTradingAs,
  companyTradingAs,
  otherTradingAs,
}) => {
  switch (businessType) {
    case BUSINESS_TYPE_COMPANY: {
      return companyTradingAs;
    }
    case BUSINESS_TYPE_SOLE_TRADER: {
      return soleTraderTradingAs;
    }
    case BUSINESS_TYPE_OTHER: {
      return otherTradingAs;
    }
    default: {
      return "";
    }
  }
};
