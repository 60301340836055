import { ArticleHeader } from "components/ArticleHeader";
import { BlockEditor } from "components/BlockEditor";
import { Breadcrumb } from "components/Breadcrumb";
import { useGlobalData } from "providers/GlobalData";

export const FaqDetails = ({ dynamicPageItem }) => {
  const {
    fields: { title, blockeditor },
  } = dynamicPageItem;
  const { parentItems } = useGlobalData();
  return (
    <div className="pb-15 md:pb-25">
      <ArticleHeader title={title} />
      <Breadcrumb
        parentItems={parentItems}
        currentItem={{
          menuText: title,
        }}
      />
      <BlockEditor
        data={blockeditor}
        className="mt-10 mb-10 last:mb-0 first:mt-0"
        testid="faqDetails"
      />
    </div>
  );
};
