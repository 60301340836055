import classNames from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import Icon from "join-form/components/icon";
import { heightFadeTransition, heightFadeVariants } from "./animations";

export default function Accordion(props) {
  const {
    className,
    title,
    icon,
    onClick,
    children,
    isOpen = false,
    isBlackVariant = false,
    ...rest
  } = props;

  return (
    <div className={className} {...rest}>
      <button
        className={classNames(
          "flex gap-x-1 items-center cursor-pointer",
          "w-full focus:outline-none",
          "text-14/20 md:text-16/24 font-medium",
          {
            "text-warm-orange-primary": !isBlackVariant,
            "text-black-primary": isBlackVariant,
          },
        )}
        onClick={onClick}
      >
        <Icon
          className="-ml-1 md:-ml-1.5"
          name={icon}
          size="32"
          desktopSize="38"
          useSvg={false}
        />
        <span>{title}</span>
        <Icon
          name="ChevronDown"
          size="18"
          className={classNames(
            "ml-auto",
            "transition-transform text-black-primary",
            { "rotate-0": !isOpen, "rotate-180": isOpen },
          )}
        />
      </button>
      <AnimatePresence exitBeforeEnter>
        {isOpen && (
          <motion.div
            initial="initial"
            animate="animate"
            exit="exit"
            variants={heightFadeVariants}
            transition={heightFadeTransition}
            className="overflow-hidden"
          >
            <div>{children}</div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}
