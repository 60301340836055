import React from "react";
import { JoinFormApp } from "join-form";
import { getJoinPageTemplate } from "./getJoinPageTemplate";


function JoinPageTemplate(props) {
  const { pageTemplateName } = props;

  const PageTemplateComponent = getJoinPageTemplate(pageTemplateName);

  return (
    <JoinFormApp {...props}>
      <PageTemplateComponent {...props} />
    </JoinFormApp>
  );
}

export { JoinPageTemplate };
