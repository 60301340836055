import { ArrowRight } from "assets/icons";
import { Button } from "components/Button";
import { VARIANT } from "helpers/constants";

function AccordionSectionCta({ data }) {
  const canRender = data?.text && data?.href ? true : false;

  return (
    canRender && (
      <div data-testid="accordionSectionCta" className="flex">
        <Button
          href={data.href}
          target={data.target}
          variant={VARIANT.darkTransparent}
          className="pl-0 md:pl-0"
        >
          <span>{data.text}</span>
          <ArrowRight />
        </Button>
      </div>
    )
  );
}

export { AccordionSectionCta };
