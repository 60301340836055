import React from "react";
import classNames from "classnames";
import ReactHtmlParser from "react-html-parser";
import { transform } from "helpers/transformNodeHelper";

function TableHeader({ data }) {
  if (!data.length) {
    return null;
  }

  return (
    <thead>
      <tr>
        {data.map((dataCell, index) => {
          const html = ReactHtmlParser(dataCell, { transform });
          return (
            <th className="px-4 py-5" key={index}>
              {html}
            </th>
          );
        })}
      </tr>
    </thead>
  );
}

function TableBody({ data }) {
  return (
    <tbody>
      {data.map((row, index) => {
        return (
          <tr className="border-b last:border-b-0" key={index}>
            {row.map((item, idx) => {
              const tableRow = ReactHtmlParser(item, {
                transform,
              });
              return (
                <td key={idx} className={classNames("p-4 md:p-5")}>
                  {tableRow}
                </td>
              );
            })}
          </tr>
        );
      })}
    </tbody>
  );
}

function Table({ data }) {
  const { content, withHeadings } = data;

  if (!content.length) {
    return null;
  }

  const tableBodyData = withHeadings ? content.slice(1) : content;

  return (
    <div className="md:grid md:gap-x-7 md:grid-cols-24 lg:gap-x-8">
      <div className="md:col-span-22">
        <div className="relative overflow-hidden">
          <div
            className={classNames(
              "w-full pb-2 overflow-x-auto custom-scrollbar bg-white",
              "border rounded-5 border-black-100",
            )}
          >
            <table className="break-words table-fixed w-149 sm:w-233 md:w-252 md:min-w-full">
              {withHeadings && <TableHeader data={content[0]} />}
              <TableBody data={tableBodyData} />
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export { Table };
