export const list = {
  visible: {
    opacity: 1,
    transition: {
      when: "beforeChildren",
      staggerChildren: 0.2,
    },
  },
  hidden: {
    opacity: 0,
    transition: {
      when: "afterChildren",
    },
  },
  exit: {
    transition: {
      staggerChildren: 0.1,
    },
  },
};

export const itemContainerVariants = {
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.2,
    },
  },
  hidden: {
    opacity: 1,
  },
  exit: {
    opacity: 0,
    y: -60,
    transition: {
      type: "tween",
    },
  },
};

export const item = {
  visible: { opacity: 1, y: 0 },
  hidden: { opacity: 0, y: 60 },
  exit: { opacity: 1 },
};

export const headerFade = {
  visible: { x: 0, opacity: 1 },
  hidden: { x: -40, opacity: 0 },
  exit: {
    y: -60,
    opacity: 0,
    transition: {
      type: "tween",
    },
  },
};

export const heightFadeVariants = {
  visible: { height: "auto", opacity: 1 },
  hidden: { height: 0, opacity: 0 },
  exit: { height: 0, opacity: 0 },
};

export const heightFadeTransition = { type: "tween" };
