import { ModuleWrapper } from "components/Wrappers/ModuleWrapper";
import { getContentWithLineBreaks } from "helpers/htmlHelper";
import { Carousel } from "./";
import { getCustomInitialProps } from "./getCustomInitialProps";

const Milestone = ({ customData: cardStates, module }) => {
  let {
    contentID: key,
    fields: { anchor, cards, description, showtabs: showTabs, title } = {},
  } = module ?? {};
  showTabs = showTabs === "true";
  if (!title && !description && !cards) return null;

  return [
    <ModuleWrapper
      id={anchor}
      psnid="psn_milestone"
      key={`milestone-key-${key}`}
      className="!mb-5 prose md:prose-md"
      testid="milestone-module"
    >
      {title && <h2 className="mb-5">{title}</h2>}
      {description && (
        <p className="mb-7">{getContentWithLineBreaks(description)}</p>
      )}
    </ModuleWrapper>,
    !!cards?.length && (
      <div
        className="mb-10 lg:mb-20"
        key={`milestone-swiper-wrapper-key-${key}`}
      >
        <Carousel cards={cards} cardStates={cardStates} showTabs={showTabs} />
      </div>
    ),
  ];
};

Milestone.getCustomInitialProps = getCustomInitialProps;

export { Milestone };
