import axios from "axios";
import { format, parse } from "date-fns";
import { HYDRO } from "helpers/constants";
import { paddLeadingZero } from "helpers/numberStringHelper";

const graphUrlSuffix = {
  [HYDRO.type.rainfall]: "-rainfall",
  [HYDRO.type.river]: "-river-flow",
  [HYDRO.type.lake]: "-lake-level",
  [HYDRO.type.spillFlow]: "",
};

export const useQueryConfig = {
  cacheTime: HYDRO.cacheTime,
  refetchOnWindowFocus: false,
  refetchOnmount: false,
  refetchOnReconnect: false,
  staleTime: HYDRO.staleTime,
  refetchInterval: HYDRO.cacheTime,
};

// convert 27/04/2022 4:30:00 p.m. => 27 Apr 2022 10:12am
export const parseDateTime = (dateTime) => {
  if (!dateTime) return "";

  const dateTimeSplit = dateTime.split(" "); // 2/06/2022

  // date
  const date = format(
    parse(dateTimeSplit[0], "d/LL/yyyy", new Date()),
    "d MMM yyyy",
  );

  // time is sometimes ommited in error from the feed
  if (dateTimeSplit.length === 1) {
    return `${date}`;
  }

  // time, ditch the seconds => "10:00:00"
  const time = format(parse(dateTimeSplit[1], "H:mm:ss", new Date()), "H:mm");

  // am or pm
  const period = dateTimeSplit[2].replace(/\./g, "");

  return `${date} <br />${time}${period}`;
};

export const parseUrl = (gifName, groupId) => {
  const sanitizedGifName = gifName
    ?.toLowerCase()
    .replace(/ /g, "-")
    .replace(/\./g, "");

  const suffix = graphUrlSuffix[groupId];
  let url = [HYDRO.detailPageUrlPath, sanitizedGifName, suffix].join("");

  // exception - remove the trailing '-rainfall' from 'moawhango-at-moawhango-rainfall-rainfall'
  url = url.replace(/-rainfall-rainfall/, "-rainfall");

  return url;
};

export const parseResponse = (res) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(res.data, "application/xml");
  const summaries = doc.querySelectorAll("Summary");
  const data = Array.from(summaries, (summary) => {
    // exception - remove the trailing '2' from 'Karikaringa2'
    const site = summary
      .querySelector("Site")
      .textContent.replace(/Karikaringa2/, "Karikaringa");

    const gifName = summary.querySelector("GifName").textContent;
    const groupId = `${
      summary.querySelector("GroupID").textContent
    }`.toLowerCase();
    return {
      site,
      hydroGraphUrl: parseUrl(gifName, groupId),
      lastHour: paddLeadingZero(summary.querySelector("LastHour").textContent),
      at: parseDateTime(summary.querySelector("At").textContent),
      day7Max: paddLeadingZero(summary.querySelector("Day7Max").textContent),
      day7DateOfMax: parseDateTime(
        summary.querySelector("Day7DateOfMax").textContent,
      ),
      gifName,
      groupId,
      region: `${summary.querySelector("Region").textContent}`.toLowerCase(),
    };
  });

  return data;
};

export const fetchReq = async () => {
  try {
    const req = axios.get(HYDRO.summaryUrl, {
      baseURL: process.env.NEXT_PUBLIC_BLOB_STORAGE_HOST_NAME,
    });
    const res = await req;
    return parseResponse(res);
  } catch (ex) {
    throw new Error(ex);
  }
};
