import { useState } from "react";
import { motion } from "framer-motion";
import { sortBy } from "lodash-es";
import { ModuleWrapper } from "components/Wrappers/ModuleWrapper";
import { getContentWithLineBreaks } from "helpers/htmlHelper";
import { FadeInBottomAnimation } from "./animations";
import { Carousel } from "./FactGoRoundHelper";

export const FactGoRound = ({ module }) => {
  const { fields } = module;
  const [isInView, setisInView] = useState(false);
  if (!fields?.factList) return null;

  const factList = sortBy(fields.factList, ["properties.itemOrder"]);
  const { anchor, description, title } = fields;

  return (
    <>
      <ModuleWrapper className="!mb-0" id={anchor} psnid="psn_fact_go_round">
        <FadeInBottomAnimation>
          <div className="prose md:prose-md">
            <h2 className="my-7">{title}</h2>
            <p>{getContentWithLineBreaks(description)}</p>
          </div>
        </FadeInBottomAnimation>
      </ModuleWrapper>
      {!!factList.length && (
        <motion.div
          viewport={{ once: true, amount: 0.4 }}
          whileInView={
            /* istanbul ignore next */
            () => {
              if (!isInView) {
                setisInView(true);
              }
            }
          }
        >
          {isInView && <Carousel factList={factList} />}
        </motion.div>
      )}
    </>
  );
};
