const defaultTheme = require("tailwindcss/defaultTheme");

const round = (num) =>
  num
    .toFixed(7)
    .replace(/(\.[0-9]+?)0+$/, "$1")
    .replace(/\.0$/, "");
const rem = (px) => `${round(px / 16)}rem`;

/** @type {import('tailwindcss').Config} */
module.exports = {
  content: ["./src/**/*.{js,jsx,ts,tsx}"],
  theme: {
    fontSize: {
      "3xs": [rem(10), { lineHeight: rem(20) }],
      "2xs": [rem(12), { lineHeight: rem(20) }],
      xs: [rem(14), { lineHeight: rem(20) }],
      sm: [rem(16), { lineHeight: rem(24) }],
      base: [rem(18), { lineHeight: rem(28) }],
      "base-primary": [rem(20), { lineHeight: rem(32) }],
      lg: [rem(24), { lineHeight: rem(36) }],
      xl: [rem(32), { lineHeight: rem(36) }],
      "2xl": [rem(44), { lineHeight: rem(52) }],
      "3xl": [rem(60), { lineHeight: rem(68) }],
      "4xl": [rem(80), { lineHeight: rem(96) }],

      "10/20": [rem(10), { lineHeight: rem(20) }],
      "12/16": [rem(12), { lineHeight: rem(16) }],
      "12/20": [rem(12), { lineHeight: rem(20) }],
      "13/16": [rem(13), { lineHeight: rem(16) }],
      "13/20": [rem(13), { lineHeight: rem(20) }],
      "14/16": [rem(14), { lineHeight: rem(16) }],
      "14/20": [rem(14), { lineHeight: rem(20) }],
      "14/22": [rem(14), { lineHeight: rem(22) }],
      "15/24": [rem(15), { lineHeight: rem(24) }],
      "16/24": [rem(16), { lineHeight: rem(24) }],
      "18/28": [rem(18), { lineHeight: rem(28) }],
      "18/30": [rem(18), { lineHeight: rem(30) }],
      "20/24": [rem(20), { lineHeight: rem(24) }],
      "20/32": [rem(20), { lineHeight: rem(32) }],
      "24/28": [rem(24), { lineHeight: rem(28) }],
      "24/36": [rem(24), { lineHeight: rem(36) }],
      "27/32": [rem(27), { lineHeight: rem(32) }],
      "32/36": [rem(32), { lineHeight: rem(36) }],
      "40/48": [rem(40), { lineHeight: rem(48) }],
      "44/52": [rem(44), { lineHeight: rem(52) }],
      "60/68": [rem(60), { lineHeight: rem(68) }],
      "80/96": [rem(80), { lineHeight: rem(96) }],
    },
    screens: {
      sm: "601px", // Tablet
      // => @media (min-width: 601px) { ... }

      md: "1068px", // Small desktop
      // => @media (min-width: 981px) { ... }

      lg: "1401px", // Large desktop
      // => @media (min-width: 1401px) { ... }

      xl: "1680px", // Extra-large desktop
      // => @media (min-width: 1680px) { ... }
    },
    extend: {
      keyframes: {
        "fade-in": {
          "0%": {
            opacity: "0",
          },
          "100%": {
            opacity: "1",
          },
        },
      },
      animation: {
        "fade-in": "fade-in 0.5s ease-out",
      },
      // 1rem = 16px
      backgroundImage: {
        "gradient-radial-to-tr":
          "radial-gradient(148.56% 105.98% at 0% 100%, var(--tw-gradient-stops))",
        "energy-gradient":
          "radial-gradient(148.56% 105.98% at 0% 100%, #ffcd00 0%,   #f58025 49.48%,  #f26526 100%)",
        "energy-bg-1": "url('/images/img-energy_brought_to_life.svg')",
        "curve-sm": "url('/images/img-curve_mb.svg')",
        "curve-md": "url('/images/img-curve_tb.svg')",
        "curve-lg": "url('/images/img-curve_sm-dt.svg')",
        "curve-xl": "url('/images/img-curve_lg-dt.svg')",
        "chevron-left": "url('/images/img-chevron-left.svg')",
        "chevron-right": "url('/images/img-chevron-right.svg')",
        "day-gradient":
          "radial-gradient(148.56% 105.98% at 0% 100%, #F26526 9.38%, #F58025 60.94%, rgba(255, 205, 0, 0.7) 100%), linear-gradient(0deg, #F58025, #F58025)",
        "glass-grey":
          "linear-gradient(109.63deg, rgba(236, 237, 239, 0.8) 0%, rgba(236, 237, 239, 0.2) 100%);",
        "glass-dark-grey":
          "linear-gradient(109.63deg, rgba(70, 73, 88, 0.8) 0%, rgba(70, 73, 88, 0.6) 100%);",
        "textoverlay-gradient":
          "linear-gradient(180deg, rgba(51, 51, 51, 0) 0%, rgba(51, 51, 51, 0.8) 100%);",
      },
      blur: {
        "4xl": "100px",
      },
      borderRadius: {
        1: rem(4),
        2: rem(8),
        2.5: rem(10),
        3: rem(12),
        4: rem(16),
        5: rem(20),
        6.5: rem(26),
        7: rem(28),
        7.5: rem(30),
        10: rem(40),
        15: rem(60),
        25: rem(100),
      },
      boxShadow: {
        100: "0px 0px 12px rgba(70, 73, 88, 0.1)",
        200: "0px 0px 20px rgba(70, 73, 88, 0.1)",
        300: "0px 20px 50px rgba(70, 73, 88, 0.3)",
        400: "0px 8px 28px rgba(70, 73, 88, 0.3)",
        500: "0px 8px 16px rgba(70, 73, 88, 0.3)",
        600: "0px 20px 50px rgba(70, 73, 88, 0.3)",
        700: "0px 0px 60px rgba(70, 73, 88, 0.5)",
        "4/14/15": "0px 4px 14px rgba(70, 73, 88, 0.15)",
        "6/30/20": "0px 6px 30px rgba(70, 73, 88, 0.2)",
        "6/30/40": "0px 6px 30px rgba(70, 73, 88, 0.4)",
        "12/30/20": "0px 12px 30px rgba(70, 73, 88, 0.2)",
        "12/60/40": "0px 12px 60px rgba(70, 73, 88, 0.4)",
        "12/60/60": "0px 12px 60px rgba(70, 73, 88, 0.6)",
        "12/80/60": "0px 12px 80px rgba(70, 73, 88, 0.6)",
        "12/80/100": "0px 12px 80px rgba(70, 73, 88, 1)",

        "glow-100": "0px 0px 20px rgba(238, 80, 13, 0.1)",
        "glow-200":
          "0px 8px 28px rgba(238, 80, 13, 0.35), 0px -8px 50px rgba(255, 230, 128, 0.2)",
        "glow-300":
          "0px 10px 30px rgba(238, 80, 13, 0.35), 0px -10px 30px rgba(255, 230, 128, 0.2)",
        "glow-400":
          "0px 20px 50px rgba(238, 80, 13, 0.35), 0px -12px 50px rgba(255, 230, 128, 0.2)",
        "glow-500":
          "0px 20px 50px rgba(238, 80, 13, 0.35), 0px -12px 50px rgba(255, 230, 128, 0.2)",

        "glow-0/20/9": "0px 0px 20px rgba(238, 80, 13, 0.09)",
        "glow-0/20/10": "0px 0px 20px rgba(238, 80, 13, 0.1)",
        "glow-6/30/40": "0px 6px 30px rgba(242, 109, 27, 0.4)",
        "glow-8/28/20":
          "0px 8px 28px rgba(238, 80, 13, 0.2), 0px -8px 28px rgba(255, 230, 128, 0.1)",
        "glow-10/60/35":
          "0px 10px 60px rgba(238, 80, 13, 0.35), 0px -10px 40px rgba(255, 230, 128, 0.2)",
        "glow-12/60/60": "0px 12px 60px rgba(242, 109, 27, 0.6)",
        "glow-12/80/80": "0px 12px 80px rgba(242, 109, 27, 0.8)",
        "glow-20/50/20":
          "0px 20px 50px rgba(238, 80, 13, 0.2), 0px -12px 50px rgba(255, 230, 128, 0.1)",
        "glow-20/90/35":
          "0px 20px 90px rgba(238, 80, 13, 0.35), 0px -12px 50px rgba(255, 230, 128, 0.2)",
        "inset-b": "inset 0px -1px 0px transparent",
        "white-glow": "0px 0px 0px 2px rgba(255, 255, 255, 0.4)",
        none: "none",
      },
      colors: {
        current: "currentColor",
        // primary
        orange: {
          primary: "#F58025",
          100: "#FDE6D3",
          200: "#FBCCA8",
          300: "#F9B37C",
          400: "#F79951",
          500: "#F58025", // same as primary
          600: "#F47821",
          700: "#F26D1B",
          800: "#F06316",
          900: "#EE500D",
        },
        // primary alt
        "warm-orange": {
          primary: "#F26526",
          100: "#FCE0D4",
          200: "#FAC1A8",
          300: "#F7A37D",
          400: "#F58451",
          500: "#F26526", // same as primary
          600: "#F05D22",
          700: "#EE531C",
          800: "#EC4917",
          900: "#E8370D",
        },
        yellow: {
          primary: "#FFCD00",
          100: "#FFF5CC",
          200: "#FFEB99",
          300: "#FFE166",
          400: "#FFD733",
          500: "#FFCD00", // same as primary
          600: "#FFC800",
          700: "#FFC100",
          800: "#FFBA00",
          900: "#FFAE00",
        },
        black: {
          primary: "#464958",
          50: "#ECEDEF",
          100: "#DADBDE",
          200: "#B5B6BC",
          300: "#90929B",
          400: "#6B6D79",
          500: "#464958", // same as primary
          600: "#3F4250",
          700: "#373947",
          800: "#2F313D",
          900: "#20212D",
        },
        // named "neutral-dark" in the design system
        dark: {
          50: "#F7F7F7",
          primary: "#333333",
          100: "#D6D6D6",
          200: "#ADADAD",
          300: "#858585",
          400: "#5C5C5C",
          500: "#333333", // same as primary
        },
        red: {
          primary: "#CC0000",
          100: "#F5CCCC",
          200: "#EB9999",
          300: "#E06666",
          400: "#D63333",
          500: "#CC0000", // same as primary
        },
        blue: {
          primary: "#00A9FF",
          100: "#cceeff",
          200: "#99ddff",
          300: "#66cbff",
          400: "#33baff",
          500: "#00A9FF", // same as primary
        },
        extra: {
          purple: "#9575CD",
          "neutral-light": "#F7F7F7",
          red: "#F15A5A",
          silver: "#CBD0D7",
        },
        green: {
          100: "#E4F4D5",
          200: "#56B971",
          300: "#49771E",
        },
      },
      fontFamily: {
        sans: ["Circular", ...defaultTheme.fontFamily.sans],
      },
      fontWeight: {
        "normal-plus": 450,
      },
      gradientColorStops: (theme) => ({
        ...theme("colors"),
        "transparent-bright-gray-20": "rgba(236, 237, 239, 0.2)", // black-50 with .2 transparency
        "transparent-bright-gray-80": "rgba(236, 237, 239, 0.8)", // black-50 with .8 transparency
        "transparent-white-20": "rgba(255, 255, 255, 0.2)",
        "transparent-white-40": "rgba(255, 255, 255, 0.4)",
        "transparent-white-60": "rgba(255, 255, 255, 0.6)",
        "transparent-white-80": "rgba(255, 255, 255, 0.8)",
      }),
      width: {
        // 1 = 4px
        37.5: rem(150),
        53: rem(212),
        63: rem(252),
        70: rem(280),
        73: rem(292),
        75: rem(300),
        83: rem(332),
        85: rem(340),
        93: rem(372),
        96: rem(384),
        100: rem(400),
        122: rem(488),
        133: rem(532),
        137: rem(548),
        149: rem(596),
        153: rem(612),
        161: rem(644),
        162: rem(648),
        200: rem(800),
        213: rem(852),
        233: rem(932),
        252: rem(1008),
        280: rem(1120),
        304: rem(1216),
        306: rem(1224),
        318: rem(1272),
        360: rem(1440),
      },
      height: {
        // 1 = 4px
        0.5: rem(2),
        37.5: rem(150),
        68: rem(272),
        75: rem(300),
        81: rem(324),
        88: rem(352),
        90: rem(360),
        93: rem(372),
        97: rem(388),
        106: rem(424),
        119: rem(476),
        123: rem(492),
        150: rem(600),
      },
      maxWidth: {
        // 1 = 4px
        37: rem(148),
        83: rem(332),
        85: rem(340),
        96: rem(384),
        110: rem(440),
        120: rem(480),
        122: rem(488),
        150: rem(600),
        153: rem(612),
        162: rem(648),
        175: rem(700),
        177: rem(708),
        200: rem(800),
        240: rem(960),
        318: rem(1272),
        400: rem(1600),
        410: rem(1640),
        450: rem(1800),
        470: rem(1880),
      },
      maxHeight: {
        "(screen-185px)": "calc(100vh - 185px)",
        75: rem(300),
      },
      minHeight: {
        // 1 = 4px
        2: rem(8),
        3: rem(12),
        9: rem(36),
        14: rem(56),
        17: rem(68),
        23: rem(92),
        42: rem(168),
        52: rem(208),
        56: rem(224),
        60: rem(240),
        83: rem(332),
        97: rem(388),
        105: rem(420),
        115: rem(460),
        125: rem(500),
        150: rem(600),
      },
      minWidth: {
        35: rem(140),
        37.5: rem(150),
        45: rem(180),
        75: rem(300),
        220: rem(880),
        236: rem(944),
      },
      gridTemplateColumns: {
        // Simple 24 column grid
        24: "repeat(24, minmax(0, 1fr))",
        "plan-cards": `repeat(3, minmax(${rem(300)}, 1fr))`,
      },
      gridColumn: {
        "span-8": "span 8 / span 8",
        "span-9": "span 9 / span 9",
        "span-11": "span 11 / span 11",
        "span-13": "span 13 / span 13",
        "span-14": "span 14 / span 14",
        "span-15": "span 15 / span 15",
        "span-16": "span 16 / span 16",
        "span-20": "span 20 / span 20",
        "span-22": "span 22 / span 22",
        "span-24": "span 24 / span 24",
      },
      gridColumnStart: {
        13: "13",
        14: "14",
        15: "15",
        16: "16",
        17: "17",
        18: "18",
        19: "19",
        20: "20",
        21: "21",
        22: "22",
        23: "23",
        24: "24",
      },
      gridColumnEnd: {
        13: "13",
        14: "14",
        15: "15",
        16: "16",
        17: "17",
        18: "18",
        19: "19",
        20: "20",
        21: "21",
        22: "22",
        23: "23",
        24: "24",
      },
      listStyleType: {
        roman: "lower-roman",
        alpha: "lower-alpha",
      },
      outline: {
        blue: "3px solid #00A9FF",
      },
      opacity: {
        15: ".15",
      },
      margin: {
        34: rem(136),
      },
      spacing: {
        1.5: rem(6),
        2.5: rem(10),
        4.5: rem(18),
        7.5: rem(30),
        8.5: rem(34),
        13: rem(52),
        15: rem(60),
        17: rem(68),
        18: rem(72),
        19: rem(76),
        21: rem(84),
        22: rem(88),
        23: rem(92),
        25: rem(100),
        26: rem(104),
        27: rem(108),
        28.5: rem(114),
        29: rem(116),
        30: rem(120),
        32: rem(128),
        34.5: rem(138),
        35: rem(140),
        37: rem(148),
        38: rem(152),
        41: rem(164),
        45: rem(180),
        46: rem(184),
        49: rem(196),
        49.5: rem(198),
        50: rem(200),
        53: rem(212),
        55: rem(220),
        57: rem(228),
        59: rem(236),
        65: rem(260),
        68: rem(272),
        70: rem(280),
        82.5: rem(330),
        83: rem(332),
        120: rem(480),
        122: rem(488),
        158: rem(632),
        174: rem(696),
        202: rem(808),
        210: rem(840),
        220: rem(880),
        265: rem(1060),
        308: rem(1232),
        420: rem(1680),
        470: rem(1880),
      },
      transitionProperty: {
        background: "background",
        height: "height",
        "width-and-opacity": "width, opacity",
        "transform-and-opacity": "transform, opacity",
      },
      zIndex: {
        1: "1",
        2: "2",
      },
    },
  },
  plugins: [],
};
