import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { getToken } from "helpers/apiHelper";
import { PROPERTY_ACCESS_REASONS } from "./constants";

const validateLpgOrderEndpoint = `${process.env.NEXT_PUBLIC_MULE_API_HOST_NAME}/v2/expr/cms/orders/lpg/validate`;
const placeLpgOrderEndpoint = `${process.env.NEXT_PUBLIC_MULE_API_HOST_NAME}/v2/expr/cms/orders/lpg`;

function createLpgRequestData(data, isValidateAPI = true) {
  const {
    name,
    customerNumber,
    cylinderQuantity,
    propertyAccessReasons,
    email,
    address,
    propertyAccessIssue,
    propertyAccessReasonOther,
    phone,
    propertyAccessReasonSecurityCode,
    cylinderType,
  } = data;

  const requestData = {
    numberOfBottles: Number(cylinderQuantity),
    addDogNote:
      propertyAccessReasons &&
      propertyAccessReasons.includes(PROPERTY_ACCESS_REASONS.DOG)
        ? "1"
        : "0",
    keySecurityCode: propertyAccessReasonSecurityCode || "0",
    accessIssues: propertyAccessIssue === "true" ? "1" : "0",
    extraComments: propertyAccessReasonOther,
    bottleSize: cylinderType,
    userName: name,
  };

  // request body for lpg order validate
  if (isValidateAPI) {
    return {
      order: {
        supplyAgreement: {
          id: `GENE-Gentrack-${customerNumber}`,
        },
        emailAddress: email,
        deliveryAddress: address,
        ...requestData,
      },
    };
  }

  // request body for lpg order
  return {
    order: {
      supplyAgreement: {
        id: `GENE-Gentrack-${customerNumber}`,
        contact: {
          deliveryAddress: address,
          communicationDetails: [
            {
              type: "MOBILE",
              value: phone,
            },
            {
              type: "EMAIL",
              value: email,
            },
          ],
        },
      },
      ...requestData,
    },
  };
}

export async function placeLpgOrder(data) {
  // Get access token for API calls
  const accessToken = await getToken();

  const headers = {
    "X-Application-ID": "CMS",
    "X-Channel-ID": "Browser",
    "X-Brand-ID": "GENE",
    "X-Transaction-ID": uuidv4(),
    Authorization: `Bearer ${accessToken}`,
  };

  // Validate lpg order
  await axios.post(validateLpgOrderEndpoint, createLpgRequestData(data), {
    headers,
  });

  // Place lpg order
  await axios.post(placeLpgOrderEndpoint, createLpgRequestData(data, false), {
    headers,
  });
}
