import classNames from "classnames";
import { InlineBlockEditor } from "components/BlockEditor";
import { CallToAction } from "components/CallToAction";
import { Image } from "components/Image";
import { getBlocks } from "helpers/blockEditorHelper";
import { VARIANT } from "helpers/constants";
import { SnippetItemFadeInAnimation } from "./animations";
import { CheckListContent } from "./CheckListContent";

function Snippet({ content, isFirstItem, isSnippetLeftAligned }) {
  const {
    fields: { title, description, cta1, icons, list, subtitle },
  } = content;
  const hasLink = cta1?.text && cta1?.href;
  const descriptionBlockContent = getBlocks(description);

  return (
    <div className="mb-10 last:mb-0" data-testid="videoCardSnippet">
      <SnippetItemFadeInAnimation isSnippetLeftAligned={isSnippetLeftAligned}>
        {icons && (
          <div
            className="mb-4 w-9 h-9 md:w-12 md:h-12 md:mb-5"
            data-testid="videoCardSnippetIcon"
          >
            <Image src={icons.url} alt={icons.label} width={60} height={60} />
          </div>
        )}

        {isFirstItem ? (
          <h2
            className={classNames(
              "text-27/32 font-bold text-warm-orange-primary mb-2",
              "md:text-32/36 md:mb-3",
            )}
            data-testid="h2VideoSnippetTitle"
          >
            {title}
          </h2>
        ) : (
          <h3
            className={classNames(
              "text-20/24 font-bold text-warm-orange-primary mb-2",
              "md:text-24/28 md:mb-3",
            )}
            data-testid="h3VideoSnippetTitle"
          >
            {title}
          </h3>
        )}
        {subtitle ? (
          <p
            className={classNames(
              "text-black-primary mb-2 font-medium",
              "md:mb-3",
              {
                "text-20/32 md:text-24/36": isFirstItem,
                "text-18/28 md:text-20/32": !isFirstItem,
              },
            )}
            data-testid="videoCardSnippetSubtitle"
          >
            {subtitle}
          </p>
        ) : null}

        <InlineBlockEditor
          data={descriptionBlockContent}
          testid="videoCardBlockEditor"
          className="prose-p:text-15/24 md:prose-p:text-18/28"
        />

        {list?.length ? <CheckListContent list={list} /> : null}
        {hasLink && (
          <div data-testid="videoCardCta" className="flex">
            <CallToAction
              className="!pl-0 !pb-0 mt-1 md:mt-2"
              data={cta1}
              dataGa={{
                content_tertiary: title,
                event: "cta_click",
                event_category: "cta",
                event_label: [cta1.text],
              }}
              variant={VARIANT.darkTransparent}
              testid="videoCardItemCta"
            />
          </div>
        )}
      </SnippetItemFadeInAnimation>
    </div>
  );
}

export { Snippet };
