import { createSelector } from "@reduxjs/toolkit";
import classNames from "classnames";
import { motion } from "framer-motion";
import { Alert } from "join-form/components/alert";
import { useSelector } from "react-redux";
import { selectAppState, selectResidentialOffers } from "reducers/selector";
import { isElectricitySelected as checkElectricitySelection } from "helpers/productHelper";

const animationVariants = {
  initial: { x: -40, opacity: 0 },
  animate: { x: 0, opacity: 1 },
  exit: {
    y: -60,
    opacity: 0,
    transition: {
      type: "tween",
    },
  },
};

const stateSelector = createSelector(
  [selectAppState, selectResidentialOffers],
  (appState, residentialOffers) => {
    const { hasEvPlan, smartMeter, evPlan } =
      residentialOffers.electricityOffer;

    if (!hasEvPlan) {
      return {
        copy: "Oh no, unfortunately the network you're on doesn't cover our Energy EV plan. Please select another plan type.",
      };
    }

    const isElectricitySelected = checkElectricitySelection(appState.products);

    if (hasEvPlan && !isElectricitySelected) {
      return {
        copy: "To go on the Energy EV plan, Electricity must be selected as one of your energy options.",
      };
    }

    const hasNoEvPlanTariffsForHighUsage =
      evPlan.highUsage.tariffList.length === 0;
    const hasNoEvPlanTariffsForLowUsage =
      evPlan.lowUsage.tariffList.length === 0;

    const hasNoEvPlanTariffs =
      hasNoEvPlanTariffsForHighUsage && hasNoEvPlanTariffsForLowUsage;

    if (hasEvPlan && hasNoEvPlanTariffs) {
      return {
        copy: `
        At no cost to you, going on the Energy EV plan may require a meter upgrade at your address.
        Once you're signed up, we'll contact you if a new smart meter needs to be installed.`,
      };
    }

    if (hasEvPlan && !smartMeter) {
      return {
        copy: `
        At no cost to you, going on the Energy EV plan requires a meter upgrade at your address.
        Once you're signed-up we'll contact you to arrange a time to install your new smart meter.`,
      };
    }

    return { copy: null };
  },
);

function EvPlanAlert() {
  const { copy } = useSelector(stateSelector);

  return (
    copy && (
      <motion.div
        initial="initial"
        animate="animate"
        exit="exit"
        variants={animationVariants}
        data-testid="evPlanAlert"
        className={classNames("max-w-full mx-auto w-265", "mb-10")}
      >
        <Alert
          fields={{
            copy,
          }}
        />
      </motion.div>
    )
  );
}

export { EvPlanAlert };
