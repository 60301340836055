import {
  CUSTOMER_TYPE_RESIDENTIAL,
  DUAL_FUEL_ELEC_BONUS_CREDIT_PLAN_CODE,
  PLAN_TYPE_BASIC,
  PRODUCT_ELECTRICITY,
  PRODUCT_GAS,
  PRODUCT_LPG,
  PROPERTY_SITUATION_MOVE,
  RESIDENTIAL_PLAN_TERM_FIXED12,
} from "helpers/constants";
import { getFullAddressFromFinder } from "helpers/addressHelper";
import { getBusinessName } from "helpers/businessDetailsHelper";
import { getJoiningCampaignsForResidential } from "helpers/joiningCreditHelper";
import { createTicket, updateTicket } from "join-form/services/zendeskService";
import { filter, find } from "lodash";
import { CREATE_ZENDESK_TICKET, SET_UTM_VALUES } from "./zendeskActionTypes";

const generateZendeskCreateAction = (payload) => ({
  type: CREATE_ZENDESK_TICKET,
  payload,
});

const createCampaignCodes = ({ selectedProducts, planType, termData }) => {
  const selectedPlanType = planType || PLAN_TYPE_BASIC;

  const selectedPlanTermConfig =
    find(termData, ({ isSelected }) => isSelected) || {};
  const selectedPlanTerm =
    selectedPlanTermConfig.codeName || RESIDENTIAL_PLAN_TERM_FIXED12;

  const campaignCodes =
    getJoiningCampaignsForResidential({
      products: selectedProducts,
      plan: selectedPlanType,
      term: selectedPlanTerm,
    }) || [];
  const campaignElecSelected = filter(
    campaignCodes,
    ({ planProduct }) => planProduct === PRODUCT_ELECTRICITY,
  );
  const campaignGasSelected = filter(
    campaignCodes,
    ({ planProduct }) => planProduct === PRODUCT_GAS,
  );
  const campaignLpgSelected = filter(
    campaignCodes,
    ({ planProduct }) => planProduct === PRODUCT_LPG,
  );
  const campaignDualFuelSelected = filter(
    campaignCodes,
    ({ planCode }) => planCode === DUAL_FUEL_ELEC_BONUS_CREDIT_PLAN_CODE,
  );
  return {
    campaignElectricity:
      campaignElecSelected.length > 0 ? campaignElecSelected[0].planCode : "",
    campaignGas:
      campaignGasSelected.length > 0 ? campaignGasSelected[0].planCode : "",
    campaignLpg:
      campaignLpgSelected.length > 0 ? campaignLpgSelected[0].planCode : "",
    campaignDualFuel:
      campaignDualFuelSelected.length > 0
        ? campaignDualFuelSelected[0].planCode
        : "",
  };
};

function createTicketPayload(state) {
  const {
    appPropertyState,
    appState: { customerType, products, planType },
    appUserState: { firstName, lastName, phone, email, title },
    businessState,
    residentialPlanConfig: { termData },
    zendeskState: { ticketId, utm },
    addressState: { addressDetails },
    icp: { elecRegistryAddress },
  } = state;

  const selectedProducts = products.filter((product) => product.isSelected);
  const energyServices = selectedProducts
    .map((product) => product.name)
    .join(",");

  const isResidential = customerType === CUSTOMER_TYPE_RESIDENTIAL;

  let campaignCodes = {};

  if (isResidential) {
    campaignCodes = createCampaignCodes({
      selectedProducts,
      planType,
      termData,
    });
  }

  const promoCode = isResidential ? "npa-signup" : "sme-signup";

  const businessName = isResidential ? "" : getBusinessName(businessState);

  const address = addressDetails
    ? getFullAddressFromFinder(addressDetails)
    : elecRegistryAddress.fullAddress;

  let situation = "";

  if (appPropertyState.situation) {
    const isMoving = appPropertyState.situation === PROPERTY_SITUATION_MOVE;
    situation = isMoving ? "moving" : "switching";
  }

  return {
    ticketId,
    title,
    lastName,
    firstName,
    phone,
    email,
    address,
    situation,
    promoCode,
    energyServices,
    businessName,
    customerType,
    ...utm,
    ...campaignCodes,
  };
}

export function createZendeskTicket() {
  return (dispatch, getState) => {
    const state = getState();

    const ticket = createTicketPayload(state);

    createTicket(ticket).then((data) =>
      dispatch(generateZendeskCreateAction(data)),
    );
  };
}

export function updateZendeskTicket() {
  return (_dispatch, getState) => {
    const state = getState();

    const ticket = createTicketPayload(state);

    updateTicket(ticket);
  };
}

export const setUtmValues = (payload) => ({
  type: SET_UTM_VALUES,
  payload,
});
