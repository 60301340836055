import { createSelector } from "@reduxjs/toolkit";
import classNames from "classnames";
import { motion } from "framer-motion";
import { useSelector } from "react-redux";
import { selectAppState, selectIcpState } from "reducers/selector";

// selectors
const selectFuelTypeHeaderCopy = createSelector(
  [selectAppState, selectIcpState],
  (appState, icpState) => {    
    const { gasIcp } = icpState;
    const showDualFuelCopy = gasIcp !== '';
    return { showDualFuelCopy };
  },
);

export function FuelTypeIntroHeader({ module }) {
  const { title, subtitle, dualFuelSubtitle } = module.fields;
  const { showDualFuelCopy } = useSelector(selectFuelTypeHeaderCopy);

  return (
    <div className="max-w-full px-4 mx-auto w-158 md:w-210">
      <motion.div className="my-10 md:my-15">
        <h1
          className={classNames(
            "mb-4 last:mb-0",
            "text-32/36 md:text-44/52",
            "font-bold text-black-primary",
          )}
        >
          {title}
        </h1>
        <p>
          {" "}
          {showDualFuelCopy && (
            <span data-testid="dualFuelSelectionCopy">{dualFuelSubtitle} </span>
          )}
          {subtitle}
        </p>
      </motion.div>
    </div>
  );
}
