import axios from "axios";

const requestConfig = {
  auth: {
    username: process.env.NEXT_PUBLIC_AUTH_USER_NAME,
    password: process.env.NEXT_PUBLIC_AUTH_PASSWORD,
  },
};

function getBankDetailsFromAccountNumber(accountNumber) {
  const [bankNumber, branchNumber] = accountNumber.split("-");
  return [bankNumber, branchNumber];
}

async function findBankName(bankAccountNumber) {
  try {
    const [bankNumber, branchNumber] =
      getBankDetailsFromAccountNumber(bankAccountNumber);

    const requestData = { bankNumber, branchNumber };

    const { data } = await axios.post(
      "/api/npa/findBankName",
      requestData,
      requestConfig,
    );

    return [data.bankName, null];
  } catch (ex) {
    return [null, ex];
  }
}

export { findBankName, getBankDetailsFromAccountNumber };
