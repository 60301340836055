import classNames from "classnames";

function FadeIn({ isVisible, children }) {
  return (
    <div
      className={classNames("opacity-0 transition-opacity duration-150", {
        "opacity-100": isVisible,
      })}
    >
      {children}
    </div>
  );
}

export { FadeIn };
