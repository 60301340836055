import React from "react";
import { zip } from "lodash-es";
import {
  ArticleCard,
  ArticleCardContent,
  ArticleCardFooter,
  ArticleCardHeader,
  ArticleCardImage,
  ArticleCardTagList,
} from "components/ArticleCard";
import { Link } from "components/Link";
import { APP_ROUTES } from "helpers/constants";
import { formatDate } from "helpers/dateHelper";
import { FadeInBottomAnimation } from "./animations";

function ArticleItem({ className, data, testid, horizontal }) {
  const {
    image,
    slug,
    title,
    briefdescription,
    tag,
    tag_TextField,
    date,
    topic_TextField,
    topic_ValueField,
  } = data.fields;

  const { referenceName } = data.properties;

  const baseUrl = APP_ROUTES[referenceName] ?? APP_ROUTES.news;

  const url = `${baseUrl}/${slug}`;

  const tagText = tag?.fields?.title || tag_TextField;

  const topicTextList = topic_TextField?.split(",");
  const topicIdList = topic_ValueField?.split(",");
  const topicList = zip(topicIdList, topicTextList);

  const topicLinks = topicList.map(([topicId, topicName]) => {
    if (!topicId || !topicName) {
      return null;
    }
    return (
      <Link key={topicId} href={{ pathname: baseUrl, query: { topicId } }}>
        <a>{topicName}</a>
      </Link>
    );
  });

  return (
    <FadeInBottomAnimation>
      <ArticleCard
        testid={testid}
        key={data.contentID}
        className={className}
        horizontal={horizontal}
      >
        {image && (
          <ArticleCardImage
            data={image}
            url={url}
            testid="articleItemImageLink"
            horizontal={horizontal}
          />
        )}
        <ArticleCardContent>
          <ArticleCardTagList
            items={[tagText, formatDate(date)]}
            testid="articleItemHeaderTagList"
          />
          <ArticleCardHeader url={url}>{title}</ArticleCardHeader>
          <p className="text-14/20 md:text-16/24">{briefdescription}</p>
          <ArticleCardFooter>
            <ArticleCardTagList
              testid="articleItemFooterTagList"
              items={topicLinks}
              textColor="text-orange-primary"
            />
          </ArticleCardFooter>
        </ArticleCardContent>
      </ArticleCard>
    </FadeInBottomAnimation>
  );
}

export { ArticleItem };
