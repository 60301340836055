import {
  SET_ADDRESS_DETAILS,
  SET_ADDRESS_ISLAND_STATUS,
  SET_ADDRESS_TYPE,
  SET_MANUAL_ADDRESS_DETAILS,
  SET_REGISTRY_ADDRESS_DETAILS,
  UNSET_ADDRESS_DETAILS,
} from "./addressStateActionTypes";
import {
  ADDRESS_TYPE_ADDRESS_FINDER,
  ADDRESS_TYPE_MANUAL,
  ADDRESS_TYPE_REGISTRY,
} from "helpers/constants";

export function setAddressType(addressType) {
  return { type: SET_ADDRESS_TYPE, payload: addressType };
}

export function setAddressDetailsFromFinder(payload) {
  return { type: SET_ADDRESS_DETAILS, payload };
}

export function setRegistryAddressDetails(payload) {
  return { type: SET_REGISTRY_ADDRESS_DETAILS, payload };
}

export function setManualAddressDetails(payload) {
  return { type: SET_MANUAL_ADDRESS_DETAILS, payload };
}

export function unsetAddressDetails(payload) {
  return { type: UNSET_ADDRESS_DETAILS, payload };
}

export function setAddressIslandStatus(payload) {
  return { type: SET_ADDRESS_ISLAND_STATUS, payload };
}

export function setAddressDetails(payload) {
  return (dispatch) => {
    dispatch(setAddressType(ADDRESS_TYPE_ADDRESS_FINDER));
    dispatch(setAddressDetailsFromFinder(payload));
  };
}

export function setRegistryAddress(payload) {
  return (dispatch) => {
    dispatch(setAddressType(ADDRESS_TYPE_REGISTRY));
    dispatch(setRegistryAddressDetails(payload));
  };
}

export function setManualAddress(payload) {
  return (dispatch) => {
    dispatch(setAddressType(ADDRESS_TYPE_MANUAL));
    dispatch(setManualAddressDetails(payload));
  };
}
