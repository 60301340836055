import resolveConfig from "tailwindcss/resolveConfig";
import content from "../tailwind-content.config";
import theme from "../tailwind-theme.config.js";

const fullConfig = resolveConfig({
  content,
  theme,
});

export { fullConfig as tailWindConfig };
