import classNames from "classnames";
import { CallToAction } from "components/CallToAction";
import { VARIANT } from "helpers/constants";
import { getContentWithLineBreaks } from "helpers/htmlHelper";
import { FadeInBottomAnimation, FadeInTopAnimation } from "./animations";
import { HeaderImage } from "./HeaderImage";

function HeaderLevelTwoProduct({ module }) {
  const { fields } = module;
  const hasCtas = fields.cta1 || fields.cta2;
  return (
    <div
      data-psnid="psn_header_level_two_product"
      className={classNames(
        "relative mx-auto mb-20",
        "first:pt-21",
        "sm:mb-20",
        "md:first:pt-37",
        "lg:first:pt-45",
      )}
    >
      <div
        className={classNames(
          "absolute inset-x-0 top-0 bottom-0",
          "bg-day-gradient",
          "sm:bottom-15",
          "md:bottom-0",
        )}
      >
        <div
          className={classNames(
            "absolute inset-0 translate-y-px",
            "bg-bottom bg-no-repeat bg-auto",
            "bg-curve-sm sm:bg-curve-md md:bg-curve-lg lg:bg-curve-xl",
          )}
        />
      </div>
      <div
        className={classNames(
          "relative mx-auto px-4",
          "sm:px-15",
          "md:px-17 md:min-h-115",
          "lg:px-23 xl:px-46",
        )}
      >
        <div className="sm:mb-10 md:mb-0">
          <div
            className={classNames("pt-7", "md:pt-10 md:pb-30", "lg:pb-49.5")}
          >
            <div
              data-testid="headerContent"
              className="sm:max-w-162 md:max-w-175"
            >
              <FadeInTopAnimation>
                <h1
                  className={classNames(
                    "mb-3 md:mb-5",
                    "font-bold text-white text-32/36 md:text-44/52",
                  )}
                >
                  {fields.title}
                </h1>
                <p
                  data-testid="headerContentDescription"
                  className="font-normal text-white text-18/28 md:text-20/32"
                >
                  {getContentWithLineBreaks(fields.description)}
                </p>
              </FadeInTopAnimation>
              {hasCtas ? (
                <div
                  data-testid="ctaSection"
                  className={classNames("flex flex-wrap mt-8 md:mt-10 gap-5")}
                >
                  <FadeInBottomAnimation isPrimary>
                    <CallToAction
                      data={fields.cta1}
                      dataGa={{
                        content_tertiary: fields.title,
                        event: "cta_click",
                        event_category: "cta",
                        event_label: [fields.cta1?.text],
                      }}
                      testid="primaryCta"
                      variant={VARIANT.light}
                    />
                  </FadeInBottomAnimation>
                  <FadeInBottomAnimation>
                    <CallToAction
                      data={fields.cta2}
                      dataGa={{
                        content_tertiary: fields.title,
                        event: "cta_click",
                        event_category: "cta",
                        event_label: [fields.cta2?.text],
                      }}
                      testid="secondaryCta"
                      variant={VARIANT.light}
                      outline
                    />
                  </FadeInBottomAnimation>
                </div>
              ) : null}
            </div>
          </div>
        </div>
        {fields.image ? <HeaderImage image={fields.image} /> : null}
      </div>
    </div>
  );
}

export { HeaderLevelTwoProduct };
