import { compact, sortBy, take } from "lodash-es";
import { ArticleCarouselSection } from "components/ArticleCarouselSection";
import { LatestDocumentItem } from "page-modules/LatestDocuments/LatestDocumentItem";

function RelatedDocuments({ module }) {
  const { title, cta, relatedDocuments: relatedDocumentsGroup } = module.fields;

  if (!relatedDocumentsGroup) {
    return null;
  }

  const sortedRelatedDocumentsGroup = sortBy(relatedDocumentsGroup, [
    "properties.itemOrder",
  ]);

  const relatedDocuments = sortedRelatedDocumentsGroup
    .map(({ fields }) => fields.relatedDocuments)
    .flat();

  const topFourRelatedDocuments = take(compact(relatedDocuments), 4);

  return topFourRelatedDocuments.length ? (
    <ArticleCarouselSection title={title} cta={cta} testid="relatedDocuments">
      {topFourRelatedDocuments.map((item) => (
        <LatestDocumentItem key={item.contentID} data={item} />
      ))}
    </ArticleCarouselSection>
  ) : null;
}

export { RelatedDocuments };
