import React from "react";
import classNames from "classnames";
import { compact } from "lodash-es";

function ArticleCardTagList({
  items,
  textColor = "text-black-primary",
  testid,
}) {
  const filteredItems = compact(items);

  if (!filteredItems.length) {
    return null;
  }

  return (
    <ul
      data-testid={testid}
      className={classNames("flex gap-x-1 text-12/20 flex-wrap", textColor)}
    >
      {filteredItems.map((item, index) => {
        const isFirst = index === 0;
        return (
          <React.Fragment key={index}>
            {!isFirst && <li aria-hidden>&bull;</li>}
            <li>{item}</li>
          </React.Fragment>
        );
      })}
    </ul>
  );
}

export { ArticleCardTagList };
