import classNames from "classnames";
import Icon from "join-form/components/icon";
import styles from "./page-sidebar-partial.module.scss";

function createButtonText({
  termDiscount,
  multiConnectDiscount,
  paymentMethodDiscount,
} = {}) {
  const discountPercentage =
    termDiscount + multiConnectDiscount + paymentMethodDiscount;

  const discountExists = discountPercentage > 0;

  return discountExists ? `${discountPercentage}% discount` : "";
}

export default function EnergyPlanButton(props) {
  const {
    onClick,
    termItem: { discountPercentage: termDiscount },
    multiConnectDiscount,
    paymentMethodDiscount,
  } = props;

  const text = createButtonText({
    termDiscount,
    multiConnectDiscount,
    paymentMethodDiscount,
  });

  return (
    <button className={styles.header_button} onClick={onClick}>
      <span className="flex items-center">
        <span
          className={classNames(
            "flex items-center justify-center rounded-full mr-3",
            "bg-orange-primary text-white",
          )}
        >
          <Icon name="ElectricityWhite" size="24" />
        </span>
        <span className={styles.header_button_text_container}>
          <span className={styles.header_button_text_line1}>
            Your energy plan & rates
          </span>
          <span
            className={styles.header_button_text_line2}
            data-testid="mobileHeaderText"
          >
            {text}
          </span>
        </span>
      </span>
      <span className={styles.header_button_icon_container}>
        <Icon name="ChevronDown" size="18" />
      </span>
    </button>
  );
}
