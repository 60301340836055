import classNames from "classnames";

function PlanSelectTab({ isSelected, onClick, children }) {
  return (
    <button
      className={classNames(
        "py-2 pr-4 border-b-2 transition text-14/20",
        isSelected ? "border-b-black-primary" : "border-b-transparent",
      )}
      onClick={onClick}
    >
      {children}
    </button>
  );
}

export { PlanSelectTab };
