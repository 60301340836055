import classNames from "classnames";
import Button from "join-form/components/button";
import ReactModal from "react-modal";

export default function Modal(props) {
  const {
    isOpen,
    onRequestClose,
    title,
    buttonText = "Okay, got it",
    children,
  } = props;

  return (
    <ReactModal
      onRequestClose={onRequestClose} // onModalClose
      closeTimeoutMS={200}
      className={classNames(
        "flex flex-col w-162 bg-white text-black-primary",
        "my-0 mx-auto py-10 px-4 md:p-15",
        "rounded-7 md:rounded-10",
      )}
      overlayClassName={classNames(
        "flex items-start",
        "fixed top-0 left-0 right-0 bottom-0 overflow-auto",
        "bg-black-primary/70 z-30",
        "pt-10 md:pt-20 px-4 pb-4",
      )}
      isOpen={isOpen} // modalIsVisible
    >
      <div data-testid="modal">
        <h3
          className={classNames(
            "text-center text-27/32 md:text-32/36 font-bold text-dark-primary",
            "mt-0 mb-5",
          )}
        >
          {title}
        </h3>
        <div className="text-center text-15/24 md:text-18/28 mb-5">
          {children}
        </div>
        <div className="flex justify-center">
          <Button
            buttonType="secondaryFill"
            data-testid="modalCloseButton"
            onClick={onRequestClose} // onModalClose
          >
            {buttonText}
          </Button>
        </div>
      </div>
    </ReactModal>
  );
}
