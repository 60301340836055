import React from "react";
import classNames from "classnames";
import { useScreenSize } from "hooks";
import Script from "next/script";
import { useIntersection } from "react-use";
import { Image } from "components/Image";
import { Link } from "components/Link";
import { useHeadroomInstance } from "providers/Headroom";
import { selectHomeNav, useNavigation } from "providers/Navigation";

function SiteFooter(props) {
  const {
    globalData: {
      footer: { content },
    },
    isPreview,
  } = props;

  const { dispatch } = useNavigation();

  const shouldPrefetch = isPreview ? false : undefined;

  const headroomInstance = useHeadroomInstance();
  const footerRef = React.useRef();
  const [mounted, setMounted] = React.useState(false);

  const footerIntersection = useIntersection(footerRef, {
    root: null,
    threshold: 0.05,
  });

  React.useEffect(() => {
    headroomInstance.unpin(footerIntersection?.isIntersecting);
    headroomInstance.freeze(footerIntersection?.isIntersecting);
  }, [headroomInstance, footerIntersection]);

  React.useEffect(() => {
    setMounted(true);
  }, []);

  const { isSm, isMd } = useScreenSize();

  const isMedium = mounted && isSm;

  const isLarge = mounted && isMd;

  const secondaryItemsToDisplay = [...content.secondaryLinks];

  if (isMedium) {
    secondaryItemsToDisplay.push({
      text: content.copyrightMessage,
      type: "message",
    });
  }

  function onHomeNavClick() {
    selectHomeNav({ dispatch });
  }

  return (
    <div
      ref={footerRef}
      className={classNames(
        "relative",
        "flex flex-col",
        "items-center md:items-start",
        "px-4 py-10 sm:px-15 md:pb-20 md:px-17 lg:px-23 xl:px-46",
        "bg-black-50",
        "rounded-t-7 md:rounded-t-10",
      )}
    >
      <div
        className={classNames(
          "flex items-center md:justify-between",
          "md:w-full",
          "mt-5 mb-10 sm:mt-10 md:mb-15",
        )}
      >
        <Link href={content.logoLink} prefetch={shouldPrefetch}>
          <a
            className="w-55 md:w-82.5"
            target={content.logoLinkTarget}
            onClick={onHomeNavClick}
            data-testid="genesisLogo"
          >
            <Image
              src={content.logoUrl}
              alt={content.logoAltText || "Genesis Energy"}
              width={330}
              height={120}
              quality={100}
            />
          </a>
        </Link>
        {content.trustedBrandsLogoUrl ? (
          <div
            className="hidden md:block"
            data-testid="trustedBrandsLogoDesktop"
          >
            <Image
              src={content.trustedBrandsLogoUrl}
              alt={content.trustedBrandsLogoAltText || "Trusted Brands"}
              width={302.66}
              height={80}
              quality={100}
            />
          </div>
        ) : null}
      </div>
      <div className="flex flex-col items-center md:items-start lg:flex-row lg:justify-between lg:w-full">
        <div
          className="flex flex-col items-center sm:flex-row"
          data-testid="primaryLinks"
        >
          {content.primaryLinks.map((item) => (
            <Link href={item.link} key={item.text} prefetch={shouldPrefetch}>
              <a
                className="mb-4 mr-0 font-medium text-black-primary text-15/24 sm:mr-4 md:mb-5 md:mr-5 md:text-18/28 lg:mb-4"
                target={item.target}
                data-ga={JSON.stringify({
                  content_tertiary: "footer menu",
                  event_label: [item.text],
                  event_category: "footer menu",
                })}
              >
                {item.text}
              </a>
            </Link>
          ))}
        </div>

        {!isLarge && <div className="mt-6 my-10 w-32 h-0.5 bg-black-100" />}
        <div
          className="flex flex-row items-center mb-3"
          data-testid="secondaryLinks"
        >
          {secondaryItemsToDisplay.map((item, index) => {
            return (
              <React.Fragment key={item.text}>
                {item.type === "message" ? (
                  <span
                    className="text-black-primary text-14/20 md:text-16/24"
                    data-testid="copyRightMessageSecondaryItem"
                  >
                    {item.text}
                  </span>
                ) : (
                  <Link href={item.link} prefetch={shouldPrefetch}>
                    <a
                      className="mr-2 text-black-primary text-14/20 md:text-16/24 lg:mr-3"
                      target={item.target}
                      data-ga={JSON.stringify({
                        content_tertiary: "footer menu",
                        event_label: [item.text],
                        event_category: "footer menu",
                      })}
                    >
                      {item.text}
                    </a>
                  </Link>
                )}
                {index !== secondaryItemsToDisplay.length - 1 && (
                  <span className="mr-2 text-black-200 text-14/20 md:text-16/24 lg:mr-3">
                    •
                  </span>
                )}
              </React.Fragment>
            );
          })}
        </div>
      </div>
      {isLarge && (
        <div
          className="mt-6 my-10 w-full h-0.5 bg-black-100"
          data-testid="separatorFullWidth"
        />
      )}
      {!isMedium ? (
        <div
          className="mb-10 text-xs text-black-primary"
          data-testid="copyRightMessage"
        >
          {content.copyrightMessage}
        </div>
      ) : null}
      <div className="flex flex-col items-center md:flex-row md:justify-between md:w-full">
        <div className="flex flex-col items-center sm:flex-row sm:mt-7 md:mt-0">
          {content.trustedBrandsLogoUrl ? (
            <div
              className="mb-10 sm:mr-10 md:hidden"
              data-testid="trustedBrandsLogoMobile"
            >
              <Image
                src={content.trustedBrandsLogoUrl}
                alt={content.trustedBrandsLogoAltText || "Trusted Brands"}
                width={211.86}
                height={56}
                quality={100}
              />
            </div>
          ) : null}
          <Link
            href={content.utilitiesDisputeLogoLink}
            prefetch={shouldPrefetch}
          >
            <a
              target={content.utilitiesDisputeLogoLinkTarget}
              className="w-28.5 mb-10 h-9 sm:mr-10 md:w-38 md:h-12"
              data-testid="utilitiesDispute"
              data-ga={JSON.stringify({
                content_tertiary: "footer menu",
                event_label: ["utilities dispute"],
                event_category: "footer menu",
              })}
            >
              <Image
                src={content.utilitiesDisputeLogoUrl}
                alt={content.utilitiesDisputeLogoAltText || "Utilities Dispute"}
                width={152}
                height={48}
                quality={100}
              />
            </a>
          </Link>
          <Link href={content.powerSwitchLogoLink} prefetch={shouldPrefetch}>
            <a
              target={content.powerSwitchLogoLinkTarget}
              className="w-28.5 mb-10 h-9 md:w-38 md:h-12"
              data-testid="powerSwitch"
              data-ga={JSON.stringify({
                content_tertiary: "footer menu",
                event_label: ["power switch"],
                event_category: "footer menu",
              })}
            >
              <Image
                src={content.powerSwitchLogoUrl}
                alt={content.powerSwitchLogoAltText || "Power Switch"}
                width={152}
                height={48}
                quality={100}
              />
            </a>
          </Link>
        </div>
        <div
          className="flex flex-row items-center mb-5 sm:mb-10 md:items-end"
          data-testid="socialIcons"
        >
          {content.socialIcons.map((item) => (
            <Link href={item.link} key={item.link} prefetch={shouldPrefetch}>
              <a
                target={item.target}
                className="flex items-center justify-center w-10 h-10 mr-3 border-2 rounded-full border-black-100 hover:border-dark-primary md:mr-5 md:w-12 md:h-12"
                data-ga={JSON.stringify({
                  content_tertiary: "footer menu",
                  event_label: [item.altText.replace(/Genesis Energy /i, "")],
                  event_category: "footer menu",
                })}
              >
                <Image
                  src={item.image}
                  alt={item.altText || "Social Media"}
                  width={isLarge ? 28 : 24}
                  height={isLarge ? 28 : 24}
                  quality={100}
                />
              </a>
            </Link>
          ))}
          <a
            href="#"
            id="shielded-logo"
            className="w-10 h-10 border-2 rounded-full border-black-100 hover:border-dark-primary md:w-12 md:h-12"
          >
            <Image
              src="https://shielded.co.nz/img/custom-logo.png"
              alt="shielded"
              width={50}
              height={50}
            />
          </a>
          <Script
            data-testid="shieldedScript"
            src="https://staticcdn.co.nz/embed/embed.js"
            onLoad={() => {
              const frameName = new ds07o6pcmkorn({
                openElementId: "#shielded-logo",
                modalID: "modal",
              });
              frameName.init();
            }}
          ></Script>
        </div>
      </div>
    </div>
  );
}

export { SiteFooter };
