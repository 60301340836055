import { useEffect, useState } from "react";
import { createSelector } from "@reduxjs/toolkit";
import {
  setAddressDetails,
  setAddressIslandStatus,
  unsetAddressDetails,
} from "actions/addressState/addressStateActions";
import {
  setNaturalGasAvailability,
} from "actions/appState/appStateActions";
import { setSignupForAdditionalPropertiesOrIcps } from "actions/appUserStateActions";
import { setBusinessOffers } from "actions/businessOfferActions";
import {
  setCallbackReason,
  setCallbackReferrerUrl,
  unsetCallbackReferrerUrl,
} from "actions/callbackPageActions";
import { setIcpDetails, unsetIcpDetails } from "actions/icpState/icpActions";
import { AnimatePresence, motion } from "framer-motion";
import { useRouter } from "next/router";
import { useDispatch, useSelector } from "react-redux";
import {
  AddressAutoSuggest,
  AddressDetails,
  NoResults,
  SuggestionsFooterRequestCallback,
} from "components/AddressAutoSuggest";
import { InlineBlockEditor } from "components/BlockEditor";
import { ButtonCheckboxSelection } from "components/ButtonCheckboxSelection";
import { Feedback, feedbackStyles } from "components/Feedback";
import { Icon } from "components/Icon";
import { Link } from "components/Link";
import { Modal } from "components/Modal";
import { StepProgressionButton } from "components/StepProgressionButton";
import {
  getFullAddressFromFinder,
  isAddressInNorthIsland,
} from "helpers/addressHelper";
import {
  appBusinessRoutes,
  CALLBACK_REASON_MULTI_CONNECT,
  CALLBACK_REASON_UNSPECIFIED,
} from "helpers/constants";
import { getPageData } from "helpers/getPageData";
import {
  trackBusinessAddressPageLoad,
  trackBusinessContinueButton,
} from "helpers/gtmHelper";
import { IntroHeader } from "page-modules/Join/IntroHeader";
import styles from "./address-finder-page.module.scss";
import {
  footerFade,
  footerFadeTransition,
  heightFadeTransition,
  heightFadeVariants,
  itemContainer,
  itemFade,
} from "./animations";

const selectAppUserState = (state) => state.appUserState;
const selectAddressState = (state) => state.addressState;
const selectIcpState = (state) => state.icp;

function selectState(appUserState, addressState, icpData) {
  const { signupForAdditionalPropertiesOrIcps } = appUserState;
  const { addressDetails, isAddressInNorthIsland } = addressState;

  const isValid = icpData.elecIcp !== "";

  const autoSuggestInputValue = getFullAddressFromFinder(addressDetails);

  return {
    addressDetails,
    isAddressInNorthIsland,
    autoSuggestInputValue,
    icpData,
    isValid,
    signupForAdditionalPropertiesOrIcps,
  };
}

const defaultPageData = {
  fields: {
    title: "What's your address?",
    enterIcpNumberLabel: "Enter your ICP number instead CMS",
    whatsAnIcpNumberLabel: "What's an ICP number??",
    whatsAnIcpModalTitle: "What's an ICP number??",
    whatsAnIcpModalContent: "",
    signupForMultiplePropertiesLabel:
      "I would like to sign up for additional properties or ICPs!!",
    signupForMultiplePropertiesModalTitle:
      "Adding multiple properties or ICPs?",
    signupForMultiplePropertiesModalContent: "",
  },
};

const modules = [
  "ge_join_intro_header",
  "ge_join_business_address",
  "ge_join_business_multiple_properties_checkbox",
];

const stateSelector = createSelector(
  [selectAppUserState, selectAddressState, selectIcpState],
  selectState,
);

function AddressFinderPage(props) {
  const pageData = getPageData({ modules, page: props.page, defaultPageData });

  const {
    autoSuggestInputValue,
    isAddressInNorthIsland: showGasIcpDetails,
    icpData,
    isValid,
    signupForAdditionalPropertiesOrIcps:
      signupForAdditionalPropertiesOrIcpsFromStore,
  } = useSelector(stateSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    trackBusinessAddressPageLoad();
  }, []);

  const [
    signupForAdditionalPropertiesOrIcps,
    setSignupForAdditionalPropertiesOrIcpsState,
  ] = useState(signupForAdditionalPropertiesOrIcpsFromStore);
  const [
    additionalPropertiesOrIcpsModalIsVisible,
    setAdditionalPropertiesOrIcpsModalVisibility,
  ] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isNavigating, setIsNavigating] = useState(false);
  const [isFetchingOffers, setIsFetchingOffers] = useState(false);
  const [modalIsVisible, setModalVisibility] = useState(false);

  const router = useRouter();

  const onAddressSelectionReset = () => {
    if (isValid) {
      dispatch(unsetIcpDetails());
      dispatch(unsetAddressDetails());
    }
    if (errorMessage) {
      setErrorMessage(null);
    }
  };

  const addressSelectionSuccess = ({ icpData, addressFromFinder }) => {
    const addressIslandStatus = isAddressInNorthIsland(addressFromFinder);
    dispatch(setIcpDetails(icpData));
    dispatch(setAddressDetails(addressFromFinder));
    dispatch(setAddressIslandStatus(addressIslandStatus));
    dispatch(setNaturalGasAvailability(addressIslandStatus));    
    setErrorMessage(null);
  };

  const addressSelectionFailure = ({ icpData }) => {
    setIcpDetails(icpData);
    setErrorMessage("icpNotFound");
  };

  const onAddressSelectionComplete = (data) => {
    const { icpData, addressFromFinder } = data;
    const icpFound = icpData.elecIcp !== "";
    if (icpFound) {
      addressSelectionSuccess({ icpData, addressFromFinder });
    } else {
      addressSelectionFailure({ icpData });
    }
  };

  const onContinueClick = async (e) => {
    e.preventDefault();

    if (errorMessage === "icpNotFound") {
      return;
    }
    if (isValid === false) {
      setErrorMessage("Please enter an address");
      return;
    }

    try {
      setIsNavigating(true);
      setIsFetchingOffers(true);
      dispatch(
        setSignupForAdditionalPropertiesOrIcps(
          signupForAdditionalPropertiesOrIcps,
        ),
      );

      if (signupForAdditionalPropertiesOrIcps) {
        dispatch(setCallbackReason(CALLBACK_REASON_MULTI_CONNECT));
        dispatch(setCallbackReferrerUrl(appBusinessRoutes.address));
        router.push(appBusinessRoutes.callback);
        return;
      }

      dispatch(unsetCallbackReferrerUrl());
      dispatch(setCallbackReason(""));

      await dispatch(setBusinessOffers()).catch((ex) => {
        console.error(ex); //todo: log exception
      });

      trackBusinessContinueButton({
        event: "input_address",
        content_tertiary: "input address",
      });

      router.push(appBusinessRoutes.fuel);
    } catch (ex) {
      setIsNavigating(false);
      setIsFetchingOffers(false);
      setErrorMessage("icpNotFound");
    }
  };

  const onIcpInfoButtonClick = (e) => {
    e.preventDefault();
    setModalVisibility(true);
  };

  const onModalClose = () => {
    setModalVisibility(false);
  };

  const onAdditionalPropertiesOrIcpsModalClose = () => {
    setAdditionalPropertiesOrIcpsModalVisibility(false);
  };

  const onAdditionalPropertiesOrIcpsInfoClick = () => {
    setAdditionalPropertiesOrIcpsModalVisibility(true);
  };

  const toggleSignupForAdditionalPropertiesOrIcps = () => {
    const toggledState = signupForAdditionalPropertiesOrIcps ? false : true;
    setSignupForAdditionalPropertiesOrIcpsState(toggledState);
  };

  const onRequestCallbackClick = () => {
    dispatch(setCallbackReason(CALLBACK_REASON_UNSPECIFIED));
    dispatch(setCallbackReferrerUrl(appBusinessRoutes.address));
    router.push(appBusinessRoutes.callback);
  };

  const disableContinueButton = isValid ? isFetchingOffers : true;

  return (
    <motion.div
      className={styles.container}
      exit="undefined"
      data-testid="addressPage"
    >
      <IntroHeader
        module={{
          fields: {
            title: pageData.fields.title,
            blockEditor: pageData.fields.blockEditor,
          },
        }}
      />
      <motion.div
        initial="initial"
        animate="animate"
        exit="exit"
        variants={itemContainer}
        className={styles.body}
      >
        <motion.div variants={itemFade} className={styles.item}>
          <AddressAutoSuggest
            initialValue={autoSuggestInputValue}
            onAddressSelectionReset={onAddressSelectionReset}
            onAddressSelectionComplete={onAddressSelectionComplete}
            renderSuggestionsContainerFooter={() => (
              <SuggestionsFooterRequestCallback
                manualAddressRoute={appBusinessRoutes.addressManual}
                onRequestCallbackClick={onRequestCallbackClick}
              />
            )}
            renderNoResults={(noResultProps) => (
              <NoResults
                {...noResultProps}
                manualAddressRoute={appBusinessRoutes.addressManual}
                onRequestCallbackClick={onRequestCallbackClick}
              />
            )}
            disabled={isFetchingOffers}
          />
          <p className={styles.address_link_list}>
            <Link
              href={appBusinessRoutes.addressIcp}
              className={styles.address_link_item}
            >
              <a className={styles.address_link_item}>
                <span className={styles.address_link_item_text}>
                  {pageData.fields.enterIcpNumberLabel}
                </span>
                <Icon
                  name="Switch"
                  size="18"
                  className={styles.address_link_item_icon}
                />
              </a>
            </Link>
            <button
              onClick={onIcpInfoButtonClick}
              className={styles.address_link_item}
            >
              <span className={styles.address_link_item_text}>
                {pageData.fields.whatsAnIcpNumberLabel}
              </span>
              <Icon
                name="Info"
                size="18"
                className={styles.address_link_item_icon}
              />
            </button>
          </p>
          <div className={styles.address_details_container}>
            <AnimatePresence>
              {isValid && (
                <AddressDetails
                  icpData={icpData}
                  showGasIcpDetails={showGasIcpDetails}
                  isNavigating={isNavigating}
                />
              )}
            </AnimatePresence>
          </div>
        </motion.div>
      </motion.div>

      <motion.div
        initial="initial"
        animate="animate"
        exit="exit"
        variants={footerFade}
        transition={footerFadeTransition}
        className={styles.multiple_properties}
      >
        <ButtonCheckboxSelection
          isSelected={signupForAdditionalPropertiesOrIcps}
          onClick={toggleSignupForAdditionalPropertiesOrIcps}
          className={styles.multiple_properties_checkbox}
          onInfoIconClick={onAdditionalPropertiesOrIcpsInfoClick}
          size="medium"
          textSize="small"
        >
          {pageData.fields.signupForMultiplePropertiesLabel}
        </ButtonCheckboxSelection>
      </motion.div>

      <div className="mt-10 overflow-hidden md:mt-15 lg:mt-20">
        <AnimatePresence>
          {errorMessage && (
            <motion.div
              initial="initial"
              animate="animate"
              exit="exit"
              variants={heightFadeVariants}
              transition={heightFadeTransition}
              className="mb-8 md:mb-10"
            >
              {errorMessage === "icpNotFound" ? (
                <Feedback type="error" data-testid="icpNotFoundErrorMessage">
                  Oops! We&apos;re having a bit of trouble finding your address.
                  <br />
                  Please{" "}
                  <Link href={appBusinessRoutes.addressIcp}>
                    <a className={feedbackStyles.text_link}>enter your ICP</a>
                  </Link>{" "}
                  instead.
                </Feedback>
              ) : (
                <Feedback type="error" title="Error" data-testid="errorMessage">
                  {errorMessage}
                </Feedback>
              )}
            </motion.div>
          )}
        </AnimatePresence>
      </div>

      <StepProgressionButton
        onContinueClick={onContinueClick}
        applyDisabledStyle={disableContinueButton}
        testId="continueButton"
        isLoading={isFetchingOffers}
        backLink={appBusinessRoutes.businessDetails}
      />

      <Modal
        onRequestClose={onModalClose}
        isOpen={modalIsVisible}
        title={pageData.fields.whatsAnIcpModalTitle}
      >
        <InlineBlockEditor
          jsonString={pageData.fields.whatsAnIcpModalContent}
        />
      </Modal>

      <Modal
        onRequestClose={onAdditionalPropertiesOrIcpsModalClose}
        isOpen={additionalPropertiesOrIcpsModalIsVisible}
        title={pageData.fields.signupForMultiplePropertiesModalTitle}
      >
        <InlineBlockEditor
          jsonString={pageData.fields.signupForMultiplePropertiesModalContent}
        />
      </Modal>
    </motion.div>
  );
}

export { AddressFinderPage as BusinessAddressTemplate };
