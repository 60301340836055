import { differenceInYears, parse } from "date-fns";
import { Formik } from "formik";
import { AnimatePresence, motion } from "framer-motion";
import { submitCreditCheckForm } from "actions/appUserStateActions";
import {
  DateInput,
  errorText,
  Input,
  RadioButton,
} from "join-form/components/form-controls";
import Intro from "join-form/components/intro";
import StepProgressionButton from "join-form/components/step-progression-button";
import { appBusinessRoutes } from "helpers/constants";
import { trackBusinessContinueButton } from "helpers/gtmHelper";
import { useRouter } from "next/router";
import { useDispatch, useSelector } from "react-redux";
import { selectAppUserState } from "reducers/selector";
import * as Yup from "yup";
import { getPageData } from "helpers/getPageData";
import {
  headerFade,
  heightFadeTransition,
  heightFadeVariants,
  itemContainer,
} from "./animations";
import styles from "./credit-check-page.module.scss";

export function CreditCheckPage({ props, pageData }) {
  const {
    values,
    touched,
    errors,
    handleChange,
    handleSubmit,
    isValid,
    handleBlur,
  } = props;

  return (
    <motion.div
      className={styles.container}
      exit="undefined"
      data-testid="creditCheckPage"
    >
      <Intro>
        {(introStyles) => (
          <motion.div
            initial="initial"
            animate="animate"
            exit="exit"
            variants={headerFade}
          >
            <h1 className={introStyles.title}>{pageData.fields.title}</h1>
          </motion.div>
        )}
      </Intro>
      <motion.div
        initial="initial"
        animate="animate"
        exit="exit"
        variants={itemContainer}
        className={styles.body}
      >
        <div className={styles.radio_container}>
          <p className={styles.radio_label}>
            {pageData.fields.driversLicenceLabel}
          </p>
          <div className={styles.radio_row}>
            <div className={styles.radio_column}>
              <RadioButton
                name="isDriverLicence"
                checked={values.isDriverLicence === "true"}
                onChange={handleChange}
                value={true}
                text="Yes"
                size="medium"
              />
            </div>
            <div className={styles.radio_column}>
              <RadioButton
                name="isDriverLicence"
                checked={values.isDriverLicence === "false"}
                onChange={handleChange}
                value={false}
                text="No"
                size="medium"
              />
            </div>
          </div>
          {errors.isDriverLicence && touched.isDriverLicence && (
            <span
              data-testid="isDriverLicenceErrorMessage"
              className={errorText}
            >
              {errors.isDriverLicence}
            </span>
          )}
        </div>

        <AnimatePresence>
          {values.isDriverLicence === "true" && (
            <motion.div
              initial="initial"
              animate="animate"
              exit="exit"
              variants={heightFadeVariants}
              transition={heightFadeTransition}
              className={styles.licence_form_wrapper}
            >
              <div className={styles.row}>
                <div className={styles.column}>
                  <Input
                    name="licenceNo"
                    placeholder="Licence number"
                    value={values.licenceNo}
                    onChange={handleChange}
                    errorMessage={errors.licenceNo}
                    showError={errors.licenceNo && touched.licenceNo}
                    onBlur={handleBlur}
                  />
                </div>
                <div className={styles.column}>
                  <Input
                    name="licenceVersionNo"
                    placeholder="Licence version number"
                    value={values.licenceVersionNo}
                    onChange={handleChange}
                    errorMessage={errors.licenceVersionNo}
                    showError={
                      errors.licenceVersionNo && touched.licenceVersionNo
                    }
                    onBlur={handleBlur}
                  />
                </div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
        <AnimatePresence>
          <motion.div
            initial="initial"
            animate="animate"
            exit="exit"
            variants={heightFadeVariants}
            transition={heightFadeTransition}
            className={styles.licence_form_wrapper}
          >
            <div className={styles.row}>
              <div className={styles.column}>
                <DateInput
                  name="dob"
                  label="Date of Birth (DD/MM/YYYY)"
                  placeholder="Date of Birth (DD/MM/YYYY)"
                  value={values.dob}
                  onChange={handleChange}
                  errorMessage={errors.dob}
                  showError={errors.dob && touched.dob}
                  onBlur={handleBlur}
                />
              </div>
              <div className={styles.column}></div>
            </div>
          </motion.div>
        </AnimatePresence>
      </motion.div>
      <StepProgressionButton
        onContinueClick={handleSubmit}
        disabled={!isValid}
        backLink={appBusinessRoutes.termSelect}
      />
    </motion.div>
  );
}

const mapPropsToValues = (appUserState) => ({
  isDriverLicence: appUserState.isDriverLicence || "",
  licenceNo: appUserState.licenceNo,
  licenceVersionNo: appUserState.licenceVersionNo,
  dob: appUserState.dob,
});

const validationSchema = Yup.object().shape({
  isDriverLicence: Yup.string().required("Please select an option"),
  dob: Yup.string()
    .required("Date of birth is required")
    .matches(
      /(?:0[1-9]|[12][0-9]|3[01])\/(?:0[1-9]|1[0-2])\/(?:\d{4})$/,
      "Please enter a valid date of birth",
    )
    .max(10, "Date of birth cannot be more than 10 characters")
    .test("dob", "Sorry, you must be over 18", (value) => {
      const now = new Date(Date.now());
      const enteredDate = parse(value, "dd/MM/yyyy", now);
      const age = differenceInYears(now, enteredDate);
      return age >= 18;
    }),
  licenceVersionNo: Yup.string().when("isDriverLicence", {
    is: (value) => value === "true",
    then: Yup.string()
      .required("Please enter version number")
      .matches(/^[0-9 ]+$/, "Please enter a valid version number")
      .max(3, "Licence version number cannot be more than 3 characters"),
  }),
  licenceNo: Yup.string().when("isDriverLicence", {
    is: (value) => value === "true",
    then: Yup.string()
      .required("Drivers licence is required")
      .max(8, "Drivers licence number cannot be more than 8 characters"),
  }),
});

const modules = ["GE_JOIN_Intro_Header", "GE_JOIN_Business_Credit_Check"];

function CreditCheckPageForm(props) {
  const pageData = getPageData({ modules, page: props.page });
  const componentProps = useSelector(selectAppUserState);
  const dispatch = useDispatch();

  const initialValues = mapPropsToValues(componentProps);
  const router = useRouter();

  const onSubmit = (values) => {
    dispatch(submitCreditCheckForm(values));

    trackBusinessContinueButton({
      event: "input_licence_details",
      content_tertiary: "input licence details",
      selection: `driver licence - ${
        values.isDriverLicence === "true" ? "yes" : "no"
      }`,
    });
    router.push(appBusinessRoutes.property);
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      validateOnMount
      enableReinitialize
    >
      {(formikProps) => (
        <CreditCheckPage
          pageData={pageData}
          props={{ ...componentProps, ...formikProps }}
        />
      )}
    </Formik>
  );
}

export default CreditCheckPageForm;
