import classNames from "classnames";
import { InlineBlockEditor } from "components/BlockEditor";
import { getBlocks } from "helpers/blockEditorHelper";
import { SmeLpgRefillForm } from "./SmeLpgRefillForm";

function ApiForm({ module }) {
  const {
    fields: {
      cta,
      description,
      tBCFormPicker,
      title,
      subtitle,
      anchor,
      thanksPage,
    },
  } = module;

  const descriptionBlockContent = getBlocks(description);
  const isSmeRefillForm = tBCFormPicker === "smerefill";

  return (
    <div
      data-psnid="psn_api_form"
      className="mb-15 md:mb-25 md:px-17 lg:px-23 xl:px-46"
    >
      <div className="lg:grid lg:grid-cols-24 lg:gap-8">
        <div className="lg:col-start-2 lg:col-span-22">
          <div
            id={anchor}
            className={classNames(
              "rounded-7 bg-white mt-10 py-15 md:border md:border-black-100",
              "md:rounded-10 md:border md:border-black-100",
            )}
          >
            <div
              className={classNames(
                "px-4 sm:px-15 md:px-10 lg:px-13",
                "mx-auto max-w-full w-120 sm:w-210 md:w-auto",
              )}
            >
              <h2
                className={classNames(
                  "text-32/36 font-bold mb-5 text-black-primary",
                  "md:text-44/52",
                )}
              >
                {title}
              </h2>
              {subtitle && (
                <h3
                  className={classNames(
                    "text-20/24 font-bold mb-5 text-black-primary",
                    "md:text-24/28",
                  )}
                >
                  {subtitle}
                </h3>
              )}
              <InlineBlockEditor
                data={descriptionBlockContent}
                testid="apiFormBlockEditor"
              />
              {isSmeRefillForm && (
                <SmeLpgRefillForm submitText={cta} thanksPage={thanksPage} />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export { ApiForm };
