import { createSelector } from "@reduxjs/toolkit";
import { EnergyRateItem } from "join-form/components/energy-rate";
import { useSelector } from "react-redux";
import { selectAppState, selectResidentialOffers } from "reducers/selector";
import { PLAN_TYPE_TO_PLAN_NAME, PLAN_USAGE_LOW } from "helpers/constants";
import { isNaturalGasSelected } from "helpers/productHelper";
import {
  getRateDescription,
  getRateUnitOfMeasurement,
} from "helpers/rateHelper";

const stateSelector = createSelector(
  [selectAppState, selectResidentialOffers, (state, planType) => planType],
  (appState, residentialOffers, planType) => {
    const plan = PLAN_TYPE_TO_PLAN_NAME[planType];
    const { products } = appState;
    const naturalGasOffer = residentialOffers?.naturalGasOffer;

    const lowUsageTariffList = naturalGasOffer[plan]?.lowUsage.tariffList || [];
    const highUsageTariffList =
      naturalGasOffer[plan]?.highUsage.tariffList || [];

    const shouldRender = isNaturalGasSelected(products);

    return {
      lowUsageTariffList,
      highUsageTariffList,
      shouldRender,
    };
  },
);

function NaturalGasRates(props) {
  const { planType, usageType, inclGst, testIdPrefix } = props;

  const { lowUsageTariffList, highUsageTariffList, shouldRender } = useSelector(
    (state) => stateSelector(state, planType),
  );

  if (!shouldRender) {
    return null;
  }

  const tariffList =
    usageType === PLAN_USAGE_LOW ? lowUsageTariffList : highUsageTariffList;

  if (!tariffList.length) {
    return (
      <div
        data-testid={`${testIdPrefix}_${planType}_${usageType}_NaturalGasRatesPlaceholder`}
        className="relative flex flex-col py-4 gap-y-2"
      >
        <hr className="absolute inset-x-0 top-0 h-px bg-black-100" />
        <h5 className="font-medium text-15/24 md:text-18/28 text-black-primary">
          Natural Gas
        </h5>
        <p className="text-black-primary text-14/20">
          We couldn’t find your Natural Gas rates for this plan. We’ll confirm
          these later.
        </p>
      </div>
    );
  }

  return (
    <div
      data-testid={`${testIdPrefix}_${planType}_${usageType}_NaturalGasRates`}
      className="relative flex flex-col py-4 gap-y-2"
    >
      <hr className="absolute inset-x-0 top-0 h-px bg-black-100" />
      <h5 className="font-medium text-15/24 md:text-18/28 text-black-primary">
        Natural Gas
      </h5>
      <div className="flex flex-col gap-y-2 text-black-primary">
        {tariffList.map((tariffItem, index) => {
          const {
            description,
            meteringUnitOfMeasurement,
            pricingUnitOfMeasurement,
            rateExcludingGST,
            rateIncludingGST,
          } = tariffItem;

          const title = getRateDescription(description);
          const rateValue = inclGst ? rateIncludingGST : rateExcludingGST;
          const unitOfMeasurement = getRateUnitOfMeasurement(
            meteringUnitOfMeasurement,
          );
          const value = `${pricingUnitOfMeasurement}${rateValue.toFixed(
            4,
          )} / ${unitOfMeasurement}`;

          return <EnergyRateItem key={index} title={title} value={value} />;
        })}
      </div>
    </div>
  );
}

export { NaturalGasRates };
