import {
  links,
  PLAN_TYPE_BASIC,
  PLAN_TYPE_EV_PLUS,
  PLAN_TYPE_PLUS,
  PLAN_TYPE_PLUS_FIXED,
  PLAN_TYPE_PLUS_FLEXI,
  RESIDENTIAL_PLAN_TERM_FLEXI,
} from "helpers/constants";

export function getPlanTerms(planType) {
  switch (planType) {
    case PLAN_TYPE_PLUS:
      return {
        title: "Energy Plus",
        link: links.plusTerms,
      };
    case PLAN_TYPE_BASIC:
      return {
        title: "Energy Basic",
        link: links.basicTerms,
      };
    case PLAN_TYPE_EV_PLUS:
      return {
        title: "Energy EV",
        link: links.evPlusTerms,
      };
    default:
      return {
        title: "Plan",
        link: "/",
      };
  }
}

export function getPlanTypeByTerm({ selectedTermCodeName, planType }) {
  if (planType !== PLAN_TYPE_PLUS) {
    return planType;
  }

  if (selectedTermCodeName === RESIDENTIAL_PLAN_TERM_FLEXI) {
    return PLAN_TYPE_PLUS_FLEXI;
  }

  return PLAN_TYPE_PLUS_FIXED;
}

export function getPlanDisplayName(planCodeName) {
  switch (planCodeName) {
    case PLAN_TYPE_PLUS:
      return "Plus plan";
    case PLAN_TYPE_PLUS_FIXED:
      return "Plus Fixed plan";
    case PLAN_TYPE_PLUS_FLEXI:
      return "Plus Flexi plan";
    case PLAN_TYPE_BASIC:
      return "Basic plan";
    case PLAN_TYPE_EV_PLUS:
      return "EV plan";
    default:
      return "";
  }
}
