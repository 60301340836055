import { runAsync } from "helpers";

async function getJoinHeaderProps(context) {
  const { agility, languageCode } = context;

  const [contentItem, getContentItemError] = await runAsync(() =>
    agility.getContentItem({
      contentID: 9050,
      languageCode,
      expandAllContentLinks: true,
      contentLinkDepth: 2,
    }),
  );

  if (getContentItemError) {
    return null;
  }

  return contentItem.fields;
}

export { getJoinHeaderProps };
