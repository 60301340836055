import classNames from "classnames";
import { Image } from "components/Image";
import { ScaleAnimation } from "./animations";

function HeaderImage({ image }) {
  return (
    <ScaleAnimation>
      <div
        data-testid="imageContainer"
        className={classNames(
          "rounded-7 overflow-hidden mx-auto shadow-white-glow max-w-85",
          "sm:max-w-120 sm:h-75",
        )}
      >
        <Image
          alt={image.label}
          src={image.url}
          width={image.width}
          height={image.height}
          layout="responsive"
        />
      </div>
    </ScaleAnimation>
  );
}

export { HeaderImage };
