import { useState } from "react";
import { createSelector } from "@reduxjs/toolkit";
import { Formik } from "formik";
import { motion } from "framer-motion";
import {
  setAddressIslandStatus,
  setManualAddress,
} from "actions/addressState/addressStateActions";
import { setNaturalGasAvailability } from "actions/appState/appStateActions";
import { setSignupForAdditionalPropertiesOrIcps } from "actions/appUserStateActions";
import {
  setCallbackReason,
  setCallbackReferrerUrl,
  unsetCallbackReferrerUrl,
} from "actions/callbackPageActions";
import ButtonCheckboxSelection from "join-form/components/button-checkbox-selection";
import { Input } from "join-form/components/form-controls";
import Intro from "join-form/components/intro";
import StepProgressionButton from "join-form/components/step-progression-button";
import {
  appBusinessRoutes,
  CALLBACK_REASON_MULTI_CONNECT,
} from "helpers/constants";
import { isPostcodeForNorthIslandAddress } from "helpers/addressHelper";
import {
  selectAddressState,
  selectAppUserState,
} from "reducers/selector";
import { useRouter } from "next/router";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { InlineBlockEditor } from "components/BlockEditor";
import { Icon } from "components/Icon";
import { Link } from "components/Link";
import { Modal } from "components/Modal";
import { getPageData } from "helpers/getPageData";
import styles from "./address-manual-page.module.scss";
import {
  footerFade,
  footerFadeTransition,
  headerFade,
  itemContainer,
  itemFade,
} from "./animations";

function AddressManualPage({ props, pageData }) {
  const {
    values,
    touched,
    errors,
    handleChange,
    handleSubmit,
    isValid,
    isSubmitting,
    setFieldValue,
  } = props;

  const {
    signupForMultiplePropertiesLabel,
    signupForMultiplePropertiesModalContent,
    signupForMultiplePropertiesModalTitle,
    signupForMultiplePropertiesModalButton,
    title,
    useAddressFinderFieldLabel,
  } = pageData.fields;

  const shouldDisableContinueButton = isValid === false || isSubmitting;

  const [
    additionalPropertiesOrIcpsModalIsVisible,
    setAdditionalPropertiesOrIcpsModalVisibility,
  ] = useState(false);

  const onAdditionalPropertiesOrIcpsInfoClick = () => {
    setAdditionalPropertiesOrIcpsModalVisibility(true);
  };

  const onAdditionalPropertiesOrIcpsModalClose = () => {
    setAdditionalPropertiesOrIcpsModalVisibility(false);
  };

  const toggleSignupForAdditionalPropertiesOrIcps = () => {
    const toggledState = values.signupForAdditionalPropertiesOrIcps
      ? false
      : true;
    setFieldValue("signupForAdditionalPropertiesOrIcps", toggledState);
  };

  return (
    <motion.div
      className={styles.container}
      exit="undefined"
      data-testid="addressManual"
    >
      <Intro>
        {(introStyles) => (
          <motion.div
            initial="initial"
            animate="animate"
            exit="exit"
            variants={headerFade}
          >
            <h1 className={introStyles.title}>{title}</h1>
          </motion.div>
        )}
      </Intro>
      <motion.div
        initial="initial"
        animate="animate"
        exit="exit"
        variants={itemContainer}
        className={styles.body}
      >
        <motion.div variants={itemFade} className={styles.item}>
          <div className={styles.row}>
            <div className={styles.column}>
              <Input
                name="addressLine1"
                placeholder="Address Line 1"
                value={values.addressLine1}
                onChange={handleChange}
                errorMessage={errors.addressLine1}
                showError={errors.addressLine1 && touched.addressLine1}
              />
            </div>
            <div className={styles.column}>
              <Input
                name="addressLine2"
                placeholder="Address Line 2 (Optional)"
                value={values.addressLine2}
                onChange={handleChange}
                errorMessage={errors.addressLine2}
                showError={errors.addressLine2 && touched.addressLine2}
              />
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.column}>
              <Input
                name="suburb"
                placeholder="Suburb (Optional)"
                value={values.suburb}
                onChange={handleChange}
                errorMessage={errors.suburb}
                showError={errors.suburb && touched.suburb}
              />
            </div>
            <div className={styles.column}>
              <Input
                name="town"
                placeholder="Town / City"
                value={values.town}
                onChange={handleChange}
                errorMessage={errors.town}
                showError={errors.town && touched.town}
              />
            </div>
            <div
              className={`${styles.column} ${styles.postcode_field_container}`}
            >
              <Input
                name="postcode"
                placeholder="Postcode"
                maxLength="4"
                value={values.postcode}
                onChange={handleChange}
                errorMessage={errors.postcode}
                showError={errors.postcode && touched.postcode}
              />
            </div>
          </div>

          <p className={styles.address_link_list}>
            <Link
              href={appBusinessRoutes.address}
              className={styles.address_link_item}
            >
              <a className={styles.address_link_item}>
                <span className={styles.address_link_item_text}>
                  {useAddressFinderFieldLabel}
                </span>
                <Icon
                  name="Switch"
                  size="18"
                  className={styles.address_link_item_icon}
                />
              </a>
            </Link>
          </p>
        </motion.div>
      </motion.div>

      <motion.div
        initial="initial"
        animate="animate"
        exit="exit"
        variants={footerFade}
        transition={footerFadeTransition}
        className={styles.multiple_properties}
      >
        <ButtonCheckboxSelection
          isSelected={values.signupForAdditionalPropertiesOrIcps}
          onClick={toggleSignupForAdditionalPropertiesOrIcps}
          className={styles.multiple_properties_checkbox}
          onInfoIconClick={onAdditionalPropertiesOrIcpsInfoClick}
          size="medium"
          textSize="small"
        >
          {signupForMultiplePropertiesLabel}
        </ButtonCheckboxSelection>
      </motion.div>

      <div className={styles.feedback_wrapper}></div>

      <StepProgressionButton
        disabled={shouldDisableContinueButton}
        onContinueClick={handleSubmit}
        backLink={appBusinessRoutes.businessDetails}
        testId="continueButton"
      />
      <Modal
        onRequestClose={onAdditionalPropertiesOrIcpsModalClose}
        isOpen={additionalPropertiesOrIcpsModalIsVisible}
        title={signupForMultiplePropertiesModalTitle}
        buttonText={signupForMultiplePropertiesModalButton}
      >
        <InlineBlockEditor
          jsonString={signupForMultiplePropertiesModalContent}
        />
      </Modal>
    </motion.div>
  );
}

const mapPropsToValues = ({
  manualAddressDetails,
  signupForAdditionalPropertiesOrIcps,
}) => ({
  addressLine1: manualAddressDetails.addressLine1,
  addressLine2: manualAddressDetails.addressLine2,
  suburb: manualAddressDetails.suburb,
  town: manualAddressDetails.town,
  postcode: manualAddressDetails.postcode,
  signupForAdditionalPropertiesOrIcps,
});

const validationSchema = Yup.object().shape({
  addressLine1: Yup.string()
    .required("This field is required")
    .max(255, "This field can not contain more than 255 characters"),
  addressLine2: Yup.string()
    .optional()
    .max(255, "This field can not contain more than 255 characters"),
  suburb: Yup.string()
    .optional()
    .max(255, "This field can not contain more than 255 characters"),
  town: Yup.string()
    .required("This field is required")
    .max(255, "This field can not contain more than 255 characters"),
  postcode: Yup.string()
    .required("This field is required")
    .min(4, "This field must be 4 characters"),
});

const selectBusinessManualAddressState = createSelector(
  [selectAppUserState, selectAddressState],
  (appUserState, addressState) => {
    return {
      manualAddressDetails: addressState.manualAddressDetails,
      signupForAdditionalPropertiesOrIcps:
        appUserState.signupForAdditionalPropertiesOrIcps,
    };
  },
);

const modules = [
  "GE_JOIN_Intro_Header",
  "GE_JOIN_Business_Multiple_Properties_Checkbox",
  "GE_JOIN_Business_Manual_Address",
];

function AddressManualPageForm(props) {
  const pageData = getPageData({ modules, page: props.page });
  const componentProps = useSelector(selectBusinessManualAddressState);
  const dispatch = useDispatch();

  const initialValues = mapPropsToValues(componentProps);
  const router = useRouter();

  const onSubmit = (values, options) => {
    options.setSubmitting(true);

    const {
      signupForAdditionalPropertiesOrIcps: signupForAdditionalPropertiesBound,
      ...manualAddressFormValues
    } = values;

    dispatch(setManualAddress(manualAddressFormValues));
    dispatch(
      setSignupForAdditionalPropertiesOrIcps(
        signupForAdditionalPropertiesBound,
      ),
    );

    const isAddressInNorthIsland = isPostcodeForNorthIslandAddress(
      values.postcode,
    );

    dispatch(setAddressIslandStatus(isAddressInNorthIsland));
    dispatch(setNaturalGasAvailability(isAddressInNorthIsland));

    options.setSubmitting(false);

    if (signupForAdditionalPropertiesBound) {
      dispatch(setCallbackReason(CALLBACK_REASON_MULTI_CONNECT));
      dispatch(setCallbackReferrerUrl(appBusinessRoutes.addressManual));
      router.push(appBusinessRoutes.callback);
      return;
    }

    dispatch(unsetCallbackReferrerUrl());
    dispatch(setCallbackReason(""));

    router.push(appBusinessRoutes.fuel);
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      validateOnMount
      enableReinitialize
    >
      {(formikProps) => (
        <AddressManualPage
          pageData={pageData}
          props={{ ...componentProps, ...formikProps }}
        />
      )}
    </Formik>
  );
}

export default AddressManualPageForm;
