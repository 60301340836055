import { getSafeNumber } from "helpers/numberStringHelper";

function getIndexOfModuleByContentId(zone, contentID) {
  const index = zone.findIndex((module) => module.item.contentID === contentID);
  return index;
}

function convertModuleFields(moduleFields) {
  return {
    ...moduleFields,
    startModuleId: getSafeNumber(moduleFields.startModuleId),
    span: getSafeNumber(moduleFields.span),
  };
}

function calculateBoundsForCurve({ page, fields }) {
  const pageContent = document.getElementById("page-content");

  const { startModuleId, span } = fields;

  const contentZone = page.zones.Content ?? [];

  const indexOfStartingModuleId = getIndexOfModuleByContentId(
    contentZone,
    startModuleId,
  );

  if (indexOfStartingModuleId === -1) {
    return {
      top: 0,
      height: 0,
    };
  }

  const startingElement = pageContent?.children[indexOfStartingModuleId];

  const endingElement = pageContent?.children[indexOfStartingModuleId + span];

  const endingElementBounds = endingElement?.getBoundingClientRect();

  const height =
    endingElement?.offsetTop +
    endingElementBounds?.height -
    startingElement?.offsetTop;

  return {
    top: startingElement?.offsetTop,
    height: span > -1 ? height : null,
  };
}

export { convertModuleFields, calculateBoundsForCurve };
