import {
  PLAN_TYPE_BASIC,
} from "helpers/constants";
import { InlineBlockEditor } from "components/BlockEditor";
import styles from "./payment-page.module.scss";
import { Button } from "components/ButtonForJoinJourney";
import { setCcPayment, createCreditCardSession, deleteCreditCardInfo } from "join-form/services/creditCardService";
import { motion } from "framer-motion";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Image } from "components/Image";
import Icon from "join-form/components/icon/Icon";
import Feedback from "join-form/components/feedback";
import { heightFadeTransition, heightFadeVariants } from "./animations";
import { Close } from "assets/icons";

const ccImageCardTypeMapping = {
  visa: "/images/visa.png",
  mastercard: "/images/mastercard.png",
  amex: "/images/amex.png",
  diners: "/images/diners.png",
  other: "/images/other-card.png",
}

const ccAltTextCardTypeMapping = {
  visa: "Visa",
  mastercard: "Mastercard",
  amex: "American Express",
  diners: "Diners Club",
  other: "Other Card",
}

export default function CreditCardForm(props) {
  const {
    values,
    planType,
    creditCardTerms,
    creditTransactionFeeNote,
  } = props;

  const {
    creditCard,
    creditCardType,
    expiryMmYy,
  } = values;

  const [isLoading, setIsLoading] = useState(false);
  const [hasSessionId, setHasSessionId] = useState(false);
  const errorMsg = useMemo(() => {
    if (isLoading && !values.isApiError) return null;
    if (values.isApiError && !values.sessionId) {
      return "An error has occurred while navigating to the Credit Card page. Please try again later."
    }

    if (values.isApiError && values.sessionId) {
      return "An error has occurred while retrieving data. Please try again later.";
    }

    if (values.isCcInvalid && values.sessionId && values.responseText) {
      return "Your credit card has not been added. Please try again.";
    }

    return null;
  }, [values.isApiError, values.isCcInvalid, values.responseText, values.sessionId, isLoading]);

  const setCcSession = useCallback(async () => {
    if (!hasSessionId && !isLoading) {
      setIsLoading(true);
      await setCcPayment(values.sessionId);
      setHasSessionId(true);
      setIsLoading(false);
    }
  }, [values.sessionId, hasSessionId, isLoading]);

  useEffect(() => {
    if (!values.sessionId || isLoading) return;
    setCcSession();
  }, [values.sessionId, isLoading, setCcSession]);

  const cardType = useMemo(() => {
    if (!ccImageCardTypeMapping[creditCardType]) return ccImageCardTypeMapping["other"];
    
    return ccImageCardTypeMapping[creditCardType];
  }, [creditCardType]);

  const cardAltText = useMemo(() => {
    if (!ccAltTextCardTypeMapping[creditCardType]) return ccAltTextCardTypeMapping["other"];
    
    return ccAltTextCardTypeMapping[creditCardType];
  }, [creditCardType]);

  const transformedCreditCardType = useMemo(() => {
    if (creditCardType === "amex" || creditCardType === "jcb") return creditCardType.toUpperCase();

    return creditCardType.charAt(0).toUpperCase() + creditCardType.slice(1);
  }, [creditCardType]);

  const onButtonClick = async () => {
    if (isLoading) return;
    setIsLoading(true);
    await createCreditCardSession();
  }
  
  const onDeleteButtonClick = () => {
    deleteCreditCardInfo();
  }

  return (
    <div className={styles.credit_card_form}>
      {
        errorMsg && (
          <motion.div
              initial="initial"
              animate="animate"
              exit="exit"
              variants={heightFadeVariants}
              transition={heightFadeTransition}
              className="mb-8 md:mb-10"
            >
              <Feedback type="warning" data-testid="errorMessage">
                {errorMsg}
              </Feedback>
            </motion.div>
        )
      }
      {
        !(creditCard || expiryMmYy || creditCardType) ? (
          <Button
            className={styles.add_credit_card_button}
            buttonType={"tertiaryFillWithBorder"}
            isLoading={isLoading}
            onClick={onButtonClick}
          >
            {isLoading ? (
            <Icon
              name="Loading"
              id="loading"
              size="20"
              className="animate-spin"
            />
          ) : (
            "Add credit card"
          )}
      </Button>
        ) : (
            <motion.div
              initial="initial"
              animate="animate"
              exit="exit"
              variants={heightFadeVariants}
              transition={heightFadeTransition}
              className="mb-8 md:mb-10"
            >
              <div className={styles.card}>
                <div className={styles.card_details}>
                  <Image src={cardType} alt={cardAltText} className={styles.card_type} width={82} height={57} />
                  <div>
                    <p className={styles.card_number}>
                      {`${transformedCreditCardType} ${creditCard}`}
                      </p>
                    <p className={styles.expiry}>Expires {expiryMmYy}</p>
                  </div>
                </div>
                <button
                  type="button"
                  className="text-black-primary text-opacity-40 hover:text-dark-primary hover:text-opacity-60 hover:cursor-pointer"
                  onClick={onDeleteButtonClick}
                  aria-label="Delete credit card"
                >
                  <Close
                    aria-label="Delete credit card"
                    className="w-8 h-8 fill-current"
                    
                  />
                </button>
              </div>
            </motion.div>
        )
      }
      
      <InlineBlockEditor
        jsonString={creditCardTerms}
        className={`${styles.credit_card_terms} prose-p:tex-15/24 prose-p:md:text-18/28`}
      />

      {planType === PLAN_TYPE_BASIC && (
        <p
          className={styles.credit_card_terms}
          data-testid="basicPlanTransactionFee"
        >
          {creditTransactionFeeNote}
        </p>
      )}
    </div>
  );
}
