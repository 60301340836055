import classNames from "classnames";
import { Link } from "components/Link";
import { selectTopLevelNav, useNavigation } from "providers/Navigation";

function TopLevelNav({ prefetch }) {
  const {
    state: { topLevelNav },
    dispatch,
  } = useNavigation();

  function onTopLevelNavClick(navData) {
    selectTopLevelNav({ dispatch, payload: navData });
  }

  return (
    <nav className="" key="topLevelNav">
      <ul className="flex flex-wrap space-x-1">
        {topLevelNav.map((navigationItem) => {
          return (
            <li key={navigationItem.name}>
              <Link href={navigationItem.path} prefetch={prefetch}>
                <a
                  onClick={() => {
                    onTopLevelNavClick(navigationItem);
                  }}
                  className={classNames(
                    "transition-background block px-2 py-0.5 text-black-primary text-xs rounded duration-150",
                    {
                      "bg-black-50 is-selected": navigationItem.isSelected,
                    },
                    {
                      "hover:bg-orange-100": !navigationItem.isSelected,
                    },
                  )}
                  data-ga={JSON.stringify({
                    content_tertiary: "global menu",
                    event_label: [navigationItem.menuText],
                    event_category: "global menu",
                  })}
                >
                  {navigationItem.menuText}
                </a>
              </Link>
            </li>
          );
        })}
      </ul>
    </nav>
  );
}

export { TopLevelNav };
