import styles from "../address-auto-suggest.module.scss";

const theme = {
  container: styles.container,
  containerOpen: styles.container_open,
  input: styles.input,
  inputOpen: styles.input_open,
  inputFocused: styles.input_focused,
  suggestionsWrapper: styles.suggestions_wrapper,
  suggestionsWrapperScrolled: styles.suggestions_wrapper_scrolled,
  suggestionsContainer: styles.suggestions_container,
  suggestionsContainerOpen: styles.suggestions_container_open,
  suggestionsHeader: styles.suggestions_header,
  suggestionsList: styles.suggestions_list,
  suggestionsFooter: styles.suggestions_footer,
  suggestionsFooterText: styles.suggestions_footer_text,
  suggestionsFooterLink: styles.suggestions_footer_link,
  suggestion: styles.suggestion_item,
  suggestionFirst: styles.suggestion_item_first,
  suggestionHighlighted: styles.suggestion_item_highlighted,
};

export { theme };
