export const selectAppState = (state) => state.appState;
export const selectIcpState = (state) => state.icp;
export const selectBusinessState = (state) => state.businessState;
export const selectResidentialOffers = (state) => state.residentialOffers;
export const selectResidentialPlanConfig = (state) => state.residentialPlanConfig;
export const selectCarRegistration = (state) => state.carRegistration;
export const selectAppUserState = (state) => state.appUserState;
export const selectAddressState = (state) => state.addressState;
export const selectBusinessPlanConfig = (state) => state.businessPlanConfig;
export const selectAppPropertyState = (state) => state.appPropertyState;
export const selectBusinessOffers = (state) => state.businessOffers;
export const selectPaymentState = (state) => state.paymentState;
export const selectGtmState = (state) => state.gtmState;
