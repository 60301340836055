function filterByYear(articleItem, selectedYear) {
  const articleYear = articleItem.fields.year;
  const result = selectedYear ? articleYear === selectedYear : true;
  return result;
}

function filterByMonth(articleItem, selectedMonth) {
  const articleMonth = articleItem.fields.month;
  const result = selectedMonth ? articleMonth === selectedMonth : true;
  return result;
}

function filterByTagId(articleItem, selectedTagIds) {
  if (selectedTagIds.length === 0) {
    return true;
  }
  const tagId = articleItem.fields.tag?.contentID.toString();
  return selectedTagIds.includes(tagId);
}

function filterByTopicId(articleItem, selectedTopicIds) {
  if (selectedTopicIds.length === 0) {
    return true;
  }
  const { topics } = articleItem.fields;

  return topics.some((topic) =>
    selectedTopicIds.includes(topic.contentID.toString()),
  );
}

function findMonthsForSelectedYear(allDates, selectedYear) {
  const allMonths = allDates
    .filter((date) => date.year === selectedYear)
    .map((date) => date.month);

  return allMonths;
}

function toggleItemFromList(list, item) {
  const itemExistsInList = list.includes(item);

  if (itemExistsInList) {
    return list.filter((listItem) => listItem !== item);
  }

  return list.concat(item);
}

function getPaginationState(visibleArticles, totalArticles) {
  const totalArticlesCount = totalArticles.length;
  const visibleArticlesCount = Math.min(
    visibleArticles.length,
    totalArticles.length,
  );

  if (visibleArticlesCount === 0) {
    return {
      paginationText: "",
      showLoadMoreButton: false,
    };
  }

  if (visibleArticlesCount === totalArticlesCount) {
    return {
      paginationText: "Showing all results",
      showLoadMoreButton: false,
    };
  }

  return {
    paginationText: `Showing ${visibleArticlesCount} of ${totalArticlesCount} results`,
    showLoadMoreButton: true,
  };
}

export {
  filterByYear,
  filterByMonth,
  filterByTagId,
  filterByTopicId,
  findMonthsForSelectedYear,
  toggleItemFromList,
  getPaginationState,
};
