import { default as SearchSvg } from "./individual-icons/Search.svg";

export { default } from "./Icon";
export { default as ExternalLink } from "./individual-icons/ExternalLink.svg";
export { default as Info } from "./individual-icons/Info.svg";
export { default as InfoUnfilled } from "./individual-icons/InfoUnfilled.svg";
export { default as Loading } from "./individual-icons/Loading.svg";
// export { default as Search } from "./individual-icons/Search.svg";
export { default as Spinner } from "./individual-icons/Spinner.svg";
export { default as Tick } from "./individual-icons/Tick.svg";
export { default as Chat } from "./individual-icons/Chat.svg";

export function Search(props) {
  return <SearchSvg viewBox="0 0 36 36" {...props} />;
}
