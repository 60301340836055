import StepNavigationItem from "join-form/components/step-navigation-item";
import { appResidentialRoutes } from "helpers/constants";
import { getShortAddressFromFinder } from "helpers/addressHelper";
import { useRouter } from "next/router";
import { connect } from "react-redux";

function AddressItem({ addressDetails, ...rest }) {
  const title = getShortAddressFromFinder(addressDetails);
  const {
    success,
    callbackSuccess,
    address,
    addressIcp,
    addressManual,
    ...allFuelRouteInfo
  } = appResidentialRoutes;
  const fuelPageRoutes = Object.values(allFuelRouteInfo);
  const router = useRouter();
  const pathWithoutQuery = router.asPath.split('?').at(0);
  const isVisible = fuelPageRoutes.includes(pathWithoutQuery);

  return isVisible ? (
    <StepNavigationItem title={title} path={address} {...rest} />
  ) : null;
}

const mapStateToProps = (props, ownProps) => {
  const {
    addressState: { addressDetails },
  } = props;
  return { addressDetails };
};

export default connect(mapStateToProps)(AddressItem);
