import { useCallback, useState } from "react";
import { DialogContent, DialogOverlay } from "@reach/dialog";
import Skeleton from "react-loading-skeleton";
import { ExternalLink, InfoFilled } from "assets/icons";
import { Button } from "components/Button";
import { VARIANT } from "helpers/constants";
import { getContentWithLineBreaks } from "helpers/htmlHelper";

const Modal = ({ showOverlay, title, primaryModal, toggleModal }) => (
  <DialogOverlay
    className="z-20 flex items-center bg-black-primary/90 animate-fade-in"
    isOpen={showOverlay}
    onDismiss={toggleModal}
    aria-label={title}
  >
    <DialogContent
      className="w-full p-4 bg-transparent sm:p-8"
      aria-label={title}
    >
      <div className="p-10 mx-auto bg-white border max-w-96 border-black-100 rounded-5">
        <h2 className="mb-5 font-bold text-dark-primary text-32/36">{title}</h2>
        <p className="text-black-primary text-18/28">
          {getContentWithLineBreaks(primaryModal)}
        </p>
        <div className="flex flex-wrap justify-end mt-10">
          <Button size="small" onClick={toggleModal} variant={VARIANT.dark}>
            <span>Got it</span>
          </Button>
        </div>
      </div>
    </DialogContent>
  </DialogOverlay>
);

export const QuotePanel = ({
  asxLink,
  nzxLink,
  priceSummary,
  primaryModal,
}) => {
  const [showOverlay, setShowOverlay] = useState(false);
  const toggleModal = useCallback(
    () => setShowOverlay(!showOverlay),
    [showOverlay],
  );
  const [symbol] = priceSummary || [];
  const title = `NZX ${symbol?.value ?? ""} Quote`;
  const hasAsxLink = asxLink?.href.length > 0 && asxLink?.text.length > 0;
  const hasNzxLink = nzxLink?.href.length > 0 && nzxLink?.text.length > 0;

  return (
    <div className="px-5 py-10 bg-white md:p-10 rounded-5">
      {!priceSummary ? (
        <>
          <Skeleton />
          <Skeleton height={200} />
          <Skeleton />
          <Skeleton width="50%" />
        </>
      ) : (
        <>
          <h3 className="mb-5 font-medium text-20/32 text-black-primary">
            {title}
            <InfoFilled
              className="inline w-5 h-5 mb-1 ml-1 fill-current text-black-100 hover:cursor-pointer"
              data-testid="open-modal"
              onClick={toggleModal}
            />
          </h3>
          <table className="w-full text-black-primary text-14/20 md:text-16/24">
            <tbody>
              {priceSummary.map((item) => {
                return (
                  <tr key={`quote-panel-key-${item.key}`}>
                    <th className="w-6/12 py-4 font-medium text-left border-b border-black-100">
                      {item.key}
                    </th>
                    <td className="w-6/12 py-4 text-right border-b border-black-100">
                      {item.value}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <p className="my-5 text-black-primary text-14/20">
            Source of NZX trading data: NZX Copyright [2014] NZX Limited. All
            Rights Reserved.
          </p>
          {(hasAsxLink || hasNzxLink) && (
            <div className="flex flex-wrap">
              {hasNzxLink && (
                <Button
                  className="-ml-6"
                  href={nzxLink.href}
                  target={nzxLink.target}
                  variant={VARIANT.orangeTransparent}
                >
                  <span>{nzxLink.text}</span>
                  <ExternalLink className="w-4 h-4" />
                </Button>
              )}
              {hasAsxLink && (
                <Button
                  className="-ml-6"
                  href={asxLink.href}
                  target={asxLink.target}
                  variant={VARIANT.orangeTransparent}
                >
                  <span>{asxLink.text}</span>
                  <ExternalLink className="w-4 h-4" />
                </Button>
              )}
            </div>
          )}
          <Modal
            primaryModal={primaryModal}
            title={title}
            showOverlay={showOverlay}
            toggleModal={toggleModal}
          />
        </>
      )}
    </div>
  );
};
