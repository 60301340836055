import CustomerTypeItem from "./CustomerTypeItem";
import BusinessTypeItem from "./BusinessTypeItem";
import AddressItem from "./AddressItem";
import FuelItem from "./FuelItem";
import ContactDetailsItem from "./ContactDetailsItem";
import TermSelectItem from "./TermSelectItem";
import CreditCheckItem from "./CreditCheckItem";
import PropertyItem from "./PropertyItem";

export default function BusinessStepNavigationItems() {
  return (
    <>
      <CustomerTypeItem />
      <BusinessTypeItem />
      <AddressItem />
      <FuelItem />
      <ContactDetailsItem />
      <TermSelectItem />
      <CreditCheckItem />
      <PropertyItem />
    </>
  );
}
