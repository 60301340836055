import Checkbox from "../Checkbox";
import styles from "./checkbox-list.module.scss";

export default function CheckboxList(props) {
  const { items, label, name, onChange, errorMessage, showError, size } = props;

  return (
    <div className={styles.container}>
      <p className={styles.label}>{label}</p>
      <div className={styles.row}>
        {items.map((checkboxProps, index) => (
          <div key={index} className={styles.column}>
            <Checkbox
              name={name}
              onChange={onChange}
              size={size}
              {...checkboxProps}
            />
          </div>
        ))}
      </div>
      {showError && (
        <span data-testid={`${name}ErrorMessage`} className={styles.errorText}>
          {errorMessage}
        </span>
      )}
    </div>
  );
}
