import ReactHtmlParser from "react-html-parser";

function getContentWithLineBreaks(content = "") {
  const contentHtml = content.replaceAll("\r\n", "<br />");

  return ReactHtmlParser(contentHtml);
}

function getTextWithoutSpaces(content = "") {
  const textContent = content.replace(/<br\s*\/?>/gi, "");
  const parsedContent = ReactHtmlParser(textContent);

  if (parsedContent.length) {
    return parsedContent[0].trim();
  }

  return "";
}

export { getContentWithLineBreaks, getTextWithoutSpaces };
