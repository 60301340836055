import React from "react";

const GlobalDataContext = React.createContext(null);

function GlobalDataProvider({ globalData, ...props }) {
  return <GlobalDataContext.Provider value={globalData} {...props} />;
}

function useGlobalData() {
  const context = React.useContext(GlobalDataContext);
  if (!context) {
    throw new Error(
      "useGlobalData must be used within a <GlobalDataProvider /> component",
    );
  }
  return context;
}

export { GlobalDataProvider, useGlobalData };
