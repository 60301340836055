import { gtmCreateEcommerceObjectForBusiness } from "actions/gtmActions";
import { createSmeSignup } from "join-form/services/smeOnlineSignup";

export function createSignUp() {
  return async function createSignUpWithStoreState(dispatch, getState) {
    const storeState = getState();
    const result = await createSmeSignup(storeState);
    const { data, requestObject, transactionId } = result;
    dispatch(
      gtmCreateEcommerceObjectForBusiness({ requestObject, transactionId }),
    );

    return data;
  };
}
