import { Icon } from "components/Icon";
import { Input } from "components/JoinFormControls";
import styles from "./address-icp.module.scss";

export function AddressIcpInput(props) {
  const {
    name,
    placeholder,
    value,
    onChange,
    errorMessage,
    showError,
    isBusy,
    disabled,
    readOnly,
  } = props;
  const busyClassName = isBusy ? styles.busy : "";

  return (
    <div className={styles.inputContainer}>
      <Input
        size="28"
        maxLength="15"
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        errorMessage={errorMessage}
        showError={showError}
        className={styles.inputFieldContainer}
        inputClassName={styles.input}
        disabled={disabled}
        readOnly={readOnly}
        isOrangeBackground
      />
      <div
        data-testid="addressIcpInputIconContainer"
        className={`${styles.inputIconContainer} ${busyClassName}`}
      >
        <div className={styles.inputIconText}>{value.length} / 15</div>
        <div className={styles.inputIconLoading}>
          <Icon name="Loading" size="24" />
        </div>
      </div>
    </div>
  );
}
