import { useState } from "react";
import classNames from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import { applyCreditToResidentialTerm } from "helpers/joiningCreditHelper";
import {
  applyDiscountToResidentialTerm,
  getDualFuelDiscount,
  getEBillingDiscount,
  getPaymentMethodDiscountByPlanType,
} from "helpers/joiningDiscountsHelper";
import { isOnlyBottledGasFuelSelected } from "helpers/productHelper";
import { isDesktop } from "helpers/viewportHelper";
import { useSelector } from "react-redux";
import {
  heightFadeTransition,
  heightFadeVariants,
  sidebarFade,
} from "./animations";
import styles from "./page-sidebar-partial.module.scss";
import EnergyPlanButton from "./sub-components/EnergyPlanButton";
import { SidebarHeader } from "./sub-components/SidebarHeader";
import { SidebarItems } from "./sub-components/SidebarItems";

const defaultTermItem = {
  codeName: "",
  discountPercentage: 0,
  credit: "",
  isSelected: false,
};

function applyTermDiscountAndCredit({ termItem, planType, products }) {
  const termItemWithDiscountsApplied = applyDiscountToResidentialTerm({
    termItem,
    planType,
  });

  const termItemWithDiscountAndCreditsApplied = applyCreditToResidentialTerm({
    termItem: termItemWithDiscountsApplied,
    planType,
    products,
  });

  return termItemWithDiscountAndCreditsApplied;
}

const stateSelector = (state, ownProps) => {
  const {
    appState: { planType, products, planUsageType },
    residentialPlanConfig: { termData },
    appUserState: { paymentMethod },
  } = state;

  const termItem = termData.find(({ isSelected }) => isSelected);
  const termItemWithDiscountAndCreditsApplied = termItem
    ? applyTermDiscountAndCredit({
        termItem,
        planType,
        products,
      })
    : defaultTermItem;

  const dualFuelDiscount = getDualFuelDiscount(products);

  const selectedPaymentMethodDiscount = getPaymentMethodDiscountByPlanType({
    paymentMethod,
    planType,
  });

  const eBillingDiscount = getEBillingDiscount(planType);

  return {
    termItem: termItemWithDiscountAndCreditsApplied,
    planType,
    planUsageType,
    products,
    paymentMethod,
    dualFuelDiscount,
    eBillingDiscount,
    selectedPaymentMethodDiscount,
    isOnlyBottledGasSelected: isOnlyBottledGasFuelSelected(products),
  };
};

function ResidentialPageSidebarPartial(props) {
  const {
    termItem,
    planType,
    products,
    paymentMethod,
    dualFuelDiscount,
    eBillingDiscount,
    selectedPaymentMethodDiscount,
  } = useSelector(stateSelector);
  const [plansVisible, togglePlans] = useState(false);

  const onTogglePlansClick = (e) => {
    const newValue = plansVisible ? false : true;
    togglePlans(newValue);
  };

  const plansVisibleCssClass = plansVisible ? styles.plans_visible : "";

  return (
    <motion.div
      initial="initial"
      animate="animate"
      exit="exit"
      variants={sidebarFade}
      className={`${styles.container} ${plansVisibleCssClass}`}
      data-testid="residentialSidebar"
    >
      <EnergyPlanButton
        onClick={onTogglePlansClick}
        termItem={termItem}
        dualFuelDiscount={dualFuelDiscount}
        paymentMethodDiscount={selectedPaymentMethodDiscount}
        eBillingDiscount={eBillingDiscount}
      />
      {isDesktop() ? (
        <>
          <SidebarHeader className="mb-5" />
          <SidebarItems
            planType={planType}
            dualFuelDiscount={dualFuelDiscount}
            termItem={termItem}
            products={products}
            paymentMethod={paymentMethod}
            selectedPaymentMethodDiscount={selectedPaymentMethodDiscount}
          />
        </>
      ) : (
        <AnimatePresence>
          {plansVisible && (
            <motion.div
              initial="initial"
              animate="animate"
              exit="exit"
              variants={heightFadeVariants}
              transition={heightFadeTransition}
              className={classNames(styles.body, "overflow-hidden")}
            >
              <SidebarHeader className="mt-3 mb-5" />
              <SidebarItems
                planType={planType}
                dualFuelDiscount={dualFuelDiscount}
                termItem={termItem}
                products={products}
                paymentMethod={paymentMethod}
                selectedPaymentMethodDiscount={selectedPaymentMethodDiscount}
              />
            </motion.div>
          )}
        </AnimatePresence>
      )}
    </motion.div>
  );
}

export default ResidentialPageSidebarPartial;
