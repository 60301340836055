function filterByYear(documentItem, selectedYear) {
  const documentYear = documentItem.fields.year;
  const result = selectedYear ? documentYear === selectedYear : true;
  return result;
}

function filterByTagId(documentItem, selectedTagIds) {
  if (selectedTagIds.length === 0) {
    return true;
  }
  const tagId = documentItem.fields.tag?.contentID.toString();
  return selectedTagIds.includes(tagId);
}

function findMonthsForSelectedYear(allDates, selectedYear) {
  const allMonths = allDates
    .filter((date) => date.year === selectedYear)
    .map((date) => date.month);

  return allMonths;
}

function toggleItemFromList(list, item) {
  const itemExistsInList = list.includes(item);

  if (itemExistsInList) {
    return list.filter((listItem) => listItem !== item);
  }

  return list.concat(item);
}

function getPaginationState(visibleDocuments, totalDocuments) {
  const totalDocumentsCount = totalDocuments.length;
  const visibleDocumentsCount = Math.min(
    visibleDocuments.length,
    totalDocuments.length,
  );

  if (visibleDocumentsCount === 0) {
    return {
      paginationText: "",
      showLoadMoreButton: false,
    };
  }

  if (visibleDocumentsCount === totalDocumentsCount) {
    return {
      paginationText: "Showing all results",
      showLoadMoreButton: false,
    };
  }

  return {
    paginationText: `Showing ${visibleDocumentsCount} of ${totalDocumentsCount} results`,
    showLoadMoreButton: true,
  };
}

export {
  filterByYear,
  filterByTagId,
  findMonthsForSelectedYear,
  toggleItemFromList,
  getPaginationState,
};
