import classNames from "classnames";
import { Image } from "components/Image";
import { CtaSection } from "./CtaSection";
import { TitleDescriptionSection } from "./TitleDescriptionSection";

const ImageItem = ({ data }) => {
  const { description, image, title } = data;
  return (
    <div className="absolute inset-0 overflow-hidden rounded-7">
      <Image alt={image.label} layout="fill" src={image.url} />
      <TitleDescriptionSection description={description} title={title} />
    </div>
  );
};

export const DesktopView = ({ ctaData, images, alignment }) => {
  const isAlignedLeft = alignment === "left"; // default alignment is right

  return (
    <>
      <div
        className="mx-auto mb-15 md:w-233 lg:w-304"
        data-testid="multiple-image-desktop"
      >
        <div className="flex w-full">
          <div
            className={classNames("md:w-153", "lg:w-200", {
              "order-2": isAlignedLeft,
            })}
          >
            <CtaSection ctaData={ctaData} />
            <div className="flex">
              <div
                className={classNames(
                  "relative",
                  "md:h-68 md:w-53",
                  "lg:h-90 lg:w-70",
                )}
              >
                <ImageItem data={images[0].fields} />
              </div>
              <div
                className={classNames(
                  "relative ml-7",
                  "md:h-68 md:w-93",
                  "lg:h-90 lg:w-122",
                )}
              >
                <ImageItem data={images[1].fields} />
              </div>
            </div>
          </div>
          <div
            className={classNames(
              "self-end mb-34",
              isAlignedLeft ? "order-1 pr-7" : "pl-7",
            )}
          >
            <div
              className={classNames(
                "relative",
                "md:h-93 md:w-73",
                "lg:h-123 lg:w-96",
              )}
            >
              <ImageItem data={images[2].fields} />
            </div>
          </div>
        </div>
        <div className="flex w-full mt-7">
          <div
            className={classNames(
              "flex md:w-153 lg:w-200",
              isAlignedLeft ? "order-2 pl-7" : "justify-end",
            )}
          >
            <div
              className={classNames(
                "relative",
                "md:h-97 md:w-133",
                "lg:h-119 lg:w-161",
              )}
            >
              <ImageItem data={images[3].fields} />
            </div>
          </div>
          <div
            className={classNames(
              "-mt-34",
              isAlignedLeft
                ? "order-1 md:w-73 lg:w-96 flex justify-end"
                : "pl-7",
            )}
          >
            <div
              className={classNames(
                "relative",
                "md:h-81 md:w-63",
                "lg:h-106 lg:w-83",
              )}
            >
              <ImageItem data={images[4].fields} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
