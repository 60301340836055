import React from "react";
import { createSelector } from "@reduxjs/toolkit";
import classNames from "classnames";
import { motion } from "framer-motion";
import { useScreenSize } from "hooks";
import { clearState } from "actions/clearStateActions";
import Intro from "join-form/components/intro";
import { NextSteps } from "join-form/components/nextSteps";
import {
  trackBusinessCallbackSuccessPageLoad,
  trackBusinessSuccessPageLoad,
} from "helpers/gtmHelper";
import {
  selectAppUserState,
  selectGtmState,
} from "reducers/selector";
import { sortBy } from "lodash-es";
import { useDispatch, useSelector } from "react-redux";
import { InlineBlockEditor } from "components/BlockEditor";
import { getPageData } from "helpers/getPageData";
import { CardItem } from "page-modules/CtaCardStatic/CardItem";
import {
  FadeInBottomContainer,
  footerFade,
  footerFadeTransition,
  headerFade,
  itemContainerFade,
  itemFade,
} from "./animations";
import styles from "./success-page.module.scss";
import { useEffectOnce } from "react-use";

const selectBusinessSuccessState = createSelector(
  [selectAppUserState, selectGtmState],
  (appUserState, gtmState) => {
    return {
      callback: !!appUserState.callbackReason,
      businessEcommerce: gtmState.businessEcommerce,
    };
  },
);

const modules = [
  "GE_JOIN_Success_Header",
  "GE_JOIN_Business_Success_Module",
  "GE_JOIN_Help_Live_Chat",
  "GE_JOIN_Help_Phone",
  "GE_CTA_Card_Static",
  "GE_JOIN_Next_Step",
];

function SuccessPage(props) {
  const pageData = getPageData({ modules, page: props.page });
  const { callback, businessEcommerce } = useSelector(
    selectBusinessSuccessState,
  );
  const dispatch = useDispatch();

  const {
    image,
    mobileImage,
    logo,
    text,
    contentText,
    contentTitle,
    helpSectionText,
    helpSectionTitle,
    cards,
    liveChatHelpText,
    phoneHelpText,
    nextStepTitle,
  } = pageData.fields;

  const {
    selectedProducts,
    selectedTermName,
    meanFixedCharges,
    postalCode,
    transactionId,
  } = businessEcommerce;

  const { isSm } = useScreenSize();

  React.useEffect(() => {
    if (window.dataLayer) {
      if (callback) {
        trackBusinessCallbackSuccessPageLoad({ selectedProducts });
      } else {
        trackBusinessSuccessPageLoad({
          selectedProducts,
          selectedTermName,
          meanFixedCharges,
          postalCode,
          transactionId,
        });
      }
    }
  }, [
    selectedProducts,
    selectedTermName,
    meanFixedCharges,
    postalCode,
    transactionId,
    callback,
  ]);

  useEffectOnce(() => {
    // clear signup data
    dispatch(clearState());
  });

  const sortedCards = sortBy(cards, ["properties.itemOrder"]);
  return (
    <motion.div
      className="flex flex-col flex-1 space-y-10 sm:space-y-15"
      data-testid="successPage"
    >
      <motion.div
        className={styles["header-wrapper"]}
        initial="initial"
        animate="animate"
        exit="exit"
        variants={headerFade}
      >
        <div
          className={styles["header-background"]}
          style={{
            backgroundImage: `url(${isSm ? image?.url : mobileImage?.url})`,
          }}
        >
          <a href="/" data-testid="homePage">
            <img
              className={styles["header-logo"]}
              src={logo.url}
              alt={logo.label}
            />
          </a>

          <h1 className={styles["header-text"]} data-testid="heading">
            <InlineBlockEditor
              jsonString={text}
              className="prose-p:text-50/60 prose-p:md:text-80/96 prose-p:text-white"
            />
          </h1>
        </div>
      </motion.div>

      <div className="px-12 md:px-27">
        <div className={classNames("w-137 max-w-full mx-auto", "md:w-306")}>
          <motion.div
            initial="initial"
            animate="animate"
            exit="exit"
            variants={itemContainerFade}
            className={classNames(
              "flex flex-col-reverse mx-auto max-w-full",
              "md:grid md:grid-cols-12 gap-x-6",
            )}
          >
            <motion.div variants={itemFade} className="md:col-span-7">
              <Intro className="mt-15 md:mt-0" noStyle>
                {(introStyles) => (
                  <>
                    <h1 className={introStyles.title}>{contentTitle}</h1>
                    <p className={introStyles.text}>{contentText}</p>
                    <h2 className={introStyles.section_title}>
                      {helpSectionTitle}
                    </h2>
                    <p className={introStyles.text}>{helpSectionText}</p>

                    <ul className={introStyles.link_list}>
                      <li className={introStyles.link_list_item}>
                        <InlineBlockEditor
                          jsonString={liveChatHelpText}
                          className="prose-p:text-14/20 prose-p:md:text-16/24 prose-a:no-underline"
                        />
                      </li>
                      <li className={introStyles.link_list_item}>
                        <InlineBlockEditor
                          jsonString={phoneHelpText}
                          className="prose-p:text-14/20 prose-p:md:text-16/24 prose-a:no-underline"
                        />
                      </li>
                    </ul>
                  </>
                )}
              </Intro>
            </motion.div>
          </motion.div>
          <motion.hr
            initial="initial"
            animate="animate"
            exit="exit"
            variants={footerFade}
            transition={footerFadeTransition}
            className={styles.separator}
          />

          <NextSteps title={nextStepTitle}>
            <FadeInBottomContainer>
              <div
                className={classNames(
                  "space-y-2",
                  "md:space-y-0 md:flex md:space-x-2",
                )}
              >
                {sortedCards.map((item, index) => {
                  const isEven = (index + 1) % 2 === 0;
                  return (
                    <CardItem
                      key={item.contentID}
                      item={item}
                      isEven={isEven}
                      className="md:col-span-8"
                    />
                  );
                })}
              </div>
            </FadeInBottomContainer>
          </NextSteps>
        </div>
      </div>
    </motion.div>
  );
}

export default SuccessPage;
