module.exports = [
  "./src/hooks/**/*.{js,ts,jsx,tsx}",
  "./src/pages/**/*.{js,ts,jsx,tsx}",
  "./src/page-layouts/**/*.{js,ts,jsx,tsx}",
  "./src/page-modules/**/*.{js,ts,jsx,tsx}",
  "./src/page-templates/**/*.{js,ts,jsx,tsx}",
  "./src/components/**/*.{js,ts,jsx,tsx}",
  "./src/join-form/**/*.{js,ts,jsx,tsx}",
  "./public/formStackEmbedMarkup.html",
  "./public/vhtWidgetMarkup.html",
  "./node_modules/react-instantsearch-hooks-web/**/*.js",
];
