import { sampleSize } from "lodash-es";

export const getCustomInitialProps = ({ item: module }) => {
  if (!module?.fields?.imageSnippets) return [];

  // mobile dimensions
  const landscapeDimensions = { h: 272, w: 368 };
  const portraitDimensions = { h: 272, w: 211 };

  const isLandscape = (item) =>
    item.fields.image.height < item.fields.image.width;
  const isPortrait = (item) =>
    item.fields.image.height > item.fields.image.width;

  // determine orientation
  let landscapeImages = module.fields.imageSnippets
    .filter(isLandscape)
    .map((item) => {
      // add mobile dimensions
      item.fields.image.h = landscapeDimensions.h;
      item.fields.image.w = landscapeDimensions.w;
      return item;
    });
  let portraitImages = module.fields.imageSnippets
    .filter(isPortrait)
    .map((item) => {
      // add mobile dimensions
      item.fields.image.h = portraitDimensions.h;
      item.fields.image.w = portraitDimensions.w;
      return item;
    });

  // randomise images
  landscapeImages = sampleSize(landscapeImages, 2);
  portraitImages = sampleSize(portraitImages, 3);

  // organize images aesthetically
  return [
    portraitImages[0],
    landscapeImages[0],
    portraitImages[1],
    landscapeImages[1],
    portraitImages[2],
  ];
};
