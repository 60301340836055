import { useEffect, useRef, useState } from "react";
import { AnimatePresence } from "framer-motion";
import { debounce, isUndefined } from "lodash-es";
import Autosuggest from "react-autosuggest";
import styles from "./address-auto-suggest.module.scss";
import {
  fetchAddressDetails,
  fetchIcpNumber,
  fetchSuggestions,
  mergeElecAndGasIcpData,
  theme,
} from "./helpers";
import {
  Input,
  NoResults,
  RegistrySuggestionItem,
  SuggestionItem,
} from "./sub-components";
import { SuggestionsContainer } from "./sub-components/SuggestionsContainer";

export function AddressAutoSuggest(props) {
  const {
    initialValue = "",
    onAddressSelectionReset,
    onAddressSelectionComplete,
    renderSuggestionsContainerHeader,
    renderSuggestionsContainerFooter,
    renderNoResults = NoResults,
    disabled,
    setRecentlyFailedApi,
  } = props;

  // state
  const defaultIcpData = {
    elecIcp: "",
    elecHasMultipleResults: false,
    gasIcp: "",
    gasHasMultipleResults: false,
    result: {
      electricityList: [],
    },
  };

  const mountedRef = useRef(true);
  const [inputValue, setInputValue] = useState(initialValue);
  const [isBusy, setBusy] = useState(false);
  const [alwaysRenderSuggestions, setAlwaysRenderSuggestions] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [noResults, setNoResults] = useState(false);
  const [inputDisabled, setInputDisabled] = useState(false);
  const [addressFromFinder, setAddressFromFinder] = useState({});
  const [icpData, setIcpData] = useState(defaultIcpData);

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  const getSuggestionValue = (suggestion) => {
    if (suggestion.registryAddress) {
      return inputValue;
    }
    return suggestion.a;
  };

  // events
  const onInputChange = (e, { newValue }) => {
    if (typeof newValue !== "undefined") {
      setInputValue(newValue);
    }
    setNoResults(false);
    onAddressSelectionReset();
    const { elecHasMultipleResults } = icpData;
    if (elecHasMultipleResults) {
      setIcpData(defaultIcpData);
      setSuggestions([]);
    }
  };

  const onSuggestionSelected = async (event, { suggestion }) => {
    setBusy(true);
    setSuggestions([]);
    setNoResults(false);

    if (suggestion.registryAddress) {
      setBusy(false);

      const mergedIcpData = mergeElecAndGasIcpData(icpData, suggestion);
      onAddressSelectionComplete({
        icpData: mergedIcpData,
        addressFromFinder,
      });
      return;
    }

    setInputDisabled(true);

    const address = await fetchAddressDetails(suggestion).catch((error) => {
      console.error(error);
      return {};
    });

    const icpResult = await fetchIcpNumber(address)
      .then((data) => {
        if (setRecentlyFailedApi) {
          setRecentlyFailedApi("");
        }
        return data;
      })
      .catch((error) => {
        if (setRecentlyFailedApi) {
          setRecentlyFailedApi("getIcpList");
        }
        console.error(error);
        return defaultIcpData;
      });

    const newSuggestions =
      icpResult && icpResult.elecHasMultipleResults
        ? icpResult.result.electricityList
        : [];

    setInputDisabled(false);
    setAddressFromFinder(address);
    setIcpData(icpResult);
    setSuggestions(newSuggestions);
    setAlwaysRenderSuggestions(icpResult.elecHasMultipleResults);
    setBusy(false);

    if (icpResult.elecHasMultipleResults === false) {
      const mergedIcpData = mergeElecAndGasIcpData(icpResult);
      onAddressSelectionComplete({
        icpData: mergedIcpData,
        addressFromFinder: address,
      });
    }
  };

  const onSuggestionsClearRequested = () => {
    if (noResults) {
      return;
    }

    const { elecHasMultipleResults } = icpData;
    if (elecHasMultipleResults) {
      return;
    }

    if (suggestions.length) {
      return;
    }

    setSuggestions([]);
  };

  const onSuggestionsFetchRequestedDebounced = useRef(
    debounce(async (data) => {
      const result = await fetchSuggestions(data);
      const noResultsReturned = result.length === 0;
      setSuggestions(result);
      setNoResults(noResultsReturned);
      setAlwaysRenderSuggestions(noResultsReturned);
      setBusy(false);
    }, 200),
  ).current;

  const onSuggestionsFetchRequested = async (data) => {
    const { elecHasMultipleResults } = icpData;
    if (elecHasMultipleResults) {
      return;
    }

    const { value, reason } = data;
    if (isUndefined(value)) {
      return;
    }
    if (reason === "suggestion-selected" || reason === "input-focused") {
      return;
    }

    const inputLength = value.replace(/[^a-z]/gi, "").length;
    if (inputLength < 3) {
      return;
    }

    setBusy(true);
    onSuggestionsFetchRequestedDebounced(data);
  };

  // components
  const renderSuggestionsContainer = ({ containerProps, children }) => {
    const { elecHasMultipleResults } = icpData;

    return (
      <AnimatePresence>
        {children ? (
          <SuggestionsContainer
            containerProps={containerProps}
            hasMultipleRegistryResults={elecHasMultipleResults}
            addressFromFinder={addressFromFinder}
            renderHeader={renderSuggestionsContainerHeader}
            renderFooter={renderSuggestionsContainerFooter}
          >
            {children}
          </SuggestionsContainer>
        ) : (
          noResults && renderNoResults()
        )}
      </AnimatePresence>
    );
  };

  const renderSuggestionItem = (suggestion, suggestionItemProps) => {
    return suggestion.registryAddress ? (
      <RegistrySuggestionItem
        suggestion={suggestion}
        {...suggestionItemProps}
      />
    ) : (
      <SuggestionItem suggestion={suggestion} {...suggestionItemProps} />
    );
  };

  const renderInput = (renderInputProps) => {
    return (
      <Input
        inputProps={renderInputProps}
        isBusy={isBusy}
        disabled={inputDisabled || disabled}
      />
    );
  };

  // props
  const inputProps = {
    placeholder: "Enter your street address",
    value: inputValue,
    onChange: onInputChange,
  };

  return (
    <div className={styles.wrapper}>
      <Autosuggest
        alwaysRenderSuggestions={alwaysRenderSuggestions}
        suggestions={suggestions}
        inputProps={inputProps}
        getSuggestionValue={getSuggestionValue}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        onSuggestionSelected={onSuggestionSelected}
        renderInputComponent={renderInput}
        renderSuggestionsContainer={renderSuggestionsContainer}
        renderSuggestion={renderSuggestionItem}
        theme={theme}
      />
    </div>
  );
}
