import { useState } from "react";
import { createSelector } from "@reduxjs/toolkit";
import { setProductSelection } from "actions/appState/appStateActions";
import { motion } from "framer-motion";
import { useRouter } from "next/router";
import { useDispatch, useSelector } from "react-redux";
import { selectAppState, selectIcpState } from "reducers/selector";
import { ButtonOnOffToggle } from "components/ButtonOnOffToggle";
import { StepProgressionButton } from "components/StepProgressionButton";
import { shouldRedirectToResidentialCallback } from "helpers/callbackHelper";
import { appResidentialRoutes } from "helpers/constants";
import { gaJoinFormResidentialContinueButton } from "helpers/gaHelper";
import {
  buildFuelOptionList,
  selectProductsByIcp,
  validateProductSelection,
} from "helpers/residentialProductsHelper";
import { itemContainer, itemFade } from "./animations";
import styles from "./fuel-page.module.scss";
import { Alert } from "components/Alert";
import classNames from "classnames";

// selectors
const selectResidentialFuelType = createSelector(
  [selectAppState, selectIcpState],
  (appState, icpState) => {
    const { products: appStateProducts, recentlyFailedApi } = appState;
    const { gasIcp } = icpState;

    const isProductSelectionValid = validateProductSelection({
      products: appStateProducts,
    });

    const products = isProductSelectionValid
      ? appStateProducts
      : selectProductsByIcp({ products: appStateProducts, gasIcp });

    const fuelOptionList = buildFuelOptionList({ products, gasIcp });

    return { products, recentlyFailedApi, fuelOptionList };
  },
);

export function ResidentialFuelType({ module }) {
  const { fuelOptions, copy, cta } = module.fields;
  const {
    products,
    recentlyFailedApi,
    fuelOptionList: initialFuelOptionList,
  } = useSelector(selectResidentialFuelType);

  const dispatch = useDispatch();
  const router = useRouter();
  const [fuelOptionList, updateFuelOptionList] = useState(
    initialFuelOptionList,
  );

  const onContinueClick = (e) => {
    e.preventDefault();
    const { productIds, title } = fuelOptionList.find(
      ({ isSelected }) => isSelected,
    );
    const productSelection = products.map((productInfo) => {
      const isSelected = productIds.includes(productInfo.name);
      return {
        ...productInfo,
        isSelected,
      };
    });
    dispatch(setProductSelection(productSelection));
    gaJoinFormResidentialContinueButton({
      event: "select_fuel",
      content_tertiary: "select fuel",
      selection: title,
    });
    if (shouldRedirectToResidentialCallback(recentlyFailedApi)) {
      return router.push({
        pathname: appResidentialRoutes.callback,
      });
    }
    router.push({
      pathname: appResidentialRoutes.planSelect,
    });
  };

  const onProductListClick = (selectedFuelOption) => {
    if (selectedFuelOption.isSelected) {
      return;
    }
    const updatedFuelOptionList = fuelOptionList.map((fuelOption) => {
      const isSelected = fuelOption.id === selectedFuelOption.id;
      return {
        ...fuelOption,
        isSelected,
      };
    });
    updateFuelOptionList(updatedFuelOptionList);
  };

  return (
    <motion.div
      initial="initial"
      animate="animate"
      exit="exit"
      variants={itemContainer}
      className="max-w-full px-4 mx-auto w-158 md:w-210"
    >
      {fuelOptions.map((option) => {
        const { title, subtitle, selectedIcon, defaultIcon, id } =
          option.fields;

        const fuelOption = fuelOptionList.find((item) => item.id === id);

        if (fuelOption) {
          return (
            <motion.div
              className={styles.selection_item}
              variants={itemFade}
              key={id}
            >
              <ButtonOnOffToggle
                isSelected={fuelOption.isSelected}
                onClick={() => onProductListClick(fuelOption)}
                icon={fuelOption.isSelected ? selectedIcon : defaultIcon}
                subTitle={subtitle}
                title={title}
              />
            </motion.div>
          );
        }

        return null;
      })}
      {copy && (
        <Alert
          className="md:!p-5"
          contentContainerClassName={classNames(
            "[&_p]:my-0 md:[&_p]:text-16/24 [&_p]:font-normal-plus",
          )}
          ctaClassName="h-10 w-31 py-0"
          fields={{
            copy: copy.replace("~/", "/"),
            url: cta,
          }}
        />
      )}
      <div className="mt-10 overflow-hidden md:mt-15 lg:mt-20"></div>
      <StepProgressionButton
        onContinueClick={onContinueClick}
        backLink={appResidentialRoutes.address}
      />
    </motion.div>
  );
}
