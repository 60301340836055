import FeatureItem from "join-form/components/feature-item";
import {
  PAYMENT_METHOD_CREDIT_CARD,
  PAYMENT_METHOD_DIRECT_DEBIT,
  PAYMENT_METHOD_MANUAL,
  PLAN_TYPE_BASIC,
  PLAN_TYPE_EV_PLUS,
} from "helpers/constants";
import { useSelector } from "react-redux";

function getPaymentType(paymentMethod) {
  switch (paymentMethod) {
    case PAYMENT_METHOD_DIRECT_DEBIT:
      return "Direct Debit";
    case PAYMENT_METHOD_CREDIT_CARD:
      return "Credit Card";
    case PAYMENT_METHOD_MANUAL:
    default:
      return "";
  }
}

export default function AutoPayFeature({ paymentMethod, discount, ...rest }) {
  const { planType, isExistingCustomer } = useSelector(
    ({ appState, appUserState }) => {
      return {
        planType: appState.planType,
        isExistingCustomer: appUserState.isExistingCustomer,
      };
    },
  );

  if (planType === PLAN_TYPE_EV_PLUS && isExistingCustomer === "true") {
    return null;
  }

  const paymentType = getPaymentType(paymentMethod);

  if (
    !paymentMethod ||
    (paymentMethod === PAYMENT_METHOD_MANUAL && planType === PLAN_TYPE_BASIC)
  ) {
    return <FeatureItem {...rest}>Choose how you pay</FeatureItem>;
  }

  if (planType === PLAN_TYPE_BASIC) {
    return (
      <FeatureItem type="complete" {...rest}>
        Auto-pay, {paymentType}
      </FeatureItem>
    );
  }

  if (paymentMethod === PAYMENT_METHOD_MANUAL) {
    return (
      <FeatureItem type="complete" {...rest}>
        Manual, no Auto-pay discount
      </FeatureItem>
    );
  }

  if (discount > 0) {
    return (
      <FeatureItem type="complete" {...rest}>
        {discount}% {paymentType} discount
      </FeatureItem>
    );
  }
}
