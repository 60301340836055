import { useState } from "react";
import classNames from "classnames";
import { CallToAction } from "components/CallToAction";
import { Image } from "components/Image";
import { THEME, VARIANT } from "helpers/constants";
import { getContentWithLineBreaks } from "helpers/htmlHelper";
import { FadeInBottomAnimation } from "./animations";
import { ContentFeatureCardList } from "./ContentFeatureCardList";

function ContentFeatureCardItem({ item, className, theme }) {
  const { cta, description, image, title, subtitle, list } = item.fields;
  const [imageError, updateImageError] = useState(false);
  const variantStyles = {
    heading: theme === THEME.orange ? "text-white" : "text-warm-orange-primary",
    paragraph: theme === THEME.orange ? "text-white" : "text-black-primary",
    cta:
      theme === THEME.orange
        ? VARIANT.lightTransparent
        : VARIANT.darkTransparent,
  };
  return (
    <div className={className} data-testid="contentFeatureCardItem">
      <FadeInBottomAnimation>
        {image?.url ? (
          <div
            className={classNames("w-9 h-9 mb-4", "md:w-12 md:h-12 md:mb-5")}
            data-testid="contentFeatureCardItemImage"
          >
            <Image
              src={image.url}
              alt={image.label}
              width={60}
              height={60}
              onError={() => updateImageError(true)}
              className={classNames({
                "sr-only": imageError,
              })}
            />
          </div>
        ) : null}
        <h1
          className={classNames(
            "text-27/32 font-bold mb-2",
            "md:text-32/36 md:mb-3",
            variantStyles.heading,
          )}
        >
          {title}
        </h1>
        {subtitle && (
          <p
            className={classNames(
              "text-18/28 font-medium mb-2",
              "md:text-24/36 md:mb-3",
              variantStyles.paragraph,
            )}
          >
            {subtitle}
          </p>
        )}
        <p
          className={classNames(
            "text-15/24",
            "md:text-18/28",
            variantStyles.paragraph,
          )}
        >
          {getContentWithLineBreaks(description)}
        </p>
        <ContentFeatureCardList data={list} theme={theme} />
        <div className="flex">
          <CallToAction
            className="!pl-0 !pb-0 mt-1 md:mt-2"
            data={cta}
            dataGa={{
              content_tertiary: title,
              event: "cta_click",
              event_category: "cta",
              event_label: [cta?.text],
            }}
            variant={variantStyles.cta}
            testid="contentFeatureCardItemCta"
          />
        </div>
      </FadeInBottomAnimation>
    </div>
  );
}

export { ContentFeatureCardItem };
