import classNames from "classnames";
import { CheckboxOff, CheckboxOn } from "assets/icons";
import {
  PLAN_TYPE_EV_PLUS,
  PLAN_TYPE_PLUS,
  PLAN_USAGE_HIGH,
  PLAN_USAGE_LOW,
} from "helpers/constants";
import { BottledGasRates } from "./BottledGasRates";
import { ElectricityRates } from "./ElectricityRates";
import { NaturalGasRates } from "./NaturalGasRates";
import { PlanCardTooltip } from "./PlanCardTooltip";

function PlanCardRates({
  radioButtonName,
  planType,
  planUsageType,
  inclGst,
  onGstChange,
  onUsageChange,
  testIdPrefix,
  showDayNightRatesInfo,
}) {
  const naturalGasPlanType =
    planType === PLAN_TYPE_EV_PLUS ? PLAN_TYPE_PLUS : planType;
  return (
    <div className="flex flex-col py-1 gap-y-7 px-7">
      <hr className="h-px bg-black-100" />
      <div className="flex flex-col gap-y-4">
        <div className="flex flex-col gap-y-4">
          <div className="flex justify-between">
            <p className="flex gap-x-0.5 items-start text-15/24 md:text-18/28 text-black-primary font-medium">
              <span>Select usage type</span>
              <PlanCardTooltip testIdPrefix={`${testIdPrefix}_usage`}>
                <h4 className="font-medium text-16/24">Low usage</h4>
                <p className="text-14/20">
                  For small, energy-efficient households of 1 or 2 people who
                  may have one EV, 8000 kWh or less of electricity (9000 kWh for
                  the lower South Island) and 4000 kWh for Natural Gas per year.
                </p>
                <h4 className="font-medium text-16/24">Standard usage</h4>
                <p className="text-14/20">
                  For 3 or more people who rely on electricity for heating to
                  keep the house warm. More than 8000 kWh of electricity (9000
                  kWh for the lower South Island) and 4000 kWh for Natural Gas
                  per year.
                </p>
              </PlanCardTooltip>
            </p>
            <label className="flex items-center cursor-pointer gap-x-2">
              <span className="font-medium text-black-primary text-12/20">
                GST
              </span>
              <div className="relative text-warm-orange-primary">
                <input
                  type="checkbox"
                  data-testid="ratesGstCheckbox"
                  className="hidden peer"
                  checked={inclGst}
                  onChange={onGstChange}
                />
                <CheckboxOff className="fill-current size-5" />
                <CheckboxOn
                  className={classNames(
                    "absolute inset-0 size-5",
                    "fill-current transition-opacity",
                    "opacity-0 peer-checked:opacity-100",
                  )}
                />
              </div>
            </label>
          </div>
        </div>

        <div className="flex gap-x-5">
          <label className="flex items-center cursor-pointer gap-x-2">
            <span
              className={classNames(
                "flex items-center justify-center size-6",
                "rounded-full border-2 border-warm-orange-primary",
              )}
            >
              <input
                name={radioButtonName}
                data-testid={`${testIdPrefix}_${PLAN_USAGE_LOW}_radio`}
                type="radio"
                className={classNames(
                  "rounded-full appearance-none",
                  "size-4 checked:bg-warm-orange-primary",
                )}
                value={PLAN_USAGE_LOW}
                checked={planUsageType === PLAN_USAGE_LOW}
                onChange={onUsageChange}
              />
            </span>
            <span className="text-14/20 text-black-primary">Low</span>
          </label>
          <label className="flex items-center cursor-pointer gap-x-2">
            <span
              className={classNames(
                "flex items-center justify-center size-6",
                "rounded-full border-2 border-warm-orange-primary",
              )}
            >
              <input
                name={radioButtonName}
                data-testid={`${testIdPrefix}_${PLAN_USAGE_HIGH}_radio`}
                type="radio"
                className={classNames(
                  "rounded-full appearance-none",
                  "size-4 checked:bg-warm-orange-primary",
                )}
                value={PLAN_USAGE_HIGH}
                checked={planUsageType === PLAN_USAGE_HIGH}
                onChange={onUsageChange}
              />
            </span>
            <span className="text-14/20 text-black-primary">Standard</span>
          </label>
        </div>

        <ElectricityRates
          testIdPrefix={testIdPrefix}
          planType={planType}
          usageType={planUsageType}
          inclGst={inclGst}
          showDayNightRatesInfo={showDayNightRatesInfo}
        />
        <NaturalGasRates
          testIdPrefix={testIdPrefix}
          planType={naturalGasPlanType}
          usageType={planUsageType}
          inclGst={inclGst}
        />
        <BottledGasRates
          testIdPrefix={testIdPrefix}
          planType={planType}
          usageType={planUsageType}
          inclGst={inclGst}
        />
      </div>
    </div>
  );
}

export { PlanCardRates };
