import { Image } from "components/Image";
import styles from "./feedback.module.scss";

const typeClassNameMapping = {
  info: styles.feedback_info,
  success: styles.feedback_success,
  warning: styles.feedback_warning,
  error: styles.feedback_error,
};

const typeIconMapping = {
  info: "/images/Info.svg",
  success: "/images/Success.svg",
  warning: "/images/Warning.svg",
  error: "/images/Error.svg",
}

export default function Feedback(props) {
  const { type = "info", children, title, ...rest } = props;

  const typeClassName = typeClassNameMapping[type] || "";
  const typeIcon = typeIconMapping[type] || "";

  return (
    <div className={typeClassName} {...rest}>
      <div className={styles.feedback_icon_container}>
        <Image
          src={typeIcon}
          className={styles.feedback_icon}
          alt=""
          width={20}
          height={20}
        />
      </div>
      <div className={styles.feedback_content}>
        {title && <h4 className={styles.feedback_title}>{title}</h4>}
        {children && <p className={styles.feedback_text}>{children}</p>}
      </div>
    </div>
  );
}
