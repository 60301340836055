import FeatureItem, {
  FeatureItemContainer,
} from "join-form/components/feature-item";
import {
  EBILLING_DISCOUNT,
  PLAN_TYPE_BASIC,
  PLAN_TYPE_EV_PLUS,
} from "helpers/constants";
import styles from "../page-sidebar-partial.module.scss";
import AutoPayFeature from "./AutoPayFeature";
import PlusPlanCredit from "./PlusPlanCredit";
import TermFeature from "./TermFeature";

export const SidebarItems = ({
  planType,
  dualFuelDiscount,
  termItem,
  products,
  paymentMethod,
  selectedPaymentMethodDiscount,
}) => {
  return (
    <FeatureItemContainer className={styles.feature_list_container}>
      {planType === PLAN_TYPE_EV_PLUS && (
        <FeatureItem data-testid="evHalfRates" type="complete">
          50% electricity rates, 9pm - 7am
        </FeatureItem>
      )}
      {planType !== PLAN_TYPE_BASIC && (
        <FeatureItem data-testid="powerShout" type="complete">
          Free Power Shout Hours
        </FeatureItem>
      )}
      {dualFuelDiscount > 0 && (
        <FeatureItem data-testid="dualFuelDiscount" type="complete">
          {dualFuelDiscount}% Dual Fuel discount
        </FeatureItem>
      )}
      {planType !== PLAN_TYPE_BASIC && (
        <FeatureItem data-testid="eBillingDiscount" type="complete">
          {EBILLING_DISCOUNT}% eBilling discount
        </FeatureItem>
      )}
      <PlusPlanCredit
        termItem={termItem}
        products={products}
        selectedPlanType={planType}
        data-testid="plusPlanCredit"
      />
      <TermFeature
        termItem={termItem}
        planType={planType}
        data-testid="planTerm"
      />
      <AutoPayFeature
        paymentMethod={paymentMethod}
        discount={selectedPaymentMethodDiscount}
        data-testid="paymentDiscount"
      />
    </FeatureItemContainer>
  );
};
