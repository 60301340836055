import { useCallback } from "react";
import classNames from "classnames";
import { tailWindConfig } from "getConfig";
import { getTrackBackground, Range } from "react-range";
import {
  Area,
  Bar,
  BarChart,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import {
  CHART,
  GridMask,
  LegendContent,
  renderGrid,
  TooltipContent,
} from "components/Chart";
import { formatVolume } from "helpers/numberStringHelper";

const { colors } = tailWindConfig.theme;

// chart ui config
export const config = {
  colours: {
    black50: colors.black[50],
    black100: colors.black[100],
    blackPrimary: colors.black.primary,
    orange100: colors.orange[100],
    orangePrimary: colors.orange.primary,
    warmOrangePrimary: colors["warm-orange"].primary,
    yellowPrimary: colors.yellow.primary,
  },
  tooltip: {
    cursor: {
      strokeDasharray: "12 6",
      strokeWidth: 2,
    },
  },
  xAxis: {
    axisLine: false,
    tickLine: false,
  },
  yAxis: {
    axisLine: false,
    domain: ["auto", "auto"],
    padding: {
      top: 55,
    },
    width: 70,
  },
};

const CustomizedXaxisTick = (props) => {
  const hideDays = Number(props.timeline) > 1;
  const text = props.payload.value.trim().split(" ");
  /* eslint-disable no-unused-vars */
  const {
    index,
    payload,
    tickFormatter,
    verticalAnchor,
    visibleTicksCount,
    ...textProps
  } = props;
  /* eslint-enable no-unused-vars */

  return (
    <text
      {...textProps}
      className="text-black-primary text-13/20 md:text-16/24 md:translate-y-2"
      fill="#464958"
    >
      {!hideDays && (
        <tspan x={textProps.x} dy="15" className="font-medium">
          {text[0]}
        </tspan>
      )}
      <tspan x={textProps.x} dy="20">
        {text[1]}
      </tspan>
    </text>
  );
};

const CustomYaxisTick = (props) => {
  const {
    chart,
    payload: { value },
  } = props;
  /* eslint-disable no-unused-vars */
  const {
    index,
    payload,
    tickFormatter,
    verticalAnchor,
    visibleTicksCount,
    ...textProps
  } = props;
  /* eslint-enable no-unused-vars */
  const textValue = chart === CHART.volume ? formatVolume(value / 1000) : value;
  return (
    <text
      {...textProps}
      className="text-13/20 md:text-16/24 md:translate-y-2"
      fill="#464958"
    >
      <tspan dx={-8} x={textProps.x}>
        {textValue}
      </tspan>
    </text>
  );
};

const CustomYaxisLabel = (props) => {
  const {
    value,
    viewBox: { width, x, y },
  } = props;
  const smallText = value === "Price";
  return (
    <text
      className={classNames(
        "translate-y-4 translate-x-2 text-14/20",
        {
          "translate-x-6 md:translate-x-5": smallText,
        },
        "md:text-16/24 md:translate-x-0",
      )}
      fill="#464958"
      y={y}
      width={width}
    >
      <tspan dx={-3} x={x} className="font-medium">
        {value}
      </tspan>
    </text>
  );
};

export const AreaChartComponent = ({ data, isVisibleNZX50, timeline }) => {
  if (!data.length) return null;

  return (
    <>
      <ResponsiveContainer width="100%" aspect={1.9}>
        <ComposedChart data={data} syncId="shares">
          <defs>
            <linearGradient id="priceGradient" x1="0" y1="0" x2="0" y2="1">
              <stop
                offset="0%"
                stopColor={config.colours.orange100}
                stopOpacity={1}
              />
              <stop
                offset="85%"
                stopColor={config.colours.orange100}
                stopOpacity={0.0}
              />
            </linearGradient>
          </defs>
          {renderGrid(config.colours.black100)}
          <Tooltip
            chart={CHART.price}
            content={<TooltipContent />}
            cursor={{
              fill: "none",
              stroke: config.colours.orange100,
              ...config.tooltip.cursor,
            }}
          />
          <Legend
            align="center"
            content={<LegendContent />}
            height={20}
            iconSize={12}
            iconType="circle"
          />
          <XAxis
            dataKey="date"
            tick={(props) => (
              <CustomizedXaxisTick {...props} timeline={timeline} />
            )}
            {...config.xAxis}
          />
          <YAxis
            dataKey="close"
            label={<CustomYaxisLabel value="Price" />}
            tickLine={{ strokeDasharray: 3, stroke: config.colours.black100 }}
            tick={<CustomYaxisTick />}
            yAxisId="GNE"
            {...config.yAxis}
          />
          <Line
            dataKey="nzx"
            dot={false}
            hide={!isVisibleNZX50}
            stroke={config.colours.blackPrimary}
            strokeWidth={2}
            type="monotoneX"
            yAxisId="NZX50"
          />
          <Area
            dataKey="close"
            activeDot={{
              r: 8,
            }}
            fill="url(#priceGradient)"
            stroke={config.colours.orangePrimary}
            strokeLinecap="round"
            strokeWidth={4}
            type="monotoneX"
            yAxisId="GNE"
          />
          <YAxis dataKey="nzx" hide={true} yAxisId="NZX50" {...config.yAxis} />
        </ComposedChart>
      </ResponsiveContainer>
      <GridMask width={config.yAxis.width} />
    </>
  );
};

export const BarChartComponent = ({ data, timeline }) => {
  if (!data.length) return null;

  return (
    <>
      <ResponsiveContainer width="100%" aspect={1.9}>
        <BarChart data={data} syncId="shares">
          <defs>
            <linearGradient id="volumeGradient" x1="0" y1="0" x2="0" y2="1">
              <stop offset="10%" stopColor={config.colours.warmOrangePrimary} />
              <stop offset="40%" stopColor={config.colours.orangePrimary} />
              <stop offset="100%" stopColor="#FCB60C" />
            </linearGradient>
          </defs>
          <CartesianGrid
            vertical={false}
            strokeDasharray="3"
            stroke={config.colours.black100}
          />
          {renderGrid(config.colours.black100)}
          <Tooltip
            chart={CHART.volume}
            content={<TooltipContent />}
            cursor={{
              fill: "#f8f8f8",
              ...config.tooltip.cursor,
            }}
          />
          <XAxis
            dataKey="date"
            height={50}
            tick={(props) => (
              <CustomizedXaxisTick {...props} timeline={timeline} />
            )}
            {...config.xAxis}
          />
          <YAxis
            dataKey="volume"
            label={<CustomYaxisLabel value="Volume" />}
            tickLine={false}
            tick={<CustomYaxisTick chart={CHART.volume} />}
            {...config.yAxis}
          />
          <Bar
            dataKey="volume"
            fill="url(#volumeGradient)"
            radius={[10, 10, 0, 0]}
            maxBarSize={10}
          />
        </BarChart>
      </ResponsiveContainer>
      <GridMask width={config.yAxis.width} />
    </>
  );
};

export const RangeSlider = ({ data, range, setRange }) => {
  // pass date to tooltip
  const getDateFromIndex = useCallback(
    (tooltipIndex) => {
      const dateIndex = data.findIndex(
        (_item, itemIndex) => itemIndex === range.values[tooltipIndex],
      );
      if (dateIndex > 0 && dateIndex < range.max) return data[dateIndex].date;
    },
    [data, range.max, range.values],
  );
  return (
    <Range
      values={range.values}
      step={1}
      min={0}
      max={range.max}
      onChange={(values) => {
        setRange({ ...range, values }); // update tooltip instantly
      }}
      onFinalChange={
        (values) => setRange({ ...range, values, valuesDebounced: values }) // debounce chart re-render for efficiency
      }
      renderTrack={({ props, children }) => (
        <div
          onMouseDown={props.onMouseDown}
          onTouchStart={props.onTouchStart}
          className="flex h-8 pr-2 mb-5 ml-2"
          data-testid="range-slider"
          style={{ paddingLeft: config.yAxis.width }}
        >
          <div
            ref={props.ref}
            className="h-[3px] w-full rounded-sm self-center"
            style={{
              background: getTrackBackground({
                values: range.values,
                colors: [
                  config.colours.black50,
                  config.colours.warmOrangePrimary,
                  config.colours.black50,
                ],
                min: 0,
                max: range.max,
              }),
            }}
          >
            {children}
          </div>
        </div>
      )}
      renderThumb={({ index, props }) => (
        <div
          {...props}
          className="flex items-center justify-center w-4 h-4 rounded-full bg-warm-orange-primary focus:outline-none"
        >
          <div className="absolute w-12 text-xs font-medium text-center -bottom-5 text-black-primary">
            {getDateFromIndex(index)}
          </div>
        </div>
      )}
    />
  );
};
