import classNames from "classnames";
import { sortBy } from "lodash-es";
import { ModuleWrapper } from "components/Wrappers";
import { FadeInBottomContainer } from "./animations";
import { CardItem } from "./CardItem";

function CtaCardStatic({ module }) {
  const { anchor, cards } = module.fields;

  if (!cards?.length) {
    return null;
  }

  const sortedCards = sortBy(cards, ["properties.itemOrder"]);

  return (
    <ModuleWrapper
      id={anchor}
      testid="ctaCardStatic"
      psnid="psn_cta_card_static"
      className={classNames(
        "mb-15 first:pt-21 scroll-mt-28",
        "md:mb-25 md:first:pt-37 md:scroll-mt-48",
        "lg:first:pt-45 lg:scroll-mt-56",
      )}
    >
      <FadeInBottomContainer>
        <div
          className={classNames(
            "space-y-2",
            "md:space-y-0 md:flex md:space-x-2",
          )}
        >
          {sortedCards.map((item, index) => {
            const isEven = (index + 1) % 2 === 0;
            return (
              <CardItem
                key={item.contentID}
                item={item}
                isEven={isEven}
                className="md:col-span-8"
              />
            );
          })}
        </div>
      </FadeInBottomContainer>
    </ModuleWrapper>
  );
}

export { CtaCardStatic };
