import classNames from "classnames";
import { CallToAction } from "components/CallToAction";
import { Image } from "components/Image";
import { VARIANT } from "helpers/constants";
import { getContentWithLineBreaks } from "helpers/htmlHelper";
import { SnippetItemFadeInAnimation } from "./animations";
import { CheckListContent } from "./CheckListContent";

function Snippet({ content, isFirstItem, isSnippetLeftAligned }) {
  const {
    fields: { title, description, cta, image, list, subtitle },
  } = content;
  const hasLink = cta?.text && cta?.href;

  return (
    <div className="mt-10 first:mt-0 md:mt-15" data-testid="imageCardSnippet">
      <SnippetItemFadeInAnimation isSnippetLeftAligned={isSnippetLeftAligned}>
        {image ? (
          <div
            className="mb-4 w-9 h-9 md:w-12 md:h-12 md:mb-5"
            data-testid="imageCardSnippetIcon"
          >
            <Image src={image.url} alt={image.label} width={60} height={60} />
          </div>
        ) : null}

        {isFirstItem ? (
          <h2
            className={classNames(
              "text-27/32 font-bold text-warm-orange-primary mb-2",
              "md:text-32/36 md:mb-3",
            )}
            data-testid="h2SnippetTitle"
          >
            {title}
          </h2>
        ) : (
          <h3
            className={classNames(
              "text-20/24 font-bold text-warm-orange-primary mb-2",
              "md:text-24/28 mb-3 ",
            )}
            data-testid="h3SnippetTitle"
          >
            {title}
          </h3>
        )}
        {subtitle ? (
          <p
            className={classNames(
              "text-black-primary mb-2 font-medium",
              "md:mb-3",
              {
                "text-20/32 md:text-24/36": isFirstItem,
                "text-18/28 md:text-20/32": !isFirstItem,
              },
            )}
            data-testid="imageCardSnippetSubtitle"
          >
            {subtitle}
          </p>
        ) : null}
        <p
          className={classNames(
            "text-15/24 text-black-primary",
            "md:text-18/28",
          )}
        >
          {getContentWithLineBreaks(description)}
        </p>
        {list?.length ? <CheckListContent list={list} /> : null}
        {hasLink ? (
          <div className="flex">
            <CallToAction
              className="!pl-0 !pb-0 mt-1 md:mt-2"
              data={cta}
              dataGa={{
                content_tertiary: title,
                event: "cta_click",
                event_category: "cta",
                event_label: [cta.text],
              }}
              variant={VARIANT.darkTransparent}
              testid="imageCardCta"
            />
          </div>
        ) : null}
      </SnippetItemFadeInAnimation>
    </div>
  );
}

export { Snippet };
