import classNames from "classnames";
import { InfoUnfilled } from "assets/icons";
import { AlertCta } from "./AlertCta";

const theme = {
  info: {
    testId: "info",
    backgroundColor: "bg-blue-100",
    borderColor: "border-blue-primary",
    iconColor: "text-blue-primary",
  },
  error: {
    testId: "error",
    backgroundColor: "bg-red-100",
    borderColor: "border-red-primary",
    iconColor: "text-red-primary",
  },
};

function Alert({ fields, type = "info", CtaJsx = null }) {
  const { title, copy, url } = fields;
  const { testId, backgroundColor, borderColor, iconColor } =
    theme[type] ?? theme.info;

  return (
    <div
      role="alert"
      data-testid={testId}
      className={classNames(
        "flex flex-wrap justify-between items-center",
        "p-4 border-2 rounded-4 text-black-primary",
        "gap-y-3 gap-x-2",
        "md:p-6 md:rounded-5",
        "md:gap-y-0 md:gap-x-9",
        backgroundColor,
        borderColor,
      )}
    >
      <div className="flex items-center md:flex-1">
        <div className={classNames("mr-4", iconColor)}>
          <InfoUnfilled className="w-5 h-5 fill-current md:w-6 md:h-6" />
        </div>
        <div className="text-15/24 md:text-18/28">
          <h4 className="mb-1 font-medium">{title}</h4>
          <p className="mb-1">{copy}</p>
        </div>
      </div>
      {CtaJsx ?? <AlertCta url={url} />}
    </div>
  );
  // return <div>TEST</div>;
}

export { Alert };
