import { useMemo, useState } from "react";
import { createSelector } from "@reduxjs/toolkit";
import { Formik } from "formik";
import { AnimatePresence, motion } from "framer-motion";
import { setPaymentMethod as setPaymentMethodAction } from "actions/appUserStateActions";
import { setPaymentForm } from "actions/paymentStateActions";
import { createOnlineSignup } from "actions/residentialSignupActions";
import { updateZendeskTicket } from "actions/zendeskActions";
import ButtonRadioSelection from "join-form/components/button-radio-selection";
import ConditionalForm from "join-form/components/conditional-form";
import Feedback from "join-form/components/feedback";
import { FormFieldTerm } from "join-form/components/form-controls";
import Intro from "join-form/components/intro";
import StepProgressionButton from "join-form/components/step-progression-button";
import {
  appResidentialRoutes,
  links,
  PAYMENT_METHOD_CREDIT_CARD,
  PAYMENT_METHOD_DIRECT_DEBIT,
  PAYMENT_METHOD_MANUAL,
  PLAN_TYPE_BASIC,
  PLAN_TYPE_EV_PLUS,
  PLAN_TYPE_PLUS,
} from "helpers/constants";
import {
  selectAppState,
  selectAppUserState,
  selectPaymentState,
  selectAddressState,
} from "reducers/selector";
import { findBankName } from "join-form/services/bankInfoService";
import { useRouter } from "next/router";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { InlineBlockEditor } from "components/BlockEditor";
import { getPageData } from "helpers/getPageData";
import {
  headerFade,
  heightFadeTransition,
  heightFadeVariants,
  itemContainer,
  itemFade,
} from "./animations";
import CreditCardForm, { creditCardFormValidation } from "./CreditCardForm";
import styles from "./payment-page.module.scss";
import { appRoutes } from "helpers/constants";
import { getDebitTermConditionId, setTermsAndConditionsValidation } from "helpers/debitTermsConditionsHelper";
import DirectDebitForm, { directDebitFormValidation } from "join-form/components/direct-debit-form";
import { useEffectOnce } from "react-use";
import { isLocalStorageAvailable } from "helpers";

export function getPlanTerms(planType) {
  switch (planType) {
    case PLAN_TYPE_PLUS:
      return {
        title: "Energy Plus",
        link: links.plusTerms,
      };
    case PLAN_TYPE_BASIC:
      return {
        title: "Energy Basic",
        link: links.basicTerms,
      };
    case PLAN_TYPE_EV_PLUS:
      return {
        title: "Energy EV",
        link: links.evPlusTerms,
      };
    default:
      return {
        title: "Plan",
        link: "/",
      };
  }
}

function PaymentPage(props) {
  const {
    values,
    touched,
    errors,
    handleChange,
    handleSubmit,
    isValid,
    isSubmitting,
    planType,
    status,
    isSignupInProgress,
    isCreditCardValidationError,
    pageData,
  } = props;

  const {
    title,
    blockEditor,
    paymentOptions,
    plusPlanTerms,
    basicPlanTerms,
    eVPlanTerms,
    creditCardTerms,
    creditTransactionFeeNote,
    debitTermsConditions,
  } = pageData.fields;

  const dispatch = useDispatch();

  const sortedPaymentOptions = useMemo(() => paymentOptions.sort((item) => 
    item.fields.id === PAYMENT_METHOD_DIRECT_DEBIT ? -1 : 1
  ), [paymentOptions]);

  function setPaymentMethod(paymentMethod) {
    dispatch(setPaymentMethodAction(paymentMethod));
    dispatch(setPaymentForm({ ...values }));
  }

  const getPlanTerms = () => {
    switch (planType) {
      case PLAN_TYPE_PLUS:
        return plusPlanTerms;
      case PLAN_TYPE_BASIC:
        return basicPlanTerms;
      case PLAN_TYPE_EV_PLUS:
        return eVPlanTerms;
    }
  };

  const formikProps = {
    values,
    touched,
    errors,
    handleChange,
  };

  const showSubTitle = planType !== PLAN_TYPE_BASIC;

  return (
    <motion.div
      className={styles.container}
      exit="undefined"
      data-testid="paymentPage"
    >
      <Intro className={styles.intro}>
        {(introStyles) => (
          <motion.div
            initial="initial"
            animate="animate"
            exit="exit"
            variants={headerFade}
          >
            <h1 className={introStyles.title}>{title}</h1>
            <InlineBlockEditor jsonString={blockEditor} />
          </motion.div>
        )}
      </Intro>
      <motion.div
        initial="initial"
        animate="animate"
        exit="exit"
        variants={itemContainer}
        className={styles.body}
      >
        {!isLocalStorageAvailable() && (
          <motion.div
            initial="initial"
            animate="animate"
            exit="exit"
            variants={heightFadeVariants}
            transition={heightFadeTransition}
            className={`mb-5 ${styles.feedback_container}`}
          >
            <Feedback
              title="Auto-pay by credit card currently unavailable"
              type="info"
              data-testid="infoMessage"
            >
              Auto-pay using a debit or credit card can be set up in Energy IQ once you&apos;re connected. You get a 1% discount for paying by recurring credit or debit card.
            </Feedback>
          </motion.div>
        )
        }
        {sortedPaymentOptions.map((option) => {
          const { id, title, subtitle } = option.fields;
          if (id === PAYMENT_METHOD_DIRECT_DEBIT) {
            return (
              <motion.div variants={itemFade} className={styles.item} key={id}>
                <ConditionalForm
                  title={title}
                  subTitle={showSubTitle ? subtitle : ""}
                  isSelected={
                    values.paymentMethod === PAYMENT_METHOD_DIRECT_DEBIT
                  }
                  onClick={() => setPaymentMethod(PAYMENT_METHOD_DIRECT_DEBIT)}
                  size="large"
                >
                  <DirectDebitForm
                    {...formikProps}
                    debitTermsConditions={debitTermsConditions}
                  />
                </ConditionalForm>
              </motion.div>
            );
          }

          if (id === PAYMENT_METHOD_CREDIT_CARD && isLocalStorageAvailable()) {
            return (
              <motion.div variants={itemFade} className={styles.item} key={id}>
                <ConditionalForm
                  id="autoPayCreditCard"
                  title={title}
                  subTitle={showSubTitle ? subtitle : ""}
                  isSelected={
                    values.paymentMethod === PAYMENT_METHOD_CREDIT_CARD
                  }
                  onClick={() => setPaymentMethod(PAYMENT_METHOD_CREDIT_CARD)}
                  size="large"
                >
                  <CreditCardForm
                    {...formikProps}
                    planType={planType}
                    creditCardTerms={creditCardTerms}
                    creditTransactionFeeNote={creditTransactionFeeNote}
                  />
                </ConditionalForm>
              </motion.div>
            );
          }

          if (id === PAYMENT_METHOD_MANUAL && planType !== PLAN_TYPE_BASIC) {
            return (
              <motion.div variants={itemFade} className={styles.item} key={id}>
                <ButtonRadioSelection
                  subTitle={subtitle}
                  isSelected={values.paymentMethod === PAYMENT_METHOD_MANUAL}
                  onClick={() => setPaymentMethod(PAYMENT_METHOD_MANUAL)}
                  className={styles.manual_payments}
                  size="large"
                >
                  {title}
                </ButtonRadioSelection>
              </motion.div>
            );
          }
        })}

        <motion.div variants={itemFade} className={styles.terms_container}>
          <FormFieldTerm
            name="generalTerms"
            testId="generalTerms"
            value={values.generalTerms}
            checked={values.generalTerms === true}
            onChange={handleChange}
            size="small"
            errorMessage={errors.generalTerms}
            showError={errors.generalTerms && touched.generalTerms}
            textSize="small"
          >
            <InlineBlockEditor
              jsonString={getPlanTerms()}
              className="prose-p:text-14/20 prose-p:md:text-16/24"
              testid="planTerms"
            />
          </FormFieldTerm>
        </motion.div>
      </motion.div>

      <div className="mt-10 overflow-hidden md:mt-0 lg:mt-5">
        <AnimatePresence>
          {errors.paymentMethod && touched.paymentMethod && (
            <motion.div
              initial="initial"
              animate="animate"
              exit="exit"
              variants={heightFadeVariants}
              transition={heightFadeTransition}
              className="mb-8 md:mb-10"
            >
              <Feedback type="error" title="Error" data-testid="errorMessage">
                Please select an option.
              </Feedback>
            </motion.div>
          )}
          {status && (
            <motion.div
              initial="initial"
              animate="animate"
              exit="exit"
              variants={heightFadeVariants}
              transition={heightFadeTransition}
              className="mb-8 md:mb-10"
            >
              <Feedback
                type="error"
                title={
                  isCreditCardValidationError
                    ? "Card validation error"
                    : "Error"
                }
                data-testid="apiErrorMessage"
              >
                {status}
              </Feedback>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
      <StepProgressionButton
        disabled={isValid ? isSignupInProgress : true}
        onContinueClick={handleSubmit}
        backLink={appResidentialRoutes.property}
        applyDisabledAttribute={isSubmitting}
        isLoading={isSignupInProgress}
        testId="submitButton"
        text="Sign up now"
      />
    </motion.div>
  );
}

const mapPropsToValues = ({ paymentState, paymentMethod, planTerms }) => ({
  ...paymentState,
  paymentMethod,
  planTerms,
});

const validationSchema = Yup.object()
  .shape({
    paymentMethod: Yup.string().required("Please select a payment method"),
    generalTerms: Yup.boolean()
      .required()
      .oneOf([true], "Please accept terms and conditions"),
  })
  .concat(creditCardFormValidation)
  .concat(directDebitFormValidation);

const selectResidentialPaymentState = createSelector(
  [selectAppUserState, selectAppState, selectPaymentState],
  (appUserState, appState, paymentState) => ({
    paymentState,
    paymentMethod: appUserState.paymentMethod,
    planType: appState.planType,
  }),
);

const modules = [
  "GE_JOIN_Intro_Header",
  "GE_JOIN_Residential_Payment_Options",
  "GE_JOIN_Residential_Basic_Plan_Payment_Terms",
  "GE_JOIN_Residential_Plus_Plan_Payment_Terms",
  "GE_JOIN_Residential_EV_Plan_Payment_Terms",
  "GE_JOIN_Debit_Card_Terms",
  "GE_JOIN_Credit_Card_Terms",
];

function PaymentPageForm(props) {

  const router = useRouter();
  const dispatch = useDispatch();
  const pageData = getPageData({ modules, page: props.page });

  const { customerType } = useSelector(selectAppState);
  const { addressDetails } = useSelector(selectAddressState);
  const componentProps = useSelector(selectResidentialPaymentState);
  const initialValues = mapPropsToValues(componentProps);
  const debitTermsConditions = pageData.fields?.debitTermsConditions;

  const [formValidationSchema, setFormValidationSchema] = useState(validationSchema);
  const [pendingRequest, setPendingRequest] = useState(false);
  const [isCreditCardValidationError, setCreditCardValidationError] =
    useState(false);

  const onSubmit = async (values, options) => {
    const invalidRequest = !customerType || !addressDetails;
    if (invalidRequest) {
      router.push(appRoutes.customerType);
      return;
    }
    if(pendingRequest) return;

    setPendingRequest(true);
    setCreditCardValidationError(false);

    options.setSubmitting(true);

    dispatch(setPaymentForm(values));

    if (values.paymentMethod === PAYMENT_METHOD_DIRECT_DEBIT) {
      const [bankName] = await findBankName(values.bankAccountNo);
      const debitTermsConditionsKeys = debitTermsConditions?.map(({ fields }, index) => getDebitTermConditionId(fields, index));
      dispatch(setPaymentForm({ bankName, debitTermsConditionsKeys }));
    }

    const request = dispatch(createOnlineSignup())
      .then(() => {
        setPendingRequest(false);
        options.setSubmitting(false);
        dispatch(updateZendeskTicket());
        router.push(appResidentialRoutes.success);
        window.localStorage.removeItem("persist:root");
        store.dispatch(clearState());
      })
      .catch((err) => {
        setPendingRequest(false);
        options.setStatus("Something went wrong, please try again.");
        options.setSubmitting(false);
      });
    return request;
  };

  useEffectOnce(() => {
    setTermsAndConditionsValidation({ debitTermsConditions, setFormValidationSchema, validationSchema });
  });

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={formValidationSchema}
      validateOnMount
      enableReinitialize
    >
      {(formikProps) => (
        <PaymentPage
          pageData={pageData}
          {...componentProps}
          {...formikProps}
          isSignupInProgress={pendingRequest}
          isCreditCardValidationError={isCreditCardValidationError}
        />
      )}
    </Formik>
  );
}

export default PaymentPageForm;
