import classNames from "classnames";
import { InlineBlockEditor } from "components/BlockEditor";
import { Image } from "components/Image";
import { useScreenSize } from "hooks";

export function TrustedBrandsSection({ module }) {
  const {
    fields: { trustedBrandsLogo, blockEditor },
  } = module;

  const { isMd } = useScreenSize();

  return (
    <div className="px-4 w-158 md:w-210 max-w-full mx-auto">
      <div
        className={classNames(
          "flex flex-col items-center md:flex-row",
          "bg-black-50 p-6 rounded-5 mb-25 mt-5",
        )}
      >
        <div
          className="mb-3 md:mb-0 md:mr-6"
        >
          <Image
            src={trustedBrandsLogo.url}
            alt={trustedBrandsLogo.label}
            width={isMd ? 302.66 : 228.04}
            height={isMd ? 80 : 64}
            quality={100}
          />
        </div>
        <InlineBlockEditor
          jsonString={blockEditor}
          className="prose-p:text-16/24 prose-p:md:text-16/24"
        />
      </div>
    </div>
  );
}
