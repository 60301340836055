import React from "react";
import classNames from "classnames";
import { get, sortBy } from "lodash-es";
import {
  Accordion,
  AccordionHeader,
  AccordionItem,
  AccordionPanel,
} from "components/Accordion";
import { InlineBlockEditor } from "components/BlockEditor";

function AccordionSectionList({
  anchorPrefix,
  accordionList,
  accordionItemTitleField = "title",
  accordionItemContentField = "body",
}) {
  const [indices, setIndices] = React.useState([]);

  function toggleItem(toggledIndex) {
    if (indices.includes(toggledIndex)) {
      setIndices(
        indices.filter((currentIndex) => currentIndex !== toggledIndex),
      );
    } else {
      const sortedIndices = sortBy([...indices, toggledIndex]);
      setIndices(sortedIndices);
    }
  }

  React.useEffect(() => {
    const hash = window.location.hash.replace(`#${anchorPrefix}`, "");
    const parsedHash = Number(hash);
    const indexOfAccordion = accordionList.findIndex(
      (item) => item.contentID === parsedHash,
    );
    if (indexOfAccordion > -1) {
      setIndices([indexOfAccordion]);
    }
  }, [accordionList, anchorPrefix]);

  return (
    <div className="md:grid md:grid-cols-3 md:gap-x-7 lg:gap-x-8">
      <Accordion
        index={indices}
        onChange={toggleItem}
        className="md:col-span-2"
      >
        {accordionList.map((item) => {
          const { fields } = item;
          const id = `${anchorPrefix}${item.contentID}`;
          const accordionContent = get(fields, accordionItemContentField, "");
          return (
            <AccordionItem
              key={item.contentID}
              id={id}
              data-testid={id}
              className="scroll-mt-28 md:scroll-mt-48 lg:scroll-mt-56"
            >
              <AccordionHeader>
                {get(fields, accordionItemTitleField, "")}
              </AccordionHeader>
              <AccordionPanel>
                <InlineBlockEditor
                  jsonString={accordionContent}
                  className={classNames(
                    "prose-headings:text-20/24",
                    "md:prose-headings:text-24/28",
                  )}
                />
              </AccordionPanel>
            </AccordionItem>
          );
        })}
      </Accordion>
    </div>
  );
}

export { AccordionSectionList };
