import classNames from "classnames";

export default function EnergyRateItem(props) {
  const { title, subtitle, value } = props;

  return (
    <div
      className={classNames(
        "flex justify-between items-center",
        "gap-x-4 md:gap-x-5",
        "text-13/20 md:text-14/20",
      )}
    >
      <div className="flex flex-wrap gap-1">
        <span>{title}</span>
        {subtitle && (
          <span className="text-10/20 text-black-400">{`(${subtitle})`}</span>
        )}
      </div>
      <div className="whitespace-nowrap">{value}</div>
    </div>
  );
}
