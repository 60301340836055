import { useState } from "react";
import classNames from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import { RadioList } from "join-form/components/form-controls";
import Icon from "join-form/components/icon";
import Modal from "join-form/components/modal";
import { LPG_NO_OF_BOTTLES_SETUP_2 } from "helpers/constants";
import { getPropertyAccessListContent } from "helpers/propertyAccessHelper";
import { heightFadeTransition, heightFadeVariants } from "./animations";
import styles from "./property-page.module.scss";

export function BottledGasQuestions(props) {
  const { values, touched, errors, handleChange, pageData } = props;

  const [showGasBottlesLocationInfoModal, setGasBottlesLocationInfoModal] =
    useState(false);
  const onInfoModalClose = () => {
    setGasBottlesLocationInfoModal(false);
  };

  return (
    <>
      <p className={styles.label} data-testid="gasBottlesCountLabel">
        {pageData.fields.gasBottlesCountLabel}
      </p>
      <RadioList
        name="gasBottlesCount"
        onChange={handleChange}
        errorMessage={errors.gasBottlesCount}
        showError={errors.gasBottlesCount && touched.gasBottlesCount}
        isHalfWidth
        size="medium"
        items={getPropertyAccessListContent("gasBottlesCount").map(
          (option) => ({
            text: option.text,
            value: option.value,
            checked: values.gasBottlesCount === option.value,
            testId: `gasBottlesCount${option.text}`,
          }),
        )}
      />
      <AnimatePresence>
        {values.gasBottlesCount === LPG_NO_OF_BOTTLES_SETUP_2 && (
          <motion.div
            initial="initial"
            animate="animate"
            exit="exit"
            variants={heightFadeVariants}
            transition={heightFadeTransition}
            className={styles.dependant_fields_wrapper}
          >
            <p className={styles.label} data-testid="gasBottlesSupplierLabel">
              {pageData.fields.gasBottlesSupplierLabel}
            </p>
            <RadioList
              name="gasBottlesSupplier"
              onChange={handleChange}
              errorMessage={errors.gasBottlesSupplier}
              showError={
                errors.gasBottlesSupplier && touched.gasBottlesSupplier
              }
              isHalfWidth
              size="medium"
              items={getPropertyAccessListContent("gasBottlesSupplier").map(
                (supplier) => ({
                  text: supplier.text,
                  value: supplier.value,
                  checked: values.gasBottlesSupplier === supplier.value,
                  testId: `gasBottlesSupplier${supplier.text}`,
                }),
              )}
            />
            <p className={styles.label} data-testid="orderBottlesLabel">
              {pageData.fields.orderBottlesLabel}
            </p>
            <RadioList
              name="orderBottlesOption"
              onChange={handleChange}
              errorMessage={errors.orderBottlesOption}
              showError={
                errors.orderBottlesOption && touched.orderBottlesOption
              }
              isHalfWidth
              size="medium"
              items={[
                {
                  text: "Yes, 2 bottles",
                  value: true,
                  checked: values.orderBottlesOption === "true",
                  testId: "orderBottlesYesOption",
                },
                {
                  text: "No, I’ll order later",
                  value: false,
                  checked: values.orderBottlesOption === "false",
                  testId: "orderBottlesNoOption",
                },
              ]}
            />
            <AnimatePresence>
              <motion.div
                initial="initial"
                animate="animate"
                exit="exit"
                variants={heightFadeVariants}
                transition={heightFadeTransition}
                className={styles.dependant_fields_wrapper}
              >
                <p
                  className={classNames(styles.label, "inline")}
                  data-testid="gasBottlesLocationLabel"
                >
                  {pageData.fields.gasBottlesLocationLabel}
                </p>
                <button
                  data-testid="gasBottlesAccessOverlay"
                  onClick={() => setGasBottlesLocationInfoModal(true)}
                  className="ml-2 align-text-bottom text-black-primary md:align-text-top"
                >
                  <Icon name="Info" size="18" />
                </button>
                <RadioList
                  name="gasBottlesLocation"
                  onChange={handleChange}
                  errorMessage={errors.gasBottlesLocation}
                  showError={
                    errors.gasBottlesLocation && touched.gasBottlesLocation
                  }
                  isHalfWidth
                  size="medium"
                  items={getPropertyAccessListContent("gasBottlesLocation").map(
                    (location) => ({
                      text: location.text,
                      value: location.value,
                      checked: values.gasBottlesLocation === location.value,
                      testId: `gasBottlesLocation${location.text}`,
                    }),
                  )}
                />
              </motion.div>
            </AnimatePresence>
          </motion.div>
        )}
      </AnimatePresence>

      {showGasBottlesLocationInfoModal && (
        <Modal
          onRequestClose={onInfoModalClose}
          isOpen
          title={pageData.fields.gasBottleLocationInfoModalTitle}
        >
          <p>{pageData.fields.gasBottlesLocationInfoModalContent}</p>
        </Modal>
      )}
    </>
  );
}

export default BottledGasQuestions;
