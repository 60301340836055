import { ArrowRight, ExternalLink } from "assets/icons";
import { Button } from "components/Button";

function CallToAction({
  variant,
  data,
  dataGa,
  className,
  size,
  outline,
  testid,
}) {
  if (!data?.text || !data?.href) return null;
  const openInNewTab = data?.target === "_blank";

  return (
    <Button
      size={size}
      variant={variant}
      href={data.href}
      prefetch={false}
      target={data.target}
      data-testid={testid}
      outline={outline}
      className={className}
      data-ga={dataGa && JSON.stringify(dataGa)}
    >
      <span>{data.text}</span>
      {openInNewTab ? (
        <ExternalLink data-testid="linkCtaIcon" />
      ) : (
        <ArrowRight />
      )}
    </Button>
  );
}

export { CallToAction };
