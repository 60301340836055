import React from "react";
import classNames from "classnames";
import { formatDate } from "helpers/dateHelper";
import { DocumentActionButton } from "./DocumentActionButton";

function DocumentItem({ data }) {
  const {
    title,
    tag,
    tag_TextField,
    publishDate,
    url,
    type,
    buttonText,
    buttonText_TextField,
  } = data.fields;
  const tagText = tag?.fields?.title || tag_TextField;
  const hasTag = tagText || publishDate;
  const buttonLabel =
    buttonText?.fields?.title.toLowerCase() ||
    buttonText_TextField?.toLowerCase();

  return (
    <div
      data-testid="documentCard"
      className={classNames(
        "flex justify-between transition-all bg-white text-black-primary",
        "p-5 md:p-7",
        "border border-black-100 rounded-4 md:rounded-5",
        "hover:shadow-200",
      )}
    >
      <div className="flex-col mr-5 space-y-1 md:space-y-2">
        {hasTag && (
          <ul data-testid="documentTag" className="flex space-x-1 text-12/20">
            <li>{tagText}</li> <li>&bull;</li>
            <li>{formatDate(publishDate)}</li>
          </ul>
        )}
        <h4 className="font-medium text-18/28 md:text-20/32">{title}</h4>
      </div>
      <div className="flex items-center cursor-pointer">
        <DocumentActionButton {...{ type, url, buttonLabel }} />
      </div>
    </div>
  );
}

export { DocumentItem };
