import classNames from "classnames";
import { motion } from "framer-motion";
import { InlineBlockEditor } from "components/BlockEditor";
import { getBlocks } from "helpers/blockEditorHelper";
import { headerFade } from "./animations";

export function IntroHeader({ module }) {
  const { title, blockEditor } = module.fields;
  const blocks = getBlocks(blockEditor);

  return (
    <div className="max-w-full px-4 mx-auto w-158 md:w-210">
      <motion.div
        className="my-10 md:my-15"
        initial="initial"
        animate="animate"
        exit="exit"
        variants={headerFade}
      >
        <h1
          className={classNames(
            "mb-4 last:mb-0",
            "text-32/36 md:text-44/52",
            "font-bold text-black-primary",
          )}
        >
          {title}
        </h1>
        {blocks.length ? (
          <InlineBlockEditor
            data={blocks}
            className="prose-p:text-20/32 prose-p:md:text-24/36 prose-a:text-black-primary"
          />
        ) : null}
      </motion.div>
    </div>
  );
}
