import classNames from "classnames";

export default function NextSteps({ children, title }) {
  return (
    <div className="flex flex-col space-y-10">
      <h1
        className={classNames(
          "font-bold text-32/36 text-black-900",
          "md:text-44/52",
        )}
      >
        {title}
      </h1>
      <div>
        <div
          className={classNames(
            "flex flex-col gap-2 mb-30",
            "md:flex-row md:-mx-2 md:mb-45",
          )}
        >
          {children}
        </div>
      </div>
    </div>
  );
}
