import Icon from "join-form/components/icon";
import styles from "./form-controls.module.scss";

export default function Dropdown(props) {
  const {
    name,
    placeholder,
    value,
    options,
    onChange,
    errorMessage,
    showError,
    containerClassName,
  } = props;

  const filledClassName = value ? styles.filled : "";
  const invalidClassName = showError ? styles.error : "";

  const className = [
    styles.control,
    filledClassName,
    invalidClassName,
    containerClassName,
  ]
    .join(" ")
    .trim();

  return (
    <div className={className}>
      <label className={styles.label} htmlFor={name}>
        {placeholder}
      </label>
      <div className={styles.dropdown_container}>
        <select
          className={styles.dropdown}
          name={name}
          id={name}
          value={value}
          onChange={onChange}
        >
          <option className={styles.dropdown_placeholder} value="" disabled>
            {placeholder}
          </option>
          {options.map((item) => (
            <option key={item.value} value={item.value}>
              {item.name}
            </option>
          ))}
        </select>
        <Icon name="ChevronDown" className={styles.dropdown_icon} size="18" />
      </div>
      {showError && (
        <span data-testid={`${name}ErrorMessage`} className={styles.errorText}>
          {errorMessage}
        </span>
      )}
    </div>
  );
}
