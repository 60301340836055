import Axios from "axios";

export class AddressFinder {
  constructor() {
    this.config = {
      addressFinderQuery: {
        format: "json",
        key: "6KAWTCXQH7LEUF9P3M8N",
        ascii: 1,
      },
      suggestionsQuery: {
        max: 10,
        strict: 2,
      },
    };
    this.httpServiceOptions = {
      baseURL: "https://api.addressfinder.io/api/nz/address",
    };
    this.httpService = Axios.create(this.httpServiceOptions);
    this.setDefaultAcceptHeaders();
  }

  async getAddressDetails(query) {
    const { addressFinderQuery } = this.config;
    const params = Object.assign({}, query, addressFinderQuery);
    return this.httpService.get("/metadata", { params }).then((response) => {
      return response;
    });
  }

  async getAddressSuggestions(query) {
    const { addressFinderQuery, suggestionsQuery } = this.config;
    const params = Object.assign(
      {},
      query,
      addressFinderQuery,
      suggestionsQuery,
    );

    return this.httpService
      .get("/autocomplete", { params })
      .then((response) => {
        return response;
      });
  }

  setDefaultAcceptHeaders() {
    this.httpService.defaults.headers.post["Accept"] = "application/json";
    this.httpService.defaults.headers.get["Accept"] = "application/json";
  }
}

const addressFinder = new AddressFinder();

export default addressFinder;
