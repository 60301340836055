import React from "react";
import classNames from "classnames";
import { useFilterOverlay } from "hooks";
import { Filter } from "assets/icons";
import { Button } from "components/Button";
import { Checkbox } from "components/Checkbox";
import { Select } from "components/Select";
import { VARIANT } from "helpers/constants";
import { gaPush } from "helpers/gaHelper";
import { DocumentFilterButton } from "./DocumentFilterButton";
import { DocumentFilterResult } from "./DocumentFilterResult";
import { getCustomInitialProps } from "./getDocumentFilterProps";
import { useDocumentFilter } from "./state";

function DocumentFilter({
  module,
  customData,
  visibleDocumentsCount = 12,
  pageSize = 12,
}) {
  const { filterDocumentsLabel, categoryLabel } = module.fields;
  const { documentItems, categories, allDates, allYears } = customData;

  const sidebarTitle = filterDocumentsLabel
    ? filterDocumentsLabel
    : "Filter documents";

  const categoryTitle = categoryLabel ? categoryLabel : "Category";

  const {
    filteredDocuments,
    selectedYear,
    selectedTagIds,
    paginationText,
    showLoadMoreButton,
    setYear,
    setCategory,
    reset,
    loadMoreDocuments,
  } = useDocumentFilter({
    documentItems,
    categories,
    allYears,
    allDates,
    pageSize,
    visibleDocumentsCount,
  });

  const { isOverlayVisible, setOverlayVisibility, filterButtonRef } =
    useFilterOverlay();

  if (documentItems.length === 0) {
    return null;
  }

  return (
    <div
      data-testid="documentFilter"
      data-psnid="psn_document_filter"
      className={classNames("first:pt-21 md:first:pt-44 lg:first:pt-56", {
        "mb-5 md:mb-10": showLoadMoreButton,
        "mb-15 md:mb-25": !showLoadMoreButton,
      })}
    >
      <div
        className={classNames(
          "px-4 mx-auto sm:px-15 md:px-17 lg:px-23 xl:px-46",
          "max-w-full w-120 sm:w-210 md:w-auto",
        )}
      >
        <div className="md:grid md:gap-x-7 md:grid-cols-24 lg:gap-x-8">
          <div className="relative mx-auto mb-10 z-2 md:hidden">
            <DocumentFilterButton
              onClick={() => setOverlayVisibility(!isOverlayVisible)}
              isActive={isOverlayVisible}
              ref={filterButtonRef}
            >
              {sidebarTitle}
            </DocumentFilterButton>
          </div>
          <div className="md:col-span-9 lg:col-span-8">
            <div
              data-testid="documentFilterOverlay"
              className={classNames("article-filter-sidebar", {
                "article-filter-sidebar-mobile--invisible": !isOverlayVisible,
                "article-filter-sidebar-mobile--visible": isOverlayVisible,
              })}
            >
              <div className="article-filter-sidebar-content">
                <div
                  className={classNames(
                    "hidden text-black-primary",
                    "items-center justify-between mb-10",
                    "md:flex",
                  )}
                >
                  <h5 className="font-medium text-20/32">{sidebarTitle}</h5>
                  <Filter className="w-5 h-5 cursor-pointer fill-current" />
                </div>
                <div
                  className={classNames(
                    "flex flex-col",
                    "text-black-primary text-15/24 md:text-18/28",
                    "space-y-7 md:space-y-10",
                  )}
                >
                  <Select
                    id="year"
                    label="Year released"
                    value={selectedYear}
                    onChange={(e) => {
                      gaPush({
                        content_tertiary: "market announcement listings",
                        event: "filter_click",
                        event_category:
                          "market announcement listings - year released",
                        event_label: e.target.value,
                      });
                      setYear(e.target.value);
                    }}
                  >
                    {allYears.map((year) => (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    ))}
                  </Select>
                  {categories.length > 1 && (
                    <div>
                      <p className="mb-2" data-testid="categoryTitle">
                        {categoryTitle}
                      </p>
                      <ul className="space-y-2">
                        {categories.map((category) => {
                          const checked = selectedTagIds.includes(
                            category.contentID.toString(),
                          );
                          return (
                            <li key={category.contentID}>
                              <Checkbox
                                label={category.title}
                                value={category.contentID}
                                checked={checked}
                                onChange={(e) => {
                                  if (!checked) {
                                    const eventLabels = selectedTagIds.map(
                                      (id) =>
                                        categories.find(
                                          ({ contentID }) => contentID == id,
                                        )?.title ?? [],
                                    );
                                    gaPush({
                                      content_tertiary:
                                        "market announcement listings",
                                      event: "filter_click",
                                      event_category:
                                        "market announcement listings - category",
                                      event_label: [
                                        category.title,
                                        ...eventLabels,
                                      ].join(" | "),
                                    });
                                  }
                                  setCategory(e.target.value);
                                }}
                              />
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  )}
                </div>
              </div>
              <div className="article-filter-sidebar-footer">
                <Button
                  size="small"
                  shadowless
                  onClick={() => setOverlayVisibility(false)}
                >
                  Apply filters
                </Button>
                <Button
                  size="small"
                  shadowless
                  variant={VARIANT.darkTransparent}
                  onClick={reset}
                >
                  Clear all
                </Button>
              </div>
            </div>
          </div>
          <div className="md:col-start-10 md:col-span-15">
            {filteredDocuments.length ? (
              <DocumentFilterResult
                paginationText={paginationText}
                filteredDocuments={filteredDocuments}
                showLoadMoreButton={showLoadMoreButton}
                loadMoreDocuments={loadMoreDocuments}
              />
            ) : (
              <div
                data-testid="noResultsSection"
                className="space-y-1 md:mt-8 lg:mt-10 text-black-primary"
              >
                <h4 className="font-medium text-18/28 md:text-20/32">
                  No matches found
                </h4>
                <p className="text-15/24 md:text-18/28">
                  Please try a different set of filters.
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

DocumentFilter.getCustomInitialProps = getCustomInitialProps;

export { DocumentFilter };
