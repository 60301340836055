import { tailWindConfig } from "getConfig";
import { useMedia } from "react-use";

function useScreenSize() {
  const { sm, md, lg, xl } = tailWindConfig.theme.screens;

  const isSm = useMedia(`(min-width: ${sm})`, true); // server rendering screen size
  const isMd = useMedia(`(min-width: ${md})`, false);
  const isLg = useMedia(`(min-width: ${lg})`, false);
  const isXl = useMedia(`(min-width: ${xl})`, false);

  return {
    isSm,
    isMd,
    isLg,
    isXl,
  };
}

export { useScreenSize };
