import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import { get } from "lodash-es";
import {
  deselectPrimaryNavItem,
  selectPrimaryNavItem,
  useNavigation,
} from "providers/Navigation";
import { PrimaryNavLink } from "./PrimaryNavLink";
import { SecondaryNav } from "./SecondaryNav";

function PrimaryNav({ prefetch }) {
  const {
    state: { primaryNav, activePrimaryNav },
    dispatch,
  } = useNavigation();

  function onPrimaryNavItemClick(e, { item, hasChildren, isActive }) {
    if (!hasChildren) {
      selectPrimaryNavItem({ dispatch, payload: item });
      return;
    }

    e.preventDefault();

    if (isActive) {
      deselectPrimaryNavItem({ dispatch });
    } else {
      selectPrimaryNavItem({ dispatch, payload: item });
    }
  }

  return primaryNav ? (
    <AnimatePresence exitBeforeEnter>
      <motion.ul
        initial={{
          x: 10,
          opacity: 0,
        }}
        animate={{
          x: 0,
          opacity: 1,
        }}
        exit={{
          x: -10,
          opacity: 0,
        }}
        transition={{ type: "tween", duration: 0.15 }}
        key={primaryNav.name}
        data-testid="primaryNav"
        className="flex justify-end pr-5"
      >
        {primaryNav?.children?.map((item) => {
          const hasChildren = get(item, "children", []).length !== 0;
          const isActive = activePrimaryNav === item.path;
          return (
            <li key={item.path} className="relative primary-nav">
              <PrimaryNavLink
                item={item}
                isActive={isActive}
                hasChildren={hasChildren}
                onClick={(e) => {
                  onPrimaryNavItemClick(e, { item, isActive, hasChildren });
                }}
                prefetch={prefetch}
                parentLabel={[primaryNav.menuText]}
              />
              {isActive && hasChildren && (
                <SecondaryNav item={item} isActive={isActive} key={item.path} />
              )}
            </li>
          );
        })}
      </motion.ul>
    </AnimatePresence>
  ) : null;
}

export { PrimaryNav };
