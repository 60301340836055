import StepNavigationItem from "join-form/components/step-navigation-item";
import { appBusinessRoutes } from "helpers/constants";
import { useRouter } from "next/router";

const {
  businessDetails,
  address,
  addressIcp,
  addressManual,
  fuel,
  callback,
  contactDetails,
  termSelect,
  creditCheck,
  property,
  success,
  callbackSuccess,
  ...allPropertyDetailsRouteInfo
} = appBusinessRoutes;
const propertyDetailPageRoutes = Object.values(allPropertyDetailsRouteInfo);

export default function PropertyItem(props) {
  const router = useRouter();

  const isVisible = propertyDetailPageRoutes.includes(router.asPath);

  if (!isVisible) {
    return null;
  }

  return (
    <StepNavigationItem title="Property Details" path={property} {...props} />
  );
}
