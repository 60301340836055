import smeService from "services/SmeService";
import {
  SET_BUSINESS_OFFERS,
  UNSET_BUSINESS_OFFERS,
} from "./businessOfferActionTypes";

function unsetBusinessOffers() {
  return { type: UNSET_BUSINESS_OFFERS, payload: "" };
}

export function setBusinessOffers(isFromAddressIcpPage = false) {
  return async function setBusinessOffersWithDispatch(dispatch, getState) {
    const {
      icp: { elecIcp, elecRegistryAddress, gasIcp, gasRegistryAddress, lpgIcp },
      addressState: { addressDetails },
    } = getState();

    let addressCoordinates = {};

    // LPG geoCoordinates shouldn't be passed to get offers from address ICP page
    if (addressDetails && !isFromAddressIcpPage) {
      addressCoordinates = {
        geoCoordinates: {
          x: addressDetails.x,
          y: addressDetails.y,
        },
      };
    }

    //clean up and old state for previously entered ICP/address
    const unsetBusinessOffersAction = unsetBusinessOffers();
    dispatch(unsetBusinessOffersAction);

    //get offers for new ICP/address
    const result = await smeService.findOffersByIcp({
      elecIcp,
      elecRegistryAddress,
      gasIcp,
      gasRegistryAddress,
      lpgIcp,
      addressCoordinates,
    });

    const {
      elecOffers: electricityOffers,
      elecOffersResult: electricityOffersResult,
      gasOffers: naturalGasOffers,
      gasOffersResult: naturalGasOffersResult,
      lpgOffers: bottledGasOffers,
      lpgOffersResult: bottledGasOffersResult,
    } = result;

    const setBusinessOffersAction = {
      type: SET_BUSINESS_OFFERS,
      payload: {
        electricityOffers,
        electricityOffersResult,
        naturalGasOffers,
        naturalGasOffersResult,
        bottledGasOffers,
        bottledGasOffersResult,
      },
    };

    dispatch(setBusinessOffersAction);

    return result;
  };
}
