import StepNavigationItem from "join-form/components/step-navigation-item";
import { appResidentialRoutes, appRoutes } from "helpers/constants";
import { useRouter } from "next/router";

export default function CustomerTypeItem(props) {
  const { success, callbackSuccess, ...allowedRoutesInfo } =
    appResidentialRoutes;
  const allowedRoutes = Object.values(allowedRoutesInfo);
  const router = useRouter();
  const pathWithoutQuery = router.asPath.split('?').at(0);
  const isVisible = allowedRoutes.includes(pathWithoutQuery);

  return isVisible ? (
    <StepNavigationItem
      title="For Home"
      path={appRoutes.customerType}
      {...props}
    />
  ) : null;
}
