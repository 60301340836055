import { useState } from "react";
import classNames from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import { Alert } from "components/Alert";
import { Icon } from "components/Icon";
import { Dropdown, RadioList } from "components/JoinFormControls";
import { Modal } from "components/Modal";
import {
  LPG_BOTTLE_SIZE_45,
  LPG_BOTTLE_SIZE_OTHER,
  LPG_NO_OF_BOTTLES_SETUP_2,
} from "helpers/constants";
import { getPropertyAccessListContent } from "helpers/propertyAccessHelper";
import { heightFadeTransition, heightFadeVariants } from "../animations";
import styles from "./property-page.module.scss";

function UnsupportedGasBottledSizeAlert({ gasBottleSize }) {
  if (gasBottleSize !== LPG_BOTTLE_SIZE_OTHER) {
    return null;
  }

  return (
    <motion.div
      initial="initial"
      animate="animate"
      exit="exit"
      variants={heightFadeVariants}
      transition={heightFadeTransition}
      className={styles.dependant_fields_wrapper}
    >
      <div className="mb-15">
        <Alert
          className="md:p-5 md:items-start"
          contentContainerClassName="md:items-start"
          fields={{
            copy: "Once you've completed this form, we'll be in touch to discuss your requirements.",
          }}
          type="info"
        />
      </div>
    </motion.div>
  );
}

export function BottledGasQuestions(props) {
  const {
    values,
    touched,
    errors,
    handleChange,
    lpgCapacityQuestion,
    lpgLocationOverlayButton,
    lpgLocationOverlayDescription,
    lpgLocationOverlayTitle,
    lpgLocationQuestion,
    lpgOrderBottlesQuestion,
    lpgSupplierQuestion,
    enableLPGLocationOverlay,
  } = props;
  const [showLocationInfoModal, setLocationInfoModal] = useState(false);
  const onInfoModalClose = () => {
    setLocationInfoModal(false);
  };

  return (
    <>
      <p className={styles.label} data-testid="gasBottlesCountLabel">
        {lpgCapacityQuestion}
      </p>
      <RadioList
        name="gasBottlesCount"
        onChange={handleChange}
        errorMessage={errors.gasBottlesCount}
        showError={errors.gasBottlesCount && touched.gasBottlesCount}
        size="medium"
        isHalfWidth
        items={getPropertyAccessListContent("gasBottlesCount").map(
          (option) => ({
            text: option.text,
            value: option.value,
            checked: values.gasBottlesCount === option.value,
            testId: `gasBottlesCount${option.text}`,
          }),
        )}
      />
      <AnimatePresence>
        {values.gasBottlesCount === LPG_NO_OF_BOTTLES_SETUP_2 && (
          <motion.div
            initial="initial"
            animate="animate"
            exit="exit"
            variants={heightFadeVariants}
            transition={heightFadeTransition}
            className={styles.dependant_fields_wrapper}
          >
            <label className={styles.label} data-testid="gasBottleSizeLabel">
              What size are your gas bottles?
            </label>
            <RadioList
              name="gasBottleSize"
              onChange={handleChange}
              errorMessage={errors.gasBottleSize}
              showError={errors.gasBottleSize && touched.gasBottleSize}
              size="medium"
              isHalfWidth
              items={[
                {
                  text: "45kg standard",
                  value: LPG_BOTTLE_SIZE_45,
                  checked: values.gasBottleSize === LPG_BOTTLE_SIZE_45,
                  testId: `gasBottlesSize${LPG_BOTTLE_SIZE_45}`,
                },
                {
                  text: "Other",
                  value: LPG_BOTTLE_SIZE_OTHER,
                  checked: values.gasBottleSize === LPG_BOTTLE_SIZE_OTHER,
                  testId: `gasBottlesSizeOther`,
                },
              ]}
            />
            <AnimatePresence>
              <UnsupportedGasBottledSizeAlert
                gasBottleSize={values.gasBottleSize}
              />
            </AnimatePresence>
            <AnimatePresence>
              {values.gasBottleSize === LPG_BOTTLE_SIZE_45 && (
                <motion.div
                  initial="initial"
                  animate="animate"
                  exit="exit"
                  variants={heightFadeVariants}
                  transition={heightFadeTransition}
                  className={styles.dependant_fields_wrapper}
                >
                  <Dropdown
                    name="gasBottlesSupplier"
                    placeholder={lpgSupplierQuestion}
                    value={values.gasBottlesSupplier}
                    options={getPropertyAccessListContent(
                      "gasBottlesSupplier",
                    ).map((supplier) => ({
                      name: supplier.text,
                      value: supplier.value,
                    }))}
                    onChange={handleChange}
                    errorMessage={errors.gasBottlesSupplier}
                    showError={
                      errors.gasBottlesSupplier && touched.gasBottlesSupplier
                    }
                  />
                  <p className={styles.label} data-testid="orderBottlesLabel">
                    {lpgOrderBottlesQuestion}
                  </p>
                  <RadioList
                    name="orderBottlesOption"
                    onChange={handleChange}
                    errorMessage={errors.orderBottlesOption}
                    showError={
                      errors.orderBottlesOption && touched.orderBottlesOption
                    }
                    size="medium"
                    isHalfWidth
                    items={[
                      {
                        text: "Yes, 2 bottles",
                        value: true,
                        checked: values.orderBottlesOption === "true",
                        testId: "orderBottlesYesOption",
                      },
                      {
                        text: "No, I'll order later",
                        value: false,
                        checked: values.orderBottlesOption === "false",
                        testId: "orderBottlesNoOption",
                      },
                    ]}
                  />
                  <AnimatePresence>
                    <motion.div
                      initial="initial"
                      animate="animate"
                      exit="exit"
                      variants={heightFadeVariants}
                      transition={heightFadeTransition}
                      className={styles.dependant_fields_wrapper}
                    >
                      <p
                        className={classNames(styles.label, "inline")}
                        data-testid="gasBottlesLocationLabel"
                      >
                        {lpgLocationQuestion}
                      </p>
                      {enableLPGLocationOverlay && (
                        <button
                          data-testid="gasBottlesLocationOverlay"
                          onClick={() => setLocationInfoModal(true)}
                          className="ml-2 align-text-bottom text-black-primary md:align-text-top"
                        >
                          <Icon name="Info" size="18" />
                        </button>
                      )}
                      <RadioList
                        name="gasBottlesLocation"
                        onChange={handleChange}
                        errorMessage={errors.gasBottlesLocation}
                        showError={
                          errors.gasBottlesLocation &&
                          touched.gasBottlesLocation
                        }
                        size="medium"
                        isHalfWidth
                        items={getPropertyAccessListContent(
                          "gasBottlesLocation",
                        ).map((location) => ({
                          text: location.text,
                          value: location.value,
                          checked: values.gasBottlesLocation === location.value,
                          testId: `gasBottlesLocation${location.text}`,
                        }))}
                      />
                    </motion.div>
                  </AnimatePresence>
                </motion.div>
              )}
            </AnimatePresence>
          </motion.div>
        )}
      </AnimatePresence>
      {showLocationInfoModal && (
        <Modal
          onRequestClose={onInfoModalClose}
          isOpen
          title={lpgLocationOverlayTitle}
          buttonText={lpgLocationOverlayButton}
        >
          {lpgLocationOverlayDescription}
        </Modal>
      )}
    </>
  );
}

export default BottledGasQuestions;
