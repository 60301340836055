import { motion } from "framer-motion";

const transition = {
  delay: 0.3,
  type: "spring",
  bounce: 0.3,
  duration: 0.3,
};

function FadeInOnScroll({ children }) {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      viewport={{ once: true, amount: 0.2 }}
      transition={transition}
    >
      {children}
    </motion.div>
  );
}

function FadeInBottomOnScroll({ children, isSecondary }) {
  return (
    <motion.div
      initial={{ y: isSecondary ? 60 : 20, opacity: 0 }}
      whileInView={{ y: 0, opacity: 1 }}
      viewport={{ once: true, amount: 0.2 }}
      transition={transition}
    >
      {children}
    </motion.div>
  );
}

function ScaleOnScroll({ children }) {
  return (
    <motion.div
      initial={{ scale: 1.05, opacity: 0 }}
      whileInView={{ scale: 1, opacity: 1 }}
      viewport={{ once: true, amount: 0.2 }}
      transition={{ delay: 0.3, type: "tween", duration: 0.3 }}
      className="h-full"
    >
      {children}
    </motion.div>
  );
}

export { FadeInOnScroll, FadeInBottomOnScroll, ScaleOnScroll };
