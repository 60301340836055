import classNames from "classnames";
import styles from "./energy-rate.module.scss";

export default function SeasonalEnergyRateItem(props) {
  const { title, seasonalRates, inclGst } = props;
  return (
    <div data-testid="SeasonalEnergyRatesContainer">
      <div className={classNames(styles.rate_item, styles.seasonal_rate_title)}>
        <div className={styles.rate_item_header}>
          <span className={styles.rate_item_title}>{title}</span>
        </div>
      </div>

      {seasonalRates.map((rate) => {
        const subtitle = inclGst
          ? rate.fixtureCalculationIncludingGST
          : rate.fixtureCalculationExcludingGST;

        const amount = inclGst
          ? rate.valueIncludingGST
          : rate.valueExcludingGST;
        return (
          <div className={styles.rate_item} key={rate.seasonName}>
            <div className={styles.rate_item_header}>
              <span className={styles.rate_item_title}>
                &#x21B3;{rate.seasonName}
              </span>
              {subtitle && (
                <span className={styles.rate_item_subtitle}>({subtitle})</span>
              )}
            </div>
            <div className={styles.rate_item_text}>{amount}</div>
          </div>
        );
      })}
    </div>
  );
}
