import { motion } from "framer-motion";
import { compact } from "lodash-es";
import { IconListItem } from "components/IconListItem";
import {
  heightFadeTransition,
  heightFadeVariants,
  staticVariants,
} from "./animations";

export function AddressDetails({ icpData, showGasIcpDetails, isNavigating }) {
  const variants = isNavigating ? staticVariants : heightFadeVariants;
  return (
    <motion.div
      initial="initial"
      animate="animate"
      exit="exit"
      variants={variants}
      transition={heightFadeTransition}
      data-testid="addressDetails"
      className="p-3 bg-black-50 rounded-5"
    >
      {icpData.elecRegistryAddress && (
        <IconListItem icon="Home" title="ICP Address" theme="tertiary">
          {compact([
            icpData.elecRegistryAddress.fullAddress,
            icpData.elecRegistryAddress.postCode.padStart(4, "0"),
          ]).join(", ")}
        </IconListItem>
      )}
      <IconListItem
        icon="ElectricityWhite"
        title="ICP Number for Electricity"
        theme="tertiary"
      >
        {icpData.elecIcp}
      </IconListItem>
      {showGasIcpDetails && icpData.gasIcp && (
        <IconListItem
          data-testid="gasIcpDetails"
          icon="NaturalGasWhite"
          title="ICP Number for Gas"
          theme="tertiary"
        >
          {icpData.gasIcp}
        </IconListItem>
      )}
    </motion.div>
  );
}
