import { appBusinessRoutes, appResidentialRoutes } from "helpers/constants";

const routesToHideHeaderFrom = [
  appBusinessRoutes.success,
  appBusinessRoutes.callbackSuccess,
  appResidentialRoutes.success,
  appResidentialRoutes.callbackSuccess,
];

export const hideHeader = (location) => {
  const { pathname } = location;

  const result = routesToHideHeaderFrom.some((route) =>
    pathname?.includes(route),
  );

  return result;
};
