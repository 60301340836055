import { runAsync } from "helpers";
import { orderBy, uniqBy } from "lodash-es";
import { getContentListAll } from "helpers/agilityHelper";
import { formatDate, tryParseISODate } from "helpers/dateHelper";

function getTagData(tag) {
  return {
    title: tag.fields.title,
    contentID: tag.contentID,
  };
}

function getDocumentItemData(documentItem) {
  const {
    title,
    publishDate = null,
    tag,
    type,
    tag_TextField = null,
    buttonText = null,
    buttonText_TextField = null,
    url,
  } = documentItem.fields;

  const tagSafe = tag ? getTagData(tag) : null;
  const year = formatDate(publishDate, "YYY");
  const month = formatDate(publishDate, "MMMM");

  return {
    contentID: documentItem.contentID,
    fields: {
      title,
      publishDate,
      type,
      year,
      month,
      tag: tagSafe,
      tag_TextField,
      buttonText,
      buttonText_TextField,
      url,
    },
  };
}

async function getCustomInitialProps({ agility, languageCode, item: module }) {
  // getting module data but with reference name for documentstoList instead of the array
  const [moduleData, error] = await runAsync(() =>
    agility.getContentItem({
      contentID: module.contentID,
      languageCode,
      contentLinkDepth: 0,
      expandAllContentLinks: false,
    }),
  );

  if (error) {
    return { documentItems: [] };
  }

  const { referencename } = moduleData.fields.documentstoList;

  // passing the reference name to get all the documentstoList items
  const result = await getContentListAll(agility, {
    referenceName: referencename,
    languageCode,
  });

  const documentItems = result.items.map(getDocumentItemData);

  const allTags = documentItems
    .filter((item) => item.fields.tag !== null)
    .map((item) => item.fields.tag);

  const documentItemsSortedByDate = documentItems.sort(
    (firstDocumentItem, secondDocumentItem) => {
      const firstDocumentDate = tryParseISODate(
        firstDocumentItem.fields.publishDate,
      );
      const secondDocumentDate = tryParseISODate(
        secondDocumentItem.fields.publishDate,
      );
      return secondDocumentDate - firstDocumentDate;
    },
  );

  const allDates = documentItems.map((item) => {
    const parsedDate = tryParseISODate(item.fields.publishDate);
    return {
      date: item.fields.publishDate,
      year: item.fields.year,
      month: item.fields.month,
      yearValue: parsedDate.getFullYear(),
      monthValue: parsedDate.getMonth(),
    };
  });

  const sortedDates = orderBy(
    allDates,
    ["yearValue", "monthValue"],
    ["desc", "asc"],
  );

  const uniqueDates = uniqBy(
    sortedDates,
    (date) => `${date.year}-${date.month}`,
  );

  const uniqueYears = uniqBy(uniqueDates, "year").map((date) => date.year);
  const uniqueTags = uniqBy(allTags, (tag) => tag.contentID);

  return {
    documentItems: documentItemsSortedByDate,
    allDates: uniqueDates,
    allYears: uniqueYears,
    categories: uniqueTags,
  };
}

export { getCustomInitialProps };
