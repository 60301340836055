import {
  EnergyRateContainer,
  EnergyRateItem,
  EnergyRatePlaceholderContainer,
} from "join-form/components/energy-rate";
import { PLAN_TYPE_BASIC, PLAN_USAGE_LOW } from "helpers/constants";
import { isNaturalGasSelected } from "helpers/productHelper";
import {
  getRateDescription,
  getRateUnitOfMeasurement,
} from "helpers/rateHelper";
import { connect } from "react-redux";

function NaturalGasRates(props) {
  const {
    shouldRender,
    planType,
    usageType,
    lowUsageTariffList,
    highUsageTariffList,
    inclGst,
    visible,
    onToggle,
    highlightOnMobile,
  } = props;

  if (!shouldRender) {
    return null;
  }

  const tariffList =
    usageType === PLAN_USAGE_LOW ? lowUsageTariffList : highUsageTariffList;

  return tariffList.length ? (
    <EnergyRateContainer
      icon="GasBadge"
      title="Natural Gas"
      data-testid={`${planType}_${usageType}_NaturalGasRates`}
      visible={visible}
      onToggle={onToggle}
      highlightOnMobile={highlightOnMobile}
    >
      {tariffList.map((tariffItem, index) => {
        const {
          description,
          meteringUnitOfMeasurement,
          pricingUnitOfMeasurement,
          rateExcludingGST,
          rateIncludingGST,
        } = tariffItem;

        const title = getRateDescription(description);
        const rateValue = inclGst ? rateIncludingGST : rateExcludingGST;
        const unitOfMeasurement = getRateUnitOfMeasurement(
          meteringUnitOfMeasurement,
        );
        const value = `${pricingUnitOfMeasurement}${rateValue.toFixed(
          4,
        )} / ${unitOfMeasurement}`;

        return <EnergyRateItem key={index} title={title} value={value} />;
      })}
    </EnergyRateContainer>
  ) : (
    <EnergyRatePlaceholderContainer
      icon="GasBadgeBlack"
      title="Natural Gas"
      visible={visible}
      onToggle={onToggle}
      data-testid={`${planType}_NaturalGasRatesPlaceholder`}
    />
  );
}

const mapStateToProps = (state, { planType, ...ownProps }) => {
  const plan = planType === PLAN_TYPE_BASIC ? "basicPlan" : "plusPlan";
  const {
    appState: { products },
    residentialOffers: {
      naturalGasOffer: {
        [plan]: {
          lowUsage: { tariffList: lowUsageTariffList },
          highUsage: { tariffList: highUsageTariffList },
        },
      },
    },
  } = state;

  const shouldRender = isNaturalGasSelected(products);

  return { lowUsageTariffList, highUsageTariffList, shouldRender, ...ownProps };
};

export default connect(mapStateToProps)(NaturalGasRates);
