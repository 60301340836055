import { ContentZone } from "@agility/nextjs";
import PlanSelectPage from "join-form/pages/residential/plan-select";
import { getModule } from "page-modules";

// hardcoded modules inside PlanSelectPage
const modules = ["GE_JOIN_Intro_Header"];

function ResidentialPlanSelectTemplate(props) {
  // remove hardcoded modules for ContentZone
  const updatedProps = {
    ...props,
    page: {
      ...props.page,
      zones: {
        ...props.page.zones,
        Content: props.page.zones.Content.filter(
          ({ moduleName }) => !modules.includes(moduleName),
        ),
      },
    },
  };

  return (
    <div>
      <PlanSelectPage {...props} modules={modules} />
      <ContentZone name="Content" {...updatedProps} getModule={getModule} />
    </div>
  );
}

export { ResidentialPlanSelectTemplate };
