import { Download, ExternalLink, VideoPlayBlack } from "assets/icons";
import { Button } from "components/Button";
import { DOCUMENT_ACTION_TYPES, VARIANT } from "helpers/constants";

const buttonProps = {
  [DOCUMENT_ACTION_TYPES.video]: {
    text: "watch",
    IconComponent: VideoPlayBlack,
  },
  [DOCUMENT_ACTION_TYPES.file]: {
    text: "download",
    IconComponent: Download,
  },
  [DOCUMENT_ACTION_TYPES.external]: {
    text: "go to website",
    IconComponent: ExternalLink,
  },
};

function LatestDocumentActionButton({ buttonText, type, url }) {
  const props = buttonProps[type] ?? buttonProps.file;
  const { text, IconComponent } = props;

  return (
    <Button
      size="medium"
      variant={VARIANT.darkTransparent}
      href={url.href}
      prefetch={false}
      target={url.target}
      data-testid="documentActionButton"
    >
      <span>{buttonText ?? text}</span>
      <IconComponent className="w-5 h-5 fill-current" />
    </Button>
  );
}

export { LatestDocumentActionButton };
