import classNames from "classnames";
import { ErrorMessage } from "./ErrorMessage";

function InputList({ label, className, name, errorMessage, children }) {
  return (
    <div className={className}>
      <p className="text-15/24 md:text-18/28 mb-5 text-black-primary">
        {label}
      </p>
      <div
        className={classNames(
          "flex flex-col gap-y-5",
          "md:grid md:grid-cols-2 md:gap-x-7 md:gap-y-7",
          "lg:gap-x-8 lg:gap-y-8",
        )}
      >
        {children}
      </div>
      {errorMessage && (
        <ErrorMessage message={errorMessage} testId={`${name}ErrorMessage`} />
      )}
    </div>
  );
}

export { InputList };
