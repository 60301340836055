import { useState } from "react";
import { createSelector } from "@reduxjs/toolkit";
import { setRegistryAddress } from "actions/addressState/addressStateActions";
import { updateProductSelection } from "actions/appState/appStateActions";
import { setSignupForAdditionalPropertiesOrIcps } from "actions/appUserStateActions";
import { setBusinessOffers } from "actions/businessOfferActions";
import {
  checkStoreStateForBusinessCallbackFlow,
  setCallbackReferrerUrl,
  unsetCallbackReferrerUrl,
} from "actions/callbackPageActions";
import { setIcpDetails } from "actions/icpState/icpActions";
import { Formik } from "formik";
import { AnimatePresence, motion } from "framer-motion";
import { isEmpty, merge } from "lodash-es";
import { useRouter } from "next/router";
import { useDispatch, useSelector } from "react-redux";
import {
  selectAppState,
  selectAppUserState,
  selectIcpState,
} from "reducers/selector";
import {
  findElecRegistryDetailsByIcp,
  findGasRegistryDetailsByIcp,
} from "services/FindAddressByIcp";
import * as Yup from "yup";
import { AddressIcpInput, RegistryAddressDetails } from "components/AddressIcp";
import { InlineBlockEditor } from "components/BlockEditor";
import { ButtonCheckboxSelection } from "components/ButtonCheckboxSelection";
import { ButtonOnOffToggle } from "components/ButtonOnOffToggle";
import { ConditionalFormWithButtonToggle } from "components/ConditionalFormWithButtonToggle";
import { Feedback } from "components/Feedback";
import { Icon } from "components/Icon";
import { Link } from "components/Link";
import { Modal } from "components/Modal";
import { StepProgressionButton } from "components/StepProgressionButton";
import { getIcpDetails } from "helpers/addressIcpHelper";
import {
  appBusinessRoutes,
  CALLBACK_REASON_LPG_REQUOTE_PPD_PLANS,
  CALLBACK_REASON_NO_RATES_FOR_SELECTED_TERM,
  PRODUCT_ELECTRICITY,
  PRODUCT_GAS,
  PRODUCT_LPG,
} from "helpers/constants";
import { IntroHeader } from "page-modules/Join/IntroHeader";
import styles from "./address-icp-page.module.scss";
import {
  footerFade,
  footerFadeTransition,
  heightFadeTransition,
  heightFadeVariants,
  itemContainer,
  itemFade,
} from "./animations";

const modules = [
  "ge_join_intro_header",
  "ge_join_business_fuel_type_icp",
  "ge_join_business_multiple_properties_checkbox",
];

function getPageData(page) {
  const content = page.zones.Content;
  const pageModules = content
    .filter(({ moduleName }) => modules.includes(moduleName?.toLowerCase()))
    .map((moduleInfo) => moduleInfo?.item)
    .filter(Boolean);

  const pageModulesCombined = merge({}, ...pageModules);

  return pageModulesCombined;
}

const isRegistryDataAvailable = ({ icp, registryAddress }) => {
  const isIcpAvailable = icp !== "";
  const isRegistryAddressAvailable = isEmpty(registryAddress) === false;
  const result = isIcpAvailable && isRegistryAddressAvailable;
  return result;
};

const getSelectedFuelTypes = ({
  products,
  isElecRegistryDataAvailable,
  isGasRegistryDataAvailable,
}) => {
  const selectedProducts = products.filter(({ name, isSelected }) => {
    if (name === PRODUCT_ELECTRICITY) {
      const shouldSelectElectricity = isSelected && isElecRegistryDataAvailable;
      return shouldSelectElectricity;
    }
    if (name === PRODUCT_GAS) {
      const shouldSelectGas = isSelected && isGasRegistryDataAvailable;
      return shouldSelectGas;
    }
    return isSelected;
  });

  const selectedFuelTypes = selectedProducts.map(({ name }) => name);
  return selectedFuelTypes;
};

const selectBusinessIcpState = createSelector(
  [selectAppState, selectAppUserState, selectIcpState],
  (appState, appUserState, icpData) => {
    const { products } = appState;
    const {
      elecIcp,
      elecMeterId,
      elecRegistryAddress,
      gasIcp,
      gasMeterId,
      gasRegistryAddress,
    } = icpData;
    const { signupForAdditionalPropertiesOrIcps } = appUserState;

    const isElecRegistryDataAvailable = isRegistryDataAvailable({
      icp: elecIcp,
      registryAddress: elecRegistryAddress,
    });
    const isGasRegistryDataAvailable = isRegistryDataAvailable({
      icp: gasIcp,
      registryAddress: gasRegistryAddress,
    });

    const selectedFuelTypes = getSelectedFuelTypes({
      products,
      isElecRegistryDataAvailable,
      isGasRegistryDataAvailable,
    });

    const elecRegistryData = isElecRegistryDataAvailable
      ? {
          ICPIdentifier: elecIcp,
          meter: [{ id: elecMeterId }],
          registryAddress: elecRegistryAddress,
        }
      : null;
    const gasRegistryData = isGasRegistryDataAvailable
      ? {
          ICPIdentifier: gasIcp,
          meter: [{ id: gasMeterId }],
          registryAddress: gasRegistryAddress,
        }
      : null;

    return {
      selectedFuelTypes,
      elecIcp,
      elecRegistryAddress,
      elecRegistryData,
      gasIcp,
      gasRegistryAddress,
      gasRegistryData,
      signupForAdditionalPropertiesOrIcps,
    };
  },
);

export function BusinessIcpModule({ pageData, props }) {
  const {
    values: {
      selectedFuelTypes,
      elecIcp,
      elecRegistryData,
      gasIcp,
      gasRegistryData,
      signupForAdditionalPropertiesOrIcps,
    },
    touched,
    errors,
    handleChange,
    handleSubmit,
    isSubmitting,
    isValid,
    setFieldValue,
  } = props;

  const {
    fields: {
      fuelOptions,
      blockEditor,
      enterAddressLabel,
      signupForMultiplePropertiesLabel,
      signupForMultiplePropertiesModalContent,
      signupForMultiplePropertiesModalTitle,
      title,
      whatsanIcpmodalcontent,
      whatsanIcpmodaltitle,
      whatsanIcpnumberlabel,
    },
  } = pageData;

  const [modalIsVisible, setModalVisibility] = useState(false);
  const [
    additionalPropertiesOrIcpsModalIsVisible,
    setAdditionalPropertiesOrIcpsModalVisibility,
  ] = useState(false);

  const [isFetchingElecRegistryDetails, setFetchingElecRegistryDetails] =
    useState(false);
  const [elecRegistryError, setElecRegistryError] = useState(null);

  const [isFetchingGasRegistryDetails, setFetchingGasRegistryDetails] =
    useState(false);
  const [gasRegistryError, setGasRegistryError] = useState(null);

  const onIcpInfoButtonClick = (e) => {
    e.preventDefault();
    setModalVisibility(true);
  };

  const onAdditionalPropertiesOrIcpsInfoClick = () => {
    setAdditionalPropertiesOrIcpsModalVisibility(true);
  };

  const toggleSignupForAdditionalPropertiesOrIcps = () => {
    const toggledState = signupForAdditionalPropertiesOrIcps ? false : true;
    setFieldValue("signupForAdditionalPropertiesOrIcps", toggledState);
  };

  const toggleFuelTypeSelection = (fuelTypeCodeName) => {
    const isFuelSelected = selectedFuelTypes.includes(fuelTypeCodeName);

    const selection = isFuelSelected
      ? selectedFuelTypes.filter(
          (selectedFuelTypeCodeName) =>
            selectedFuelTypeCodeName !== fuelTypeCodeName,
        )
      : selectedFuelTypes.concat(fuelTypeCodeName);

    setFieldValue("selectedFuelTypes", selection);
  };

  const handleElecIcpChange = async (e) => {
    const inputValue = e.target.value.trim();

    handleChange(e);

    if (elecRegistryData) {
      setFieldValue("elecRegistryData", null);
    }

    if (elecRegistryError) {
      setElecRegistryError(false);
    }

    if (inputValue.length < 15) {
      return;
    }
    try {
      setFetchingElecRegistryDetails(true);
      const data = await findElecRegistryDetailsByIcp({
        icp: inputValue,
      });
      setFieldValue("elecRegistryData", data);
    } catch (ex) {
      setElecRegistryError(true);
      setFieldValue("elecRegistryData", null);
    } finally {
      setFetchingElecRegistryDetails(false);
    }
  };

  const handleGasIcpChange = async (e) => {
    const inputValue = e.target.value.trim();

    handleChange(e);

    if (gasRegistryData) {
      setFieldValue("gasRegistryData", null);
    }

    if (gasRegistryError) {
      setGasRegistryError(false);
    }

    if (inputValue.length < 15) {
      return;
    }
    try {
      setFetchingGasRegistryDetails(true);
      const data = await findGasRegistryDetailsByIcp({
        icp: inputValue,
      });
      setFieldValue("gasRegistryData", data);
    } catch (ex) {
      setGasRegistryError(true);
      setFieldValue("gasRegistryData", null);
    } finally {
      setFetchingGasRegistryDetails(false);
    }
  };

  const onModalClose = () => {
    setModalVisibility(false);
  };

  const onAdditionalPropertiesOrIcpsModalClose = () => {
    setAdditionalPropertiesOrIcpsModalVisibility(false);
  };

  const shouldDisableContinueButton =
    isValid === false ||
    isFetchingElecRegistryDetails ||
    isFetchingGasRegistryDetails ||
    isSubmitting;

  return (
    <motion.div
      className={styles.container}
      exit="undefined"
      data-testid="addressIcp"
    >
      <IntroHeader
        module={{
          fields: {
            title,
            blockEditor,
          },
        }}
      />
      <motion.div
        initial="initial"
        animate="animate"
        exit="exit"
        variants={itemContainer}
        className={styles.body}
      >
        {fuelOptions.map((option) => {
          const { title, selectedIcon, defaultIcon, id, enterIcpLabel } =
            option.fields;
          const isSelected = selectedFuelTypes.includes(id);

          if (id === PRODUCT_ELECTRICITY) {
            return (
              <motion.div variants={itemFade} className={styles.item} key={id}>
                <ConditionalFormWithButtonToggle
                  title={title}
                  isSelected={isSelected}
                  onClick={() => toggleFuelTypeSelection(PRODUCT_ELECTRICITY)}
                  icon={isSelected ? selectedIcon : defaultIcon}
                >
                  <AddressIcpInput
                    name="elecIcp"
                    placeholder={enterIcpLabel}
                    value={elecIcp}
                    onChange={handleElecIcpChange}
                    errorMessage={errors.elecIcp}
                    showError={touched.elecIcp && errors.elecIcp}
                    isBusy={isFetchingElecRegistryDetails}
                    readOnly={isFetchingElecRegistryDetails || isSubmitting}
                  />
                  <AnimatePresence>
                    <RegistryAddressDetails
                      key="elecRegistryAddressDetails"
                      testIdPrefix="elec"
                      serialNumberTitle="Serial Number for Electricity"
                      icon="ElectricityWhite"
                      registryData={elecRegistryData}
                    />
                    {elecRegistryError && (
                      <motion.div
                        initial="initial"
                        animate="animate"
                        exit="exit"
                        key="elecErrorMessage"
                        variants={heightFadeVariants}
                        transition={heightFadeTransition}
                        className={styles.registry_error_wrapper}
                      >
                        <div className={styles.registry_error}>
                          <Feedback type="error" data-testid="elecErrorMessage">
                            Please enter a valid ICP number
                          </Feedback>
                        </div>
                      </motion.div>
                    )}
                  </AnimatePresence>
                </ConditionalFormWithButtonToggle>
              </motion.div>
            );
          }

          if (id === PRODUCT_GAS) {
            return (
              <motion.div variants={itemFade} className={styles.item} key={id}>
                <ConditionalFormWithButtonToggle
                  title={title}
                  isSelected={isSelected}
                  onClick={() => toggleFuelTypeSelection(PRODUCT_GAS)}
                  icon={isSelected ? selectedIcon : defaultIcon}
                >
                  <AddressIcpInput
                    name="gasIcp"
                    placeholder="ICP Number for Natural gas"
                    value={gasIcp}
                    onChange={handleGasIcpChange}
                    errorMessage={errors.gasIcp}
                    showError={touched.gasIcp && errors.gasIcp}
                    isBusy={isFetchingGasRegistryDetails}
                    readOnly={isFetchingGasRegistryDetails || isSubmitting}
                  />
                  <AnimatePresence>
                    <RegistryAddressDetails
                      key="gasRegistryAddressDetails"
                      testIdPrefix="gas"
                      serialNumberTitle="Serial Number for Gas"
                      icon="NaturalGasWhite"
                      registryData={gasRegistryData}
                    />
                    {gasRegistryError && (
                      <motion.div
                        initial="initial"
                        animate="animate"
                        exit="exit"
                        key="gasErrorMessage"
                        variants={heightFadeVariants}
                        transition={heightFadeTransition}
                        className={styles.registry_error_wrapper}
                      >
                        <div className={styles.registry_error}>
                          <Feedback type="error" data-testid="gasErrorMessage">
                            Please enter a valid ICP number
                          </Feedback>
                        </div>
                      </motion.div>
                    )}
                  </AnimatePresence>
                </ConditionalFormWithButtonToggle>
              </motion.div>
            );
          }

          if (id === PRODUCT_LPG) {
            return (
              <motion.div variants={itemFade} className={styles.item} key={id}>
                <ButtonOnOffToggle
                  isSelected={isSelected}
                  onClick={() => toggleFuelTypeSelection(PRODUCT_LPG)}
                  className="w-full"
                  icon={isSelected ? selectedIcon : defaultIcon}
                  title={title}
                />
              </motion.div>
            );
          }
        })}

        <motion.div variants={itemFade} className={styles.item}>
          <p className={styles.address_link_list}>
            <Link
              href={appBusinessRoutes.address}
              className={styles.address_link_item}
            >
              <a className={styles.address_link_item}>
                <span className={styles.address_link_item_text}>
                  {enterAddressLabel}
                </span>
                <Icon
                  name="Switch"
                  size="18"
                  className={styles.address_link_item_icon}
                />
              </a>
            </Link>
            <button
              onClick={onIcpInfoButtonClick}
              className={styles.address_link_item}
            >
              <span className={styles.address_link_item_text}>
                {whatsanIcpnumberlabel}
              </span>
              <Icon
                name="Info"
                size="18"
                className={styles.address_link_item_icon}
              />
            </button>
          </p>
        </motion.div>
      </motion.div>

      <motion.div
        initial="initial"
        animate="animate"
        exit="exit"
        variants={footerFade}
        transition={footerFadeTransition}
        className={styles.multiple_properties}
      >
        <ButtonCheckboxSelection
          isSelected={signupForAdditionalPropertiesOrIcps}
          onClick={toggleSignupForAdditionalPropertiesOrIcps}
          className={styles.multiple_properties_checkbox}
          onInfoIconClick={onAdditionalPropertiesOrIcpsInfoClick}
          size="medium"
          textSize="small"
        >
          {signupForMultiplePropertiesLabel}
        </ButtonCheckboxSelection>
      </motion.div>

      <div className="mt-10 md:mt-15 lg:mt-20 overflow-hidden">
        <AnimatePresence>
          {touched.selectedFuelTypes && errors.selectedFuelTypes && (
            <motion.div
              initial="initial"
              animate="animate"
              exit="exit"
              variants={heightFadeVariants}
              transition={heightFadeTransition}
              className="mb-8 md:mb-10"
              key="errorMessage"
            >
              <Feedback type="error" title="Error" data-testid="errorMessage">
                {errors.selectedFuelTypes}
              </Feedback>
            </motion.div>
          )}
        </AnimatePresence>
      </div>

      <StepProgressionButton
        applyDisabledStyle={shouldDisableContinueButton}
        onContinueClick={handleSubmit}
        backLink={appBusinessRoutes.businessDetails}
        testId="continueButton"
        isLoading={isSubmitting}
      />

      <Modal
        onRequestClose={onModalClose}
        isOpen={modalIsVisible}
        title={whatsanIcpmodaltitle}
      >
        <InlineBlockEditor jsonString={whatsanIcpmodalcontent} />
      </Modal>

      <Modal
        onRequestClose={onAdditionalPropertiesOrIcpsModalClose}
        isOpen={additionalPropertiesOrIcpsModalIsVisible}
        title={signupForMultiplePropertiesModalTitle}
      >
        <InlineBlockEditor
          jsonString={signupForMultiplePropertiesModalContent}
        />
      </Modal>
    </motion.div>
  );
}

const validationSchema = Yup.object().shape({
  selectedFuelTypes: Yup.array()
    .min(1, "Please select an option")
    .required("Please select an option"),
  elecIcp: Yup.string().when("selectedFuelTypes", {
    is: (values) => values.includes(PRODUCT_ELECTRICITY),
    then: Yup.string()
      .min(15, "Electricity ICP must be 15 characters")
      .required("This field is required"),
  }),
  elecRegistryData: Yup.object()
    .nullable()
    .when("selectedFuelTypes", {
      is: (values) => values.includes(PRODUCT_ELECTRICITY),
      then: Yup.object().required(
        "There was an error retrieving your address details",
      ),
    }),
  gasIcp: Yup.string().when("selectedFuelTypes", {
    is: (values) => values.includes(PRODUCT_GAS),
    then: Yup.string()
      .min(15, "Natural Gas ICP must be 15 characters")
      .required("This field is required"),
  }),
  gasRegistryData: Yup.object()
    .nullable()
    .when("selectedFuelTypes", {
      is: (values) => values.includes(PRODUCT_GAS),
      then: Yup.object().required(
        "There was an error retrieving your address details",
      ),
    }),
});

export function BusinessIcpTemplate(props) {
  const pageData = getPageData(props.page);
  const initialValues = useSelector(selectBusinessIcpState);
  const dispatch = useDispatch();

  const router = useRouter();

  const onSubmit = async (values, options) => {
    try {
      options.setSubmitting(true);

      const { selectedFuelTypes, signupForAdditionalPropertiesOrIcps } = values;

      const icpDetails = getIcpDetails(values);
      const { elecRegistryAddress, gasRegistryAddress } = icpDetails;

      dispatch(updateProductSelection(selectedFuelTypes));
      dispatch(
        setSignupForAdditionalPropertiesOrIcps(
          signupForAdditionalPropertiesOrIcps,
        ),
      );

      const registryAddress = elecRegistryAddress || gasRegistryAddress || {};
      dispatch(setRegistryAddress(registryAddress));

      dispatch(setIcpDetails(icpDetails));

      if (signupForAdditionalPropertiesOrIcps === false) {
        await dispatch(setBusinessOffers(true));
      }
    } catch (ex) {
      console.log(ex);
    } finally {
      const { shouldTriggerCallbackFlow, reasonCode } = dispatch(
        checkStoreStateForBusinessCallbackFlow(),
      );

      const canNavigateToCallbackPage =
        shouldTriggerCallbackFlow &&
        reasonCode !== CALLBACK_REASON_NO_RATES_FOR_SELECTED_TERM &&
        reasonCode !== CALLBACK_REASON_LPG_REQUOTE_PPD_PLANS;

      const path = canNavigateToCallbackPage
        ? appBusinessRoutes.callback
        : appBusinessRoutes.contactDetails;

      if (canNavigateToCallbackPage) {
        dispatch(setCallbackReferrerUrl(appBusinessRoutes.addressIcp));
      } else {
        dispatch(unsetCallbackReferrerUrl());
      }

      router.push(path);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      validateOnMount
      enableReinitialize
    >
      {(formikProps) => (
        <BusinessIcpModule pageData={pageData} props={formikProps} />
      )}
    </Formik>
  );
}
