import classNames from "classnames";
import { motion } from "framer-motion";
import { ArrowLeft, ArrowRight, Spinner } from "assets/icons";
import { Button } from "components/Button";
import { VARIANT } from "helpers/constants";

const footerFade = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: {
    opacity: 0,
    transition: {
      delay: 0,
    },
  },
};

const footerFadeTransition = {
  delay: 0.2,
};

export function StepProgressionButton(props) {
  const {
    text = "Continue",
    disabled = false,
    onContinueClick,
    backLink,
    isLoading = false,
    testId = "Continue",
    className,
    buttonContainerClassName = "",
    applyDisabledStyle = false,
  } = props;

  return (
    <motion.div
      initial="initial"
      animate="animate"
      exit="exit"
      variants={footerFade}
      transition={footerFadeTransition}
      className={classNames("pb-30 md:pb-45", className)}
    >
      <div
        className={classNames(
          "bg-white rounded-15 p-4 flex items-center border border-black-50",
          {
            "justify-end": !backLink,
            "justify-between": backLink,
          },
          buttonContainerClassName,
        )}
      >
        {backLink && (
          <Button
            href={backLink}
            size="round"
            className="flex items-center justify-center"
            data-testid="previousPage"
            variant={VARIANT.blackOutlined}
            disableHoverIconStyle
          >
            <ArrowLeft />
          </Button>
        )}
        <Button
          disabled={disabled}
          onClick={onContinueClick}
          className={classNames(
            "flex gap-3 items-center justify-center min-w-35 md:min-w-45",
            {
              "bg-orange-300 border-none shadow-none hover:bg-orange-300 hover:shadow-none cursor-default":
                applyDisabledStyle,
            },
          )}
          data-testid={testId}
          size="large"
          type="submit"
          disableHoverIconStyle={applyDisabledStyle}
        >
          {text}
          {isLoading ? (
            <Spinner
              className="w-5 h-5 motion-safe:animate-spin"
              data-testid="loadingIcon"
            />
          ) : (
            <ArrowRight data-testid="arrowRightIcon" />
          )}
        </Button>
      </div>
    </motion.div>
  );
}
