import { runAsync } from "helpers";

function getReferenceNames(dynamicPageItem) {
  const descriptionLists = dynamicPageItem.fields?.descriptionLists ?? [];

  const contentIds = descriptionLists.map((listItem) => listItem.contentID);

  return contentIds;
}

async function getCustomInitialProps({
  agility,
  languageCode,
  dynamicPageItem,
}) {
  const contentIds = getReferenceNames(dynamicPageItem);

  const getContentItemRequests = contentIds.map((contentID) =>
    agility.getContentItem({
      contentID,
      languageCode,
      expandAllContentLinks: true,
    }),
  );

  const [data, error] = await runAsync(() =>
    Promise.all(getContentItemRequests),
  );

  if (error) {
    return [];
  }

  return {
    descriptionList: data.flat(),
  };
}

export { getCustomInitialProps };
