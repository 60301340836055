import React from "react";
import { take } from "lodash-es";
import { getPaginationState } from "./helpers";
import { ACTION_TYPES, reducer } from "./reducer";

const defaultState = {
  filteredArticles: [],
  articleItems: [],
  tags: [],
  topics: [],
  allYears: [],
  allMonths: [],
  allDates: [],
  pageSize: 10,
  visibleArticlesCount: 10,
  selectedYear: "",
  selectedMonth: "",
  selectedTagIds: [],
  selectedTopicIds: [],
};

function useArticleFilter({
  articleItems,
  tags,
  topics,
  allYears,
  allDates,
  pageSize,
  visibleArticlesCount,
}) {
  const initialState = {
    ...defaultState,
    filteredArticles: articleItems,
    articleItems,
    tags,
    topics,
    allYears,
    allDates,
    pageSize,
    visibleArticlesCount,
  };

  const [state, dispatch] = React.useReducer(reducer, initialState);

  function filterArticles() {
    dispatch({ type: ACTION_TYPES.filterArticles });
  }

  function setYear(year) {
    dispatch({ type: ACTION_TYPES.setYear, payload: year });
    filterArticles();
  }

  function setMonth(month) {
    dispatch({ type: ACTION_TYPES.setMonth, payload: month });
    filterArticles();
  }

  function setTag(tagId) {
    dispatch({ type: ACTION_TYPES.setTag, payload: tagId });
    filterArticles();
  }

  function setTopic(topicId) {
    dispatch({ type: ACTION_TYPES.setTopic, payload: topicId });
    filterArticles();
  }

  function reset() {
    dispatch({ type: ACTION_TYPES.reset });
  }

  function loadMoreArticles() {
    dispatch({ type: ACTION_TYPES.loadMoreArticles });
  }

  const paginatedArticles = take(
    state.filteredArticles,
    state.visibleArticlesCount,
  );

  const { paginationText, showLoadMoreButton } = getPaginationState(
    paginatedArticles,
    state.filteredArticles,
  );

  return {
    filteredArticles: paginatedArticles,
    visibleArticlesCount: state.visibleArticlesCount,
    allMonths: state.allMonths,
    selectedYear: state.selectedYear,
    selectedMonth: state.selectedMonth,
    selectedTagIds: state.selectedTagIds,
    selectedTopicIds: state.selectedTopicIds,
    paginationText,
    showLoadMoreButton,
    setYear,
    setMonth,
    setTag,
    setTopic,
    reset,
    loadMoreArticles,
    dispatch,
  };
}

export { useArticleFilter };
