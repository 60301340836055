import { useEffect, useRef } from "react";
import { animate } from "framer-motion";

export const useAnimatedCounter = (maxValue, initialValue, transition) => {
  const ref = useRef();

  useEffect(() => {
    const node = ref.current;
    const controls = animate(initialValue, maxValue, {
      onUpdate(value) {
        if (node) {
          node.textContent = Math.ceil(value);
        }
      },
      ...transition,
    });
    return () => controls.stop();
  }, [initialValue, maxValue, transition]);

  return <span ref={ref} />;
};
