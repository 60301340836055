import classNames from "classnames";
import { SPECIAL_BLOCKS } from "./Blocks";
import { InlineBlockEditor } from "./InlineBlockEditor";

function SpecialBlocks({ data }) {
  return (
    <div className="mt-10 mb-10 last:mb-0 first:mt-0">
      <div
        className={classNames(
          "px-4 mx-auto sm:px-15 md:px-17 lg:px-23 xl:px-46",
          "max-w-full w-120 sm:w-210 md:w-auto",
        )}
        data-testid="blockEditorSpecialGroup"
      >
        <InlineBlockEditor data={data} blocks={SPECIAL_BLOCKS} />
      </div>
    </div>
  );
}

export { SpecialBlocks };
