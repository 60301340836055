import classNames from "classnames";
import { clamp } from "lodash-es";
import { useInstantSearch } from "react-instantsearch-hooks-web";

function getPaginationText({ page, hitsPerPage, nbHits }) {
  const resultsShowing = clamp(hitsPerPage * page, nbHits);

  if (resultsShowing === nbHits) {
    return "Showing all results";
  }

  return `Showing ${resultsShowing} of ${nbHits} results`;
}

function MetaData() {
  const {
    indexUiState: {
      page = 1,
      configure: { hitsPerPage },
    },
    results: { nbHits },
  } = useInstantSearch();

  const paginationText = getPaginationText({ page, hitsPerPage, nbHits });

  return (
    <div
      className={classNames(
        "flex items-center justify-center",
        "text-black-300",
        "text-13/20 md:text-14/20",
      )}
    >
      {paginationText}
    </div>
  );
}

export { MetaData, getPaginationText };
