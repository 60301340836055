import { useState } from "react";
import classNames from "classnames";
import { motion } from "framer-motion";
import { useScreenSize } from "hooks";
import { sortBy } from "lodash-es";
import { ModuleWrapper } from "components/Wrappers";
import { formatStat } from "helpers/numberStringHelper";
import { useAnimatedCounter } from "hooks/useAnimatedCounter";

const Counter = ({ delay, formattedStat }) => {
  const transition = {
    delay,
    duration: 1,
    ease: "easeInOut",
  };
  const counter = useAnimatedCounter(formattedStat.value, 0, transition);

  return (
    <p className="relative font-bold text-center pt-15 md:pt-20 text-black-primary text-40/48 md:text-60/68">
      {formattedStat.prepend}
      {counter}
      {formattedStat.append}
    </p>
  );
};
const BgCircle = ({ isMd }) => {
  const stroke = 16;
  const r = isMd ? 110 : 80;
  const viewBox = `0 0 ${r * 2 + stroke * 2} ${r * 2 + stroke * 2}`;

  return (
    <svg
      className="absolute text-dark-50"
      viewBox={viewBox}
      data-testid="stat-circle"
    >
      <circle cx="50%" cy="50%" r={r} fill="currentColor" />
    </svg>
  );
};

export const Stat = ({ module }) => {
  const {
    fields: { anchor, alignment, stats },
  } = module;
  const isRight = alignment === "right";
  const { isMd } = useScreenSize();
  const [isInView, setisInView] = useState(false);
  const sortedStats = sortBy(stats, ["properties.itemOrder"]);
  return (
    <ModuleWrapper id={anchor} psnid="psn_stat">
      <div
        className={classNames("flex flex-col", "sm:flex-row sm:flex-wrap", {
          "items-end justify-end": isRight,
        })}
      >
        {sortedStats.map((stat, index) => {
          const formattedStat = formatStat(stat.fields.stat.toLowerCase());
          return (
            <motion.div
              animate="visible"
              initial="hidden"
              className={classNames(
                "relative w-48 h-48 rounded-full md:w-63 md:h-63 bg-day-gradient mb-7 md:mb-10",
                isRight ? "sm:ml-7 md:ml-10" : "sm:mr-7 md:mr-10",
              )}
              key={`key-${stat.contentID}`}
              variants={{
                hidden: {
                  opacity: 0,
                  y: 20,
                },
                visible: {
                  opacity: 1,
                  y: 0,
                  transition: {
                    delay: index * 0.2,
                    duration: 0.3,
                  },
                },
              }}
              viewport={{ once: true, amount: 0.2 }}
              whileInView={
                /* istanbul ignore next */
                () => {
                  if (!isInView) {
                    setisInView(true);
                  }
                }
              }
            >
              <BgCircle isMd={isMd} />
              <Counter delay={0.2} formattedStat={formattedStat} />
              <p className="relative px-3 pt-1 text-center text-14/20 text-black-primary md:text-16/24">
                {stat.fields.description}
              </p>
            </motion.div>
          );
        })}
      </div>
    </ModuleWrapper>
  );
};
