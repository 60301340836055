import classNames from "classnames";
import { tailWindConfig } from "getConfig";
import { AnimatedCircle } from "components/AnimatedCircle";
import { Image } from "components/Image";

const baseConfig = {
  circle: {
    r: 46,
  },
  viewbox: "0 0 100 100",
};

function GoalIcon({ card, isComplete, showAnimatedCircle, isInView }) {
  if (isComplete) {
    return (
      <div
        className={classNames(
          "relative",
          "items-center justify-center shrink-0",
          "h-25 w-25 sm:h-35 sm:w-35 md:h-45 md:w-45",
        )}
      >
        <svg className="absolute" viewBox={baseConfig.viewbox}>
          <circle
            strokeWidth="8"
            cx="50%"
            cy="50%"
            fill="transparent"
            data-testid="bg-circle"
            stroke={tailWindConfig.theme.colors.black[50]}
            {...baseConfig.circle}
          />
        </svg>
        <div className="absolute inset-0 flex flex-col items-center justify-center">
          <div
            className={classNames(
              "flex max-w-10 sm:max-w-15 mix-blend-color-dodge invert",
            )}
          >
            <Image
              alt={card.fields.icon.label}
              height={card.fields.icon.height}
              width={card.fields.icon.width}
              src={card.fields.icon.url}
            />
          </div>
          <p className="font-medium text-white text-18/28 md:text-24/36">
            100%
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="relative flex items-center justify-center h-25 shrink-0 w-25 sm:h-35 sm:w-35 md:h-45 md:w-45">
      {showAnimatedCircle && (
        <AnimatedCircle
          className="font-medium text-18/28 md:text-24/36 text-black-primary"
          config={baseConfig}
          isInView={isInView}
          percentageCompletion={card.fields.percentageComplete}
        >
          <div className="flex max-w-10 sm:max-w-15">
            <Image
              alt={card.fields.icon.label}
              height={card.fields.icon.height}
              width={card.fields.icon.width}
              src={card.fields.icon.url}
            />
          </div>
        </AnimatedCircle>
      )}
    </div>
  );
}

export { GoalIcon };
