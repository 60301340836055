import { motion } from "framer-motion";
import { tailWindConfig } from "getConfig";
import { useAnimatedCounter } from "hooks/useAnimatedCounter";

export const BgCircle = ({ config }) => {
  return (
    <svg className="absolute" viewBox={config.viewbox}>
      <circle
        strokeWidth="8"
        cx="50%"
        cy="50%"
        fill="transparent"
        data-testid="bg-circle"
        stroke={tailWindConfig.theme.colors.black[50]}
        {...config.circle}
      />
    </svg>
  );
};
export const Circle = ({ config, circumference, fillPercent, isInView }) => {
  return (
    <svg className="absolute -rotate-90" viewBox={config.viewbox}>
      <defs>
        <radialGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
          <stop offset="94%" stopColor="#F58025" />
          <stop offset="100%" stopColor="#F26526" />
        </radialGradient>
      </defs>

      <motion.circle
        animate={isInView ? "animate" : "initial"}
        cx="50%"
        cy="50%"
        fill="transparent"
        initial="initial"
        stroke="url(#gradient)"
        strokeDasharray={circumference}
        strokeDashoffset={fillPercent}
        strokeLinecap="round"
        strokeWidth="8"
        transition={{
          duration: 3,
          ease: "easeInOut",
          ...config.transition,
        }}
        variants={{
          initial: {
            strokeDashoffset: circumference,
          },
          animate: {
            strokeDashoffset: fillPercent,
          },
        }}
        data-testid="animated-circle"
        {...config.circle}
      />
    </svg>
  );
};

export const AnimatedCircle = ({
  className,
  config,
  isInView,
  percentageCompletion,
  children,
}) => {
  const percent = Number(percentageCompletion);
  const counter = useAnimatedCounter(isInView ? percent : 0, 0, {
    duration: 3,
    ease: "easeInOut",
    ...config.transition,
  });
  const circumference = Math.ceil(2 * Math.PI * config.circle.r);
  const fillPercent = Math.abs(
    Math.ceil((circumference / 100) * (percent - 100)),
  );

  return (
    <div className="relative w-full h-full">
      <BgCircle config={config} />
      <Circle
        config={config}
        circumference={circumference}
        fillPercent={fillPercent}
        isInView={isInView}
      />
      <div className="absolute inset-0 flex flex-col items-center justify-center">
        {children}
        <p className={className}>{counter}%</p>
      </div>
    </div>
  );
};
