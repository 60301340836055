import {
  BUSINESS_DIRECT_DEBIT_DISCOUNT,
  CREDIT_CARD_DISCOUNT,
  DIRECT_DEBIT_DISCOUNT,
  DUAL_FUEL_DISCOUNT,
  EBILLING_DISCOUNT,
  MULTI_CONNECT_DISCOUNT,
  PAYMENT_METHOD_CREDIT_CARD,
  PAYMENT_METHOD_DIRECT_DEBIT,
  PAYMENT_METHOD_MANUAL,
  PLAN_TYPE_BASIC,
  PLAN_TYPE_EV_PLUS,
  PLAN_TYPE_PLUS_FIXED,
  PRODUCT_ELECTRICITY,
  RESIDENTIAL_PLAN_TERM_FIXED12,
  TWELVE_MONTH_FIXED_TERM_DISCOUNT,
} from "helpers/constants";
import { getSelectedAvailableProducts } from "helpers/productHelper";

export function getPaymentMethodDiscountByPlanType({
  paymentMethod,
  planType,
} = {}) {
  if (planType === PLAN_TYPE_BASIC) {
    return 0;
  }

  return getPaymentMethodDiscount({ paymentMethod });
}

export function getPaymentMethodDiscount(
  { paymentMethod } = {},
  isBusiness = false,
) {
  switch (paymentMethod) {
    case PAYMENT_METHOD_DIRECT_DEBIT:
      return isBusiness
        ? BUSINESS_DIRECT_DEBIT_DISCOUNT
        : DIRECT_DEBIT_DISCOUNT;

    case PAYMENT_METHOD_CREDIT_CARD:
      return CREDIT_CARD_DISCOUNT;

    case PAYMENT_METHOD_MANUAL:
    default:
      return 0;
  }
}

export function getDualFuelDiscount(products, isBusiness = false) {
  const selectedAvailableProducts = getSelectedAvailableProducts(products);

  const isMoreThanOneFuelTypeIsSelected = selectedAvailableProducts.length > 1;
  const isElectricitySelected = selectedAvailableProducts.some(
    ({ name }) => name === PRODUCT_ELECTRICITY,
  );

  const shouldApplyDualFuelDiscount =
    isMoreThanOneFuelTypeIsSelected && isElectricitySelected;

  if (shouldApplyDualFuelDiscount) {
    return isBusiness ? MULTI_CONNECT_DISCOUNT : DUAL_FUEL_DISCOUNT;
  }

  return 0;
}

export function getEBillingDiscount(planType) {
  const result = planType === PLAN_TYPE_BASIC ? 0 : EBILLING_DISCOUNT;
  return result;
}

export function getMaximumDiscountForPlusPlan(products) {
  const dualFuelDiscount = getDualFuelDiscount(products);

  const totalDiscount =
    EBILLING_DISCOUNT +
    DIRECT_DEBIT_DISCOUNT +
    TWELVE_MONTH_FIXED_TERM_DISCOUNT +
    dualFuelDiscount;

  return totalDiscount;
}

function getFixedTermDiscount({ planType }) {
  if (planType === PLAN_TYPE_PLUS_FIXED) {
    return TWELVE_MONTH_FIXED_TERM_DISCOUNT;
  }

  if (planType === PLAN_TYPE_EV_PLUS) {
    return TWELVE_MONTH_FIXED_TERM_DISCOUNT;
  }

  return 0;
}

export function getTotalDiscountPercentage({ products, planType }) {
  const baseDiscount = EBILLING_DISCOUNT + DIRECT_DEBIT_DISCOUNT;
  const dualFuelDiscount = getDualFuelDiscount(products);
  const fixedTermDiscount = getFixedTermDiscount({ planType });

  const totalDiscount = baseDiscount + dualFuelDiscount + fixedTermDiscount;

  return totalDiscount;
}

export function applyResidentialTermDiscounts({ termData, planType }) {
  return termData.map((termItem) => {
    return applyDiscountToResidentialTerm({ termItem, planType });
  });
}

export function applyDiscountToResidentialTerm({ termItem, planType } = {}) {
  const discountPercentage = getDiscountPercentage({ termItem, planType });
  return {
    ...termItem,
    discountPercentage,
  };
}

function getDiscountPercentage({ termItem, planType }) {
  const { codeName } = termItem;
  const discountPercentage =
    planType !== PLAN_TYPE_BASIC
      ? getPlusPlanTermDiscountPercentage(codeName)
      : getBasicPlanTermDiscountPercentage(codeName);

  return discountPercentage;
}

function getPlusPlanTermDiscountPercentage(termCodeName) {
  if (termCodeName === RESIDENTIAL_PLAN_TERM_FIXED12) {
    return TWELVE_MONTH_FIXED_TERM_DISCOUNT;
  }
  return 0;
}

function getBasicPlanTermDiscountPercentage(termCodeName) {
  return 0;
}
