import { createSignup } from "join-form/services/resiOnlineSignupService";
import { gtmCreateEcommerceObjectForResidential } from "./gtmActions";

export function createOnlineSignup() {
  return async (dispatch, getState) => {
    const { requestObject, transactionId } = await createSignup(getState());
    dispatch(
      gtmCreateEcommerceObjectForResidential({ requestObject, transactionId }),
    );
  };
}
