import classNames from "classnames";
import { Image } from "components/Image";
import { RotateAnimation } from "./animations";

function HeaderImage({ image }) {
  return (
    <div
      data-testid="imageContainer"
      className={classNames(
        "overflow-hidden ml-auto max-w-83 translate-y-15",
        "sm:translate-y-0",
        "md:max-w-full md:absolute md:w-83 md:right-17 md:-bottom-15",
        "lg:w-100 lg:-bottom-20 lg:right-46",
      )}
    >
      <RotateAnimation>
        <Image
          alt={image.label}
          src={image.url}
          width={image.width}
          height={image.height}
          layout="responsive"
        />
      </RotateAnimation>
    </div>
  );
}

export { HeaderImage };
