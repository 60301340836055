import React from "react";
import classNames from "classnames";
import { AccordionSectionCta } from "./AccordionSectionCta";

function AccordionSection({ title, anchor, psnid, cta = null, children }) {
  return (
    <div
      id={anchor}
      data-testid="accordionSection"
      data-psnid={psnid}
      className="mx-auto bg-white rounded-t-7 md:rounded-t-10 pb-7 -mb-7 scroll-mt-28 md:scroll-mt-48 lg:scroll-mt-56"
    >
      <div
        className={classNames(
          "px-4 mx-auto sm:px-15 md:px-17 lg:px-23 xl:px-46",
          "max-w-full w-120 sm:w-210 md:w-auto",
        )}
      >
        <div
          className={classNames(
            "flex flex-col",
            "gap-6 sm:gap-10",
            "py-15 md:py-25",
          )}
        >
          <div
            className={classNames(
              "flex flex-col",
              "sm:flex-row sm:justify-between sm:items-center",
            )}
          >
            <h2 className={classNames("font-bold text-27/32", "md:text-32/36")}>
              {title}
            </h2>
            {cta ? <AccordionSectionCta data={cta} /> : null}
          </div>
          {children}
        </div>
      </div>
    </div>
  );
}

export { AccordionSection };
