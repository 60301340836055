import StepNavigationItem from "join-form/components/step-navigation-item";
import { appResidentialRoutes } from "helpers/constants";
import { usePathWithoutQuery } from "hooks";

const {
  success,
  address,
  addressIcp,
  addressManual,
  fuel,
  occupancy,
  contactDetails,
  planSelect,
  termSelect,
  creditCheck,
  property,
  callback,
  callbackSuccess,
  carRegistration,
  ...allResidentialRoutes
} = appResidentialRoutes;
const validRoutes = Object.values(allResidentialRoutes);

export default function PropertyItem(props) {
  const pathWithoutQuery = usePathWithoutQuery();
  const isVisible = validRoutes.includes(pathWithoutQuery);

  return isVisible ? (
    <StepNavigationItem title="Property Details" path={property} {...props} />
  ) : null;
}
