import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { getToken } from "helpers/apiHelper";

const pageIdMappings = {
  "/": "home",
  "/for-home": "home",
  "/for-home/services/billing-and-payments": "billing-and-payments",
  "/for-home/products/bottled-gas": "bottled-gas",
  "/for-business": "business",
  "/help": "contact-and-support",
  "/for-home/products/electricity": "electricity",
  "/for-home/services/manage-your-account-online": "services/energyiq",
  "/help/faqs": "contact-and-support/faqs",
  "/for-home/move-house": "moving-house",
  "/for-home/products/natural-gas": "natural-gas",
  "/for-home/why-genesis/offers-and-rewards": "offers",
  "/for-home/products/explore-pricing": "plans-and-pricing",
  "/for-home/why-genesis/offers-and-rewards/power-shout": "powershout-info",
  "/for-home/products/explore-pricing/plan-check":
    "plans-and-pricing/price-plan-check",
  "/test/live-chat-test": "live-chat-test",
  "/for-home/products/electric-vehicles": "electric-vehicles",
  "/for-home/products/electric-vehicles/everywhere":
    "electric-vehicles/everywhere",
  "/for-home/services/new-build-connection": "get-connected",
  "/for-home/why-genesis": "why-genesis",
  "/for-home/products": "products",
  "/for-home/move-house/moving-house-checklist": "moving-house-checklist",
};

export async function getLiveChatButtonActiveStatus() {
  const page = window.location.pathname;
  try {
    const pageId = pageIdMappings[page] || page;
    const accessToken = await getToken();
    const result = await axios.get(
      `/v1/expr/genesys-cloud/webmessenger/status?pageId=${pageId}`,
      {
        baseURL: process.env.NEXT_PUBLIC_MULE_API_HOST_NAME,
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Client_id: process.env.NEXT_PUBLIC_MULE_API_CLIENT_ID,
          Client_secret: process.env.NEXT_PUBLIC_MULE_API_CLIENT_SECRET,
          "X-Transaction-ID": uuidv4(),
          "X-Brand-ID": "GENE",
          "X-Application-ID": "Genesys",
          "X-Channel-ID": "Chat",
        },
      },
    );

    return result.data.active;
  } catch (e) {
    return false;
  }
}
