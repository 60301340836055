import { motion } from "framer-motion";
import { get } from "lodash-es";
import { IconListItem } from "components/IconListItem";
import { getFullRegistryAddress } from "helpers/addressHelper";
import styles from "./address-icp.module.scss";
import { heightFadeTransition, heightFadeVariants } from "./animations";

export function RegistryAddressDetails({
  serialNumberTitle,
  icon,
  registryData,
  testIdPrefix = "",
}) {
  const meterId = get(registryData, "meter[0].id", "");

  return (
    registryData && (
      <motion.div
        initial="initial"
        animate="animate"
        exit="exit"
        variants={heightFadeVariants}
        transition={heightFadeTransition}
        className={styles.address_details_wrapper}
        data-testid={`${testIdPrefix}RegistryAddressDetails`}
      >
        <div className={styles.address_details_container}>
          <IconListItem icon="Home" title="ICP Address" theme="secondary">
            {getFullRegistryAddress(registryData.registryAddress)}
          </IconListItem>
          {meterId && (
            <IconListItem
              icon={icon}
              title={serialNumberTitle}
              theme="secondary"
              testId={`${testIdPrefix}SerialNumber`}
            >
              {meterId}
            </IconListItem>
          )}
        </div>
      </motion.div>
    )
  );
}
