import { useRef, useState } from "react";
import axios from "axios";
import classNames from "classnames";
import Downshift from "downshift";
import { debounce } from "lodash-es";
import { baseStyles, defaultStyles, filledStyles } from "components/Form";
import { ErrorMessage } from "components/Form/ErrorMessage";
import { ADDRESS_FINDER } from "helpers/constants";

export const fetchAddress = async (baseUrl, path, params) => {
  const queryParams = new URLSearchParams({
    key: process.env.NEXT_PUBLIC_ADDRESS_FINDER_KEY,
    format: "json",
    ...params,
  }).toString();

  const { data } = await axios.get(`${path}?${queryParams}`, {
    accept: "application/json",
    baseURL: baseUrl,
  });

  return data;
};

const Results = ({
  getMenuProps,
  inputItems,
  highlightedIndex,
  getItemProps,
}) => (
  <ul
    {...getMenuProps()}
    className="absolute w-full mt-2 overflow-y-scroll z-1 bg-dark-50 max-h-63 rounded-3"
  >
    {inputItems.map((item, index) => (
      <li
        className={classNames(
          "text-12/20 border-b border-b-black-100 last:border-b-dark-50 mx-2 p-2 text-black-primary",
          { "border-b-dark-50": highlightedIndex === index + 1 },
          {
            "!text-white border-b-dark-50 bg-orange-primary rounded-2 cursor-pointer":
              highlightedIndex === index,
          },
        )}
        data-testid={`address-finder-item-${index}`}
        key={item.a}
        {...getItemProps({ index, item })}
      >
        {item.a}
      </li>
    ))}
  </ul>
);

export const AddressFinder = ({ errorMessage, name, onChange }) => {
  const minLength = 2;
  const [inputValue, setInputValue] = useState("");
  const [inputItems, setInputItems] = useState([]);
  const debounceSetCurrValue = useRef(null); // cache fn
  const onInputValueChange = async (inputValue) => {
    if (inputValue?.length > minLength) {
      const data = await fetchAddress(
        ADDRESS_FINDER.baseUrl,
        ADDRESS_FINDER.autocomplete,
        { q: inputValue },
      );
      setInputItems(data.completions);
    }
  };
  if (!debounceSetCurrValue.current) {
    // debounce the cached fn
    debounceSetCurrValue.current = debounce(onInputValueChange, 500);
  }
  return (
    <Downshift
      onInputValueChange={(newInputValue) => {
        setInputValue(newInputValue);
        debounceSetCurrValue.current(newInputValue);
      }}
      onSelect={(item) => {
        if (item?.a) {
          onChange(item.a);
        }
      }}
      itemToString={(item) => (item ? item.a : "")} // set item.a where neccessary
      inputValue={inputValue}
    >
      {({
        getInputProps,
        getItemProps,
        getLabelProps,
        getMenuProps,
        isOpen,
        inputValue,
        highlightedIndex,
        clearSelection,
      }) => (
        <div className="relative" aria-labelledby={name}>
          <div
            className={classNames("pt-5 md:pt-4", "text-15/24 md:text-18/28")}
          >
            <label
              {...getLabelProps()}
              className={classNames(
                baseStyles.label,
                !inputValue ? defaultStyles.label : filledStyles.label,
              )}
              htmlFor={name}
              id={name}
            >
              Address
            </label>
            <input
              {...getInputProps({
                onChange: clearSelection,
              })}
              aria-labelledby={name}
              className={classNames(
                baseStyles.input,
                !inputValue ? defaultStyles.input : filledStyles.input,
                { "border-b-red-primary": !!errorMessage },
              )}
              id={name}
              placeholder="Enter your address"
            />
            {isOpen && (
              <Results
                getMenuProps={getMenuProps}
                inputItems={inputItems}
                highlightedIndex={highlightedIndex}
                getItemProps={getItemProps}
              />
            )}
          </div>
          {errorMessage && (
            <ErrorMessage
              message={errorMessage}
              testId={`${name}ErrorMessage`}
            />
          )}
        </div>
      )}
    </Downshift>
  );
};
