import { motion } from "framer-motion";
import { sortBy } from "lodash-es";
import { CallToAction } from "components/CallToAction";
import { Image } from "components/Image";
import { Link } from "components/Link";
import { ModuleWrapper } from "components/Wrappers";
import { VARIANT } from "helpers/constants";
import { getContentWithLineBreaks } from "helpers/htmlHelper";

const ImageSet = ({ icon }) => {
  return (
    <>
      <div className="absolute invisible w-full h-full pt-5 peer z-1 group-hover:visible">
        <Image
          alt={`${icon.label}-hover`}
          src={icon.url.replace(/_orange/, "_white")}
          width={icon.width}
          height={icon.height}
          layout="responsive"
          onError={function (e) {
            e.target.parentElement.parentElement.parentElement.removeChild(
              e.target.parentElement.parentElement,
            );
          }}
        />
      </div>
      <div className="absolute z-0 w-full h-full pt-5 peer-hover:invisible">
        <Image
          alt={icon.label}
          src={icon.url}
          width={icon.width}
          height={icon.height}
          layout="responsive"
        />
      </div>
    </>
  );
};
export const QuickLinks = ({ module }) => {
  const {
    fields: { cards, description, title },
  } = module;

  if (cards.length < 3) return null;

  const sortedCards = sortBy(cards, ["properties.itemOrder"]);

  const ctaProps = {
    data: {
      href: "/for-home/products",
      text: "Products Overview",
    },
    variant: VARIANT.darkTransparent,
  };
  return (
    <ModuleWrapper testid="quick-links" psnid="psn_quick_links">
      {(title || description) && (
        <div className="items-center justify-between md:flex">
          <div>
            {title && (
              <h2
                className="mb-3 font-bold md:mb-5 text-dark-primary text-32/36 sm:text-44/52"
                data-testid="quick-links-title"
              >
                {title}
              </h2>
            )}
            {description && (
              <p
                className="md:text-24/36 text-20/32"
                data-testid="quick-links-description"
              >
                {getContentWithLineBreaks(description)}
              </p>
            )}
          </div>
          <div>
            <CallToAction {...ctaProps} className="pt-5 pl-0" />
          </div>
        </div>
      )}
      <div className="flex flex-wrap gap-4 mt-10 md:gap-8">
        {sortedCards.map((card, index) => {
          const cta = card.fields.cta;
          const icon = card.fields.icon;
          return (
            <motion.div
              key={card.contentID}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: 0.2 }}
              variants={{
                hidden: { opacity: 0, y: 20 },
                visible: {
                  opacity: 1,
                  y: 0,
                  transition: {
                    delay: 0.2 + 0.2 * index,
                  },
                },
              }}
              transition={{
                delay: 0.3,
                type: "spring",
                bounce: 0.3,
                duration: 0.3,
              }}
            >
              <Link href={cta.href}>
                <a className="relative block pb-10 bg-black-50 rounded-7 h-44 w-34 md:h-57 md:w-44 hover:bg-day-gradient hover:text-white group">
                  <ImageSet icon={icon} />
                  <span className="absolute left-0 right-0 font-medium text-center bottom-7 md:bottom-10 text-15/24 md:text-18/28">
                    {cta.text}
                  </span>
                </a>
              </Link>
            </motion.div>
          );
        })}
      </div>
    </ModuleWrapper>
  );
};
