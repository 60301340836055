import { GreyWithImage } from "./GreyWithImage";
import { OrangeWithImage } from "./OrangeWithImage";
import { DefaultHeader } from "./DefaultHeader";

const headers = {
  sansImage: DefaultHeader,
  greyImage: GreyWithImage,
  orangeImage: OrangeWithImage,
};

function HeaderLevelThreeContent(props) {
  const { fields } = props.module;
  const Header = headers[fields.theme] || DefaultHeader;
  return <Header {...props} />;
}

export { HeaderLevelThreeContent };
