import { createSelector } from "@reduxjs/toolkit";
import { EnergyRateItem } from "join-form/components/energy-rate";
import { useSelector } from "react-redux";
import { selectAppState, selectResidentialOffers } from "reducers/selector";
import {
  PLAN_TYPE_EV_PLUS,
  PLAN_TYPE_TO_PLAN_NAME,
  PLAN_USAGE_LOW,
} from "helpers/constants";
import { isElectricitySelected } from "helpers/productHelper";
import {
  getRateDescription,
  getRateUnitOfMeasurement,
  sortEVTariffListForDisplay,
} from "helpers/rateHelper";
import { PlanCardTooltip } from "./PlanCardTooltip";

const stateSelector = createSelector(
  [selectAppState, selectResidentialOffers, (state, planType) => planType],
  (appState, residentialOffers, planType) => {
    const plan = PLAN_TYPE_TO_PLAN_NAME[planType];
    const { products } = appState;
    const electricityOffer = residentialOffers?.electricityOffer;

    const lowUsageTariffList =
      electricityOffer[plan]?.lowUsage.tariffList || [];
    const highUsageTariffList =
      electricityOffer[plan]?.highUsage.tariffList || [];

    const shouldRender = isElectricitySelected(products);
    const isEVplan = planType === PLAN_TYPE_EV_PLUS;

    const lowUsageTariffListToDisplay = isEVplan
      ? sortEVTariffListForDisplay(lowUsageTariffList)
      : lowUsageTariffList;

    const highUsageTariffListToDisplay = isEVplan
      ? sortEVTariffListForDisplay(highUsageTariffList)
      : highUsageTariffList;

    return {
      lowUsageTariffListToDisplay,
      highUsageTariffListToDisplay,
      shouldRender,
    };
  },
);

function ElectricityRates(props) {
  const { planType, usageType, inclGst, showDayNightRatesInfo, testIdPrefix } =
    props;

  const {
    lowUsageTariffListToDisplay,
    highUsageTariffListToDisplay,
    shouldRender,
  } = useSelector((state) => stateSelector(state, planType));

  const tariffList =
    usageType === PLAN_USAGE_LOW
      ? lowUsageTariffListToDisplay
      : highUsageTariffListToDisplay;

  if (!shouldRender) {
    return null;
  }

  if (!tariffList.length) {
    return (
      <div
        data-testid={`${testIdPrefix}_${planType}_${usageType}_NaturalGasRatesPlaceholder`}
        className="flex flex-col gap-y-2"
      >
        <h5 className="font-medium text-15/24 md:text-18/28 text-black-primary">
          Electricity
        </h5>
        <p className="text-black-primary text-14/20">
          We couldn&apos;t find your Electricity rates for this plan. We&apos;ll
          confirm these later.
        </p>
      </div>
    );
  }

  return (
    <div
      data-testid={`${testIdPrefix}_${planType}_${usageType}_ElectricityRates`}
      className="flex flex-col gap-y-2"
    >
      <h5 className="font-medium text-15/24 md:text-18/28 text-black-primary">
        Electricity
      </h5>
      <div className="flex flex-col gap-y-2 text-black-primary">
        {tariffList.map((tariffItem, index) => {
          const {
            description,
            meteringUnitOfMeasurement,
            pricingUnitOfMeasurement,
            rateExcludingGST,
            rateIncludingGST,
          } = tariffItem;

          const title = getRateDescription(description);
          const rateValue = inclGst ? rateIncludingGST : rateExcludingGST;
          const unitOfMeasurement = getRateUnitOfMeasurement(
            meteringUnitOfMeasurement,
          );
          const value = `${pricingUnitOfMeasurement}${rateValue.toFixed(
            4,
          )} / ${unitOfMeasurement}`;

          return <EnergyRateItem key={index} title={title} value={value} />;
        })}
        {showDayNightRatesInfo ? (
          <p className="flex gap-x-0.5 items-start text-13/20 md:text-14/20 text-black-primary py-2">
            <span>Day/night rates</span>
            <PlanCardTooltip>
              <h4 className="font-medium text-16/24">
                Energy EV electricity rates
              </h4>
              <p className="text-14/20">
                Your meter needs to be configured to allow for day/night
                pricing. This can take a few weeks. We&apos;ll keep in touch
                throughout the process.
                <br />
                <br />
                New customers: You&apos;ll go on Energy EV &ldquo;interim&rdquo;
                rates until your meter is updated, then be switched to day/night
                pricing.
                <br />
                <br />
                Existing customers: You&apos;ll stay on your current plan until
                your meter is updated, then be switched to day/night pricing.
                Ignore &ldquo;Interim&rdquo; rates.
              </p>
            </PlanCardTooltip>
          </p>
        ) : null}
      </div>
    </div>
  );
}

export { ElectricityRates };
