import { useEffect, useState } from "react";
import { createSelector } from "@reduxjs/toolkit";
import {
  setAddressDetails,
  setAddressIslandStatus,
  unsetAddressDetails,
} from "actions/addressState/addressStateActions";
import {
  setGasSelection,
  setNaturalGasAvailability,
  setRecentlyFailedApi,
  setResidentialCustomerType,
  submitOccupancyForm,
} from "actions/appState/appStateActions";
import { setIcpDetails, unsetIcpDetails } from "actions/icpState/icpActions";
import { setResidentialOffers } from "actions/residentialOffer/residentialOfferActions";
import classNames from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import { useRouter } from "next/router";
import { useDispatch, useSelector } from "react-redux";
import { IntroHeader } from "page-modules/Join/IntroHeader";
import {
  AddressAutoSuggest,
  SuggestionsFooterConfirmLater,
} from "components/AddressAutoSuggest";
import { AddressDetails } from "components/AddressAutoSuggest";
import { Alert } from "components/Alert";
import { StepProgressionButton } from "components/StepProgressionButton";
import { ButtonCheckboxSelection } from "components/ButtonCheckboxSelection";
import { Modal } from "components/Modal";
import { InlineBlockEditor } from "components/BlockEditor";
import {
  getFullAddressFromFinder,
  verifyAddressInNorthIsland,
} from "helpers/addressHelper";
import {
  appResidentialRoutes,
  appRoutes,
  STORAGE_KEY,
} from "helpers/constants";
import {
  gaJoinFormResidentialAddressPageLoad,
  gaJoinFormResidentialContinueButton,
} from "helpers/gaHelper";
import { getPageData } from "helpers/getPageData";

const modules = ["GE_JOIN_Intro_Header", "GE_JOIN_Residential_Occupancy"];

const itemFade = {
  initial: { opacity: 0, y: 60 },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: 0 },
};

const heightFadeVariants = {
  initial: { height: 0, opacity: 0 },
  animate: { height: "auto", opacity: 1 },
  exit: { height: 0, opacity: 0 },
};

const heightFadeTransition = { type: "tween" };

// selectors
const selectAppState = (state) => state.appState;
const selectAddressState = (state) => state.addressState;
const selectIcpState = (state) => state.icp;

const selectAddressPageData = createSelector(
  [selectAppState, selectAddressState, selectIcpState],
  (appState, addressState, icpData) => {
    const { customerType, isPrimaryResidence } = appState;
    const { addressDetails, isAddressInNorthIsland } = addressState;

    const isValid = !!addressDetails;

    const autoSuggestInputValue = getFullAddressFromFinder(addressDetails);

    return {
      isAddressInNorthIsland,
      autoSuggestInputValue,
      icpData,
      isValid,
      customerType,
      isPrimaryResidence,
    };
  },
);

function ResidentialAddressTemplate(props) {
  const pageData = getPageData({ modules, page: props.page });
  const {
    isAddressInNorthIsland,
    autoSuggestInputValue,
    icpData,
    isValid,
    customerType,
    isPrimaryResidence: initialIsPrimaryResidence,
  } = useSelector(selectAddressPageData);

  const dispatch = useDispatch();
  const router = useRouter();

  const [errorMessage, setErrorMessage] = useState(null);
  const [isNavigating, setIsNavigating] = useState(false);
  const [isFetchingOffers, setIsFetchingOffers] = useState(false);
  const [isPrimaryResidence, setPrimaryResidence] = useState(
    initialIsPrimaryResidence,
  );
  const [modalIsVisible, setModalVisibility] = useState(false);

  useEffect(() => {
    gaJoinFormResidentialAddressPageLoad();
  }, []);

  const onAddressSelectionReset = () => {
    if (isValid) {
      dispatch(unsetIcpDetails());
      dispatch(unsetAddressDetails());
    }
    if (errorMessage) {
      setErrorMessage(null);
    }
  };

  const addressSelectionSuccess = ({ icpData, addressFromFinder }) => {
    const addressIslandStatus = verifyAddressInNorthIsland(addressFromFinder);
    const preselectGas = icpData.gasIcp !== "";
    dispatch(setIcpDetails(icpData));
    dispatch(setAddressDetails(addressFromFinder));
    dispatch(setAddressIslandStatus(addressIslandStatus));
    dispatch(setNaturalGasAvailability(addressIslandStatus));
    dispatch(setGasSelection(preselectGas));
    setErrorMessage(null);
  };

  const addressSelectionFailure = ({ icpData, addressFromFinder }) => {
    dispatch(setIcpDetails(icpData));
    dispatch(setAddressDetails(addressFromFinder));
    dispatch(setRecentlyFailedApi("getIcpList"));
  };

  const onAddressSelectionComplete = (data) => {
    const { icpData, addressFromFinder } = data;
    const icpFound = icpData.elecIcp !== "";
    if (icpFound) {
      addressSelectionSuccess({ icpData, addressFromFinder });
    } else {
      addressSelectionFailure({ icpData, addressFromFinder });
    }
  };

  const handleRecentlyFailedApi = (data) => {
    dispatch(setRecentlyFailedApi(data));
  };

  const onConfirmLaterClick = ({ addressFromFinder }) => {
    const emptyIcpData = {
      elecIcp: "",
      gasIcp: "",
    };
    addressSelectionSuccess({ icpData: emptyIcpData, addressFromFinder });
    router.push({
      pathname: appResidentialRoutes.fuel,
    });
  };

  const onContinueClick = async (e) => {
    e.preventDefault();
    if (!isValid) {
      setErrorMessage("Please enter an address");
      return;
    }

    try {
      setIsNavigating(true);
      setIsFetchingOffers(true);
      if (!customerType) {
        dispatch(setResidentialCustomerType());
      }

      await dispatch(setResidentialOffers());
      if (!icpData.elecIcp) {
        dispatch(setRecentlyFailedApi("getIcpList"));
      }
      gaJoinFormResidentialContinueButton({
        event: "input_address",
        content_tertiary: "input address",
      });
      dispatch(submitOccupancyForm({ isPrimaryResidence }));
      router.push({
        pathname: appResidentialRoutes.fuel,
      });
    } catch (ex) {
      if (window && window.localStorage) {
        const tokenInfo = JSON.parse(window.localStorage.getItem(STORAGE_KEY));
        const isValidToken = Boolean(tokenInfo.accessToken);
        if (!isValidToken) {
          dispatch(setRecentlyFailedApi("accessToken"));
        }
      }

      if (isValid) {
        router.push({
          pathname: appResidentialRoutes.fuel,
        });
      }
      setIsNavigating(false);
      setIsFetchingOffers(false);
    }
  };

  const onPrimaryResidenceClick = (e) => {
    e.preventDefault();
    const newPrimaryResidenceValue = isPrimaryResidence ? false : true;
    setPrimaryResidence(newPrimaryResidenceValue);
  };

  return (
    <div className="max-w-full px-4 mx-auto w-158 md:w-210">
      <IntroHeader
        module={{
          fields: {
            title: pageData.fields.title,
            blockEditor: pageData.fields.blockEditor,
          },
        }}
      />
      <motion.div variants={itemFade}>
        <AddressAutoSuggest
          initialValue={autoSuggestInputValue}
          onAddressSelectionReset={onAddressSelectionReset}
          setRecentlyFailedApi={handleRecentlyFailedApi}
          onAddressSelectionComplete={onAddressSelectionComplete}
          renderSuggestionsContainerFooter={({
            hasMultipleRegistryResults,
            addressFromFinder,
          }) => (
            <SuggestionsFooterConfirmLater
              visible={hasMultipleRegistryResults}
              addressFromFinder={addressFromFinder}
              onConfirmLaterClick={onConfirmLaterClick}
            />
          )}
          disabled={isFetchingOffers}
        />
        <div
          className={classNames(
            "overflow-hidden mb-10 md:mb-15 lg:mb-20 ",
            icpData.elecIcp || errorMessage ? "mt-7 md:mt-10" : "",
          )}
        >
          <AnimatePresence>
            {icpData.elecIcp && (
              <AddressDetails
                key="addressDetails"
                icpData={icpData}
                showGasIcpDetails={isAddressInNorthIsland}
                isNavigating={isNavigating}
              />
            )}
            {errorMessage && (
              <motion.div
                key="errorMessage"
                initial="initial"
                animate="animate"
                exit="exit"
                variants={heightFadeVariants}
                transition={heightFadeTransition}
                className="overflow-hidden"
              >
                <Alert
                  fields={{
                    title: "Error",
                    copy: errorMessage,
                  }}
                  type="error"
                />
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </motion.div>
      <motion.div
        variants={itemFade}
        className="flex mt-10 md:mt-15 mb-15 md:mb-20"
      >
        <ButtonCheckboxSelection
          id="primaryResidence"
          isSelected={isPrimaryResidence}
          onClick={onPrimaryResidenceClick}
          onInfoIconClick={() => setModalVisibility(true)}
          size="medium"
          textSize="small"
        >
          {pageData.fields.primaryResidenceLabel}
        </ButtonCheckboxSelection>
      </motion.div>
      <StepProgressionButton
        testId="continueButton"
        onContinueClick={onContinueClick}
        applyDisabledStyle={!isValid}
        isLoading={isFetchingOffers}
        backLink={appRoutes.customerType}
      />
      <Modal
        onRequestClose={() => setModalVisibility(false)}
        isOpen={modalIsVisible}
        title={pageData.fields.primaryResidenceModalTitle}
      >
        <InlineBlockEditor
          jsonString={pageData.fields.primaryResidenceModalContent}
        />
      </Modal>
    </div>
  );
}

export { ResidentialAddressTemplate };
