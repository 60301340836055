function Delimiter() {
  return (
    <div
      className="h-1 bg-black-100 rounded-sm my-10 md:my-15"
      data-testid="blockEditorDelimiter"
    />
  );
}

export { Delimiter };
