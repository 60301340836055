import classNames from "classnames";
import Icon from "join-form/components/icon";
import styles from "./feature-item.module.scss";

const typeMap = {
  highlight: styles.feature_highlight,
  complete: styles.feature_complete,
};

const fontSizeMap = {
  13: styles.font13,
  14: styles.font14,
};

const tabletFontSizeMap = {
  13: styles.tabletFont13,
  14: styles.tabletFont14,
};

const desktopFontSizeMap = {
  13: styles.desktopFont13,
  14: styles.desktopFont14,
};

const bottomSpacingMap = {
  12: styles.bottomSpacing12,
  20: styles.bottomSpacing20,
};

const tabletBottomSpacingMap = {
  20: styles.tabletBottomSpacing20,
};

const desktopBottomSpacingMap = {
  20: styles.desktopBottomSpacing20,
};

const iconAlignmentMap = {
  top: styles.iconAlignmentTop,
  middle: styles.iconAlignmentMiddle,
};

export default function FeatureItem(props) {
  const {
    children,
    className = "",
    type = "",
    fontSize,
    tabletFontSize,
    desktopFontSize,
    iconAlignment,
    bottomSpacing,
    tabletBottomSpacing,
    desktopBottomSpacing,
    shouldApplyBasicFont = true,
    showSeparator = true,
    ...rest
  } = props;

  const fontSizeClassName = fontSizeMap[fontSize];
  const tabletFontSizeClassName = tabletFontSizeMap[tabletFontSize];
  const desktopFontSizeClassName = desktopFontSizeMap[desktopFontSize];

  const iconAlignmentClassName = iconAlignmentMap[iconAlignment];

  const bottomSpacingClassName = bottomSpacingMap[bottomSpacing];
  const tabletBottomSpacingClassName =
    tabletBottomSpacingMap[tabletBottomSpacing];
  const desktopBottomSpacingClassName =
    desktopBottomSpacingMap[desktopBottomSpacing];

  const typeClassName = typeMap[type];

  const featureClass = classNames(
    styles.feature,
    { [styles.feature_basic_font]: shouldApplyBasicFont },
    typeClassName,
    fontSizeClassName,
    tabletFontSizeClassName,
    desktopFontSizeClassName,
    bottomSpacingClassName,
    tabletBottomSpacingClassName,
    desktopBottomSpacingClassName,
    iconAlignmentClassName,
    className,
  );

  const separatorColor = type === "complete" ? "bg-orange-300" : "bg-black-300";

  if(!children) return null;

  return (
    <>
      <li className={`${featureClass}`} {...rest}>
        <Icon name="Success" size="18" className={styles.feature_icon} />
        <span className={styles.feature_text}>{children}</span>
      </li>
      {showSeparator && (
        <div
          className={classNames(
            "w-0.5 rounded min-h-2 -mt-3 ml-2",
            "md:min-h-3 last:w-0",
            separatorColor,
          )}
        />
      )}
    </>
  );
}
