import { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { getUnixTime, subMonths } from "date-fns";
import Skeleton from "react-loading-skeleton";
import { useQuery } from "react-query";
import { CheckboxOff, CheckboxOn, ChevronDown } from "assets/icons";
import { PercentChangeLabel } from "components/Chart/PercentChangeLabel";
import { CircleDivider } from "components/Dividers";
import { ModuleWrapper } from "components/Wrappers";
import { getToken } from "helpers/apiHelper";
import { SHARE_PRICE_API } from "helpers/constants";
import { formatDate } from "helpers/dateHelper";
import {
  formatCurrency,
  formatVolume,
  superscriptDollarSymbol,
} from "helpers/numberStringHelper";
import {
  AreaChartComponent,
  BarChartComponent,
  RangeSlider,
} from "./chartHelper";
import { QuotePanel } from "./QuotePanel";

// filter & cache 1, 3, 12 month data sets during loading phase
const filterByMonths = (data) => {
  const filteredData = {};
  SHARE_PRICE_API.months.forEach((month) => {
    const delta = getUnixTime(subMonths(new Date(), month));
    filteredData[month] = data.filter((item) => delta < item.unix).reverse();
  });
  data = []; // clean up

  return filteredData;
};

// format data req
const formatPriceData = (
  priceQuoteData,
  priceHistoryData,
  indicesData,
  priceSummaryData,
) => {
  const ticker = priceHistoryData.value; // GNE
  // price data
  let priceHistory = priceHistoryData.day.map((item) => {
    const dateSplit = item.date.split("-");
    const monthValue = Number(dateSplit[1]) - 1; // months are zero indexed

    return {
      close: Number(item.close),
      nzx: indicesData.find((nzxItem) => nzxItem.name === item.date)?.index
        ?.gross, // match nzx data against dates
      date: formatDate(item.date, "d LLL"),
      tooltip: {
        price: `${ticker}: ${formatCurrency(item.close, true)}`,
        date: formatDate(item.date, "LLL d, yyyy"),
        volume: Number(item.volume).toLocaleString(),
      },
      unix: getUnixTime(new Date(dateSplit[0], monthValue, dateSplit[2])),
      volume: Number(item.volume),
    };
  });

  // summary data
  const {
    id,
    last,
    move_percent: movePercent,
    prices_at_time: dateTime,
  } = priceQuoteData;

  const priceQuote = {
    id,
    last,
    movePercent,
    dateTime: formatDate(dateTime, "d LLL, HH:mm:ss aaa"),
  };

  // price summary
  const monthSummary = priceSummaryData.summary.find(
    (item) => item.label === "Rolling Month",
  );
  const yearSummary = priceSummaryData.summary.find(
    (item) => item.label === "Rolling Year",
  );
  const priceSummary = [
    { key: "Symbol", value: ticker },
    { key: "Volume", value: formatVolume(priceHistory[0].volume) },
    { key: "Current Price", value: formatCurrency(priceQuote.last, true) },
    { key: "Prev Close Price", value: `$${priceHistory[0].close} NZD` },
    { key: "Monthly high", value: formatCurrency(monthSummary.high, true) },
    { key: "Monthly low", value: formatCurrency(monthSummary.low, true) },
    { key: "52 week high", value: formatCurrency(yearSummary.high, true) },
    { key: "52 week low", value: formatCurrency(yearSummary.low, true) },
  ];

  // filter & cache
  priceHistory = filterByMonths(priceHistory);

  // clean up
  indicesData = [];

  return {
    priceHistory,
    priceQuote,
    priceSummary,
  };
};

const fetchReq = async () => {
  try {
    // fetch jwt token once if necessary
    const accessToken = await getToken();

    // config
    const requests = SHARE_PRICE_API.urls.map((url) =>
      axios.get(url, {
        baseURL: process.env.NEXT_PUBLIC_MULE_API_HOST_NAME,
        method: "get",
        headers: { Authorization: `Bearer ${accessToken}` },
      }),
    );

    // request
    const res = await Promise.all(requests);

    // parse data
    const priceQuoteData = res.find((item) =>
      item.config.url.includes("priceQuote"),
    ).data.document.feed.issuer;
    const priceHistoryData = res.find((item) =>
      item.config.url.includes("priceHistory"),
    ).data.document.feed.stock;
    const indicesData = res.find((item) =>
      item.config.url.includes("indicesHistory"),
    ).data.document.feed.date;
    const priceSummary = res.find((item) =>
      item.config.url.includes("priceSummary"),
    ).data.document.feed;

    return formatPriceData(
      priceQuoteData,
      priceHistoryData,
      indicesData,
      priceSummary,
    );
  } catch (ex) {
    throw new Error(ex);
  }
};

// conditionally utilise debounced <RangeSlider />
const filterPriceHistory = (priceHistory, timeline, range) => {
  if (
    range.valuesDebounced[0] === range.values[0] &&
    range.valuesDebounced[1] === range.values[1]
  ) {
    return priceHistory.filter(
      (_, index) =>
        index >= range.valuesDebounced[0] && index <= range.valuesDebounced[1],
    );
  } else {
    return priceHistory;
  }
};

export const SharePriceListing = ({ module }) => {
  const [defaultTimeline] = SHARE_PRICE_API.months;
  const [timeline, setTimeline] = useState(String(defaultTimeline));
  const [range, setRange] = useState({
    max: null,
    values: [0, null],
    valuesDebounced: [null, null],
  }); // nulls overwritten upon <RangeSlider /> init
  const [NZX50, setNZX50] = useState(false);

  // fetch parsed data
  const { data, isError, isLoading } = useQuery("sharePrice", fetchReq, {
    refetchOnWindowFocus: false,
    refetchOnmount: false,
    refetchOnReconnect: false,
    staleTime: SHARE_PRICE_API.refetchInterval,
    refetchInterval: SHARE_PRICE_API.refetchInterval,
  });

  // fire only once, after data loaded
  useEffect(() => {
    const dataLength = data?.priceHistory[timeline].length;
    if (range.max === null && dataLength) {
      setRange({
        ...range,
        max: dataLength,
        values: [0, dataLength],
      });
    }
  }, [data, range, setRange, timeline]);

  // toggle month timeline dropdown
  const handleChange = useCallback(
    (target, _range) => {
      setTimeline(target.value);
      setRange({
        ..._range,
        max: data.priceHistory[target.value].length,
        values: [0, data.priceHistory[target.value].length],
        valuesDebounced: [null, null],
      });
    },
    [data?.priceHistory],
  );

  // quote panel data
  const { asxLink, nzxLink, primaryModal } = module?.fields ?? {};
  if (!asxLink || !nzxLink || !primaryModal) return null;

  // utilise <Range/> slider
  let priceHistory = data?.priceHistory[timeline];
  priceHistory = filterPriceHistory(priceHistory, timeline, range);

  return (
    <ModuleWrapper psnid="psn_share_price_listing">
      {isError && (
        <div className="bg-red-100 border-2 text-black-primary text-18/28 p-7 border-red-primary rounded-5">
          <p className="font-medium">
            Genesis share performance data was unable to load
          </p>
          <p>
            Please try again later, or view our share performance on{" "}
            <a
              className="underline"
              href="https://www.google.com/finance/quote/GNE:NZE"
              target="_blank"
              rel="noreferrer"
            >
              Google Finance
            </a>
            .
          </p>
        </div>
      )}
      {!isError && (
        <div className="grid grid-cols-3 space-y-4 sm:space-y-15 md:space-y-0">
          <div className="col-span-3 md:col-span-2">
            <div
              className="p-4 bg-white border border-black-100 rounded-4 md:p-10 md:rounded-5"
              data-testid="share-price-listing"
            >
              {isLoading || !data ? (
                <div className="mb-4">
                  <Skeleton
                    containerTestId="share-price-loader"
                    count={3}
                    width={150}
                  />
                </div>
              ) : (
                <div className="mb-10 md:flex">
                  <div className="grow">
                    <p className="mb-1 font-medium text-orange-primary text-13/16 md:text-14/20">
                      Share price
                      <CircleDivider className="bg-orange-200" />
                      Genesis Energy Ltd
                      <CircleDivider className="bg-orange-200" />
                      {data.priceQuote.id}
                    </p>
                    <div className="flex mb-1 gap-x-4">
                      <div className="self-center">
                        <h3 className="my-1 font-medium text-20/24 text-black-primary md:text-32/36">
                          {superscriptDollarSymbol(
                            formatCurrency(data.priceQuote.last),
                            "font-bold text-14/22 md:text-18/30 -top-1 md:-top-2",
                          )}
                        </h3>
                      </div>
                      <div className="self-center">
                        <PercentChangeLabel
                          movePercent={data.priceQuote.movePercent}
                        />
                      </div>
                    </div>
                    <p className="mb-1 text-13/16 text-black-primary md:text-14/20">
                      {data.priceQuote.dateTime}
                      <CircleDivider className="bg-black-200" />
                      NZDT
                    </p>
                    <p className="text-black-300 text-12/20">
                      (Share price data delayed 20 minutes)
                    </p>
                  </div>
                  <div className="mt-5 mr-4 md:justify-self-end text-14/20 md:m-0 md:text-16/24">
                    <div className="flex md:justify-end">
                      <div className="fsSelectContainer">
                        <select
                          className="fsField"
                          onChange={({ target }) => handleChange(target, range)}
                        >
                          {SHARE_PRICE_API.months.map((item) => (
                            <option key={`key-month-${item}`} value={item}>
                              {item} month
                            </option>
                          ))}
                        </select>
                        <ChevronDown className="fsSelectIcon" />
                      </div>
                    </div>
                    <div className="flex md:justify-end">
                      <label className="flex items-center px-5 py-3 mt-4 -ml-5 space-x-3 cursor-pointer md:mt-8 md:-ml-0 md:-mr-5 text-black-primary text-14/20 md:text-16/24 md:py-4">
                        <span>NZX50</span>
                        <span className="relative w-6 h-6 text-orange-primary">
                          <input
                            className="hidden peer"
                            onChange={() => setNZX50(!NZX50)}
                            type="checkbox"
                          />
                          <CheckboxOff className="absolute inset-0 fill-current" />
                          <CheckboxOn className="absolute inset-0 transition-opacity opacity-0 fill-current peer-checked:opacity-100" />
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
              )}
              {isLoading || !data ? (
                <Skeleton
                  containerTestId="share-price-loader"
                  inline
                  height={200}
                />
              ) : (
                <div className="relative mb-20">
                  <AreaChartComponent
                    data={priceHistory}
                    isVisibleNZX50={NZX50}
                    timeline={timeline}
                  />
                </div>
              )}

              {isLoading || !data ? (
                <div className="text-center">
                  <Skeleton
                    containerTestId="share-price-loader"
                    className="my-4"
                    inline
                    width={200}
                  />
                  <Skeleton
                    containerTestId="share-price-loader"
                    height={200}
                    inline
                  />
                </div>
              ) : (
                <div className="relative mb-11">
                  <BarChartComponent data={priceHistory} timeline={timeline} />
                </div>
              )}
              {/* only render <RangeSlider /> with known max value */}
              {!isLoading && range.max && (
                <RangeSlider
                  data={data.priceHistory[timeline]}
                  range={range}
                  setRange={setRange}
                />
              )}
            </div>
          </div>
          <div className="col-span-3 md:ml-10 md:col-span-1">
            <QuotePanel
              priceSummary={data?.priceSummary}
              asxLink={asxLink}
              nzxLink={nzxLink}
              primaryModal={primaryModal}
            />
          </div>
        </div>
      )}
    </ModuleWrapper>
  );
};
