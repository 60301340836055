import StepNavigationItem from "join-form/components/step-navigation-item";
import { appBusinessRoutes } from "helpers/constants";
import { getBusinessTypeDisplayName } from "helpers/businessDetailsHelper";
import { useRouter } from "next/router";
import { connect } from "react-redux";

const { businessDetails, success, callbackSuccess, ...allAddressRouteInfo } =
  appBusinessRoutes;

const addressPageRoutes = Object.values(allAddressRouteInfo);

function BusinessTypeItem({ businessState, ...rest }) {
  const title = getBusinessTypeDisplayName(businessState);

  const router = useRouter();

  const isVisible = addressPageRoutes.includes(router.asPath);

  if (!isVisible) {
    return null;
  }

  return <StepNavigationItem title={title} path={businessDetails} {...rest} />;
}

const mapStateToProps = (props, ownProps) => {
  const { businessState } = props;
  return { businessState };
};

export default connect(mapStateToProps)(BusinessTypeItem);
