import React from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import App from "join-form/App";
import PageFooterPartial from "join-form/partials/footer/PageFooterPartial";
import PageHeaderPartial from "join-form/partials/header/PageHeaderPartial";
import { initializeNpaAccessToken } from "join-form/services/npaAuth";
import Modal from "react-modal";
import { HeadroomProvider } from "providers/Headroom";

const queryClient = new QueryClient();

function JoinFormApp({ children, ...props }) {
  React.useEffect(() => {
    initializeNpaAccessToken();
    Modal.setAppElement("#wrapper");
  }, []);
  return (
    <QueryClientProvider client={queryClient}>
      <HeadroomProvider>
        <div id="wrapper">
          <PageHeaderPartial {...props} />
          <App>{children}</App>
          <PageFooterPartial {...props} />
        </div>
      </HeadroomProvider>
    </QueryClientProvider>
  );
}

export { JoinFormApp };
