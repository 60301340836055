export function isDesktop() {
  return window.matchMedia("(min-width: 1068px)").matches;
}

// Fires callback function when element is horizontally scrolled to the threshold in viewport
export function createObserver(element, callback) {
  const options = {
    rootMargin: "100% 0% 100% 0%",
    threshold: 1,
  };

  const observer = new IntersectionObserver(callback, options);
  observer.observe(element);
}
