import classNames from "classnames";
import { InlineBlockEditor } from "components/BlockEditor";
import { FormStack, getFormName } from "components/FormStack";

function FooterForm({ module }) {
  const { anchor, form, subtitle, title, description } = module.fields;
  const formName = getFormName(form);
  return (
    formName && (
      <div
        id={anchor}
        data-testid="footerForm"
        data-psnid="psn_footer_form"
        className={classNames(
          "rounded-t-7 md:rounded-t-10",
          "text-white bg-orange-primary",
          "pb-7 md:pb-10",
          "-mb-7 md:-mb-10",
        )}
      >
        <div
          className={classNames(
            "px-4 mx-auto py-15 sm:px-15 md:px-17 md:py-25 lg:px-23 xl:px-46",
            "max-w-full w-120 sm:w-210 md:w-auto",
          )}
        >
          <div className="md:grid md:grid-cols-24 md:gap-7 lg:gap-8">
            <div className="md:col-span-20">
              <div className="mb-10 space-y-3 md:mb-15">
                <h2 className="font-bold text-32/36 md:text-44/52">{title}</h2>
                {subtitle && (
                  <h3
                    data-testid="footerFormSubTitle"
                    className="text-20/32 md:text-24/36"
                  >
                    {subtitle}
                  </h3>
                )}
                {description && (
                  <InlineBlockEditor
                    testid="footerFormDescription"
                    jsonString={description}
                    className="prose-white"
                  />
                )}
              </div>
              <FormStack
                theme="orange"
                className="fsFooterForm"
                formName={formName}
                module={module}
              />
            </div>
          </div>
        </div>
      </div>
    )
  );
}

export { FooterForm };
