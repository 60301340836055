import React, { useEffect } from "react";
import { createSelector } from "@reduxjs/toolkit";
import classNames from "classnames";
import { Formik } from "formik";
import { AnimatePresence, motion } from "framer-motion";
import Feedback from "join-form/components/feedback";
import { Input } from "join-form/components/form-controls";
import { Search, Spinner, Tick } from "join-form/components/icon";
import StepProgressionButton from "join-form/components/step-progression-button";
import { appResidentialRoutes } from "helpers/constants";
import { trackResidentialContinueButton } from "helpers/gtmHelper";
import {
  setCarRegistrationData,
  unsetCarRegistrationData,
} from "reducers/carRegistrationReducer";
import { selectCarRegistration } from "reducers/selector";
import { useGetCarRegistration } from "join-form/services/carRegistrationService";
import { useRouter } from "next/router";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { getPageData } from "helpers/getPageData";
import {
  headerFade,
  heightFadeTransition,
  heightFadeVariants,
  itemContainer,
  itemFade,
} from "./animations";
import RegistrationDetails from "./RegistrationDetails";
import { RegistrationError } from "./RegistrationError";

const colorToCarImageMap = {
  black: "/icons/individual-icons/cars/Black.png",
  blue: "/icons/individual-icons/cars/Blue.png",
  green: "/icons/individual-icons/cars/Green.png",
  grey: "/icons/individual-icons/cars/Grey.png",
  red: "/icons/individual-icons/cars/Red.png",
  silver: "/icons/individual-icons/cars/Silver.png",
  white: "/icons/individual-icons/cars/White.png",
};

const stateSelector = createSelector(
  [selectCarRegistration],
  (carRegistration) => {
    return { carRegistration };
  },
);

function SubmitButtonIcon({ isFetching, isSuccess }) {
  if (isFetching) {
    return (
      <Spinner className={classNames("w-5 h-5 motion-safe:animate-spin")} />
    );
  }

  if (isSuccess) {
    return <Tick className="w-5 h-5" />;
  }

  return <Search className="w-5 h-5" />;
}

function getCarImage(evColour) {
  if (!evColour) {
    return colorToCarImageMap.silver;
  }
  const colour = evColour.toLowerCase() || "";
  const isAvailableColour = Object.keys(colorToCarImageMap).includes(colour);
  return isAvailableColour
    ? colorToCarImageMap[colour]
    : colorToCarImageMap.silver;
}

const modules = [
  "GE_JOIN_Intro_Header",
  "GE_JOIN_Residential_Car_Registration",
];

const defaultPageData = {
  fields: {
    title: "What's your EV's number plate?",
    carRegistrationFormTitle: "Your EV's number plate?",
    carRegistrationFormEmptyInputErrorMessage:
      "Please enter your EV's number plate",
    carRegistrationFormInputPlaceholder: "Enter number plate.",
  },
};

function CarRegistrationPage(props) {
  const {
    values,
    handleChange,
    handleSubmit,
    errors,
    touched,
    isSubmitting,
    isValid,
  } = props;

  const pageData = getPageData({ modules, page: props.page, defaultPageData });

  const [showInitialData, setShowInitialData] = React.useState(true);

  const [errorMessage, setErrorMessage] = React.useState(null);

  const { carRegistration } = useSelector(stateSelector);

  const dispatch = useDispatch();

  const initialData = showInitialData ? carRegistration : null;
  const [carImage, setCarImage] = React.useState(colorToCarImageMap.silver);

  const queryOptions = {
    initialData,
  };

  const queryResult = useGetCarRegistration(values.plate, queryOptions);

  const { data, isFetching, isSuccess, error, remove, refetch } = queryResult;

  const isBusy = isFetching || isSubmitting;
  const isContinueButtonDisabled = data ? false : true;

  useEffect(() => {
    if (carRegistration.colour) {
      setCarImage(getCarImage(carRegistration.colour));
    }
  }, [carRegistration]);

  useEffect(() => {
    if (isSuccess && data) {
      setCarImage(getCarImage(data.colour));
    }
  }, [isSuccess, data]);

  const searchRegistration = () => {
    if (isBusy) {
      return;
    }
    setErrorMessage(null);

    if (values.plate && isValid) {
      refetch();
    } else {
      remove();
      handleSubmit();
    }
  };

  const onInputChange = (e) => {
    handleChange(e);
    setErrorMessage(null);
    if (showInitialData) {
      setShowInitialData(false);
    }
    if (data) {
      remove();
    }
  };

  const onKeyDown = (e) => {
    if (e.key === "Enter") {
      searchRegistration();
    }
  };

  const onContinueButtonClick = () => {
    if (error) {
      return;
    }

    if (isValid) {
      if (!data) {
        setErrorMessage("Please click on search icon before continuing");
        return;
      }

      const action = data
        ? setCarRegistrationData({ ...values, ...data })
        : unsetCarRegistrationData(values);

      dispatch(action);
    }

    handleSubmit();
  };

  return (
    <motion.div
      className={classNames(
        "px-4 mx-auto max-w-full w-158",
        "md:w-200 md:px-12 md:ml-0",
      )}
      exit="undefined"
      data-testid="carRegistrationPage"
    >
      <motion.div
        initial="initial"
        animate="animate"
        exit="exit"
        variants={headerFade}
        className="py-10 md:py-15"
      >
        <h1 className="font-bold text-32/36 md:text-44/52 text-dark-primary">
          {pageData.fields.title}
        </h1>
      </motion.div>
      <motion.div
        initial="initial"
        animate="animate"
        exit="exit"
        variants={itemContainer}
      >
        <motion.div variants={itemFade}>
          <div
            className={classNames(
              "px-4 py-10 pb-13 sm:px-5",
              "bg-white rounded-7 shadow-100",
              "flex flex-col gap-y-5",
            )}
          >
            <div className="flex items-center gap-4">
              <img alt="EV" src={carImage} className="w-16 sm:w-20" />
              <p className="font-bold text-20/24 md:text-24/28">
                {pageData.fields.carRegistrationFormTitle}
              </p>
            </div>
            <div className="flex items-center gap-x-3 sm:px-6 lg:pl-24 lg:pr-10">
              <Input
                name="plate"
                placeholder={
                  pageData.fields.carRegistrationFormInputPlaceholder
                }
                value={values.plate}
                onKeyDown={onKeyDown}
                onChange={onInputChange}
                errorMessage={
                  pageData.fields.carRegistrationFormEmptyInputErrorMessage
                }
                showError={errors.plate && touched.plate}
                className="!mb-0 flex-1"
                disabled={isFetching}
                maxLength="6"
              />
              <button
                type="button"
                data-testid="carRegistrationSearchButton"
                aria-label="Search car registration"
                disabled={isFetching}
                onClick={searchRegistration}
                className={classNames(
                  "transition",
                  "w-13 h-13",
                  "flex items-center justify-center",
                  "rounded-full",
                  "shadow",
                  "text-white",
                  "outline-none focus:outline-none",
                  {
                    "bg-black-primary hover:shadow-6/30/40": !isSuccess,
                    "bg-orange-primary": isSuccess,
                  },
                )}
              >
                <SubmitButtonIcon
                  isFetching={isFetching}
                  isSuccess={isSuccess}
                />
              </button>
            </div>
            <div className="sm:px-6 lg:pl-24 lg:pr-10">
              <AnimatePresence>
                {isSuccess ? <RegistrationDetails data={data} /> : null}
                {error ? <RegistrationError data={error} /> : null}
              </AnimatePresence>
            </div>
          </div>
          <p className="mt-3 mb-10 mr-4 text-right md:mb-15 md:mr-5 text-14/20">
            Data supplied by{" "}
            <a
              href="https://www.carjam.co.nz"
              target="_blank"
              rel="noreferrer"
              className={classNames(
                "text-warm-orange-primary",
                "border-b border-b-warm-orange-primary hover:border-0",
              )}
            >
              CarJam
            </a>
          </p>
        </motion.div>
      </motion.div>

      <div className="my-4 overflow-hidden">
        <AnimatePresence>
          {errorMessage && (
            <motion.div
              initial="initial"
              animate="animate"
              exit="exit"
              variants={heightFadeVariants}
              transition={heightFadeTransition}
              className="overflow-hidden"
            >
              <Feedback type="error" title="Error" data-testid="errorMessage">
                {errorMessage}
              </Feedback>
            </motion.div>
          )}
        </AnimatePresence>
      </div>

      <StepProgressionButton
        onContinueClick={onContinueButtonClick}
        disabled={isContinueButtonDisabled}
        applyDisabledAttribute={isSubmitting}
        backLink={appResidentialRoutes.planSelect}
      />
    </motion.div>
  );
}

const validationSchema = Yup.object().shape({
  plate: Yup.string()
    .required("Please enter your EV's number plate")
    .matches(/^[a-zA-Z0-9]+$/, "Please use letters and numbers only")
    .max(6, "Registration number cannot be more than 6 characters"),
});

const mapPropsToValues = ({ carRegistration }) => ({
  plate: carRegistration.plate,
  carDealership: carRegistration.carDealership,
});

function CarRegistrationPageForm(props) {
  const storeProps = useSelector(stateSelector);

  const initialValues = mapPropsToValues(storeProps);
  const router = useRouter();

  const onSubmit = (values) => {
    trackResidentialContinueButton({
      event: "input_car_registration",
      content_tertiary: "input car registration",
    });
    router.push({ pathname: appResidentialRoutes.contactDetails });
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      validateOnMount
      enableReinitialize
    >
      {(formikProps) => (
        <CarRegistrationPage {...props} {...storeProps} {...formikProps} />
      )}
    </Formik>
  );
}

export default CarRegistrationPageForm;
