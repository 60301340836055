import React from "react";
import { sortBy } from "lodash-es";
import {
  AccordionSectionList,
  AccordionSectionTransparent,
} from "components/AccordionSection";
import { InlineBlockEditor } from "components/BlockEditor";

function AccordionModule({ module }) {
  const {
    title,
    anchor = "accordion",
    accordionList,
    blockEditor,
  } = module.fields;

  if (!accordionList.length) {
    return null;
  }

  const sortedAccordionList = sortBy(accordionList, ["properties.itemOrder"]);

  return (
    <AccordionSectionTransparent
      title={title}
      anchor={anchor}
      accordionList={sortedAccordionList}
    >
      <div className="md:grid md:grid-cols-2">
        <div className="md:col-span-1">
          <InlineBlockEditor jsonString={blockEditor} />
        </div>
      </div>
      <AccordionSectionList
        anchorPrefix={anchor}
        accordionList={sortedAccordionList}
      />
    </AccordionSectionTransparent>
  );
}

export { AccordionModule };
