import styles from "../address-auto-suggest.module.scss";

export function SuggestionsHeaderMultipleResults({
  hasMultipleRegistryResults,
}) {
  return hasMultipleRegistryResults ? (
    <h4 className={styles.suggestions_header}>
      Okay, we found a few similar properties. Please confirm your correct
      address:
    </h4>
  ) : null;
}
