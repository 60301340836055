import classNames from "classnames";
import { ArticleCardTagList } from "components/ArticleCard";
import { formatDate } from "helpers/dateHelper";
import { FadeInBottomAnimation } from "./animations";
import { LatestDocumentActionButton } from "./LatestDocumentActionButton";

function LatestDocumentItem({ className, data }) {
  const {
    title,
    tag,
    tag_TextField,
    publishDate,
    url,
    type,
    buttonText: buttonTextContent,
    buttonText_TextField,
  } = data.fields;

  const tagText = tag?.fields?.title || tag_TextField;

  const buttonText = buttonTextContent?.fields?.title ?? buttonText_TextField;

  return (
    <FadeInBottomAnimation>
      <div
        className={classNames(
          "flex flex-col",
          "p-5 bg-white",
          "rounded-4 border border-black-100",
          "text-black-primary",
          "min-h-52 md:min-h-56",
          "transition md:hover:shadow-100",
          className,
        )}
      >
        <ArticleCardTagList
          items={[tagText, formatDate(publishDate, "LLL yyyy")]}
          testid="documentTag"
        />
        <div
          className={classNames(
            "flex flex-col flex-1",
            "gap-y-5",
            "transition-colors",
          )}
        >
          <div className="flex-1">
            <h4 className="font-medium text-18/28 md:text-20/32">{title}</h4>
          </div>
          <div className="flex lowercase">
            <LatestDocumentActionButton
              buttonText={buttonText}
              type={type}
              url={url}
            />
          </div>
        </div>
      </div>
    </FadeInBottomAnimation>
  );
}

export { LatestDocumentItem };
