import React from "react";
import ReactHtmlParser from "react-html-parser";

function Header({ data, id }) {
  const { level, text } = data;
  const headingText = ReactHtmlParser(text);

  const tag = `h${level}`;
  return React.createElement(
    tag,
    { id, className: "scroll-mt-28 md:scroll-mt-48 lg:scroll-mt-56" },
    headingText,
  );
}

export { Header };
